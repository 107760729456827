import Carousel from "react-multi-carousel";

const LatestChallenges = ({ color, challenges, navigate }) => {
  const truncateString = (str, maxLength) => {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + "...";
    } else {
      return str;
    }
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1440 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1440, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div
      className="marginsleftrightCompany"
      style={{ overflow: "visible" }}
      id="challenges"
    >
      <div
        className="d-flex"
        style={{ justifyContent: "center", overflow: "visible" }}
      >
        <div className={`headerCompany`} style={{ overflow: "visible" }}>
          <div
            className="space flex-wrap"
            style={{ gap: "1rem", alignItems: "center" }}
          >
            <p className="registeration">Latest Challenges</p>
            <p className="black16" style={{ color: color, cursor: "pointer" }}>
              See all Challenges
            </p>
          </div>
          <div
            style={{ marginTop: "2.5rem", gap: "1.25rem", overflow: "visible" }}
          >
            {" "}
            <Carousel responsive={responsive} className="p-2 pb-4 ">
              {challenges &&
                challenges.length > 0 &&
                challenges.map((challe, index) => (
                  <div
                    className="Challengescardouterwhitediv "
                    key={index}
                    style={{ boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)" }}
                  >
                    <div className="Challengescardinnergreydiv">
                      <div className="challengescardinsidegreybackgroundimagediv">
                        {challe.company && (
                          // <div
                          //   className="center p-1"
                          //   style={{ alignItems: "center", background: "#FFF" }}
                          // >
                          <img
                            src={JSON.parse(challe.company).img}
                            alt="card-bg"
                            className="challengescardinsidegreybackgroundimagedivcompanycard p-1  shadowa"
                            style={{
                              maxHeight: "48px",
                              maxWidth: "48px",
                              background: "#FFF",
                              borderRadius: "6px",
                            }}
                          />
                          // </div>
                        )}
                        <img
                          src={`${process.env.REACT_APP_BACKEND_URL}${challe.selectedFile}`}
                          alt="challenge image"
                          className=""
                          style={{
                            borderTopLeftRadius: "10px",
                            borderTopRightRadius: "10px",
                            maxHeight: "7.813rem",
                            width: "100%",
                          }}
                        />
                      </div>
                      <div
                        style={{ padding: "0.75rem 0.46rem 0.75rem 0.5rem" }}
                      >
                        <p className="ChallengecardTaskCounter text-start">
                          {challe.alltask.length}{" "}
                          {challe.alltask.length > 1 ? "Tasks" : "Task"}
                          {" / "}
                          {challe.duration} mins
                        </p>
                        <p
                          className="ChallengecardChallengeTitle"
                          style={{ marginTop: "0.625rem" }}
                        >
                          {challe.title}
                        </p>
                        {challe.company && (
                          <p
                            className="ChallengeCardCompanyName"
                            style={{ marginTop: "0.375rem" }}
                          >
                            {JSON.parse(challe.company).title}
                          </p>
                        )}
                      </div>
                    </div>

                    <div
                      className="d-flex"
                      style={{
                        justifyContent: "space-between",
                        marginTop: "0.5rem",
                      }}
                    >
                      <div
                        className="d-flex"
                        style={{ alignItems: "center", gap: "0.5rem" }}
                      >
                        {challe.user &&
                          JSON.parse(challe.user).profilephoto && (
                            <img
                              src={JSON.parse(challe.user).profilephoto}
                              alt="user photo"
                              height="32px"
                              width="32px"
                              style={{ borderRadius: "125px" }}
                            />
                          )}
                        {challe.user && (
                          <p className=" byname">
                            by{" "}
                            {truncateString(
                              JSON.parse(challe.user).firstName,
                              10
                            )}
                          </p>
                        )}
                      </div>
                      <button
                        className="previewbutton border-0 black14"
                        style={{
                          maxHeight: "32px",

                          fontWeight: "500",
                        }}
                        onClick={() => {
                          navigate(`/preview/${challe.uniqueId}`);
                        }}
                      >
                        Preview
                      </button>
                    </div>
                  </div>
                ))}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LatestChallenges;
