import React from "react";
import on from "../../assets/images/on.svg";
import off from "../../assets/images/off.png";

const NotificationToggle = ({
  type,
  label,
  settings,
  setSettings,
  category,
}) => {
  const handleToggle = () => {
    setSettings({
      ...settings,
      [category]: {
        ...settings[category],
        [type]: !settings[category][type],
      },
    });
  };

  return (
    <div
      className="d-flex align-items-center"
      style={{ gap: "1rem", cursor: "pointer" }}
      onClick={handleToggle}
    >
      <img
        src={settings[category][type] ? on : off}
        alt=""
        width="36px"
        height="20px"
      />
      <p className="gray14new">{label}</p>
    </div>
  );
};

export default NotificationToggle;
