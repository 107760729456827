import { useMediaQuery } from "@mui/material";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Details from "./Details";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Password from "./Password";
const Settings = () => {
  const isNonMobile1024 = useMediaQuery("(min-width:1024px)");
  const isNonMobile = useMediaQuery("(min-width:767px)");
  const [selected, setSelected] = useState("My Details");
  const [user, setUser] = useState("");
  const [company, setCompany] = useState(null);
  const email = useSelector((state) => state.uniqueId);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      try {
        let id;
        const userInfoResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/getUserInfo/uniqueId`,
          { email: email }
        );

        if (userInfoResponse.data.message === "Found") {
          setUser(userInfoResponse.data.user);
          id = userInfoResponse.data.user.company;
        } else {
          navigate("/register/organiser");
          return;
        }

        const companyInfoResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/getCompanyInfo`,
          { id: id }
        );
        setCompany(companyInfoResponse.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [email]);

  return (
    <div className="pb-5 mb-5 paddingmaincontent">
      <div
        className={`transition ${isNonMobile && "d-flex"} `}
        style={{
          justifyContent: "space-between",
          flexFlow: "wrap",
          gap: "10px",
        }}
      >
        <div className=" behindbuttons ">
          <button
            className={`  border-0  ${
              selected === "My Details"
                ? "selectedbutton rounded-2 "
                : "secondbuttontext"
            } ${isNonMobile ? "" : "p-1  "}`}
            style={
              isNonMobile
                ? {
                    minWidth: "188px",
                  }
                : {}
            }
            onClick={() => {
              setSelected("My Details");
            }}
          >
            My Details
          </button>
          <button
            className={` border-0   ${
              selected === "Password"
                ? "selectedbutton rounded-2 "
                : "secondbuttontext"
            } ${isNonMobile ? "" : "p-1  "}`}
            style={isNonMobile ? { minWidth: "188px" } : {}}
            onClick={() => {
              setSelected("Password");
            }}
          >
            Password
          </button>
        </div>
      </div>
      {selected === "My Details" ? (
        <Details user={user} company={company} />
      ) : null}
      {selected === "Password" ? (
        <Password user={user} isNonMobile={isNonMobile} />
      ) : null}
    </div>
  );
};

export default Settings;
