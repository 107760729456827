import "./thirdsection.css";
import { useMediaQuery } from "@mui/material";
const ThirdSection = ({
  selectedVideo,
  user,
  company,
  type,
  learns,
  color,
}) => {
  const isNonMob1024 = useMediaQuery("(min-width:1024px)");

  const getVideoSource = () => {
    if (typeof selectedVideo === "string") {
      return selectedVideo.includes("http")
        ? selectedVideo
        : `${process.env.REACT_APP_BACKEND_URL}/uploads/${selectedVideo}`;
    } else if (selectedVideo instanceof File) {
      return URL.createObjectURL(selectedVideo);
    }
    return null;
  };

  const videoSource = getVideoSource();
  return (
    <div className="marginsleftrightCompany" style={{ paddingTop: "8.125rem" }}>
      <div className="d-flex" style={{ justifyContent: "center" }}>
        <div className="headerCompany ">
          <p className="black16 text-start" style={{ color: color }}>
            Instructions
          </p>
          <p
            style={{
              marginTop: "0.75rem",
              marginBottom: "2.5rem",
              maxWidth: "572px",
            }}
            className="registeration"
          >
            Welcome to the {company?.title ? company?.title : ""}'s Challenge
          </p>
          <div className={`${isNonMob1024 ? "space" : ""}`}>
            <div style={{ width: isNonMob1024 ? "61.9%" : "100%" }}>
              {videoSource ? (
                videoSource.includes("http") ? (
                  videoSource.endsWith(".mp4") ? (
                    <video
                      src={videoSource}
                      controls
                      className="rounded-3 shadowa"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        maxHeight: "330px",
                      }}
                    ></video>
                  ) : (
                    <iframe
                      title="Embedded Video"
                      src={videoSource}
                      frameBorder="0"
                      allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    ></iframe>
                  )
                ) : (
                  <video
                    src={videoSource}
                    controls
                    className="rounded-3 shadowa"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      maxHeight: "330px",
                    }}
                  ></video>
                )
              ) : (
                <p>No video selected</p>
              )}
            </div>
            <div
              style={{
                width: isNonMob1024 ? "33%" : "100%",
                marginTop: isNonMob1024 ? "" : "1rem",
              }}
            >
              <div
                className=" "
                style={{ height: "100%", alignItems: "center" }}
              >
                <div>
                  <div className="d-flex">
                    {" "}
                    <div
                      className=" shadowa"
                      style={{
                        background: "#FFF",
                        borderRadius: "160px",
                        padding: "4px",
                      }}
                    >
                      {user && (
                        <img
                          style={{ borderRadius: "160px" }}
                          src={user.profilephoto}
                          alt="profile"
                          height="80"
                          width="80"
                        />
                      )}
                    </div>
                  </div>
                  <div style={{ marginTop: "1.5rem" }}>
                    <>
                      <p className="black24">
                        {user?.firstName} {user?.lastName}
                      </p>
                      <p
                        className="pink16"
                        style={{ marginTop: "6px", color: color }}
                      >
                        {company && company?.title}
                      </p>
                      <p className="welcometo">
                        Welcome to the <span>{company?.title}</span>{" "}
                        {type && type}. My name is {user?.firstName}{" "}
                        {user?.lastName}, and I will be your instructor today.
                        <br />
                        <br /> We are excited to have you here!
                      </p>
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ThirdSection;
