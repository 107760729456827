const Cast = ({ color, height, width }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : "40"}
      height={height ? height : "40"}
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        d="M6.57543 29.8827C6.3032 29.6117 5.95399 29.4312 5.57543 29.366C5.45376 29.3495 5.33043 29.3495 5.20877 29.366C4.72836 29.3654 4.26493 29.5436 3.90877 29.866C3.15429 30.6209 3.15429 31.8444 3.90877 32.5994C4.26905 32.9651 4.76203 33.1695 5.27543 33.166C5.78921 33.1715 6.28292 32.9668 6.6421 32.5994C7.04689 32.1458 7.21917 31.5305 7.10877 30.9327C7.03846 30.5377 6.8529 30.1724 6.57543 29.8827Z"
        fill={color ? color : "#0C111D"}
      />
      <path
        d="M4.84209 24.9827C4.38927 24.8868 3.9205 25.0489 3.62373 25.4041C3.32697 25.7594 3.2508 26.2495 3.42576 26.678C3.60073 27.1066 3.99818 27.4033 4.45876 27.4494C6.81609 27.7964 8.66231 29.6562 8.99209 32.016C9.076 32.6314 9.60439 33.0884 10.2254 33.0827H10.4088C11.089 32.9798 11.5582 32.3467 11.4588 31.666C10.9883 28.1976 8.26054 25.4698 4.79209 24.9993L4.84209 24.9827Z"
        fill={color ? color : "#0C111D"}
      />
      <path
        d="M5.65876 17.4494C5.19649 17.3422 4.71341 17.506 4.41169 17.8723C4.10997 18.2385 4.04166 18.7441 4.23534 19.1773C4.42902 19.6105 4.8513 19.8966 5.32543 19.916C11.1974 20.6401 15.8188 25.2752 16.5254 31.1494C16.6094 31.7739 17.1452 32.2383 17.7754 32.2327H17.9254C18.2549 32.1911 18.5544 32.0201 18.7576 31.7575C18.9609 31.4948 19.0512 31.1621 19.0088 30.8327C18.134 23.8633 12.6449 18.3741 5.67543 17.4993L5.65876 17.4494Z"
        fill={color ? color : "#0C111D"}
      />
      <path
        d="M5.22543 21.2327C4.76316 21.1255 4.28008 21.2894 3.97836 21.6556C3.67664 22.0219 3.60833 22.5274 3.80201 22.9606C3.99569 23.3938 4.41797 23.68 4.8921 23.6993C6.89269 23.9565 8.75114 24.8711 10.1754 26.2993C11.6025 27.7178 12.5172 29.5706 12.7754 31.566C12.8516 32.188 13.3821 32.654 14.0088 32.6493H14.1588C14.4885 32.6039 14.7866 32.4289 14.9868 32.1629C15.187 31.897 15.2729 31.5622 15.2254 31.2327C14.8649 28.7083 13.6953 26.369 11.8921 24.566C10.0941 22.7558 7.75235 21.5849 5.22543 21.2327Z"
        fill={color ? color : "#0C111D"}
      />
      <path
        d="M29.1588 6.66602H10.9921C7.01295 6.67521 3.79208 9.90353 3.79209 13.8827C3.79209 14.573 4.35174 15.1327 5.04209 15.1327C5.73245 15.1327 6.29209 14.573 6.29209 13.8827C6.28766 12.6333 6.78088 11.4335 7.66279 10.5485C8.54469 9.66347 9.74268 9.16601 10.9921 9.16602H29.1588C31.7311 9.21147 33.7925 11.3099 33.7921 13.8827V25.0827C33.7744 27.6425 31.7182 29.7209 29.1588 29.766H23.1588C22.4684 29.766 21.9088 30.3257 21.9088 31.016C21.9088 31.7064 22.4684 32.266 23.1588 32.266H29.1588C33.1379 32.266 36.3662 29.0452 36.3754 25.066V13.8827C36.3754 9.89703 33.1444 6.66602 29.1588 6.66602Z"
        fill={color ? color : "#0C111D"}
      />
    </svg>
  );
};
export default Cast;
