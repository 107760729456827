import React, { useState, useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import Table from "./Table";
import Clicks from "./Clicks";
import Completed from "./Completed";
import { useMediaQuery } from "@mui/material";
const Analytics = ({ challenges, True }) => {
  const [chartData, setChartData] = useState({});
  const chartRef = useRef(null);

  const [totalCount, setTotalCount] = useState(0);
  const [totalImp, setTotalImp] = useState(0);
  const [totalClicks, setTotalClicks] = useState(0);
  const [previusMonthImps, setPreviousMonthImps] = useState(0);
  const [previosMonthClicks, setPreviousMonthClicks] = useState(0);
  const [previousMonthCompleted, setPreviousMonthCompleted] = useState(0);
  const [percentagevs, setPercentageDifference] = useState(0);
  const isNonMobile768 = useMediaQuery("(min-width: 768px)");
  const processDateData = (dates, currentDay) => {
    const currentMonth = new Date().getMonth() + 1;
    const data = Array.from({ length: currentDay }, (_, index) => {
      const day = index + 1;

      const count = dates.filter(
        (date) =>
          new Date(date.date).getDate() === day &&
          new Date(date.date).getMonth() + 1 === currentMonth
      ).length;
      return count;
    });

    return data;
  };

  useEffect(() => {
    if (challenges.length > 0) {
      const impressionDates = challenges.reduce((allDates, challenge) => {
        return [...allDates, ...challenge.impressionDates];
      }, []);

      const today = new Date();
      const currentYear = today.getFullYear();
      const currentMonth = today.getMonth();
      const currentDay = today.getDate();
      const data = processDateData(impressionDates, currentDay);

      const gradient = chartRef.current
        ?.getContext("2d")
        .createLinearGradient(0, 0, 0, 64);
      gradient.addColorStop(0, "#ECFDF3"); // Top color
      gradient.addColorStop(1, "#FFF");

      setChartData({
        labels: Array.from(
          { length: data.length },
          (_, index) => `${index + 1}`
        ),
        datasets: [
          {
            label: false,
            fill: true,
            lineTension: 0.5,
            backgroundColor: gradient,
            borderColor: "#17B26A",
            borderWidth: 2,
            data: data,
          },
        ],
      });

      let lastDayOfPrevMonth = new Date(today);
      lastDayOfPrevMonth.setMonth(currentMonth, 0);

      const totalImpsPrevMonth = challenges.reduce((acc, challenge) => {
        const prevMonthImpressions = challenge.impressionDates
          .map((date) => new Date(date.date))
          .filter((date) => {
            return (
              date.getFullYear() === currentYear &&
              date.getMonth() === currentMonth - 1 &&
              date.getDate() <= lastDayOfPrevMonth
            );
          }).length;

        return acc + prevMonthImpressions;
      }, 0);

      setPreviousMonthImps(totalImpsPrevMonth);

      const totalClicksPrevMonth = challenges.reduce((acc, challenge) => {
        const prevMonthImpressions = challenge.clickDates
          .map((date) => new Date(date.date))
          .filter((date) => {
            return (
              date.getFullYear() === currentYear &&
              date.getMonth() === currentMonth - 1 &&
              date.getDate() <= lastDayOfPrevMonth
            );
          }).length;

        return acc + prevMonthImpressions;
      }, 0);

      setPreviousMonthClicks(totalClicksPrevMonth);

      const totalCompletedPrevMonth = challenges.reduce((acc, challenge) => {
        const prevMonthImpressions = challenge.countDates
          .map((date) => new Date(date.date))
          .filter((date) => {
            return (
              date.getFullYear() === currentYear &&
              date.getMonth() === currentMonth - 1 &&
              date.getDate() <= lastDayOfPrevMonth
            );
          }).length;

        return acc + prevMonthImpressions;
      }, 0);

      setPreviousMonthCompleted(totalCompletedPrevMonth);

      const totalImps = challenges.reduce((acc, challenge) => {
        const thisMonthImpressions = challenge.impressionDates
          .map((date) => new Date(date.date))
          .filter((date) => {
            return (
              date.getFullYear() === currentYear &&
              date.getMonth() === currentMonth &&
              date.getDate() <= currentDay
            );
          }).length;

        return acc + thisMonthImpressions;
      }, 0);

      const totalClick = challenges.reduce((acc, challenge) => {
        const thisMonthImpressions = challenge.clickDates
          .map((date) => new Date(date.date))
          .filter((date) => {
            return (
              date.getFullYear() === currentYear &&
              date.getMonth() === currentMonth &&
              date.getDate() <= currentDay
            );
          }).length;

        return acc + thisMonthImpressions;
      }, 0);

      const totalCounts = challenges.reduce((acc, challenge) => {
        const thisMonthImpressions = challenge.countDates
          .map((date) => new Date(date.date))
          .filter((date) => {
            return (
              date.getFullYear() === currentYear &&
              date.getMonth() === currentMonth &&
              date.getDate() <= currentDay
            );
          }).length;

        return acc + thisMonthImpressions;
      }, 0);

      // const totalCounts = challenges.reduce((acc, challenge) => {
      //   const thisMonthImpressions = challenge.countDates
      //     .map((date) => new Date(date).getDate())
      //     .filter((date) => date <= currentDay).length;

      //   return acc + thisMonthImpressions;
      // }, 0);

      if (totalImps >= totalImpsPrevMonth) {
        const difference = totalImps - totalImpsPrevMonth;
        const percentageDiff = (
          (difference / totalImpsPrevMonth) *
          100
        ).toFixed(0);
        setPercentageDifference(percentageDiff);
      } else {
        const difference = totalImpsPrevMonth - totalImps;
        const percentageDiff = ((difference / totalImps) * 100).toFixed(0);

        setPercentageDifference(percentageDiff);
      }

      setTotalCount(totalCounts);
      setTotalClicks(totalClick);
      setTotalImp(totalImps);
    }
  }, [challenges]);

  useEffect(() => {
    if (chartRef.current) {
      // Destroy existing Chart instance
      const existingChart = Chart.getChart(chartRef.current);
      if (existingChart) {
        existingChart.destroy();
      }
      chartRef.current.width = 128;
      chartRef.current.height = 64;
      // Create a new Chart instance
      const newChart = new Chart(chartRef.current, {
        type: "line",
        data: chartData,
        options: {
          scales: {
            x: {
              display: false,
            },
            y: {
              display: false,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
          tooltips: {
            enabled: false,
          },
        },
      });
    }
  }, [chartData]);

  return (
    <div
      className="d-flex flex-wrap "
      style={{
        gap: "10px",
        marginTop: "1.25rem",
        justifyContent: "space-between",
      }}
    >
      <div
        className="statsdiv  col-12"
        style={{ width: isNonMobile768 ? "32%" : "" }}
      >
        <div
          className=" d-flex col-md-12 col-12"
          style={{ justifyContent: "space-between" }}
        >
          <p className="statstextfirst">Impressions</p>
        </div>
        <div
          className=" d-flex col-md-12 col-12"
          style={{ marginTop: "1.5rem", justifyContent: "space-between" }}
        >
          <div className="col-md-7">
            <p className="col-md-12 numbertesttaken"> {totalImp}</p>
            {previusMonthImps !== 0 && (
              <p
                className={`col-md-12  ${
                  totalImp > previusMonthImps ? "vslastmonthtext" : "red"
                }`}
                style={{ marginTop: "1rem" }}
              >
                <span>
                  {totalImp > previusMonthImps ||
                  totalImp === previusMonthImps ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M9.99935 15.8337V4.16699M9.99935 4.16699L4.16602 10.0003M9.99935 4.16699L15.8327 10.0003"
                        stroke="#17B26A"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M9.99935 4.16699V15.8337M9.99935 15.8337L4.16602 10.0003M9.99935 15.8337L15.8327 10.0003"
                        stroke="red"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        transform="rotate(0 10 10)"
                      />
                    </svg>
                  )}
                  {percentagevs}%
                </span>{" "}
                vs last month
              </p>
            )}
          </div>
          <div className="col-md-4 d-flex" style={{ justifyContent: "end" }}>
            <div style={{ height: "64px", width: "128px" }}>
              <canvas className="" ref={chartRef}></canvas>
            </div>
          </div>
        </div>
      </div>
      <div
        className="statsdiv  col-12"
        style={{ width: isNonMobile768 ? "32%" : "" }}
      >
        <div
          className=" d-flex col-md-12 col-12"
          style={{ justifyContent: "space-between" }}
        >
          <p className="statstextfirst">Clicks (Job Post)</p>
        </div>
        <Clicks
          totalClicks={totalClicks}
          challenges={challenges}
          previosMonthClicks={previosMonthClicks}
        />
      </div>
      <div
        className="statsdiv col-12"
        style={{ width: isNonMobile768 ? "32%" : "" }}
      >
        <div
          className="  d-flex col-md-12 col-12"
          style={{ justifyContent: "space-between" }}
        >
          <p className="statstextfirst">Applications</p>
        </div>
        <Completed
          totalCount={totalCount}
          challenges={challenges}
          previousMonthCompleted={previousMonthCompleted}
        />
      </div>
      {!True && (
        <div className="" style={{ width: "100%" }}>
          <Table challenges={challenges} />
        </div>
      )}
    </div>
  );
};

export default Analytics;
