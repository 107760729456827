import React, { Component } from "react";
const handleRefresh = () => {
  window.location.reload();
};
class ErrorBoundary extends Component {
  state = { hasError: false };

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service here
    console.error("Error caught by ErrorBoundary:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI here
      return (
        <div
          style={{
            textAlign: "center",
            padding: "50px",
            height: "100vh",
            width: "100vw",
            background: "#FFF",
            position: "absolute",
            zIndex: 100,
          }}
        >
          <h1>Something went wrong.</h1>
          <p>We encountered an error. Please refresh the page to continue.</p>
          <div className="d-flex center">
            <button
              onClick={handleRefresh}
              className="continuebutton mt-4"
              style={{
                padding: "10px 20px",
                fontSize: "16px",
                cursor: "pointer",

                color: "#FFF",
                border: "none",
                borderRadius: "5px",
              }}
            >
              Refresh Page
            </button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
