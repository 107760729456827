// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.resultuserName {
  color: var(--grayscale-black, #252733);
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 145%; /* 20.3px */
}
.resultintaketable {
  color: var(--Gray-500, #667085);
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%; /* 17.4px */
}
.resultuniname {
  color: var(--grayscale-black, #252733);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%; /* 20.3px */
}
`, "",{"version":3,"sources":["webpack://./src/components/Company/Dashboard/resulttable.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;EACtC,sBAAsB;EACtB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,WAAW;AAChC;AACA;EACE,+BAA+B;EAC/B,sBAAsB;EACtB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,WAAW;AAChC;AACA;EACE,sCAAsC;EACtC,oBAAoB;EACpB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,WAAW;AAChC","sourcesContent":[".resultuserName {\n  color: var(--grayscale-black, #252733);\n  font-family: \"Poppins\";\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: 145%; /* 20.3px */\n}\n.resultintaketable {\n  color: var(--Gray-500, #667085);\n  font-family: \"Poppins\";\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 145%; /* 17.4px */\n}\n.resultuniname {\n  color: var(--grayscale-black, #252733);\n  font-family: Poppins;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 145%; /* 20.3px */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
