import EmptyState from "../../assets/images/EmptyState.png";
const Published = ({ jobposts, navigate, loading }) => {
  return (
    <div>
      <div
        className="space flex-wrap align-items-center mb-2"
        style={{ padding: "0rem 1rem" }}
      >
        <p className="gray95016500">Published Jobs</p>
        <div style={{ position: "relative" }}>
          <button
            className="bluebutton"
            onClick={() => {
              navigate("/dashboard/Jobposts");
            }}
          >
            see all
          </button>
        </div>
      </div>
      <table style={{ width: "100%" }}>
        <thead>
          <tr className="newtr">
            <th className="text-start">Job Title</th>

            <th className="text-end">Status</th>
          </tr>
        </thead>
      </table>
      {jobposts.length > 0 ? (
        <div style={{ paddingRight: "0.5rem" }}>
          <div
            className="tablescroll"
            style={{ width: "100%", maxHeight: "274px", overflowY: "scroll" }}
          >
            <table style={{ width: "100%", overflowX: "hidden" }}>
              <tbody
                style={{
                  width: "100%",
                }}
              >
                {jobposts.map((test, index) => (
                  <tr key={index}>
                    <td
                      className="text-start trnew "
                      style={{ paddingRight: "0" }}
                    >
                      {test.title}
                    </td>
                    <td className="text-end trnew" style={{ width: "30%" }}>
                      <div className="joblivestatusouter">
                        <div
                          style={{
                            height: "8px",
                            width: "8px",
                            borderRadius: "8px",
                            background: test?.draft ? "#F97066" : "#17B26A",
                          }}
                        />{" "}
                        {test?.draft ? "Draft" : "Live"}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <>
          {loading ? (
            <p className="black16500 text-start paddingmaincontent">
              {" "}
              Loading..{" "}
            </p>
          ) : (
            <div
              className="center flex-column align-items-center mt-4"
              style={{ width: "100%", height: "90%" }}
            >
              <img
                src={EmptyState}
                alt="emptystate"
                style={{ maxWidth: "86px", maxHeight: "70px" }}
              />
              <p className="pink16">No Data yet</p>
              <p
                className="black14 text-center"
                style={{
                  fontWeight: "500",
                  maxWidth: "332px",
                  marginTop: "1rem",
                }}
              >
                Publish <span className="black14">internships</span> and{" "}
                <span className="black14">job posts</span> to build your talent
                pool.
              </p>
              <button
                className="continuebutton"
                style={{ marginTop: "1rem" }}
                onClick={() => {
                  navigate("/create/job");
                }}
              >
                Publish now
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};
export default Published;
