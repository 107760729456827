import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDispatch } from "react-redux";
import { setEditChallenge } from "../../../state";
import EmptySpace from "../../../assets/images/EmptyState.svg";
import CloseIcon from "@mui/icons-material/Close";
import { RiDeleteBinLine } from "react-icons/ri";
import { setSelectedChallenge } from "../../../state";
import deleteicon from "../../../assets/images/delete.png";

const Table = ({ challenges, setData }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [popup, setPopup] = useState(false);
  const popupref = useRef(null);
  const [selected, setSelected] = useState("");
  const entriesPerPage = 5;
  const [Index, setIndex] = useState(-1);
  const [showIcon, setShowIcon] = useState(false);

  const iconRef = useRef(null);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });

    function handleClickOutside(event) {
      if (iconRef.current && !iconRef.current.contains(event.target)) {
        setShowIcon(false);
        setIndex(-1);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (popupref.current && !popupref.current.contains(event.target)) {
        setSelected("");
        setPopup(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [challenge, setChallenge] = useState(
    [...challenges].sort((a, b) => {
      const titleA = a.title.toLowerCase();
      const titleB = b.title.toLowerCase();
      if (titleA < titleB) return -1;
      if (titleA > titleB) return 1;
      return 0;
    })
  );

  useEffect(() => {
    setChallenge(
      [...challenges].sort((a, b) => {
        const titleA = a.title.toLowerCase();
        const titleB = b.title.toLowerCase();
        if (titleA < titleB) return -1;
        if (titleA > titleB) return 1;
        return 0;
      })
    );
  }, [challenges]);

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const [currentEntries, setCurrentEntries] = useState(
    challenge.slice(indexOfFirstEntry, indexOfLastEntry)
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const totalEntries = challenge.length;
  const totalPages = Math.ceil(totalEntries / entriesPerPage);
  const getTimeElapsed = (createdAt) => {
    const currentDate = new Date();
    const creationDate = new Date(createdAt);

    const timeDifference = currentDate - creationDate;

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return `${days} days ago`;
    } else if (hours > 0) {
      return `${hours} hours ago`;
    } else if (minutes > 0) {
      return `${minutes} minutes ago`;
    } else {
      return `${seconds} seconds ago`;
    }
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    setCurrentEntries(challenge.slice(indexOfFirstEntry, indexOfLastEntry));
  }, [currentPage, challenge, challenges, indexOfFirstEntry, indexOfLastEntry]);

  const handleSort = () => {
    setChallenge(challenge.reverse());
    setCurrentEntries(challenge.slice(indexOfFirstEntry, indexOfLastEntry));
  };
  const renderPageButtons = () => {
    const maxButtons = 5; // Maximum number of buttons to display
    const buttons = [];

    // Calculate the range of buttons to display around the current page
    let startPage = Math.max(1, currentPage - Math.floor(maxButtons / 2));
    let endPage = Math.min(totalPages, startPage + maxButtons - 1);

    // Adjust startPage and endPage if there are not enough buttons
    if (endPage - startPage + 1 < maxButtons) {
      if (currentPage <= Math.floor(maxButtons / 2) + 1) {
        endPage = Math.min(totalPages, maxButtons);
      } else {
        startPage = Math.max(1, totalPages - maxButtons + 1);
      }
    }

    // Render the buttons in the calculated range
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          key={i}
          style={
            i === currentPage
              ? { backgroundColor: "#E31B54", color: "#FFF" }
              : { backgroundColor: "transparent" } // Adjust colors as needed
          }
          onClick={() => handlePageChange(i)}
          className={`btn normalbuttons hoverred ${
            i === currentPage && " rounded-3"
          }`}
        >
          {i}
        </button>
      );
    }

    return buttons;
  };

  const handleDeleteChallenge = async (id) => {
    await axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/api/delete/challenge`, {
        id: id,
      })
      .then((res) => {
        if (res.data.msg === "Challenge deleted successfully") {
          toast.success("Challenge deleted successfully");
          setData((prev) => !prev);
        } else if (res.data.msg === "Challenge not found") {
          setData((prev) => !prev);
        } else {
          toast.error("Error Deleting Challenge");
        }
      });
  };

  return (
    <>
      <div>
        {popup && (
          <div
            className="center zindex1000"
            style={{
              width: "100%",
              height: "100%",

              position: "fixed",
              alignItems: "center",
              top: 0,
              left: 0,
              right: 0,
              zIndex: 11,
              backgroundColor: "rgba(45, 50, 130, 0.30)",
              backdropFilter: "blur(3px)",
              padding: "1rem",
            }}
          >
            <div
              className=" shadowa newPopUpContainer"
              ref={popupref}
              style={{ width: "600px" }}
            >
              <div className="d-flex " style={{ gap: "1rem" }}>
                <div className="circle-background">
                  <img
                    src={deleteicon}
                    alt="delete"
                    height="48px"
                    width="48px"
                  />
                </div>
                <div>
                  <p className="gray90018500">Delete Challenge</p>
                  <p className="gray70014400">
                    Are you sure you want to delete this Challenge?
                    <br /> This action cannot be undone.
                  </p>
                </div>
              </div>
              <div
                className="d-flex justify-content-end"
                style={{ marginTop: "2rem", gap: "0.75rem" }}
              >
                <button
                  className="backbutton"
                  onClick={() => {
                    setSelected("");
                    setPopup(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  className="continuebutton"
                  onClick={() => {
                    handleDeleteChallenge(selected);
                    setSelected("");
                    setPopup(false);
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        )}
        <div
          className="tablescroll"
          style={{ marginTop: "0.6rem", overflowX: "auto" }}
        >
          <table
            className="tableofchallenges"
            style={{ width: "100%", overflowX: "scroll" }}
          >
            <thead className="thead">
              <tr className="theadtr">
                <th className="theadth ">
                  Challenge Title{" "}
                  <div className="btn" onClick={() => handleSort()}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M21.8413 13.01L17.2013 8.36C16.8989 8.08169 16.4337 8.08169 16.1313 8.36L11.5213 13.01C11.3332 13.3001 11.3678 13.6812 11.6051 13.9327C11.8423 14.1842 12.2207 14.2409 12.5213 14.07L15.7413 10.85V19.75C15.7413 20.1642 16.0771 20.5 16.4913 20.5C16.9055 20.5 17.2413 20.1642 17.2413 19.75V10.56L20.7513 14.07C21.0441 14.3625 21.5185 14.3625 21.8113 14.07C22.1122 13.7855 22.1256 13.311 21.8413 13.01Z"
                        fill="#475467"
                      />
                      <path
                        d="M11.5213 9.22C11.2285 8.92755 10.7541 8.92755 10.4613 9.22L7.27129 12.44V3.75C7.27129 3.33579 6.93551 3 6.52129 3C6.10708 3 5.77129 3.33579 5.77129 3.75V12.75L2.26129 9.22C1.96578 8.94464 1.50528 8.95277 1.21967 9.23838C0.934059 9.52399 0.925934 9.98449 1.20129 10.28L5.84129 14.93C6.13753 15.2236 6.61505 15.2236 6.91129 14.93L11.5213 10.28C11.8137 9.98718 11.8137 9.51282 11.5213 9.22Z"
                        fill="#475467"
                      />
                    </svg>
                  </div>
                </th>
                <th className="theadth"> Created by</th>
                <th className="theadth ">Status</th>
                <th className="theadth ">Challenge Type</th>
                <th className="theadth ">Live for</th>
                <th className="theadth ">Completed Challenges</th>
                <th className="theadth text-center">Action</th>
              </tr>
            </thead>
            <tbody className="tbody">
              {currentEntries &&
                currentEntries.map((challenge, index) => (
                  <tr key={index} className="tbodytr">
                    <td className="tbodytrtd ">
                      {" "}
                      <p
                        className="hoverredTableChallenge"
                        onClick={() => {
                          dispatch(
                            setSelectedChallenge({
                              selectedChallenge: challenge,
                            })
                          );
                          navigate("/dashboard/challenge/details");
                        }}
                      >
                        {challenge.title}
                      </p>
                    </td>
                    <td className="tbodytrtd">
                      <div className="d-flex">
                        {JSON.parse(challenge.user[0]).profilephoto && (
                          <img
                            src={JSON.parse(challenge.user[0]).profilephoto}
                            alt="user profile"
                            height="45px"
                            className="shadowa"
                            width="45px"
                            style={{ borderRadius: "100px" }}
                          />
                        )}
                        <p
                          className="mt-0 mb-0 m-2 byname d-flex"
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {JSON.parse(challenge.user[0]).firstName}
                        </p>
                      </div>
                    </td>

                    <td className="tbodytrtd ">
                      <div className="livediv ">
                        {challenge.draft ? (
                          <>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="8"
                              height="8"
                              viewBox="0 0 8 8"
                              fill="none"
                            >
                              <circle cx="4" cy="4" r="3" fill="#F97066" />
                            </svg>
                            Draft
                          </>
                        ) : (
                          <>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="8"
                              height="8"
                              viewBox="0 0 8 8"
                              fill="none"
                            >
                              <circle cx="4" cy="4" r="3" fill="#17B26A" />
                            </svg>
                            Live
                          </>
                        )}
                      </div>
                    </td>
                    <td className="tbodytrtd ">
                      <div className="">
                        {challenge.type === "Fast-Track" && (
                          <div className="typediv  ">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="22"
                              viewBox="0 0 20 22"
                              fill="none"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M4.84062 2.64941H15.1581C16.9114 2.64941 18.3327 4.07365 18.3327 5.83054V16.1692C18.3327 17.9261 16.9114 19.3503 15.1581 19.3503H4.84062C3.08733 19.3503 1.66602 17.9261 1.66602 16.1692V5.83054C1.66602 4.07365 3.08733 2.64941 4.84062 2.64941ZM15.1581 18.1574C16.2539 18.1574 17.1422 17.2672 17.1422 16.1692V5.83054C17.1422 4.73248 16.2539 3.84233 15.1581 3.84233H4.84062C3.74481 3.84233 2.85649 4.73248 2.85649 5.83054V16.1692C2.85649 17.2672 3.74481 18.1574 4.84062 18.1574H15.1581Z"
                                fill="#0C111D"
                              />
                              <path
                                d="M14.3644 8.4152H11.9835C11.6547 8.4152 11.3882 8.68224 11.3882 9.01166C11.3882 9.34107 11.6547 9.60812 11.9835 9.60812H12.7216L9.89617 12.4393L8.30887 10.8488C7.88766 10.4281 7.20628 10.4281 6.78506 10.8488L4.71364 12.8608C4.48153 13.0937 4.48153 13.4709 4.71364 13.7038C4.82465 13.8165 4.97628 13.8795 5.13427 13.8788C5.29244 13.8804 5.44441 13.8172 5.55491 13.7038L7.51522 11.7395L9.10252 13.33C9.52645 13.7516 10.2103 13.7516 10.6343 13.33L13.7374 10.2205V11.3975C13.7374 11.7269 14.0039 11.994 14.3327 11.994C14.6614 11.994 14.9279 11.7269 14.9279 11.3975V9.01166C14.9245 8.69585 14.6789 8.43596 14.3644 8.4152Z"
                                fill="#0C111D"
                              />
                            </svg>
                            Fast-Track
                          </div>
                        )}

                        {challenge.type === "Virtual Experience" && (
                          <div className="typediv  ">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M17.0641 7.42392H17.9732C18.7142 7.42887 19.3137 8.02836 19.3186 8.76937V10.1694C19.3186 10.9124 18.7162 11.5148 17.9732 11.5148H17.2732V12.3057C17.3296 13.6901 16.2572 14.86 14.8732 14.9239H13.4368C12.7782 14.9143 12.1542 14.6271 11.7186 14.133C11.292 13.6259 10.6631 13.3332 10.0004 13.3332C9.33774 13.3332 8.70889 13.6259 8.28224 14.133C7.84661 14.6271 7.22267 14.9143 6.56406 14.9239H5.1277C3.74366 14.86 2.67121 13.6901 2.7277 12.3057V11.5148H2.00952C1.26644 11.5148 0.664062 10.9124 0.664062 10.1694V8.76937C0.669019 8.02836 1.26851 7.42887 2.00952 7.42392H2.91861C3.25605 6.48851 4.13356 5.85656 5.1277 5.83301H14.8732C15.8606 5.86393 16.729 6.49456 17.0641 7.42392ZM2.04588 8.76937V10.1512L2.7277 10.1421V8.76937H2.04588ZM15.9095 12.3057C15.9625 12.9361 15.5022 13.4933 14.8732 13.5603H13.4368C13.1649 13.5585 12.9081 13.4351 12.7368 13.2239C12.0465 12.4412 11.0532 11.9928 10.0095 11.9928C8.96588 11.9928 7.97255 12.4412 7.28224 13.2239C7.11095 13.4351 6.85414 13.5585 6.58224 13.5603H5.1277C4.49867 13.4933 4.03836 12.9361 4.09134 12.3057V8.45119C4.03836 7.82083 4.49867 7.26361 5.1277 7.19664H14.8732C15.5022 7.26361 15.9625 7.82083 15.9095 8.45119V12.3057ZM17.2732 8.77846V10.1512L17.955 10.1603V8.77846H17.2732Z"
                                fill="#0C111D"
                              />
                              <path
                                d="M8.18224 9.41483H5.45497C5.07841 9.41483 4.77315 9.72009 4.77315 10.0966C4.77315 10.4732 5.07841 10.7785 5.45497 10.7785H8.18224C8.5588 10.7785 8.86406 10.4732 8.86406 10.0966C8.86406 9.72009 8.5588 9.41483 8.18224 9.41483Z"
                                fill="#0C111D"
                              />
                              <path
                                d="M13.3004 9.61483C13.2361 9.54822 13.1587 9.49561 13.0732 9.46028C12.9063 9.39666 12.7218 9.39666 12.555 9.46028C12.4694 9.49561 12.392 9.54822 12.3277 9.61483C12.2048 9.74509 12.1364 9.91753 12.1368 10.0966C12.1333 10.1838 12.1488 10.2707 12.1822 10.3512C12.2165 10.4352 12.2658 10.5122 12.3277 10.5785C12.4598 10.7053 12.6355 10.7768 12.8186 10.7785C12.9063 10.7778 12.9929 10.7592 13.0732 10.7239C13.1571 10.6897 13.2341 10.6404 13.3004 10.5785C13.4267 10.4496 13.4983 10.277 13.5004 10.0966C13.5007 10.0059 13.4821 9.91616 13.4459 9.83301C13.4103 9.75244 13.3611 9.67862 13.3004 9.61483Z"
                                fill="#0C111D"
                              />
                              <path
                                d="M11.255 9.46937C11.0881 9.40575 10.9037 9.40575 10.7368 9.46937C10.6525 9.50232 10.5776 9.55538 10.5186 9.62392C10.389 9.75049 10.3167 9.92456 10.3186 10.1057C10.3197 10.1925 10.335 10.2785 10.3641 10.3603C10.3984 10.4464 10.4512 10.524 10.5186 10.5876C10.6463 10.7155 10.8197 10.7874 11.0004 10.7876C11.0881 10.7868 11.1747 10.7683 11.255 10.733C11.3389 10.6988 11.416 10.6495 11.4822 10.5876C11.6069 10.4577 11.6783 10.2857 11.6822 10.1057C11.6821 9.92497 11.6101 9.75166 11.4822 9.62392C11.4179 9.55731 11.3406 9.5047 11.255 9.46937Z"
                                fill="#0C111D"
                              />
                              <path
                                d="M15.1186 9.60573C14.9214 9.41277 14.6284 9.35559 14.3732 9.46028C14.291 9.49797 14.2145 9.54693 14.1459 9.60573C14.0864 9.67383 14.0373 9.75044 14.0004 9.83301C13.983 9.91696 13.983 10.0036 14.0004 10.0876C13.9823 10.1745 13.9823 10.2642 14.0004 10.3512C14.0349 10.4323 14.0843 10.5063 14.1459 10.5694C14.2095 10.6368 14.287 10.6896 14.3732 10.7239C14.4563 10.7602 14.5461 10.7787 14.6368 10.7785C14.7246 10.7788 14.8114 10.7602 14.8913 10.7239C14.9774 10.6896 15.055 10.6368 15.1186 10.5694C15.1802 10.5063 15.2296 10.4323 15.2641 10.3512C15.2931 10.2663 15.3085 10.1773 15.3095 10.0876C15.3077 10.0009 15.2923 9.91499 15.2641 9.83301C15.2243 9.75202 15.1755 9.67578 15.1186 9.60573Z"
                                fill="#0C111D"
                              />
                            </svg>
                            Virtual Experience
                          </div>
                        )}

                        {challenge.type === "Insights" && (
                          <div className="typediv">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 40 40"
                              fill="none"
                            >
                              <path
                                d="M19.9151 14.1663C19.2285 14.1574 18.6741 13.6029 18.6651 12.9163V4.58301C18.6651 3.89265 19.2247 3.33301 19.9151 3.33301C20.6055 3.33301 21.1651 3.89265 21.1651 4.58301V12.9163C21.1561 13.6029 20.6017 14.1574 19.9151 14.1663Z"
                                fill="#0C111D"
                              />
                              <path
                                d="M19.9151 37.4997C19.2285 37.4907 18.6741 36.9363 18.6651 36.2497V27.9163C18.6651 27.226 19.2247 26.6663 19.9151 26.6663C20.6055 26.6663 21.1651 27.226 21.1651 27.9163V36.2497C21.1561 36.9363 20.6017 37.4907 19.9151 37.4997Z"
                                fill="#0C111D"
                              />
                              <path
                                d="M15.6151 15.5163C15.2039 15.5194 14.8175 15.3199 14.5818 14.983L9.8151 8.16634C9.62136 7.89498 9.54407 7.55746 9.6004 7.22882C9.65674 6.90019 9.84204 6.60768 10.1151 6.41634C10.6697 6.07934 11.3894 6.21607 11.7818 6.73301L16.5818 13.5497C16.8615 13.9277 16.9019 14.4319 16.6859 14.8496C16.47 15.2674 16.0353 15.526 15.5651 15.5163H15.6151Z"
                                fill="#0C111D"
                              />
                              <path
                                d="M28.9984 34.633C28.5872 34.636 28.2009 34.4366 27.9651 34.0997L23.2484 27.283C22.8526 26.7169 22.9907 25.9371 23.5568 25.5413C24.1229 25.1455 24.9026 25.2836 25.2984 25.8497L30.0651 32.6663C30.3448 33.0444 30.3852 33.5486 30.1693 33.9663C29.9533 34.384 29.5186 34.6426 29.0484 34.633H28.9984Z"
                                fill="#0C111D"
                              />
                              <path
                                d="M12.8651 19.0997C12.7216 19.1227 12.5753 19.1227 12.4318 19.0997L4.6151 16.183C4.29015 16.0673 4.02643 15.8241 3.88488 15.5095C3.74333 15.195 3.73619 14.8363 3.8651 14.5163C3.976 14.1882 4.21976 13.9218 4.5368 13.7823C4.85384 13.6428 5.21492 13.6431 5.53177 13.783L13.2484 16.683C13.808 16.8981 14.1361 17.4803 14.0303 18.0704C13.9245 18.6605 13.4146 19.0924 12.8151 19.0997H12.8651Z"
                                fill="#0C111D"
                              />
                              <path
                                d="M34.7984 27.083C34.6504 27.0795 34.5039 27.0513 34.3651 26.9997L26.5818 24.1497C26.2538 24.038 25.9868 23.7953 25.8446 23.4793C25.7025 23.1633 25.6978 22.8025 25.8318 22.483C25.9475 22.1581 26.1907 21.8943 26.5052 21.7528C26.8198 21.6112 27.1785 21.6041 27.4984 21.733L35.3151 24.583C35.6401 24.6987 35.9038 24.9419 36.0453 25.2565C36.1869 25.5711 36.194 25.9297 36.0651 26.2497C35.8788 26.781 35.3602 27.1222 34.7984 27.083Z"
                                fill="#0C111D"
                              />
                              <path
                                d="M4.6151 25.7663C3.97441 25.7826 3.42526 25.3116 3.34365 24.6759C3.26204 24.0402 3.67443 23.4458 4.29843 23.2997L12.3484 21.1497C12.6689 21.0631 13.0107 21.1076 13.2983 21.2733C13.586 21.439 13.7959 21.7124 13.8818 22.033C14.0523 22.6992 13.6603 23.3797 12.9984 23.5663L4.9151 25.7163C4.81776 25.7462 4.71687 25.763 4.6151 25.7663Z"
                                fill="#0C111D"
                              />
                              <path
                                d="M27.1651 19.733C26.5959 19.7306 26.0982 19.3488 25.9484 18.7997C25.8619 18.4792 25.9064 18.1374 26.0721 17.8498C26.2378 17.5622 26.5111 17.3522 26.8318 17.2663L34.9151 15.1163C35.5824 14.9368 36.2689 15.3323 36.4484 15.9997C36.6279 16.667 36.2324 17.3535 35.5651 17.533L27.5151 19.683C27.4021 19.7193 27.2838 19.7362 27.1651 19.733Z"
                                fill="#0C111D"
                              />
                              <path
                                d="M9.73177 33.7497C9.24869 33.7456 8.81119 33.4636 8.60806 33.0253C8.40494 32.587 8.47257 32.0709 8.78177 31.6997L14.1318 25.4163C14.5859 24.9022 15.3641 24.8361 15.8984 25.2663C16.1535 25.4802 16.3127 25.7869 16.3409 26.1185C16.369 26.4501 16.2638 26.7793 16.0484 27.033L10.6984 33.3497C10.4515 33.6198 10.0974 33.7664 9.73177 33.7497Z"
                                fill="#0C111D"
                              />
                              <path
                                d="M24.7318 15.9163C24.4395 15.9225 24.155 15.8217 23.9318 15.633C23.6767 15.4192 23.5175 15.1125 23.4893 14.7809C23.4612 14.4493 23.5664 14.1201 23.7818 13.8663L29.1318 7.48301C29.3456 7.22797 29.6523 7.06873 29.9839 7.04058C30.3155 7.01242 30.6447 7.11767 30.8984 7.33301C31.3753 7.76448 31.4406 8.48998 31.0484 8.99967L25.6984 15.4163C25.4663 15.7181 25.1122 15.9013 24.7318 15.9163Z"
                                fill="#0C111D"
                              />
                              <path
                                d="M19.9151 14.1663C19.2285 14.1574 18.6741 13.6029 18.6651 12.9163V4.58301C18.6651 3.89265 19.2247 3.33301 19.9151 3.33301C20.6055 3.33301 21.1651 3.89265 21.1651 4.58301V12.9163C21.1561 13.6029 20.6017 14.1574 19.9151 14.1663Z"
                                fill="#0C111D"
                              />
                              <path
                                d="M19.9151 37.4997C19.2285 37.4907 18.6741 36.9363 18.6651 36.2497V27.9163C18.6651 27.226 19.2247 26.6663 19.9151 26.6663C20.6055 26.6663 21.1651 27.226 21.1651 27.9163V36.2497C21.1561 36.9363 20.6017 37.4907 19.9151 37.4997Z"
                                fill="#0C111D"
                              />
                              <path
                                d="M15.6151 15.5163C15.2039 15.5194 14.8175 15.3199 14.5818 14.983L9.8151 8.16634C9.62136 7.89498 9.54407 7.55746 9.6004 7.22882C9.65674 6.90019 9.84204 6.60768 10.1151 6.41634C10.6697 6.07934 11.3894 6.21607 11.7818 6.73301L16.5818 13.5497C16.8615 13.9277 16.9019 14.4319 16.6859 14.8496C16.47 15.2674 16.0353 15.526 15.5651 15.5163H15.6151Z"
                                fill="#0C111D"
                              />
                              <path
                                d="M28.9984 34.633C28.5872 34.636 28.2009 34.4366 27.9651 34.0997L23.2484 27.283C22.8526 26.7169 22.9907 25.9371 23.5568 25.5413C24.1229 25.1455 24.9026 25.2836 25.2984 25.8497L30.0651 32.6663C30.3448 33.0444 30.3852 33.5486 30.1693 33.9663C29.9533 34.384 29.5186 34.6426 29.0484 34.633H28.9984Z"
                                fill="#0C111D"
                              />
                              <path
                                d="M12.8651 19.0997C12.7216 19.1227 12.5753 19.1227 12.4318 19.0997L4.6151 16.183C4.29015 16.0673 4.02643 15.8241 3.88488 15.5095C3.74333 15.195 3.73619 14.8363 3.8651 14.5163C3.976 14.1882 4.21976 13.9218 4.5368 13.7823C4.85384 13.6428 5.21492 13.6431 5.53177 13.783L13.2484 16.683C13.808 16.8981 14.1361 17.4803 14.0303 18.0704C13.9245 18.6605 13.4146 19.0924 12.8151 19.0997H12.8651Z"
                                fill="#0C111D"
                              />
                              <path
                                d="M34.7984 27.083C34.6504 27.0795 34.5039 27.0513 34.3651 26.9997L26.5818 24.1497C26.2538 24.038 25.9868 23.7953 25.8446 23.4793C25.7025 23.1633 25.6978 22.8025 25.8318 22.483C25.9475 22.1581 26.1907 21.8943 26.5052 21.7528C26.8198 21.6112 27.1785 21.6041 27.4984 21.733L35.3151 24.583C35.6401 24.6987 35.9038 24.9419 36.0453 25.2565C36.1869 25.5711 36.194 25.9297 36.0651 26.2497C35.8788 26.781 35.3602 27.1222 34.7984 27.083Z"
                                fill="#0C111D"
                              />
                              <path
                                d="M4.6151 25.7663C3.97441 25.7826 3.42526 25.3116 3.34365 24.6759C3.26204 24.0402 3.67443 23.4458 4.29843 23.2997L12.3484 21.1497C12.6689 21.0631 13.0107 21.1076 13.2983 21.2733C13.586 21.439 13.7959 21.7124 13.8818 22.033C14.0523 22.6992 13.6603 23.3797 12.9984 23.5663L4.9151 25.7163C4.81776 25.7462 4.71687 25.763 4.6151 25.7663Z"
                                fill="#0C111D"
                              />
                              <path
                                d="M27.1651 19.733C26.5959 19.7306 26.0982 19.3488 25.9484 18.7997C25.8619 18.4792 25.9064 18.1374 26.0721 17.8498C26.2378 17.5622 26.5111 17.3522 26.8318 17.2663L34.9151 15.1163C35.5824 14.9368 36.2689 15.3323 36.4484 15.9997C36.6279 16.667 36.2324 17.3535 35.5651 17.533L27.5151 19.683C27.4021 19.7193 27.2838 19.7362 27.1651 19.733Z"
                                fill="#0C111D"
                              />
                              <path
                                d="M9.73177 33.7497C9.24869 33.7456 8.81119 33.4636 8.60806 33.0253C8.40494 32.587 8.47257 32.0709 8.78177 31.6997L14.1318 25.4163C14.5859 24.9022 15.3641 24.8361 15.8984 25.2663C16.1535 25.4802 16.3127 25.7869 16.3409 26.1185C16.369 26.4501 16.2638 26.7793 16.0484 27.033L10.6984 33.3497C10.4515 33.6198 10.0974 33.7664 9.73177 33.7497Z"
                                fill="#0C111D"
                              />
                              <path
                                d="M24.7318 15.9163C24.4395 15.9225 24.155 15.8217 23.9318 15.633C23.6767 15.4192 23.5175 15.1125 23.4893 14.7809C23.4612 14.4493 23.5664 14.1201 23.7818 13.8663L29.1318 7.48301C29.3456 7.22797 29.6523 7.06873 29.9839 7.04058C30.3155 7.01242 30.6447 7.11767 30.8984 7.33301C31.3753 7.76448 31.4406 8.48998 31.0484 8.99967L25.6984 15.4163C25.4663 15.7181 25.1122 15.9013 24.7318 15.9163Z"
                                fill="#0C111D"
                              />
                            </svg>
                            Insights
                          </div>
                        )}
                      </div>
                    </td>
                    <td className="tbodytrtd ">
                      <div className="livediv">
                        {challenge.draft
                          ? "0 days"
                          : `${getTimeElapsed(challenge.createdAt)} `}
                      </div>
                    </td>
                    <td className="textofthecount tbodytrtd ">
                      {challenge.count}
                    </td>
                    <td className="textofthecount tbodytrtd ">
                      <div
                        className="center"
                        // style={{ padding: "12px 0px" }}
                      >
                        <div style={{ position: "absolute" }}>
                          {showIcon && Index === index && (
                            <div
                              style={{
                                position: "absolute",
                                background: "transparent",
                                zIndex: 10,

                                marginLeft: "-140px",
                              }}
                              ref={iconRef}
                            >
                              <div
                                className="d-flex shadowa"
                                style={{
                                  flexDirection: "column",
                                  background: "transparent",
                                  borderRadius: "5px",
                                }}
                              >
                                <button
                                  className="d-flex popuptext"
                                  style={{
                                    padding: "11px 1rem",
                                    maxHeight: "2rem",
                                    borderRadius: "5px 5px 0px 0px",
                                    gap: "10px",
                                    background: "#FFF",
                                    border: "none",
                                    width: "160px",
                                    minHeight: "42px",
                                  }}
                                  onClick={() => {
                                    dispatch(
                                      setEditChallenge({
                                        editChallenge: challenge,
                                      })
                                    );
                                    navigate("/edit/challenge");
                                  }}
                                >
                                  Edit
                                </button>

                                <button
                                  className="d-flex popuptext"
                                  style={{
                                    padding: "11px 1rem",
                                    maxHeight: "2rem",
                                    borderRadius: "0px 0px 5px 5px",
                                    gap: "10px",
                                    background: "#FFF",
                                    border: "none",
                                    width: "160px",
                                    minHeight: "42px",
                                    borderTop: "1px solid #d9d9d9",
                                  }}
                                  onClick={() => {
                                    setSelected(challenge._id);
                                    setPopup(true);
                                  }}
                                >
                                  Delete
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                        <MoreVertIcon
                          className="hoverred gray700"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setIndex(index);
                            setShowIcon(true);
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              {currentEntries.length > 0 && currentEntries.length < 5 && (
                <>
                  {Array.from({ length: 5 - currentEntries.length }).map(
                    (_, index) => (
                      <tr
                        key={index}
                        className="tbodytr"
                        style={{ border: "none" }}
                      >
                        <td className="tbodytrtd"></td>
                        <td className="tbodytrtd"></td>
                        <td className="tbodytrtd"></td>
                        <td className="tbodytrtd"></td>
                        <td className="tbodytrtd"></td>
                        <td className="tbodytrtd"></td>
                      </tr>
                    )
                  )}
                </>
              )}
            </tbody>
          </table>
          {challenges.length === 0 && (
            <div
              className="center col-md-12 col-12 innerglass"
              style={{
                alignItems: "center",
                flexDirection: "column",

                height: "400px",
              }}
            >
              <div className="d-flex justify-content-center align-items-center flex-column">
                <img
                  src={EmptySpace}
                  alt="Emptyspace"
                  height="225px"
                  width="180px"
                  className="img-fluid"
                />
                <p className="pink16">No Data yet</p>
                <p className="black14" style={{ fontWeight: "500" }}>
                  <span className="black14">Please come back later.</span>{" "}
                  Create challenges to view them here
                </p>
              </div>
            </div>
          )}
        </div>
        <div
          className="d-flex"
          style={{ marginTop: "1rem", justifyContent: "end" }}
        >
          <div
            className="d-flex"
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            {renderPageButtons()}
            {indexOfLastEntry < challenge.length && (
              <button
                onClick={handleNextPage}
                disabled={indexOfLastEntry >= challenge.length}
                className="backbutton"
              >
                Next
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Table;
