import "./popup.css";
import React, { useState, useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import { useDispatch } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { setLogin } from "../../../state";
import Step1 from "../../StudentMoreDetails/step1";
import Step2 from "../../StudentMoreDetails/Step2";
import Step3 from "../../StudentMoreDetails/Step3";
import Step4 from "../../StudentMoreDetails/Step4";
import Step5 from "../../StudentMoreDetails/Step5";
import Step6 from "../../StudentMoreDetails/Step6";
import Step7 from "../../StudentMoreDetails/Step7";
import Step8 from "../../StudentMoreDetails/Step8";

const Popup = ({ user, setPopup, setStudent }) => {
  const [levelofstudy, setLevelOfStudy] = useState("");
  const [study, setStudy] = useState("Please Select");
  const [gender, setGender] = useState("");
  const [password, setPassword] = useState("");
  const [intake, setIntake] = useState({
    Month: "",
    Year: "",
  });
  const [languages, setLanguages] = useState([]);
  const [graduation, setGraduation] = useState({
    Month: "",
    Year: "",
  });
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [croppedimage, setcroppedImage] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // EmailAddress
  const isNonMobile = useMediaQuery("(min-width:767px)");
  // Add state to manage the visibility of the popup
  const [showPopup, setShowPopup] = useState(true);
  const [fillPercentage, setFillPercentage] = useState(0);
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);

  const fillBarStyle = {
    borderRadius: "10px 0 0 0",
    width: `${fillPercentage}%`,
    height: "10px", // Adjust as needed
    background: "#C01048", // Color of the fill bar
    transition: "width 0.3s ease", // Optional: Add transition for a smoother effect
  };

  useEffect(() => {
    setFillPercentage((step / 8) * 100);
  }, [step]);

  const HandleSendData = async () => {
    const base64 = await new Promise((resolve) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64Data = reader.result;
        resolve(base64Data);
      };

      reader.readAsDataURL(profilePhoto);
    });

    const userdata = {
      email: user.EmailAddress,
      levelofstudy,
      study,
      intake,
      graduation,
      profilePhoto: croppedimage,

      languages: languages.map((lang) => ({
        text: lang.text,
        select: lang.select,
        icon: lang.icon && lang.icon.outerHTML, // Convert SVG to string
      })),
      gender,
    };
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/UserData`, userdata, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`, // Attach the token in the headers
        },
      })
      .then((res) => {
        setLoading(false);
        if (res.data.message === "Data received successfully") {
          setStudent(res.data.user);
          setPopup(false);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <div className="popup">
      {/* Popup content */}
      <div className="popup-content">
        <div className="fill-bar" style={fillBarStyle}></div>
        {step === 1 && (
          <Step1
            levelofstudy={levelofstudy}
            setLevelOfStudy={setLevelOfStudy}
            isNonMobile={isNonMobile}
            setStep={setStep}
          />
        )}
        {step === 2 && (
          <Step2
            isNonMobile={isNonMobile}
            setStep={setStep}
            study={study}
            setStudy={setStudy}
          />
        )}
        {step === 3 && (
          <Step3
            isNonMobile={isNonMobile}
            setStep={setStep}
            intake={intake}
            setIntake={setIntake}
          />
        )}
        {step === 4 && (
          <Step4
            isNonMobile={isNonMobile}
            setStep={setStep}
            intake={intake}
            graduation={graduation}
            setGraduation={setGraduation}
          />
        )}
        {step === 5 && (
          <Step5
            isNonMobile={isNonMobile}
            setStep={setStep}
            gender={gender}
            setGender={setGender}
          />
        )}
        {step === 6 && (
          <Step6
            isNonMobile={isNonMobile}
            setStep={setStep}
            languages={languages}
            setLanguages={setLanguages}
          />
        )}
        {/* {step === 7 && (
          <Step7
            isNonMobile={isNonMobile}
            setStep={setStep}
            password={password}
            setPassword={setPassword}
          />
        )} */}
        {step === 7 && (
          <Step8
            setcroppedImage={setcroppedImage}
            croppedimage={croppedimage}
            isNonMobile={isNonMobile}
            setStep={setStep}
            profilePhoto={profilePhoto}
            loading={loading}
            setProfilePhoto={setProfilePhoto}
            HandleSendData={HandleSendData}
          />
        )}
      </div>
    </div>
  );
};
export default Popup;
