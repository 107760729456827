import { useState } from "react";
import logo from "../../../assets/images/logo.png";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { toast } from "react-toastify";
const Step5 = ({
  setSteps,
  applicationOpenDate,
  setApplicationOpenDate,
  applicationCloseDate,
  setApplicationCloseDate,
  method,
  setMethod,
  requiredDocuments,
  setRequiredDocuments,
  seturl,
  url,
}) => {
  const [closeDate, setCloseDate] = useState("Rolling");

  const handleSetRequiredDocuments = (name) => {
    if (name !== "varyance student profile") {
      setRequiredDocuments((prevDocuments) => {
        const updated = prevDocuments.map((document) => {
          if (document.name === name) {
            return { ...document, value: !document.value };
          }
          return document;
        });
        return updated;
      });
    }
  };

  const handleSubmit = () => {
    if (!applicationOpenDate) {
      toast.error("Please Enter Application Open Date");
      return;
    } else if (closeDate === "Fixed" && !applicationCloseDate) {
      toast.error("Please Enter Application Close Date");
      return;
    } else if (method === "On a separate website" && !url) {
      toast.error("Please Enter Separate website Url");
      return;
    } else {
      setSteps(7);
    }
  };

  return (
    <div className="">
      <p className="BasicInforMationHeading">Application Process</p>
      <p className="UnderBasicHeading" style={{ marginTop: "0.25rem" }}>
        What’s the application window and process?
      </p>
      <div style={{ marginTop: "2.5rem" }}>
        <p className="thumbnailcreate">Application open date</p>
        <input
          type="date"
          className=" forminput "
          style={{
            marginTop: "0.75rem",
            width: "100%",
            display: "block",
          }}
          value={applicationOpenDate}
          onChange={(e) => setApplicationOpenDate(e.target.value)}
        />
      </div>
      <div style={{ marginTop: "2.5rem" }}>
        <p className="thumbnailcreate">Application close date</p>
        <div className="d-flex">
          <div
            className="d-flex"
            style={{ gap: "0.75rem", marginTop: "0.75rem", cursor: "pointer" }}
            onClick={() => setCloseDate("Rolling")}
          >
            <div className="d-flex">
              {closeDate === "Rolling" ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M9.97591 1.6665C5.37591 1.6665 1.64258 5.39984 1.64258 9.99984C1.64258 14.5998 5.37591 18.3332 9.97591 18.3332C14.5759 18.3332 18.3092 14.5998 18.3092 9.99984C18.3092 5.39984 14.5842 1.6665 9.97591 1.6665ZM10.0009 13.5248C8.05091 13.5248 6.47591 11.9498 6.47591 9.99984C6.47591 8.04984 8.05091 6.47484 10.0009 6.47484C11.9509 6.47484 13.5259 8.04984 13.5259 9.99984C13.5259 11.9498 11.9509 13.5248 10.0009 13.5248Z"
                    fill="#E31B54"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z"
                    stroke="#292D32"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </div>
            <p className="black14" style={{ fontWeight: "400" }}>
              Rolling deadline
            </p>
          </div>
        </div>
        <div
          className="d-flex"
          style={{ gap: "0.75rem", marginTop: "1rem", cursor: "pointer" }}
          onClick={() => setCloseDate("Fixed")}
        >
          <div className="d-flex">
            {closeDate === "Fixed" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M9.97591 1.6665C5.37591 1.6665 1.64258 5.39984 1.64258 9.99984C1.64258 14.5998 5.37591 18.3332 9.97591 18.3332C14.5759 18.3332 18.3092 14.5998 18.3092 9.99984C18.3092 5.39984 14.5842 1.6665 9.97591 1.6665ZM10.0009 13.5248C8.05091 13.5248 6.47591 11.9498 6.47591 9.99984C6.47591 8.04984 8.05091 6.47484 10.0009 6.47484C11.9509 6.47484 13.5259 8.04984 13.5259 9.99984C13.5259 11.9498 11.9509 13.5248 10.0009 13.5248Z"
                  fill="#E31B54"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z"
                  stroke="#292D32"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </div>
          <p className="black14" style={{ fontWeight: "400" }}>
            Fixed deadline
          </p>
        </div>
        {closeDate === "Fixed" && (
          <input
            type="date"
            className=" forminput "
            style={{
              marginTop: "0.75rem",
              width: "100%",
              display: "block",
            }}
            value={applicationCloseDate}
            onChange={(e) => setApplicationCloseDate(e.target.value)}
          />
        )}
      </div>
      <div style={{ marginTop: "4rem" }}>
        <p className="thumbnailcreate">
          Preferred method for candidates to submit their application?
        </p>
        <div
          className="w-full d-flex"
          style={{ marginTop: "0.75rem", gap: "1.25rem" }}
        >
          <div
            className={`rolecards  ${
              method === "On a separate website" ? "selectedrolecard" : ""
            }`}
            style={{
              // maxWidth: "318px",
              width: "48%",
              padding: "1.25rem",
            }}
            onClick={() => setMethod("On a separate website")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
            >
              <path
                d="M34.0667 5.76667C33.8688 5.30606 33.4179 5.00548 32.9167 5H24.5833C23.893 5 23.3333 5.55964 23.3333 6.25C23.3333 6.94036 23.893 7.5 24.5833 7.5H29.9L20.3667 17.0333C19.8792 17.5214 19.8792 18.312 20.3667 18.8C20.8547 19.2874 21.6453 19.2874 22.1333 18.8L31.6667 9.26667V14.5833C31.6667 15.2737 32.2263 15.8333 32.9167 15.8333C33.607 15.8333 34.1667 15.2737 34.1667 14.5833V6.25C34.1658 6.08384 34.1318 5.91953 34.0667 5.76667Z"
                fill={
                  method === "On a separate website" ? "#E31B54" : "#000000"
                }
              />
              <path
                d="M29.5833 18.3333C28.8967 18.3423 28.3423 18.8967 28.3333 19.5833V26.25C28.3242 29.2377 25.9044 31.6575 22.9167 31.6667H12.9167C9.92892 31.6575 7.50915 29.2377 7.5 26.25V16.25C7.50915 13.2623 9.92892 10.8425 12.9167 10.8333H19.5833C20.2737 10.8333 20.8333 10.2737 20.8333 9.58333C20.8333 8.89298 20.2737 8.33333 19.5833 8.33333H12.9167C8.54441 8.33333 5 11.8777 5 16.25V26.25C5 30.6223 8.54441 34.1667 12.9167 34.1667H22.9167C27.2889 34.1667 30.8333 30.6223 30.8333 26.25V19.5833C30.8244 18.8967 30.2699 18.3423 29.5833 18.3333Z"
                fill={
                  method === "On a separate website" ? "#E31B54" : "#000000"
                }
              />
            </svg>
            <p
              className={`rolecardsheading ${
                method === "On a separate website"
                  ? "recruiter-text"
                  : "default-text"
              }`}
              style={{ marginTop: "1.25rem" }}
            >
              On a separate website
            </p>
            <p
              className={`rolecardstext mt-2 ${
                method === "On a separate website"
                  ? "recruiter-text"
                  : "default-text"
              }`}
            >
              Enter a website or Applicant Tracking System URL
            </p>
          </div>

          <div
            className={`rolecards  ${
              method === "On varyance.io" ? "selectedrolecard" : ""
            }`}
            style={{
              width: "48%",
              padding: "1.25rem",
            }}
            onClick={() => setMethod("On varyance.io")}
          >
            <img src={logo} alt="logo" height="36px" width="36px" />

            <p
              className={`rolecardsheading ${
                method === "On varyance.io" ? "recruiter-text" : "default-text"
              }`}
              style={{ marginTop: "1.25rem" }}
            >
              On varyance.io
            </p>
            <p
              className={`rolecardstext mt-2 ${
                method === "On varyance.io" ? "recruiter-text" : "default-text"
              }`}
            >
              View applications on talent pool page.
            </p>
          </div>
        </div>
      </div>
      {method === "On a separate website" && (
        <div style={{ marginTop: "1.25rem" }}>
          <label htmlFor="url" className="thumbnailcreate">
            Separate website url
          </label>
          <p className="underthumbnailcreate">
            Where students will apply for the job
          </p>
          <input
            type="url"
            id="url"
            value={url}
            onChange={(e) => seturl(e.target.value)}
            placeholder="Enter URL"
            className="newinputs mt-2 mb-3 col-md-12 col-12"
          />
        </div>
      )}
      <div style={{ marginTop: "2.5rem" }}>
        <p className="thumbnailcreate">Required documents</p>
        <p className="underthumbnailcreate" style={{ marginTop: "0.25rem" }}>
          The fewer documents you require, the more likely candidates are to
          apply.
        </p>
        <div
          className="d-flex"
          style={{ flexDirection: "column", marginTop: "0.75rem", gap: "1rem" }}
        >
          {requiredDocuments.map((doc, index) => (
            <div className="d-flex" key={index}>
              <div
                className="d-flex "
                style={{ gap: "0.75rem", cursor: "pointer" }}
                onClick={() => handleSetRequiredDocuments(doc.name)}
              >
                <div className="d-flex">
                  {doc.value ? (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z"
                          fill="#E31B54"
                        />
                      </svg>
                    </>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5.00521 1.66602H15.0052C16.8462 1.66602 18.3385 3.1584 18.3385 4.99935V14.9993C18.3385 16.8403 16.8462 18.3327 15.0052 18.3327H5.00521C3.16426 18.3327 1.67188 16.8403 1.67188 14.9993V4.99935C1.67188 3.1584 3.16426 1.66602 5.00521 1.66602ZM16.4783 16.4725C16.869 16.0818 17.0885 15.5519 17.0885 14.9993V4.99935C17.0885 4.44681 16.869 3.91691 16.4783 3.52621C16.0876 3.13551 15.5577 2.91602 15.0052 2.91602H5.00521C3.85461 2.91602 2.92188 3.84876 2.92188 4.99935V14.9993C2.92188 15.5519 3.14137 16.0818 3.53207 16.4725C3.92277 16.8632 4.45267 17.0827 5.00521 17.0827H15.0052C15.5577 17.0827 16.0876 16.8632 16.4783 16.4725Z"
                        fill="#344054"
                      />
                    </svg>
                  )}
                </div>
                <p className="black14" style={{ fontWeight: "400" }}>
                  {doc.name}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <hr style={{ marginTop: "2.5rem", marginBottom: "1.25rem" }} />
      <div
        className="d-flex flex-wrap mb-5"
        style={{ justifyContent: "space-between", gap: "1.25rem" }}
      >
        <button className="backbutton" onClick={() => setSteps(5)}>
          Back
        </button>
        <button
          className="continuebutton"
          onClick={() => {
            handleSubmit();
          }}
        >
          Continue <ChevronRightIcon />
        </button>
      </div>
    </div>
  );
};

export default Step5;
