import { useEffect, useState } from "react";
import logo from "../../assets/images/logo.png";
import Popup from "../StudentDashboard/Challenges/popup";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import FeedIcon from "../../assets/images/icons/Feed";
import ChallengeIcon from "../../assets/images/icons/ChallengeIcon";
import JobIcon from "../../assets/images/icons/JobsIcon";
import EventIcon from "../../assets/images/icons/EventIcon";
import Groups from "../../assets/images/icons/GroupIcon";
import GroupIcon from "../../assets/images/icons/GroupIcon";
const NewStudentHeader = ({
  student,
  setStudent,
  setSelected,
  selected,
  navigate,
}) => {
  const [popup, setPopup] = useState(false);

  useEffect(() => {
    if (student && !student.levelofstudy) {
      setPopup(true);
    }
  }, [student]);

  const buttons = [
    {
      name: "Feed",
      icon: <FeedIcon color={selected === "Feed" ? "#E31b54" : "#344054"} />,
    },
    {
      name: "Challenges",
      icon: (
        <ChallengeIcon
          color={selected === "Challenges" ? "#E31b54" : "#344054"}
        />
      ),
    },
    {
      name: "Jobs",
      icon: <JobIcon color={selected === "Jobs" ? "#E31b54" : "#344054"} />,
    },
    {
      name: "Events",
      icon: <EventIcon color={selected === "Events" ? "#E31b54" : "#344054"} />,
    },
    {
      name: "Groups",
      icon: <GroupIcon color={selected === "Groups" ? "#E31b54" : "#344054"} />,
    },
  ];

  return (
    <>
      <div style={{ position: "relative", zIndex: 100 }}>
        {popup && (
          <Popup user={student} setPopup={setPopup} setStudent={setStudent} />
        )}
      </div>
      <div className="LatestStudentHeader shadowa">
        <div
          className="marginsleftrightCompany"
          style={{ padding: "10px 0px" }}
        >
          <div className="center">
            <div className="headerCompany space align-items-center">
              <img
                src={logo}
                alt="logo"
                className="shadowa "
                height={"36px"}
                width={"36px"}
                style={{ borderRadius: "4px", background: "transparent" }}
              />
              <div
                className="d-flex align-items-center"
                style={{ gap: "1rem" }}
              >
                {buttons.map((btn, index) => (
                  <button
                    key={index}
                    className="student-header-button"
                    style={{ color: selected === btn.name ? "#E31b54" : "" }}
                    onClick={() => {
                      setSelected(btn.name);
                      if (btn.name === "Feed") {
                        navigate("/student/dashboard");
                      } else {
                        navigate(`/student/dashboard/${btn.name}`);
                      }
                    }}
                  >
                    {btn.icon}
                    {btn.name}
                  </button>
                ))}
              </div>
              <div
                className="d-flex align-items-center"
                style={{ gap: "0.75rem" }}
              >
                <button className="student-header-button-support">
                  <InfoOutlinedIcon style={{ height: "20px", width: "20px" }} />
                  Support
                </button>
                {student?.profilePhoto && (
                  <img
                    src={student.profilePhoto}
                    alt="profilePhoto"
                    className="student-profile-pic shadowa"
                    style={{ height: "36px", width: "36px" }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default NewStudentHeader;
