import React from "react";

const card2 = () => {
  return (
    <div className="companyCard2nd rounded-3 shadowa">
      <div className="card1paddings">
        <div className="d-flex">
          <div className="card2button1">
            <p className="secondcard1buttontext">02 Engage</p>
          </div>
        </div>
        <div style={{ maxWidth: "560px" }}>
          <p className="text-start Secondcard2ndtext">
            Start the conversation (early)
          </p>
          <p className="Secondcard3rdtext text-start">
            Spark the interest of potential candidates with our engagement tools
            along every step of their academic journey. Plan varyance
            live-stream events, post news, physical events and short videos on
            our social feed & follow-up via messenger.
          </p>
        </div>
      </div>
    </div>
  );
};

export default card2;
