import axios from "axios";
import { useEffect, useState } from "react";
const SpeakersComponent = ({ addedSpeakers }) => {
  const [speakers, setSpeakers] = useState([]);

  useEffect(() => {
    const fetchOwnerName = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/get/speakers/Pictures`,
          { id: addedSpeakers }
        );
        if (response.data.msg === "success") {
          setSpeakers(response.data.user);
        }
      } catch (err) {
        console.log(err);
      }
    };

    if (addedSpeakers.length > 0) {
      fetchOwnerName();
    }
  }, [addedSpeakers]);

  return (
    <>
      {speakers.length > 0 && (
        <div
          className="d-flex align-items-center"
          style={{ position: "relative" }}
        >
          <p className="upcoming-host">Speakers: </p>
          <div
            className="d-flex align-items-center"
            style={{ width: `${30 * speakers.length}px` }}
          >
            {speakers.map((speaker, index) => (
              <img
                className="upcoming-speaker-img"
                src={speaker}
                key={index}
                alt="speaker"
                height={"30px"}
                width={"30px"}
                style={{
                  position: "relative",
                  left: `-${index * 10}px`, // Adjust 20px according to half of the image width (40px / 2)
                }}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default SpeakersComponent;
