import InterestItems from "../../components/InterestItems/InterestItems";
import React, { useEffect } from "react";
const Step2 = ({
  title,
  setTitle,
  setDescription,
  description,
  ReactQuill,
  allCountries,
  isNonMobile1000,
  location,
  setLocation,
  setDuration,
  duration,
  setDateTime,
  datetime,
  setTopic,
  topic,
  language,
  setLanguage,
  languages,
  setSelectedItem,
  selectedItem,
  type,
}) => {
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
      [{ align: [] }],
      ["clean"],
    ],
  };

  const [show, setShow] = React.useState(false);

  useEffect(() => {
    if (topic === "Others") setShow(true);
  }, [topic]);

  const options = [
    "Day in the life",
    "Opportunities",
    "Your application Q/A",
    "Company intro",
    "Topic insight",
    "Industry insight",
    "Others",
  ];

  const getMaxDate = () => {
    const now = new Date();
    now.setDate(now.getDate() + 8); // Add 8 days
    return now.toISOString().slice(0, 16); // Format as YYYY-MM-DDThh:mm
  };

  useEffect(() => {
    if (topic !== "") {
      let chek;
      options.map((opt) => {
        if (opt === topic) {
          chek = false;
        } else {
          chek = true;
        }
      });
      setShow(chek);
    }
  }, []);

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "link",
    "image",
    "align",
  ];

  return (
    <div style={{ width: "100%", maxWidth: "100vw" }}>
      <p className="BasicInforMationHeading">Event information</p>
      <p className="UnderBasicHeading" style={{ marginTop: "0.25rem" }}>
        Add relevant information to publish the event
      </p>

      <div style={{ marginTop: "2.5rem" }}>
        <label htmlFor="title" className="thumbnailcreate">
          Event Title
        </label>
        <input
          value={title}
          id="title"
          onChange={(e) => setTitle(e.target.value)}
          className="newinputs col-md-12 col-12"
          style={{ marginTop: "0.75rem" }}
        />
        <p
          className="mt-2"
          style={{
            fontFamily: "Poppins",
            fontSize: "14px",
            fontWeight: "400",
          }}
        >
          {title.replace(/(<([^>]+)>)/gi, "").length} Characters
        </p>
      </div>

      <div style={{ marginTop: "2.5rem" }} className="jobcreatereactquill">
        <p htmlFor="description" className="thumbnailcreate">
          Description
        </p>
        <p className="underthumbnailcreate" style={{ marginTop: "0.25rem" }}>
          What is this event about? Give some context about the event.
        </p>
        <ReactQuill
          id="description"
          value={description}
          onChange={(value) => setDescription(value)}
          modules={modules}
          formats={formats}
          className="col-md-12 reactquillfontfix whitescrollbar"
          style={{
            backgroundColor: "#FFF",
            marginTop: "0.75rem",
          }}
        />
        <p className="formtext" style={{ marginTop: "1rem" }}>
          {description.replace(/(<([^>]+)>)/gi, "").length} Characters
        </p>
      </div>

      <hr style={{ marginTop: "2.5rem", marginBottom: "2.5rem" }} />

      {type !== "varyance Livestream" && (
        <div
          className={`${isNonMobile1000 ? "d-flex" : ""} `}
          style={{ gap: "1.25rem" }}
        >
          <div
            className="flex-grow-1"
            style={{ maxWidth: isNonMobile1000 ? "48%" : "" }}
          >
            <p className="thumbnailcreate">Country *</p>
            <select
              style={{ marginTop: "0.75rem" }}
              placeholder="Please select Country"
              className="newinputs col-md-12 col-12"
              value={location.Country || ""} // Ensuring it's controlled
              onChange={(e) => {
                setLocation({ ...location, Country: e.target.value });
              }}
            >
              <option value="" disabled>
                Please select Country
              </option>
              {allCountries &&
                allCountries.map((con, index) => (
                  <option key={index} value={con.name}>
                    {con.name}
                  </option>
                ))}
            </select>
          </div>{" "}
          <div
            className="flex-grow-1"
            style={{
              maxWidth: isNonMobile1000 ? "48%" : "",
              marginTop: isNonMobile1000 ? "" : "1rem",
            }}
          >
            <p className="thumbnailcreate">Event Location *</p>
            <input
              className="newinputs col-md-12 col-12"
              style={{ marginTop: "0.75rem" }}
              placeholder="Please Enter Location"
              value={location.City}
              onChange={(e) => {
                setLocation({ ...location, City: e.target.value });
              }}
            />
          </div>
        </div>
      )}

      <div
        className={`${isNonMobile1000 ? "d-flex" : ""} `}
        style={{ gap: "1.25rem", marginTop: "2.5rem" }}
      >
        <div
          className="flex-grow-1 "
          style={{ maxWidth: isNonMobile1000 ? "48%" : "" }}
        >
          <p className="thumbnailcreate">Event Date *</p>
          <input
            type="datetime-local"
            className="newinputs"
            value={datetime}
            onChange={(e) => {
              setDateTime(e.target.value);
            }}
            min={getMaxDate()}
            style={{ marginTop: "0.75rem", width: "100%" }}
          />
        </div>
        <div className="flex-grow-1">
          <p
            className="thumbnailcreate"
            style={{
              maxWidth: isNonMobile1000 ? "48%" : "",
              marginTop: isNonMobile1000 ? "" : "1rem",
            }}
          >
            Event Duration *
          </p>

          <select
            style={{ marginTop: "0.75rem" }}
            className="newinputs col-md-12 col-12 "
            value={duration}
            onChange={(e) => {
              setDuration(e.target.value);
            }}
          >
            <option value="" disabled className="black14">
              Select Duration
            </option>

            <option value="30 mins" className="black14">
              30 mins
            </option>

            <option value="1 hour" className="black14">
              1 hour
            </option>

            <option value="1:30 hour" className="black14">
              1:30 hour
            </option>
          </select>
        </div>
      </div>

      <div
        className={`${isNonMobile1000 ? "d-flex" : ""} `}
        style={{ gap: "1.25rem", marginTop: "2.5rem" }}
      >
        <div
          className="flex-grow-1"
          style={{ maxWidth: isNonMobile1000 ? "48%" : "" }}
        >
          <p className="thumbnailcreate">Event Topic *</p>
          {!show ? (
            <select
              style={{ marginTop: "0.75rem" }}
              className="newinputs col-md-12 col-12"
              value={topic}
              onChange={(e) => {
                setTopic(e.target.value);
              }}
            >
              <option value="" disabled>
                Select Topics
              </option>
              {options.map((opt, index) => (
                <option value={opt} className="black14" key={index}>
                  {opt}
                </option>
              ))}
            </select>
          ) : (
            <>
              <input
                value={topic !== "Others" ? topic : ""}
                onChange={(e) => {
                  setTopic(e.target.value);
                }}
                type="search"
                placeholder="Please Enter topic"
                className="newinputs col-md-12 col-12"
                style={{ marginTop: "0.75rem" }}
              />
            </>
          )}
        </div>
        <div
          className="flex-grow-1"
          style={{
            maxWidth: isNonMobile1000 ? "48%" : "",
            marginTop: isNonMobile1000 ? "" : "1rem",
          }}
        >
          <p className="thumbnailcreate">Select Language *</p>
          <select
            style={{ marginTop: "0.75rem" }}
            className="newinputs col-md-12 col-12 "
            value={language}
            onChange={(e) => {
              setLanguage(e.target.value);
            }}
          >
            <option disabled value="" className="black14">
              Select Language
            </option>
            {languages.map((lang, index) => (
              <option key={index} value={lang} className="black14">
                {lang}
              </option>
            ))}
          </select>
        </div>
      </div>
      <hr style={{ marginTop: "2.5rem", marginBottom: "2.5rem" }} />
      <div>
        {" "}
        <p htmlFor="description" className="thumbnailcreate">
          Select Categories{" "}
        </p>
        <p className="underthumbnailcreate" style={{ marginTop: "0.25rem" }}>
          Categorize event based on student interests.
        </p>
        <div style={{ marginTop: "0.75rem" }}>
          <InterestItems
            setSelectedItem={setSelectedItem}
            selectedItem={selectedItem}
            TRUE={false}
          />
        </div>
      </div>
    </div>
  );
};
export default Step2;
