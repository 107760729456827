import { useRef, useState } from "react";
import Header from "./Header";
import "./main.css";
import FirstSection from "./FirstSection";
import { useMediaQuery } from "@mui/material";

const PreviewWhole = ({
  company,
  newTasks,
  setPreview,
  title,
  color,
  taskCounter,
}) => {
  const [taskCount, setTaskCount] = useState(taskCounter ?? 0);
  const isNonMobile1024 = useMediaQuery("(min-width:1024px)");
  const isNonMobile768 = useMediaQuery("(min-width:768px)");
  const [Test, setTest] = useState(false);
  const referenceDiv2 = useRef(null);
  return (
    <div style={{ width: "100vw", minHeight: "90vh" }}>
      <Header
        company={company}
        title={title}
        setPreview={setPreview}
        isNonMobile1024={isNonMobile1024}
        setTest={setTest}
        Test={Test}
        referenceDiv2={referenceDiv2}
      />
      <FirstSection
        Test={Test}
        setTest={setTest}
        newTasks={newTasks}
        taskCount={taskCount}
        setPreview={setPreview}
        color={color}
        setTaskCount={setTaskCount}
        isNonMobile1024={isNonMobile1024}
        isNonMobile768={isNonMobile768}
        taskCounter={taskCounter}
        referenceDiv2={referenceDiv2}
      />
    </div>
  );
};

export default PreviewWhole;
