import React, { useState, useEffect } from "react";
import axios from "axios";
import "./testing.css";
import emailjs from "@emailjs/browser";
const Testing = () => {
  const maleNames = [
    "Daan de Jong",
    "Bram Jansen",
    "Levi Bakker",
    "Finn Visser",
    "Sem Smit",
    "Lucas Mulder",
    "Luuk Bos",
    "Milan Dekker",
    "Jesse van Dijk",
    "Lars Meijer",
    "Julian de Vries",
    "Sam de Boer",
    "Ruben Timmermans",
    "Thijs Peters",
    "Mees van den Berg",
  ];

  const femaleNames = [
    "Emma de Graaf",
    "Julia Hendriks",
    "Mila de Bruin",
    "Sophie van Leeuwen",
    "Tess de Lange",
    "Zoë van Dam",
    "Sara van der Meer",
    "Eva Koster",
    "Anna van der Heijden",
    "Lotte Wolters",
    "Noor Martens",
    "Liv van Loon",
    "Fenna Maas",
    "Isa Huisman",
    "Saar van Vliet",
  ];

  const tagline = [
    "Student Ondernemerschap & Retail Management",
    "Working Student @ Personio | M.Sc. Culture, Organization & Management",
    "Aspiring Business Leader | Business Administration Student | Navigating the Corporate World with Passion and Purpose",
    "Engineering Enthusiast | Mechanical Engineering Student | Designing Innovative Solutions for Tomorrow's Challenges 🛫🎓",
    "Dedicated Business Analyst 🌍 | Business Management Student 📝 | Transforming Ideas into Strategic Actions",
    "Business Engineering Student - Final Year",
    "2024 Rangel Fellow | BSc International Relations and Chinese",
    "Financial Auditor | EY The Netherlands. European Postmaster in Accountancy candidate | MSc Accounting, Auditing, & Control",
  ];
  const Countries = [
    { name: "Germany" },
    { name: "United Kingdom" },
    { name: "Netherlands" },
  ];

  const Skills = [
    { points: 20, skill: "Analytical Thinking" },
    { points: 20, skill: "Critical Thinking" },
    { points: 20, skill: "Cross-team Collaboration" },
    { points: 20, skill: "Relationship Building" },
    { points: 20, skill: "Teamwork" },
    { points: 20, skill: "Written Communication" },
    // Add more skill objects here if needed
  ];

  const fetchData = async (country) => {
    if (country) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/get/university`,
          {
            name: country.name,
          }
        );
        if (response.data.msg === "success") {
          return response.data.university;
        } else {
          console.log(response.data);
        }
      } catch (err) {
        console.log(err.message);
      }
    }
    return [];
  };

  const generateRandomUni = async () => {
    let uni = {};
    try {
      let int = Math.floor(Math.random() * Countries.length);
      let unilist = await fetchData(Countries[int]);
      let num = Math.floor(Math.random() * unilist.length);
      uni = unilist[num] || {};
    } catch (error) {
      console.error("Error generating random university:", error);
    }
    return uni;
  };

  const generateRandomNumber = () => Math.floor(Math.random() * 38);

  const generateRandomMaleName = () =>
    maleNames[generateRandomNumber() % maleNames.length];
  const generateRandomFemaleName = () =>
    femaleNames[generateRandomNumber() % femaleNames.length];

  const generateRandomEmail = () => {
    const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
    let email = "";
    for (let i = 0; i < 8; i++) {
      email += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    email += "@"; // Set a default domain for email
    return email;
  };

  const study = "Engineering";
  const levelofstudylist = [
    "First Year",
    "2nd Year",
    "3rd Year",
    "Bachelor",
    "Masters Program",
    "PHD",
    "State Examination",
    "MBA",
    "Doctorate",
    "Postdoctoral Studies",
    "Other",
  ];

  const options = [
    "Humanity",
    "Business",
    "Law",
    "Engineering",
    "MINT",
    "Social Sciences",
    "Psychology",
    "Political Sciences",

    "Others",
  ];
  const Months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const selectedItem = [
    {
      icon: "/static/media/Brand.e87190bdf796ef2baad4.png",
      id: "Brand Management",
      select: false,
      text: "Brand Management",
    },
    {
      icon: "/static/media/Pharma.7b5ef2c064eb982f19f3.png",
      id: "Pharma",
      select: false,
      text: "Pharma",
    },
  ];
  const Experience = [
    {
      logo: "http://localhost:5000/uploads/e63f362e-3d34-4fd9-91db-6d80ca0b8123.png",
      position: "Junior Trainee Audit - Amstelveen / Utrecht / Alkmaar",
      from: "2023",
      to: "2023",
      location: {
        name: "Netherlands",
        isoCode: "NL",
        flag: "🇳🇱",
        phonecode: "31",
        currency: "EUR",
        latitude: "52.50000000",
        longitude: "5.75000000",
      },
      company: "KPGM",
      description: `Key Responsibilities:

      Assisted in performing financial audits and assessments for diverse clients across multiple sectors.
      Conducted detailed financial analysis and verification of financial statements to ensure compliance with regulatory standards.
      Supported senior auditors in the preparation of audit reports and documentation.
      
      Achievements:
      
      Successfully contributed to multiple audit engagements, ensuring timely and accurate completion of audit tasks.
      Recognized for strong analytical skills and attention to detail, which significantly improved the accuracy of financial reporting.`,
    },
  ];

  const currentYear = new Date().getFullYear();
  const Years = [
    `${Array.from(
      { length: currentYear - 2013 },
      (_, index) => `${2014 + index}`
    )}`,
  ];

  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchData = async (gender, names, imageUrls) => {
      const userData = [];

      for (let i = 0; i < imageUrls.length; i++) {
        const name =
          gender === "Male"
            ? i <= 14
              ? names[Math.floor(Math.random() * names.length)]
              : i === 15
              ? "Olumide Adebayo"
              : i === 16
              ? "Adeola Balogun"
              : "Li Wei"
            : i <= 14
            ? names[Math.floor(Math.random() * names.length)]
            : i === 15
            ? "Amara Nwosu"
            : i === 16
            ? "Ji-woo Park"
            : "Li Wei";

        let uni = await generateRandomUni();
        while (!uni?.domain) {
          uni = await generateRandomUni();
        }
        let email = generateRandomEmail();
        email += uni?.domain;

        const levelofstudy =
          levelofstudylist[Math.floor(Math.random() * levelofstudylist.length)];
        const study = options[Math.floor(Math.random() * options.length)];
        const password = "afdsfasfsdf";

        const graduation = {
          Month: Months[Math.floor(Math.random() * Months.length)],
          Year: 2023,
        };
        let intake = {
          Year: 2019,
          Month: Months[Math.floor(Math.random() * Months.length)],
        };

        const fullName = name;
        const splitName = fullName.split(" ");
        const firstName = splitName[0];
        const lastName = splitName.slice(1).join(" ");

        const Education = [
          {
            logo: uni.logo,
            degree: study,
            from: intake.Year,
            to: graduation.Year,
            location: {
              name: uni.location,
              flag: "",
            },
            schoolName: uni.name,
            description: `Studying ${study} at ${uni.name} has been transformative. The rigorous curriculum and supportive faculty have enriched my academic journey. Beyond the classroom, diverse extracurricular activities and a vibrant campus community have broadened my horizons. The holistic support services ensure overall well-being, making my time here both rewarding and fulfilling.`,
          },
        ];
        userData.push({
          firstName,
          lastName,
          EmailAddress: email,
          gender,
          profilePhoto: imageUrls[i],
          uni,
          levelofstudy,
          Skills,
          selectedItem,
          study,
          about: tagline[Math.floor(Math.random() * tagline.length)],
          education: Education,
          password,
          graduation,
          experience: Experience,
          intake,
        });
      }

      return userData;
    };

    const maleImageUrls = Array.from(
      { length: 15 },
      (_, index) =>
        `${process.env.REACT_APP_BACKEND_URL}/uploads/malepics/${index + 1}.png`
    );
    maleImageUrls.push(
      `${process.env.REACT_APP_BACKEND_URL}/uploads/malepics/${16}.png`
    );
    maleImageUrls.push(
      `${process.env.REACT_APP_BACKEND_URL}/uploads/malepics/${17}.png`
    );
    maleImageUrls.push(
      `${process.env.REACT_APP_BACKEND_URL}/uploads/malepics/${18}.png`
    );

    const femaleImageUrls = Array.from(
      { length: 15 },
      (_, index) =>
        `${process.env.REACT_APP_BACKEND_URL}/uploads/femalepics/${
          index + 1
        }.png`
    );
    femaleImageUrls.push(
      `${process.env.REACT_APP_BACKEND_URL}/uploads/femalepics/${16}.png`
    );
    femaleImageUrls.push(
      `${process.env.REACT_APP_BACKEND_URL}/uploads/femalepics/${17}.png`
    );
    const maleUsersData = fetchData("Male", maleNames, maleImageUrls);
    const femaleUsersData = fetchData("Female", femaleNames, femaleImageUrls);

    Promise.all([maleUsersData, femaleUsersData]).then((values) => {
      const allUsersData = [...values[0], ...values[1]];
      setUsers(allUsersData);
    });
  }, []);

  const sendDataToBackend = async () => {
    await axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/Test/users`, { users })
      .then((response) => {
        console.log("Data sent successfully:", response.data.uniqueIds);
      })
      .catch((error) => {
        console.error("Error sending data:", error);
      });
  };

  const sendEmail = () => {
    const payload = {
      code: `${process.env.REACT_APP_FRONTEND_URL}/Find/afdsfafa`,
      to_email: "ahayee589@gmail.com",
      from_name: "Hayee",
      name: "BADSA",
      company_name: "varyance",
    };

    emailjs
      .send(
        "service_08a640e",
        "template_ararw0s",

        "FCKBh2iU92kGQhhAA"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <>
      <div className="d-flex flex-wrap" style={{ gap: "1rem" }}>
        {console.log(users)}
        {/* {users.map((user, index) => (
          <div key={index} className="d-flex flex-column">
            <img
              src={user.profilePhoto}
              height="100px"
              width="100px"
              className="scale shadowa rounded-circle"
              alt={`${user.gender} user ${index}`}
            />
            <p className="black12">
              {user.firstName} {user.lastName}
            </p>
            <p className="black12">{user.EmailAddress}</p>
            <p className="black12">{user?.uni?.name}</p>
          </div>
        ))} */}
      </div>

      <button onClick={sendDataToBackend}>Send Data to Backend</button>
    </>
  );
};

export default Testing;
