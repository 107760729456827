import { useSelector, useDispatch } from "react-redux";
import { setDemo } from "../../state";
import { useState, useRef, useEffect } from "react";
import logout from "../../assets/images/logout.png";
import clock from "../../assets/images/clock.png";
import dart from "../../assets/images/dart.png";
import deleteicon from "../../assets/images/delete.png";
import {
  setCompany,
  setEditChallenge,
  setEditJob,
  setEmployer,
  setId,
  setPreview,
} from "../../state/index";
import axios from "axios";

const NewHeader = ({
  text,
  navigate,
  isNonMobile1024,
  isNonMobile1450,
  user,
  isNonMobile1200,
  isClose,
  isNonMobile350,
  company,
  path,
}) => {
  const dispatch = useDispatch();
  const demo = useSelector((state) => state?.demo);
  const [profilePopup, setProfilePopup] = useState(false);
  const [demopopup, setDemoPopUp] = useState(false);
  const iconRef = useRef(null);
  const iconRef2 = useRef(null);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });

    function handleClickOutside(event) {
      if (iconRef.current && !iconRef.current.contains(event.target)) {
        setProfilePopup(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });

    function handleClickOutside(event) {
      if (iconRef2.current && !iconRef2.current.contains(event.target)) {
        setDemoPopUp(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const fetchData = async (add) => {
    if (company && user) {
      try {
        await axios
          .post(
            `${process.env.REACT_APP_BACKEND_URL}/dummy/${
              add ? "add" : "delete"
            }`,
            {
              company,
              user,
            }
          )
          .then((res) => {
            if (res.data.msg === "success") {
              window.location.reload();
            }
            console.log(res.data);
          })
          .catch((err) => {
            console.log(err.message);
          });
      } catch (err) {
        console.log(err.message);
      }
    }
  };

  const handleSignOut = () => {
    dispatch(setId({ uniqueId: null }));
    dispatch(setCompany({ company: null }));
    dispatch(setEmployer({ employer: null }));
    dispatch(setPreview({ preview: null }));
    dispatch(setEditChallenge({ editChallenge: null }));
    dispatch(setEditJob({ editJob: null }));
  };

  const popupref = useRef(null);
  const [popup, setPopup] = useState(false);

  useEffect(() => {
    function handleClickOutside(event) {
      if (popupref.current && !popupref.current.contains(event.target)) {
        setPopup(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      {demopopup && (
        <div
          className="center zindex1000"
          style={{
            width: "100%",
            height: "100%",

            position: "fixed",
            alignItems: "center",
            top: 0,
            left: 0,
            right: 0,
            zIndex: 11,
            backgroundColor: "rgba(45, 50, 130, 0.30)",
            backdropFilter: "blur(3px)",
            padding: "1rem",
          }}
        >
          <div
            className="shadowa newPopUpContainer"
            ref={popupref}
            style={{
              // maxWidth: "90vw",
              maxHeight: "80vh",

              maxWidth: "700px",
              backgroundColor: "",
              padding: "1.5rem",
            }}
          >
            {demo ? (
              <>
                <div className="d-flex " style={{ gap: "1rem" }}>
                  <div className="circle-background">
                    <img
                      src={deleteicon}
                      alt="delete"
                      height="48px"
                      width="48px"
                    />
                  </div>
                  <div>
                    <p className="gray90018500">Delete Demo Data</p>
                    <p className="gray70014400">
                      Are you sure you want to delete Demo Data?
                    </p>
                  </div>
                </div>
                <div
                  className="d-flex justify-content-end"
                  style={{ marginTop: "2rem", gap: "0.75rem" }}
                >
                  <button
                    className="backbutton"
                    onClick={() => {
                      setDemoPopUp(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="continuebutton"
                    onClick={() => {
                      dispatch(setDemo({ demo: false }));
                      fetchData(false);
                      setDemoPopUp(false);
                    }}
                  >
                    Delete
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="d-flex " style={{ gap: "1rem" }}>
                  <div className="circle-background">
                    <img src={dart} alt="delete" height="48px" width="48px" />
                  </div>
                  <div>
                    <p className="gray90018500">Demo Data</p>
                    <p className="gray70014400">
                      Demo data has been uploaded to help you explore our
                      features.
                      <br /> You can delete it when you're ready to start.
                    </p>
                  </div>
                </div>
                <div
                  className="d-flex justify-content-end"
                  style={{ marginTop: "2rem", gap: "0.75rem" }}
                >
                  <button
                    className="backbutton"
                    onClick={() => {
                      setDemoPopUp(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="continuebutton"
                    onClick={() => {
                      dispatch(setDemo({ demo: true }));
                      fetchData(true);
                      setDemoPopUp(false);
                    }}
                  >
                    Explore Demo Data
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
      {popup && (
        <div
          className="center zindex1000"
          style={{
            width: "100%",
            height: "100%",

            position: "fixed",
            alignItems: "center",
            top: 0,
            left: 0,
            right: 0,
            zIndex: 11,
            backgroundColor: "rgba(45, 50, 130, 0.30)",
            backdropFilter: "blur(3px)",
            padding: "1rem",
          }}
        >
          <div
            className="shadowa newPopUpContainer"
            ref={popupref}
            style={{
              // maxWidth: "90vw",
              maxHeight: "80vh",

              maxWidth: "700px",
              backgroundColor: "",
              padding: "1.5rem",
            }}
          >
            <div className="d-flex " style={{ gap: "1rem" }}>
              <div className="circle-background">
                <img src={logout} alt="delete" height="48px" width="48px" />
              </div>
              <div>
                <p className="gray90018500">Logout</p>
                <p className="gray70014400">
                  Are you sure want logout your account? <br />
                  Hope to see you soon!
                </p>
              </div>
            </div>
            <div
              className="d-flex justify-content-end"
              style={{ marginTop: "2rem", gap: "0.75rem" }}
            >
              <button
                className="backbutton"
                onClick={() => {
                  setPopup(false);
                }}
              >
                Cancel
              </button>
              <button
                className="continuebutton"
                onClick={() => {
                  handleSignOut();
                  setPopup(false);
                }}
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      )}
      <div
        className={`companydashboard__header ${
          isNonMobile1200 && !isClose ? "open" : "close"
        }  `}
        style={{
          zIndex: profilePopup ? 5 : "",
          maxHeight: profilePopup ? "65px" : "",
        }}
      >
        <div className="space align-items-center">
          <p className="headerToptext">
            {text === "Progress"
              ? `Hi, ${user?.firstName}. Let’s start!`
              : path === "/dashboard/events/details"
              ? "Event Details"
              : path === "/dashboard/challenge/details"
              ? "Challenge Details"
              : path.includes("/dashboard/job/details")
              ? "Job Details"
              : text}
          </p>
          <div className="d-flex align-items-center" style={{ gap: "0.75rem" }}>
            {company && user && company.createdBy === user?.email && (
              <button
                className={`   supportbtnHeader align-items-center`}
                onClick={() => {
                  // dispatch(setDemo({ demo: !demo }));
                  setDemoPopUp(true);
                }}
              >
                <img src={dart} alt="dart" height="20px" width="20px" />
                {demo ? "Delete Demo Data" : "Demo Data"}
              </button>
            )}
            <button
              className={`   supportbtnHeader align-items-center`}
              onClick={() => {
                navigate("/dashboard/book-tour");
              }}
            >
              <img src={clock} alt="dart" height="20px" width="20px" /> Book
              Tour
            </button>
            <div
              className="hoverredsvg"
              onClick={() => {
                navigate("/dashboard/chat");
              }}
              style={{ cursor: "pointer", padding: "8px" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5.36887 1.66699H14.6355C16.7465 1.73944 18.4011 3.50583 18.3355 5.61699V11.0503C18.3669 12.0699 17.9906 13.06 17.2899 13.8013C16.5892 14.5427 15.622 14.9742 14.6022 15.0003H6.66887C6.11986 15.0117 5.59922 15.2464 5.22721 15.6503L3.25221 17.7587C3.08488 17.9411 2.84977 18.0466 2.60221 18.0503C2.34652 18.0438 2.10405 17.9354 1.92876 17.7491C1.75346 17.5629 1.65989 17.3143 1.66887 17.0587V5.61699C1.60335 3.50583 3.25793 1.73944 5.36887 1.66699ZM14.6355 13.7503C16.0563 13.6784 17.1516 12.4714 17.0855 11.0503V5.61699C17.1516 4.19593 16.0563 2.98888 14.6355 2.91699H5.36887C3.94809 2.98888 2.85281 4.19593 2.91887 5.61699V16.2837L4.2772 14.8003C4.89839 14.1395 5.762 13.7604 6.66887 13.7503H14.6355Z"
                  fill={path === "/dashboard/chat" ? "#E31B54" : "#344054"}
                />
                <path
                  d="M6.8772 7.29199H11.0439C11.389 7.29199 11.6689 7.01217 11.6689 6.66699C11.6689 6.32181 11.389 6.04199 11.0439 6.04199H6.8772C6.53203 6.04199 6.2522 6.32181 6.2522 6.66699C6.2522 7.01217 6.53203 7.29199 6.8772 7.29199Z"
                  fill={path === "/dashboard/chat" ? "#E31B54" : "#344054"}
                />
                <path
                  d="M13.1272 9.37533H6.8772C6.53203 9.37533 6.2522 9.65515 6.2522 10.0003C6.2522 10.3455 6.53203 10.6253 6.8772 10.6253H13.1272C13.4724 10.6253 13.7522 10.3455 13.7522 10.0003C13.7522 9.65515 13.4724 9.37533 13.1272 9.37533Z"
                  fill={path === "/dashboard/chat" ? "#E31B54" : "#344054"}
                />
              </svg>
            </div>
            {/* <button
            className={`   supportbtnHeader `}
            onClick={() => navigate("/support")}
          >
            <InfoOutlinedIcon /> {isNonMobile1450 && "Support"}
          </button> */}
            <img
              src={user?.profilephoto}
              alt="profilepic"
              width={"36px"}
              height={"36px"}
              style={{ cursor: "pointer" }}
              className="companyheader__profilepic"
              onClick={() => setProfilePopup(true)}
            />
            {profilePopup && (
              <div
                ref={iconRef}
                className="innerglass shadowa "
                style={{
                  position: "absolute",
                  right: "1.875rem",
                  top: "4rem",
                  // maxWidth: "314px",
                  zIndex: 19,

                  minWidth: isNonMobile350 ? "314px" : "90vw",
                  padding: "1rem",
                  maxWidth: "90vw",
                }}
              >
                <div className="d-flex" style={{ gap: "1rem" }}>
                  {user.profilephoto ? (
                    <img
                      src={user.profilephoto}
                      alt="User Profile"
                      className="userimage shadowa mt-0 mb-0 border border-2 border-light"
                      style={{
                        height: "45px",
                        width: "45px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigate(`/dashboard/u/${user?.url}`);
                        setProfilePopup(false);
                      }}
                    />
                  ) : (
                    <div>{"\u00A0"}</div>
                  )}

                  <div>
                    <p
                      className="black14 hoverdarkred"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate(`/dashboard/u/${user?.url}`);
                        setProfilePopup(false);
                      }}
                    >
                      {user?.firstName} {user?.lastName}
                    </p>
                    <p
                      className="gray12"
                      style={{ marginTop: "0.5rem", maxWidth: "220px" }}
                    >
                      {user?.tagline}
                    </p>
                  </div>
                </div>
                <hr style={{ marginTop: "1rem", marginBottom: "0.5rem" }} />
                <p className="CompanyDashboardSidebarToolstext">Tools</p>
                <div className="d-flex flex-column">
                  <button
                    className=" buttonblack12"
                    style={{ marginTop: "0.5rem" }}
                    onClick={() => {
                      navigate("/dashboard/Team");
                      setProfilePopup(false);
                    }}
                  >
                    Invite Team
                  </button>
                  <button
                    className="buttonblack12"
                    style={{ marginTop: "0.75rem" }}
                    onClick={() => {
                      navigate("/dashboard/settings");
                      setProfilePopup(false);
                    }}
                  >
                    Settings
                  </button>
                </div>
                <hr style={{ marginTop: "1rem", marginBottom: "1rem" }} />
                <div className="d-flex" style={{ gap: "1rem" }}>
                  {company.img && (
                    <img
                      src={company.img}
                      alt="User Profile"
                      className="rounded-2 shadowa mt-0 mb-0 border border-2 border-light"
                      style={{
                        height: "45px",
                        width: "45px",
                        cursor: "pointer",
                      }}
                      onClick={() => setProfilePopup(true)}
                    />
                  )}
                  <div>
                    <p className="black14" style={{ color: "#101828" }}>
                      {company.title}
                    </p>
                    <button
                      className=" pink12 p-0 text-start hovercolorback"
                      style={{ background: "transparent", border: "none" }}
                      onClick={() =>
                        navigate(`/dashboard/company/${company?.link}`)
                      }
                    >
                      View Company Page
                    </button>
                  </div>
                </div>
                <hr style={{ marginTop: "1rem", marginBottom: "1rem" }} />
                <button
                  className="buttonblack12"
                  onClick={() => setPopup(true)}
                >
                  Signout
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default NewHeader;
