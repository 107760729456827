import logo from "../../../assets/images/logo.png";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const MobileHeader = ({ navigate, user }) => {
  return (
    <div
      className="space shadowa"
      style={{
        padding: "6px 20px",
        zIndex: 500,
        position: "sticky",
        top: "0",
        width: "100vw",
        background: "#FFF",
      }}
    >
      <img
        src={logo}
        alt="logo"
        height={`36px`}
        width={`36px`}
        onClick={() => navigate("/")}
        style={{ cursor: "pointer" }}
      />
      <div className="d-flex align-items-center" style={{ gap: "0.75rem" }}>
        <div className="d-flex align-items-center">
          <div className="btn" style={{ padding: "0.5rem" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
            >
              <path
                d="M17.5 18.2812L14.5834 15.3646M16.6667 10.3646C16.6667 14.2766 13.4954 17.4479 9.58333 17.4479C5.67132 17.4479 2.5 14.2766 2.5 10.3646C2.5 6.45257 5.67132 3.28125 9.58333 3.28125C13.4954 3.28125 16.6667 6.45257 16.6667 10.3646Z"
                stroke="#344054"
                strokeWidth="1.36482"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>

          <div className="btn" style={{ padding: "0.5rem" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.36887 1.66699H14.6355C16.7465 1.73944 18.4011 3.50583 18.3355 5.61699V11.0503C18.3669 12.0699 17.9906 13.06 17.2899 13.8013C16.5892 14.5427 15.622 14.9742 14.6022 15.0003H6.66887C6.11986 15.0117 5.59922 15.2464 5.22721 15.6503L3.25221 17.7587C3.08488 17.9411 2.84977 18.0466 2.60221 18.0503C2.34652 18.0438 2.10405 17.9354 1.92876 17.7491C1.75346 17.5629 1.65989 17.3143 1.66887 17.0587V5.61699C1.60335 3.50583 3.25793 1.73944 5.36887 1.66699ZM14.6355 13.7503C16.0563 13.6784 17.1516 12.4714 17.0855 11.0503V5.61699C17.1516 4.19593 16.0563 2.98888 14.6355 2.91699H5.36887C3.94809 2.98888 2.85281 4.19593 2.91887 5.61699V16.2837L4.2772 14.8003C4.89839 14.1395 5.762 13.7604 6.66887 13.7503H14.6355Z"
                fill="#344054"
              />
              <path
                d="M6.8772 7.29199H11.0439C11.389 7.29199 11.6689 7.01217 11.6689 6.66699C11.6689 6.32181 11.389 6.04199 11.0439 6.04199H6.8772C6.53203 6.04199 6.2522 6.32181 6.2522 6.66699C6.2522 7.01217 6.53203 7.29199 6.8772 7.29199Z"
                fill="#344054"
              />
              <path
                d="M13.1272 9.37533H6.8772C6.53203 9.37533 6.2522 9.65515 6.2522 10.0003C6.2522 10.3455 6.53203 10.6253 6.8772 10.6253H13.1272C13.4724 10.6253 13.7522 10.3455 13.7522 10.0003C13.7522 9.65515 13.4724 9.37533 13.1272 9.37533Z"
                fill="#344054"
              />
            </svg>
          </div>
          <button
            className={`    btn `}
            onClick={() => navigate("/support")}
            style={{ padding: "0.5rem" }}
          >
            <InfoOutlinedIcon />
          </button>
        </div>
        <div>
          <img
            src={user?.profilePhoto}
            alt="profilepic"
            width={"36px"}
            height={"36px"}
            className="companyheader__profilepic"
          />
        </div>
      </div>
    </div>
  );
};
export default MobileHeader;
