import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";
import axios from "axios";
import CoverPhoto from "../StudentProfilePage/backgroundImg";
import SecondSection from "../StudentProfilePage/secondSection";
const UserProfile = () => {
  const { uniqueId } = useParams();
  const watcher = useSelector((state) => state.user);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const challenges = [];
  const AllChallenges = [];
  const jobposts = [];
  const isNonMobile1024 = useMediaQuery("(min-width:1024px)");
  const isNonMobile450 = useMediaQuery("(min-width:450px)");
  const [similarUsers, setSimilarUsers] = useState([]);
  const [filter, setFilter] = useState(false);
  useEffect(() => {
    if (!watcher || !uniqueId) {
      navigate(-1);
    }
  }, []);

  useEffect(() => {
    if (similarUsers && similarUsers.length > 0) {
      setSimilarUsers(similarUsers.filter((sim) => sim._id !== watcher._id));
      setFilter(false);
    }
  }, [filter]);

  useEffect(() => {
    const fetchData = async () => {
      if (uniqueId) {
        const userResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/get/user/details/uniqueId`,
          { userId: uniqueId, msg: "fjksabnfjkbnasjfbajsbfjasjhfbjahsbjkfbk" }
        );

        if (userResponse.data.message !== "Found") {
          navigate(-1);
          return;
        }

        setUser(userResponse.data.user);

        const similar = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/get/similar/users`,
          {
            Skills: userResponse.data.user.Skills,
            msg: "fjksabnfjkbnasjfbajsbfjasjhfbjahsbjkfbk",
            userId: userResponse.data.user._id,
          }
        );
        if (similar.data.msg === "Found") {
          setSimilarUsers(similar.data.users);
          setFilter(true);
        }
      }
    };
    fetchData();
  }, [uniqueId]);

  const test = true;

  const owner = watcher.uniqueId === uniqueId ? true : false;

  return (
    <div className="pb-5">
      <CoverPhoto user={user} />
      <div
        style={{
          marginLeft: isNonMobile450 ? "1.875rem" : "1.5rem",
          marginRight: isNonMobile450 ? "1.875rem" : "1.5rem",
        }}
      >
        <SecondSection
          user={user}
          owner={owner}
          isNonMobile450={isNonMobile450}
          isNonMobile1024={isNonMobile1024}
          challenges={challenges}
          watcher={watcher}
          navigate={navigate}
          AllChallenges={AllChallenges}
          jobposts={jobposts}
          similarUsers={similarUsers}
          test={test}
        />
      </div>
    </div>
  );
};

export default UserProfile;
