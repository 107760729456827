import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Adduser from "../../assets/images/icons/adduser";
import { toast } from "react-toastify";
import axios from "axios";
const ChatTop = ({
  otheruser,
  navigate,
  user,
  chatmessages,
  setTest2,
  handleShortList,
}) => {
  const buttons = [
    {
      text: "Archive",
      function: async () => {
        try {
          await axios
            .post(`${process.env.REACT_APP_BACKEND_URL}/api/archieve/message`, {
              selectedChats: [chatmessages?._id],
              userId: user?._id,
            })
            .then((res) => {
              if (res.data.msg === "success") {
                setTest2((prev) => !prev);

                toast.success("Updated Successfully");
              }
            })
            .catch((err) => {
              console.log(err.message);
            });
        } catch (err) {
          console.log(err.message);
        }
      },
    },
    {
      text: "Unread",
      function: async () => {
        try {
          await axios
            .post(`${process.env.REACT_APP_BACKEND_URL}/api/unread/message`, {
              selectedChats: [chatmessages?._id],
              userId: user?._id,
            })
            .then((res) => {
              if (res.data.msg === "success") {
                setTest2((prev) => !prev);

                toast.success("Updated Successfully");
              }
            })
            .catch((err) => {
              console.log(err.message);
            });
        } catch (err) {
          console.log(err.message);
        }
      },
    },
    {
      text: "Starred",
      function: async () => {
        try {
          await axios
            .post(`${process.env.REACT_APP_BACKEND_URL}/api/star/message`, {
              selectedChats: [chatmessages?._id],
              userId: user?._id,
            })
            .then((res) => {
              if (res.data.msg === "success") {
                setTest2((prev) => !prev);

                toast.success("Updated Successfully");
              }
            })
            .catch((err) => {
              console.log(err.message);
            });
        } catch (err) {
          console.log(err.message);
        }
      },
    },
    {
      text: "Shortlist",
      icon: <Adduser />,
      function: async () => {
        handleShortList(otheruser?._id);
      },
    },
  ];

  return (
    <div
      className="chattop__wrapper flex-wrap testingshadow"
      style={{ gap: "1rem" }}
    >
      <div className="d-flex" style={{ gap: "1rem" }}>
        <ArrowBackIosNewIcon
          className="hoverred"
          style={{ height: "20px", width: "20px", cursor: "pointer" }}
          onClick={() => navigate("/dashboard/chat")}
        />
        <p className="black16 text-start">
          {otheruser?.firstName} {otheruser?.LastName}
        </p>
      </div>
      <div
        className="d-flex align-items-center flex-wrap"
        style={{ gap: "0.75rem" }}
      >
        {buttons.map((button, index) => (
          <button
            className="backbutton p-2 hoverred hoverredsvg"
            style={{ gap: "8px" }}
            key={index}
            onClick={() => button?.function()}
          >
            {button?.icon}
            {button.text}
          </button>
        ))}
      </div>
    </div>
  );
};

export default ChatTop;
