import { useState } from "react";

const SecondHeader = ({ text, megaMenu, setMegaMenu }) => {
  const [buttons, setButtons] = useState([
    { text: "Overview", link: "/" },
    { text: "Solutions", link: "/" },
    { text: "Foster Opportunity DE&I", link: "/" },
  ]);
  const [selected, setSelected] = useState("Solutions");

  return (
    <div
      style={{
        borderBottom: "1px solid #EAECF0",
        background: "#FFF",
        position: "sticky",
        zIndex: 1,
        top: 0,
      }}
    >
      <div className="marginsleftrightCompany">
        <div className="center">
          <div
            className="headerCompany space align-items-center"
            style={{ padding: "0.5rem 0rem" }}
          >
            <p className="filmo16">{text}</p>
            <div className="d-flex align-items-center" style={{ gap: "2rem" }}>
              {buttons.map((item, index) => (
                <a
                  href={item.link}
                  key={index}
                  style={{ textDecoration: "none" }}
                >
                  <p
                    className={`${
                      selected === item.text ? "pink12" : "gray50012500"
                    }`}
                    style={{
                      fontSize: item.text === selected ? "" : "",
                      fontWeight: item.text === selected ? 500 : "",
                    }}
                    onMouseEnter={() => {
                      if (item.text === "Solutions") {
                        setMegaMenu(true);
                      }
                    }}
                    onMouseLeave={() => {
                      if (item.text === "Solutions") {
                        setMegaMenu(false);
                      }
                    }}
                  >
                    {item.text}
                  </p>
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecondHeader;
