import React, { useRef, useEffect } from "react";

const Step2 = ({ firstName, setFirstName }) => {
  const inputRef = useRef(null);
  useEffect(() => {
    // Focus on the input field when the component loads
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    const adjustFontSize = () => {
      const input = inputRef.current;

      if (input) {
        const maxWidth = input.clientWidth;
        let newSize = 58; // Set a default font size
        input.style.fontSize = `${newSize}px`;

        // Loop until the scroll width is less than or equal to the max width
        while (input.scrollWidth > maxWidth && newSize > 0) {
          newSize -= 1;
          input.style.fontSize = `${newSize}px`;
        }
      }
    };

    adjustFontSize();
  }, [firstName]);

  return (
    <div className="w-full">
      <p className="sectionheadingclamped" style={{ marginBottom: "1.5rem" }}>
        Your first name
      </p>
      <div
        className="center"
        style={{
          height: "100%",
          flexDirection: "column",
          position: "relative",
          height: "100%",
          width: "100%",
        }}
      >
        <input
          ref={inputRef}
          type="text"
          placeholder="John"
          className="Name_field mt-4"
          style={{ width: "80vw" }}
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
      </div>
    </div>
  );
};

export default Step2;
