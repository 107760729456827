import EditIcon from "@mui/icons-material/Edit";
const Awards = ({ owner, user, setAwardsPopup }) => {
  return (
    <div className=" shadowa">
      <div
        className="innerglass tablescroll"
        style={{ maxHeight: "240px", overflow: "auto", padding: "1.25rem" }}
      >
        <div className="space flex-wrap" style={{ gap: "1rem" }}>
          <p className="filmo20">Awards & Scholarships</p>
          {owner && (
            <EditIcon
              className="hoverred"
              style={{ marginTop: "0.25rem", width: "24px", cursor: "pointer" }}
              onClick={() => setAwardsPopup(true)}
            />
          )}
        </div>
        {user?.awards?.map((cert, index) => (
          <div key={index} style={{ marginTop: "1.5rem" }}>
            <div className="d-flex flex-wrap" style={{ gap: "1rem" }}>
              {cert?.logo && (
                <img
                  src={cert?.logo}
                  alt="university logo"
                  className="shadowa rounded-3"
                  style={{
                    maxWidth: "32px",
                    maxHeight: "32px",
                    padding: "2px",
                    background: "#FFF",
                  }}
                />
              )}
              <div>
                {cert?.title && (
                  <p className="black16 text-start">{cert?.title}</p>
                )}
                {cert?.companyName && (
                  <p className="gray14400" style={{ marginTop: "0.5rem" }}>
                    {cert?.companyName}
                  </p>
                )}
                {cert?.package && (
                  <p
                    className="gray14400"
                    style={{ fontWeight: "600", marginTop: "0.75rem" }}
                  >
                    {cert?.package}
                  </p>
                )}
                {cert?.issue && (
                  <p className="gray14400" style={{ marginTop: "0.75rem" }}>
                    Issued date: {cert?.issue}
                  </p>
                )}
                {cert?.description && (
                  <p className="gray14400" style={{ marginTop: "0.75rem" }}>
                    {cert?.description}
                  </p>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default Awards;
