import TuneIcon from "@mui/icons-material/Tune";
import { useEffect, useRef, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const Filter = ({
  bulkAction,
  setBulkAction,
  bulkOptions,
  handleBulkAction,
  searchFilter,
  setSearchFilter,
}) => {
  const [filterPopup, setFilterPopup] = useState(false);
  const [popup, setPopUp] = useState(false);
  const iconRef = useRef();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });

    function handleClickOutside(event) {
      if (iconRef.current && !iconRef.current.contains(event.target)) {
        setPopUp(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="space">
      <div className="d-flex flex-wrap" style={{ gap: "1.5rem" }}>
        <div>
          {" "}
          <div
            className="forminput space"
            style={{ cursor: "pointer", width: "190px" }}
            onClick={() => setPopUp(!popup)}
          >
            {bulkAction ? bulkAction : "Select Bulk Action"}
            <KeyboardArrowDownIcon />
          </div>
          {popup && (
            <div
              className="w-100"
              style={{ position: "relative", zIndex: 2 }}
              ref={iconRef}
            >
              <div className="w-100" style={{ position: "absolute" }}>
                <div
                  className="d-flex shadowa w-100"
                  style={{
                    flexDirection: "column",
                    background: "transparent",
                  }}
                >
                  {bulkOptions.map((option, index) => (
                    <button
                      className="d-flex popupbuttontext "
                      style={{
                        padding: "11px 1rem",
                        maxHeight: "2rem",
                        borderRadius:
                          index === 0
                            ? "5px 5px 0px 0px"
                            : index === bulkOptions.length - 1
                            ? "0px 0px 5px 5px"
                            : "0px",
                        gap: "10px",
                        background: "#FFF",
                        border: "none",
                        borderTop: index !== 0 ? "1px solid #DEE3E6" : "none",
                        width: "100%",
                        minHeight: "42px",
                      }}
                      onClick={() => {
                        setBulkAction(option);
                        setPopUp(false);
                      }}
                    >
                      {option}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>

        {/* <select
          className="forminput"
          onChange={(e) => setBulkAction(e.target.value)}
          value={bulkAction}
        >
          <option disabled value="" className="formtext">
            Select Bulk Action
          </option>
          {bulkOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select> */}
        <button
          className="continuebutton"
          disabled={!bulkAction}
          onClick={() => handleBulkAction()}
        >
          Apply
        </button>
      </div>
      <div className="d-flex flex-wrap" style={{ gap: "1rem" }}>
        <div className="forminput">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M19 19L15.5001 15.5M18 9.5C18 14.1944 14.1944 18 9.5 18C4.80558 18 1 14.1944 1 9.5C1 4.80558 4.80558 1 9.5 1C14.1944 1 18 4.80558 18 9.5Z"
              stroke="#475467"
              strokeWidth="1.70603"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <input
            value={searchFilter}
            onChange={(e) => setSearchFilter(e.target.value)}
            type="search"
            placeholder="Search"
            className="searchinputuni"
          />
        </div>
        {/* <button
          className="newSupportButton"
          style={{ background: "transparent" }}
          onClick={() => setFilterPopup(!filterPopup)}
        >
          Filter
          <TuneIcon />
        </button> */}
      </div>
    </div>
  );
};
export default Filter;
