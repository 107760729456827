import sandclock from "../../../assets/images/sandclock.svg";
const Right = ({ step, title, position, company }) => {
  return (
    <div className=" ">
      <p className="nextstepsright">Next steps</p>
      <div className="d-flex " style={{ marginTop: "1.25rem", gap: "0.75rem" }}>
        <div
          className="d-flex"
          style={{ alignItems: "center", flexDirection: "column" }}
        >
          <div className=" rightsidesvgcontainer ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M13.1532 6.79102H9.06155C8.74859 6.79102 8.49488 6.53731 8.49488 6.22435C8.49488 5.91139 8.74859 5.65768 9.06155 5.65768H13.1532C13.4662 5.65768 13.7199 5.91139 13.7199 6.22435C13.7199 6.53731 13.4662 6.79102 13.1532 6.79102Z"
                fill={step >= 1 ? "#E31B54" : "#344054"}
              />
              <path
                d="M9.08655 10.5493H12.0032C12.3162 10.5493 12.5699 10.8031 12.5699 11.116C12.5699 11.429 12.3162 11.6827 12.0032 11.6827H9.08655C8.77359 11.6827 8.51988 11.429 8.51988 11.116C8.51988 10.8031 8.77359 10.5493 9.08655 10.5493Z"
                fill={step >= 1 ? "#E31B54" : "#344054"}
              />
              <path
                d="M14.3199 8.09935H9.06155C8.84114 8.07267 8.62507 8.17526 8.50643 8.36292C8.38779 8.55059 8.38779 8.78978 8.50643 8.97744C8.62507 9.1651 8.84114 9.26769 9.06155 9.24102H14.3199C14.6086 9.20607 14.8258 8.96102 14.8258 8.67018C14.8258 8.37934 14.6086 8.1343 14.3199 8.09935Z"
                fill={step >= 1 ? "#E31B54" : "#344054"}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.98655 1.66602H13.3865C15.5727 1.66602 17.3449 3.43822 17.3449 5.62435V11.7243C17.3602 13.3824 16.3384 14.8736 14.7865 15.4577C14.6268 17.1773 13.1802 18.4901 11.4532 18.4827H7.28655C5.10042 18.4827 3.32821 16.7105 3.32821 14.5243V7.67435C3.31597 5.97893 4.57833 4.54443 6.26155 4.34102C6.80761 2.74763 8.30221 1.67433 9.98655 1.66602ZM7.25321 17.1993H11.4199C12.3514 17.2 13.1692 16.5799 13.4199 15.6827H9.98655C7.80042 15.6827 6.02821 13.9105 6.02821 11.7243V5.65768C5.15018 5.91889 4.54732 6.72496 4.54488 7.64102V14.491C4.54488 15.9868 5.75744 17.1993 7.25321 17.1993ZM16.0949 11.7243C16.0949 13.2201 14.8823 14.4327 13.3865 14.4327H9.98655C8.49267 14.4281 7.28279 13.2182 7.27821 11.7243V5.62435C7.28279 4.13048 8.49267 2.92059 9.98655 2.91602H13.3865C14.8823 2.91602 16.0949 4.12858 16.0949 5.62435V11.7243Z"
                fill={step >= 1 ? "#E31B54" : "#344054"}
              />
            </svg>
          </div>
          <div
            className="rounded-5"
            style={{
              width: "2px",
              height: "20px",
              backgroundColor: "#E31B54",
              marginTop: "0.25rem",
              marginBottom: "0.25rem",
            }}
          ></div>
        </div>
        <div className=" ">
          <p className="rightheadingtext" style={{ color: "#E31B54" }}>
            Job Details
          </p>
          <p className="righttext" style={{ color: "#E31B54" }}>
            Add details about job post
          </p>
        </div>
      </div>
      <div className="d-flex " style={{ gap: "0.75rem" }}>
        <div
          className="d-flex"
          style={{ alignItems: "center", flexDirection: "column" }}
        >
          <div className=" rightsidesvgcontainer ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M7.20573 6.32435C6.95947 6.09488 6.57572 6.10165 6.33771 6.33966C6.0997 6.57767 6.09293 6.96142 6.3224 7.20768L9.4724 10.3577V14.266C9.4724 14.6112 9.75222 14.891 10.0974 14.891C10.4426 14.891 10.7224 14.6112 10.7224 14.266V10.0993C10.7222 9.93364 10.6563 9.77478 10.5391 9.65768L7.20573 6.32435Z"
                fill={step >= 2 ? "#E31B54" : "#344054"}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.33073 1.66602H11.8641C13.6322 1.66602 15.3279 2.36839 16.5781 3.61864C17.8284 4.86888 18.5307 6.56457 18.5307 8.33268V11.866C18.5307 15.5479 15.546 18.5327 11.8641 18.5327H8.33073C4.64883 18.5327 1.66406 15.5479 1.66406 11.866V8.33268C1.66406 4.65078 4.64883 1.66602 8.33073 1.66602ZM11.8641 17.2827C14.8537 17.2781 17.2761 14.8557 17.2807 11.866V8.33268C17.2761 5.34304 14.8537 2.9206 11.8641 2.91602H8.33073C5.34109 2.9206 2.91865 5.34304 2.91406 8.33268V11.866C2.91865 14.8557 5.34109 17.2781 8.33073 17.2827H11.8641Z"
                fill={step >= 2 ? "#E31B54" : "#344054"}
              />
            </svg>
          </div>
          <div
            className="rounded-5"
            style={{
              width: "2px",
              height: "20px",
              backgroundColor: step >= 2 ? "#E31B54" : "#344054",
              opacity: step >= 2 ? 1 : 0.5,
              marginTop: "0.25rem",
              marginBottom: "0.25rem",
            }}
          ></div>
        </div>
        <div className=" ">
          <p
            className="rightheadingtext"
            style={step >= 2 ? { color: "#E31B54" } : { color: "#344054" }}
          >
            Time Requirements
          </p>
          <p
            className="righttext"
            style={step >= 2 ? { color: "#E31B54" } : { color: "#344054" }}
          >
            Specify anticipated workload
          </p>
        </div>
      </div>
      <div className="d-flex " style={{ gap: "0.75rem" }}>
        <div
          className="d-flex"
          style={{ alignItems: "center", flexDirection: "column" }}
        >
          <div className="rightsidesvgcontainer ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M14.0141 12.9035C13.71 12.7507 13.3396 12.8692 13.1807 13.1702C13.0339 13.4767 13.1553 13.8445 13.4557 14.0035C14.1891 14.3785 14.6057 14.8368 14.6057 15.2785C14.6057 16.2202 12.6391 17.2702 10.0057 17.2702C7.3724 17.2702 5.3974 16.2285 5.3974 15.3118C5.3974 14.8785 5.81406 14.4118 6.5474 14.0368C6.76731 13.9487 6.91846 13.7437 6.93773 13.5075C6.95699 13.2714 6.84106 13.0447 6.63834 12.922C6.43563 12.7993 6.18098 12.8019 5.98073 12.9285C4.96898 13.3098 4.26363 14.2352 4.16406 15.3118C4.16406 17.1618 6.66406 18.5535 9.9974 18.5535C13.3307 18.5535 15.8307 17.1618 15.8307 15.3118C15.7404 14.2259 15.0334 13.2887 14.0141 12.9035Z"
                fill={step >= 3 ? "#E31B54" : "#344054"}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.3724 15.6785V12.0785C6.62814 11.7479 4.61617 9.33709 4.78178 6.57795C4.9474 3.81882 7.23329 1.66602 9.9974 1.66602C12.7615 1.66602 15.0474 3.81882 15.213 6.57795C15.3786 9.33709 13.3667 11.7479 10.6224 12.0785V15.6785C10.6224 16.0237 10.3426 16.3035 9.9974 16.3035C9.65222 16.3035 9.3724 16.0237 9.3724 15.6785ZM13.6842 5.36696C13.0656 3.87773 11.61 2.90846 9.9974 2.91183C7.80397 2.91642 6.02698 4.6934 6.0224 6.88683C6.01903 8.49945 6.9883 9.955 8.47752 10.5737C9.96675 11.1924 11.6821 10.8521 12.8224 9.7118C13.9627 8.5715 14.3029 6.85618 13.6842 5.36696Z"
                fill={step >= 3 ? "#E31B54" : "#344054"}
              />
              <path
                d="M7.98906 7.81183C8.33424 7.81183 8.61406 7.53201 8.61406 7.18683C8.61406 6.22954 9.3901 5.4535 10.3474 5.4535C10.6926 5.4535 10.9724 5.17368 10.9724 4.8285C10.9724 4.48332 10.6926 4.2035 10.3474 4.2035C9.55396 4.20571 8.79389 4.52302 8.23441 5.08563C7.67492 5.64824 7.36185 6.41006 7.36406 7.2035C7.36626 7.36706 7.43334 7.52304 7.55056 7.63713C7.66777 7.75121 7.82551 7.81406 7.98906 7.81183Z"
                fill={step >= 3 ? "#E31B54" : "#344054"}
              />
            </svg>
          </div>
          <div
            className="rounded-5"
            style={{
              width: "2px",
              height: "20px",
              backgroundColor: step >= 3 ? "#E31B54" : "#344054",
              opacity: step >= 3 ? 1 : 0.5,
              marginTop: "0.25rem",
              marginBottom: "0.25rem",
            }}
          ></div>
        </div>
        <div className="">
          <p
            className="rightheadingtext"
            style={step >= 3 ? { color: "#E31B54" } : { color: "#344054" }}
          >
            Job Location
          </p>
          <p
            className="righttext"
            style={step >= 3 ? { color: "#E31B54" } : { color: "#344054" }}
          >
            Specifiy job locations
          </p>
        </div>
      </div>
      <div className="d-flex " style={{ gap: "0.75rem" }}>
        <div
          className="d-flex"
          style={{ alignItems: "center", flexDirection: "column" }}
        >
          <div className="rightsidesvgcontainer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.4743 8.84827C12.2251 8.83023 12.8958 8.37423 13.1886 7.68271C13.2511 7.70906 13.3179 7.72359 13.3857 7.72556H16.8143C17.1693 7.72556 17.4571 7.43778 17.4571 7.08279C17.4571 6.7278 17.1693 6.44002 16.8143 6.44002H13.4286C13.3916 6.43517 13.3541 6.43517 13.3171 6.44002C13.0716 5.47552 12.1269 4.86086 11.1455 5.02708C10.1641 5.19331 9.47458 6.08478 9.56047 7.07631C9.64636 8.06785 10.4789 8.82751 11.4743 8.82256V8.84827ZM11.4743 6.32004C11.8199 6.32004 12.1 6.60014 12.1 6.94567C12.1 7.29119 11.8199 7.5713 11.4743 7.5713C11.1287 7.5713 10.8486 7.29119 10.8486 6.94567C10.8486 6.60014 11.1287 6.32004 11.4743 6.32004Z"
                fill={step >= 4 ? "#E31B54" : "#344054"}
              />
              <path
                d="M3.14286 6.44002H8.28572C8.64076 6.44002 8.92857 6.7278 8.92857 7.08279C8.92857 7.43778 8.64076 7.72556 8.28572 7.72556H3.14286C2.78782 7.72556 2.5 7.43778 2.5 7.08279C2.5 6.7278 2.78782 6.44002 3.14286 6.44002Z"
                fill={step >= 4 ? "#E31B54" : "#344054"}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.78 13.2962C5.04848 12.537 5.76599 12.0289 6.57143 12.0278V12.0107C7.5407 12.0095 8.35746 12.7338 8.47192 13.6962C8.58638 14.6585 7.96231 15.5542 7.01978 15.7803C6.07726 16.0064 5.11468 15.4913 4.78 14.5818H3.14286C2.78782 14.5818 2.5 14.294 2.5 13.939C2.5 13.584 2.78782 13.2962 3.14286 13.2962H4.78ZM5.94675 13.9841C5.95264 14.325 6.23044 14.5984 6.57143 14.5989V14.5646C6.917 14.5646 7.19714 14.2845 7.19714 13.939C7.17846 13.5986 6.89059 13.3358 6.54982 13.3481C6.20905 13.3604 5.94086 13.6432 5.94675 13.9841Z"
                fill={step >= 4 ? "#E31B54" : "#344054"}
              />
              <path
                d="M10 13.2962H16.8571C17.2122 13.2962 17.5 13.584 17.5 13.939C17.5 14.294 17.2122 14.5818 16.8571 14.5818H10C9.64496 14.5818 9.35714 14.294 9.35714 13.939C9.35714 13.584 9.64496 13.2962 10 13.2962Z"
                fill={step >= 4 ? "#E31B54" : "#344054"}
              />
            </svg>
          </div>
          <div
            className="rounded-5"
            style={{
              width: "2px",
              height: "20px",
              backgroundColor: step >= 4 ? "#E31B54" : "#344054",
              opacity: step >= 4 ? 1 : 0.5,
              marginTop: "0.25rem",
              marginBottom: "0.25rem",
            }}
          ></div>
        </div>
        <div className="">
          <p
            className="rightheadingtext"
            style={step >= 4 ? { color: "#E31B54" } : { color: "#344054" }}
          >
            Categorize job post
          </p>
          <p
            className="righttext"
            style={step >= 4 ? { color: "#E31B54" } : { color: "#344054" }}
          >
            Add filters and requirements
          </p>
        </div>
      </div>
      <div className="d-flex " style={{ gap: "0.75rem" }}>
        <div className="rightsidesvgcontainer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.48053 9.51706C5.99156 9.74539 6.41118 10.1386 6.6722 10.6337L8.8722 14.6421C9.03688 14.9402 9.12023 15.2765 9.11386 15.6171L9.0722 17.2837C9.07084 17.6277 8.88677 17.9451 8.58886 18.1171C8.45283 18.189 8.3011 18.2262 8.1472 18.2254C7.96173 18.2246 7.78007 18.1727 7.6222 18.0754L6.2222 17.2421C5.93135 17.0625 5.69283 16.8096 5.53053 16.5087L3.33886 12.5004C3.06274 11.9884 2.96871 11.3978 3.0722 10.8254C3.13583 10.285 3.4427 9.80319 3.90553 9.51706C4.40149 9.27009 4.98458 9.27009 5.48053 9.51706ZM6.86386 16.1337L7.83053 16.7171L7.8222 15.5671C7.81937 15.4448 7.78798 15.325 7.73053 15.2171L5.53886 11.2087C5.41134 10.9564 5.20306 10.754 4.9472 10.6337C4.86173 10.591 4.76772 10.5683 4.6722 10.5671C4.60252 10.5677 4.53399 10.5848 4.4722 10.6171C4.33405 10.7096 4.24521 10.8597 4.23053 11.0254C4.19245 11.314 4.25106 11.607 4.3972 11.8587L6.58886 15.8337C6.64793 15.9593 6.74386 16.064 6.86386 16.1337Z"
              fill={step >= 5 ? "#E31B54" : "#344054"}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.0221 1.95872L16.8555 7.79206C16.979 7.91521 17.0455 8.0844 17.0388 8.25872V14.0921C17.0388 15.1419 16.6217 16.1487 15.8794 16.891C15.1371 17.6334 14.1303 18.0504 13.0805 18.0504H10.6888C10.3436 18.0504 10.0638 17.7706 10.0638 17.4254C10.0638 17.0802 10.3436 16.8004 10.6888 16.8004H13.0805C14.5743 16.7958 15.7842 15.5859 15.7888 14.0921V8.88372H12.2471C10.9834 8.87915 9.96002 7.85581 9.95545 6.59206V3.05039H8.08045C6.58468 3.05039 5.37212 4.26295 5.37212 5.75872V7.96706C5.37212 8.31223 5.0923 8.59206 4.74712 8.59206C4.40194 8.59206 4.12212 8.31223 4.12212 7.96706V5.75872C4.11546 4.70458 4.52956 3.69133 5.27261 2.94358C6.01567 2.19584 7.02629 1.77537 8.08045 1.77539H10.5805C10.7462 1.77554 10.905 1.84148 11.0221 1.95872ZM11.2055 3.90872V6.59206C11.2055 7.16735 11.6718 7.63372 12.2471 7.63372H14.9305L11.2055 3.90872Z"
              fill={step >= 5 ? "#E31B54" : "#344054"}
            />
          </svg>
        </div>
        <div className="">
          <p
            className="rightheadingtext"
            style={step >= 5 ? { color: "#E31B54" } : { color: "#344054" }}
          >
            Application Process
          </p>
          <p
            className="righttext"
            style={step >= 5 ? { color: "#E31B54" } : { color: "#344054" }}
          >
            Dates and documents
          </p>
        </div>
      </div>
      {step === 1 && (
        <div style={{ marginTop: "2.5rem" }}>
          <p className="nextstepsright">Preview</p>
          <div className="Challengescardouterwhitediv">
            <div
              className="Challengescardinnergreydiv"
              style={{ overflow: "hidden" }}
            >
              <div style={{ padding: "1.5rem 0.75rem" }}>
                <p className=" text-start black10">just now</p>
                <p className="pink12" style={{ marginTop: "0.75rem" }}>
                  {company && company.title && company.title}
                </p>
                <p
                  className="jobecardChallengeTitle"
                  style={{ marginTop: "0.625rem" }}
                >
                  {title && title}
                </p>
              </div>
            </div>
            <div className="space ">
              <div
                className="center shadowa"
                style={{
                  height: "56px",
                  maxHeight: "56px",
                  width: "56px",
                  maxWidth: "56px",
                  padding: "auto",
                  background: "#FFF",
                  alignItems: "center",
                  borderRadius: "6px",
                  position: "relative",
                  marginTop: "-28px",
                  marginLeft: "8px",
                }}
              >
                <img
                  src={company && company.img && company.img}
                  alt="company"
                  style={{ maxWidth: "42px" }}
                />
              </div>
              <div className="jobCardBlueChip">
                <img
                  src={sandclock}
                  alt="sand clock"
                  height="14px"
                  width="14px"
                />
                <p className="black12">{position && position}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Right;
