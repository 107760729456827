import { useEffect, useRef, useState } from "react";
import SelectedBox from "../../assets/images/icons/selectedbox";
import UnselectedBox from "../../assets/images/icons/unselectedBox";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ListItem from "./ChatListItem";
import StarIcon from "@mui/icons-material/Star";
import EmptySpace from "../../assets/images/EmptyState.svg";
import axios from "axios";
import { toast } from "react-toastify";
import MoreVertIcon from "@mui/icons-material/MoreVert";
const ChatList = ({
  messages,
  selectedChats,
  setSelectedChats,
  isNonMob1024,
  user,
  navigate,
  starredChats,
  setStarredChats,
  isNonMobile600,
  isNonMobile300,
  setTest,
  search,
  setSearch,
  alldata,
  setTest2,
  test2,
  selected,
  setSelected,
  isNonMobile660,
}) => {
  const [searchResult, setSearchResult] = useState([]);

  const [popup, setPopup] = useState(false);

  const [popup2, setPopup2] = useState(false);

  const [results, setResults] = useState([]);

  const updateData = async () => {
    try {
      await axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/api/update/starred/message`,
          {
            starredChats: starredChats,
            userId: user?._id,
          }
        )
        .then((res) => {
          if (res.data.msg === "success") {
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    } catch (err) {
      console.log(err.message);
    }
  };

  const [currentPage, setCurrentPage] = useState(1);

  const [check, setCheck] = useState(false);

  const entriesPerPage = 7;
  const indexOfLastEntry = currentPage * entriesPerPage;

  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;

  const [totalPages, setTotalPages] = useState(0);

  const iconRef = useRef(null);

  useEffect(() => {
    if (user) {
      updateData();
    }
  }, [check]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });

    function handleClickOutside(event) {
      if (iconRef.current && !iconRef.current.contains(event.target)) {
        setPopup(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (search === "" && searchResult.length === 0) {
      setResults(messages.slice(indexOfFirstEntry, indexOfLastEntry));
      setTotalPages(Math.ceil(messages.length / entriesPerPage));
    } else {
      setResults(searchResult.slice(indexOfFirstEntry, indexOfLastEntry));
      setTotalPages(Math.ceil(searchResult.length / entriesPerPage));
    }
  }, [messages, search, currentPage, currentPage, selected]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [selected]);

  const handleSearch = (e) => {
    e.preventDefault();
    const searchTerm = e.target.value;
    setSearch(searchTerm);

    if (searchTerm !== "") {
      const res = messages.filter((dat) => {
        return alldata.some((data) => {
          const name = `${data?.firstName} ${
            data?.lastName ? data?.lastName : data?.LastName
          }`;
          return (
            name.toLowerCase().includes(searchTerm.toLowerCase()) &&
            dat?.users.includes(data?._id)
          );
        });
      });
      setSearchResult(res);
    } else {
      setSearchResult([]);
    }
  };

  const buttons = [
    {
      text: "Inbox",
    },
    {
      text: "Sent",
    },
    {
      text: "Archive",
    },
    {
      text: "Unread",
    },
    {
      text: "Starred",
    },
  ];

  const handleStarMessage = async () => {
    if (selectedChats?.length > 0) {
      try {
        await axios
          .post(`${process.env.REACT_APP_BACKEND_URL}/api/star/message`, {
            selectedChats,
            userId: user?._id,
          })
          .then((res) => {
            if (res.data.msg === "success") {
              setTest2(!test2);
              setSelectedChats([]);
              toast.success("Updated Successfully");
            }
          })
          .catch((err) => {
            console.log(err.message);
          });
      } catch (err) {
        console.log(err.message);
      }
    }
  };

  const handleUnreadMessage = async () => {
    if (selectedChats?.length > 0) {
      try {
        await axios
          .post(`${process.env.REACT_APP_BACKEND_URL}/api/unread/message`, {
            selectedChats,
            userId: user?._id,
          })
          .then((res) => {
            if (res.data.msg === "success") {
              setTest2(!test2);
              setSelectedChats([]);
              toast.success("Updated Successfully");
            }
          })
          .catch((err) => {
            console.log(err.message);
          });
      } catch (err) {
        console.log(err.message);
      }
    }
  };

  const handleReadMessage = async () => {
    if (selectedChats?.length > 0) {
      try {
        await axios
          .post(`${process.env.REACT_APP_BACKEND_URL}/api/read/message`, {
            selectedChats,
            userId: user?._id,
          })
          .then((res) => {
            if (res.data.msg === "success") {
              setTest2(!test2);
              setSelectedChats([]);
              toast.success("Updated Successfully");
            }
          })
          .catch((err) => {
            console.log(err.message);
          });
      } catch (err) {
        console.log(err.message);
      }
    }
  };

  const handleArchieveMessage = async () => {
    if (selectedChats?.length > 0) {
      try {
        await axios
          .post(`${process.env.REACT_APP_BACKEND_URL}/api/archieve/message`, {
            selectedChats,
            userId: user?._id,
          })
          .then((res) => {
            if (res.data.msg === "success") {
              setTest2(!test2);
              setSelectedChats([]);
              toast.success("Updated Successfully");
            }
          })
          .catch((err) => {
            console.log(err.message);
          });
      } catch (err) {
        console.log(err.message);
      }
    }
  };

  const actionbuttons = [
    {
      text: "Star",
      icon: (
        <StarIcon style={{ height: "18px", width: "18px", color: "#E31B54" }} />
      ),
      onClick: async () => {
        await handleStarMessage();
      },
    },
    {
      text: "Mark unread",
      onClick: async () => {
        await handleUnreadMessage();
      },
    },
    {
      text: "Mark read",
      onClick: async () => {
        await handleReadMessage();
      },
    },
    {
      text: "Archieve",
      onClick: async () => {
        await handleArchieveMessage();
      },
    },
  ];

  const popupbuttons = [
    {
      text: "All",
      function: () => {
        handleAddAll();
      },
    },
    {
      text: "Read",
      function: () => {
        setSelected("Inbox");
      },
    },
    {
      text: "Unread",
      function: () => {
        setSelected("Unread");
      },
    },
    {
      text: "Starred",
      function: () => {
        setSelected("Starred");
      },
    },
  ];

  const renderPageButtons = () => {
    const maxButtons = 5; // Maximum number of buttons to display
    const buttons = [];

    // Calculate the range of buttons to display around the current page
    let startPage = Math.max(1, currentPage - Math.floor(maxButtons / 2));
    let endPage = Math.min(totalPages, startPage + maxButtons - 1);

    // Adjust startPage and endPage if there are not enough buttons
    if (endPage - startPage + 1 < maxButtons) {
      if (currentPage <= Math.floor(maxButtons / 2) + 1) {
        endPage = Math.min(totalPages, maxButtons);
      } else {
        startPage = Math.max(1, totalPages - maxButtons + 1);
      }
    }

    // Render the buttons in the calculated range
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          key={i}
          style={
            i === currentPage
              ? { backgroundColor: "#E31B54", color: "#FFF" }
              : { color: "#000", backgroundColor: "transparent" } // Adjust colors as needed
          }
          onClick={() => handlePageChange(i)}
          className={`btn normalbuttons ${i === currentPage && " rounded-3"}`}
        >
          {i}
        </button>
      );
    }

    return buttons;
  };

  const handleAddAll = () => {
    selectedChats && selectedChats?.length > 0
      ? setSelectedChats([])
      : setSelectedChats((prev) => {
          let ids = [];
          results.map((res) => {
            ids.push(res?._id);
          });
          return [...prev, ...ids];
        });
  };

  return (
    <div className="paddingmaincontent" style={{ minHeight: "80vh" }}>
      <div
        className="space flex-wrap align-items-center"
        style={{ gap: "1rem" }}
      >
        <div
          className="d-flex align-items-center flex-wrap"
          style={{
            gap: "1rem",
            maxHeight: isNonMob1024 ? "44px" : "",
            zIndex: 1,
            position: "relative",
          }}
        >
          <div>
            <div
              className="backbutton p-3"
              style={{
                maxHeight: isNonMob1024 ? "44px" : "",
              }}
            >
              <div style={{ cursor: "pointer" }} onClick={() => handleAddAll()}>
                {selectedChats?.length > 0 ? (
                  <SelectedBox />
                ) : (
                  <UnselectedBox />
                )}
              </div>

              <div>
                <KeyboardArrowDownIcon
                  className="hoverred"
                  style={{ height: "24px", width: "24px", cursor: "pointer" }}
                  onClick={() => setPopup(!popup)}
                />
              </div>
            </div>
            <div style={{ position: "absolute" }}>
              {popup && (
                <div
                  style={{
                    position: "absolute",
                    background: "transparent",
                  }}
                  ref={iconRef}
                >
                  <div
                    className="d-flex shadowa"
                    style={{
                      flexDirection: "column",
                      background: "transparent",
                    }}
                  >
                    {popupbuttons.map((button, index) => (
                      <button
                        key={index}
                        className="d-flex black14"
                        style={{
                          padding: "11px 1rem",
                          maxHeight: "2rem",
                          borderRadius: "5px 5px 0px 0px",
                          gap: "10px",
                          background: "#FFF",
                          border: "none",
                          width: "160px",
                          minHeight: "42px",
                          borderBottom:
                            index === popupbuttons.length - 1
                              ? "none"
                              : "1px solid #D0D5DD",
                        }}
                        onClick={() => {
                          button?.function();
                          setPopup(false);
                        }}
                      >
                        {button.text}
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>

          {selectedChats?.length === 0 && isNonMobile660 ? (
            <div className="outercoverofbuttons shadowa">
              {buttons.map((button, index) => (
                <div
                  key={index}
                  className={`innercoverofbuttons ${
                    selected === button.text ? "change" : null
                  }`}
                  onClick={() => setSelected(`${button.text}`)}
                >
                  {button.text}{" "}
                </div>
              ))}
            </div>
          ) : (
            selectedChats?.length > 0 &&
            actionbuttons.map((button, index) => (
              <button
                key={index}
                className="backbutton p-2"
                onClick={button?.onClick}
              >
                {button?.icon} {button?.text}
              </button>
            ))
          )}
        </div>
        <div
          className="d-flex flex-wrap align-items-center"
          style={{ gap: "0.5rem", position: "relative", zIndex: 1 }}
        >
          <div style={{ position: "relative" }}>
            <input
              type="search"
              placeholder="Search"
              className="forminput"
              style={{
                width: isNonMobile600 ? "260px" : "200px",
                display: "block",
                paddingLeft: "2rem", // Adjust padding to accommodate the icon
              }}
              value={search}
              onChange={(e) => handleSearch(e)}
            />

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              style={{
                position: "absolute",
                left: "0.5rem", // Adjust icon position
                top: "50%",
                transform: "translateY(-50%)",
              }}
            >
              <path
                d="M17.4999 17.4999L14.5833 14.5832M16.6665 9.58321C16.6665 13.4952 13.4952 16.6665 9.58321 16.6665C5.67119 16.6665 2.49988 13.4952 2.49988 9.58321C2.49988 5.67119 5.67119 2.49988 9.58321 2.49988C13.4952 2.49988 16.6665 5.67119 16.6665 9.58321Z"
                stroke="#667085"
                strokeWidth="1.36482"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          {!isNonMobile660 && (
            <button
              className="backbutton p-2 hoverred"
              onClick={() => {
                setPopup2(!popup2);
              }}
            >
              <MoreVertIcon />
            </button>
          )}

          {popup2 && (
            <div
              style={{
                position: "absolute",
                background: "transparent",
                left: "5rem",
                top: "2rem",
              }}
              ref={iconRef}
            >
              <div
                className="d-flex shadowa"
                style={{
                  flexDirection: "column",
                  background: "transparent",
                }}
              >
                {buttons.map((button, index) => (
                  <button
                    key={index}
                    className="d-flex black14"
                    style={{
                      padding: "11px 1rem",
                      maxHeight: "2rem",
                      borderRadius: "5px 5px 0px 0px",
                      gap: "10px",
                      background: "#FFF",
                      border: "none",
                      width: "160px",
                      minHeight: "42px",
                    }}
                    onClick={() => {
                      setSelected(`${button.text}`);
                      setPopup2(false);
                    }}
                  >
                    {button.text}
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      <div style={{ marginTop: "1.5rem" }} className="d-flex flex-column">
        {results?.length > 0 ? (
          results.map((res, index) => (
            <div key={index}>
              <ListItem
                setCheck={setCheck}
                res={res}
                user={user}
                navigate={navigate}
                selectedChats={selectedChats}
                setSelectedChats={setSelectedChats}
                starredChats={starredChats}
                setStarredChats={setStarredChats}
                isNonMob1024={isNonMob1024}
                isNonMobile600={isNonMobile600}
                isNonMobile300={isNonMobile300}
                setTest={setTest}
                alldata={alldata}
              />
            </div>
          ))
        ) : (
          <div
            className="center col-md-12 col-12"
            style={{
              alignItems: "center",
              flexDirection: "column",
              width: "100%",
              height: "400px",
            }}
          >
            <img
              src={EmptySpace}
              alt="Emptyspace"
              height="225px"
              width="180px"
              className="img-fluid"
            />

            <p className="black14" style={{ fontWeight: "500" }}>
              No Messages to show
            </p>
          </div>
        )}
      </div>
      <div className="d-flex justify-content-end" style={{ marginTop: "3rem" }}>
        {totalPages > 1 && renderPageButtons()}
      </div>
    </div>
  );
};
export default ChatList;
