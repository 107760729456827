// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StudentsShortlist .slick-slide,
.StudentsShortlist .slick-current {
  /* max-height: 300px !important; */
  max-width: 367px;
  /* overflow: hidden !important; */
}
.StudentsShortlist .slick-slider {
  max-width: 71vw;
}
.StudentsShortlist .slick-track {
  /* width: 100% !important; */
  /* display: block; */
  width: "" !important;
}

.StudentCardMostOuterDiv {
  padding: 20px;

  flex: 1 0;
  border-radius: 5px;
  background: #fff;

  /* Trans Card: Icons */
  box-shadow: 0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02);
}

.countrycontainer {
  display: flex;
  padding: 6px 8px;
  align-items: center;
  gap: 6px;
  border-radius: 30px;
  border: 1px solid #eff0f1;
  background: var(--Gray-25, #fcfcfd);
}
.skillscontainer {
  display: flex;
  height: 36px;
  padding: 12px 14px;
  align-items: center;
  gap: 8px;
  border-radius: 180px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
}

@media (max-width: 1024px) {
  .StudentsShortlist .slick-slider {
    max-width: 90vw;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/TalentPool/ShortListed/cards.css"],"names":[],"mappings":"AAAA;;EAEE,kCAAkC;EAClC,gBAAgB;EAChB,iCAAiC;AACnC;AACA;EACE,eAAe;AACjB;AACA;EACE,4BAA4B;EAC5B,oBAAoB;EACpB,oBAAoB;AACtB;;AAEA;EACE,aAAa;;EAEb,SAAW;EACX,kBAAkB;EAClB,gBAAgB;;EAEhB,sBAAsB;EACtB;;;kDAGgD;AAClD;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,mBAAmB;EACnB,QAAQ;EACR,mBAAmB;EACnB,yBAAyB;EACzB,mCAAmC;AACrC;AACA;EACE,aAAa;EACb,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;EACnB,QAAQ;EACR,oBAAoB;EACpB,0CAA0C;EAC1C,mCAAmC;AACrC;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".StudentsShortlist .slick-slide,\n.StudentsShortlist .slick-current {\n  /* max-height: 300px !important; */\n  max-width: 367px;\n  /* overflow: hidden !important; */\n}\n.StudentsShortlist .slick-slider {\n  max-width: 71vw;\n}\n.StudentsShortlist .slick-track {\n  /* width: 100% !important; */\n  /* display: block; */\n  width: \"\" !important;\n}\n\n.StudentCardMostOuterDiv {\n  padding: 20px;\n\n  flex: 1 0 0;\n  border-radius: 5px;\n  background: #fff;\n\n  /* Trans Card: Icons */\n  box-shadow: 0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),\n    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),\n    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),\n    0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02);\n}\n\n.countrycontainer {\n  display: flex;\n  padding: 6px 8px;\n  align-items: center;\n  gap: 6px;\n  border-radius: 30px;\n  border: 1px solid #eff0f1;\n  background: var(--Gray-25, #fcfcfd);\n}\n.skillscontainer {\n  display: flex;\n  height: 36px;\n  padding: 12px 14px;\n  align-items: center;\n  gap: 8px;\n  border-radius: 180px;\n  border: 1px solid var(--Gray-300, #d0d5dd);\n  background: var(--Base-White, #fff);\n}\n\n@media (max-width: 1024px) {\n  .StudentsShortlist .slick-slider {\n    max-width: 90vw;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
