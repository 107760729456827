import React, { useState } from "react";
import { useMediaQuery } from "@mui/material";
import card from "../../assets/images/companyhome.png";
const ThirdSection = () => {
  const isNonMobile = useMediaQuery("(min-width: 768px)");
  const isNonMobile450 = useMediaQuery("(min-width: 450px)");
  const [MouseHover, setStartHover] = useState(false);
  return (
    <div
      className="d-flex"
      style={{
        justifyContent: "center",
        position: "relative",
        marginTop: isNonMobile450 ? "10rem" : "4rem",
      }}
    >
      <div className="headerCompany">
        <p className="sparkInterest">
          Spark <span> Interest</span>
        </p>
        <div
          className="d-flex"
          style={{
            flexDirection: isNonMobile ? "row" : "column",
            justifyContent: "space-between",
            gap: "3.806rem",
          }}
        >
          <div style={{ width: isNonMobile ? "50%" : "100%" }}>
            <p className="underSparkInterest">
              Begin the <span className="bluespans">candidate journey</span> on
              campus and let <span className="blackspans">students </span>
              immerse into your{" "}
              <span className="pinkspans"> employer brand.</span>
            </p>
            <p className="engageEarly">
              Engage early talent in a <span>new and exciting way</span> by
              showcasing real-world case studies, and give potential candidates
              the opportunity to explore their future career at your company.
            </p>
            <button
              className="btn startonboard p-0 d-flex"
              style={{ gap: "1rem" }}
              onMouseEnter={() => setStartHover(true)}
              onMouseLeave={() => setStartHover(false)}
            >
              Start Onboarding
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M20.05 11.47L13.05 4.47C12.8571 4.28243 12.5787 4.21204 12.3198 4.28533C12.0609 4.35863 11.8608 4.56447 11.7948 4.82533C11.7289 5.08619 11.8071 5.36243 12 5.55L17.71 11.25H4.47998C4.06577 11.25 3.72998 11.5858 3.72998 12C3.72998 12.4142 4.06577 12.75 4.47998 12.75H17.7L12 18.45C11.857 18.5893 11.7764 18.7804 11.7764 18.98C11.7764 19.1796 11.857 19.3707 12 19.51C12.1378 19.6546 12.3302 19.7345 12.53 19.73C12.729 19.7309 12.9201 19.6516 13.06 19.51L20.06 12.51C20.3524 12.2172 20.3524 11.7428 20.06 11.45L20.05 11.47Z"
                  fill={MouseHover ? "#A11043" : "#E31B54"}
                />
              </svg>
            </button>
          </div>
          <div style={{ width: isNonMobile ? "45%" : "100%" }}>
            <img
              src={card}
              alt="company home card"
              className="shadowa  rounded-3 img-fluid"
              style={{ marginTop: isNonMobile ? "1.25rem" : "0rem" }}
            />
            <div
              className="d-flex exemplarymargintopCompany"
              style={{ alignItems: "center", gap: "0.75rem" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
              >
                <path
                  d="M8 5.59776C7.6866 5.63389 7.45008 5.89924 7.45008 6.21471C7.45008 6.53019 7.6866 6.79554 8 6.83167C8.16466 6.83396 8.32313 6.76899 8.4388 6.65178C8.55447 6.53456 8.61734 6.37524 8.61287 6.21063C8.60847 5.87399 8.33664 5.60216 8 5.59776Z"
                  fill="#475467"
                />
                <path
                  d="M8 7.95118C7.83679 7.94896 7.67961 8.01282 7.56419 8.12824C7.44877 8.24366 7.38491 8.40084 7.38713 8.56405V11.1136C7.38713 11.4521 7.66152 11.7265 8 11.7265C8.33848 11.7265 8.61287 11.4521 8.61287 11.1136V8.58039C8.61728 8.41504 8.55466 8.25492 8.43925 8.13642C8.32383 8.01793 8.16541 7.95112 8 7.95118Z"
                  fill="#475467"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8 0.662109C3.58359 0.666614 0.00450425 4.2457 0 8.66211C0 13.0804 3.58172 16.6621 8 16.6621C12.4183 16.6621 16 13.0804 16 8.66211C15.9955 4.2457 12.4164 0.666614 8 0.662109ZM8 15.4364C4.25868 15.4364 1.22574 12.4034 1.22574 8.66211C1.22574 4.92079 4.25868 1.88785 8 1.88785C11.7413 1.88785 14.7743 4.92079 14.7743 8.66211C14.7698 12.4016 11.7395 15.4319 8 15.4364Z"
                  fill="#475467"
                />
              </svg>
              <p className="exemplary">Exemplary Challenge Task.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThirdSection;
