const Tasknav = ({ taskCounter, total, steps, isNonMobile1024 }) => {
  return (
    <div
      className="d-flex flex-wrap tablescroll"
      style={{
        gap: "1rem",
        maxWidth: isNonMobile1024 ? "100%" : "350px",
        overflow: "auto",
      }}
    >
      {Array.from({ length: total }, (_, index) => (
        <div
          key={index}
          className="taskcounter black12"
          style={{
            color: taskCounter >= index ? "white" : "black",
            background: taskCounter >= index ? "#E31B54" : "",
          }}
        >
          T {index + 1}
        </div>
      ))}
      <div
        className="taskcounter black12"
        style={{
          color: steps === 4 ? "white" : "black",
          background: steps === 4 ? "#E31B54" : "",
        }}
      >
        Done
      </div>
    </div>
  );
};
export default Tasknav;
