const First = ({ newTask }) => {
  return (
    <div>
      <p className="filmo20"> {newTask.taskTitle}</p>
      <video
        src={
          newTask.introVideo instanceof File
            ? URL.createObjectURL(newTask.introVideo)
            : newTask.introVideo.includes("http")
            ? newTask.introVideo
            : `${process.env.REACT_APP_BACKEND_URL}/uploads/${newTask.introVideo}`
        }
        className="rounded-3"
        controls
        style={{
          marginTop: "1.5rem",

          width: "100%",
          height: "auto",
          maxHeight: "100%",
        }}
      />
    </div>
  );
};
export default First;
