import { useState } from "react";
import Header from "../../../components/CompanyHome/Header";
import "./Main.css";
import First from "./FirstSection";
import Second from "./Second";
import Third from "./Third";
import Fourth from "./Fourth";
import Fifth from "./Fifth";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import HomeFooter from "../../../components/Home/HomeFooter";
const UniversityHome = () => {
  const [Test, setTest] = useState(false);
  const isNonMobile = useMediaQuery("(min-width: 768px)");
  const isNonMobile450 = useMediaQuery("(min-width: 450px)");
  const navigate = useNavigate();
  return (
    <div style={{ width: "100vw", overflowX: "clip" }}>
      <Header setTest={setTest} Test={Test} />
      <div className="university-first ">
        <First />
      </div>
      <div style={{ marginTop: "4.375rem", padding: "2.5rem 0rem" }}>
        <Second />
      </div>
      <div
        style={{ background: "rgba(242, 244, 247, 0.80)", padding: "80px 0px" }}
      >
        <Third isNonMobile450={isNonMobile450} />
      </div>
      <div style={{ padding: "5rem 0rem 8.125rem 0rem" }}>
        <Fourth />
      </div>
      <div
        style={{
          padding: "3.75rem 0rem",
          // background: "rgba(242, 243, 247, 0.80)",
        }}
      >
        <Fifth />
      </div>
      {/* <div className="marginsleftrightCompany">
        <div className="center">
          <div className="headerCompany">
            <div
              className="lastSection d-flex"
              style={{
                justifyContent: isNonMobile ? "space-between" : "center",
                flexDirection: isNonMobile ? "row" : "column",
              }}
            >
              <p className={`startjourney ${isNonMobile && "section"}`}>
                Start your journey today and explore your future career.
              </p>
              <div
                className="d-flex "
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: isNonMobile ? "" : "1rem",
                }}
              >
                <div
                  className={`${isNonMobile450 ? "d-flex" : ""} col-12`}
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  <button
                    className={`backbutton ${isNonMobile450 ? "" : "col-12"}`}
                    onClick={() => {
                      navigate("/career-center/login");
                    }}
                  >
                    Login
                  </button>
                  <button
                    className={`continuebutton  ${
                      isNonMobile450 ? "" : "col-12 mt-3"
                    }`}
                    onClick={() => {
                      navigate("/career-center/register");
                    }}
                  >
                    Register Now
                  </button>
                </div>
              </div>
            </div>
            <hr className="horiline " />
          </div>
        </div>
      </div> */}
      <div className="marginsleftrightCompany mt-5 pt-5">
        {/* <FourthSection /> */}
        <div className="d-flex" style={{ justifyContent: "center" }}>
          <div className="headerCompany">
            <HomeFooter />
          </div>
        </div>
      </div>
    </div>
  );
};
export default UniversityHome;
