// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/rolebackground.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.createcompany {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  min-width: 100vw;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.wrapper .slick-slider,
.wrapper .slick-list,
.wrapper .slick-track {
  margin-top: 2rem;
  max-height: 300px !important;
  overflow: hidden !important;
}
`, "",{"version":3,"sources":["webpack://./src/pages/register/role.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,gBAAgB;EAChB,mDAAuD;EACvD,4BAA4B;EAC5B,sBAAsB;EACtB,2BAA2B;AAC7B;;AAEA;;;EAGE,gBAAgB;EAChB,4BAA4B;EAC5B,2BAA2B;AAC7B","sourcesContent":[".createcompany {\n  width: 100%;\n  height: 100%;\n  min-height: 100vh;\n  min-width: 100vw;\n  background: url(../../assets/images/rolebackground.png);\n  background-repeat: no-repeat;\n  background-size: cover;\n  background-position: center;\n}\n\n.wrapper .slick-slider,\n.wrapper .slick-list,\n.wrapper .slick-track {\n  margin-top: 2rem;\n  max-height: 300px !important;\n  overflow: hidden !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
