// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headerbutton {
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.9);
  width: 45px;
  height: 45px;
  display: flex;
  /* Trans Card v2 */
  box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 33.074px 26.459px 0px rgba(0, 49, 122, 0.05),
    0px 79.167px 63.333px 0px rgba(0, 49, 122, 0.07);
}
.HeaderText {
  color: #0c111d;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem; /* 150% */
}
.headersmalltext {
  color: var(--Gray-800, #1d2939);
  /* Caption/Caption Text */
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%; /* 20.3px */
}
`, "",{"version":3,"sources":["webpack://./src/pages/JobPost/nav.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,oCAAoC;EACpC,WAAW;EACX,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB;;;;;oDAKkD;AACpD;AACA;EACE,cAAc;EACd,oBAAoB;EACpB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB,EAAE,SAAS;AAChC;AACA;EACE,+BAA+B;EAC/B,yBAAyB;EACzB,sBAAsB;EACtB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,WAAW;AAChC","sourcesContent":[".headerbutton {\n  border-radius: 5px;\n  background: rgba(255, 255, 255, 0.9);\n  width: 45px;\n  height: 45px;\n  display: flex;\n  /* Trans Card v2 */\n  box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),\n    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),\n    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),\n    0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),\n    0px 33.074px 26.459px 0px rgba(0, 49, 122, 0.05),\n    0px 79.167px 63.333px 0px rgba(0, 49, 122, 0.07);\n}\n.HeaderText {\n  color: #0c111d;\n  font-family: Poppins;\n  font-size: 1rem;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 1.5rem; /* 150% */\n}\n.headersmalltext {\n  color: var(--Gray-800, #1d2939);\n  /* Caption/Caption Text */\n  font-family: \"Poppins\";\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 145%; /* 20.3px */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
