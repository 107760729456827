import EditIcon from "@mui/icons-material/Edit";
const Certificates = ({ owner, user, setCertificatesPopup }) => {
  return (
    <div className=" shadowa">
      <div
        className="innerglass tablescroll"
        style={{ maxHeight: "20.25rem", overflow: "auto", padding: "1.25rem" }}
      >
        <div className="space flex-wrap" style={{ gap: "1rem" }}>
          <p className="filmo20">Certificates</p>
          {owner && (
            <EditIcon
              className="hoverred"
              style={{ marginTop: "0.25rem", width: "24px", cursor: "pointer" }}
              onClick={() => setCertificatesPopup(true)}
            />
          )}
        </div>
        {user?.certificates?.map((cert, index) => (
          <div key={index} style={{ marginTop: "1.5rem" }}>
            <div className="space flex-wrap col-md-12 col-12">
              <div className="d-flex flex-wrap" style={{ gap: "1rem" }}>
                {cert?.logo && (
                  <img
                    src={cert?.logo} // Access logo property from each experience object
                    alt="university logo"
                    className="shadowa rounded-3"
                    style={{
                      maxWidth: "32px",
                      maxHeight: "32px",
                      padding: "2px",
                      background: "#FFF",
                    }}
                  />
                )}
                <div>
                  {cert?.name && (
                    <p className="black16 text-start">{cert?.name}</p>
                  )}
                  <p className="gray14400" style={{ marginTop: "0.75rem" }}>
                    Issued Date: {cert?.issued}
                    <br />
                    Credential ID: {cert?.credentialId}
                  </p>
                </div>
              </div>
              <div className="center" style={{ alignItems: "center" }}>
                <button
                  className="backbutton"
                  onClick={() =>
                    window.open(
                      `${cert?.link.startsWith("http") ? "" : "https://"}${
                        cert?.link
                      }`,
                      "_blank"
                    )
                  }
                >
                  Show Credential
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default Certificates;
