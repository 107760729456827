import img from "../../../assets/images/dashboardpreview.png";
const Second = () => {
  return (
    <div className="marginsleftrightCompany">
      <div className="center">
        <div className="headerCompany">
          <p className="text-center gray80016500">Benefits for your Students</p>
          <div style={{ padding: "1rem 0rem" }}>
            <div className="center">
              <p
                className="newheadingtextthirdsection text-center"
                style={{ maxWidth: "656px" }}
              >
                Give students access to the tools they need to explore their
                <span> future career</span>.
              </p>
            </div>
            <div className="center">
              <p
                className="text-center gray95016500"
                style={{
                  maxWidth: "700px",
                  marginTop: "1rem",
                  fontWeight: 400,
                }}
              >
                With varyance students can explore careers by attempting{" "}
                <span className="gray95016500">
                  virtual challenges designed by top-employers
                </span>
                , find a job, attend events and so much more.
              </p>
            </div>
          </div>
          <img
            src={img}
            alt="dashboard preview"
            className="img-fluid"
            style={{ marginTop: "2.25rem" }}
          />
        </div>
      </div>
    </div>
  );
};
export default Second;
