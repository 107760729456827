import { useState } from "react";
import "./body.css";
import Recruiter from "./component/Recruiter";
import { useMediaQuery } from "@mui/material";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
const Body = () => {
  const [role, setRole] = useState("Recruiter");
  const isNonMobile = useMediaQuery("(min-width:636px");
  const navigate = useNavigate();

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: true,
          centerMode: true,
          centerPadding: "3%",
        },
      },
    ],
  };

  const handleSubmit = async () => {
    navigate(`/create/company/role/${role}`);
  };
  return (
    <>
      <div
        className="d-flex"
        style={{
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <div className="">
          <div className="d-flex" style={{ justifyContent: "space-between" }}>
            <div className="row d-flex">
              <p className="registeration">What are you up to?</p>
              <p
                style={{
                  fontSize: "14px",
                  fontFamily: "Poppins",
                  color: "#1D2939",
                  marginTop: "0.5rem",
                }}
              >
                Define your role on varyance. You can easily change the
                selection in team settings.
              </p>
            </div>
          </div>
          {isNonMobile ? (
            <div
              className="mt-5 d-flex "
              style={{ justifyContent: "space-between" }}
            >
              <div
                className={`rolecards col-md-4 col-3 ${
                  role === "Recruiter" ? "selectedrolecard" : ""
                }`}
                style={{
                  maxWidth: "390px",
                  width: "30%",
                  padding: "29px 24px",
                }}
                onClick={() => setRole("Recruiter")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="34"
                  viewBox="0 0 28 34"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M26.6333 24.85L27.0333 26.75C27.4123 28.3927 27.0381 30.1188 26.0127 31.4569C24.9873 32.7951 23.418 33.6054 21.7333 33.6667H6.26662C4.58191 33.6054 3.01257 32.7951 1.9872 31.4569C0.96183 30.1188 0.587564 28.3927 0.96662 26.75L1.36662 24.85C1.82668 22.278 4.03766 20.3878 6.64995 20.3334H21.35C23.9623 20.3878 26.1732 22.278 26.6333 24.85ZM21.7333 31.15C22.5796 31.1402 23.3761 30.7481 23.9 30.0834V30.1C24.5428 29.2937 24.7932 28.243 24.5833 27.2334L24.1833 25.3334C23.9614 23.9253 22.7744 22.871 21.35 22.8167H6.64996C5.22557 22.871 4.03856 23.9253 3.81663 25.3334L3.41663 27.2334C3.21189 28.2377 3.46206 29.2811 4.09996 30.0834C4.62385 30.7481 5.42031 31.1402 6.26663 31.15H21.7333Z"
                    fill={role === "Recruiter" ? "#E31B54" : "#000000"}
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.8333 17H13.1666C9.4847 17 6.49993 14.0152 6.49993 10.3333V5.93334C6.49548 4.44677 7.08406 3.0198 8.13522 1.96863C9.18639 0.917465 10.6134 0.328894 12.0999 0.333338H15.8999C17.3865 0.328894 18.8135 0.917465 19.8646 1.96863C20.9158 3.0198 21.5044 4.44677 21.4999 5.93334V10.3333C21.4999 14.0152 18.5152 17 14.8333 17ZM12.0999 2.83334C10.3878 2.83334 8.99993 4.22126 8.99993 5.93334V10.3333C8.99993 12.6345 10.8654 14.5 13.1666 14.5H14.8333C17.1344 14.5 18.9999 12.6345 18.9999 10.3333V5.93334C18.9999 5.11117 18.6733 4.32267 18.092 3.74131C17.5106 3.15994 16.7221 2.83334 15.8999 2.83334H12.0999Z"
                    fill={role === "Recruiter" ? "#E31B54" : "#000000"}
                  />
                </svg>
                <p
                  className={`rolecardsheading mt-2 ${
                    role === "Recruiter" ? "recruiter-text" : "default-text"
                  }`}
                >
                  Recruiter
                </p>
                <p
                  className={`rolecardstext mt-2 ${
                    role === "Recruiter" ? "recruiter-text" : "default-text"
                  }`}
                >
                  I can use all engagement tools and have access to the company
                  talent pool.
                </p>
              </div>
              <div
                className={`rolecards col-md-4 col-3 ${
                  role === "Team" ? "selectedrolecard" : ""
                }`}
                style={{
                  maxWidth: "390px",
                  width: "30%",
                  padding: "29px 24px",
                }}
                onClick={() => setRole("Team")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="49"
                  height="40"
                  viewBox="0 0 49 40"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M43.8375 24.5666L44.1158 25.7166C44.4188 26.699 44.1717 27.7437 43.4443 28.5547C42.7169 29.3657 41.5885 29.8547 40.3788 29.8833H32.8054C32.8072 32.5809 30.2564 34.7934 27.0409 34.8833H10.5623C8.74254 34.858 7.03621 34.1374 5.9288 32.9264C4.82138 31.7154 4.43414 30.1467 4.87725 28.6666L5.29468 27C5.78006 24.704 8.13945 23.0194 10.9201 22.9833H26.7029C27.4073 22.2774 28.4478 21.8687 29.5455 21.8666H40.0607C41.9174 21.9163 43.4855 23.0373 43.8375 24.5666ZM40.339 27.3666C40.5815 27.3591 40.8074 27.2614 40.9552 27.1C41.2152 26.88 41.3462 26.5751 41.313 26.2666L41.0347 25.1166C40.9809 24.7441 40.6255 24.453 40.18 24.4166H29.6647C29.4359 24.4179 29.2217 24.5108 29.0883 24.6666C28.5182 25.2171 27.6916 25.5332 26.8222 25.5333H11.0393C9.67853 25.5611 8.53829 26.4047 8.33596 27.5333L7.91853 29.2C7.71465 30.0208 7.95485 30.8752 8.57449 31.5333C9.05852 32.0451 9.78393 32.3553 10.5623 32.3833H26.9812C28.581 32.2942 29.8104 31.1604 29.7641 29.8166C29.7966 28.4555 31.122 27.3664 32.7457 27.3666H40.339Z"
                    fill={role === "Team" ? "#E31B54" : "#000000"}
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M26.6632 13.3333C26.6632 17.0152 23.1034 20 18.7121 20C14.3209 20 10.761 17.0152 10.761 13.3333V10.7166C10.761 7.62384 13.7513 5.11664 17.4399 5.11664H20.0439C23.7093 5.14408 26.6633 7.64327 26.6632 10.7166V13.3333ZM17.4399 7.61664C15.398 7.61664 13.7427 9.00456 13.7427 10.7166L13.8023 13.3333C14.1196 15.4319 16.2488 17.0052 18.7717 17.0052C21.2947 17.0052 23.4239 15.4319 23.7412 13.3333V10.7166C23.7412 9.00456 22.0858 7.61664 20.0439 7.61664H17.4399Z"
                    fill={role === "Team" ? "#E31B54" : "#000000"}
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M41.7305 13.75C41.7305 16.5114 39.0606 18.75 35.7672 18.75C32.4737 18.75 29.8039 16.5114 29.8039 13.75V11.8333C29.8035 9.5395 31.9981 7.66971 34.7335 7.63331H36.7213C39.4878 7.63331 41.7305 9.51371 41.7305 11.8333V13.75ZM34.7733 10.1666C33.6755 10.1666 32.7855 10.9128 32.7855 11.8333V13.75C32.7855 15.1307 34.1204 16.25 35.7672 16.25C37.4139 16.25 38.7488 15.1307 38.7488 13.75V11.8333C38.7488 10.9128 37.8589 10.1666 36.761 10.1666H34.7733Z"
                    fill={role === "Team" ? "#E31B54" : "#000000"}
                  />
                </svg>
                <p
                  className={`rolecardsheading mt-2 ${
                    role === "Team" ? "recruiter-text" : "default-text"
                  }`}
                >
                  Team
                </p>
                <p
                  className={`rolecardstext mt-2 ${
                    role === "Team" ? "recruiter-text" : "default-text"
                  }`}
                >
                  I have access to the company talent pool and analytics.
                </p>
              </div>
              <div
                className={`rolecards   col-4 ${
                  role === "Representative" ? "selectedrolecard" : ""
                }`}
                style={{
                  width: "30%",
                  maxWidth: "390px",
                  padding: "29px 24px",
                }}
                onClick={() => setRole("Representative")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M26.6666 14.6C26.6122 17.3223 24.3895 19.5005 21.6666 19.5C18.9762 19.4557 16.8034 17.2902 16.75 14.6V12.7166C16.7496 11.6073 17.197 10.5447 17.9908 9.76978C18.7846 8.99482 19.8576 8.57302 20.9666 8.59997H22.5333C23.628 8.59554 24.6794 9.0273 25.455 9.79981C26.2306 10.5723 26.6666 11.6219 26.6666 12.7166V14.6ZM20.9166 11.05C19.9961 11.05 19.25 11.7962 19.25 12.7166L19.3166 14.6C19.3166 15.9347 20.3986 17.0166 21.7333 17.0166C23.068 17.0166 24.15 15.9347 24.15 14.6V12.7166C24.15 11.7962 23.4038 11.05 22.4833 11.05H20.9166Z"
                    fill={role === "Representative" ? "#E31B54" : "#000000"}
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M39.1333 25.5L39.3166 28.1C39.5092 28.9137 39.3212 29.7708 38.8055 30.4291C38.2899 31.0874 37.5028 31.4754 36.6666 31.4833H29.1833C28.5305 32.1792 27.6208 32.5768 26.6666 32.5833H16.6666C15.6643 32.5968 14.7047 32.1777 14.0333 31.4333C13.8782 31.4505 13.7217 31.4505 13.5666 31.4333H5.91662C5.10325 31.4026 4.34525 31.0132 3.84664 30.3698C3.34804 29.7265 3.16006 28.8953 3.33329 28.1L3.58329 25.5C3.82649 24.2594 4.90263 23.3568 6.16662 23.3333H13.3333C13.6324 23.3167 13.932 23.3563 14.2166 23.45C14.856 22.6512 15.8269 22.1904 16.85 22.2H26.4833C27.4465 22.1964 28.3642 22.6096 29 23.3333C29.116 23.3169 29.2339 23.3169 29.35 23.3333H36.55C37.8139 23.3568 38.8901 24.2594 39.1333 25.5ZM29.25 28.9333H36.8C36.851 28.8351 36.857 28.7197 36.8166 28.6166V28.2833L36.65 25.9166L36.55 25.7833H29C28.2954 25.7859 27.6228 25.489 27.15 24.9666C26.9846 24.7689 26.7411 24.6532 26.4833 24.65H16.85C16.5707 24.6536 16.3078 24.782 16.1333 25C15.6575 25.5648 14.9551 25.8885 14.2166 25.8833C13.9142 25.8847 13.6144 25.8281 13.3333 25.7166H6.24996C6.14996 25.7166 6.14996 25.75 6.14996 25.85L5.9833 28.2166V28.55C5.93766 28.6506 5.93766 28.766 5.9833 28.8666H13.6333C13.8107 28.8504 13.9892 28.8504 14.1666 28.8666C14.844 28.8792 15.4849 29.1755 15.9333 29.6833C16.1172 29.8985 16.3837 30.0257 16.6666 30.0333H26.7333C27.0168 30.0278 27.2841 29.9002 27.4666 29.6833C27.9353 29.2025 28.5786 28.932 29.25 28.9333Z"
                    fill={role === "Representative" ? "#E31B54" : "#000000"}
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M36.6166 17.1C36.6166 19.125 34.975 20.7666 32.95 20.7666C31.9746 20.7711 31.0377 20.3867 30.3465 19.6986C29.6552 19.0105 29.2666 18.0753 29.2666 17.1V15.7C29.2666 14.8822 29.5915 14.098 30.1697 13.5197C30.7479 12.9415 31.5322 12.6166 32.35 12.6166H33.5333C35.2362 12.6166 36.6166 13.9971 36.6166 15.7V17.1ZM31.9375 15.3542C31.8281 15.4636 31.7666 15.6119 31.7666 15.7666V17.1666C31.8376 17.7617 32.3423 18.2098 32.9416 18.2098C33.5409 18.2098 34.0456 17.7617 34.1166 17.1666V15.7666C34.1166 15.4445 33.8555 15.1833 33.5333 15.1833H32.35C32.1952 15.1833 32.0469 15.2448 31.9375 15.3542Z"
                    fill={role === "Representative" ? "#E31B54" : "#000000"}
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.3701 19.6986C11.6789 20.3867 10.742 20.7711 9.76663 20.7666C8.79417 20.7666 7.86154 20.3803 7.1739 19.6927C6.48627 19.0051 6.09996 18.0724 6.09996 17.1V15.7C6.09996 13.9971 7.48042 12.6166 9.18329 12.6166H10.3666C12.0695 12.6166 13.45 13.9971 13.45 15.7V17.1C13.45 18.0753 13.0614 19.0105 12.3701 19.6986ZM9.1833 15.1833C8.86113 15.1833 8.59996 15.4445 8.59996 15.7666V17.1666C8.67096 17.7617 9.17565 18.2098 9.77496 18.2098C10.3743 18.2098 10.879 17.7617 10.95 17.1666V15.7666C10.95 15.4445 10.6888 15.1833 10.3666 15.1833H9.1833Z"
                    fill={role === "Representative" ? "#E31B54" : "#000000"}
                  />
                </svg>
                <p
                  className={`rolecardsheading mt-2 ${
                    role === "Representative"
                      ? "recruiter-text"
                      : "default-text"
                  }`}
                >
                  Representative
                </p>
                <p
                  className={`rolecardstext mt-2 ${
                    role === "Representative"
                      ? "recruiter-text"
                      : "default-text"
                  }`}
                >
                  I’m here to create a challenge or attend a varyance
                  online-event.
                </p>
              </div>
            </div>
          ) : (
            <Slider {...settings} style={{ maxHeight: "400px" }}>
              <div>
                <div
                  className={`rolecards  ${
                    role === "Recruiter" ? "selectedrolecardmobile" : ""
                  }`}
                  style={{
                    maxWidth: "390px",
                    height: "210px",
                    padding: "29px 24px",
                  }}
                  onClick={() => setRole("Recruiter")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="34"
                    viewBox="0 0 28 34"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M26.6333 24.85L27.0333 26.75C27.4123 28.3927 27.0381 30.1188 26.0127 31.4569C24.9873 32.7951 23.418 33.6054 21.7333 33.6667H6.26662C4.58191 33.6054 3.01257 32.7951 1.9872 31.4569C0.96183 30.1188 0.587564 28.3927 0.96662 26.75L1.36662 24.85C1.82668 22.278 4.03766 20.3878 6.64995 20.3334H21.35C23.9623 20.3878 26.1732 22.278 26.6333 24.85ZM21.7333 31.15C22.5796 31.1402 23.3761 30.7481 23.9 30.0834V30.1C24.5428 29.2937 24.7932 28.243 24.5833 27.2334L24.1833 25.3334C23.9614 23.9253 22.7744 22.871 21.35 22.8167H6.64996C5.22557 22.871 4.03856 23.9253 3.81663 25.3334L3.41663 27.2334C3.21189 28.2377 3.46206 29.2811 4.09996 30.0834C4.62385 30.7481 5.42031 31.1402 6.26663 31.15H21.7333Z"
                      fill={role === "Recruiter" ? "#E31B54" : "#000000"}
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M14.8333 17H13.1666C9.4847 17 6.49993 14.0152 6.49993 10.3333V5.93334C6.49548 4.44677 7.08406 3.0198 8.13522 1.96863C9.18639 0.917465 10.6134 0.328894 12.0999 0.333338H15.8999C17.3865 0.328894 18.8135 0.917465 19.8646 1.96863C20.9158 3.0198 21.5044 4.44677 21.4999 5.93334V10.3333C21.4999 14.0152 18.5152 17 14.8333 17ZM12.0999 2.83334C10.3878 2.83334 8.99993 4.22126 8.99993 5.93334V10.3333C8.99993 12.6345 10.8654 14.5 13.1666 14.5H14.8333C17.1344 14.5 18.9999 12.6345 18.9999 10.3333V5.93334C18.9999 5.11117 18.6733 4.32267 18.092 3.74131C17.5106 3.15994 16.7221 2.83334 15.8999 2.83334H12.0999Z"
                      fill={role === "Recruiter" ? "#E31B54" : "#000000"}
                    />
                  </svg>
                  <p
                    className={`rolecardsheading mt-2 ${
                      role === "Recruiter" ? "recruiter-text" : "default-text"
                    }`}
                  >
                    Recruiter
                  </p>
                  <p
                    className={`rolecardstext mt-2 ${
                      role === "Recruiter" ? "recruiter-text" : "default-text"
                    }`}
                  >
                    I have access to all tools and students can contact me
                    directly via my profile.
                  </p>
                </div>
              </div>
              <div>
                <div
                  className={`rolecards  ${
                    role === "Team" ? "selectedrolecardmobile" : ""
                  }`}
                  style={{
                    maxWidth: "390px",
                    height: "210px",
                    padding: "29px 24px",
                  }}
                  onClick={() => setRole("Team")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="49"
                    height="40"
                    viewBox="0 0 49 40"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M43.8375 24.5666L44.1158 25.7166C44.4188 26.699 44.1717 27.7437 43.4443 28.5547C42.7169 29.3657 41.5885 29.8547 40.3788 29.8833H32.8054C32.8072 32.5809 30.2564 34.7934 27.0409 34.8833H10.5623C8.74254 34.858 7.03621 34.1374 5.9288 32.9264C4.82138 31.7154 4.43414 30.1467 4.87725 28.6666L5.29468 27C5.78006 24.704 8.13945 23.0194 10.9201 22.9833H26.7029C27.4073 22.2774 28.4478 21.8687 29.5455 21.8666H40.0607C41.9174 21.9163 43.4855 23.0373 43.8375 24.5666ZM40.339 27.3666C40.5815 27.3591 40.8074 27.2614 40.9552 27.1C41.2152 26.88 41.3462 26.5751 41.313 26.2666L41.0347 25.1166C40.9809 24.7441 40.6255 24.453 40.18 24.4166H29.6647C29.4359 24.4179 29.2217 24.5108 29.0883 24.6666C28.5182 25.2171 27.6916 25.5332 26.8222 25.5333H11.0393C9.67853 25.5611 8.53829 26.4047 8.33596 27.5333L7.91853 29.2C7.71465 30.0208 7.95485 30.8752 8.57449 31.5333C9.05852 32.0451 9.78393 32.3553 10.5623 32.3833H26.9812C28.581 32.2942 29.8104 31.1604 29.7641 29.8166C29.7966 28.4555 31.122 27.3664 32.7457 27.3666H40.339Z"
                      fill={role === "Team" ? "#E31B54" : "#000000"}
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M26.6632 13.3333C26.6632 17.0152 23.1034 20 18.7121 20C14.3209 20 10.761 17.0152 10.761 13.3333V10.7166C10.761 7.62384 13.7513 5.11664 17.4399 5.11664H20.0439C23.7093 5.14408 26.6633 7.64327 26.6632 10.7166V13.3333ZM17.4399 7.61664C15.398 7.61664 13.7427 9.00456 13.7427 10.7166L13.8023 13.3333C14.1196 15.4319 16.2488 17.0052 18.7717 17.0052C21.2947 17.0052 23.4239 15.4319 23.7412 13.3333V10.7166C23.7412 9.00456 22.0858 7.61664 20.0439 7.61664H17.4399Z"
                      fill={role === "Team" ? "#E31B54" : "#000000"}
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M41.7305 13.75C41.7305 16.5114 39.0606 18.75 35.7672 18.75C32.4737 18.75 29.8039 16.5114 29.8039 13.75V11.8333C29.8035 9.5395 31.9981 7.66971 34.7335 7.63331H36.7213C39.4878 7.63331 41.7305 9.51371 41.7305 11.8333V13.75ZM34.7733 10.1666C33.6755 10.1666 32.7855 10.9128 32.7855 11.8333V13.75C32.7855 15.1307 34.1204 16.25 35.7672 16.25C37.4139 16.25 38.7488 15.1307 38.7488 13.75V11.8333C38.7488 10.9128 37.8589 10.1666 36.761 10.1666H34.7733Z"
                      fill={role === "Team" ? "#E31B54" : "#000000"}
                    />
                  </svg>
                  <p
                    className={`rolecardsheading mt-2 ${
                      role === "Team" ? "recruiter-text" : "default-text"
                    }`}
                  >
                    Team
                  </p>
                  <p
                    className={`rolecardstext mt-2 ${
                      role === "Team" ? "recruiter-text" : "default-text"
                    }`}
                  >
                    I have access to all tools but I don’t have a profile. I
                    will post via company profile.
                  </p>
                </div>
              </div>
              <div
                className=""
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div
                  className={`rolecards   ${
                    role === "Representative" ? "selectedrolecardmobile" : ""
                  }`}
                  style={{
                    maxWidth: "390px",
                    height: "210px",

                    padding: "29px 24px",
                  }}
                  onClick={() => setRole("Representative")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M26.6666 14.6C26.6122 17.3223 24.3895 19.5005 21.6666 19.5C18.9762 19.4557 16.8034 17.2902 16.75 14.6V12.7166C16.7496 11.6073 17.197 10.5447 17.9908 9.76978C18.7846 8.99482 19.8576 8.57302 20.9666 8.59997H22.5333C23.628 8.59554 24.6794 9.0273 25.455 9.79981C26.2306 10.5723 26.6666 11.6219 26.6666 12.7166V14.6ZM20.9166 11.05C19.9961 11.05 19.25 11.7962 19.25 12.7166L19.3166 14.6C19.3166 15.9347 20.3986 17.0166 21.7333 17.0166C23.068 17.0166 24.15 15.9347 24.15 14.6V12.7166C24.15 11.7962 23.4038 11.05 22.4833 11.05H20.9166Z"
                      fill={role === "Representative" ? "#E31B54" : "#000000"}
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M39.1333 25.5L39.3166 28.1C39.5092 28.9137 39.3212 29.7708 38.8055 30.4291C38.2899 31.0874 37.5028 31.4754 36.6666 31.4833H29.1833C28.5305 32.1792 27.6208 32.5768 26.6666 32.5833H16.6666C15.6643 32.5968 14.7047 32.1777 14.0333 31.4333C13.8782 31.4505 13.7217 31.4505 13.5666 31.4333H5.91662C5.10325 31.4026 4.34525 31.0132 3.84664 30.3698C3.34804 29.7265 3.16006 28.8953 3.33329 28.1L3.58329 25.5C3.82649 24.2594 4.90263 23.3568 6.16662 23.3333H13.3333C13.6324 23.3167 13.932 23.3563 14.2166 23.45C14.856 22.6512 15.8269 22.1904 16.85 22.2H26.4833C27.4465 22.1964 28.3642 22.6096 29 23.3333C29.116 23.3169 29.2339 23.3169 29.35 23.3333H36.55C37.8139 23.3568 38.8901 24.2594 39.1333 25.5ZM29.25 28.9333H36.8C36.851 28.8351 36.857 28.7197 36.8166 28.6166V28.2833L36.65 25.9166L36.55 25.7833H29C28.2954 25.7859 27.6228 25.489 27.15 24.9666C26.9846 24.7689 26.7411 24.6532 26.4833 24.65H16.85C16.5707 24.6536 16.3078 24.782 16.1333 25C15.6575 25.5648 14.9551 25.8885 14.2166 25.8833C13.9142 25.8847 13.6144 25.8281 13.3333 25.7166H6.24996C6.14996 25.7166 6.14996 25.75 6.14996 25.85L5.9833 28.2166V28.55C5.93766 28.6506 5.93766 28.766 5.9833 28.8666H13.6333C13.8107 28.8504 13.9892 28.8504 14.1666 28.8666C14.844 28.8792 15.4849 29.1755 15.9333 29.6833C16.1172 29.8985 16.3837 30.0257 16.6666 30.0333H26.7333C27.0168 30.0278 27.2841 29.9002 27.4666 29.6833C27.9353 29.2025 28.5786 28.932 29.25 28.9333Z"
                      fill={role === "Representative" ? "#E31B54" : "#000000"}
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M36.6166 17.1C36.6166 19.125 34.975 20.7666 32.95 20.7666C31.9746 20.7711 31.0377 20.3867 30.3465 19.6986C29.6552 19.0105 29.2666 18.0753 29.2666 17.1V15.7C29.2666 14.8822 29.5915 14.098 30.1697 13.5197C30.7479 12.9415 31.5322 12.6166 32.35 12.6166H33.5333C35.2362 12.6166 36.6166 13.9971 36.6166 15.7V17.1ZM31.9375 15.3542C31.8281 15.4636 31.7666 15.6119 31.7666 15.7666V17.1666C31.8376 17.7617 32.3423 18.2098 32.9416 18.2098C33.5409 18.2098 34.0456 17.7617 34.1166 17.1666V15.7666C34.1166 15.4445 33.8555 15.1833 33.5333 15.1833H32.35C32.1952 15.1833 32.0469 15.2448 31.9375 15.3542Z"
                      fill={role === "Representative" ? "#E31B54" : "#000000"}
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.3701 19.6986C11.6789 20.3867 10.742 20.7711 9.76663 20.7666C8.79417 20.7666 7.86154 20.3803 7.1739 19.6927C6.48627 19.0051 6.09996 18.0724 6.09996 17.1V15.7C6.09996 13.9971 7.48042 12.6166 9.18329 12.6166H10.3666C12.0695 12.6166 13.45 13.9971 13.45 15.7V17.1C13.45 18.0753 13.0614 19.0105 12.3701 19.6986ZM9.1833 15.1833C8.86113 15.1833 8.59996 15.4445 8.59996 15.7666V17.1666C8.67096 17.7617 9.17565 18.2098 9.77496 18.2098C10.3743 18.2098 10.879 17.7617 10.95 17.1666V15.7666C10.95 15.4445 10.6888 15.1833 10.3666 15.1833H9.1833Z"
                      fill={role === "Representative" ? "#E31B54" : "#000000"}
                    />
                  </svg>
                  <p
                    className={`rolecardsheading mt-2 ${
                      role === "Representative"
                        ? "recruiter-text"
                        : "default-text"
                    }`}
                  >
                    Representative
                  </p>
                  <p
                    className={`rolecardstext mt-2 ${
                      role === "Representative"
                        ? "recruiter-text"
                        : "default-text"
                    }`}
                  >
                    I’m just here to create a challenge or attent a varyance
                    live event.
                  </p>
                </div>
              </div>
            </Slider>
          )}
          {/* <div style={{ marginTop: "5rem" }}>
        <Recruiter />
      </div> */}
        </div>
        <div>
          <div
            className="d-flex mt-5"
            style={{ justifyContent: "end", gap: "20px" }}
          >
            {/* <button className="backbutton" onClick={() => navigate(-1)}>
              Back
            </button> */}
            <button className="continuebutton" onClick={() => handleSubmit()}>
              Continue
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default Body;
