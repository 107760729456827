const Message = ({ msg, otheruser, user, isNonMobile600 }) => {
  const sender = msg?.sender === otheruser?._id ? otheruser : user;

  function formatTimestampToTime(timestamp) {
    // Create a new Date object from the timestamp
    const date = new Date(timestamp);

    // Get the user's timezone offset in minutes

    // Adjust the date to the user's local time by adding the offset in minutes
    date.setMinutes(date.getMinutes());

    // Get the hours and minutes from the adjusted date object
    let hours = date.getHours();
    const minutes = date.getMinutes();

    // Determine the AM/PM suffix
    const ampm = hours >= 12 ? "PM" : "AM";

    // Convert hours from 24-hour format to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    // Format minutes to always have two digits
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

    // Return the formatted time string
    return `${hours}:${formattedMinutes} ${ampm}`;
  }

  return (
    <div className="d-flex " style={{ gap: "1rem" }}>
      <div>
        {sender && sender?.profilephoto && (
          <img
            src={sender?.profilephoto}
            height={"36px"}
            width={"36px"}
            alt="profile"
            className="companyheader__profilepic"
          />
        )}
        {sender && sender?.profilePhoto && (
          <img
            src={sender?.profilePhoto}
            height={"36px"}
            width={"36px"}
            alt="profile"
            className="companyheader__profilepic"
          />
        )}
      </div>
      <div className="w-100">
        <div className="space w-100">
          <p className="black14">
            {sender?.firstName} {sender?.lastName} {sender?.LastName}
          </p>
          <p className="companyLocation m-0">
            {formatTimestampToTime(msg?.timestamp)}
          </p>
        </div>

        <div
          className="allpoppins14 tablescroll"
          style={{
            marginTop: "1rem",
            width: isNonMobile600 ? "60%" : "70vw",
            whiteSpace: "pre-wrap",
            wordWrap: "break-word",
            overflowWrap: "break-word",
          }}
          dangerouslySetInnerHTML={{ __html: msg?.content }}
        />
      </div>
    </div>
  );
};
export default Message;
