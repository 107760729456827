import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";

const AwardsPopup = ({
  awards,
  setAwards,
  newAward,
  setNewAward,
  setIndex,
  setAddExperience,
  addExperience,
  loading,
  user,
  handleConfirmAward,
  handleUpdateAchievements,
}) => {
  const fileInputRef = React.createRef();

  const openFileInput = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();

      reader.onload = function (event) {
        const base64String = event.target.result;

        setNewAward((prev) => {
          return {
            ...prev,
            logo: base64String,
          };
        });
      };

      reader.readAsDataURL(file);
    } else {
      toast.error("Please select a valid image file.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  return (
    <div>
      <p className="black16 text-start">Awards & Scholarships</p>
      {!addExperience ? (
        <>
          {user?.awards?.map((cert, index) => (
            <div key={index} style={{ marginTop: "1.5rem" }}>
              <hr />
              <div
                className="d-flex justify-content-end"
                style={{ gap: "1rem" }}
              >
                <EditIcon
                  className="hoverred"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setIndex(index);
                    setNewAward(cert);
                    setAddExperience(true);
                  }}
                />
                <DeleteIcon
                  className="hoverred"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setAwards(awards.filter((_, i) => i !== index));
                  }}
                />
              </div>
              <div className="d-flex flex-wrap" style={{ gap: "1rem" }}>
                {cert?.logo && (
                  <img
                    src={cert?.logo}
                    alt="university logo"
                    className="shadowa rounded-3"
                    style={{
                      maxWidth: "32px",
                      maxHeight: "32px",
                      padding: "2px",
                      background: "#FFF",
                    }}
                  />
                )}
                <div>
                  {cert?.title && (
                    <p className="black16 text-start">{cert?.title}</p>
                  )}
                  {cert?.companyName && (
                    <p className="gray14" style={{ marginTop: "0.5rem" }}>
                      {cert?.companyName}
                    </p>
                  )}
                  {cert?.package && (
                    <p
                      className="gray14"
                      style={{ fontWeight: "600", marginTop: "0.75rem" }}
                    >
                      {cert?.package}
                    </p>
                  )}
                  {cert?.issue && (
                    <p className="gray14" style={{ marginTop: "0.75rem" }}>
                      Issued date: {cert?.issue}
                    </p>
                  )}
                  {cert?.description && (
                    <p className="gray14" style={{ marginTop: "0.75rem" }}>
                      {cert?.description}
                    </p>
                  )}
                </div>
              </div>
            </div>
          ))}
          <div className="mt-4">
            <div className="d-flex">
              <p
                className=" text-start backbutton hoverred mt-3"
                onClick={() => setAddExperience(true)}
                style={{ cursor: "pointer" }}
              >
                Add Award/Scholarship
              </p>
            </div>
            <hr className="mt-5" />
            <button
              className="continuebutton mt-2"
              onClick={() => handleUpdateAchievements()}
              disabled={loading}
            >
              Update Achievements
            </button>
          </div>
        </>
      ) : (
        <>
          <p className="black14 mt-4">Institute Name:</p>
          <input
            className="col-md-12 col-12 forminput mt-3"
            value={newAward?.companyName}
            placeholder="Enter Institute Name"
            onChange={(e) => {
              setNewAward((prev) => {
                return {
                  ...prev,
                  companyName: e.target.value,
                };
              });
            }}
          />

          <p className="black14 mt-4">Institute Logo(optional):</p>
          <div className="mt-3 border p-4 rounded-3">
            {newAward?.logo && (
              <div className="center">
                <img
                  src={
                    newAward?.logo instanceof File
                      ? URL.createObjectURL(newAward?.logo)
                      : newAward?.logo
                  }
                  alt="selectefFile"
                  className="rounded-2"
                  style={{ maxHeight: "200px", maxWidth: "200px" }}
                />
              </div>
            )}

            <div style={{ gap: "5px", justifyContent: "center" }}>
              <p
                className="text-center clicktoupload"
                onClick={openFileInput}
                style={{ cursor: "pointer" }}
              >
                {newAward?.logo
                  ? "Click to change Image"
                  : "Click to upload Image"}
              </p>
              <p className="svgpngtext">SVG, PNG, JPG or GIF (max. 12MB)</p>
            </div>

            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              style={{ display: "none" }}
              ref={fileInputRef}
            />
          </div>
          <p className="black14 mt-4">Award Title</p>
          <input
            className="col-md-12 col-12 forminput mt-3"
            value={newAward?.title}
            placeholder="Enter Award title"
            onChange={(e) => {
              setNewAward((prev) => {
                return {
                  ...prev,
                  title: e.target.value,
                };
              });
            }}
          />
          <p className="black14 mt-4">Issued Date:</p>
          <input
            className="col-md-12 col-12 forminput mt-3"
            value={newAward?.issue}
            type="date"
            placeholder="Enter Issued Date"
            onChange={(e) => {
              setNewAward((prev) => {
                return {
                  ...prev,
                  issue: e.target.value,
                };
              });
            }}
          />
          <p className="black14 mt-4">Award Position:</p>
          <input
            className="col-md-12 col-12 forminput mt-3"
            value={newAward?.package}
            type="text"
            placeholder="Provide info about level of Achievement"
            onChange={(e) => {
              setNewAward((prev) => {
                return {
                  ...prev,
                  package: e.target.value,
                };
              });
            }}
          />
          <p className="black14 mt-4">Description:</p>
          <input
            className="col-md-12 col-12 forminput mt-3"
            value={newAward?.description}
            type="text"
            placeholder="Explain the achievement"
            onChange={(e) => {
              setNewAward((prev) => {
                return {
                  ...prev,
                  description: e.target.value,
                };
              });
            }}
          />
          <button
            className="continuebutton mt-4 mb-5"
            onClick={() => handleConfirmAward()}
          >
            Confirm
          </button>
        </>
      )}
    </div>
  );
};
export default AwardsPopup;
