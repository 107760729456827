// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.black90020400 {
  color: var(--Gray-900, #101828);

  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 120% */
}
.Role-outer {
  display: flex;
  padding: 6px 10px;
  align-items: center;
  gap: 8px;
  border-radius: 30px;
  background: rgba(68, 76, 231, 0.1);
  color: var(--Secondary-600, #444ce7);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
}
.gray80014400 {
  color: var(--Gray-800, #1d2939);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.gray80016500 {
  color: var(--Secondary-Color-Gray-800, #1d2939);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}
`, "",{"version":3,"sources":["webpack://./src/pages/Profile/main.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;;EAE/B,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,SAAS;AAC9B;AACA;EACE,aAAa;EACb,iBAAiB;EACjB,mBAAmB;EACnB,QAAQ;EACR,mBAAmB;EACnB,kCAAkC;EAClC,oCAAoC;EACpC,oBAAoB;EACpB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,aAAa;AAClC;AACA;EACE,+BAA+B;EAC/B,oBAAoB;EACpB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,aAAa;AAClC;AACA;EACE,+CAA+C;EAC/C,oBAAoB;EACpB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,SAAS;AAC9B","sourcesContent":[".black90020400 {\n  color: var(--Gray-900, #101828);\n\n  font-size: 20px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 24px; /* 120% */\n}\n.Role-outer {\n  display: flex;\n  padding: 6px 10px;\n  align-items: center;\n  gap: 8px;\n  border-radius: 30px;\n  background: rgba(68, 76, 231, 0.1);\n  color: var(--Secondary-600, #444ce7);\n  font-family: Poppins;\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 16px; /* 133.333% */\n}\n.gray80014400 {\n  color: var(--Gray-800, #1d2939);\n  font-family: Poppins;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 20px; /* 142.857% */\n}\n.gray80016500 {\n  color: var(--Secondary-Color-Gray-800, #1d2939);\n  font-family: Poppins;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 24px; /* 150% */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
