import { useMediaQuery } from "@mui/material";

import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import FirstSection from "../Challenge/PreviewPage/FirstSection";
import Header from "../../components/CompanyHome/Header";
import LogHeader from "../PreviewPage/LoggedInHeader";
import SecondSection from "../Challenge/PreviewPage/SecondSection/SecondSection";
import ThirdSection from "../Challenge/PreviewPage/ThirdSection/ThirdSection";
import FourthSection from "../Challenge/PreviewPage/FourthSection/FourthSection";
import FifthSection from "../Challenge/PreviewPage/FifthSection/FifthSection";
import HomeFooter from "../../components/Home/HomeFooter";
import NewSection from "../Challenge/PreviewPage/NewSection/NewSection";
import NewFirstSection from "../Challenge/PreviewPage/NewFirstSection";
import NewSecondSection from "../Challenge/PreviewPage/SecondSection/NewSecondSection";
import TaskSection from "../Challenge/PreviewPage/Tasksection/TaskSection";
import PreviewWhole from "../Challenge/PreviewWhole/PreviewWholeChallenge";
const Preview = () => {
  //   const user = useSelector((state) => state.user);

  const [user, setUser] = useState([]);
  const Userloggedin = !!user;
  const [challenge, setChallenge] = useState({});
  const check = false;
  const [challengeUser, setChallengeUser] = useState({});
  const [Test, setTest] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [skills, setSkills] = useState([]);
  const [learns, setLearns] = useState([]);
  const [newTasks, setNewTasks] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [studentInfo, setStudentInfo] = useState("");
  const [information, setInformation] = useState("");
  const [type, setType] = useState("Virtual Experience");
  const [duration, setDuration] = useState("45");
  const [difficulty, setDifficulty] = useState("Basic");
  const [tools, setTools] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [company, setCompany] = useState();
  const [totalTime, setTotalTime] = useState(0);
  const { uniqueId } = useParams();
  const checker = true;
  const [no, setNo] = useState(false);

  const id = useSelector((state) => state.uniqueId);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userInfoResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/getUserInfo/uniqueId`,
          { email: id }
        );

        if (userInfoResponse.data.message === "Found") {
          setUser(userInfoResponse.data.user);
        } else {
          navigate("/company/register");
          return; // Exit early if user not found
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    if (uniqueId) {
      axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/get/specific/challenge`, {
          uniqueId,
          id: id ? null : user ? user._id : null,
        })
        .then((res) => {
          if (res.data.msg == "success") {
            setChallenge(res.data.challenge);
            setSelectedFile(res.data.challenge.selectedFile);
            setSkills(res.data.challenge.skills);
            setLearns(res.data.challenge.learns);
            setTitle(res.data.challenge.title);
            setDescription(res.data.challenge.description);
            setType(res.data.challenge.type);
            setDuration(res.data.challenge.duration);
            setDifficulty(res.data.challenge.difficulty);
            setTools(res.data.challenge.tools);
            setInformation(res.data.challenge.information);
            setSelectedVideo(res.data.challenge.selectedVideo);
            setCompany(JSON.parse(res.data.challenge.company));
            setChallengeUser(JSON.parse(res.data.challenge.user));
            setStudentInfo(res.data.challenge.studentInfo);
            setNewTasks(res.data.challenge.alltask);

            // Assuming res.data.challenge.alltask is an array of tasks and time is some additional time value
            let total = 0;
            res.data.challenge.alltask.map((task) => {
              total = total + parseInt(task.time);
            });
            console.log(total);
            setTotalTime(total);

            console.log(res.data);
          } else {
            navigate(-1);
          }
        })
        .catch((err) => console.log(err));
    } else {
      navigate(-1);
    }
  }, []);

  const isNonMob1024 = useMediaQuery("(min-width:1024px)");
  const maxLines = 4;
  const navigate = useNavigate();

  // const [user, setUser] = useState("");
  const getClampedText = (text, maxLines) => {
    const lineHeight = 1.2; // Adjust this value based on your line height
    const maxHeight = maxLines * lineHeight;

    return {
      maxHeight: `${maxHeight}em`,
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: maxLines,
    };
  };

  const [color, setColor] = useState("#E31b54");

  useEffect(() => {
    if (company?.color) {
      setColor(company?.color);
    }
  }, [company]);

  const [preview, setPreview] = useState(false);

  useEffect(() => {
    if (company && user) {
      if (user.company === company.uniqueId) {
        setNo(true);
      }
    }
  }, [company, user]);

  return (
    <>
      {preview ? (
        <PreviewWhole
          company={company}
          newTasks={newTasks}
          title={title}
          setPreview={setPreview}
          color={color}
        />
      ) : (
        <>
          <div>
            {Userloggedin ? (
              <LogHeader user={user} />
            ) : (
              <Header setTest={setTest} Test={Test} headerback={true} />
            )}

            <div style={{ marginTop: "50px" }}>
              {/* <FirstSection
            check={check}
            selectedFile={
              selectedFile && selectedFile?.includes("/uploads/")
                ? selectedFile
                : `/uploads/${selectedFile}`
            }
            company={company}
            type={type}
            title={title}
            description={description}
            test={true}
            Userloggedin={Userloggedin}
            uniqueId={uniqueId}
            checker={checker}
            color={color}
          /> */}
              <NewFirstSection
                check={check}
                selectedFile={
                  selectedFile && selectedFile?.includes("/uploads/")
                    ? selectedFile
                    : `/uploads/${selectedFile}`
                }
                company={company}
                type={type}
                title={title}
                description={description}
                test={true}
                Userloggedin={Userloggedin}
                uniqueId={uniqueId}
                checker={checker}
                color={color}
                setPreview={setPreview}
                no={no}
              />
              <div style={{ background: "#FFF" }}>
                <ThirdSection
                  selectedVideo={`${selectedVideo}`}
                  user={challengeUser}
                  type={type}
                  company={company}
                  learns={learns}
                  color={color}
                  title={title}
                />
              </div>
              <div>
                <NewSecondSection
                  difficulty={difficulty}
                  title={title}
                  information={information}
                  tools={tools}
                  studentInfo={studentInfo}
                  company={company}
                  color={color}
                  newTasks={newTasks}
                />
                <div
                  className="marginsleftrightCompany"
                  id="skills"
                  style={{ padding: "4rem 0rem" }}
                >
                  <div className="d-flex" style={{ justifyContent: "center" }}>
                    <div
                      className={`headerCompany`}
                      style={{ padding: "3.75rem 0rem" }}
                    >
                      <div
                        style={
                          {
                            // paddingTop: isNonMob1024 ? "10rem" : "5rem",
                            // marginBottom: "6.875rem",
                          }
                        }
                      >
                        <p
                          className="black16 text-start"
                          style={{
                            color: color,
                          }}
                        >
                          Skills you will learn
                        </p>
                        <div
                          className="space "
                          style={{
                            gap: isNonMob1024 ? "2.875rem" : "1rem",
                            marginTop: "0.75rem",
                            flexDirection: isNonMob1024 ? "row" : "column",
                          }}
                        >
                          <div
                            style={{ width: isNonMob1024 ? "36.3%" : "100%" }}
                          >
                            <div>
                              <p
                                className="sectionSectiontitle"
                                style={{ wordBreak: "keep-all" }}
                              >
                                Advance skills for your future career
                              </p>
                              <p
                                className="welcometo"
                                style={{ marginTop: "0.75rem" }}
                              >
                                Learn, apply and expand conceptual and technical
                                skills in this challenge relevant to your career
                                start.
                              </p>
                            </div>
                          </div>

                          <div style={{ width: isNonMob1024 ? "59%" : "100%" }}>
                            <div
                              className="d-flex flex-wrap"
                              style={{ gap: "0.875rem 1rem" }}
                            >
                              {learns &&
                                learns.map((ski, i) => (
                                  <div
                                    key={i}
                                    className=" skillsshow d-flex"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <p className="skillsshowtext"> {ski}</p>
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <SecondSection
            difficulty={difficulty}
            title={title}
            information={information}
            tools={tools}
            studentInfo={studentInfo}
            company={company}
            color={color}
          /> */}
              </div>
              {/* <div>
          <ThirdSection
            selectedVideo={`${selectedVideo}`}
            user={challengeUser}
            type={type}
            company={company}
            learns={learns}
            color={color}
          />
        </div> */}
              {/* <div>
          <NewSection challenge={challenge} color={color} />
        </div> */}
              <div className="newtasksbackground">
                <TaskSection
                  newTasks={newTasks}
                  color={color}
                  check={check}
                  setPreview={setPreview}
                  no={no}
                />
              </div>
              <div style={{ maxWidth: "100vw" }}>
                <FourthSection
                  type={type}
                  test={true}
                  totalTime={totalTime}
                  difficulty={difficulty}
                  totalSkills={skills.length}
                  totalLearns={learns.length}
                />
              </div>
              <div>
                <FifthSection
                  company={company}
                  setPreview={setPreview}
                  type={type}
                  Userloggedin={Userloggedin}
                  uniqueId={uniqueId}
                  checker={checker}
                  test={true}
                  color={color}
                  no={no}
                />
              </div>
              <div className="marginsleftrightCompany mt-5 pt-5">
                <div className="d-flex" style={{ justifyContent: "center" }}>
                  <div className="headerCompany">
                    {Userloggedin ? (
                      <>
                        {" "}
                        <div
                          className="d-flex mb-5"
                          style={{ justifyContent: "center" }}
                        >
                          <div className="d-flex" style={{ width: "90vw" }}>
                            <div
                              className="center"
                              style={{
                                height: "100%",
                                alignItems: "center",
                                position: "relative",
                              }}
                            >
                              <div
                                className=" shadowa center"
                                style={{
                                  borderRadius: "72px",
                                  height: "80px",
                                  width: "80px",
                                  padding: "1px",
                                  background: "#FFF",
                                  alignItems: "center",
                                  overflow: "hidden",
                                }}
                              >
                                <img
                                  src={user?.profilephoto}
                                  alt="profile"
                                  className="img-fluid"
                                  style={{ borderRadius: "72px" }}
                                />
                              </div>
                            </div>
                            <div className="m-3 mt-0 mb-0">
                              <p className="maybeLater">
                                Maybe later?
                                <button
                                  className="backtosearch btn"
                                  onClick={() =>
                                    navigate("/dashboard/Challenges")
                                  }
                                >
                                  {" "}
                                  Back to Challenges.
                                </button>
                              </p>
                              <p
                                className="col-md-5 black14"
                                style={{ fontWeight: "400" }}
                              >
                                Solve real-world challenges designed by
                                investment banks, law firms, consulting firms
                                and industry leaders across the globe.
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <HomeFooter />
                    )}
                  </div>
                </div>
              </div>{" "}
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default Preview;
