const FirstSection = ({ company, color, organizer }) => {
  return (
    <div style={{ marginTop: "8.25rem" }}>
      <div className="marginsleftrightCompany">
        <div className="d-flex" style={{ justifyContent: "center" }}>
          <div className={`headerCompany`}>
            <div
              className=" rounded-3 shadowa center"
              style={{
                background: "#fff",
                height: "72px",

                alignItems: "center",
                width: "72px",
                padding: "4.5px",
              }}
            >
              {company && company?.img && (
                <img
                  className="img-fluid rounded-1"
                  src={company?.img}
                  alt="company logo"
                />
              )}
            </div>
            <div
              style={{ marginTop: "2.375rem", gap: "1.25rem" }}
              className="space flex-wrap"
            >
              <div>
                {company && company?.title && (
                  <p className="registeration"> {company && company?.title}</p>
                )}
                {company && company?.tagline && (
                  <p
                    style={{ marginTop: "0.75rem", maxWidth: "560px" }}
                    className="black16  text-start"
                  >
                    {company?.tagline}
                  </p>
                )}
              </div>
              <div>
                {company && (
                  <p className="black14">
                    {company?.followers?.length} Followers
                  </p>
                )}
                <div style={{ marginTop: "1.5rem" }}>
                  <div className="d-flex flex-wrap" style={{ gap: "0.75rem" }}>
                    {/* <button
                      className="backbutton"
                      style={{
                        border: `1px solid ${color ? color : ""}`,
                        color: color ? color : "",
                      }}
                    >
                      Learn more
                    </button> */}

                    <button
                      className="continuebutton"
                      style={{ backgroundColor: color ? color : "" }}
                    >
                      Follow
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FirstSection;
