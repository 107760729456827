import { useEffect, useState } from "react";
import "./loader.css";
const Loader = () => {
  const [showText, setShowText] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowText(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      className="outerLoader transition"
      style={{ padding: !showText && "5px" }}
    >
      {!showText ? (
        <div className="spinner"></div>
      ) : (
        <div
          className="d-flex flex-column justify-content-end"
          style={{
            width: showText ? "100%" : "0px",
            height: showText ? "100%" : "0px",
            opacity: showText ? 1 : 0,
          }}
        >
          <p
            className="text-end loadertext"
            style={{ paddingRight: "3px", paddingBottom: "3px" }}
          >
            /v
          </p>
        </div>
      )}
    </div>
  );
};
export default Loader;
