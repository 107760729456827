import React, { useEffect, useRef } from "react";
import "./style.scss";
import { IoIosArrowBack } from "react-icons/io";
import logo from "../../../../assets/images/logo.png";
import flag from "../../../../assets/images/flags/flagGermany.png";
import interest1 from "../../../../assets/images/icons/money.png";
import interest2 from "../../../../assets/images/icons/airplane.png";
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import NameCard from "../../../Card/Namecard";
function Step2({ firstName, setFirstName, selectedItem, Countries }) {
  const inputRef = useRef(null);

  useEffect(() => {
    localStorage.setItem("firstName", JSON.stringify(firstName));
  }, [firstName]);

  useEffect(() => {
    // Focus on the input field when the component loads
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  // useEffect(() => {
  //   const adjustFontSize = () => {
  //     const input = inputRef.current;

  //     if (input) {
  //       const currentFontSize = parseInt(
  //         window.getComputedStyle(input).fontSize
  //       );
  //       const maxWidth = input.clientWidth;

  //       input.style.fontSize = "2rem"; // Set a default font size

  //       while (input.scrollWidth > maxWidth) {
  //         const newSize = parseInt(window.getComputedStyle(input).fontSize) - 1;
  //         input.style.fontSize = `${newSize}px`;
  //       }
  //     }
  //   };

  //   adjustFontSize();
  // }, [firstName]);

  return (
    <AnimatePresence>
      <motion.div
        id="Step2Ki33"
        initial={{ opacity: 0.2, scale: 0.2, x: "50%" }}
        animate={{ opacity: 1, scale: 1, x: 0 }}
        exit={{ opacity: 0.2, x: "-100%" }}
        transition={{ duration: 0.5 }}
      >
        <div className="stepWrapper ">
          {/* <div className="backButton" onClick={() => BackSetp()}>
          <h6>
            <IoIosArrowBack /> {"    "}
            Back
          </h6>
        </div> */}
          <div className="section_title ">
            <p className="sectionheadingclamped">Your first name</p>
          </div>
          <div className="Form_first_wrapper">
            <div className="">
              <div className="">
                <div className="Input_wrapper">
                  <input
                    ref={inputRef}
                    value={firstName}
                    type="text"
                    name="First_Name"
                    className="Name_field "
                    placeholder="type here"
                    onChange={(e) => setFirstName(e.target.value)}
                    autoComplete="off"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
}

export default Step2;
