import user from "../../assets/images/user.png";
import marketing from "../../assets/images/icons/marketing.png";
import chart from "../../assets/images/icons/Chart.png";
import search from "../../assets/images/searcha.png";
import ChallengeType from "./type";
import EmptyState from "../../assets/images/EmptyState.png";
import { useEffect, useRef, useState } from "react";

import { useInView, useMotionValue, useSpring } from "framer-motion";
import axios from "axios";
const NewCards = ({
  navigate,
  challenge,
  loading,
  engaged,
  complete,
  touch,
  shortlisted,
  usersWithDetails,
  isNonMob1024,
}) => {
  const Completions = () => {
    const [speakers, setSpeakers] = useState([]);

    useEffect(() => {
      if (
        challenge &&
        challenge?.countDates?.length > 0 &&
        usersWithDetails?.length > 0
      ) {
        let users = [];

        challenge?.countDates?.forEach((date) => {
          const user = usersWithDetails.find(
            (user) => user.userDetails?.user?._id === date.userId
          );

          if (user) {
            users.push(user.userDetails?.user.profilePhoto);
          }
        });

        setSpeakers(users);
      }
    }, [challenge, usersWithDetails]);

    return (
      <>
        {speakers.length > 0 && (
          <div
            className="d-flex align-items-center"
            style={{ position: "relative" }}
          >
            <p className="upcoming-host">Completions: </p>
            <div
              className="d-flex align-items-center"
              style={{ width: `${30 * 3}px` }}
            >
              {speakers.slice(0, 4).map((speaker, index) => (
                <img
                  className="upcoming-speaker-img shadowa"
                  src={speaker}
                  key={index}
                  alt="speaker"
                  height={"30px"}
                  width={"30px"}
                  style={{
                    position: "relative",
                    left: `-${index * 10}px`, // Adjust 10px according to overlap
                  }}
                />
              ))}
            </div>
            {speakers.length > 4 && (
              <p className="upcoming-host">{speakers.length - 4}+</p>
            )}
          </div>
        )}
      </>
    );
  };

  function NumberTicker({ value, direction = "up", delay = 0, className }) {
    const ref = useRef(null);
    const motionValue = useMotionValue(direction === "down" ? value : 0);
    const springValue = useSpring(motionValue, {
      damping: 60,
      stiffness: 100,
    });
    const isInView = useInView(ref, { once: true, margin: "0px" });

    useEffect(() => {
      if (isInView) {
        setTimeout(() => {
          motionValue.set(direction === "down" ? 0 : value);
        }, delay * 1000);
      }
    }, [motionValue, isInView, delay, value, direction]);

    useEffect(() => {
      springValue.on("change", (latest) => {
        if (ref.current) {
          const formattedValue = Number(latest.toFixed(0));
          // Ensure that 0 is displayed as well
          ref.current.textContent =
            Intl.NumberFormat("en-US").format(formattedValue);
        }
      });
    }, [springValue]);

    return (
      <span className={`${className}`} ref={ref}>
        {value === 0 ? 0 : null}
      </span>
    );
  }

  return (
    <div
      className={`d-flex ${isNonMob1024 ? "flex-row" : "flex-column"}`}
      style={{ gap: "1.5rem", position: "relative", width: "100%" }}
    >
      <div
        className="d-flex flex-wrap"
        style={{
          width: isNonMob1024 ? "31.9%" : "100%",
          position: "relative",
          gap: "1rem",
        }}
      >
        <div
          className="d-flex space"
          style={{ gap: "1rem", position: "relative", width: "100%" }}
        >
          <div
            style={{ width: "47.8%" }}
            className="selectedEvent-inner shadowa"
          >
            <div className="backbluediv">
              <img
                src={user}
                alt="user"
                style={{ height: "20px", width: "20px" }}
              />
            </div>
            <p className="gray50012500 " style={{ marginTop: "1rem" }}>
              Engaged Talent
            </p>
            <p className="gray95020400" style={{ marginTop: "0.25rem" }}>
              <NumberTicker value={engaged} className="gray95020400" />
            </p>
          </div>
          <div
            style={{ width: "47.8%" }}
            className="selectedEvent-inner shadowa"
          >
            <div className="backbluediv">
              <img
                src={marketing}
                alt="user"
                style={{ height: "20px", width: "20px" }}
              />
            </div>
            <p className="gray50012500 " style={{ marginTop: "1rem" }}>
              Challenge Completions
            </p>
            <p className="gray95020400" style={{ marginTop: "0.25rem" }}>
              <NumberTicker value={complete} className="gray95020400" />
            </p>
          </div>
        </div>
        <div
          className="d-flex space"
          style={{ gap: "1rem", position: "relative", width: "100%" }}
        >
          <div
            style={{ width: "47.8%" }}
            className="selectedEvent-inner shadowa"
          >
            <div className="backbluediv">
              <img
                src={search}
                alt="user"
                style={{ height: "20px", width: "20px" }}
              />
            </div>
            <p className="gray50012500 " style={{ marginTop: "1rem" }}>
              Shortlisted Talent
            </p>
            <p className="gray95020400" style={{ marginTop: "0.25rem" }}>
              <NumberTicker value={shortlisted} className="gray95020400" />
            </p>
          </div>
          <div
            style={{ width: "47.8%" }}
            className="selectedEvent-inner shadowa"
          >
            <div className="backbluediv">
              <img
                src={chart}
                alt="user"
                style={{ height: "20px", width: "20px" }}
              />
            </div>
            <p className="gray50012500 " style={{ marginTop: "1rem" }}>
              Touchpoints
            </p>
            <p className="gray95020400" style={{ marginTop: "0.25rem" }}>
              <NumberTicker value={touch} className="gray95020400" />
            </p>
          </div>
        </div>
      </div>
      <div
        className="selectedEvent-inner shadowa"
        style={{ width: isNonMob1024 ? "65.9%" : "100%", position: "relative" }}
      >
        <div className="space">
          <p className="gray95016500">Latest Challenge</p>{" "}
          <button
            className="bluebutton"
            onClick={() => {
              navigate("/dashboard/challenges");
            }}
          >
            see all
          </button>
        </div>
        {challenge ? (
          <div style={{ marginTop: "1rem" }} className="latestinginner">
            <p className="filmo16"> {challenge?.title}</p>
            <p style={{ marginTop: "1rem" }} className="gray60011400">
              {challenge?.description}
            </p>
            <div
              className="d-flex flex-wrap align-items-center"
              style={{ marginTop: "0.75rem", gap: "0.75rem" }}
            >
              <div
                style={{
                  height: "12px",
                  width: "12px",
                  borderRadius: "100px",
                  background: challenge?.draft ? "#F97066" : "#17B26A",
                }}
              />
              <ChallengeType type={challenge?.type} />
            </div>
            <div
              className="d-flex flex-wrap space align-items-center"
              style={{ marginTop: "2.125rem", gap: "1rem" }}
            >
              <Completions />
              <p className="upcoming-host">
                Published: <span>{challenge?.createdAt?.split("T")[0]}</span>
              </p>
              <button
                className="bluebutton"
                onClick={() => {
                  navigate(`/preview/${challenge?.uniqueId}`);
                }}
              >
                Preview Challenge
              </button>
            </div>
          </div>
        ) : (
          <>
            {loading ? (
              <p className="black16500 text-start"> Loading.. </p>
            ) : (
              <div
                className="center flex-column align-items-center"
                style={{ width: "100%", height: "90%" }}
              >
                <img
                  src={EmptyState}
                  alt="emptystate"
                  style={{ maxWidth: "86px", maxHeight: "70px" }}
                />
                <p className="pink16">No Data yet</p>
                <p
                  className="black14 text-center"
                  style={{
                    fontWeight: "500",
                    maxWidth: "332px",
                    marginTop: "1rem",
                  }}
                >
                  Accelerate your employer brand by designing real-world
                  challenges.
                </p>
                <button
                  className="continuebutton"
                  style={{ marginTop: "1rem" }}
                  onClick={() => {
                    navigate("/create/challenge");
                  }}
                >
                  Create now
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
export default NewCards;
