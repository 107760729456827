import AddIcon from "@mui/icons-material/Add";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import axios from "axios";
import Upcoming from "./Upcoming/Upcoming";
import Analytics from "./Analytics";
import SelectedEvent from "./SelectedEvent";
const EventMain = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [company, setCompany] = useState(null);
  const id = useSelector((state) => state.uniqueId);
  const com = useSelector((state) => state.company);
  const [DATA, setData] = useState(false);
  const [events, setEvents] = useState([]);
  const [draft, setDraft] = useState([]);
  const [owners, setOwners] = useState([]);

  const isNonMobile = useMediaQuery("(min-width:767px)");
  const isNonMobile1023 = useMediaQuery("(min-width:1023px)");
  const [selected, setSelected] = useState("Upcoming Events");
  const [selectedEvent, setSelectedEvent] = useState(null);

  useEffect(() => {
    const getChallenges = async () => {
      try {
        const companyInfo = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/getCompanyInfo`,
          { id: com }
        );
        setCompany(companyInfo.data);
        const userInfoResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/getUserInfo/uniqueId`,
          { email: id }
        );

        if (userInfoResponse.data.message === "Found") {
          setUser(userInfoResponse.data.user);
        } else {
          navigate("/company/register");
          return;
        }

        const Events = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/get/events/company`,
          { id: companyInfo.data.uniqueId }
        );

        if (Events.data.msg === "success") {
          setEvents(Events.data.events);
          setDraft(Events.data.draft);
          setOwners(Events.data.owners);
          console.log(Events.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    if (com) {
      getChallenges();
    }
  }, [com, DATA]);

  function getDayOfWeek(datetime) {
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const date = new Date(datetime);
    return days[date.getDay()]; // getDay() returns an index from 0 to 6
  }

  // Function to return the day of the month (e.g., 16, 25, 28)
  function getDayOfMonth(datetime) {
    const date = new Date(datetime);
    return date.getDate(); // getDate() returns the day of the month
  }

  function isWithinNextThreeDays(eventDatetime) {
    const eventDate = new Date(eventDatetime);
    const now = new Date();

    // Get the difference in time
    const timeDiff = eventDate.getTime() - now.getTime();

    // Convert time difference from milliseconds to days
    const dayDiff = timeDiff / (1000 * 3600 * 24);

    return dayDiff >= 0 && dayDiff <= 3;
  }

  function getTimeRange(datetime, duration) {
    const startTime = new Date(datetime);

    // Parse the duration and convert to minutes
    let durationMinutes = 0;
    if (duration === "30 mins") {
      durationMinutes = 30;
    } else if (duration === "1 hour") {
      durationMinutes = 60;
    } else if (duration === "1:30 hour") {
      durationMinutes = 90;
    } else if (duration.includes("hour")) {
      const [hours, mins] = duration.split(":");
      durationMinutes = parseInt(hours) * 60 + parseInt(mins) || 0;
    }

    // Calculate end time by adding duration to start time
    const endTime = new Date(startTime.getTime() + durationMinutes * 60000);

    // Format times as HH.MM
    const formatTime = (date) => {
      const hours = date.getHours();
      const minutes = date.getMinutes() === 0 ? ":00" : date.getMinutes();
      return `${hours}.${minutes}`;
    };

    return `${formatTime(startTime)}-${formatTime(endTime)}`;
  }

  return (
    <div className="paddingmaincontent mb-5 pb-3">
      <>
        <div
          className={`transition ${isNonMobile && "d-flex"} `}
          style={{
            justifyContent: "space-between",
            flexFlow: "wrap",
            gap: "10px",
          }}
        >
          <div className=" behindbuttons ">
            <button
              className={`  border-0 d-flex ${
                selected === "Upcoming Events"
                  ? "selectedbutton rounded-2 "
                  : "secondbuttontext"
              } ${isNonMobile ? "" : "p-1  "}`}
              style={
                isNonMobile
                  ? {
                      minWidth: "188px",
                    }
                  : {}
              }
              onClick={() => {
                setSelected("Upcoming Events");
              }}
            >
              Upcoming Events{" "}
            </button>
            <button
              className={` border-0 d-flex  ${
                selected === "Past Events"
                  ? "selectedbutton rounded-2 "
                  : "secondbuttontext"
              } ${isNonMobile ? "" : "p-1  "}`}
              style={isNonMobile ? { minWidth: "188px" } : {}}
              onClick={() => {
                setSelected("Past Events");
              }}
            >
              Past Events
            </button>

            <button
              className={`   border-0 d-flex ${
                selected === "Your Drafts"
                  ? "selectedbutton rounded-2 "
                  : "secondbuttontext"
              } ${isNonMobile ? "" : "p-1  "}`}
              style={isNonMobile ? { minWidth: "188px" } : {}}
              onClick={() => {
                setSelected("Your Drafts");
              }}
            >
              Your Drafts
            </button>
          </div>
          <div
            className={`d-flex  ${!isNonMobile && "mt-3"}`}
            style={{
              gap: "10px",
            }}
          >
            <button
              className="d-flex border continuebuttonsmaller"
              style={{ gap: "10px" }}
              onClick={() => {
                navigate("/create/event");
              }}
            >
              <AddIcon /> Create new Event
            </button>
          </div>
        </div>

        <div style={{ marginTop: "1.25rem" }}>
          {selected === "Upcoming Events" && (
            <Upcoming
              events={events}
              setSelectedEvent={setSelectedEvent}
              selectedEvent={selectedEvent}
              future={true}
              getDayOfWeek={getDayOfWeek}
              getDayOfMonth={getDayOfMonth}
              isWithinNextThreeDays={isWithinNextThreeDays}
              getTimeRange={getTimeRange}
              setData={setData}
            />
          )}
          {selected === "Past Events" && (
            <Upcoming
              events={events}
              setSelectedEvent={setSelectedEvent}
              selectedEvent={selectedEvent}
              future={false}
              getDayOfWeek={getDayOfWeek}
              getDayOfMonth={getDayOfMonth}
              isWithinNextThreeDays={isWithinNextThreeDays}
              getTimeRange={getTimeRange}
              setData={setData}
            />
          )}
          {selected === "Your Drafts" && (
            <Analytics events={draft} setData={setData} owners={owners} />
          )}
        </div>
      </>
    </div>
  );
};
export default EventMain;
