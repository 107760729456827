import Slider from "react-slick";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { useMediaQuery } from "@mui/material";
import "./cards.css";
import StudentCard from "./StudentCard";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const Cards = ({ shortlisted }) => {
  const isNonMobile1024 = useMediaQuery("(min-width:1024px)");

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className="Next_arrow p-2 rounded-5 shadowa "
        style={{
          ...style,
          right: "0",
          zIndex: 1,
          position: "absolute",
          top: "110%",
          transform: "translateY(-50%)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          backgroundColor: "#FFF",
        }}
        onClick={onClick}
      >
        <MdKeyboardArrowRight size={24} />
      </div>
    );
  }

  const GetUserinfo = (short) => {};

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className="Prev_arrow p-2 rounded-5 shadowa"
        style={{
          ...style,
          left: "0",
          zIndex: 1,
          position: "absolute",
          top: "110%",
          transform: "translateY(-50%)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          backgroundColor: "#FFF",
        }}
        onClick={onClick}
      >
        <MdKeyboardArrowLeft size={24} />
      </div>
    );
  }

  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 100,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          dots: true,
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          dots: true,
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          dots: true,
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div
      className={` StudentsShortlist`}
      style={{ width: "100%", overflow: "hidden" }}
    >
      <div className="" style={{ position: "relative" }}>
        {shortlisted && shortlisted.length > 0 ? (
          <Slider
            {...settings}
            style={{
              justifyContent: "center",

              //   maxWidth: isNonMobile1024 ? "71vw" : "",
            }}
          >
            {shortlisted[0].list?.length > 0 &&
              shortlisted[0].list.map((short, index) => (
                <div key={index} style={{ maxWidth: "364px" }}>
                  <StudentCard short={short} />
                </div>
              ))}
            {shortlisted[0].list?.length > 0 &&
              shortlisted[0].list.map((short, index) => (
                <div key={index} style={{ maxWidth: "364px" }}>
                  <StudentCard short={short} />
                </div>
              ))}
            {shortlisted[0].list?.length > 0 &&
              shortlisted[0].list.map((short, index) => (
                <div key={index} style={{ maxWidth: "364px" }}>
                  <StudentCard short={short} />
                </div>
              ))}
            {shortlisted[0].list?.length > 0 &&
              shortlisted[0].list.map((short, index) => (
                <div key={index} style={{ maxWidth: "364px" }}>
                  <StudentCard short={short} />
                </div>
              ))}
            {shortlisted[0].list?.length > 0 &&
              shortlisted[0].list.map((short, index) => (
                <div key={index} style={{ maxWidth: "364px" }}>
                  <StudentCard short={short} />
                </div>
              ))}
            {shortlisted[0].list?.length > 0 &&
              shortlisted[0].list.map((short, index) => (
                <div key={index} style={{ maxWidth: "364px" }}>
                  <StudentCard short={short} />
                </div>
              ))}
            {shortlisted[0].list?.length > 0 &&
              shortlisted[0].list.map((short, index) => (
                <div key={index} style={{ maxWidth: "364px" }}>
                  <StudentCard short={short} />
                </div>
              ))}
          </Slider>
        ) : (
          <p className="black-16">No Short listed Users yet</p>
        )}
      </div>
    </div>
  );
};
export default Cards;
