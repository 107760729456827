import React, { useState, useEffect } from "react";
import logo from "../../assets/images/logo.png";
import menuPic from "../../assets/images/menuPic.png";
import { useMediaQuery } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { Spiral as Hamburger } from "hamburger-react";
import zIndex from "@mui/material/styles/zIndex";
const NewHeader = ({ setTest, Test, headerback }) => {
  const isNonMobile = useMediaQuery("(min-width:768px)");
  const isNonMobile1440 = useMediaQuery("(min-width:1440px)");
  const [isVisible, setIsVisible] = useState(true);
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();
  const [lastScrollY, setLastScrollY] = useState(0);
  const location = useLocation();
  const path = location.pathname.toLowerCase();

  useEffect(() => {
    const handleScroll = () => {
      if (!showMenu) {
        const currentScrollY = window.scrollY;
        const scrollThreshold = window.innerHeight / 2;

        if (currentScrollY > scrollThreshold) {
          if (currentScrollY > lastScrollY) {
            // Scrolling down
            setIsVisible(false);
          } else {
            // Scrolling up
            setIsVisible(true);
          }
        } else {
          setIsVisible(true);
        }

        setLastScrollY(currentScrollY);
      }
    };

    const handleMouseMove = (event) => {
      const mouseY = event.clientY;
      const headerThreshold = 30;
      if (mouseY <= headerThreshold) {
        setIsVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, [lastScrollY]);

  return (
    <>
      <div
        className={`headernew ${isVisible ? "" : "hidden"}`}
        style={{
          background: headerback ? "transparent" : "",
          position: isVisible ? "sticky !important" : "",
          top: isVisible ? "0" : "",
        }}
      >
        <div className={`headerhomepageCompany `}>
          <div className=" headerCompany" style={{}}>
            <div className="justifySpace">
              <div className="d-flex" style={{ alignItems: "center" }}>
                <img
                  src={logo}
                  alt="logo"
                  className="logoimage "
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/");

                    window.scrollTo(0, 0);
                  }}
                />
              </div>
              <div className="welcomemenucontainer">
                <button
                  className={`${
                    isNonMobile
                      ? "continuebutton pulsingButton"
                      : "continuebutton"
                  } `}
                  style={{
                    maxHeight: isNonMobile ? "48px" : "36px",
                    border: "none",
                  }}
                  onClick={() => {
                    if (path === "/company") {
                    } else {
                      navigate("/register");
                    }
                  }}
                >
                  {path === "/company" ? "Let's Talk" : "Join now"}
                </button>
                <button
                  className="menubuttonhomepage p-0"
                  style={{
                    background: "transparent",
                    color: headerback ? (Test ? "" : "#FFF") : "",
                  }}
                  onClick={() => {
                    setTest(!Test);
                    setShowMenu(!showMenu);
                  }}
                >
                  {isNonMobile && "menu"}
                  <Hamburger toggled={Test} size={24} direction="right" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`menu ${showMenu ? "activee" : "inactive"}`}
        style={{
          width: "100vw",
          height: "calc(100vh-61px)",
          background: "#F9FAFB",
          zIndex: 100,
          display: "flex",
          top: "61px",
          justifyContent: "center",
          position: "fixed",
        }}
      >
        <div
          className="d-flex marginsleftrightCompany"
          style={{
            flexDirection: "column",
            justifyContent: "space-between",
            width: "100%",
            zIndex: 100,
          }}
        >
          <div style={{ zIndex: 100 }}>
            <div
              className={`${isNonMobile ? "d-flex" : ""} headermenumargintop `}
              style={{ justifyContent: "center", zIndex: 100 }}
            >
              {isNonMobile && (
                <div>
                  <img
                    src={menuPic}
                    alt="menu"
                    className="img-fluid"
                    style={{ minWidth: "244px" }}
                  />
                </div>
              )}
              <div>
                <p className=" menutextinmenu p-2">MENU</p>
                <div
                  className="d-flex menubuttongaps"
                  style={{
                    flexDirection: "column",
                    justifyContent: "space-between",
                    zIndex: 100,
                  }}
                >
                  <button
                    className="menubuttons"
                    style={{ marginTop: "14px" }}
                    onClick={() => {
                      navigate("/");
                      setShowMenu(false);
                      setTest(false);
                    }}
                  >
                    Home
                  </button>
                  <button
                    className="menubuttons"
                    style={{ marginTop: "14px" }}
                    onClick={() => {
                      navigate("/company");
                      setShowMenu(false);
                      setTest(false);
                    }}
                  >
                    Employer Hub
                  </button>
                  <button
                    className="menubuttons"
                    style={{ marginTop: "14px" }}
                    onClick={() => {
                      navigate("/career-centers");
                      setShowMenu(false);
                      setTest(false);
                    }}
                  >
                    Career Centers
                  </button>
                  <button
                    className="menubuttons"
                    style={{ marginTop: "14px" }}
                    onClick={() => {
                      // navigate("/magazine");
                      window.location.href = "https://varyance.io/magazine";
                      setShowMenu(false);
                      setTest(false);
                    }}
                  >
                    Magazine
                  </button>
                </div>
                <button
                  className="Loginbuttoninmenu"
                  onClick={() => {
                    if (path === "/company") {
                      navigate("/company/login");
                    } else {
                      navigate("/login");
                    }
                  }}
                >
                  {path === "/company" ? "Company Login" : "Student Login"}
                </button>
              </div>
            </div>
          </div>
          <div
            className="d-flex "
            style={{ justifyContent: "center", width: "100%", zIndex: 100 }}
          >
            <div
              className="headerCompany d-flex"
              style={{
                marginBottom: "40px",
                justifyContent: "space-between",
                zIndex: 100,
              }}
            >
              <div
                className={` d-flex `}
                style={{
                  justifyContent: isNonMobile ? "space-between" : "center",
                  flexDirection: isNonMobile ? "row" : "column",
                  width: "100%",
                  zIndex: 100,
                }}
              >
                <p className="ltd">© All rights reserved</p>
                <div
                  className="d-flex"
                  style={{
                    gap: "2rem",
                    marginTop: isNonMobile ? "" : "1rem",
                    zIndex: 100,
                  }}
                >
                  <a
                    className="footertext"
                    href={`${process.env.REACT_APP_FRONTEND_URL}/privacy`}
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    Privacy Policy
                  </a>
                  <a
                    className="footertext"
                    href={`${process.env.REACT_APP_FRONTEND_URL}/terms`}
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    Terms of Use
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewHeader;
