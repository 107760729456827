import React, { useState } from "react";
import { useMediaQuery } from "@mui/material";
import finance from "../../assets/images/icons/Finance.png";
import cardbackground from "../../assets/images/cardbackground.png";
import logo from "../../assets/images/logo.png";
import HomeFourthSection from "./HomeFourthSection";
const HomeThirdSection = ({
  selectedUni,
  setSelectedUni,
  country,
  setCountry,
  Countries,
  unilist,
  loading,
  checker,
  truncateString,
  setChecker,
}) => {
  const isNonMobile = useMediaQuery("(min-width:768px)");
  const isNonMobile1025 = useMediaQuery("(min-width:1025px)");
  const isNonMobile1441 = useMediaQuery("(min-width:1441px)");
  const isNonMobile450 = useMediaQuery("(min-width:450px)");
  const [MouseHover, setStartHover] = useState(false);
  const skills = [
    "Company Analysis",
    "Data Interpretation",
    "DFC Analysis",
    "M&A Screening",
    "M&A Process Understanding",
  ];

  return (
    <>
      <div>
        {/* <div>
        <div
          className="d-flex"
          style={{ justifyContent: "center", position: "relative" }}
        >
          <div
            className={`HomeThirdSection headerCompany   ${
              isNonMobile && "d-flex sectioncenter"
            }`}
          >
            <div className="widthfirst">
              <p className=" sparkInterest">
                Stand <span>Out</span>
              </p>
              <p className="undercaseStudy">
                <span className="undercase2">Master challenges</span> designed
                by <span className="undercase1">world-leading firms</span> and
                advance skills relevant to
                <span className="undercase3"> your future career</span>.
              </p>
              <div
                className={`marginfromabove ${isNonMobile1441 && "d-flex"} `}
                style={{ gap: "0.5rem" }}
              >
                <p className={` ${isNonMobile1441 && "col-md-6"} getpoints`}>
                  Get points for different skills learned, showcase your skills
                  to future employers and receive badges for outstanding
                  achievements in your chosen career field.
                </p>
                <div className={` ${isNonMobile1441 && "col-md-6"}`}>
                  <div
                    className="d-flex flex-wrap "
                    style={{
                      gap: "0.625rem 0.5rem",

                      marginTop: isNonMobile1441 ? "" : "1rem",
                    }}
                  >
                    <div className="skillswrapper">
                      <img
                        src={finance}
                        alt="finance"
                        height="17px"
                        width="17px"
                      />
                      Finance <span>Top 5%</span>
                    </div>
                    {skills.map((ski, index) => (
                      <div className="skillswrapper">{ski}</div>
                    ))}
                  </div>
                </div>
              </div>
              <button
                className="btn startonboard p-0 d-flex"
                style={{ gap: "1rem" }}
                onMouseEnter={() => setStartHover(true)}
                onMouseLeave={() => setStartHover(false)}
              >
                Start Onboarding
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M20.05 11.47L13.05 4.47C12.8571 4.28243 12.5787 4.21204 12.3198 4.28533C12.0609 4.35863 11.8608 4.56447 11.7948 4.82533C11.7289 5.08619 11.8071 5.36243 12 5.55L17.71 11.25H4.47998C4.06577 11.25 3.72998 11.5858 3.72998 12C3.72998 12.4142 4.06577 12.75 4.47998 12.75H17.7L12 18.45C11.857 18.5893 11.7764 18.7804 11.7764 18.98C11.7764 19.1796 11.857 19.3707 12 19.51C12.1378 19.6546 12.3302 19.7345 12.53 19.73C12.729 19.7309 12.9201 19.6516 13.06 19.51L20.06 12.51C20.3524 12.2172 20.3524 11.7428 20.06 11.45L20.05 11.47Z"
                    fill={MouseHover ? "#A11043" : "#E31B54"}
                  />
                </svg>
              </button>
            </div>
            <div
              className="widthsecond d-flex"
              style={{
                justifyContent: isNonMobile ? "center" : "",
                marginTop: "1rem",
              }}
            >
              <div>
                <div className="card-outer">
                  <div className="blue-div">
                    <div className="card-center-background">
                      <div
                        className="d-flex logoimg-container rounded-3 shadow"
                        style={{
                          background: "#FFF",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "64px",
                          width: "64px",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="38"
                          height="38"
                          viewBox="0 0 38 38"
                          fill="none"
                        >
                          <path
                            d="M11.729 1.44628C14.0342 0.491503 16.5049 0 19 0V9.5C17.7524 9.5 16.5171 9.7457 15.3645 10.2232C14.2119 10.7006 13.1646 11.4003 12.2825 12.2825C11.4003 13.1646 10.7006 14.2119 10.2232 15.3645C9.7457 16.5171 9.5 17.7524 9.5 19H0C0 16.5049 0.491503 14.0342 1.44628 11.729C2.40117 9.42378 3.80065 7.32933 5.56494 5.56494C7.32933 3.80065 9.42378 2.40117 11.729 1.44628Z"
                            fill="#155EEF"
                          />
                          <path
                            d="M0 19C0 21.4951 0.491503 23.9658 1.44628 26.271C2.40117 28.5762 3.80065 30.6707 5.56494 32.4351C7.32933 34.1993 9.42378 35.5988 11.729 36.5537C14.0342 37.5085 16.5049 38 19 38C21.4951 38 23.9658 37.5085 26.271 36.5537C28.5762 35.5988 30.6707 34.1993 32.4351 32.4351C34.1993 30.6707 35.5988 28.5762 36.5537 26.271C37.5085 23.9658 38 21.4951 38 19H28.5C28.5 20.2476 28.2543 21.4829 27.7768 22.6355C27.2994 23.7881 26.5997 24.8354 25.7175 25.7175C24.8354 26.5997 23.7881 27.2994 22.6355 27.7768C21.4829 28.2543 20.2476 28.5 19 28.5C17.7524 28.5 16.5171 28.2543 15.3645 27.7768C14.2119 27.2994 13.1646 26.5997 12.2825 25.7175C11.4003 24.8354 10.7006 23.7881 10.2232 22.6355C9.7457 21.4829 9.5 20.2476 9.5 19H0Z"
                            fill="#84ADFF"
                          />
                          <path
                            d="M18.9986 12.9545C15.6598 12.9545 12.9531 15.6612 12.9531 19H25.044C25.044 15.6612 22.3374 12.9545 18.9986 12.9545Z"
                            fill="#84ADFF"
                          />
                          <path
                            d="M18.9986 25.0455C15.6598 25.0455 12.9531 22.3388 12.9531 19H25.044C25.044 22.3388 22.3374 25.0455 18.9986 25.0455Z"
                            fill="#155EEF"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="" style={{ padding: "1rem" }}>
                      <p className="tasks90 text-start">2 Tasks | 90min</p>
                      <p
                        style={{ marginTop: "12px" }}
                        className="financialmodelling"
                      >
                        Financial Modelling and M&A Process Understanding
                      </p>
                      <p className="clouds" style={{ marginTop: "0.25rem" }}>
                        Clouds Capital
                      </p>
                    </div>
                  </div>
                  <div
                    className="d-flex"
                    style={{
                      marginTop: "11px",
                      justifyContent: "space-between",
                    }}
                  >
                    <p
                      style={{
                        display: "inline-flex",
                        gap: "10px",
                        justifyContent: "center",
                        alignItems: "center",
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M4.84111 1.66669H15.1586C16.9119 1.66669 18.3332 3.08801 18.3332 4.84129V15.1588C18.3332 16.912 16.9119 18.3334 15.1586 18.3334H4.84111C3.08782 18.3334 1.6665 16.912 1.6665 15.1588V4.84129C1.6665 3.08801 3.08782 1.66669 4.84111 1.66669ZM15.1586 17.1429C16.2544 17.1429 17.1427 16.2546 17.1427 15.1588V4.84129C17.1427 3.74549 16.2544 2.85716 15.1586 2.85716H4.84111C3.7453 2.85716 2.85698 3.74549 2.85698 4.84129V15.1588C2.85698 16.2546 3.7453 17.1429 4.84111 17.1429H15.1586Z"
                          fill="#0C111D"
                        />
                        <path
                          d="M14.3649 7.42066H11.984C11.6552 7.42066 11.3887 7.68715 11.3887 8.01589C11.3887 8.34464 11.6552 8.61113 11.984 8.61113H12.7221L9.89666 11.4365L8.30936 9.84923C7.88815 9.42947 7.20677 9.42947 6.78555 9.84923L4.71412 11.8572C4.48202 12.0896 4.48202 12.466 4.71412 12.6984C4.82514 12.8108 4.97677 12.8738 5.13476 12.873C5.29293 12.8747 5.4449 12.8116 5.55539 12.6984L7.51571 10.7381L9.10301 12.3254C9.52694 12.7461 10.2108 12.7461 10.6348 12.3254L13.7379 9.22224V10.3968C13.7379 10.7256 14.0044 10.9921 14.3332 10.9921C14.6619 10.9921 14.9284 10.7256 14.9284 10.3968V8.01589C14.9249 7.70073 14.6794 7.44137 14.3649 7.42066Z"
                          fill="#0C111D"
                        />
                      </svg>
                      Fast-Track
                    </p>
                    <button className="continuebutton">Start</button>
                  </div>
                </div>

                <div
                  className="d-flex col-md-12 col-12"
                  style={{ gap: "0.75rem", marginTop: "2rem" }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                  >
                    <path
                      d="M10 6.66956C9.60825 6.71472 9.3126 7.04641 9.3126 7.44076C9.3126 7.8351 9.60825 8.16679 10 8.21195C10.2058 8.21481 10.4039 8.1336 10.5485 7.98708C10.6931 7.84057 10.7717 7.64142 10.7661 7.43565C10.7606 7.01485 10.4208 6.67506 10 6.66956Z"
                      fill="#475467"
                    />
                    <path
                      d="M10 9.61134C9.79598 9.60856 9.59951 9.68838 9.45523 9.83266C9.31096 9.97693 9.23114 10.1734 9.23391 10.3774V13.5644C9.23391 13.9875 9.5769 14.3304 10 14.3304C10.4231 14.3304 10.7661 13.9875 10.7661 13.5644V10.3979C10.7716 10.1912 10.6933 9.99101 10.5491 9.84289C10.4048 9.69478 10.2068 9.61126 10 9.61134Z"
                      fill="#475467"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10 0.5C4.47949 0.50563 0.00563031 4.97949 0 10.5C0 16.0228 4.47715 20.5 10 20.5C15.5228 20.5 20 16.0228 20 10.5C19.9944 4.97949 15.5205 0.50563 10 0.5ZM10 18.9678C5.32335 18.9678 1.53218 15.1767 1.53218 10.5C1.53218 5.82335 5.32335 2.03218 10 2.03218C14.6767 2.03218 18.4678 5.82335 18.4678 10.5C18.4622 15.1743 14.6743 18.9622 10 18.9678Z"
                      fill="#475467"
                    />
                  </svg>
                  <p className="exemplary">Exemplary Challenge Card</p>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <HomeFourthSection
          unilist={unilist}
          selectedUni={selectedUni}
          setSelectedUni={setSelectedUni}
          country={country}
          setCountry={setCountry}
          Countries={Countries}
          loading={loading}
          checker={checker}
          setChecker={setChecker}
          truncateString={truncateString}
          isNonMobile1025={isNonMobile1025}
        />
        {/* <div className="d-flex" style={{ justifyContent: "center" }}>
          <div
            className="lastSection headerCompany d-flex"
            style={{
              justifyContent: isNonMobile ? "space-between" : "center",
              flexDirection: isNonMobile ? "row" : "column",
            }}
          >
            <p className={`startjourney ${isNonMobile && "section"}`}>
              Hi campus recruiter!
              <br />
              Learn more about varyance.
            </p>
            <div
              className="d-flex "
              style={{
                justifyContent: "center",
                alignItems: "center",
                marginTop: isNonMobile ? "" : "1rem",
              }}
            >
              <div
                className={`${isNonMobile450 ? "d-flex" : ""} col-12`}
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <button
                  className={`backbutton ${isNonMobile450 ? "" : "col-12"}`}
                >
                  Learn More
                </button>
                {isNonMobile450 && (
                  <button
                    className={`continuebutton  ${
                      isNonMobile450 ? "" : "col-12 mt-3"
                    }`}
                  >
                    Let's Talk
                  </button>
                )}
              </div>
            </div>
          </div>
        </div> */}
        <hr className="horiline " />
      </div>
    </>
  );
};

export default HomeThirdSection;
