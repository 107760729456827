import { useMediaQuery } from "@mui/material";
import earth from "../../../assets/images/icons/Aviation.png";
import { useState, useEffect, useRef } from "react";
import SchoolIcon from "@mui/icons-material/School";
import { useSelector, useDispatch } from "react-redux";
import { FixedSizeList as List } from "react-window";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import France from "../../../assets/images/countries/France.png";
import Switzerland from "../../../assets/images/countries/Switzerland.png";
import uniicon from "../../../assets/images/uniicon.png";
import UnitedKingdom from "../../../assets/images/countries/United Kingdom.png";
import Germany from "../../../assets/images/countries/Germany.png";
import Estonia from "../../../assets/images/countries/Estonia.png";

import Romania from "../../../assets/images/countries/Romania.png";
import Finland from "../../../assets/images/countries/Finland.png";
import Georgia from "../../../assets/images/countries/Georgia.png";
import Greece from "../../../assets/images/countries/Greece.png";
import Hungary from "../../../assets/images/countries/Hungary.png";
import deleteicon from "../../../assets/images/unsaved.png";

import Ireland from "../../../assets/images/countries/Ireland.png";
import Italy from "../../../assets/images/countries/Italy.png";

import Jordan from "../../../assets/images/countries/Jordan.png";

import Latvia from "../../../assets/images/countries/Latvia.png";
import Liechtenstein from "../../../assets/images/countries/Liechtenstein.png";
import Lithuania from "../../../assets/images/countries/Lithuania.png";
import Luxembourg from "../../../assets/images/countries/Luxembourg.png";
import Malta from "../../../assets/images/countries/Malta.png";
import Moldova from "../../../assets/images/countries/Moldova.png";
import Netherlands from "../../../assets/images/countries/Netherlands.png";
import NewZealand from "../../../assets/images/countries/New Zealand.png";

import NorthMacedonia from "../../../assets/images/countries/North Macedonia.png";
import Norway from "../../../assets/images/countries/Norway.png";

import Poland from "../../../assets/images/countries/Poland.png";
import Portugal from "../../../assets/images/countries/Portugal.png";
import Qatar from "../../../assets/images/countries/Qatar.png";

import Singapore from "../../../assets/images/countries/Singapore.png";
import Slovakia from "../../../assets/images/countries/Slovakia.png";
import Slovenia from "../../../assets/images/countries/Slovenia.png";

import Spain from "../../../assets/images/countries/Spain.png";
import Sweden from "../../../assets/images/countries/Sweden.png";

import Turkey from "../../../assets/images/countries/Turkey.png";

import axios from "axios";
import { toast } from "react-toastify";
import "./target.css";
import AllTouchpoints from "../AllTouchpoints";
import { useNavigate } from "react-router-dom";
import Talent from "../../../components/Company/Dashboard/EngagedTalent";
import SelectedBox from "../../../assets/images/icons/selectedbox";
import UnselectedBox from "../../../assets/images/icons/unselectedBox";
const TargetSchool = () => {
  const isNonMob1024 = useMediaQuery("(min-width:1024px)");
  const isNonMobile = useMediaQuery("(min-width:768px)");
  const [selected, setSelected] = useState("All Touchpoints");
  const [selectedUniversities, setSelectedUniversities] = useState([]);
  const [unilist, setUniList] = useState([]);
  const [filteredresults, setFilteredresults] = useState([]);
  const [appliedUnis, setAppliedUnis] = useState([]);
  const [country, setCountry] = useState("");
  const [loading, setLoading] = useState(false);
  const [showUni, setShowUni] = useState(false);
  const popupref = useRef(null);
  const [search, setSearch] = useState(null);
  const [selectedUni, setSelectedUni] = useState();
  const isNonMobile1440 = useMediaQuery("(min-width:1440px)");
  const [changeLocation, ShowChangeLocation] = useState(country ? false : true);
  const isNonMob1441 = useMediaQuery("(min-width:1500px)");
  const [popup, setPopup] = useState(false);
  const [removelist, setRemoveList] = useState([]);
  const Countries = [
    {
      icon: France,
      name: "France",
    },

    {
      icon: Germany,
      name: "Germany",
    },
    {
      icon: UnitedKingdom,
      name: "United Kingdom",
    },
    {
      icon: Switzerland,
      name: "Switzerland",
    },
    {
      icon: Estonia,
      name: "Estonia",
    },

    {
      icon: Finland,
      name: "Finland",
    },

    {
      icon: Georgia,
      name: "Georgia",
    },

    {
      icon: Greece,
      name: "Greece",
    },

    {
      icon: Hungary,
      name: "Hungary",
    },

    {
      icon: Ireland,
      name: "Ireland",
    },

    {
      icon: Italy,
      name: "Italy",
    },

    {
      icon: Latvia,
      name: "Latvia",
    },

    {
      icon: Liechtenstein,
      name: "Liechtenstein",
    },

    {
      icon: Lithuania,
      name: "Lithuania",
    },

    {
      icon: Luxembourg,
      name: "Luxembourg",
    },

    {
      icon: Malta,
      name: "Malta",
    },

    {
      icon: Moldova,
      name: "Moldova",
    },

    {
      icon: Netherlands,
      name: "Netherlands",
    },

    {
      icon: NewZealand,
      name: "New Zealand",
    },

    {
      icon: NorthMacedonia,
      name: "North Macedonia",
    },

    {
      icon: Norway,
      name: "Norway",
    },

    {
      icon: Poland,
      name: "Poland",
    },

    {
      icon: Portugal,
      name: "Portugal",
    },

    // {
    //   icon: Qatar,
    //   name: "Qatar",
    // },

    {
      icon: Romania,
      name: "Romania",
    },

    // {
    //   icon: Singapore,
    //   name: "Singapore",
    // },

    {
      icon: Slovakia,
      name: "Slovakia",
    },

    {
      icon: Slovenia,
      name: "Slovenia",
    },

    {
      icon: Spain,
      name: "Spain",
    },

    {
      icon: Sweden,
      name: "Sweden",
    },

    {
      icon: Turkey,
      name: "Turkey",
    },
  ];

  const unilistinputdivRef = useRef(null);
  const [countrySearchList, SetCountriesList] = useState(Countries);

  const email = useSelector((state) => state.email);
  const [user, setUser] = useState([]);
  const [results, setResults] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState([]);
  const dispatch = useDispatch();
  const [analytics, setAnalytics] = useState("12 months");
  const navigate = useNavigate();

  const [company, setCompany] = useState();
  const id = useSelector((state) => state.uniqueId);
  const [challenges, setChallenges] = useState([]);
  const [jobposts, setJobposts] = useState([]);
  const [blocked, setBlocked] = useState(null);
  const [bulkAction, setBulkAction] = useState("");
  const [DATA, setData] = useState(false);
  const [searchFilter, setSearchFilter] = useState("");
  const [realRes, setRealRes] = useState([]);
  const [livechallengesLength, setLength] = useState(0);
  const [total, setTotal] = useState(0);
  const handleShortList = async (ida) => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/company/shortlist/student`,
        {
          id: ida,
          companyId: company.uniqueId,
          msg: "sjdfhajksdfjkdashfjhasdjfhaksfasjk",
          type: "Talentpool",
          owner: user._id,
        }
      )
      .then((res) => {
        if (res.data.msg === "success") {
          toast.success("Added to Shortlist");
          setData((prev) => !prev);
        }
      });
  };

  const handleBulkAction = () => {
    if (bulkAction === "Shortlist") {
      if (selectedStudents.length > 0) {
        selectedStudents.map(async (id) => {
          await axios
            .post(
              `${process.env.REACT_APP_BACKEND_URL}/api/company/shortlist/student`,
              {
                id: id,
                companyId: company.uniqueId,
                msg: "sjdfhajksdfjkdashfjhasdjfhaksfasjk",
                type: "Talentpool",
                owner: user._id,
              }
            )
            .then((res) => {
              if (res.data.msg === "success") {
                setData((prev) => !prev);
              }
            });
        });
        toast.success("Added successfully");
        setSelectedStudents([]);
      }
    } else if (bulkAction === "Remove") {
      if (selectedStudents.length > 0) {
        selectedStudents.map((id) => {
          axios
            .post(
              `${process.env.REACT_APP_BACKEND_URL}/api/company/blocked/student`,
              {
                id: id,
                companyId: company.uniqueId,
                msg: "sjdfhajksdfjkdashfjhasdjfhaksfasjk",
                type: "Talentpool",
                owner: user._id,
              }
            )
            .then((res) => {
              if (res.data.msg === "success") {
                setData((prev) => !prev);
              }
            });
        });
        toast.success("Removed successfully");
        setSelectedStudents([]);
      }
    }
  };

  const handleRemove = async (ida) => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/company/blocked/student`,
        {
          id: ida,
          companyId: company.uniqueId,
          msg: "sjdfhajksdfjkdashfjhasdjfhaksfasjk",
          type: "Talentpool",
          owner: user._id,
        }
      )
      .then((res) => {
        if (res.data.msg === "success") {
          toast.success("Removed");
          setData((prev) => !prev);
        }
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userInfoResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/getUserInfo/uniqueId`,
          { email: id }
        );

        if (userInfoResponse.data.message === "Found") {
          setUser(userInfoResponse.data.user);
          //   dispatch(setEmployer({ employer: userInfoResponse.data.user }));
        } else {
          navigate("/company/register");
          return; // Exit early if user not found
        }

        const companyInfoResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/getCompanyInfo`,
          { id: userInfoResponse.data.user.company }
        );
        if (companyInfoResponse.data.msg === "Logout") {
          navigate("/company/register");
          return;
        }

        setCompany(companyInfoResponse.data);

        const resultres = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/get/challenges/results`,
          {
            uniqueId: companyInfoResponse.data.uniqueId,
          }
        );

        // dispatch(set({ company: companyInfoResponse.data.uniqueId }));
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/get/company/challenges`,
          {
            uniqueId: companyInfoResponse.data.uniqueId,
          }
        );
        const blockedC = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/company/get/blocked/student`,
          {
            companyId: companyInfoResponse.data.uniqueId,
            msg: "sdakfksjfbjkasfjkfdas",
            userId: userInfoResponse.data.user._id,
            type: "Challenge",
          }
        );

        if (resultres.data.msg === "success") {
          if (blockedC.data.msg === "success") {
            let challen = [...resultres.data.results];

            challen = challen.filter((item) => {
              return !blockedC?.data?.list.some((block) =>
                block.list.some((bl) => bl.user === item.user._id)
              );
            });

            setResults(challen);
            setFilteredresults(challen);
            setRealRes(challen);
          } else {
            setResults(resultres.data.results);
            setFilteredresults(resultres.data.results);
            setRealRes(resultres.data.results);
          }
        } else {
          setResults([]);
          setFilteredresults([]);
          setRealRes([]);
        }

        const blocked = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/company/get/blocked/student`,
          {
            companyId: companyInfoResponse.data.uniqueId,
            msg: "sdakfksjfbjkasfjkfdas",
            type: "Talentpool",
            userId: userInfoResponse.data.user._id,
          }
        );

        if (blocked.data.msg === "success") {
          setBlocked(blocked.data.list);
        }

        if (response.data.msg === "success") {
          setChallenges(response.data.challenges);
          setLength(response.data.challenges.length);
        } else if (response.data.msg === "Record not found") {
          console.log("");
        } else {
          toast.error("Error Fetching Challenges");
        }
        const responsea = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/get/company/jobs`,
          {
            uniqueId: companyInfoResponse.data.uniqueId,
          }
        );
        if (responsea.data.msg === "success") {
          setJobposts(responsea.data.challenges);
        } else if (responsea.data.msg === "Record not found") {
          setJobposts([]);
        } else {
          setJobposts([]);
          toast.error("Error Fetching Job posts");
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [id, DATA]);

  const handleBulkActionChallenge = async () => {
    if (bulkAction === "Shortlist") {
      if (selectedStudent.length > 0) {
        selectedStudent.map(async (id) => {
          await axios
            .post(
              `${process.env.REACT_APP_BACKEND_URL}/api/company/shortlist/student`,
              {
                id: id,
                companyId: company.uniqueId,
                msg: "sjdfhajksdfjkdashfjhasdjfhaksfasjk",
                type: "Challenge",
                owner: user._id,
              }
            )
            .then((res) => {
              if (res.data.msg === "success") {
                setData((prev) => !prev);
              }
            });
        });
        toast.success("Added successfully");
        setSelectedStudent([]);
      }
    } else if (bulkAction === "Remove") {
      if (selectedStudent.length > 0) {
        selectedStudent.map(async (id) => {
          await axios
            .post(
              `${process.env.REACT_APP_BACKEND_URL}/api/company/blocked/student`,
              {
                id: id,
                companyId: company.uniqueId,
                msg: "sjdfhajksdfjkdashfjhasdjfhaksfasjk",
                type: "Challenge",
                owner: user._id,
              }
            )
            .then((res) => {
              if (res.data.msg === "success") {
                setData((prev) => !prev);
              }
            });
        });
        toast.success("Removed successfully");
        setSelectedStudent([]);
      }
    }
  };

  const handleAddUniversity = (ui) => {
    const universityIndex = selectedUniversities.findIndex(
      (sui) => sui.name === ui.name
    );

    if (universityIndex !== -1) {
      // If the university is already in the list, remove it
      const updatedUniversities = [...selectedUniversities];
      updatedUniversities.splice(universityIndex, 1);
      setSelectedUniversities(updatedUniversities);
    } else {
      if (selectedUniversities.length >= 8) {
        toast.error("Can't add more than 8 universities");
      } else {
        setSelectedUniversities([...selectedUniversities, ui]);
      }
    }
  };

  const filterCountries = () => {
    if (!search) {
      SetCountriesList(Countries); // Reset searchList if search input is empty
    } else {
      const filteredUniversities = Countries.filter((uni) =>
        uni.name.toLowerCase().includes(search.toLowerCase())
      );
      SetCountriesList(filteredUniversities);
    }
  };

  // Update searchList whenever search state changes
  useEffect(() => {
    filterCountries();
  }, [search]);

  const handleUniClick = () => {
    setShowUni(!showUni);
  };
  const [searchList, setSearchList] = useState(unilist);

  useEffect(() => {
    setSearchList(unilist);
  }, [unilist]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        unilistinputdivRef.current &&
        !unilistinputdivRef.current.contains(event.target)
      ) {
        setShowUni(false); // Close showUni when clicked outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (country) {
        setLoading(true);
        await axios
          .post(`${process.env.REACT_APP_BACKEND_URL}/get/university`, {
            name: country.name,
          })
          .then((res) => {
            if (res.data.msg === "success") {
              setUniList(res.data.university);
              setLoading(false);
            } else {
              console.log(res.data);
            }
          })
          .catch((err) => {
            setLoading(false);
          });

        // setUniList(data);
        setLoading(false);
      }
    };
    fetchData();
  }, [country]);

  const isValidImage = async (base64String) => {
    try {
      await new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve();
        img.onerror = () => reject();
        img.src = `${base64String}`;
      });
      return true; // Image loaded successfully
    } catch (error) {
      return false; // Error occurred while loading image
    }
  };

  const ValidateLogo = async (base64String) => {
    return await isValidImage(base64String);
  };

  const renderUniversityItem = ({ index, style }) => {
    const uni = searchList[index];
    return (
      <div
        key={index}
        className="d-flex unilists "
        style={{
          ...style,
          gap: "0.5rem",
          width: "97%",
          // marginLeft: "1rem",
          // marginTop: "1.25rem",
          alignItems: "center",
        }}
        onClick={() => {
          handleAddUniversity(uni);
          //   setSelectedUni(uni);
          //   setShowUni(false);
          //   dispatch(setUni({ Uni: uni }));
        }}
      >
        {uni.logo && ValidateLogo(uni.logo) ? (
          <img
            src={`${uni.logo}`}
            alt="University Logo"
            style={{ width: "40px", height: "40px", objectFit: "contain" }}
            className="Interest_img_icon shadowa rounded-5"
          />
        ) : (
          <img
            src={uniicon}
            alt="University Logo"
            style={{ width: "40px", height: "40px", objectFit: "contain" }}
            className="Interest_img_icon shadowa rounded-5"
          />
        )}
        <p
          className="uniselecttext d-flex hoverdarkblack"
          style={{ alignItems: "center" }}
        >
          {uni.name}
        </p>
        <div>
          {selectedUniversities.some((concept) => concept.name === uni.name) ? (
            <>
              <SelectedBox />
            </>
          ) : (
            <UnselectedBox />
          )}
        </div>
      </div>
    );
  };

  const renderUniversityList = () => (
    <List
      height={250}
      itemCount={searchList.length}
      itemSize={isNonMobile ? 55 : 70}
      width={"100%"}
      className="overflowx whitescrollbar"
    >
      {renderUniversityItem}
    </List>
  );

  const [totalchal, setTotalChallenges] = useState(0);

  useEffect(() => {
    if (appliedUnis.length > 0) {
      setFilteredresults(() => {
        const filtered = results.filter((chk) => {
          return appliedUnis.some((uni) => uni.name === chk?.user?.uni?.name);
        });
        setTotalChallenges(filtered.length);
        return filtered;
      });
    } else {
      setFilteredresults(results);
      setTotalChallenges(results.length);
    }
  }, [appliedUnis, results]);

  const filterUniversities = () => {
    if (!search) {
      setSearchList(unilist); // Reset searchList if search input is empty
    } else {
      const filteredUniversities = unilist.filter((uni) =>
        uni.name.toLowerCase().includes(search.toLowerCase())
      );
      setSearchList(filteredUniversities);
    }
  };

  // Update searchList whenever search state changes
  useEffect(() => {
    filterUniversities();
  }, [search]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (popupref.current && !popupref.current.contains(event.target)) {
        setPopup(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="paddingmaincontent">
      <div>
        {popup && (
          <div
            className="center align-items-center"
            style={{
              position: "fixed",
              height: "100vh",
              width: "100vw",
              top: 0,
              left: 0,
              backgroundColor: "rgba(45, 50, 130, 0.30)",
              backdropFilter: "blur(3px)",
              zIndex: 11,
            }}
          >
            <div
              className="shadowa newPopUpContainer"
              ref={popupref}
              style={{
                maxWidth: "90vw",
                maxHeight: "80vh",
                padding: "1.5rem",
                maxWidth: "700px",
                backgroundColor: "",
                width: "600px",
                padding: "1rem",
              }}
            >
              <div className="space align-items-center">
                <div
                  className="d-flex"
                  style={{ gap: "1rem", alignItems: "center" }}
                >
                  <div className="d-flex " style={{ gap: "1rem" }}>
                    <div className="circle-background">
                      <img
                        src={deleteicon}
                        alt="delete"
                        height="48px"
                        width="48px"
                      />
                    </div>
                    <div>
                      <p className="gray90018500">Remove Universties</p>
                      <p className="gray70014400">
                        If you've reached your limit of 8 target schools,
                        <br /> you can unselect some to replace them with
                        others.
                      </p>
                    </div>
                  </div>
                </div>
                <CloseIcon
                  className="hoverdarkblacksvg"
                  style={{ cursor: "pointer", height: "24px", width: "24px" }}
                  onClick={() => {
                    setPopup(false);
                    setRemoveList([]);
                  }}
                />
              </div>
              <div className="py-2" style={{ marginTop: "1.5rem" }}>
                <div
                  style={{
                    maxHeight: "240px",
                    gap: "1.25rem",
                    overflow: "scroll",
                  }}
                  className="tablescroll d-flex flex-column px-2"
                >
                  {appliedUnis &&
                    appliedUnis.map((uni, index) => (
                      <div
                        key={index}
                        className="space align-items-center"
                        style={{ gap: "1rem", cursor: "pointer" }}
                        onClick={() => {
                          if (
                            removelist.some((unis) => unis.name === uni?.name)
                          ) {
                            setRemoveList(
                              removelist.filter(
                                (unis) => unis.name !== uni?.name
                              )
                            );
                          } else {
                            setRemoveList([...removelist, uni]);
                          }
                        }}
                      >
                        <div
                          className="d-flex align-items-center"
                          style={{ gap: "0.5rem" }}
                        >
                          <img
                            src={uni?.logo}
                            alt="uni logo"
                            style={{ maxHeight: "40px", maxWidth: "40px" }}
                          />
                          <p className="buttonblack12"> {uni?.name}</p>
                        </div>
                        <div
                          className="center align-items-center"
                          style={{ height: "20px", width: "20px" }}
                        >
                          {removelist.some(
                            (concept) => concept.name === uni.name
                          ) ? (
                            <>
                              <SelectedBox />
                            </>
                          ) : (
                            <UnselectedBox />
                          )}
                        </div>
                      </div>
                    ))}
                </div>
                <div
                  className="d-flex justify-content-end"
                  style={{ gap: "1.25rem", marginTop: "2rem" }}
                >
                  <button
                    className="backbutton"
                    onClick={() => {
                      setRemoveList([]);
                      setPopup(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="continuebutton"
                    onClick={() => {
                      setAppliedUnis(
                        appliedUnis.filter(
                          (uni) =>
                            !removelist.some((unis) => unis.name === uni?.name)
                        )
                      );
                      setPopup(false);
                    }}
                  >
                    Remove
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        <div
          className={`d-flex flex-wrap`}
          style={{
            gap: "1.25rem",
            justifyContent: "space-between",
          }}
        >
          <div
            className="outerglass"
            style={{ width: isNonMob1024 ? "67%" : "100%" }}
          >
            <div className="innerglass">
              <img src={earth} alt="" height="20px" width={"20px"} />
              {/* <p
                className="black10 text-start"
                style={{ marginTop: "0.75rem" }}
              >
                Campus Recruiting
              </p> */}
              <p
                className=" text-start"
                style={{ marginTop: "0.75rem", fontSize: "18px" }}
              >
                {" "}
                Select Target Schools{" "}
              </p>
              <p
                className="gray14"
                style={{ marginTop: "0.5rem", color: "#344054" }}
              >
                Accelerate your campus recruiting at select target schools
                worldwide.
              </p>
              <div style={{ marginTop: "2.25rem" }}>
                <span
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    position: "relative",
                    // top: isNonMobile1024 ? "-5px" : "10px",
                    gap: "1rem",

                    alignItems: "center",
                  }}
                  className="mt-4"
                >
                  <div
                    className="unilistinputdiv d-flex"
                    ref={unilistinputdivRef}
                    onClick={handleUniClick}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="uniselect">
                      {selectedUni &&
                      selectedUni.logo &&
                      ValidateLogo(selectedUni.logo) ? (
                        <img
                          src={`${selectedUni.logo}`}
                          className="rounded-3"
                          alt={uniicon}
                          style={{
                            objectFit: "contain",
                            maxWidth: isNonMobile1440 ? "40px" : "32px",
                            maxHeight: isNonMobile1440 ? "40px" : "32px",
                            // maxWidth: "100%",
                            // maxHeight: "100%",
                          }}
                        />
                      ) : (
                        <div
                          className={`rounded-5 d-flex ${
                            isNonMobile1440 ? "p-2" : "p-1"
                          } notselecteduni `}
                          style={{
                            height: isNonMobile1440 ? "40px" : "32px",
                            width: isNonMobile1440 ? "40px" : "32px",
                            backgroundColor: country ? "#FFF" : "",
                          }}
                        >
                          <SchoolIcon
                            style={{
                              color: "white",
                            }}
                          />
                        </div>
                      )}

                      <p className="uniselecttext">
                        {selectedUni ? selectedUni.name : "Select university"}
                      </p>
                    </div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M5 7.5L10 12.5L15 7.5"
                        stroke="#1D2939"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>

                  {showUni && (
                    <div
                      ref={unilistinputdivRef}
                      style={{
                        position: "absolute",
                        overflow: "hidden",
                        top: "0",
                        zIndex: 10,
                      }}
                      className="showUnilistmodal "
                    >
                      <div className="paddingsinsearchlist">
                        <div className="searchinput">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M19 19L15.5001 15.5M18 9.5C18 14.1944 14.1944 18 9.5 18C4.80558 18 1 14.1944 1 9.5C1 4.80558 4.80558 1 9.5 1C14.1944 1 18 4.80558 18 9.5Z"
                              stroke="#475467"
                              strokeWidth="1.70603"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          <input
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            type="text"
                            placeholder="Search"
                            className="searchinputuni"
                          />
                        </div>
                        <hr style={{ width: "97%" }} />
                        <div
                          className=""
                          style={{ overflow: "auto", maxHeight: "20.438rem" }}
                        >
                          {changeLocation ? (
                            countrySearchList.map((con, index) => (
                              <div
                                key={index} // Make sure to add a unique key when mapping
                                className="d-flex unilists"
                                style={{ gap: "0.5rem", marginTop: "1.25rem" }}
                                onClick={() => {
                                  setCountry(con);
                                  ShowChangeLocation(false);
                                  setSearch(""); // Assuming 'con' is the selected university in this context
                                }}
                              >
                                <img
                                  src={con.icon}
                                  alt="uni logo"
                                  height="40px"
                                  width="40px"
                                  className="Interest_img_icon rounded-5"
                                />
                                <p
                                  className="uniselecttext d-flex hoverred"
                                  style={{ alignItems: "center" }}
                                >
                                  {con.name}
                                </p>
                              </div>
                            ))
                          ) : (
                            <>
                              {loading ? (
                                <div
                                  className="d-flex"
                                  style={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <p
                                    style={{
                                      fontSize: "1rem",
                                      fontFamily: "Poppins",
                                      fontWeight: "500",
                                    }}
                                  >
                                    Fetching Universities
                                  </p>
                                  <div class="loader"></div>
                                </div>
                              ) : (
                                <>{renderUniversityList()}</>
                              )}
                            </>
                          )}
                          <div
                            className="d-flex unilists"
                            style={{ gap: "0.5rem", marginTop: "1.25rem" }}
                            onClick={() => {
                              setSelectedUni({ name: "other" });

                              setShowUni(false);
                            }}
                          >
                            <p
                              className="uniselecttext d-flex"
                              style={{ alignItems: "center" }}
                            >
                              other
                            </p>
                          </div>
                        </div>
                        <hr style={{ width: "97%" }} />
                      </div>
                      <div className="bottomsearchlist">
                        <div
                          className="d-flex"
                          style={{
                            justifyContent: "space-between",
                          }}
                        >
                          <div className="newdashboardsidechips ">
                            {country ? (
                              <>
                                <img
                                  src={country.icon}
                                  alt="country flag"
                                  width="16px"
                                  height="16px"
                                />
                                {country.name}
                              </>
                            ) : (
                              "select country"
                            )}
                          </div>
                          <button
                            className="changelocationbutton btn p-0 hoverdarkred"
                            onClick={() => ShowChangeLocation(true)}
                          >
                            {" "}
                            Change Location
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                  <div
                    className="d-flex"
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <button
                      className="continuebutton "
                      style={{
                        height: "50px",
                        position: "relative",
                        maxHeight: isNonMobile1440 ? "" : "48px",
                      }}
                      disabled={selectedUniversities.length === 0}
                      onClick={() => {
                        setAppliedUnis(selectedUniversities);
                        setSelectedUniversities([]);
                      }}
                    >
                      Add Schools
                    </button>
                  </div>
                </span>
              </div>
            </div>
          </div>
          <div
            className="outerglass"
            style={{ width: isNonMob1024 ? "30%" : "" }}
          >
            <div className="innerglass" style={{ height: "100%" }}>
              <div className="d-flex  space" style={{ alignItems: "center" }}>
                <div className="d-flex">
                  <p className="pink12 d-flex" style={{ fontSize: "14px" }}>
                    Selected
                  </p>
                </div>
                {/* <div
                  className="rounded-5"
                  style={{
                    height: "14px",
                    width: "14px",
                    background:
                      appliedUnis.length === 0 ? "#D0D5DD" : "#5FDCB3",
                  }}
                /> */}
                {appliedUnis.length > 0 && (
                  <EditIcon
                    className="hoverdarkblacksvg"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      if (appliedUnis.length > 0) {
                        setPopup(!popup);
                      }
                    }}
                  />
                )}
              </div>

              <p className="registeration" style={{ marginTop: "1rem" }}>
                {appliedUnis.length}{" "}
                <span className="registeration" style={{ fontSize: "1.5rem" }}>
                  Universities
                </span>
              </p>
              <div className="d-flex" style={{ marginTop: "2rem" }}>
                {appliedUnis.length > 0 && (
                  <div className="logo-overlap">
                    {appliedUnis.map((uni, index) => (
                      <img
                        key={index}
                        src={uni.logo}
                        className="rounded-5 shadow"
                        alt="uni logo"
                        height="33px"
                        width="33px"
                        style={{
                          zIndex: appliedUnis.length - index,
                          position: "relative",
                          background: "#FFF",
                        }} // Ensure correct stacking order
                      />
                    ))}
                  </div>
                )}
              </div>
              <p
                className="black12"
                style={{
                  marginTop: "0.75rem",
                  fontSize: "14px",
                  lineHeight: "20px",
                  color: "#344054",
                }}
              >
                <span
                  className="black12"
                  style={{
                    fontWeight: "600",
                    fontSize: "14px",
                    lineHeight: "20px",
                    color: "#344054",
                  }}
                >
                  {" "}
                  Max. 8 universities can be selected.
                </span>{" "}
                If you recruit from more universities please contact support.
              </p>
            </div>
          </div>
        </div>
        <div
          className={`transition ${isNonMobile && "d-flex"} `}
          style={{
            justifyContent: "space-between",
            flexFlow: "wrap",
            gap: "10px",
            marginTop: "1.25rem",
          }}
        >
          <div className=" behindbuttons ">
            <button
              className={`  border-0 d-flex ${
                selected === "All Touchpoints"
                  ? "selectedbutton rounded-2 "
                  : "secondbuttontext"
              } ${isNonMobile ? "" : "p-1  "}`}
              style={
                isNonMobile
                  ? {
                      minWidth: "188px",
                    }
                  : {}
              }
              onClick={() => {
                setSelected("All Touchpoints");
              }}
            >
              All Touchpoints
              {total > 0 && <div className="countheading">{total}</div>}
            </button>
            <button
              className={` border-0  d-flex ${
                selected === "Challenges"
                  ? "selectedbutton rounded-2 "
                  : "secondbuttontext"
              } ${isNonMobile ? "" : "p-1  "}`}
              style={isNonMobile ? { minWidth: "188px" } : {}}
              onClick={() => {
                setSelected("Challenges");
              }}
            >
              Challenges{" "}
              {totalchal > 0 && <div className="countheading">{totalchal}</div>}
            </button>
            <button
              className={`   border-0 ${
                selected === "Applications"
                  ? "selectedbutton rounded-2 "
                  : "secondbuttontext"
              } ${isNonMobile ? "" : "p-1  "}`}
              style={isNonMobile ? { minWidth: "188px" } : {}}
              onClick={() => {
                setSelected("Applications");
              }}
            >
              Applications
            </button>
          </div>
        </div>

        <div style={{ marginTop: "4.5rem" }}>
          {selected === "All Touchpoints" && (
            <AllTouchpoints
              checker={[]}
              TRUE={true}
              handleBulkAction={handleBulkAction}
              isNonMob768={isNonMobile}
              challenges={challenges}
              appliedUnis={appliedUnis}
              jobposts={jobposts}
              analytics={analytics}
              isNonMob1024={isNonMob1024}
              selectedStudents={selectedStudents}
              setSelectedStudents={setSelectedStudents}
              bulkAction={bulkAction}
              setBulkAction={setBulkAction}
              handleShortList={handleShortList}
              handleRemove={handleRemove}
              blocked={blocked}
              isNonMob1441={isNonMob1441}
              searchFilter={searchFilter}
              setSearchFilter={setSearchFilter}
              setTotal={setTotal}
            />
          )}
          {selected === "Challenges" && (
            <Talent
              results={filteredresults}
              livechallengesLength={livechallengesLength}
              selectedStudents={selectedStudent}
              setSelectedStudent={setSelectedStudent}
              DATA={DATA}
              TRUE={true}
              setData={setData}
              company={company}
              user={user}
              bulkAction={bulkAction}
              setBulkAction={setBulkAction}
              handleBulkAction={handleBulkActionChallenge}
              searchFilter={searchFilter}
              setSearchFilter={setSearchFilter}
              challenges={challenges}
            />
          )}
          {selected === "Applications" && (
            <Talent
              results={[]}
              TRUE={true}
              livechallengesLength={livechallengesLength}
              selectedStudents={selectedStudents}
              setSelectedStudent={setSelectedStudent}
              DATA={DATA}
              setData={setData}
              company={company}
              user={user}
              bulkAction={bulkAction}
              setBulkAction={setBulkAction}
              handleBulkAction={handleBulkAction}
              searchFilter={searchFilter}
              setSearchFilter={setSearchFilter}
              challenges={challenges}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default TargetSchool;
