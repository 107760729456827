// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footertext{
    color: var(--Gray-400, var(--Gray-400, #98A2B3));
font-family: "Poppins";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; 
}`, "",{"version":3,"sources":["webpack://./src/components/Company/Footer/footer.css"],"names":[],"mappings":"AAAA;IACI,gDAAgD;AACpD,sBAAsB;AACtB,eAAe;AACf,kBAAkB;AAClB,gBAAgB;AAChB,iBAAiB;AACjB","sourcesContent":[".footertext{\n    color: var(--Gray-400, var(--Gray-400, #98A2B3));\nfont-family: \"Poppins\";\nfont-size: 16px;\nfont-style: normal;\nfont-weight: 400;\nline-height: 24px; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
