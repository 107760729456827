import logo from "../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { useMediaQuery } from "@mui/material";
import React, { useRef, useState, useCallback, useEffect } from "react";
import emailjs from "@emailjs/browser";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { toast } from "react-toastify";
import support from "../../assets/images/letstalk.png";

const CareerContact = () => {
  const navigate = useNavigate();
  const isNonMobile1000 = useMediaQuery("(min-width: 1000px)");
  const isNonMobile = useMediaQuery("(min-width: 1024px)");
  const [name, setName] = useState("");

  const [workMail, setWorkMail] = useState("");
  const [companySize, setCompanySize] = useState("");
  const [phone, setPhone] = useState("");
  const options = ["1-10", "11-50", "51-500", "501-5000", "5000+"];

  const [showDropDown, setShowDropDown] = useState(false);
  const [loading, setLoading] = useState(false);
  const iconRef = useRef();
  const sendEmail = useCallback(() => {
    setLoading(true);
    const payload = {
      from_name: name,
      name: name,
      from_email: workMail,
      company_size: companySize,
      phone: phone,
    };

    emailjs
      .send("service_08a640e", "template_65hjrwp", payload, "FCKBh2iU92kGQhhAA")
      .then(
        (result) => {
          console.log(result.text);
          setWorkMail("");

          setCompanySize("");
          setName("");
          toast.success("Email sent successfully");
          setLoading(false);
        },
        (error) => {
          console.log(error.text);
          toast.error("Error Sending Email");
          setLoading(false);
        }
      );
  }, [name, workMail, companySize]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });

    function handleClickOutside(event) {
      if (iconRef.current && !iconRef.current.contains(event.target)) {
        setShowDropDown(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="ContactOuter d-flex flex-column justify-content-between pb-5  ">
      <div className="marginsleftrightCompany">
        <div className="d-flex" style={{ justifyContent: "center" }}>
          <div className="headerCompany space" style={{ marginTop: "2.5rem" }}>
            <img
              src={logo}
              alt="logo"
              height="36px"
              width="36px"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/");
              }}
            />
            <CloseIcon
              className="hoverdarkblacksvg"
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
          </div>
        </div>
      </div>
      <div className="marginsleftrightCompany">
        <div className="center">
          <div className="d-flex headerCompany">
            <div
              className={`d-flex  space gap-5 w-100 `}
              style={{
                padding: "1.25px",
                // flexDirection: isNonMobile1000 ? "row" : "column",
              }}
            >
              <div
                style={{
                  width: isNonMobile ? "38.034%" : "100%",
                  padding: "2rem 0rem",
                  position: "relative",
                }}
              >
                <h2 className="filmo48400" style={{ color: "#101828" }}>
                  Let's talk.
                </h2>
                <p
                  className="black14500"
                  style={{ marginTop: "1.5rem", fontWeight: 400 }}
                >
                  Book a tour with our team to discuss our solutions.
                </p>
                <a
                  href="mailto:hello@varyance.io"
                  style={{ textDecoration: "none" }}
                >
                  <div
                    className="d-flex "
                    style={{
                      gap: "0.75rem",
                      marginTop: "2rem",
                      cursor: "pointer",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M17 3.5H7C4 3.5 2 5 2 8.5V15.5C2 19 4 20.5 7 20.5H17C20 20.5 22 19 22 15.5V8.5C22 5 20 3.5 17 3.5ZM17.47 9.59L14.34 12.09C13.68 12.62 12.84 12.88 12 12.88C11.16 12.88 10.31 12.62 9.66 12.09L6.53 9.59C6.21 9.33 6.16 8.85 6.41 8.53C6.67 8.21 7.14 8.15 7.46 8.41L10.59 10.91C11.35 11.52 12.64 11.52 13.4 10.91L16.53 8.41C16.85 8.15 17.33 8.2 17.58 8.53C17.84 8.85 17.79 9.33 17.47 9.59Z"
                        fill="#E31b54"
                      />
                    </svg>
                    <p className="white20500" style={{ color: "#E31b54" }}>
                      hello@varyance.io
                    </p>
                  </div>
                </a>
                <input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Name"
                  style={{ marginTop: "1.5rem" }}
                  className="newinputs col-md-12 col-12"
                />
                <input
                  value={workMail}
                  onChange={(e) => setWorkMail(e.target.value)}
                  style={{ marginTop: "1.5rem" }}
                  placeholder="Work Email"
                  className="newinputs col-md-12 col-12"
                />

                <input
                  value={companySize}
                  onChange={(e) => setCompanySize(e.target.value)}
                  style={{ marginTop: "1.5rem" }}
                  placeholder="University Name"
                  className="newinputs col-md-12 col-12"
                />
                <input
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  style={{ marginTop: "1.5rem" }}
                  placeholder="Phone Number (optional)"
                  className="newinputs col-md-12 col-12"
                />
                <button
                  className={`col-md-12 col-12 backbutton pink16 ${
                    name && workMail && companySize ? "hovertest" : "disabled"
                  }`}
                  style={{ marginTop: "2rem" }}
                  disabled={!name || !workMail || !companySize || loading}
                  onClick={sendEmail}
                >
                  Request Demo
                </button>
              </div>
              {isNonMobile && (
                <div
                  className="center flex-column"
                  style={{ width: "51.709%", position: "relative" }}
                >
                  <img src={support} alt="support" className="img-fluid" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};
export default CareerContact;
