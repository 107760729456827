import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import SideBar from "../../components/Company/Dashboard/sidebar";
import "./styles.css";

import { useMediaQuery } from "@mui/material";
import Header from "../../components/Company/Dashboard/Header";
import { setEmployer } from "../../state";
import { setCompany as set } from "../../state";

const Dashboard = () => {
  const email = useSelector((state) => state.email);
  const isNonMobile450 = useMediaQuery("(min-width:450px)");
  const isNonMobile1024 = useMediaQuery("(min-width:1024px)");

  const [user, setUser] = useState([]);
  const dispatch = useDispatch();
  const [component, setComponent] = useState("Dashboard");
  const navigate = useNavigate();
  const [pop, setPop] = useState(false);
  const [company, setCompany] = useState();
  const id = useSelector((state) => state.uniqueId);
  const [talentoptions, setTalentOptions] = useState("");
  const location = useLocation();
  const route = location.pathname;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userInfoResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/getUserInfo/uniqueId`,
          { email: id }
        );

        if (userInfoResponse.data.message === "Found") {
          setUser(userInfoResponse.data.user);
          dispatch(setEmployer({ employer: userInfoResponse.data.user }));
        } else {
          navigate("/company/register");
          return;
        }

        const companyInfoResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/getCompanyInfo`,
          { id: userInfoResponse.data.user.company }
        );
        if (companyInfoResponse.data.msg === "Logout") {
          navigate("/company/register");
          return;
        }
        setCompany(companyInfoResponse.data);

        dispatch(set({ company: companyInfoResponse.data.uniqueId }));
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [email]);

  return (
    <div className="new-dashboard">
      <div className="d-flex" style={{ height: "100%", width: "100%" }}>
        <div className="sidebar__wrapper open">
          {isNonMobile1024 && (
            <SideBar
              isNonMobile1024={isNonMobile1024}
              company={company}
              setComponent={setComponent}
              component={component}
              setPop={setPop}
              talentoptions={talentoptions}
              setTalentOptions={setTalentOptions}
              style={{ flex: "0 0 auto" }} // Ensure sidebar takes minimum space
            />
          )}
        </div>
        <div
          style={{
            // backgroundColor: "#F0F5FE",
            minHeight: "100vh",

            width: "100%", // Make sure the content div takes up the remaining space
            boxSizing: "border-box",
            maxWidth: "100%",
            // Ensure padding is included in the width
          }}
        >
          <div
            className={`${isNonMobile1024 ? "" : "smallshadow"}`}
            style={{
              padding: isNonMobile450 ? "0.5rem 1.875rem " : "0.5rem 1.5rem",
              background: isNonMobile1024 ? "" : "#FFF",
              position: isNonMobile1024 ? "" : "relative",
              zIndex: isNonMobile1024 ? "" : 100,
            }}
          >
            <Header
              user={user}
              company={company}
              setComponent={setComponent}
              component={component}
            />
          </div>
          <div
            style={{
              padding: route.includes("/dashboard/user/")
                ? ""
                : isNonMobile450
                ? "0.5rem 1.875rem "
                : "0.5rem 1.5rem",
              maxWidth: "100%",
            }}
          >
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
