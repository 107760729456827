import React, { useState } from "react";
import "./home.css";
import Header from "../../components/CompanyHome/Header";
import FirstSection from "../../components/CompanyHome/FirstSection";
import HomeSecondSection from "../../components/CompanyHome/SecondSection";
import ThirdSection from "../../components/CompanyHome/ThirdSection";
import FourthSection from "../../components/CompanyHome/FourthSection";
import HomeFooter from "../../components/Home/HomeFooter";
import NewThirdSection from "../../components/CompanyHome/NewThirdSection";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import NewSecondSection from "../../components/CompanyHome/NewSecondSection";
import ShortListTalent from "../../components/CompanyHome/ShortlistTalent";
import ShortListTalent2 from "../../components/CompanyHome/shortListTalent2";
import GetStarted from "../../components/CompanyHome/GetStarted";
import ShortListTalent3 from "../../components/CompanyHome/shortListTalent3";
import NewSection from "../../components/CompanyHome/NewSection";
const CompanyHome = () => {
  const [Test, setTest] = useState(false);
  const isNonMobile450 = useMediaQuery("(min-width:450px)");
  const isNonMobile700 = useMediaQuery("(min-width:700px)");
  const isNonMobile1000 = useMediaQuery("(min-width: 1001px)");
  const isNonMobile1180 = useMediaQuery("(min-width: 1181px)");
  const isNonMobile1440 = useMediaQuery("(min-width: 1441px)");
  const isNonMobile500 = useMediaQuery("(min-width: 500px)");
  const navigate = useNavigate();
  return (
    <>
      <div style={{ width: "100vw", overflowX: "clip" }}>
        <Header setTest={setTest} Test={Test} />
        {!Test && (
          <>
            <div
              className="NewbackgroundCompany"
              style={{ paddingBottom: "6.25rem" }}
            >
              <div className="marginsleftrightCompany">
                <FirstSection navigate={navigate} />
              </div>
            </div>
            {/* <HomeSecondSection /> */}
            <NewSecondSection
              isNonMobile1000={isNonMobile1000}
              isNonMobile1180={isNonMobile1180}
              isNonMobile500={isNonMobile500}
            />

            <div
              className=""
              style={{
                width: "100vw",
                marginTop: "16.125rem",
                marginBottom: "8.125rem",
              }}
            >
              <div className="marginsleftrightCompany ">
                {/* <ThirdSection /> */}
                <NewThirdSection
                  navigate={navigate}
                  isNonMobile450={isNonMobile450}
                  isNonMobile700={isNonMobile700}
                />
              </div>
            </div>

            <div className="newHomeSectionCOmpany">
              <div className="marginsleftrightCompany ">
                <div
                  style={{ paddingTop: "8.125rem", paddingBottom: "3.75rem" }}
                >
                  <ShortListTalent isNonMobile1000={isNonMobile1000} />
                  <div style={{ marginTop: "60px" }}>
                    <ShortListTalent2 isNonMobile1000={isNonMobile1000} />
                  </div>
                  <div style={{ marginTop: "60px" }}>
                    <ShortListTalent3 isNonMobile1000={isNonMobile1000} />
                  </div>
                  <div style={{ marginTop: "130px", marginBottom: "10rem" }}>
                    <NewSection />
                  </div>
                </div>
              </div>
              {/* <div className="marginsleftrightCompany "> */}
              <GetStarted
                isNonMobile1000={isNonMobile1000}
                navigate={navigate}
              />
              {/* </div> */}
            </div>

            <div className="marginsleftrightCompany mt-5 pt-5">
              {/* <FourthSection /> */}
              <div className="d-flex" style={{ justifyContent: "center" }}>
                <div className="headerCompany">
                  <HomeFooter />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default CompanyHome;
