import { useEffect, useState } from "react";
import logo from "../../assets/images/logo.png";

const OrganizerHeader = ({ owner, navigate, isNonMob, uniqueId }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const scrollThreshold = window.innerHeight / 2;
      if (window.scrollY > scrollThreshold) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    };

    const handleMouseMove = (event) => {
      const mouseY = event.clientY;
      const headerThreshold = 30;
      if (mouseY <= headerThreshold) {
        setIsVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <div
      className={`header shadowa ${isVisible ? "" : "hidden"}`}
      style={{
        background: "#FFF",
      }}
    >
      <div className={`headerhomepageCompany `}>
        <div className=" headerCompany" style={{}}>
          <div className="justifySpace">
            <div className="d-flex" style={{ alignItems: "center" }}>
              <img
                src={logo}
                alt="logo"
                className="logoimage "
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/dashboard");
                }}
              />
            </div>
            <div className="welcomemenucontainer">
              {/* <button
            className="welcomebackbutton p-2 shadowa"
            style={{ maxHeight: "48px" }}
          >
            <img src={search} alt="search" height="20px" width="20px" />
          </button>
          <button
            className="welcomebackbutton p-2 shadowa"
            style={{ maxHeight: "48px" }}
          >
            <img src={Bell} alt="search" height="20px" width="20px" />
          </button> */}

              <button className="backbutton" onClick={() => navigate(-1)}>
                Back
              </button>
              {owner && (
                <button
                  className="continuebutton"
                  onClick={() => navigate(`/company/edit/${uniqueId}`)}
                >
                  Edit{isNonMob && " Company Page"}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrganizerHeader;
