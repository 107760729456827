import { Chart, registerables } from "chart.js";
import { useRef, useState, useEffect, memo } from "react";

const Canvas = memo(
  ({ participants, genderCount, labels, data, view, True }) => {
    const chartRef = useRef(null);

    const centerTextPlugin = {
      id: "centerText",
      beforeDraw(chart) {
        const ctx = chart.ctx;
        const { width, height, options } = chart;

        const centerText = options.plugins.centerText || {};
        const staticText = centerText.staticText || "No text provided";
        const participantText = centerText.participantText || "0";
        const staticTextColor = centerText.staticTextColor || "#000";
        const participantTextColor = centerText.participantTextColor || "#000";
        const staticFontSize = centerText.staticFontSize || 14;
        const participantFontSize = centerText.participantFontSize || 18;
        const staticFontFamily = centerText.staticFontFamily || "Arial";
        const participantFontFamily =
          centerText.participantFontFamily || "Arial";
        const staticFontWeight = centerText.staticFontWeight || "400";
        const participantFontWeight = centerText.participantFontWeight || "600";

        ctx.save();

        ctx.font = `${staticFontWeight} ${staticFontSize}px ${staticFontFamily}`;
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillStyle = staticTextColor;

        const lineHeight = staticFontSize * 1.2;

        ctx.fillText(
          staticText,
          width / 2 - (True ? 0 : 100), // No change for True === true
          height / 2 - lineHeight / 2 + (True ? -30 : 0) // Adjust the vertical position for True === false
        );

        ctx.font = `${participantFontWeight} ${participantFontSize}px ${participantFontFamily}`;
        ctx.fillStyle = participantTextColor;
        ctx.fillText(
          participantText,
          width / 2 - (True ? 0 : 100), // Adjust horizontal position for True === false
          height / 2 +
            lineHeight / 2 +
            participantFontSize / 2 +
            (True ? -30 : participantFontSize / 2) // Adjust vertical position for True === false
        );
        ctx.restore();
      },
    };

    useEffect(() => {
      if (chartRef.current) {
        Chart.unregister(centerTextPlugin);
        Chart.register(centerTextPlugin);
        const ctx = chartRef.current.getContext("2d");
        ctx.clearRect(0, 0, 100, 100);
        const myChart = new Chart(ctx, {
          type: "doughnut",
          data: {
            labels,
            datasets: [
              {
                data,
                backgroundColor: [
                  "#A145FF",
                  "#FA6298",
                  "#FAA24B",
                  "#00D084",
                  "#FCB900",
                  "#F78DA7",
                  "#00BFB3",
                  "#00d4ff",
                ],
                hoverBackgroundColor: [
                  "#903EE5",
                  "#E15888",
                  "#E19143",
                  "#00B46D",
                  "#E0A600",
                  "#DE5B7D",
                  "#00A6A0",
                  "#00b2d6",
                ],
              },
            ],
          },
          options: {
            cutout: "90%",
            rotation: -90,
            circumference: 180,
            borderRadius: 20,
            responsive: true,
            maintainAspectRatio: false,
            layout: {
              padding: 0,
            },
            plugins: {
              centerText: {
                staticText: True ? "Engaged Talent" : "Total Participants",
                participantText: participants.length.toString(),
                staticTextColor: True ? "#475467" : "#E31B54",
                participantTextColor: "#475467",
                staticFontSize: 14,
                participantFontSize: 48,
                staticFontFamily: "Poppins",
                participantFontFamily: "Poppins",
                staticFontWeight: 500,
                participantFontWeight: 600,
              },
              legend: {
                display: true,
                position: True ? "bottom" : "right",
                labels: {
                  usePointStyle: true,
                  padding: 15,
                  boxHeight: 10,
                  color: "#344054",
                  font: {
                    size: 16,
                    family: "Poppins, sans-serif",
                    weight: "400",
                    lineHeight: 20,
                  },
                },
              },
            },
          },
        });

        return () => myChart.destroy();
      }
    }, [labels, data, participants.length, True]);

    return (
      <div
        style={{
          position: "relative",
          width: "100%",
        }}
      >
        <canvas ref={chartRef} />
      </div>
    );
  }
);

export default Canvas;
