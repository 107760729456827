// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/images/Rectangle.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headerbackground {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-size: cover;
  /* Optionally, you can set background-position to control the placement of the image */
  /* background-position: center center; */
}
.heading {
  color: var(--Snow, #fff);

  /* Header/Header 1 */

  font-size: 42px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 54.6px */
}
.belowheading {
  color: var(--Gray-800, #1d2939);

  /* Body Large/Semi Bold */
  font-family: "Poppins";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 28.8px */
}
.dashboard {
  color: var(--Gray-900, #101828);
  font-family: Poppins;
  font-size: 16.378px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 26.205px */
}
`, "",{"version":3,"sources":["webpack://./src/pages/StudentDashboard/Challenges/header.css"],"names":[],"mappings":"AAAA;EACE,mDAAqD;EACrD,4BAA4B;EAC5B,sBAAsB;EACtB,sFAAsF;EACtF,wCAAwC;AAC1C;AACA;EACE,wBAAwB;;EAExB,oBAAoB;;EAEpB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,WAAW;AAChC;AACA;EACE,+BAA+B;;EAE/B,yBAAyB;EACzB,sBAAsB;EACtB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,WAAW;AAChC;AACA;EACE,+BAA+B;EAC/B,oBAAoB;EACpB,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,aAAa;AAClC","sourcesContent":[".headerbackground {\n  background: url(../../../assets/images/Rectangle.png);\n  background-repeat: no-repeat;\n  background-size: cover;\n  /* Optionally, you can set background-position to control the placement of the image */\n  /* background-position: center center; */\n}\n.heading {\n  color: var(--Snow, #fff);\n\n  /* Header/Header 1 */\n\n  font-size: 42px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 130%; /* 54.6px */\n}\n.belowheading {\n  color: var(--Gray-800, #1d2939);\n\n  /* Body Large/Semi Bold */\n  font-family: \"Poppins\";\n  font-size: 18px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: 160%; /* 28.8px */\n}\n.dashboard {\n  color: var(--Gray-900, #101828);\n  font-family: Poppins;\n  font-size: 16.378px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: 160%; /* 26.205px */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
