// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/newrightcorner.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.newrightcorner {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-size: auto 100%; /* Stretch only the width, keep height auto */
  background-position: center;
  min-width: 100vw;
  aspect-ratio: 2 / 1;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Terms/terms.css"],"names":[],"mappings":"AAAA;EACE,mDAAuD;EACvD,4BAA4B;EAC5B,0BAA0B,EAAE,6CAA6C;EACzE,2BAA2B;EAC3B,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".newrightcorner {\n  background: url(../../assets/images/newrightcorner.png);\n  background-repeat: no-repeat;\n  background-size: auto 100%; /* Stretch only the width, keep height auto */\n  background-position: center;\n  min-width: 100vw;\n  aspect-ratio: 2 / 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
