import React from "react";
import { useMediaQuery } from "@mui/material";
const Card2 = () => {
  const isNonMobile1440 = useMediaQuery("(min-width:1440px)");
  const isNonMobile = useMediaQuery("(min-width:865px)");
  return (
    <div className="secondsecseconddiv rounded-3 shadowa">
      <div className="card1paddings">
        {/* <div className="d-flex">
          <div className="card2button1">
            <p
              className="secondcard1buttontext d-flex"
              style={{
                gap: "0.5rem",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M16.9096 5.83406L12.2763 2.40906C10.921 1.41964 9.0816 1.41964 7.7263 2.40906L3.1013 5.83406C2.20584 6.47917 1.67305 7.51376 1.66797 8.61739V14.8091C1.71799 16.8034 3.37351 18.3807 5.36797 18.3341H14.6346C16.6291 18.3807 18.2846 16.8034 18.3346 14.8091V8.60906C18.3275 7.5102 17.7985 6.48013 16.9096 5.83406ZM17.0846 14.8089C17.0349 16.1131 15.9389 17.1308 14.6346 17.0839H5.36797C4.06545 17.1262 2.97215 16.111 2.91797 14.8089V8.61726C2.92241 7.90886 3.26644 7.24559 3.84297 6.83392L8.46797 3.41726C9.38138 2.75024 10.6212 2.75024 11.5346 3.41726L16.1596 6.84226C16.7357 7.24678 17.0803 7.90505 17.0846 8.60892V14.8089ZM6.25 13.126H13.75C14.0952 13.126 14.375 13.4058 14.375 13.751C14.375 14.0962 14.0952 14.376 13.75 14.376H6.25C5.90482 14.376 5.625 14.0962 5.625 13.751C5.625 13.4058 5.90482 13.126 6.25 13.126Z"
                  fill="white"
                />
              </svg>
              Home Feed
            </p>
          </div>
        </div> */}
        <div style={{ maxWidth: "560px" }}>
          <p className="text-start firstcard2ndtext" style={{ color: "#FFF" }}>
            Connect Worldwide
          </p>
          <p className="Secondcard3rdtext text-start">
            Connect with students around the world who share your interests and
            discuss experiences and the next steps.
          </p>
          {/* <p
            className="belowlatest"
            style={{ marginTop: "1rem", fontWeight: "600" }}
          >
            Got questions?
            <span className="belowlatest">
              {" "}
              Message campus recruiters directly or visit live-stream events.
            </span>
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default Card2;
