// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/images/newregisterbackground.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loginbackground {
  height: 100vh;
  width: 100vw;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-size: cover;
}
@media screen and (max-width: 768px) {
  .loginbackground {
    height: 100svh;
    width: 100svw;
  }
  .headerBackgroundChange {
    margin-top: 0px !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Login/StudentLogin/login.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,mDAAiE;EACjE,4BAA4B;EAC5B,sBAAsB;AACxB;AACA;EACE;IACE,cAAc;IACd,aAAa;EACf;EACA;IACE,0BAA0B;EAC5B;AACF","sourcesContent":[".loginbackground {\n  height: 100vh;\n  width: 100vw;\n  background: url(../../../assets/images/newregisterbackground.png);\n  background-repeat: no-repeat;\n  background-size: cover;\n}\n@media screen and (max-width: 768px) {\n  .loginbackground {\n    height: 100svh;\n    width: 100svw;\n  }\n  .headerBackgroundChange {\n    margin-top: 0px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
