import "./header.css";
import { useEffect, useState } from "react";
import logo from "../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
const TestHeader = ({ preview, task, tasks, steps }) => {
  const [fillPercentage, setFillPercentage] = useState(16.6777);

  const [percentage, setPercentage] = useState(0);
  useEffect(() => {
    if (steps !== 7) {
      setPercentage((task / preview.alltask.length) * 100);
    } else {
      setPercentage(100);
    }
  }, [task, steps]);

  const fillBarStyle = {
    width: `${percentage}%`,
    height: "10px", // Adjust as needed
    background: "#E31B54", // Color of the fill bar
    transition: "width 0.3s ease", // Optional: Add transition for a smoother effect
  };

  const handleFillPercentageChange = (newPercentage) => {
    setFillPercentage(newPercentage);
  };

  const navigate = useNavigate();

  return (
    <div className="Testheader">
      <div className="row d-flex" style={{ justifyContent: "space-between" }}>
        <button
          className="col-md-1 btn"
          onClick={() => navigate("/student/dashboard")}
        >
          <img
            src={logo}
            alt="logo img"
            className="shadowa rounded-3"
            width="45px"
            height="45px"
          />
        </button>
        {/* <div className="col-md-4">
          <p className="headertitle "> {preview.title}</p>
          <p className="belowtitletask">
            Task {task + 1} : {tasks && tasks.taskTitle && tasks.taskTitle}
          </p>
        </div> */}

        <div
          className="d-flex col-md-6 mt-0 mb-0 m-2"
          style={{ gap: "30px", justifyContent: "end" }}
        >
          {steps !== 8 && (
            <>
              <div
                className="col-md-5 p-4 rounded-3 d-flex"
                style={{
                  background: "#FFF",

                  alignItems: "center",
                }}
              >
                <div className="col-md-12">
                  <div
                    className="d-flex col-md-12"
                    style={{ justifyContent: "space-between" }}
                  >
                    {" "}
                    <p className="rate">Rate of Completion</p>
                    <p className="nextrate">
                      {" "}
                      {percentage}%{/* ADD PERCENTAGE */}
                    </p>
                  </div>

                  <div
                    className="fill-bar rounded-4"
                    style={fillBarStyle}
                  ></div>
                </div>
              </div>

              <div
                className="col-md-5 p-3 rounded-3 flex-wrap d-flex"
                style={{
                  background: "#FFF",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="d-flex flex-wrap" style={{ gap: "10px" }}>
                  {preview.alltask.length > 0 &&
                    preview.alltask.map((tas, index) => (
                      <div
                        key={index}
                        className={`p-3  text-center d-flex ${
                          task === index && steps !== 7 && "rounded-5"
                        }`}
                        style={{
                          background: "#F9FAFB",
                          width: "43.322px",
                          height: "26px",
                          justifyContent: "center",
                          alignItems: "center",
                          ...(task === index &&
                            steps !== 7 && {
                              background: "#E31B54",
                            }),
                        }}
                      >
                        <p
                          className="steps"
                          style={
                            task === index && steps !== 7
                              ? { color: "white" }
                              : {}
                          }
                        >
                          S{index + 1}
                        </p>
                      </div>
                    ))}
                  <div
                    className={`p-3  text-center d-flex ${
                      steps === 7 && "rounded-5"
                    } `}
                    style={{
                      background: "#F9FAFB",
                      width: "43.322px",
                      height: "26px",
                      justifyContent: "center",
                      alignItems: "center",
                      ...(steps === 7 && {
                        background: "#E31B54",
                      }),
                    }}
                  >
                    <p
                      className=" steps "
                      style={steps === 7 ? { color: "white" } : {}}
                    >
                      Done
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}
          <div
            className="mt-0 mb-0 m-2 d-flex"
            style={{
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => navigate("/student/dashboard/challenges")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="23"
              viewBox="0 0 22 23"
              fill="none"
            >
              <path
                d="M12.0693 11.5074L19.0318 4.56837C19.3227 4.27475 19.3227 3.79908 19.0318 3.50546C18.7461 3.20659 18.2742 3.19761 17.9775 3.4854L11.0149 10.4244L4.14186 3.4854C4.00095 3.33514 3.80489 3.25 3.59977 3.25C3.39466 3.25 3.1986 3.33514 3.05769 3.4854C2.79978 3.76909 2.79978 4.20447 3.05769 4.48815L9.93075 11.4171L2.96817 18.3461C2.67728 18.6397 2.67728 19.1154 2.96817 19.409C3.10665 19.552 3.29711 19.6317 3.49533 19.6296C3.69738 19.6461 3.89768 19.5812 4.05234 19.4491L11.0149 12.5101L17.9775 19.5294C18.116 19.6724 18.3064 19.7521 18.5047 19.75C18.7027 19.7509 18.8927 19.6714 19.0318 19.5294C19.3227 19.2357 19.3227 18.7601 19.0318 18.4664L12.0693 11.5074Z"
                fill="#98A2B3"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TestHeader;
