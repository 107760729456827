import { useEffect, useState } from "react";
import axios from "axios";
import * as XLSX from "xlsx";
const Admin = () => {
  const [record, setRecord] = useState([]);
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [image, setImage] = useState("");
  const [fileData, setFileData] = useState(null);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/requests`)
      .then((res) => {
        setRecord(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  const [countries, SetCountriesList] = useState([]);

  // const countries = [
  //   "Germany",
  //   "France",
  //   "United Kingdom",
  //   "Switzerland",
  //   "Estonia",
  //   "Finland",
  //   "Georgia",
  //   "Greece",
  //   "Hong Kong",
  // ];

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const binaryString = event.target.result;

      const workbook = XLSX.read(binaryString, { type: "binary" });

      const extractedData = [];

      workbook.SheetNames.forEach((sheetName) => {
        const sheet = workbook.Sheets[sheetName];

        // Extract data by specific columns
        const data = XLSX.utils.sheet_to_json(sheet, {
          header: 1,
          range: 1, // Skip the first row (assuming it contains headers)
        });
        console.log(data);
        // Log extracted data

        // Filter out rows with empty cells in any required column
        const sheetData = data
          .filter((row) => {
            const [country, universityName, logoLink, domain] = row.slice(0, 4); // Assuming the first four columns are the required ones
            return country && universityName && logoLink && domain; // Exclude rows with empty cells in any required column
          })
          .map((row) => ({
            country: row[0],
            // Assuming Country is the first column
            universityName: row[1], // Assuming University Name is the second column
            logoLink: row[2], // Assuming Logo Link is the third column
            domain: row[3], // Assuming Domain is the fourth column
          }));
        console.log(data);
        extractedData.push(...sheetData);
      });

      setFileData(extractedData);
      console.log(extractedData);
    };

    reader.readAsBinaryString(file);
  };

  const handleSubmit = async () => {
    console.log({ fileData });
    const filteredData = fileData;
    // await fileData.filter((entry) => {
    //   const country = entry.country;
    //   // Assuming countries is an array of valid country names
    //   return countries.includes(country);
    // });
    setFileData(filteredData);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/add/university`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(filteredData),
        }
      );
      if (response.ok) {
        console.log("Upload successful");
      } else {
        console.error("Upload failed");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleAccept = (rec) => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/requests/accept`, rec)
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  const handleReject = (rec) => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/requests/reject}`, rec)
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  const handleAddCompany = () => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/add/company`, {
        name,
        location,
        image,
      })
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlesetCountry = () => {
    let country = [];
    fileData.map((entry) => {
      const con = entry.country;
      if (!country.includes(con)) {
        country.push(con);
      }
    });
    SetCountriesList(country);
  };

  return (
    <>
      <div>
        <h1 className="m-5">ADMIN</h1>
        <button
          onClick={() => {
            handlesetCountry();
          }}
        >
          Country list
        </button>
        <div className="container">
          <table className="table-bordered">
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>University</th>
                <th>Email</th>
                <th>Accept/Reject</th>
              </tr>
            </thead>
            <tbody>
              {record.map((rec, index) => (
                <tr key={index}>
                  <td>{rec._id}</td>
                  <td>
                    {rec.firstName} {rec.LastName}
                  </td>
                  <td>{rec.uni}</td>
                  <td>{rec.EmailAddress}</td>

                  <td>
                    <button
                      className="btn btn-primary  m-2"
                      onClick={() => handleAccept(rec)}
                    >
                      Accept
                    </button>
                    <button
                      className="btn btn-danger  m-2"
                      onClick={() => {
                        handleReject(rec);
                      }}
                    >
                      Reject
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <hr />
      <div className="container">
        <h2>ADD COMPANY</h2>
        <input
          placeholder="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          placeholder="location"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
        />
        <input
          placeholder="image"
          value={image}
          onChange={(e) => setImage(e.target.value)}
        />

        <button className="btn btn-primary" onClick={() => handleAddCompany()}>
          ADD COMPANY
        </button>
      </div>
      <hr />
      <div className="mt-5 p-5">
        <h2>ADD University</h2>
        <div>
          <h1>Upload Excel File</h1>
          <input type="file" onChange={handleFileChange} />
          <button onClick={handleSubmit}>Upload</button>
        </div>
        {console.log(fileData)}
        {/* {fileData &&
          fileData.map((data, index) => (
            <img
              src={data.logoLink}
              alt="NO IMAGE HERE"
              height="50px"
              width="50px"
            />
          ))} */}

        {countries.map((con, index) => (
          <p key={index}>
            {"{"}
            <br />
            &nbsp;&nbsp;icon: {con},
            <br />
            &nbsp;&nbsp;name: "{con}",
            <br />
            {"},"}
          </p>
        ))}
      </div>
    </>
  );
};

export default Admin;
