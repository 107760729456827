import User from "./userDetail";
import EmptyState from "../../assets/images/EmptyState.png";
const ChallengeRanking = ({
  navigate,
  testResults,
  usersWithDetails,
  loading,
}) => {
  return (
    <div>
      <div
        className="space flex-wrap align-items-center mb-2"
        style={{ padding: "0rem 1rem" }}
      >
        <p className="gray95016500">Challenge Ranking</p>
        <div style={{ position: "relative" }}>
          <button
            className="bluebutton"
            onClick={() => {
              navigate("/dashboard/Challenges?page=3");
            }}
          >
            see all
          </button>
        </div>
      </div>

      <table style={{ width: "100%", overflowX: "hidden" }}>
        <thead>
          <tr className="newtr">
            <th className="text-start" style={{ width: "47%" }}>
              Name
            </th>
            <th className="text-center" style={{ width: "23%" }}>
              #
            </th>
            <th className="text-end" style={{ width: "30%" }}>
              Performance
            </th>
          </tr>
        </thead>
      </table>

      {testResults.length > 0 ? (
        <div style={{ paddingRight: "0.5rem" }}>
          <div
            className="tablescroll"
            style={{
              width: "100%",
              maxHeight: "274px",
              overflowY: "scroll",
              overflowX: "hidden",
            }}
          >
            <table
              style={{
                width: "100%",
                overflowY: "hidden",
              }}
            >
              <tbody
                style={{
                  width: "100%",
                  overflowX: "hidden",
                }}
              >
                {testResults.map((test, index) => (
                  <User
                    key={index}
                    usersWithDetails={usersWithDetails}
                    test={test}
                    index={index}
                    navigate={navigate}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <>
          {loading ? (
            <p className="black14 paddingmaincontent">Loading...</p>
          ) : (
            <div
              className="center flex-column align-items-center mt-4"
              style={{ width: "100%", height: "90%" }}
            >
              <img
                src={EmptyState}
                alt="emptystate"
                style={{ maxWidth: "86px", maxHeight: "70px" }}
              />
              <p className="pink16">No Data yet</p>
              <p
                className="black14 text-center"
                style={{
                  fontWeight: "500",
                  maxWidth: "332px",
                  marginTop: "1rem",
                }}
              >
                <span className="black14"> Please come back later. </span>
                The first students will engage with your company in no time!
              </p>
            </div>
          )}
        </>
      )}
    </div>
  );
};
export default ChallengeRanking;
