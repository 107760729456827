import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import TestHeader from "./header";
import OrganizerHeader from "./OrganizerHeader";
import { toast } from "react-toastify";
import { useMediaQuery } from "@mui/material";
import Header from "../../components/CompanyHome/Header";
import FirstSection from "./FirstSection";
import Banner from "./Banner";
import SecondSection from "./SecondSection";
import LatestChallenges from "./Latest/Challenges";
import LatestJobposts from "./Latest/Jobposts";
import HomeFooter from "../../components/Home/HomeFooter";
import LastSection from "./LastSection";
import Gallery from "./Gallery";

const CompanyPage = () => {
  const { uniqueId } = useParams();
  const isNonMob = useMediaQuery("(min-width: 768px)");
  const isNonMobile1024 = useMediaQuery("(min-width: 1066px)");

  const [company, setCompany] = useState();
  const [owner, setOwner] = useState(false);
  const [challenges, setChallenges] = useState([]);
  const id = useSelector((state) => state.uniqueId);
  const Organizerloggedin = !!id;
  const user = useSelector((state) => state.user);

  const [Test, setTest] = useState(false);
  const Userloggedin = !!user;
  const [organizer, setOrganizer] = useState(null);
  const navigate = useNavigate();
  const [jobposts, setJobposts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userInfoResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/getUserInfo/uniqueId`,
          { email: id }
        );

        if (userInfoResponse.data.message === "Found") {
          setOrganizer(userInfoResponse.data.user);
        } else {
          return;
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    const fetchData = async () => {
      if (uniqueId) {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/get/company/recent/challenges`,
          {
            uniqueId: uniqueId,
          }
        );
        if (response.data.msg === "success") {
          setChallenges(response.data.challenges);
        } else if (response.data.msg === "Record not found") {
          console.log("No record");
        } else {
          toast.error("Error Fetching Challenges");
        }
      }
    };
    fetchData();
  }, [uniqueId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const companyInfoResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/getCompanyInfo`,
          { id: uniqueId }
        );
        console.log(companyInfoResponse.data, "test");
        if (companyInfoResponse.data.message === "Logout") {
          navigate(-1);
          return;
        }

        setCompany(companyInfoResponse.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [uniqueId]);

  useEffect(() => {
    if (company && organizer) {
      if (company.createdBy === organizer.email) {
        setOwner(true);
      }
    }
  }, [company, organizer]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/get/company/recent/jobs`,
        {
          uniqueId: uniqueId,
        }
      );
      if (response.data.msg === "success") {
        setJobposts(response.data.challenges);
      } else if (response.data.msg === "Record not found") {
        console.log("");
      } else {
        toast.error("Error Fetching Job posts");
      }
    };
    fetchData();
  }, [uniqueId]);

  const [color, setColor] = useState("#E31b54");

  useEffect(() => {
    if (company?.color) {
      setColor(company?.color);
    }
  }, [company]);
  return (
    <div>
      {Organizerloggedin ? (
        <OrganizerHeader
          owner={owner}
          navigate={navigate}
          isNonMob={isNonMob}
          uniqueId={uniqueId}
        />
      ) : Userloggedin ? (
        <TestHeader organizer={user} />
      ) : (
        <Header setTest={setTest} Test={Test} />
      )}
      <FirstSection company={company} color={color} organizer={organizer} />
      <Banner banner={company?.banner} />
      <SecondSection
        company={company}
        color={color}
        isNonMob={isNonMob}
        isNonMobile1024={isNonMobile1024}
      />
      {company && company?.gallery?.length > 3 && (
        <div style={{ marginTop: "8.19rem", marginBottom: "11.25rem" }}>
          <Gallery gallery={company?.gallery} />
        </div>
      )}
      {challenges && challenges.length > 0 && (
        <LatestChallenges
          color={color}
          challenges={challenges}
          navigate={navigate}
        />
      )}
      {jobposts && jobposts.length > 0 && (
        <LatestJobposts color={color} jobposts={jobposts} navigate={navigate} />
      )}{" "}
      <div
        className="marginsleftrightCompany"
        style={{ marginTop: "8.125rem" }}
      >
        {!Userloggedin ? (
          <LastSection navigate={navigate} isNonMob={isNonMob} />
        ) : (
          <>
            <hr />
          </>
        )}
        <div className="d-flex" style={{ justifyContent: "center" }}>
          <div className="headerCompany">
            <HomeFooter />{" "}
          </div>
        </div>
      </div>
    </div>
  );
};
export default CompanyPage;
