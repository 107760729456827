import React, { useEffect, useState } from "react";

import "./showall.css";

import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import Right from "./Right";
import ChallengeType from "../../../pages/MainDashBoard/type";

const ShowAllChallenges = ({
  challenges,
  setLiveChallengesLength,
  company,
}) => {
  const navigate = useNavigate();

  const [hoveredChallenge, setHoveredChallenge] = useState(null);
  const [challengestoshow, setShowChallenges] = useState([]);
  const isNonMobile = useMediaQuery("(min-width:767px)");
  const isNonMobile1024 = useMediaQuery("(min-width:1024px)");
  const [addHover, setAddHover] = useState(false);

  const maxLines = 3;
  const getClampedText = (text, maxLines) => {
    const lineHeight = 1.2; // Adjust this value based on your line height
    const maxHeight = maxLines * lineHeight;

    return {
      maxHeight: `${maxHeight}em`,
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: maxLines,
    };
  };

  const handleMouseEnter = (index) => {
    setHoveredChallenge(index);
  };

  const handleMouseLeave = () => {
    setHoveredChallenge(null);
  };

  useEffect(() => {
    if (challenges.length > 0) {
      setShowChallenges(
        challenges.filter((challe) => !challe.hasOwnProperty("draft"))
      );
      setLiveChallengesLength(
        challenges.filter((challe) => !challe.hasOwnProperty("draft")).length
      );
    }
  }, [challenges]);

  const truncateString = (str, maxLength) => {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + "...";
    } else {
      return str;
    }
  };

  return (
    <div
      className=" d-flex"
      style={{
        marginTop: "1.25rem",
        justifyContent: "space-evenly",
        gap: "1.875rem",
      }}
    >
      <div style={{ width: "100%" }}>
        <div
          className={`  flex-wrap d-flex`}
          style={{
            gap: "1rem",
          }}
        >
          {challengestoshow &&
            challengestoshow.length > 0 &&
            challengestoshow.map((challe, index) => (
              <div
                key={index}
                className="ChallengesCardNew-Outer shadowa "
                style={{ borderRadius: "5px", cursor: "pointer" }}
                onClick={() => {
                  navigate(`/preview/${challe.uniqueId}`);
                }}
              >
                <img
                  src={`${process.env.REACT_APP_BACKEND_URL}${challe.selectedFile}`}
                  alt="challenge image"
                  className="img-fluid"
                  style={{
                    borderTopLeftRadius: "5px",
                    borderTopRightRadius: "5px",
                    maxHeight: "6.875rem",
                    minHeight: "6.875rem",
                    width: "100%",
                    objectFit: "cover",
                    // ensures the image covers the container without stretching or compressing
                  }}
                />

                <div className="ChallengesCardNew-Inner space flex-column">
                  <div>
                    {challe.company && (
                      <div
                        className="shadowa center align-items-center"
                        style={{
                          padding: "2px",
                          background: "#FFF",
                          borderRadius: "5px",
                          height: "48px",
                          width: "48px",
                          position: "absolute",
                          marginTop: "-46px",
                        }}
                      >
                        <img
                          src={company.img}
                          alt="card-bg"
                          style={{
                            maxHeight: "44px",
                            maxWidth: "44px",
                            height: "44px",
                            borderRadius: "3px",
                            objectFit: "cover",
                            width: "100%",
                          }}
                        />
                      </div>
                    )}
                    <p
                      className="gray60011400"
                      style={{ fontSize: "10px", marginTop: "0.5rem" }}
                    >
                      {JSON.parse(challe?.company)?.title}
                    </p>
                    <p
                      style={{ marginTop: "0.25rem" }}
                      className="gray95016500"
                    >
                      {challe.title}
                    </p>
                  </div>
                  <div>
                    <div className=" space align-items-center">
                      <ChallengeType type={challe?.type} />
                      <div
                        className="d-flex align-items-center"
                        style={{ gap: "0.25rem" }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M1.54938 7.79185C1.54938 4.89236 3.89989 2.54185 6.79938 2.54185C9.69888 2.54185 12.0494 4.89236 12.0494 7.79185C12.0494 10.6913 9.69888 13.0419 6.79938 13.0419C3.89989 13.0419 1.54938 10.6913 1.54938 7.79185ZM2.42438 7.79185C2.42438 10.2081 4.38314 12.1669 6.79938 12.1669C7.95971 12.1669 9.0725 11.7059 9.89298 10.8854C10.7134 10.065 11.1744 8.95217 11.1744 7.79185C11.1744 5.37561 9.21563 3.41685 6.79938 3.41685C4.38314 3.41685 2.42438 5.37561 2.42438 7.79185Z"
                            fill="#344054"
                          />
                          <path
                            d="M5.42855 5.56935C5.25617 5.40873 4.98755 5.41347 4.82094 5.58007C4.65433 5.74668 4.64959 6.0153 4.81022 6.18769L6.36188 7.73935V10.1252C6.36188 10.3668 6.55776 10.5627 6.79938 10.5627C7.04101 10.5627 7.23688 10.3668 7.23688 10.1252V7.55852C7.23678 7.44253 7.19062 7.33132 7.10855 7.24935L5.42855 5.56935Z"
                            fill="#344054"
                          />
                          <path
                            d="M12.3586 3.41685C11.6384 2.45639 10.6818 1.69876 9.58188 1.21769C9.47454 1.16903 9.3521 1.16574 9.2423 1.20856C9.13249 1.25138 9.04461 1.3367 8.99855 1.44519C8.94818 1.55231 8.94401 1.67542 8.98702 1.78571C9.03004 1.896 9.11645 1.98378 9.22605 2.02852C10.1868 2.45123 11.0225 3.11417 11.6527 3.95352C11.7353 4.06368 11.865 4.12852 12.0027 4.12852C12.0974 4.12895 12.1897 4.0982 12.2652 4.04102C12.3624 3.97213 12.4273 3.86661 12.4449 3.74882C12.4625 3.63103 12.4313 3.51115 12.3586 3.41685Z"
                            fill="#344054"
                          />
                          <path
                            d="M1.94022 3.94185C2.57044 3.10251 3.40615 2.43956 4.36688 2.01685C4.47648 1.97211 4.5629 1.88433 4.60591 1.77404C4.64892 1.66376 4.64476 1.54064 4.59438 1.43352C4.54832 1.32503 4.46044 1.23972 4.35064 1.19689C4.24083 1.15407 4.1184 1.15736 4.01105 1.20602C2.91195 1.69121 1.95732 2.4529 1.24022 3.41685C1.11421 3.60468 1.15215 3.85759 1.32772 4.00019C1.40325 4.05737 1.49549 4.08811 1.59022 4.08769C1.72298 4.09478 1.85177 4.04112 1.94022 3.94185Z"
                            fill="#344054"
                          />
                        </svg>
                        <p className="gray75010500">{challe?.duration} mins</p>
                      </div>
                    </div>
                    <div className="ChallengenewCarddescription ">
                      <p className="gray40012400">{challe.description}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}

          {/* {challengestoshow &&
            challengestoshow.length > 0 &&
            challengestoshow.map((challe, index) => (
              <div
                className="Challengescardouterwhitediv shadowa"
                key={index}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
              >
                <div className="Challengescardinnergreydiv">
                  <div className="challengescardinsidegreybackgroundimagediv">
                    {challe.company && (
                      // <div
                      //   className="center p-1"
                      //   style={{ alignItems: "center", background: "#FFF" }}
                      // >
                      <img
                        src={JSON.parse(challe.company).img}
                        alt="card-bg"
                        className="challengescardinsidegreybackgroundimagedivcompanycard p-1  shadowa"
                        style={{
                          maxHeight: "48px",
                          maxWidth: "48px",
                          background: "#FFF",
                          borderRadius: "6px",
                        }}
                      />
                      // </div>
                    )}
                    <img
                      src={`${process.env.REACT_APP_BACKEND_URL}${challe.selectedFile}`}
                      alt="challenge image"
                      className=""
                      style={{
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                        maxHeight: "7.813rem",
                        width: "100%",
                      }}
                    />
                  </div>
                  <div style={{ padding: "0.75rem 0.46rem 0.75rem 0.5rem" }}>
                    <p className="ChallengecardTaskCounter text-start">
                      {challe.alltask.length}{" "}
                      {challe.alltask.length > 1 ? "Tasks" : "Task"}
                      {" / "}
                      {challe.duration} mins
                    </p>
                    <p
                      className="ChallengecardChallengeTitle"
                      style={{ marginTop: "0.625rem" }}
                    >
                      {challe.title}
                    </p>
                    {challe.company && (
                      <p
                        className="ChallengeCardCompanyName"
                        style={{ marginTop: "0.375rem" }}
                      >
                        {JSON.parse(challe.company).title}
                      </p>
                    )}
                  </div>
                </div>

                <div
                  className="d-flex"
                  style={{
                    justifyContent: "space-between",
                    marginTop: "0.5rem",
                  }}
                >
                  <div
                    className="d-flex"
                    style={{ alignItems: "center", gap: "0.5rem" }}
                  >
                    {challe.user && JSON.parse(challe.user).profilephoto && (
                      <img
                        src={JSON.parse(challe.user).profilephoto}
                        alt="user photo"
                        height="32px"
                        width="32px"
                        style={{ borderRadius: "125px" }}
                      />
                    )}
                    {challe.user && (
                      <p className=" byname">
                        by{" "}
                        {truncateString(JSON.parse(challe.user).firstName, 10)}
                      </p>
                    )}
                  </div>
                  <button
                    className="previewbutton border-0 black14"
                    style={{
                      maxHeight: "32px",

                      fontWeight: "500",
                    }}
                    onClick={() => {
                      navigate(`/preview/${challe.uniqueId}`);
                    }}
                  >
                    Preview
                  </button>
                </div>
              </div>
            ))} */}
          <div
            className="addChallengeDiv shadowa"
            onMouseEnter={() => setAddHover(true)}
            onMouseLeave={() => setAddHover(false)}
            onClick={() => navigate("/create/challenge")}
            style={{ height: "336px", minHeight: "336px" }}
          >
            <div>
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100"
                height="100"
                viewBox="0 0 100 100"
                fill="none"
              >
                <path
                  d="M82.2917 45.8333H52.0833V15.625C52.0833 13.8991 50.6842 12.5 48.9583 12.5C47.2324 12.5 45.8333 13.8991 45.8333 15.625V45.8333H15.625C13.8991 45.8333 12.5 47.2324 12.5 48.9583C12.5 50.6842 13.8991 52.0833 15.625 52.0833H45.8333V82.2917C45.8333 84.0176 47.2324 85.4167 48.9583 85.4167C50.6842 85.4167 52.0833 84.0176 52.0833 82.2917V52.0833H82.2917C84.0176 52.0833 85.4167 50.6842 85.4167 48.9583C85.4167 47.2324 84.0176 45.8333 82.2917 45.8333Z"
                  fill={addHover ? "#E31B54" : "white"}
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      {isNonMobile1024 && <Right />}
    </div>
  );
};

export default ShowAllChallenges;
