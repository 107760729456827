import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import axios from "axios";
import "./test.css";
import Header from "./Header/Header";
import { useMediaQuery } from "@mui/material";
import HeadingLogo from "./Headinglogo";
import Welcome from "./Welcome";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Terms from "./Terms";
import Document from "./Documents";
import Questions from "./Questions";
import { toast } from "react-toastify";
import Completed from "./Completed";
import Navbar from "./navbar";
import Tasknav from "./TaskCountnav";
const TestMain = () => {
  const { uniqueId } = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const [challenge, setPreview] = useState(null);
  const [steps, setSteps] = useState(0);
  const isNonMobile1024 = useMediaQuery("(min-width:1024px)");
  const isNonMobile1440 = useMediaQuery("(min-width:1440px)");
  const isNonMobile700 = useMediaQuery("(min-width:700px)");
  const [taskCounter, setTaskCounter] = useState(0);
  const [questionNumber, setQuestionNumber] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState([[{}]]);
  const [id, setId] = useState("");
  useEffect(() => {
    if (!user) {
      navigate(-1);
    } else if (!uniqueId) {
      navigate("/student/dashboard");
    }
  }, []);

  useEffect(() => {
    if (challenge) {
      // Initialize selectedAnswers with empty arrays for each task
      setSelectedAnswers(new Array(challenge.alltask.length).fill([]));
    }
  }, [challenge]);
  console.log(selectedAnswers);
  const handleNext = () => {
    if (steps === 0) {
      setSteps(1);
    } else if (steps === 1) {
      setSteps(2);
    } else if (steps === 2) {
      setSteps(3);
    } else if (steps === 3) {
      if (
        selectedAnswers.some((selected) =>
          selected.some(
            (sel) =>
              sel.index === questionNumber && sel.taskCounter === taskCounter
          )
        )
      ) {
        if (
          questionNumber ===
          challenge.alltask[taskCounter].questions.length - 1
        ) {
          if (taskCounter === challenge.alltask.length - 1) {
            setSteps(4);
            setLoading(true);
            axios
              .post(`${process.env.REACT_APP_BACKEND_URL}/Test/Results`, {
                preview: challenge,
                user,
                selectedAnswers,
              })
              .then((res) => {
                if (res.data.msg === "DONE") {
                  setId(res.data.uniqueId);
                }
                setLoading(false);
              });
          } else {
            setTaskCounter(taskCounter + 1);
            setSteps(0);
            setQuestionNumber(0);
          }
        } else {
          setQuestionNumber(questionNumber + 1);
        }
      } else {
        toast.error("Please select an option");
      }
    } else if (steps === 4) {
      if (!id) {
        setLoading(true);
        axios
          .post(`${process.env.REACT_APP_BACKEND_URL}/Test/Results`, {
            preview: challenge,
            user,
            selectedAnswers,
          })
          .then((res) => {
            if (res.data.msg === "DONE") {
              // setId(res.data.uniqueId);
              navigate(`/challenge/result/${res.data.uniqueId}`);
            }
            setLoading(false);
          });
      } else {
        navigate(`/challenge/result/${id}`);
      }
    }
  };
  const handleBack = () => {
    if (steps === 0) {
      navigate(-1);
    } else if (steps === 1) {
      setSteps(0);
    } else if (steps === 2) {
      setSteps(1);
    } else if (steps === 3) {
      if (questionNumber === 0) {
        setSteps(2);
      } else {
        setQuestionNumber(questionNumber - 1);
      }
    }
  };

  useEffect(() => {
    if (uniqueId) {
      axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/get/specific/challenge`, {
          uniqueId,
          id: user ? user._id : null,
        })
        .then((res) => {
          if (res.data.msg == "success") {
            setPreview(res.data.challenge);
            console.log(res.data);
          } else {
            navigate("/student/dashboard/challenges");
            console.log(res.data);
          }
        })
        .catch((err) => console.log(err));
    } else {
      navigate("/student/dashboard/challenges");
    }
  }, []);

  return (
    <div
      className="testpagebackground tablescroll"
      style={{ overflow: "auto" }}
    >
      <div>
        {" "}
        {challenge && (
          <Header
            isNonMobile700={isNonMobile700}
            setSteps={setSteps}
            navigate={navigate}
            steps={steps}
            title={challenge.title}
            taskCounter={taskCounter}
            questions={challenge.alltask[taskCounter].questions}
            questionNumber={questionNumber}
            total={challenge.alltask.length}
            totalTime={challenge.alltask.reduce((acc, task) => {
              return acc + task.time;
            }, 0)}
          />
        )}
      </div>
      <div
        style={{
          paddingTop: "52px",
          paddingBottom: "81px",
        }}
      >
        {challenge && (
          <HeadingLogo
            name={user.firstName}
            title={challenge.alltask[taskCounter].taskTitle}
            taskCounter={taskCounter}
            steps={steps}
            companyimg={JSON.parse(challenge.company).img}
          />
        )}
        <div className="stepsContainer ">
          <div className="challengeattemptheader d-flex space">
            <div className={`w-100 ${isNonMobile1024 && "paddingrighttest"}`}>
              {steps !== 4 && (
                <hr
                  style={{
                    marginTop: isNonMobile1024 ? "0rem" : "1rem",
                    marginBottom: "1.5rem",
                  }}
                />
              )}
              <div>
                {steps === 0 && challenge && (
                  <Welcome
                    selectedVideo={challenge.selectedVideo}
                    isNonMobile1024={isNonMobile1024}
                  />
                )}
                {steps === 1 && challenge && (
                  <Terms terms={challenge.alltask[taskCounter].terms} />
                )}
                {steps === 2 && challenge && (
                  <Document
                    doc1={challenge.alltask[taskCounter].caseDocuments}
                    doc2={challenge.alltask[taskCounter].additionalDocuments}
                    doc3={challenge.alltask[taskCounter].external}
                    axios={axios}
                  />
                )}
                {steps === 3 && challenge && (
                  <Questions
                    questions={challenge.alltask[taskCounter].questions}
                    questionNumber={questionNumber}
                    setQuestionNumber={setQuestionNumber}
                    isNonMobile1440={isNonMobile1440}
                    selectedAnswers={selectedAnswers}
                    setSelectedAnswers={setSelectedAnswers}
                    taskCounter={taskCounter}
                  />
                )}
                {steps === 4 && <Completed />}
              </div>
            </div>
            {isNonMobile1024 && challenge && (
              <div
                className="widthofsidenavtest testnavbarstyles shadowa tablescroll"
                style={{ marginTop: "1.5rem", padding: "1.5rem" }}
              >
                <Navbar
                  questions={challenge.alltask[taskCounter].questions}
                  steps={steps}
                  questionNumber={questionNumber}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className="challengeattemptheader "
        style={{
          position: "absolute",
          width: "100vw",
          bottom: 0,
          left: 0,
          paddingBottom: "0.75rem",
          zIndex: 1,
          background: "#fff",
        }}
      >
        <hr style={{ marginTop: "0rem", marginBottom: "0.75rem" }} />
        <div className="space  flex-wrap" style={{ gap: "1.5rem" }}>
          {challenge && (
            <div className="d-flex" style={{ alignItems: "center" }}>
              <Tasknav
                steps={steps}
                taskCounter={taskCounter}
                total={challenge.alltask.length}
                isNonMobile1024={isNonMobile1024}
              />
            </div>
          )}
          <div
            className="d-flex flex-wrap"
            style={{
              gap: "1.5rem",
              paddingTop: "0.5rem",
              paddingBottom: "0.5rem",
              background: "#FFF",
            }}
          >
            {steps === 3 && questionNumber < 1 ? (
              <></>
            ) : (
              <>
                {steps !== 4 && (
                  <button
                    className="backbutton"
                    style={{ maxHeight: "40px" }}
                    onClick={() => handleBack()}
                  >
                    Back
                  </button>
                )}
              </>
            )}
            <button
              className="continuebutton"
              style={{ maxHeight: "40px" }}
              onClick={() => handleNext()}
              disabled={loading}
            >
              {steps !== 4 ? "Next" : "Submit"} <NavigateNextIcon />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TestMain;
