import { useState } from "react";
import Analytics from "../MainDashBoard/Analytics";
import Filter from "./filter";
import Table from "./Table";

const AllTouchpoints = ({
  isNonMob768,
  challenges,
  jobposts,
  analytics,
  bulkAction,
  setBulkAction,
  isNonMob1441,
  handleShortList,
  handleRemove,
  isNonMob1024,
  blocked,
  handleBulkAction,
  selectedStudents,
  setSelectedStudents,
  checker,
  appliedUnis,
  TRUE,

  searchFilter,
  setSearchFilter,
  setTotal,
  Reset,
}) => {
  const [engageselect, setEngageSelect] = useState("");
  const [locationfilter, setLocationFilter] = useState("");
  const [languagefilter, setLanguageFilter] = useState("");
  const [studyLevel, setStudyLevel] = useState("");
  const [graduationFilter, setGraduationFilter] = useState("");
  const [academicFilter, setAcademicFilter] = useState("");

  const [applyFilter, setApplyFilter] = useState(false);

  const [engaged, setEngaged] = useState(0);
  const [complete, setComplete] = useState(0);
  const [touch, setTouch] = useState(0);
  return (
    <div>
      <Analytics
        challenges={challenges}
        jobposts={jobposts}
        analytics={analytics}
        isNonMob1441={isNonMob1441}
        isNonMob1024={isNonMob1024}
        setEngaged={setEngaged}
        engaged={engaged}
        setComplete={setComplete}
        complete={complete}
        setTouch={setTouch}
        touch={touch}
      />
      <div style={{ marginTop: "5rem" }}>
        <Filter
          bulkAction={bulkAction}
          handleBulkAction={handleBulkAction}
          setBulkAction={setBulkAction}
          isNonMob768={isNonMob768}
          setEngageSelect={setEngageSelect}
          engageselect={engageselect}
          locationfilter={locationfilter}
          languagefilter={languagefilter}
          setLanguageFilter={setLanguageFilter}
          setLocationFilter={setLocationFilter}
          studyLevel={studyLevel}
          setStudyLevels={setStudyLevel}
          graduationFilter={graduationFilter}
          setGraduationFilter={setGraduationFilter}
          academicFilter={academicFilter}
          setAcademicFilter={setAcademicFilter}
          setApplyFilter={setApplyFilter}
          searchFilter={searchFilter}
          setSearchFilter={setSearchFilter}
          TRUE={TRUE}
        />
      </div>
      <div style={{ marginTop: "2.5rem" }}>
        <Table
          challenges={challenges}
          appliedUnis={appliedUnis}
          checker={checker}
          TRUE={TRUE}
          jobposts={jobposts}
          selectedStudents={selectedStudents}
          setSelectedStudents={setSelectedStudents}
          setApplyFilter={setApplyFilter}
          applyFilter={applyFilter}
          engageselect={engageselect}
          locationfilter={locationfilter}
          languagefilter={languagefilter}
          studyLevel={studyLevel}
          graduationFilter={graduationFilter}
          academicFilter={academicFilter}
          handleShortList={handleShortList}
          handleRemove={handleRemove}
          blocked={blocked}
          searchFilter={searchFilter}
          setTotal={setTotal}
          Reset={Reset}
        />
      </div>
    </div>
  );
};
export default AllTouchpoints;
