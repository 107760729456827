import { useState } from "react";
import { useMediaQuery } from "@mui/material";
import CropFreeIcon from "@mui/icons-material/CropFree";
const Third = ({ isNonMobile450 }) => {
  const [selected, setSelected] = useState("Career Analytics");
  const isNon1024 = useMediaQuery("(max-width:1024px)");
  const isNon = useMediaQuery("(max-width:768px)");
  const options = ["Career Analytics", "VSX Tools"];

  const list1 = [
    {
      icon: (
        <CropFreeIcon
          style={{ height: "32px", width: "32px", color: "#7835CD" }}
        />
      ),
      title: "Career Trajectory",
      text: "Get insights which career paths students want to take.",
    },
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="33"
          viewBox="0 0 32 33"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.74636 2.70947H24.2543C27.0596 2.70947 29.3337 4.98358 29.3337 7.78884V24.2968C29.3337 27.102 27.0596 29.3761 24.2543 29.3761H7.74636C4.9411 29.3761 2.66699 27.102 2.66699 24.2968V7.78884C2.66699 4.98358 4.9411 2.70947 7.74636 2.70947ZM24.2543 27.4714C26.0076 27.4714 27.4289 26.0501 27.4289 24.2968V7.78884C27.4289 6.03555 26.0076 4.61423 24.2543 4.61423H7.74636C5.99307 4.61423 4.57175 6.03555 4.57175 7.78884V24.2968C4.57175 26.0501 5.99307 27.4714 7.74636 27.4714H24.2543Z"
            fill="#079455"
          />
          <path
            d="M22.9845 11.9158H19.1749C18.6489 11.9158 18.2225 12.3422 18.2225 12.8682C18.2225 13.3942 18.6489 13.8206 19.1749 13.8206H20.3559L15.8352 18.3412L13.2956 15.8015C12.6216 15.1299 11.5314 15.1299 10.8575 15.8015L7.54318 19.0142C7.17181 19.3861 7.17181 19.9884 7.54318 20.3603C7.72081 20.5401 7.96342 20.6408 8.2162 20.6396C8.46927 20.6423 8.71242 20.5414 8.88922 20.3603L12.0257 17.2238L14.5654 19.7634C15.2437 20.4365 16.3379 20.4365 17.0162 19.7634L21.9813 14.7984V16.6777C21.9813 17.2037 22.4077 17.6301 22.9337 17.6301C23.4596 17.6301 23.886 17.2037 23.886 16.6777V12.8682C23.8805 12.3639 23.4877 11.949 22.9845 11.9158Z"
            fill="#079455"
          />
        </svg>
      ),
      title: "Skill Maps",
      text: "With challenges students learn and expand the hands-on skills.",
    },
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="33"
          height="33"
          viewBox="0 0 33 33"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.5411 18.1863C14.5186 18.1863 15.4507 18.599 16.1078 19.3227C17.4905 20.8737 17.502 23.2117 16.1344 24.7761L12.9211 28.2294C12.4306 28.7727 11.733 29.0827 11.0011 29.0827C10.2692 29.0827 9.57154 28.7727 9.08109 28.2294L5.86775 24.7761C4.49851 23.2161 4.49851 20.8827 5.86775 19.3227C6.52482 18.599 7.4569 18.1863 8.43442 18.1863C9.40534 18.1863 10.3314 18.5935 10.9878 19.3081C11.6441 18.5935 12.5702 18.1863 13.5411 18.1863ZM11.4144 26.8561L14.6278 23.4161C15.2851 22.6067 15.2795 21.4458 14.6144 20.6427C14.3484 20.3251 13.9554 20.1416 13.5411 20.1416C13.1268 20.1416 12.7338 20.3251 12.4678 20.6427L11.7078 21.4961C11.5199 21.7114 11.2468 21.8333 10.9611 21.8294C10.6761 21.8298 10.4044 21.7085 10.2144 21.4961L9.45442 20.6427C9.2031 20.3017 8.8047 20.1004 8.38109 20.1004C7.95747 20.1004 7.55907 20.3017 7.30775 20.6427C6.64128 21.4471 6.64128 22.6118 7.30775 23.4161L10.5078 26.8561C10.6245 26.9846 10.7876 27.0614 10.9611 27.0694C11.1353 27.0644 11.2995 26.9871 11.4144 26.8561Z"
            fill="#FC7E00"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M18.2411 3.26941L27.5744 12.6027C27.7763 12.808 27.8827 13.0886 27.8678 13.3761V22.7094C27.8678 26.2072 25.0322 29.0427 21.5344 29.0427H17.6678C17.1155 29.0427 16.6678 28.595 16.6678 28.0427C16.6678 27.4905 17.1155 27.0427 17.6678 27.0427H21.5344C23.9246 27.0354 25.8604 25.0996 25.8678 22.7094V14.3761H20.2011C18.176 14.3761 16.5344 12.7345 16.5344 10.7094V5.01607H13.5344C12.3805 5.01605 11.2743 5.47626 10.4608 6.29469C9.64739 7.11313 9.19398 8.22219 9.20109 9.37607V16.0427C9.20109 16.595 8.75337 17.0427 8.20108 17.0427C7.6488 17.0427 7.20109 16.595 7.20109 16.0427V9.37607C7.18328 7.68485 7.84267 6.05676 9.03234 4.85457C10.222 3.65238 11.8431 2.97598 13.5344 2.97607H17.5344C17.7995 2.97631 18.0537 3.08182 18.2411 3.26941ZM18.5344 6.40274V10.7094C18.549 11.6195 19.2909 12.3495 20.2011 12.3494H24.4811L18.5344 6.40274Z"
            fill="#FC7E00"
          />
        </svg>
      ),
      title: "Application Success",
      text: "Get to know how good your students are prepared for their application.",
    },
  ];

  const list2 = [
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="33"
          height="33"
          viewBox="0 0 33 33"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.25699 2.70947H24.0837C27.4612 2.82539 30.1085 5.65161 30.0037 9.02947V17.7228C30.0539 19.3542 29.4518 20.9383 28.3307 22.1244C27.2096 23.3106 25.6619 24.001 24.0303 24.0428H11.337C10.4586 24.061 9.62556 24.4366 9.03033 25.0828L5.87033 28.4561C5.60261 28.7481 5.22643 28.9169 4.83033 28.9228C4.42123 28.9124 4.03328 28.7389 3.75281 28.4409C3.47234 28.1429 3.32263 27.7451 3.33699 27.3361V9.02947C3.23215 5.65161 5.87949 2.82539 9.25699 2.70947ZM24.0837 22.0428C26.3569 21.9278 28.1094 19.9965 28.0037 17.7228V9.02947C28.1094 6.75577 26.3569 4.8245 24.0837 4.70947H9.25699C6.98374 4.8245 5.23129 6.75577 5.33699 9.02947V26.0961L7.51032 23.7228C8.50422 22.6656 9.886 22.0589 11.337 22.0428H24.0837Z"
            fill="#3538CD"
          />
          <path
            d="M11.6703 11.7095H18.337C18.8893 11.7095 19.337 11.2618 19.337 10.7095C19.337 10.1572 18.8893 9.70947 18.337 9.70947H11.6703C11.118 9.70947 10.6703 10.1572 10.6703 10.7095C10.6703 11.2618 11.118 11.7095 11.6703 11.7095Z"
            fill="#3538CD"
          />
          <path
            d="M21.6703 15.0428H11.6703C11.118 15.0428 10.6703 15.4905 10.6703 16.0428C10.6703 16.5951 11.118 17.0428 11.6703 17.0428H21.6703C22.2226 17.0428 22.6703 16.5951 22.6703 16.0428C22.6703 15.4905 22.2226 15.0428 21.6703 15.0428Z"
            fill="#3538CD"
          />
        </svg>
      ),
      title: "Personalized Messenger",
      text: "Send updates and notifications relevant to students with different interests.",
    },
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="33"
          viewBox="0 0 32 33"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.16016 4.04297H9.82682C11.4837 4.04297 12.8268 5.38611 12.8268 7.04297V11.7096C12.8268 13.3665 11.4837 14.7096 9.82682 14.7096H5.16016C3.5033 14.7096 2.16016 13.3665 2.16016 11.7096V7.04297C2.16016 5.38611 3.5033 4.04297 5.16016 4.04297ZM9.82682 12.7096C10.3791 12.7096 10.8268 12.2619 10.8268 11.7096V7.04297C10.8268 6.49068 10.3791 6.04297 9.82682 6.04297H5.16016C4.61087 6.05015 4.16734 6.49369 4.16016 7.04297V11.7096C4.16734 12.2589 4.61087 12.7025 5.16016 12.7096H9.82682Z"
            fill="#E31B54"
          />
          <path
            d="M18.1602 9.04297H24.8268C25.3791 9.04297 25.8268 8.59525 25.8268 8.04297C25.8268 7.49068 25.3791 7.04297 24.8268 7.04297H18.1602C17.6079 7.04297 17.1602 7.49068 17.1602 8.04297C17.1602 8.59525 17.6079 9.04297 18.1602 9.04297Z"
            fill="#E31B54"
          />
          <path
            d="M18.1602 13.043H28.8268C29.3791 13.043 29.8268 12.5953 29.8268 12.043C29.8268 11.4907 29.3791 11.043 28.8268 11.043H18.1602C17.6079 11.043 17.1602 11.4907 17.1602 12.043C17.1602 12.5953 17.6079 13.043 18.1602 13.043Z"
            fill="#E31B54"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.16016 17.3763H9.82682C11.4837 17.3763 12.8268 18.7194 12.8268 20.3763V25.043C12.8268 25.8386 12.5108 26.6017 11.9481 27.1643C11.3855 27.7269 10.6225 28.043 9.82682 28.043H5.16016C3.5033 28.043 2.16016 26.6998 2.16016 25.043V20.3763C2.16016 18.7194 3.5033 17.3763 5.16016 17.3763ZM9.82682 26.043C10.3791 26.043 10.8268 25.5953 10.8268 25.043V20.3763C10.8268 19.824 10.3791 19.3763 9.82682 19.3763H5.16016C4.61087 19.3835 4.16734 19.827 4.16016 20.3763V25.043C4.16734 25.5923 4.61087 26.0358 5.16016 26.043H9.82682Z"
            fill="#E31B54"
          />
          <path
            d="M18.1602 22.3763H24.8268C25.3791 22.3763 25.8268 21.9286 25.8268 21.3763C25.8268 20.824 25.3791 20.3763 24.8268 20.3763H18.1602C17.6079 20.3763 17.1602 20.824 17.1602 21.3763C17.1602 21.9286 17.6079 22.3763 18.1602 22.3763Z"
            fill="#E31B54"
          />
          <path
            d="M28.8268 24.3763H18.1602C17.6079 24.3763 17.1602 24.824 17.1602 25.3763C17.1602 25.9286 17.6079 26.3763 18.1602 26.3763H28.8268C29.3791 26.3763 29.8268 25.9286 29.8268 25.3763C29.8268 24.824 29.3791 24.3763 28.8268 24.3763Z"
            fill="#E31B54"
          />
        </svg>
      ),
      title: "Events & Appointments",
      text: "Publish on-campus and online recruiting events on the platform.",
    },
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="33"
          height="33"
          viewBox="0 0 33 33"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.16127 9.48337L14.5879 13.2034C15.6323 13.7952 16.2778 14.9029 16.2778 16.1034C16.2778 17.3038 15.6323 18.4115 14.5879 19.0034L8.16127 22.71C7.65508 23.0017 7.07869 23.1493 6.4946 23.1367C5.61055 23.1367 4.7627 22.7855 4.13758 22.1604C3.51246 21.5353 3.16127 20.6874 3.16127 19.8034V12.3767C3.15052 11.1811 3.78345 10.0721 4.8183 9.47329C5.85314 8.87446 7.13005 8.87831 8.16127 9.48337ZM7.16127 20.95L13.5879 17.2034V17.2567C13.9991 17.0182 14.2522 16.5787 14.2522 16.1034C14.2522 15.628 13.9991 15.1886 13.5879 14.95L7.16127 11.23C6.95936 11.1102 6.72938 11.0458 6.4946 11.0434C5.75822 11.0434 5.16127 11.6403 5.16127 12.3767V19.8034C5.16412 20.2783 5.41938 20.7158 5.8314 20.9521C6.24341 21.1883 6.74997 21.1875 7.16127 20.95Z"
            fill="#0077B6"
          />
          <path
            d="M20.8946 9.24337C20.3453 9.25055 19.9018 9.69409 19.8946 10.2434V22.0967C19.8946 22.649 20.3423 23.0967 20.8946 23.0967C21.4469 23.0967 21.8946 22.649 21.8946 22.0967V10.2434C21.8946 9.97815 21.7893 9.7238 21.6017 9.53626C21.4142 9.34873 21.1598 9.24337 20.8946 9.24337Z"
            fill="#0077B6"
          />
          <path
            d="M28.5079 9.24337C27.9587 9.25055 27.5151 9.69409 27.5079 10.2434V22.0967C27.5079 22.649 27.9557 23.0967 28.5079 23.0967C29.0602 23.0967 29.5079 22.649 29.5079 22.0967V10.2434C29.5008 9.69409 29.0572 9.25055 28.5079 9.24337Z"
            fill="#0077B6"
          />
        </svg>
      ),
      title: "Challenges & Alumni",
      text: "Record your own virtual challenges and get students job ready with useful tips.",
    },
  ];

  return (
    <div className="marginsleftrightCompany">
      <div className="center">
        <div className="headerCompany">
          <p className="text-center gray80016500">VSX Tools</p>
          <p
            className="text-center newheadingtextthirdsection"
            style={{ marginTop: "1rem" }}
          >
            Accelerate student experience
          </p>
          <div className="center">
            <p
              className="text-center gray95016500"
              style={{ marginTop: "1rem", maxWidth: "518px", fontWeight: 400 }}
            >
              Boost student engagement and success with in-depth analytics and
              varyance student experience (VSX) tools.
            </p>
          </div>
          <div className="center" style={{ marginTop: "2.5rem" }}>
            <div className="d-flex">
              {options.map((opt, index) => (
                <div
                  className="text-center gray95020400 "
                  key={index}
                  onClick={() => {
                    setSelected(opt);
                  }}
                  style={{
                    width: isNonMobile450 ? "200px" : "180px",
                    paddingBottom: "1rem",
                    cursor: "pointer",
                    color: opt === selected ? "#E31b54" : "",

                    borderBottom: `2px solid ${
                      opt === selected ? "#E31b54" : "#EAECF0"
                    }`,
                  }}
                >
                  {opt}
                </div>
              ))}
            </div>
          </div>
          <div
            className="d-flex flex-wrap"
            style={{ marginTop: "3.75rem", gap: "1.5rem" }}
          >
            {(selected === "Career Analytics" ? list1 : list2).map(
              (item, index) => (
                <div
                  key={index}
                  className="center align-items-center"
                  style={{
                    background: "#FFF",
                    width: !isNon1024
                      ? "calc((100% - 2 * 1.5rem) / 3)"
                      : isNon
                      ? "100%"
                      : "100%", // Width calculation for 3 items
                    maxWidth: !isNon1024
                      ? "calc((100% - 2 * 1.5rem) / 3)"
                      : isNon
                      ? "100%"
                      : "100%",
                    minHeight: "232px",
                    borderRadius: "5px",
                    padding: "1.25rem",
                    boxSizing: "border-box", // Ensure padding is included in width
                  }}
                >
                  <div>
                    <div className="center">{item.icon}</div>

                    <p
                      className="gray95020400 text-center"
                      style={{
                        fontWeight: 500,
                        marginTop: "1.25rem",
                        fontFamily: "Poppins",
                      }}
                    >
                      {item.title}
                    </p>
                    <p
                      style={{ marginTop: "0.75rem", fontSize: "1rem" }}
                      className="gray70014400 text-center"
                    >
                      {item.text}
                    </p>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Third;
