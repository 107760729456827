import { useState } from "react";
import ChallengeCard from "../../../components/Company/Dashboard/ChallengeCard";
import emptyState from "../../../assets/images/EmptyState.png";
const Explore = ({ challenges, navigate, loading }) => {
  const [page, setPage] = useState(1);
  const challengesPerPage = 20;

  const indexOfLastChallenge = page * challengesPerPage;
  const indexOfFirstChallenge = indexOfLastChallenge - challengesPerPage;

  // Slice the challenges array for the current page
  const currentChallenges = challenges.slice(
    indexOfFirstChallenge,
    indexOfLastChallenge
  );

  // Calculate the total number of pages
  const totalPages = Math.ceil(challenges.length / challengesPerPage);

  return (
    <div>
      {/* Pagination Buttons */}

      {currentChallenges.length > 0 ? (
        <div
          className="d-grid"
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
            gap: "1.5rem",
            width: "100%",
          }}
        >
          {currentChallenges.map((challe, index) => (
            <div
              key={index}
              style={{ flex: "1 1 calc(25% - 1.5rem)" }}
              onClick={() => {
                navigate(`/challenge/${challe.uniqueId}`);
              }}
            >
              <ChallengeCard
                challe={challe}
                navigate={navigate}
                student={true}
              />
            </div>
          ))}
        </div>
      ) : loading ? (
        <p>Loading</p>
      ) : (
        <div style={{ width: "100%" }}>
          <div className="center">
            <img
              src={emptyState}
              className="img-fluid"
              style={{ maxWidth: "300px" }}
              alt="empty state"
            />
          </div>
          <p className="black14 text-center mt-3">No challenges found.</p>
          <p className="gray60011400 text-center mt-2">
            Try changing the filters to find exciting Challenges.
          </p>
        </div>
      )}

      {challenges.length > 0 && (
        <div
          className="pagination-buttons d-flex flex-wrap align-items-center"
          style={{ marginBottom: "1rem", marginTop: "1rem", gap: "0.75rem" }}
        >
          <button
            onClick={() => {
              setPage((prevPage) => {
                const newPage = prevPage > 1 ? prevPage - 1 : 1;
                setTimeout(() => {
                  window.scrollTo({
                    top: document.body.scrollHeight,
                    behavior: "smooth", // Change to "instant" if needed
                  });
                }, 100);
                return newPage;
              });
            }}
            disabled={page === 1}
            className="backbutton"
          >
            Previous
          </button>
          <span
            className="black14"
            style={{ gap: "0.5rem", display: "flex" }}
          >{`Showing ${page} of ${totalPages}`}</span>
          <button
            onClick={() => {
              setPage((prevPage) => {
                const newPage =
                  prevPage < totalPages ? prevPage + 1 : totalPages;

                return newPage;
              });
              setTimeout(() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth", // Change to "instant" if needed
                });
              }, 100);
            }}
            disabled={page === totalPages}
            className="continuebutton"
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};
export default Explore;
