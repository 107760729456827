import { useMediaQuery } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useState, useRef, useEffect } from "react";
import inviteteam from "../../assets/images/inviteteam.png";
import axios from "axios";
import "./team.css";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { toast } from "react-toastify";
import emailjs from "@emailjs/browser";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Table from "./Table";
const Team = () => {
  const isNonMob1024 = useMediaQuery("(min-width:1024px)");
  const isNonMob = useMediaQuery("(min-width:768px)");
  const [search, setSearch] = useState("");
  const [showRequest, setShowRequest] = useState(false);
  const [showinfo, setShowInfo] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const iconRef = useRef(null);
  const popupref = useRef(null);
  const id = useSelector((state) => state.uniqueId);
  const [user, setUser] = useState([]);
  const [userEmail, setUserEmail] = useState("");
  const [company, setCompany] = useState();
  const navigate = useNavigate();
  const [inviteShow, setInviteShow] = useState(false);
  const [email, setEmail] = useState("");
  const [invites, setInvites] = useState([]);
  const [recordsToShow, setRecordsToShow] = useState([]);
  const [DATA, setData] = useState(false);

  console.log({ user });
  console.log({ company });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });

    function handleClickOutside(event) {
      if (iconRef.current && !iconRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (popupref.current && !popupref.current.contains(event.target)) {
        setInviteShow(false);
        setUserEmail("");
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userInfoResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/getUserInfo/uniqueId`,
          { email: id }
        );

        if (userInfoResponse.data.message === "Found") {
          setUser(userInfoResponse.data.user);
        } else {
          navigate("/company/register");
          return;
        }

        const InvitesResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/getInvites`,
          {
            uniqueId: userInfoResponse.data.user.uniqueId,
          }
        );

        if (InvitesResponse.data.msg === "success") {
          setInvites(InvitesResponse.data.invites);
          setRecordsToShow(InvitesResponse.data.invites);
        }

        const companyInfoResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/getCompanyInfo`,
          { id: userInfoResponse.data.user.company }
        );
        if (companyInfoResponse.data.msg === "Logout") {
          navigate("/company/register");
          return;
        }

        setCompany(companyInfoResponse.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [id, DATA]);

  const Roles = [
    {
      name: "Recruiter",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.3168 13.9244L16.5168 14.8744C16.7064 15.6957 16.5192 16.5588 16.0065 17.2278C15.4939 17.8969 14.7092 18.3021 13.8668 18.3327H6.13349C5.29114 18.3021 4.50647 17.8969 3.99378 17.2278C3.4811 16.5588 3.29397 15.6957 3.48349 14.8744L3.68349 13.9244C3.91352 12.6384 5.01901 11.6933 6.32516 11.666H13.6752C14.9813 11.6933 16.0868 12.6384 16.3168 13.9244ZM13.8668 17.0744C14.29 17.0695 14.6882 16.8734 14.9502 16.541V16.5494C15.2716 16.1462 15.3968 15.6209 15.2918 15.116L15.0918 14.166C14.9809 13.462 14.3874 12.9348 13.6752 12.9077H6.32516C5.61297 12.9348 5.01947 13.462 4.9085 14.166L4.7085 15.116C4.60613 15.6182 4.73121 16.1399 5.05016 16.541C5.31211 16.8734 5.71034 17.0695 6.1335 17.0744H13.8668Z"
            fill="#475467"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.4168 9.99936H9.58348C7.74253 9.99936 6.25015 8.50698 6.25015 6.66603V4.46603C6.24793 3.72274 6.54221 3.00926 7.0678 2.48368C7.59338 1.95809 8.30686 1.66381 9.05015 1.66603H10.9501C11.6934 1.66381 12.4069 1.95809 12.9325 2.48368C13.4581 3.00926 13.7524 3.72274 13.7501 4.46603V6.66603C13.7501 8.50698 12.2578 9.99936 10.4168 9.99936ZM9.05015 2.91603C8.19411 2.91603 7.50015 3.60999 7.50015 4.46603V6.66603C7.50015 7.81662 8.43289 8.74936 9.58348 8.74936H10.4168C11.5674 8.74936 12.5001 7.81662 12.5001 6.66603V4.46603C12.5001 4.05494 12.3368 3.66069 12.0462 3.37001C11.7555 3.07933 11.3612 2.91603 10.9501 2.91603H9.05015Z"
            fill="#475467"
          />
        </svg>
      ),
    },
    {
      name: "Team",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.1166 12.2836L18.2333 12.8586C18.3603 13.3498 18.2567 13.8721 17.9517 14.2776C17.6468 14.6831 17.1738 14.9276 16.6666 14.9419H13.4916C13.4923 16.2907 12.423 17.397 11.0749 17.4419H4.16659C3.40371 17.4293 2.68836 17.069 2.2241 16.4635C1.75984 15.858 1.59749 15.0736 1.78326 14.3336L1.95826 13.5003C2.16175 12.3523 3.15087 11.51 4.31659 11.4919H10.9333C11.2286 11.139 11.6648 10.9346 12.1249 10.9336H16.5333C17.3116 10.9584 17.969 11.5189 18.1166 12.2836ZM16.6499 13.6836C16.7516 13.6798 16.8463 13.6309 16.9083 13.5503C17.0173 13.4403 17.0721 13.2878 17.0583 13.1336L16.9416 12.5586C16.919 12.3723 16.77 12.2268 16.5833 12.2086H12.1749C12.079 12.2092 11.9892 12.2557 11.9333 12.3336C11.6943 12.6088 11.3477 12.7669 10.9833 12.7669H4.36659C3.7961 12.7808 3.31808 13.2026 3.23326 13.7669L3.05826 14.6003C2.97278 15.0107 3.07348 15.4379 3.33326 15.7669C3.53617 16.0228 3.84029 16.1779 4.16659 16.1919H11.0499C11.7206 16.1474 12.236 15.5805 12.2166 14.9086C12.2302 14.228 12.7859 13.6835 13.4666 13.6836H16.6499Z"
            fill="#475467"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.9166 6.66693C10.9166 8.50788 9.42421 10.0003 7.58326 10.0003C5.74231 10.0003 4.24992 8.50788 4.24992 6.66693V5.35859C4.24992 3.8122 5.50353 2.55859 7.04992 2.55859H8.14159C9.67822 2.57231 10.9167 3.82191 10.9166 5.35859V6.66693ZM7.04992 3.80859C6.19388 3.80859 5.49992 4.50255 5.49992 5.35859L5.52492 6.66693C5.65792 7.71624 6.55055 8.50288 7.60826 8.50288C8.66596 8.50288 9.5586 7.71624 9.69159 6.66693V5.35859C9.69159 4.50255 8.99763 3.80859 8.14159 3.80859H7.04992Z"
            fill="#475467"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.2333 6.87526C17.2333 8.25597 16.114 9.37526 14.7333 9.37526C13.3525 9.37526 12.2333 8.25597 12.2333 6.87526V5.91693C12.2331 4.77002 13.1532 3.83513 14.2999 3.81693H15.1333C16.2931 3.81693 17.2333 4.75713 17.2333 5.91693V6.87526ZM14.3166 5.08359C13.8564 5.08359 13.4833 5.45669 13.4833 5.91693V6.87526C13.4833 7.56562 14.0429 8.12526 14.7333 8.12526C15.4236 8.12526 15.9833 7.56562 15.9833 6.87526V5.91693C15.9833 5.45669 15.6102 5.08359 15.1499 5.08359H14.3166Z"
            fill="#475467"
          />
        </svg>
      ),
    },
    {
      name: "Representative",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.3329 7.29944C13.3057 8.66058 12.1944 9.74971 10.8329 9.74944C9.48776 9.72728 8.40134 8.64454 8.37461 7.29944V6.35777C8.37445 5.80309 8.59816 5.27182 8.99505 4.88434C9.39195 4.49686 9.92843 4.28596 10.4829 4.29944H11.2663C11.8136 4.29722 12.3393 4.5131 12.7271 4.89935C13.1149 5.28561 13.3329 5.81042 13.3329 6.35777V7.29944ZM10.4579 5.52444C9.99771 5.52444 9.62461 5.89753 9.62461 6.35777L9.65794 7.29944C9.65794 7.96678 10.1989 8.50777 10.8663 8.50777C11.5336 8.50777 12.0746 7.96678 12.0746 7.29944V6.35777C12.0746 5.89753 11.7015 5.52444 11.2413 5.52444H10.4579Z"
            fill="#475467"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.5663 12.7494L19.6579 14.0494C19.7543 14.4563 19.6602 14.8849 19.4024 15.214C19.1446 15.5432 18.751 15.7371 18.3329 15.7411H14.5913C14.2649 16.089 13.81 16.2879 13.3329 16.2911H8.33295C7.83176 16.2978 7.35198 16.0883 7.01628 15.7161C6.93874 15.7247 6.86049 15.7247 6.78295 15.7161H2.95795C2.55126 15.7008 2.17226 15.506 1.92296 15.1844C1.67365 14.8627 1.57966 14.4471 1.66628 14.0494L1.79128 12.7494C1.91288 12.1291 2.45095 11.6779 3.08295 11.6661H6.66628C6.81585 11.6578 6.96566 11.6776 7.10795 11.7244C7.42766 11.3251 7.91306 11.0947 8.42461 11.0994H13.2413C13.7229 11.0976 14.1817 11.3043 14.4996 11.6661C14.5577 11.6579 14.6166 11.6579 14.6746 11.6661H18.2746C18.9066 11.6779 19.4447 12.1291 19.5663 12.7494ZM14.6246 14.4661H18.3996C18.4251 14.417 18.4282 14.3593 18.4079 14.3078V14.1411L18.3246 12.9578L18.2746 12.8911H14.4996C14.1473 12.8924 13.8111 12.7439 13.5746 12.4828C13.4919 12.3839 13.3702 12.326 13.2413 12.3244H8.42462C8.28501 12.3262 8.15353 12.3904 8.06628 12.4994C7.8284 12.7818 7.47717 12.9437 7.10795 12.9411C6.95676 12.9418 6.80683 12.9135 6.66628 12.8578H3.12462C3.07462 12.8578 3.07462 12.8744 3.07462 12.9244L2.99128 14.1078V14.2744C2.96846 14.3247 2.96846 14.3825 2.99128 14.4328H6.81628C6.90498 14.4246 6.99425 14.4246 7.08295 14.4328C7.42161 14.4391 7.74208 14.5872 7.96628 14.8411C8.05823 14.9487 8.19147 15.0123 8.33295 15.0161H13.3663C13.508 15.0134 13.6417 14.9496 13.733 14.8411C13.9673 14.6007 14.2889 14.4654 14.6246 14.4661Z"
            fill="#475467"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.3079 8.54944C18.3079 9.56196 17.4871 10.3828 16.4746 10.3828C15.9869 10.385 15.5185 10.1928 15.1729 9.84875C14.8272 9.5047 14.6329 9.03711 14.6329 8.54944V7.84944C14.6329 7.44056 14.7954 7.04843 15.0845 6.75931C15.3736 6.47019 15.7657 6.30777 16.1746 6.30777H16.7663C17.6177 6.30777 18.3079 6.998 18.3079 7.84944V8.54944ZM15.9684 7.67653C15.9137 7.73123 15.8829 7.80541 15.8829 7.88277V8.58277C15.9184 8.88032 16.1708 9.10434 16.4704 9.10434C16.7701 9.10434 17.0224 8.88032 17.0579 8.58277V7.88277C17.0579 7.72169 16.9274 7.5911 16.7663 7.5911H16.1746C16.0973 7.5911 16.0231 7.62183 15.9684 7.67653Z"
            fill="#475467"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.1847 9.84875C5.83908 10.1928 5.37062 10.385 4.88295 10.3828C4.39672 10.3828 3.9304 10.1896 3.58659 9.8458C3.24277 9.50198 3.04961 9.03567 3.04961 8.54944V7.84944C3.04961 6.998 3.73984 6.30777 4.59128 6.30777H5.18295C6.03439 6.30777 6.72461 6.998 6.72461 7.84944V8.54944C6.72462 9.03711 6.53032 9.5047 6.1847 9.84875ZM4.59128 7.5911C4.4302 7.5911 4.29961 7.72169 4.29961 7.88277V8.58277C4.33511 8.88032 4.58746 9.10434 4.88711 9.10434C5.18677 9.10434 5.43911 8.88032 5.47461 8.58277V7.88277C5.47461 7.72169 5.34403 7.5911 5.18295 7.5911H4.59128Z"
            fill="#475467"
          />
        </svg>
      ),
    },
  ];

  const handleInviteNewTeam = () => {
    if (user && company) {
      const Role = selectedRole.name;
      let dsa;
      if (Role === "Recruiter") {
        dsa = "fadsdgaskgjfdasfdasjfkalas";
      } else if (Role === "Team") {
        dsa = "jkfdhgjkhdjkhgdsjkjdksjfkjds";
      } else if (Role === "Representative") {
        dsa = "jkfdhgjkhdjkhgdsjkjfadsdfasfasdksjfkjds";
      }
      const inviteLink = `${process.env.REACT_APP_FRONTEND_URL}/registration/${company.uniqueId}/${dsa}/${user.uniqueId}`;

      navigator.clipboard.writeText(inviteLink).then(
        function () {
          toast.success("Link copied to clipboard!");
        },
        function (err) {
          console.error("Could not copy text: ", err);

          toast.error("Failed to copy link to clipboard.");
        }
      );
    }
  };

  const handleInviteSend = () => {
    if (user && company) {
      setUserEmail("");
      const Role = selectedRole.name;
      let dsa;
      if (Role === "Recruiter") {
        dsa = "fadsdgaskgjfdasfdasjfkalas";
      } else if (Role === "Team") {
        dsa = "jkfdhgjkhdjkhgdsjkjdksjfkjds";
      } else if (Role === "Representative") {
        dsa = "jkfdhgjkhdjkhgdsjkjfadsdfasfasdksjfkjds";
      }
      const inviteLink = `${process.env.REACT_APP_FRONTEND_URL}/registration/${company.uniqueId}/${dsa}/${user.uniqueId}`;
      sendEmail(inviteLink);
      setInviteShow(false);
    }
  };

  const sendEmail = (ad) => {
    const payload = {
      code: `${ad}`,
      to_email: userEmail,
      from_name: user?.firstName,
      company_name: company?.title,
    };

    emailjs
      .send("service_08a640e", "template_ipvtlwn", payload, "FCKBh2iU92kGQhhAA")
      .then(
        (result) => {
          toast.success("Email invite sent");
          handleInvitesave();
        },
        (error) => {
          console.log(error.text);
          toast.error("Error sending invite ");
        }
      );
  };

  const [selectedRole, setSelectedRole] = useState(Roles[0]);

  useEffect(() => {
    if (search) {
      const filteredInvites = invites.filter(
        (invite) =>
          invite?.email?.toLowerCase()?.includes(search.toLowerCase()) ||
          invite?.userEmail?.toLowerCase()?.includes(search.toLowerCase())
      );
      setRecordsToShow(filteredInvites);
    } else {
      setRecordsToShow(invites);
    }
  }, [search]);

  const handleInvitesave = async () => {
    await axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/api/send/invite`, {
        userEmail: userEmail,
        uniqueId: user.uniqueId,
        role: selectedRole.name,
        checker: true,
      })
      .then((res) => {
        if (res.data.msg === "alreadyreg") {
          toast.error("User already registered");
        } else if (res.data.msg === "alreadyinvited") {
          toast.error("User already invited");
        } else if (res.data.msg === "success") {
          setUserEmail("");
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setData((prev) => !prev);
  };

  const handleInviteSent = async () => {
    await axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/api/send/invite`, {
        userEmail: userEmail,
        uniqueId: user.uniqueId,
        role: selectedRole.name,
        checker: false,
      })
      .then((res) => {
        if (res.data.msg === "alreadyreg") {
          toast.error("User already registered");
        } else if (res.data.msg === "alreadyinvited") {
          toast.error("User already invited");
        } else if (res.data.msg === "success") {
          handleInviteSend();
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setData((prev) => !prev);
  };

  return (
    <>
      <div className="paddingmaincontent">
        {inviteShow && (
          <div
            className="center zindex1000"
            style={{
              width: "100%",
              height: "100%",

              position: "fixed",
              alignItems: "center",
              top: 0,
              left: 0,
              right: 0,
              zIndex: 11,
              backgroundColor: "rgba(45, 50, 130, 0.30)",
              backdropFilter: "blur(3px)",
              padding: "1rem",
            }}
          >
            <div
              className="shadowa newPopUpContainer"
              ref={popupref}
              style={{
                maxWidth: "90vw",
                maxHeight: "80vh",

                maxWidth: "400px",
                backgroundColor: "",
                padding: "0rem",
              }}
            >
              <div className="center">
                <img
                  src={inviteteam}
                  alt="inviteteam"
                  style={{ width: "100%", maxWidth: "400px" }}
                />
              </div>

              <div className="pt-0" style={{ padding: "1.5rem" }}>
                <p className="text-center gray90018500">Invite your team</p>
                <p
                  className="text-center gray70020px"
                  style={{
                    marginTop: "0.25rem",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "20px",
                  }}
                >
                  Kindly provide your colleague's email address to send the
                  invite to for the{" "}
                  <span
                    className="gray70020px"
                    style={{
                      fontWeight: 600,
                      fontSize: "14px",
                      lineHeight: "20px",
                    }}
                  >
                    {selectedRole.name} Role
                  </span>
                  .
                </p>
                <p
                  className="gray70020px text-start"
                  style={{
                    marginTop: "1.25rem",
                    fontSize: "14px",
                    lineHeight: "20px",
                  }}
                >
                  User Email
                </p>
                <input
                  className="forminput col-md-12 col-12"
                  value={userEmail}
                  onChange={(e) => setUserEmail(e.target.value)}
                  style={{ marginTop: "0.7rem" }}
                />
              </div>
              <hr style={{ marginTop: "2rem", marginBottom: "1.5rem" }} />
              <div
                className="d-flex "
                style={{ marginTop: "1.75rem", justifyContent: "center" }}
              >
                <div
                  className="d-flex flex-wrap pt-0"
                  style={{ gap: "0.75rem", padding: "1.5rem" }}
                >
                  <button
                    className="backbutton"
                    style={{ width: isNonMob ? "170px" : "" }}
                    onClick={() => {
                      setUserEmail("");
                      setInviteShow(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="continuebutton"
                    style={{ width: isNonMob ? "170px" : "" }}
                    onClick={() => handleInviteSent()}
                  >
                    Send Invitation
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className=" w-full">
          <div className="innerglass w-full">
            <p className="black16 text-start">
              Add your team members to the party
            </p>
            <div className="d-flex">
              <p
                style={{
                  marginTop: "1.5rem",
                  fontSize: "14px",
                  cursor: "pointer",
                  alignItems: "center",
                }}
                className="pink12 hoverdarkred"
                onClick={() => setShowRequest(true)}
              >
                <AddIcon /> Add team members.
              </p>
            </div>
            {showRequest && (
              <div style={{ marginTop: "2.5rem" }}>
                <p
                  className="black14 d-flex"
                  style={{ alignItems: "center", gap: "0.5rem" }}
                >
                  Select Role{" "}
                  {isNonMob1024 && (
                    <InfoOutlinedIcon
                      style={{
                        height: "20px",
                        width: "20px",
                        cursor: "pointer",
                      }}
                      className="hoverred "
                      onMouseEnter={() => setShowInfo(true)}
                      onMouseLeave={() => setShowInfo(false)}
                    />
                  )}
                </p>
                {showinfo && (
                  <div
                    className=" shadowa"
                    style={{
                      position: "absolute",
                      marginLeft: "4rem",
                      marginTop: "1rem",
                      zIndex: 5,
                      maxWidth: "20.75rem",
                    }}
                  >
                    <div className="innerglass">
                      <ul>
                        <li className="black14" style={{ fontWeight: "400" }}>
                          <span
                            className="black14"
                            style={{ fontWeight: "600" }}
                          >
                            Recruiter
                          </span>{" "}
                          can use all engagemnt tools, have access to the talent
                          pool and analytics.
                        </li>
                        <li
                          className="black14"
                          style={{ fontWeight: "400", marginTop: "0.75rem" }}
                        >
                          <span
                            className="black14"
                            style={{ fontWeight: "600" }}
                          >
                            Team members
                          </span>{" "}
                          have only access to the company talent pool and
                          analytics.
                        </li>
                        <li
                          className="black14"
                          style={{ fontWeight: "400", marginTop: "0.75rem" }}
                        >
                          <span
                            className="black14"
                            style={{ fontWeight: "600" }}
                          >
                            Representatives
                          </span>{" "}
                          register to create a challenge or attend an
                          online-event.
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
                <div
                  className="d-flex flex-wrap"
                  style={{ marginTop: "0.75rem", gap: "1.5rem" }}
                >
                  <div style={{ position: "relative" }}>
                    {" "}
                    <div
                      className="forminput space hoverred"
                      style={{
                        minWidth: isNonMob1024 ? "20.25rem" : "",
                        gap: "1rem",
                        cursor: "pointer",
                      }}
                      onClick={() => setShowOptions(!showOptions)}
                    >
                      <div
                        className="d-flex"
                        style={{ alignItems: "center", gap: "1rem" }}
                      >
                        {selectedRole.icon}
                        <p className="black14">{selectedRole.name}</p>
                      </div>
                      <div>
                        <KeyboardArrowDownIcon />
                      </div>
                    </div>
                    <div className="w-full">
                      {showOptions && (
                        <div
                          ref={iconRef}
                          className="shadowa rounded-2"
                          style={{
                            position: "absolute",
                            background: "#FFF",
                            zIndex: 2,
                            width: "100%",
                          }}
                        >
                          {Roles.map((role, index) => (
                            <>
                              {role.name !== selectedRole.name && (
                                <div
                                  key={index}
                                  className="d-flex rounded-1 popuptextcover"
                                  style={{
                                    borderBottom: "1px solid #D0D5DD",
                                    gap: "0.5rem",
                                    padding: "1rem",
                                    cursor: "pointer",
                                    minWidth: "100%",
                                  }}
                                  onClick={() => {
                                    setSelectedRole(role);
                                    setShowOptions(false);
                                  }}
                                >
                                  {role.icon}
                                  <p className="popuptext">{role.name}</p>
                                </div>
                              )}
                            </>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>

                  <button
                    className="continuebutton"
                    onClick={() => {
                      setInviteShow(true);
                    }}
                  >
                    Invite new teammate
                  </button>
                  <button
                    className="backbutton p-2"
                    onClick={() => handleInviteNewTeam()}
                  >
                    <ContentCopyIcon
                      style={{ height: "26px", width: "26px" }}
                    />
                    {isNonMob && "Invite Link"}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>

        <div>
          <Table
            invites={recordsToShow}
            Roles={Roles}
            search={search}
            setSearch={setSearch}
          />
        </div>
      </div>
    </>
  );
};
export default Team;
