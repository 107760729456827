import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useEffect, useRef, useState } from "react";
import CanvasData from "../Challenge/CanvasData";
import EmptyState from "../../assets/images/EmptyState.png";
const NewAnalytics = ({ usersWithDetails, engagedData, loading }) => {
  const [view, setView] = useState("gender");

  const [users, setUsers] = useState([]);

  const [showView, setShowView] = useState(false);
  const iconRef = useRef();

  useEffect(() => {
    let checkusers = [];

    engagedData.forEach((engage) => {
      const matchedUser = usersWithDetails.find(
        (user) => engage === user?.userDetails?.user?._id
      );

      if (matchedUser) {
        checkusers.push({
          _id: matchedUser?.userDetails?.user?._id,
          gender: matchedUser?.userDetails?.user?.gender ?? "Other",
          study: matchedUser?.userDetails?.user?.study,
        });
      }
    });

    setUsers(checkusers);
  }, [usersWithDetails, engagedData]);

  useEffect(() => {
    // Scroll to the top
    window.scrollTo({ top: 0, behavior: "instant" });

    function handleClickOutside(event) {
      // Check if the click is outside iconRef
      if (iconRef.current && !iconRef.current.contains(event.target)) {
        setShowView(false);
      }

      // Check if the click is outside iconRef2
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [iconRef]);

  return (
    <div style={{ height: "fit-content" }}>
      <div className="space flex-wrap align-items-center">
        <p className="gray95016500">Analytics</p>
        <div style={{ position: "relative" }}>
          <button
            className="bluebutton"
            style={{ padding: "6px 10px" }}
            onClick={() => {
              setShowView(!showView);
            }}
          >
            Parameters <KeyboardArrowDownIcon />
          </button>
          {showView && (
            <div
              className="shadowa"
              ref={iconRef}
              style={{
                position: "absolute",
                width: "100%",
                zIndex: 2,
                minWidth: "180px",
                right: 0,

                // marginTop: "2.25rem",
              }}
            >
              <button
                className="dropdowntopbutton"
                onClick={() => {
                  setView("gender");
                  setShowView(false);
                }}
              >
                Gender
              </button>

              <button
                className="dropdowntopbutton"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setView("fieldofstudy");
                  setShowView(false);
                }}
              >
                Field of study
              </button>
            </div>
          )}
        </div>
      </div>
      {engagedData.length > 0 ? (
        <CanvasData view={view} users={users} True={true} />
      ) : (
        <>
          {loading ? (
            <p className="black14">Loading...</p>
          ) : (
            <div
              className="center flex-column align-items-center "
              style={{ width: "100%", height: "90%", marginTop: "4.1rem" }}
            >
              <img
                src={EmptyState}
                alt="emptystate"
                style={{ maxWidth: "86px", maxHeight: "70px" }}
              />
              <p className="pink16">No Data yet</p>
              <p
                className="black14 text-center"
                style={{
                  fontWeight: "500",
                  maxWidth: "332px",
                  marginTop: "1rem",
                }}
              >
                <span className="black14"> Please come back later. </span>
                The first students will engage with your company in no time!
              </p>
            </div>
          )}
        </>
      )}
    </div>
  );
};
export default NewAnalytics;
