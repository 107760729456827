// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.g95016400 {
  color: var(--Gray-950, #0c111d);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.g95016400 span {
  color: var(--Gray-950, #0c111d);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.transitiona {
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
  /* overflow: hidden; Ensure the hidden state doesn't show content */
}

.newTasksnewdivouterpreview {
  padding: 16px;

  gap: 16px;
  align-self: stretch;
  border-radius: 5px;
  max-width: 800px;

  background: #fff;

  /* blue */
  box-shadow: 0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02);
}

.tasklisttasktitle {
  color: var(--Gray-700, #344054);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}
.tasklisttasktitle span {
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Challenge/PreviewPage/Tasksection/tasksection.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;EAC/B,kBAAkB;EAClB,oBAAoB;EACpB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,SAAS;AAC9B;AACA;EACE,+BAA+B;EAC/B,oBAAoB;EACpB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,iEAAiE;EACjE,mEAAmE;AACrE;;AAEA;EACE,aAAa;;EAEb,SAAS;EACT,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;;EAEhB,gBAAgB;;EAEhB,SAAS;EACT;;;kDAGgD;AAClD;;AAEA;EACE,+BAA+B;EAC/B,oBAAoB;EACpB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,SAAS;AAC9B;AACA;EACE,oBAAoB;EACpB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;AACnB","sourcesContent":[".g95016400 {\n  color: var(--Gray-950, #0c111d);\n  text-align: center;\n  font-family: Poppins;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 24px; /* 150% */\n}\n.g95016400 span {\n  color: var(--Gray-950, #0c111d);\n  font-family: Poppins;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 24px;\n}\n\n.transitiona {\n  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;\n  /* overflow: hidden; Ensure the hidden state doesn't show content */\n}\n\n.newTasksnewdivouterpreview {\n  padding: 16px;\n\n  gap: 16px;\n  align-self: stretch;\n  border-radius: 5px;\n  max-width: 800px;\n\n  background: #fff;\n\n  /* blue */\n  box-shadow: 0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),\n    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),\n    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),\n    0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02);\n}\n\n.tasklisttasktitle {\n  color: var(--Gray-700, #344054);\n  font-family: Poppins;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 24px; /* 150% */\n}\n.tasklisttasktitle span {\n  font-family: Poppins;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
