import ReactQuill from "react-quill";

const ChatBottom = ({ message, setMessage, handleSendMessage }) => {
  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
      [{ align: [] }],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "link",
    "image",
    "align",
  ];

  return (
    <div
      className="chatbottom__wrapper leftshadow"
      style={{ maxHeight: "259px" }}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSendMessage();
        }}
      >
        <ReactQuill
          value={message}
          onChange={(value) => setMessage(value)}
          modules={modules}
          formats={formats}
          className="col-md-12  reactquillfontfix  hovershadowa"
        />
        <div
          className="space flex-wrap"
          style={{ gap: "1rem", marginTop: "1rem" }}
        >
          <p className="formtext">
            {message?.replace(/(<([^>]+)>)/gi, "")?.length} Characters
          </p>
          <button className="continuebutton" type="submit">
            Send
          </button>
        </div>
      </form>
    </div>
  );
};
export default ChatBottom;
