import React, { useState } from "react";
import { useMediaQuery } from "@mui/material";
import finance from "../../assets/images/icons/Finance.png";
import img from "../../assets/images/learnskills.png";
const LearnSkills = () => {
  const isNonMobile = useMediaQuery("(min-width:768px)");

  const isNonMobile1441 = useMediaQuery("(min-width:1441px)");

  const [MouseHover, setStartHover] = useState(false);
  const skills = [
    "Company Analysis",
    "Data Interpretation",
    "DFC Analysis",
    "M&A Screening",
    "M&A Process Understanding",
  ];
  return (
    <div
      className="marginsleftrightCompany "
      style={{
        paddingTop: isNonMobile ? "8.125rem" : "5rem",
        paddingBottom: isNonMobile ? "8.125rem" : "5rem",
      }}
    >
      {" "}
      <div
        className="d-flex"
        style={{ justifyContent: "center", position: "relative" }}
      >
        <div
          className={` headerCompany   ${
            isNonMobile && "d-flex sectioncenter"
          }`}
          style={{ gap: "1rem" }}
        >
          <div className="widthfirst d-flex" style={{ alignItems: "center" }}>
            <div>
              {/* <p className=" sparkInterest">
                Learn <span>Skills</span>
              </p> */}
              <p
                style={{ width: "100%", maxWidth: isNonMobile ? "500px" : "" }}
                className="itsearly "
              >
                Learn and expand <span className="pinkspan">skills</span>{" "}
                relevant to your future career.
              </p>
              <div className={`marginfromabove  `} style={{ gap: "0.5rem" }}>
                <p className={`  learnmoreabout text-start`}>
                  Earn points for skills gained, showcase your abilities to
                  future employers, and receive badges for outstanding
                  achievements in your career field.
                </p>
                <div className={` `}>
                  <div
                    className="d-flex flex-wrap "
                    style={{
                      gap: "0.625rem 0.5rem",

                      marginTop: isNonMobile1441 ? "2rem" : "2rem",
                    }}
                  >
                    <div className="newskillwrapper">
                      <img
                        src={finance}
                        alt="finance"
                        height="17px"
                        width="17px"
                      />
                      Finance{" "}
                      <span
                        className="black12"
                        style={{ color: "#E31B54", fontWeight: "700" }}
                      >
                        Top 5%
                      </span>
                    </div>
                    {skills.map((ski, index) => (
                      <div className="newskillwrapper">{ski}</div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="widthsecond d-flex"
            style={{
              justifyContent: isNonMobile ? "end" : "",
              marginTop: "1rem",
            }}
          >
            <div>
              <img
                src={img}
                alt="learnskill"
                className={`img-fluid ${!isNonMobile && "mt-4"}`}
                style={{ padding: isNonMobile ? "7rem" : "" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LearnSkills;
