import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
const StudentCard = ({ short }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/get/user/details`,
          {
            msg: "fjksabnfjkbnasjfbajsbfjasjhfbjahsbjkfbk",
            userId: short.user,
          }
        );
        console.log(short);
        if (res.data.message === "Found") {
          setUser(res.data.user);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    fetchData();
  }, [short]);

  return (
    <>
      {console.log(user)}
      {user && (
        <div className="StudentCardMostOuterDiv" style={{ maxWidth: "364px" }}>
          <div className="space" style={{ alignItems: "center" }}>
            <img
              src={user.profilePhoto}
              alt="user photo"
              height="45px"
              width="45px"
              className="shadowa"
              style={{ borderRadius: "125px" }}
            />
            <div className="d-flex" style={{ gap: "12px" }}>
              <div className="countrycontainer black12">{user.uni.area}</div>
              <CloseIcon style={{ cursor: "pointer" }} />
            </div>
          </div>
          <div style={{ marginTop: "15px" }}>
            <p className="pink16">
              {user.firstName} {user.LastName}
            </p>
            <p className="black14" style={{ fontWeight: "400" }}>
              <span className="black14" style={{ fontWeight: "500" }}>
                {user.uni.name} |{" "}
              </span>
              {user.levelofstudy} {user.study}{" "}
              <span className="black14" style={{ fontWeight: "500" }}>
                |
              </span>{" "}
              Intake {user.intake.Year}
            </p>
          </div>
          <div style={{ marginTop: "1.25rem" }}>
            <p className="black14">Skills & Interests</p>
          </div>
          <div
            className="d-flex flex-wrap"
            style={{ gap: "10px 8px", marginTop: "0.75rem" }}
          >
            {user.Skills.slice(0, 5).map((skill, index) => (
              <div key={index} className="skillscontainer black12">
                {skill.skill}
              </div>
            ))}
            {user.Skills.length > 5 && (
              <div className="skillscontainer black12">
                +{user.Skills.length - 5}
              </div>
            )}
          </div>
          <div className="space" style={{ marginTop: "1.5rem" }}>
            <button className="backbutton">Message</button>
            <button className="continuebutton">Short List</button>
          </div>
        </div>
      )}
    </>
  );
};

export default StudentCard;
