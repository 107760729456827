const ChallengeIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clipPath="url(#clip0_410_5156)">
        <path
          d="M12.1113 4.99639L6.10135 11.2451C5.96542 11.3826 5.88875 11.5712 5.88875 11.7683C5.88875 11.9653 5.96542 12.154 6.10135 12.2915C6.23497 12.4303 6.41626 12.5085 6.60534 12.5086C6.79363 12.5044 6.9735 12.4269 7.10934 12.2915L13.1192 6.04277C13.2552 5.90528 13.3318 5.71661 13.3318 5.51958C13.3318 5.32256 13.2552 5.13389 13.1192 4.99639C12.8408 4.7077 12.3897 4.7077 12.1113 4.99639Z"
          fill={color || "#E31B54"}
        />
        <path
          d="M7.09983 8.86603C7.28851 8.86376 7.46906 8.78596 7.60383 8.64886L9.6103 6.56597C9.87215 6.27425 9.86442 5.81967 9.59282 5.53773C9.32122 5.25578 8.88332 5.24776 8.60231 5.51958L6.59584 7.60248C6.31773 7.89153 6.31773 8.3598 6.59584 8.64886C6.73167 8.78427 6.91155 8.86178 7.09983 8.86603Z"
          fill={color || "#E31B54"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.1196 11.3734L17.5126 3.30836C17.2102 1.78042 15.9151 0.683427 14.4125 0.682536H4.85563C3.3352 0.65977 2.01383 1.7621 1.70804 3.30836L0.10096 11.3734C-0.175059 12.2604 0.127732 13.2313 0.852198 13.7821L8.3931 19.5075C9.12103 20.0481 10.0996 20.0481 10.8275 19.5075L18.3684 13.8215C19.1095 13.2652 19.4142 12.2722 19.1196 11.3734ZM17.427 12.4297L9.88607 18.1255C9.72011 18.2446 9.50048 18.2446 9.33453 18.1255L1.79362 12.4297C1.62607 12.3087 1.55618 12.0871 1.62245 11.8867C1.6267 11.8276 1.6267 11.7682 1.62245 11.709L3.27708 3.64399C3.43306 2.87357 4.09754 2.32922 4.85563 2.35082H14.4601C15.2004 2.35296 15.8385 2.89198 15.9911 3.64399L17.5981 11.709C17.5939 11.7682 17.5939 11.8276 17.5981 11.8867C17.6644 12.0871 17.5945 12.3087 17.427 12.4297Z"
          fill={color || "#E31B54"}
        />
      </g>
      <defs>
        <clipPath id="clip0_410_5156">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default ChallengeIcon;
