import React from "react";
import pic1 from "../../assets/images/logosCOmpany/1.png";
import pic2 from "../../assets/images/logosCOmpany/2.png";
import pic4 from "../../assets/images/logosCOmpany/3.png";
import pic3 from "../../assets/images/logosCOmpany/4.png";
import pic5 from "../../assets/images/logosCOmpany/5.png";
import { useMediaQuery } from "@mui/material";
const FirstSection = ({ navigate }) => {
  const isNonMobile = useMediaQuery("(min-width: 768px)");
  const isNonMobile1024 = useMediaQuery("(min-width: 1024px)");
  const isNonMobile450 = useMediaQuery("(min-width: 450px)");
  return (
    <div className="d-flex" style={{ justifyContent: "center" }}>
      <div className="headerCompany margintopcompany">
        <div>
          <p className="smallheadingcompany">Global Campus Recruiting</p>
          <p
            className="mainheadingcompany"
            style={{
              display: "inline-block",
              gap: "20px",
              marginTop: "1.5rem",
              maxWidth: "722px",
            }}
          >
            Inspire early talent for their future career.
          </p>

          <div
            className={` ${!isNonMobile1024 ? "" : "d-flex"}`}
            style={{
              marginTop: "1rem",
              maxWidth: "722px",
            }}
          >
            <div
              className={` ${
                !isNonMobile1024 ? "" : "d-flex align-items-center"
              }`}
            >
              <div className="d-flex maxWidthClampedCompanysmalltext">
                <img
                  src={pic1}
                  alt="company"
                  className="Companypagelogoimages shadowa rounded-5"
                  style={{ position: "relative", zIndex: 5 }}
                />
                <img
                  src={pic2}
                  alt="company"
                  className="Companypagelogoimages moveLeftCompany shadowa rounded-5"
                  style={{ position: "relative", zIndex: 4, left: "-20px" }}
                />
                <img
                  src={pic3}
                  alt="company"
                  className="Companypagelogoimages shadowa rounded-5"
                  style={{ position: "relative", zIndex: 3, left: "-40px" }}
                />
                <img
                  src={pic4}
                  alt="company"
                  className="Companypagelogoimages shadowa rounded-5 moveLeftCompany"
                  style={{ position: "relative", zIndex: 2, left: "-55px" }}
                />
                <img
                  src={pic5}
                  alt="company"
                  className="Companypagelogoimages shadowa rounded-5 moveLeftCompany"
                  style={{ position: "relative", zIndex: 1, left: "-70px" }}
                />
              </div>
              <p
                className="challengeengageCompany"
                style={{
                  maxWidth: isNonMobile1024 ? "" : "500px",
                  marginTop: isNonMobile1024 ? "" : "1rem",
                  marginLeft: !isNonMobile1024 ? "" : "1rem",
                }}
              >
                Challenge, engage and connect with early talent and let students
                immerse into your company.
              </p>
            </div>
          </div>

          <div
            className={`engagemarginTopCompany d-flex flex-wrap`}
            style={{
              flexDirection: isNonMobile450 ? "row" : "column",
              alignItems: isNonMobile ? "center" : "flex-start",
              gap: "1rem",
            }}
          >
            <button
              className="continuebutton"
              style={{ height: "48px", width: isNonMobile450 ? "" : "100%" }}
              onClick={() => {
                navigate("/contact");
              }}
            >
              Let's Talk
            </button>
            <button
              className="backbutton"
              style={{
                height: "48px",
                width: isNonMobile450 ? "" : "100%",
                color: "#0c111d",
              }}
              onClick={() => navigate("/company/register")}
            >
              Start Onboarding (Free)
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstSection;
