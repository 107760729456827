import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Details from "./Details";
import Notifications from "./Notifications";
import Privacy from "./Privacy";
import Password from "./Password";
import axios from "axios";

const StudentSettings = () => {
  const [selected, setSelected] = useState("My Details");
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:767px)");
  const user = useSelector((state) => state.user);
  const [User, setUser] = useState(null);

  useEffect(() => {
    if (!user) {
      navigate("/");
    } else {
      const fetchData = async () => {
        if (user) {
          axios
            .post(
              `${process.env.REACT_APP_BACKEND_URL}/get/user/details/uniqueId`,
              {
                userId: user.uniqueId,
                msg: "fjksabnfjkbnasjfbajsbfjasjhfbjahsbjkfbk",
              }
            )
            .then((response) => {
              console.log(response.data);
              if (response.data.message === "Found") {
                setUser(response.data.user);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      };
      fetchData();
    }
  }, []);

  return (
    <div className="paddingmaincontent">
      <div>
        <div
          className={`transition ${isNonMobile && "d-flex"}  `}
          style={{
            justifyContent: "space-between",
            flexFlow: "wrap",
            gap: "10px",
            overflow: "auto",
          }}
        >
          <div
            className=" behindbuttons "
            style={{ minWidth: "430px", overflow: "auto" }}
          >
            <button
              className={`  border-0  ${
                selected === "My Details"
                  ? "selectedbutton rounded-2 "
                  : "secondbuttontext"
              } ${isNonMobile ? "" : "p-1  "}`}
              style={
                isNonMobile
                  ? {
                      minWidth: "188px",
                    }
                  : {}
              }
              onClick={() => {
                setSelected("My Details");
              }}
            >
              My Details
            </button>
            <button
              className={` border-0   ${
                selected === "Notifications"
                  ? "selectedbutton rounded-2 "
                  : "secondbuttontext"
              } ${isNonMobile ? "" : "p-1  "}`}
              style={isNonMobile ? { minWidth: "188px" } : {}}
              onClick={() => {
                setSelected("Notifications");
              }}
            >
              Notifications
            </button>

            <button
              className={`   border-0 ${
                selected === "Privacy"
                  ? "selectedbutton rounded-2 "
                  : "secondbuttontext"
              } ${isNonMobile ? "" : "p-1  "}`}
              style={isNonMobile ? { minWidth: "188px" } : {}}
              onClick={() => {
                setSelected("Privacy");
              }}
            >
              Privacy
            </button>
            <button
              className={`   border-0 ${
                selected === "Password"
                  ? "selectedbutton rounded-2 "
                  : "secondbuttontext"
              } ${isNonMobile ? "" : "p-1  "}`}
              style={isNonMobile ? { minWidth: "188px" } : {}}
              onClick={() => {
                setSelected("Password");
              }}
            >
              Password
            </button>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "2.5rem" }}>
        {selected === "My Details" && User && <Details user={User} />}
        {selected === "Notifications" && User && <Notifications user={User} />}
        {selected === "Privacy" && User && <Privacy user={User} />}
        {selected === "Password" && <Password />}
      </div>
    </div>
  );
};
export default StudentSettings;
