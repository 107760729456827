import logo from "../../assets/images/logo.png";
import { useMediaQuery } from "@mui/material";
import "./login.css";
import { toast } from "react-toastify";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const ForgotPassword = () => {
  const isNonMobile768 = useMediaQuery("(min-width: 768px)");

  const [email, setEmail] = useState("");

  const [error, setError] = useState("");
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email) {
      toast.error("Please enter email address ");
      return;
    }

    setLoader(true);

    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/company/forgot/password`,
        {
          email: email,
        }
      )
      .then((res) => {
        if (res.data.message === "Sent") {
          toast.success("Email sent successfully");
          navigate("/company/login");
        } else if (res.data.message === "User not found") {
          toast.error("User not registered");
        } else if (res.data.message === "Enter Email") {
          toast.error("Please enter email address");
        }
        setLoader(false);
      })
      .catch((err) => {
        setError("Error Sending recovery email");
        console.log(err);
        setLoader(false);
      });
  };

  return (
    <div className="loginbackground d-flex">
      <div style={{ width: isNonMobile768 ? "50%" : "100%" }}>
        <div
          className="marginsleftrightCompany"
          style={{ marginTop: "2.5rem" }}
        >
          <img
            src={logo}
            alt="logo"
            height="36px"
            width="36px"
            onClick={() => navigate("/")}
            style={{ cursor: "pointer" }}
          />
          <div
            style={{
              width: "100%",
              marginTop: "2.5rem",
            }}
          >
            <form onSubmit={(e) => handleSubmit(e)}>
              <p className="registeration">Recover Password</p>
              <p style={{ marginTop: "6px" }} className="headersmalltext">
                Please enter your Email to send recovery link.
              </p>
              <div style={{ marginTop: "1.25rem" }}>
                <label htmlFor="email" className="Email">
                  Email
                </label>
                <input
                  value={email}
                  id="email"
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  className="rounded-3   col-md-12 col-12  newinputs"
                  style={{ marginTop: "12px" }}
                />
              </div>

              <button
                className="continuebutton col-md-12 col-12"
                style={{ marginTop: "1.25rem" }}
                type="submit"
                disabled={loader}
              >
                Send Link
              </button>
            </form>

            <p className="didnt text-center " style={{ marginTop: "1.5rem" }}>
              Back to login?
              <span
                className="hoverdarkred"
                onClick={() => navigate("/company/login")}
              >
                {" "}
                Login
              </span>
            </p>
          </div>
        </div>
      </div>
      {isNonMobile768 && (
        <div style={{ width: "50%" }} className="companyrightimg"></div>
      )}
    </div>
  );
};

export default ForgotPassword;
