import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import deleteicon from "../../assets/images/delete.png";
import axios from "axios";
import { toast } from "react-toastify";
const Vertical = ({ selectedEvent }) => {
  const iconRef = useRef(null);
  const popupref = useRef(null);
  const [showIcon, setShowIcon] = useState(false);
  const [popup, setPopup] = useState(false);
  const [selected, setSelected] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });

    function handleClickOutside(event) {
      if (iconRef.current && !iconRef.current.contains(event.target)) {
        setShowIcon(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDeleteEvent = async (id, draft) => {
    const check = draft ? true : false;
    await axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/api/delete/event`, {
        id: id,
        check: check,
      })
      .then((res) => {
        if (res.data.msg === "success") {
          toast.success("Event Deleted Successfully");
          navigate("/dashboard/events");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <div>
      {popup && (
        <div
          className="center zindex1000"
          style={{
            width: "100%",
            height: "100%",

            position: "fixed",
            alignItems: "center",
            top: 0,
            left: 0,
            right: 0,
            zIndex: 11,
            backgroundColor: "rgba(45, 50, 130, 0.30)",
            backdropFilter: "blur(3px)",
            padding: "1rem",
          }}
        >
          <div
            className="shadowa newPopUpContainer"
            ref={popupref}
            style={{
              maxWidth: "90vw",
              maxHeight: "80vh",

              maxWidth: "700px",
              backgroundColor: "",
              padding: "1.5rem",
            }}
          >
            <div className="d-flex " style={{ gap: "1rem" }}>
              <div className="circle-background">
                <img src={deleteicon} alt="delete" height="48px" width="48px" />
              </div>
              <div>
                <p className="gray90018500">Delete Event</p>
                <p className="gray70014400">
                  Are you sure you want to delete this Event?
                  <br /> This action cannot be undone.
                </p>
              </div>
            </div>
            <div
              className="d-flex justify-content-end"
              style={{ marginTop: "2rem", gap: "0.75rem" }}
            >
              <button
                className="backbutton"
                onClick={() => {
                  setPopup(false);
                  setSelected("");
                }}
              >
                Cancel
              </button>
              <button
                className="continuebutton"
                onClick={() => {
                  let id = selected;
                  handleDeleteEvent(selected._id, selected.draft);
                  setPopup(false);
                  setSelected("");
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
      <MoreVertIcon
        className="hoverred"
        style={{ cursor: "pointer" }}
        onClick={(e) => {
          e.stopPropagation();

          setShowIcon(!showIcon);
        }}
      />{" "}
      <div style={{ position: "absolute" }}>
        {showIcon && (
          <div style={{ position: "relative" }}>
            <div
              style={{
                position: "absolute",
                background: "transparent",
                zIndex: 10,

                marginLeft: "-150px",
              }}
              ref={iconRef}
            >
              <div
                className="d-flex shadowa"
                style={{
                  flexDirection: "column",
                  background: "transparent",
                  borderRadius: "5px",
                }}
              >
                <button
                  className="d-flex popuptext"
                  style={{
                    padding: "11px 1rem",
                    maxHeight: "2rem",
                    borderRadius: "5px 5px 0px 0px",
                    gap: "10px",
                    background: "#FFF",
                    border: "none",
                    width: "160px",
                    minHeight: "42px",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowIcon(false);

                    navigate("/edit/event", {
                      state: {
                        challenge: selectedEvent,
                      },
                    });
                  }}
                >
                  Edit
                </button>

                <button
                  className="d-flex popuptext"
                  style={{
                    padding: "11px 1rem",
                    maxHeight: "2rem",
                    borderRadius: "0px 0px 5px 5px",
                    gap: "10px",
                    background: "#FFF",
                    border: "none",
                    width: "160px",
                    minHeight: "42px",
                    borderTop: "1px solid #d9d9d9",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowIcon(false);

                    setSelected(selectedEvent);
                    setPopup(true);
                    // handleDeleteEvent(event._id, event.draft);
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Vertical;
