import { useState, useEffect, useRef } from "react";
import "./step2.css";
import Automotive from "../../assets/images/icons/Automotive.png";
import Brand from "../../assets/images/icons/Brand.png";
import Consulting from "../../assets/images/icons/Consulting.png";
import Economics from "../../assets/images/icons/Economics.png";
import HR from "../../assets/images/icons/HR.png";
import Aviation from "../../assets/images/icons/Aviation.png";
import marketing from "../../assets/images/icons/marketing.png";
import realstate from "../../assets/images/icons/realstate.png";
import FMCG from "../../assets/images/icons/FMCG.png";
import Engineering from "../../assets/images/icons/Engineering.png";
import Computer from "../../assets/images/icons/Computer.png";
import Finance from "../../assets/images/icons/Finance.png";
import Government from "../../assets/images/icons/Government.png";

import Law from "../../assets/images/icons/Law.png";
import Pharma from "../../assets/images/icons/Pharma.png";
import Startup from "../../assets/images/icons/Startup.png";
import Supply from "../../assets/images/icons/Supply.png";
import Technology from "../../assets/images/icons/Technology.png";
import { toast } from "react-toastify";
import SkillsList from "./skillslists";
import InterestItems from "../../components/InterestItems/InterestItems";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
const Step2 = ({
  setSkills,
  skills,
  learns,
  setLearns,
  selectedItem,
  setSelectedItem,
  selectedLanguage,
  setSelectedLanguage,
  defaults,
  setDefaults,

  setStep,
  setSearch,
  search,
}) => {
  const [skill, setSkill] = useState("");
  const [learn, setLearn] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (skill !== "" && !skills.includes(skill)) {
      setSkills([...skills, skill]);
      setSkill("");
    }
  };

  const handleRemove = (ski) => {
    setSkills(skills.filter((skill) => skill !== ski));
  };

  const handlelearnSubmit = (e) => {
    e.preventDefault();
    if (learn !== "" && !learns.includes(learn)) {
      if (learns.length < 12) {
        setLearns([...learns, learn]);
        setLearn("");
      } else {
        toast.error("Only upto 12 skills are allowed");
      }
    }
  };
  const [languageshow, setLanguagesShow] = useState(false);
  const handlelearnSubmitForm = (concept) => {
    if (!learns.includes(concept)) {
      if (learns.length < 12) {
        setLearns([...learns, concept]);
        setLearn("");
      } else {
        toast.error("Only upto 12 skills are allowed");
      }
    } else {
      setLearns(learns.filter((learn) => learn !== concept));
    }
  };

  const handlelearnRemove = (ski) => {
    setLearns(learns.filter((learn) => learn !== ski));
  };

  const handleRemoveLanguage = (lang) => {
    setSelectedLanguage(
      selectedLanguage.filter((lan) => lan.text !== lang.text)
    );
  };

  const items = [
    {
      id: "Brand Management",
      icon: Brand,
      text: "Brand Management",
      select: false,
    },
    { id: "Finance", icon: Finance, text: "Finance", select: false },
    { id: "Consulting", icon: Consulting, text: "Consulting", select: false },
    { id: "Economics", icon: Economics, text: "Economics", select: false },
    { id: "Corporate Law", icon: Law, text: "Corporate Law", select: false },
    {
      id: "HR",
      icon: HR,
      text: "HR",
      select: false,
    },
    {
      id: "Aviation",
      icon: Aviation,
      text: "Aviation",
      select: false,
    },
    { id: "Supply Chain", icon: Supply, text: "Supply Chain", select: false },
    { id: "Pharma", icon: Pharma, text: "Pharma", select: false },
    {
      id: "Technology",
      icon: Technology,
      text: "Technology & AI",
      select: false,
    },
    {
      id: "FMCG",
      icon: FMCG,
      text: "FMCG",
      select: false,
    },
    { id: "Government", icon: Government, text: "Government", select: false },
    { id: "marketing", icon: marketing, text: "Marketing & PR", select: false },
    {
      id: "Real Estate",
      icon: realstate,
      text: "Real Estate",
      select: false,
    },
    { id: "Start-Up", icon: Startup, text: "Start-Up", select: false },
    { id: "Automotive", icon: Automotive, text: "Automotive", select: false },

    {
      id: "Engineering",
      icon: Engineering,
      text: "Engineering",
      select: false,
    },
    {
      id: "Computer Science",
      icon: Computer,
      text: "Computer Science",
      select: false,
    },
  ];

  const [InterestItemsList, setInterestItem] = useState([...items]);

  function selectItem(item, i) {
    let itemId = item.id;
    let updatedItem = [...InterestItemsList];

    if (selectedItem.some((selectedIte) => selectedIte.id === item.id)) {
      updatedItem[i].select = false;
      let newIdList = selectedItem.filter(
        (selectedItem) => selectedItem.id !== itemId
      );
      setSelectedItem(newIdList);
    } else {
      if (selectedItem.length < 3) {
        updatedItem[i].select = true;
        setSelectedItem((prev) => [...prev, item]);
      } else {
        toast.error("You can't select more than 3 items");
      }
    }

    setInterestItem([...updatedItem]);
  }

  const [searchList, setSearchList] = useState([]);

  const handleFormSubmit = () => {
    if (selectedItem.length < 1) {
      toast.error("Please Add At least 1 Student Interests");
      return;
    } else {
      setStep(4);
    }
  };

  const [highlightedIndex, setHighlightedIndex] = useState(0);
  const optionsRef = useRef([]);

  return (
    <div className="" style={{ width: "100%" }}>
      <div className=" ">
        <p
          className="BasicInforMationHeading"
          style={{ fontSize: "24px", fontWeight: "400", color: "#0C111D" }}
        >
          Challenge Categories
        </p>
        <p className="UnderBasicHeading">
          Categorize the challenge to attract the right students.
        </p>
        <p className="thumbnailcreate" style={{ marginTop: "2.5rem" }}>
          Student Interests
        </p>
        <p className="underthumbnailcreate" style={{ marginTop: "0.25rem" }}>
          Categorize challenge based on student interests. Add a maximum of 3
          interest fields.
        </p>
        <div style={{ marginTop: "0.75rem" }}>
          <InterestItems
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            TRUE={true}
          />
        </div>
        {/* <div
          className="tags-container "
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "16px 6px",
           
          }}
        >
          {InterestItemsList.map((item, index) => (
            <div
              className={`interestcont ${
                selectedItem.some((selectedIte) => selectedIte.id === item.id)
                  ? "active"
                  : ""
              }`}
              key={index}
              style={{
                padding: "10px 10px",
                gap: "0.5rem",
                cursor: "pointer",
              }}
              onClick={() => selectItem(item, index)}
            >
              {item.icon && (
                <img src={item.icon} alt="icon" height="20px" width="20px" />
              )}
              <p
                className="interesttext"
                style={{
                  color: selectedItem.some(
                    (selectedIte) => selectedIte.id === item.id
                  )
                    ? "white"
                    : "",
                  fontWeight: "400",
                  lineHeight: "1.125rem",
                }}
              >
                {item.text}
              </p>
            </div>
          ))}
        </div> */}
        {/* <SkillsList
          learns={learns}
          selectedItem={selectedItem}
          handlelearnSubmitForm={handlelearnSubmitForm}
        /> */}
        {/* <div className="skillsstudentwilllearn" style={{ overflow: "hidden" }}>
          <div>
            <div style={{ padding: "1.5rem 1.5rem 0rem 1.5rem" }}>
              <p className="thumbnailcreate ">Skills student will learn</p>
              <p
                className=" underthumbnailcreate"
                style={{ marginTop: "0.25rem" }}
              >
                Select a minimum of 5 and up to 12 skills students will learn,
                apply or expand on. Learn more about challenge skills.
              </p>
              <hr style={{ marginTop: "1.5rem", marginBottom: "0rem" }} />
            </div>
            <div
              className="tablescroll"
              style={{
                height: "311px",
                paddingTop: "1.5rem",
                overflow: "auto",

                marginRight: "0.5rem",

                padding: "1.5rem 0rem 1rem 1.5rem",
              }}
            >
              <p className="thumbnailcreate ">Conceptual Skills</p>
              <div
                className="d-grid "
                style={{
                  marginTop: "1rem",
                  marginBottom: "1rem",
                  gap: "1rem 0rem",
                  gridTemplateColumns: `repeat(auto-fill, minmax(200px, 1fr))`,
                }}
              >
                {conceptual.conceptual.map((concept, index) => (
                  <div
                    className="d-flex conceptskillstext "
                    key={index}
                    style={{
                      alignItems: "center",
                      gap: "0.5rem",
                    }}
                  >
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => handlelearnSubmitForm(concept)}
                    >
                      {learns.includes(concept) ? (
                        <>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z"
                              fill="#E31B54"
                            />
                          </svg>
                        </>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M5.00521 1.66602H15.0052C16.8462 1.66602 18.3385 3.1584 18.3385 4.99935V14.9993C18.3385 16.8403 16.8462 18.3327 15.0052 18.3327H5.00521C3.16426 18.3327 1.67188 16.8403 1.67188 14.9993V4.99935C1.67188 3.1584 3.16426 1.66602 5.00521 1.66602ZM16.4783 16.4725C16.869 16.0818 17.0885 15.5519 17.0885 14.9993V4.99935C17.0885 4.44681 16.869 3.91691 16.4783 3.52621C16.0876 3.13551 15.5577 2.91602 15.0052 2.91602H5.00521C3.85461 2.91602 2.92188 3.84876 2.92188 4.99935V14.9993C2.92188 15.5519 3.14137 16.0818 3.53207 16.4725C3.92277 16.8632 4.45267 17.0827 5.00521 17.0827H15.0052C15.5577 17.0827 16.0876 16.8632 16.4783 16.4725Z"
                            fill="#344054"
                          />
                        </svg>
                      )}
                    </div>
                    {concept}
                  </div>
                ))}
              </div>
              <hr style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }} />
              <p className="thumbnailcreate ">Data-Related Skills</p>
              <div
                className="d-grid "
                style={{
                  marginTop: "1rem",
                  marginBottom: "1rem",
                  gap: "1rem 0rem",
                  gridTemplateColumns: `repeat(auto-fill, minmax(200px, 1fr))`,
                }}
              >
                {conceptual.data.map((concept, index) => (
                  <div
                    className="d-flex conceptskillstext align-items-center"
                    key={index}
                    style={{
                      gap: "0.5rem",
                    }}
                  >
                    <div
                      style={{
                        width: "16px",
                        height: "16px",
                        border: "1px solid black",
                      }}
                      className="rounded-1"
                    ></div>
                    {concept}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div> */}
        {/* <p className=" thumbnailcreate" style={{ marginTop: "2.5rem" }}>
          Required Skills(Optional)
        </p>
        <p className="underthumbnailcreate " style={{ marginTop: "0.25rem" }}>
          Skills students should have to excel in the challenge.
        </p>
        <form onSubmit={(e) => handleSubmit(e)}>
          <input
            value={skill}
            onChange={(e) => setSkill(e.target.value)}
            className="forminput col-md-12 col-12"
            style={{ marginTop: "0.75rem" }}
            placeholder="e.g, DFC Modelling, Python, SWOT Analysis"
          />
        </form>
        <div className="d-flex flex-wrap" style={{ gap: "20px" }}>
          {skills &&
            skills.map((ski, i) => (
              <div
                key={i}
                className="mt-2  skillsshow d-flex"
                style={{ cursor: "pointer" }}
              >
                <p className="skillsshowtext"> {ski}</p>
                <div onClick={() => handleRemove(ski)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M9.87484 9.00604L15.5715 3.32867C15.8095 3.08843 15.8095 2.69925 15.5715 2.45901C15.3377 2.21448 14.9516 2.20714 14.7089 2.4426L9.01221 8.11997L3.38879 2.4426C3.2735 2.31966 3.11309 2.25 2.94527 2.25C2.77745 2.25 2.61703 2.31966 2.50174 2.4426C2.29073 2.67471 2.29073 3.03093 2.50174 3.26303L8.12516 8.9322L2.4285 14.6014C2.1905 14.8416 2.1905 15.2308 2.4285 15.471C2.54181 15.588 2.69763 15.6532 2.85982 15.6515C3.02513 15.665 3.18901 15.6119 3.31555 15.5038L9.01221 9.82647L14.7089 15.5695C14.8222 15.6865 14.978 15.7517 15.1402 15.75C15.3022 15.7507 15.4577 15.6857 15.5715 15.5695C15.8095 15.3292 15.8095 14.9401 15.5715 14.6998L9.87484 9.00604Z"
                      fill="#0C111D"
                    />
                  </svg>
                </div>
              </div>
            ))}
        </div> */}
        {/* <p className="thumbnailcreate " style={{ marginTop: "1.5rem" }}>
          b) Custom Skills
        </p>
        <p className=" underthumbnailcreate" style={{ marginTop: "" }}>
          Can’t find the right skills from the list? Here you can add additional
          skills.
        </p>
        <form onSubmit={(e) => handlelearnSubmit(e)}>
          <div
            className="forminput col-md-12 col-12 mt-2 space align-items-center"
            style={{ gap: "0.5rem" }}
          >
            <div className="col-md-8 col-8">
              <input
                value={learn}
                className="gray14"
                onChange={(e) => setLearn(e.target.value)}
                style={{ border: "none", width: "100%", outline: "none" }}
                placeholder="e.g, Data Presentation, Communication Skills,  etc."
              />
            </div>
            <div
              className="d-flex"
              style={{ gap: "0.5rem", alignItems: "center" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M12.3 3.9834H5.69164C5.34646 3.9834 5.06664 4.26322 5.06664 4.6084C5.06664 4.95358 5.34646 5.2334 5.69164 5.2334H12.2583C13.9324 5.2788 15.2605 6.65881 15.2416 8.3334V10.1417C15.2416 11.8446 13.8612 13.2251 12.1583 13.2251H6.24997L7.62497 11.8334C7.7441 11.7173 7.81128 11.5581 7.81128 11.3917C7.81128 11.2254 7.7441 11.0661 7.62497 10.9501C7.38096 10.7064 6.98565 10.7064 6.74164 10.9501L4.29997 13.3917C4.24348 13.4495 4.19826 13.5174 4.16664 13.5917C4.10852 13.7476 4.10852 13.9192 4.16664 14.0751C4.19826 14.1494 4.24348 14.2173 4.29997 14.2751L6.74164 16.7167C6.85821 16.8348 7.01742 16.9008 7.18331 16.9001C7.34938 16.9018 7.50895 16.8356 7.62497 16.7167C7.7441 16.6007 7.81128 16.4414 7.81128 16.2751C7.81128 16.1087 7.7441 15.9495 7.62497 15.8334L6.24997 14.4584H12.1583C14.5515 14.4584 16.4916 12.5183 16.4916 10.1251V8.3334C16.5152 5.98264 14.65 4.04694 12.3 3.9834Z"
                  fill="#475467"
                />
              </svg>
              <p className="gray14">Press Enter</p>
            </div>
          </div>
        </form>
        <div className="d-flex flex-wrap" style={{ gap: "1rem" }}>
          {learns &&
            learns.map((ski, i) => (
              <div
                key={i}
                className=" skillsshow d-flex hoverdarkblacksvg"
                style={{ cursor: "pointer", marginTop: "0.75rem" }}
              >
                <p className="skillsshowtext"> {ski}</p>
                <div
                  onClick={() => handlelearnRemove(ski)}
                  className="hoverdarkblacksvgdiv"
                >
                  <CloseOutlinedIcon className="" />
                </div>
              </div>
            ))}
        </div> */}

        {/* <select
          className="forminput col-md-12"
          style={{ marginTop: "0.75rem" }}
          onChange={(e) => {
            handleSetLanguages(e.target.value);
            setSearch("");
          }}
        >
          <option className="formtext" disabled>
            Select Language
          </option>
          {searchList.map((option, i) => (
            <option className="formtext" key={i} value={option.text}>
              {option.icon && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  style={{ marginRight: "5px" }}
                >
                  {option.icon}
                </svg>
              )}
              {option.text}
            </option>
          ))}
        </select> */}

        {/* <div
          className="tags-container "
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "5px",
            marginTop: "0.75rem",
          }}
        >
          <input
            className={`col-md-12 forminput  `}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search Languages"
          />

          {search && (
            <div
              className="countrylist scrollbaredit col-md-12"
              style={{ maxHeight: "30vh", overflow: "auto", cursor: "pointer" }}
            >
              {searchList.length !== 0
                ? searchList.map((sea, i) => (
                    <div
                      className="d-flex  mb-3"
                      key={i}
                      onClick={() => {
                        handleSetLanguages(sea);
                        setSearch("");
                      }}
                    >
                      <div>{sea.icon}</div>
                      <p> {sea.text}</p>
                    </div>
                  ))
                : "None"}
            </div>
          )}

          <div className="flex-wrap d-flex mt-3" style={{ gap: "10px" }}>
            {selectedLanguage.length > 0 &&
              selectedLanguage.map((lang, index) => (
                <div className={`tags `} key={index}>
                  {lang.icon}
                  <p className="languagestext">{lang.text}</p>
                  <div onClick={() => handleRemoveLanguage(lang)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4.00065 1.33301H12.0007C13.4734 1.33301 14.6673 2.52692 14.6673 3.99967V11.9997C14.6673 13.4724 13.4734 14.6663 12.0007 14.6663H4.00065C2.52789 14.6663 1.33398 13.4724 1.33398 11.9997V3.99967C1.33398 2.52692 2.52789 1.33301 4.00065 1.33301ZM13.1792 13.1782C13.4917 12.8656 13.6673 12.4417 13.6673 11.9997V3.99967C13.6673 3.55765 13.4917 3.13372 13.1792 2.82116C12.8666 2.5086 12.4427 2.33301 12.0007 2.33301H4.00065C3.08018 2.33301 2.33398 3.0792 2.33398 3.99967V11.9997C2.33398 12.4417 2.50958 12.8656 2.82214 13.1782C3.1347 13.4907 3.55862 13.6663 4.00065 13.6663H12.0007C12.4427 13.6663 12.8666 13.4907 13.1792 13.1782Z"
                        fill="#667085"
                      />
                      <path
                        d="M10.4607 5.81967C10.2654 5.62471 9.9492 5.62471 9.75398 5.81967L8.14065 7.43301L6.52732 5.81967C6.33031 5.6361 6.02331 5.64152 5.8329 5.83193C5.6425 6.02234 5.63708 6.32933 5.82065 6.52634L7.43398 8.13967L5.82065 9.75301C5.62568 9.94822 5.62568 10.2645 5.82065 10.4597C6.01586 10.6546 6.33211 10.6546 6.52732 10.4597L8.14065 8.84634L9.75398 10.4597C9.84766 10.5535 9.97475 10.6062 10.1073 10.6063C10.2393 10.6035 10.3654 10.5511 10.4607 10.4597C10.6556 10.2645 10.6556 9.94822 10.4607 9.75301L8.84732 8.13967L10.4607 6.52634C10.6556 6.33113 10.6556 6.01489 10.4607 5.81967Z"
                        fill="#667085"
                      />
                    </svg>
                  </div>
                </div>
              ))}
          </div> */}
        {/* {languages.map((item, index) => (
          <div
            className={`createchallengetags ${
              selectedLanguage.some(
                (selectedIte) => selectedIte.text === item.text
              )
                ? "selected"
                : ""
            }`}
            key={index}
            onClick={() => selectlanguage(item, index)}
            style={{ cursor: "pointer" }}
          >
            {item.icon && item.icon}
            <p
              className="createchallengetagstext"
              style={
                selectedLanguage.some(
                  (selectedIte) => selectedIte.text === item.text
                )
                  ? { color: "white" }
                  : {}
              }
            >
              {item.text}
            </p>
          </div>
        ))} */}
        {/* </div> */}
        {/* <div
        className=" d-flex mt-5"
        style={{ marginRight: "10px", gap: "10px" }}
      >
        <div
          className="checkbox rounded-1 border border-1"
          style={
            defaults
              ? { cursor: "pointer", backgroundColor: "#E31B54" }
              : { cursor: "pointer", backgroundColor: "#FFF" }
          }
          onClick={() => setDefaults(!defaults)}
        ></div>
        <p className="thumbnailcreate">
          Set this as default values for future job posts.
        </p>
      </div> */}
        {/* <hr style={{ borderColor: "#D9D9D9", borderWidth: "1px" }} /> */}
        <hr
          style={{
            marginTop: "5.063rem",
            marginBottom: "1.25rem",
            zIndex: 0,
          }}
        />
        <div
          className="d-flex  "
          style={{ justifyContent: "space-between", gap: "10px" }}
        >
          <button className="backbutton" onClick={() => setStep(2)}>
            Back
          </button>
          <button className="continuebutton" onClick={() => handleFormSubmit()}>
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};
export default Step2;
