import ReactQuill from "react-quill";
import on from "../../assets/images/on.svg";
import off from "../../assets/images/off.svg";
import upload from "../../assets/images/icons/upload.png";
import React, { useState } from "react";
const TaskStep2 = ({
  taskCount,
  newTasks,
  setNewTasks,
  setTaskSteps,
  toast,
}) => {
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
      [{ align: [] }],
      ["clean"],
    ],
  };

  const handleRemoveDocument = (removedFile) => {
    setNewTasks((prevTasks) => {
      return prevTasks.map((task, index) => {
        if (index === taskCount && task.caseDocuments) {
          const updatedCaseDocuments = task.caseDocuments.filter(
            (file) => file !== removedFile
          );
          return { ...task, caseDocuments: updatedCaseDocuments };
        }
        return task;
      });
    });
  };

  const getFileIcon = (fileName) => {
    if (fileName && fileName.split(".")) {
      const fileExtension = fileName.split(".").pop().toLowerCase();
      const fileIcons = {
        pdf: "#155EEF",
        docx: "#155EEF",
        txt: "#344054",
        xlsl: "#099250",
        ppt: "#E62E05",
        csv: "#099250",
      };

      const color = fileIcons[fileExtension] || "#E62E05";

      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="33"
          height="40"
          viewBox="0 0 33 40"
          fill="none"
        >
          <path
            d="M0 4C0 1.79086 1.79086 0 4 0H20L32 12V36C32 38.2091 30.2091 40 28 40H4C1.79086 40 0 38.2091 0 36V4Z"
            fill={color}
          />
          <path
            opacity="0.3"
            d="M20 0L32 12H24C21.7909 12 20 10.2091 20 8V0Z"
            fill="white"
          />
          <text
            x="50%"
            y="70%"
            dominantBaseline="middle"
            textAnchor="middle"
            fill="#fff"
            fontSize="8"
          >
            {fileExtension.toUpperCase()}
          </text>
        </svg>
      );
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const selectedFiles = event.target.files;

      if (selectedFiles && selectedFiles.length > 0) {
        setNewTasks((prevTasks) => {
          return prevTasks.map((task, index) => {
            if (index === taskCount) {
              const updatedCaseDocuments = task.caseDocuments
                ? [...task.caseDocuments]
                : []; // Make a copy of existing documents
              updatedCaseDocuments.push(...Array.from(selectedFiles)); // Add new selected files
              return { ...task, caseDocuments: updatedCaseDocuments }; // Return updated task object
            }
            return task;
          });
        });
      }
    } else {
      toast.error("Please select a valid file.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "link",
    "image",
    "align",
  ];

  const fileInputRef = React.createRef();

  const fileInputRefAdditional = React.createRef();

  const openFileInput = () => {
    fileInputRef.current.click();
  };

  const openFileInputAdditional = () => {
    fileInputRefAdditional.current.click();
  };

  const Check = () => {
    if (
      !newTasks[taskCount].task ||
      newTasks[taskCount].task.replace(/(<([^>]+)>)/gi, "").length === 0
    ) {
      toast.error("Please provide Task information");
      return false;
    } else if (
      newTasks[taskCount].settings.keyterms &&
      (!newTasks[taskCount].terms ||
        newTasks[taskCount].terms.replace(/(<([^>]+)>)/gi, "").length === 0)
    ) {
      toast.error("Please provide key terms, if not then disable it please");
    } else if (
      newTasks[taskCount].settings.externalLinks &&
      (!newTasks[taskCount].external ||
        newTasks[taskCount].external.length === 0)
    ) {
      toast.error(
        "Please provide at least one External link, if not then disable it please"
      );
    } else if (
      newTasks[taskCount].settings.files &&
      newTasks[taskCount].caseDocuments.length === 0
    ) {
      toast.error(
        "Please provide at least one file, if not then disable it please"
      );
    } else {
      setTaskSteps(3);
      // console.log(newTasks);
    }
  };
  const [links, setLinks] = useState("");
  const handleLinks = (e) => {
    e.preventDefault();
    const link = links.trim();

    if (link !== "" && !newTasks[taskCount].external.includes(link)) {
      if (newTasks[taskCount].external.length < 12) {
        setNewTasks((prevTasks) => {
          const updatedTasks = [...prevTasks];
          const updatedExternal = [...updatedTasks[taskCount].external, link];
          updatedTasks[taskCount] = {
            ...updatedTasks[taskCount],
            external: updatedExternal,
          };
          return updatedTasks;
        });
        setLinks("");
      } else {
        toast.error("Only up to 12 links are allowed");
      }
    }
  };

  const handleLinkRemove = (ski) => {
    setNewTasks((prevTasks) => {
      return prevTasks.map((task, index) => {
        if (index === taskCount) {
          const updatedExternal = task.external.filter((link) => link !== ski); // Filter out the link to be removed
          return { ...task, external: updatedExternal }; // Update the task object with the filtered external array
        }
        return task;
      });
    });
  };

  const handleChangeKeyTerms = () => {
    setNewTasks((prevTasks) => {
      const updatedTasks = [...prevTasks];
      const updatedTask = {
        ...updatedTasks[taskCount],
        settings: {
          ...updatedTasks[taskCount].settings,
          keyterms: !updatedTasks[taskCount].settings.keyterms,
        },
      };
      updatedTasks[taskCount] = updatedTask;
      return updatedTasks;
    });
  };

  const handleChangeFiles = () => {
    setNewTasks((prevTasks) => {
      const updatedTasks = [...prevTasks];
      const updatedTask = {
        ...updatedTasks[taskCount],
        settings: {
          ...updatedTasks[taskCount].settings,
          files: !updatedTasks[taskCount].settings.files,
        },
      };
      updatedTasks[taskCount] = updatedTask;
      return updatedTasks;
    });
  };

  const handleChangeExternalLinks = () => {
    setNewTasks((prevTasks) => {
      const updatedTasks = [...prevTasks];
      const updatedTask = {
        ...updatedTasks[taskCount],
        settings: {
          ...updatedTasks[taskCount].settings,
          externalLinks: !updatedTasks[taskCount].settings.externalLinks,
        },
      };
      updatedTasks[taskCount] = updatedTask;
      return updatedTasks;
    });
  };

  return (
    <div className="pb-5" style={{ width: "100%" }}>
      <div className="">
        <p className="BasicInforMationHeading ">Instructions</p>
        <p className="UnderBasicHeading" style={{ marginTop: "0.25rem" }}>
          Give students basic informations about this task
        </p>
        <div style={{ marginTop: "2.5rem" }}>
          <p className="thumbnailcreate">Your Task</p>
          <p className="underthumbnailcreate" style={{ marginTop: "0.25rem" }}>
            What should the student do?
          </p>
          <ReactQuill
            value={newTasks[taskCount].task}
            onChange={(value) => {
              const updatedTasks = [...newTasks];
              updatedTasks[taskCount] = {
                ...updatedTasks[taskCount],
                task: value,
              };
              setNewTasks(updatedTasks);
            }}
            modules={modules}
            formats={formats}
            className="col-md-12  reactquillfontfix whitescrollbar"
            style={{
              backgroundColor: "#FFF",
              marginTop: "0.75rem",
            }}
          />
          <p className="characterslimittext" style={{ marginTop: "0.5rem" }}>
            {" "}
            {newTasks[taskCount].task &&
              newTasks[taskCount].task.replace(/(<([^>]+)>)/gi, "").length}{" "}
            Characters
          </p>
          <div
            className="space flex-wrap"
            style={{ marginTop: "2.5rem", gap: "1rem" }}
          >
            <div style={{ maxWidth: "280px" }}>
              <p className="thumbnailcreate">Add Additional Information</p>
              <p
                className="underthumbnailcreate"
                style={{ marginTop: "0.25rem" }}
              >
                Would you like to add additional information to this task such
                as key terms, files or external links?
              </p>
            </div>
            <div className="d-flex flex-column" style={{ gap: "1rem" }}>
              <div
                className="d-flex"
                style={{ gap: "1rem", cursor: "pointer" }}
                onClick={() => {
                  handleChangeKeyTerms();
                }}
              >
                <img
                  src={newTasks[taskCount].settings.keyterms ? on : off}
                  alt="switch"
                  width="36px"
                  height="20px"
                />
                <p className="underthumbnailcreate">Key Terms</p>
              </div>
              <div
                className="d-flex"
                style={{ gap: "1rem", cursor: "pointer" }}
                onClick={() => {
                  handleChangeFiles();
                }}
              >
                <img
                  src={newTasks[taskCount].settings.files ? on : off}
                  alt="switch"
                  width="36px"
                  height="20px"
                />
                <p className="underthumbnailcreate">Files</p>
              </div>
              <div
                className="d-flex"
                style={{ gap: "1rem", cursor: "pointer" }}
                onClick={() => {
                  handleChangeExternalLinks();
                }}
              >
                <img
                  src={newTasks[taskCount].settings.externalLinks ? on : off}
                  alt="switch"
                  width="36px"
                  height="20px"
                />
                <p className="underthumbnailcreate">External Links</p>
              </div>
            </div>
          </div>
          {newTasks[taskCount].settings.keyterms && (
            <>
              {" "}
              <p className="thumbnailcreate" style={{ marginTop: "2.5rem" }}>
                Key Terms (Optional)
              </p>
              <p
                className="underthumbnailcreate"
                style={{ marginTop: "0.25rem" }}
              >
                Students might not be familiar with all terms or frameworks used
                in this task. Give them some additional information.
              </p>
              <ReactQuill
                value={newTasks[taskCount].terms}
                onChange={(value) => {
                  const updatedTasks = [...newTasks];
                  updatedTasks[taskCount] = {
                    ...updatedTasks[taskCount],
                    terms: value,
                  };
                  setNewTasks(updatedTasks);
                }}
                modules={modules}
                formats={formats}
                className="col-md-12  reactquillfontfix whitescrollbar"
                style={{
                  backgroundColor: "#FFF",
                  marginTop: "0.75rem",
                }}
              />
              <p
                className="characterslimittext"
                style={{ marginTop: "0.5rem" }}
              >
                {" "}
                {newTasks[taskCount].terms &&
                  newTasks[taskCount].terms.replace(/(<([^>]+)>)/gi, "")
                    .length}{" "}
                Characters
              </p>
            </>
          )}

          {newTasks[taskCount].settings.files && (
            <>
              <p className="thumbnailcreate" style={{ marginTop: "2.5rem" }}>
                File Upload
              </p>
              <div
                className=" border rounded-3"
                style={{
                  backgroundColor: "#FFF",
                  justifyContent: "center",
                  marginTop: "0.75rem",
                  padding: "1rem",
                }}
              >
                <div className="d-flex" style={{ justifyContent: "center" }}>
                  {newTasks[taskCount].caseDocuments ? (
                    <div>
                      <div className="center">
                        <img
                          src={upload}
                          alt="upload"
                          width="40px"
                          height="40px"
                        />
                      </div>
                      <p
                        style={{
                          color: "#E31B54",
                          fontFamily: "Poppins",
                          fontWeight: "600",
                          fontSize: "14px",
                          cursor: "pointer",
                        }}
                      >
                        {newTasks[taskCount].caseDocuments.length}{" "}
                        {newTasks[taskCount].caseDocuments.length > 1
                          ? "Files Selected"
                          : "File Selected"}
                      </p>
                    </div>
                  ) : (
                    <div
                      className="mt-2 p-3 d-flex"
                      style={{
                        backgroundColor: "#FFF5F6",
                        borderRadius: "60px",
                        height: "60px",
                        width: "60px",
                        justifyContent: "center",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="24"
                        viewBox="0 0 20 24"
                        fill="none"
                      >
                        <path
                          d="M0.399902 4C0.399902 1.79086 2.19076 0 4.3999 0H12.3999L19.5999 7.2V20C19.5999 22.2091 17.809 24 15.5999 24H4.3999C2.19076 24 0.399902 22.2091 0.399902 20V4Z"
                          fill="#E31B54"
                        />
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          height="11"
                          viewBox="0 0 10 11"
                          fill="none"
                          x="5"
                          y="6"
                        >
                          <g clipPath="url(#clip0_4343_10944)">
                            <path
                              d="M5.4 3.3998L4.95378 2.50737C4.82536 2.25053 4.76115 2.12210 4.66535 2.02827C4.58063 1.94530 4.47853 1.88219 4.36643 1.84353C4.23967 1.7998 4.09608 1.7998 3.80892 1.7998H2.28C1.83196 1.7998 1.60794 1.7998 1.43681 1.887C1.28628 1.9637 1.16389 2.08608 1.08719 2.23661C1 2.40774 1 2.63176 1 3.0798V3.3998M1 3.3998H7.08C7.75206 3.3998 8.08809 3.3998 8.34479 3.5306C8.57058 3.64565 8.75416 3.82922 8.86921 4.05502C9 4.31171 9 4.64774 9 5.31981V7.07981C9 7.75187 9 8.0879 8.86921 8.34459C8.75416 8.57039 8.57058 8.75397 8.34479 8.86901C8.08809 8.99981 7.75206 8.99981 7.08 8.99981H2.92C2.24794 8.99981 1.91191 8.99981 1.65521 8.86901C1.42942 8.75397 1.24584 8.57039 1.13079 8.34459C1 8.0879 1 7.75187 1 7.07981V3.3998Z"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_4343_10944">
                              <rect
                                width="9.6"
                                height="9.6"
                                fill="white"
                                transform="translate(0.200195 0.599609)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </svg>
                    </div>
                  )}
                </div>
                {!newTasks[taskCount].caseDocuments && (
                  <div
                    className="d-flex mt-2 "
                    style={{ justifyContent: "center" }}
                  >
                    <p className="addthumbnail">Add Reletive Documents</p>
                  </div>
                )}
                <div
                  className="d-flex mt-3 mb-3"
                  style={{ justifyContent: "center" }}
                >
                  {" "}
                  <p
                    className="d-flex  pink16"
                    style={{ gap: "10px", cursor: "pointer" }}
                    onClick={openFileInput}
                  >
                    click to upload
                  </p>
                  <input
                    type="file"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    ref={fileInputRef}
                    multiple
                  />
                </div>
              </div>
              <div
                className="d-flex flex-wrap"
                style={{ gap: "1.5rem 1rem", marginTop: "0.5rem" }}
              >
                {newTasks[taskCount].caseDocuments &&
                  newTasks[taskCount].caseDocuments.length > 0 && (
                    <>
                      {Array.from(newTasks[taskCount].caseDocuments).map(
                        (file, index) => (
                          <div
                            key={index}
                            className="d-flex rounded-3 "
                            style={{ background: "#FFF", padding: "6px 12px" }}
                          >
                            <div className="">
                              {file?.originalname
                                ? getFileIcon(file.originalname)
                                : getFileIcon(file.name)}
                            </div>{" "}
                            <p
                              style={{
                                color: "#E31B54",
                                fontFamily: "Poppins",
                                fontWeight: "600",
                                fontSize: "14px",
                                cursor: "pointer",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                              className="  m-2 d-flex"
                            >
                              {file?.originalname
                                ? file.originalname.length > 20
                                  ? file.originalname.slice(0, 20) + "..."
                                  : file.originalname
                                : file.name.length > 20
                                ? file.name.slice(0, 20) + "..."
                                : file.name}
                            </p>
                            <div
                              className=" d-flex"
                              style={{
                                cursor: "pointer",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                              onClick={() => {
                                handleRemoveDocument(file);
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="17"
                                height="17"
                                viewBox="0 0 17 17"
                                fill="none"
                              >
                                <path
                                  d="M9.30108 8.28369L14.3648 3.23713C14.5763 3.02359 14.5763 2.67765 14.3648 2.46411C14.157 2.24675 13.8138 2.24022 13.598 2.44952L8.53429 7.49607L3.5357 2.44952C3.43322 2.34024 3.29063 2.27832 3.14145 2.27832C2.99228 2.27832 2.84969 2.34024 2.74721 2.44952C2.55964 2.65584 2.55964 2.97248 2.74721 3.17879L7.7458 8.21805L2.6821 13.2573C2.47055 13.4709 2.47055 13.8168 2.6821 14.0303C2.78282 14.1343 2.92133 14.1923 3.0655 14.1908C3.21244 14.2028 3.35811 14.1556 3.47059 14.0595L8.53429 9.01296L13.598 14.1179C13.6987 14.2219 13.8372 14.2798 13.9814 14.2783C14.1254 14.279 14.2636 14.2211 14.3648 14.1179C14.5763 13.9043 14.5763 13.5584 14.3648 13.3448L9.30108 8.28369Z"
                                  fill="black"
                                />
                              </svg>
                            </div>
                          </div>
                        )
                      )}
                    </>
                  )}
              </div>
            </>
          )}
          {newTasks[taskCount].settings.externalLinks && (
            <>
              <p className="thumbnailcreate" style={{ marginTop: "2.5rem" }}>
                External Links
              </p>
              <p
                className="underthumbnailcreate"
                style={{ marginTop: "0.25rem" }}
              >
                After typing press enter to add as a link
              </p>
              <form onSubmit={(e) => handleLinks(e)}>
                <div
                  className="forminput col-md-12 col-12 mt-2 space align-items-center"
                  style={{ gap: "0.5rem" }}
                >
                  <div className="col-md-9 col-9">
                    <input
                      value={links}
                      style={{ border: "none", width: "100%" }}
                      className="gray14 "
                      onChange={(e) => setLinks(e.target.value)}
                    />
                  </div>
                  <div
                    className="d-flex"
                    style={{ gap: "0.5rem", alignItems: "center" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M12.3 3.9834H5.69164C5.34646 3.9834 5.06664 4.26322 5.06664 4.6084C5.06664 4.95358 5.34646 5.2334 5.69164 5.2334H12.2583C13.9324 5.2788 15.2605 6.65881 15.2416 8.3334V10.1417C15.2416 11.8446 13.8612 13.2251 12.1583 13.2251H6.24997L7.62497 11.8334C7.7441 11.7173 7.81128 11.5581 7.81128 11.3917C7.81128 11.2254 7.7441 11.0661 7.62497 10.9501C7.38096 10.7064 6.98565 10.7064 6.74164 10.9501L4.29997 13.3917C4.24348 13.4495 4.19826 13.5174 4.16664 13.5917C4.10852 13.7476 4.10852 13.9192 4.16664 14.0751C4.19826 14.1494 4.24348 14.2173 4.29997 14.2751L6.74164 16.7167C6.85821 16.8348 7.01742 16.9008 7.18331 16.9001C7.34938 16.9018 7.50895 16.8356 7.62497 16.7167C7.7441 16.6007 7.81128 16.4414 7.81128 16.2751C7.81128 16.1087 7.7441 15.9495 7.62497 15.8334L6.24997 14.4584H12.1583C14.5515 14.4584 16.4916 12.5183 16.4916 10.1251V8.3334C16.5152 5.98264 14.65 4.04694 12.3 3.9834Z"
                        fill="#475467"
                      />
                    </svg>
                    <p className="gray14">Press Enter</p>
                  </div>
                </div>
              </form>
              <div className="d-flex flex-wrap" style={{ gap: "1.5rem 1rem" }}>
                {newTasks[taskCount].external.length > 0 &&
                  newTasks[taskCount].external.map((ski, i) => (
                    <div
                      key={i}
                      className="mt-2  skillsshow d-flex"
                      style={{ cursor: "pointer" }}
                    >
                      <p className="skillsshowtext"> {ski}</p>
                      <div onClick={() => handleLinkRemove(ski)}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                        >
                          <path
                            d="M9.87484 9.00604L15.5715 3.32867C15.8095 3.08843 15.8095 2.69925 15.5715 2.45901C15.3377 2.21448 14.9516 2.20714 14.7089 2.4426L9.01221 8.11997L3.38879 2.4426C3.2735 2.31966 3.11309 2.25 2.94527 2.25C2.77745 2.25 2.61703 2.31966 2.50174 2.4426C2.29073 2.67471 2.29073 3.03093 2.50174 3.26303L8.12516 8.9322L2.4285 14.6014C2.1905 14.8416 2.1905 15.2308 2.4285 15.471C2.54181 15.588 2.69763 15.6532 2.85982 15.6515C3.02513 15.665 3.18901 15.6119 3.31555 15.5038L9.01221 9.82647L14.7089 15.5695C14.8222 15.6865 14.978 15.7517 15.1402 15.75C15.3022 15.7507 15.4577 15.6857 15.5715 15.5695C15.8095 15.3292 15.8095 14.9401 15.5715 14.6998L9.87484 9.00604Z"
                            fill="#0C111D"
                          />
                        </svg>
                      </div>
                    </div>
                  ))}
              </div>
            </>
          )}
          <hr style={{ marginTop: "2.5rem", marginBottom: "2.5rem" }} />

          <div
            className="d-flex flex-wrap"
            style={{ justifyContent: "end", gap: "1.25rem" }}
          >
            <button className="backbutton" onClick={() => setTaskSteps(1)}>
              Back
            </button>
            <button className="continuebutton" onClick={() => Check()}>
              Continue
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M6.66669 17.2917C6.83257 17.2925 6.99178 17.2264 7.10835 17.1083L13.775 10.4417C14.0187 10.1977 14.0187 9.80235 13.775 9.55833L7.10835 2.89167C6.8621 2.6622 6.47835 2.66897 6.24034 2.90698C6.00233 3.14499 5.99555 3.52874 6.22502 3.775L12.45 10L6.22502 16.225C5.98131 16.469 5.98131 16.8643 6.22502 17.1083C6.34159 17.2264 6.5008 17.2925 6.66669 17.2917Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TaskStep2;
