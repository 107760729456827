// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chattop__wrapper {
  display: flex;
  padding: 12px 30px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid #eaecf0;
  background: #fff;
}
.chattop__button__wrapper {
  display: flex;
  width: 102px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  border: 1px solid var(--Gray-300, #d0d5dd);
}
.chatbottom__wrapper {
  display: flex;
  padding: 24px 30px;
  flex-direction: column;
  justify-content: center;

  gap: 10px;
  flex: 1 0;
  align-self: stretch;
  background: #fff;
  border-top: 1px solid #eaecf0;
}
.chatbottom__wrapper .ql-editor {
  max-height: 103px;
  min-height: 103px;
  border-radius: 0px 0px 5px 5px;
}
.chatbottom__wrapper .ql-toolbar.ql-snow {
  border-radius: 5px 5px 0px 0px;
}
.chatbottom__wrapper .ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-radius: 0px 0px 5px 5px;
}
.listItem__chat {
  display: flex;
  padding: 12px 16px;

  border-bottom: 1px solid #eaecf0;
  background: var(--Base-White, #fff);
  cursor: pointer;
}
.listItem__chat:hover {
  border-bottom: 1px solid #eaecf0;
  background: var(--Base-Background-Grey, #f2f3f7);
}
`, "",{"version":3,"sources":["webpack://./src/pages/CompanyChat/main.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,8BAA8B;EAC9B,mBAAmB;EACnB,mBAAmB;EACnB,gCAAgC;EAChC,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,YAAY;EACZ,iBAAiB;EACjB,uBAAuB;EACvB,mBAAmB;EACnB,QAAQ;EACR,kBAAkB;EAClB,0CAA0C;AAC5C;AACA;EACE,aAAa;EACb,kBAAkB;EAClB,sBAAsB;EACtB,uBAAuB;;EAEvB,SAAS;EACT,SAAW;EACX,mBAAmB;EACnB,gBAAgB;EAChB,6BAA6B;AAC/B;AACA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,8BAA8B;AAChC;AACA;EACE,8BAA8B;AAChC;AACA;EACE,8BAA8B;AAChC;AACA;EACE,aAAa;EACb,kBAAkB;;EAElB,gCAAgC;EAChC,mCAAmC;EACnC,eAAe;AACjB;AACA;EACE,gCAAgC;EAChC,gDAAgD;AAClD","sourcesContent":[".chattop__wrapper {\n  display: flex;\n  padding: 12px 30px;\n  justify-content: space-between;\n  align-items: center;\n  align-self: stretch;\n  border-bottom: 1px solid #eaecf0;\n  background: #fff;\n}\n.chattop__button__wrapper {\n  display: flex;\n  width: 102px;\n  padding: 8px 12px;\n  justify-content: center;\n  align-items: center;\n  gap: 8px;\n  border-radius: 6px;\n  border: 1px solid var(--Gray-300, #d0d5dd);\n}\n.chatbottom__wrapper {\n  display: flex;\n  padding: 24px 30px;\n  flex-direction: column;\n  justify-content: center;\n\n  gap: 10px;\n  flex: 1 0 0;\n  align-self: stretch;\n  background: #fff;\n  border-top: 1px solid #eaecf0;\n}\n.chatbottom__wrapper .ql-editor {\n  max-height: 103px;\n  min-height: 103px;\n  border-radius: 0px 0px 5px 5px;\n}\n.chatbottom__wrapper .ql-toolbar.ql-snow {\n  border-radius: 5px 5px 0px 0px;\n}\n.chatbottom__wrapper .ql-toolbar.ql-snow + .ql-container.ql-snow {\n  border-radius: 0px 0px 5px 5px;\n}\n.listItem__chat {\n  display: flex;\n  padding: 12px 16px;\n\n  border-bottom: 1px solid #eaecf0;\n  background: var(--Base-White, #fff);\n  cursor: pointer;\n}\n.listItem__chat:hover {\n  border-bottom: 1px solid #eaecf0;\n  background: var(--Base-Background-Grey, #f2f3f7);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
