import React, { useEffect, useRef, useState } from "react";
import "./header.css";
import logo from "../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import deleteicon from "../../assets/images/unsaved.png";
const Header = ({
  text,
  handleDraftSave,
  taskHeader,
  taskCount,
  taskSteps,
  edit,
}) => {
  const navigate = useNavigate();
  const isNonMob1130 = useMediaQuery("(min-width:1130px)");
  const isNonMobile450 = useMediaQuery("(min-width:450px)");

  const popupref = useRef(null);
  const [popup, setPopup] = useState(false);

  useEffect(() => {
    function handleClickOutside(event) {
      if (popupref.current && !popupref.current.contains(event.target)) {
        setPopup(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      {popup && (
        <div
          className="center zindex1000"
          style={{
            width: "100%",
            height: "100%",

            position: "fixed",
            alignItems: "center",
            top: 0,
            left: 0,
            right: 0,
            zIndex: 11,
            backgroundColor: "rgba(45, 50, 130, 0.30)",
            backdropFilter: "blur(3px)",
            padding: "1rem",
          }}
        >
          <div
            className="shadowa newPopUpContainer"
            ref={popupref}
            style={{
              maxWidth: "90vw",
              maxHeight: "80vh",

              maxWidth: "700px",
              backgroundColor: "",
              padding: "1.5rem",
            }}
          >
            <div className="d-flex " style={{ gap: "1rem" }}>
              <div className="circle-background">
                <img src={deleteicon} alt="delete" height="48px" width="48px" />
              </div>
              <div>
                <p className="gray90018500">Unsaved changes</p>
                <p className="gray70014400">
                  Any Changes made will be lost, are you sure?
                </p>
              </div>
            </div>
            <div
              className="d-flex justify-content-end"
              style={{ marginTop: "2rem", gap: "0.75rem" }}
            >
              <button
                className="backbutton"
                onClick={() => {
                  setPopup(false);
                }}
              >
                Back
              </button>
              <button
                className="continuebutton"
                onClick={() => {
                  navigate(-1);
                  setPopup(false);
                }}
              >
                Exit
              </button>
            </div>
          </div>
        </div>
      )}
      <div
        className=" d-flex justify-content-between headercreate "
        style={{ position: "relative" }}
      >
        <div
          className="d-flex align-items-center"
          style={{ justifyContent: "center", gap: "1.24rem" }}
        >
          <img
            src={logo}
            alt="logo img"
            width="36px"
            height="36px"
            className="shadowa rounded-2"
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/dashboard/Challenges");
            }}
          />
          <p className="HeaderText">
            {text}
            {taskHeader && taskSteps > 0 && <>-Task {taskCount + 1}</>}
          </p>
        </div>
        <div
          className="d-flex"
          style={{ gap: isNonMobile450 ? "2.5rem" : "1rem" }}
        >
          {taskHeader && isNonMob1130 && taskSteps > 0 && (
            <div
              className="d-flex"
              style={{ gap: "6px", alignItems: "center" }}
            >
              <div
                className="d-flex"
                style={{ alignItems: "center", gap: "10px" }}
              >
                <div className="navrounddivactive">1</div>
                <p className="navrounddivtextactive">Task Details</p>
              </div>
              <div
                style={{
                  height: "1px",
                  width: "1.5rem",
                  background: "#E31B54",
                }}
              ></div>
              <div
                className="d-flex"
                style={{ alignItems: "center", gap: "10px" }}
              >
                <div
                  className={`${
                    taskSteps > 2 ? "navrounddivactive" : "navrounddiv"
                  }`}
                >
                  2
                </div>
                <p
                  className={`${
                    taskSteps > 2 ? "navrounddivtextactive" : "navrounddivtext"
                  }`}
                >
                  Resources
                </p>
              </div>
              <div
                style={{
                  height: "1px",
                  width: "1.5rem",
                  background: taskSteps > 2 ? "#E31B54" : "#D0D5DD",
                }}
              ></div>
              <div
                className="d-flex"
                style={{ alignItems: "center", gap: "10px" }}
              >
                <div
                  className={`${
                    taskSteps > 3 ? "navrounddivactive" : "navrounddiv"
                  }`}
                >
                  3
                </div>
                <p
                  className={`${
                    taskSteps > 3 ? "navrounddivtextactive" : "navrounddivtext"
                  }`}
                >
                  Questions
                </p>
              </div>
              <div
                style={{
                  height: "1px",
                  width: "1.5rem",
                  background: taskSteps > 3 ? "#E31B54" : "#D0D5DD",
                }}
              ></div>
              <div
                className="d-flex"
                style={{ alignItems: "center", gap: "10px" }}
              >
                <div
                  className={`${
                    taskSteps > 4 ? "navrounddivactive" : "navrounddiv"
                  }`}
                >
                  4
                </div>
                <p
                  className={`${
                    taskSteps > 4 ? "navrounddivtextactive" : "navrounddivtext"
                  }`}
                >
                  Duration
                </p>
              </div>
            </div>
          )}
          <div
            className="d-flex"
            style={{ gap: isNonMobile450 ? "2.5rem" : "1rem" }}
          >
            {!edit && (
              <button
                className="supportbtnHeader "
                onClick={() => handleDraftSave()}
              >
                {isNonMobile450 ? "Save As Draft" : "Draft"}
              </button>
            )}
            <div
              className="d-flex  "
              style={{ justifyContent: "center", alignItems: "center" }}
              onClick={() => setPopup(true)}
            >
              <CloseIcon
                className="hoverdarkblacksvg"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
