import { useEffect, useState } from "react";
import AddBox from "../../assets/images/icons/Addbox";
import AddedBox from "../../assets/images/icons/AddedBox";
import { useNavigate } from "react-router-dom";
const Card = ({ challenge, shortListed, handleShortList }) => {
  const [check, setCheck] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    shortListed &&
      shortListed.length > 0 &&
      shortListed.map((shor) => {
        if (shor?.list && shor?.list?.length > 0) {
          shor?.list?.map((sho, i) => {
            if (sho?.user === challenge?.userDetails?.user?._id) {
              return setCheck(true);
            }
          });
        }
      });
  }, [shortListed]);

  return (
    <div className="reccardouter" style={{ maxWidth: "300px" }}>
      <div className="reccardinner-top">
        <div className="space w-100">
          <img
            src={challenge?.userDetails?.user?.profilePhoto}
            alt="user"
            className="shadowa"
            style={{
              height: "48px",
              width: "48px",
              padding: "2px",
              background: "#FFF",
              borderRadius: "50%",
            }}
          />

          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (!check) {
                handleShortList(challenge?.userDetails?.user?._id);
              }
            }}
          >
            {check ? <AddedBox /> : <AddBox />}
          </div>
        </div>
        <div>
          <p
            className="dashboardcard-user-name"
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate(
                `/dashboard/user/${challenge?.userDetails?.user?.uniqueId}`
              );
            }}
          >
            {challenge?.userDetails?.user?.firstName}{" "}
            {challenge?.userDetails?.user?.LastName}
          </p>
          <div style={{ position: "relative", overflow: "hidden" }}>
            <p
              className="dashboardcard-user-uni"
              style={{
                marginTop: "6px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "270px",
              }}
            >
              {challenge?.userDetails?.user?.uni?.name}
            </p>
          </div>
          <p
            className="dashboardcard-user-uni"
            style={{ marginTop: "0.75rem" }}
          >
            {challenge?.userDetails?.user?.levelofstudy} of{" "}
            {challenge?.userDetails?.user?.study}- intake{" "}
            {challenge?.userDetails?.user?.intake?.Year}
          </p>
        </div>
      </div>
      <div className="reccardinner-bottom">
        <p className="black12" style={{ fontWeight: "500" }}>
          Engagement
        </p>
        <div className="w-100 levelofengagementwrapper">
          <p className="levelofengagement">
            {challenge?.levelofEngagement?.toFixed(2)}%
          </p>
          <div
            className=""
            style={{
              width: `90%`,
              height: "4px",
              borderRadius: "100px",
              background: `linear-gradient(to right, #8098F9 0%, #C01048 ${challenge?.levelofEngagement}%, #DBDFF1 ${challenge?.levelofEngagement}%)`,
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default Card;
