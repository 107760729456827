import React from "react";
import InterestItems from "../../../InterestItems/InterestItems";

const Step1 = ({ selectedItem, setSelectedItem, selectedItems }) => {
  return (
    <div className="w-full">
      <p className="sectionheadingclamped" style={{ marginBottom: "1.5rem" }}>
        What are you interested in?
      </p>

      <InterestItems
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
      />
    </div>
  );
};

export default Step1;
