import { useEffect, useState } from "react";
import axios from "axios";
import "./post.css";
import PostMedia from "./MediaGrid";
const PostContainer = ({ post }) => {
  const [user, setUser] = useState({});

  useEffect(() => {
    const getuser = async () => {
      await axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/get/user/details`, {
          userId: post?.owner,
          msg: "fjksabnfjkbnasjfbajsbfjasjhfbjahsbjkfbk",
        })
        .then((res) => {
          if (res.data.message === "Found") {
            setUser(res.data.user);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    if (post) {
      getuser();
    }
  }, [post]);

  const getTimeElapsed = (createdAt) => {
    const currentDate = new Date();
    const creationDate = new Date(createdAt);

    const timeDifference = currentDate - creationDate;

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return `${days} days ago`;
    } else if (hours > 0) {
      return `${hours} hours ago`;
    } else if (minutes > 0) {
      return `${minutes} minutes ago`;
    } else {
      return `${seconds} seconds ago`;
    }
  };

  return (
    <div className="postcontainer-outer shadowa">
      <div className="postcontainer-inner">
        {user && (
          <div className="d-flex" style={{ gap: "1rem" }}>
            {user?.profilePhoto && (
              <div className="profilephotocontainer justify-content-center align-items-center shadowa">
                <img
                  src={user?.profilePhoto}
                  alt="profile"
                  className=""
                  height={"32px"}
                  width={"32px"}
                />
              </div>
            )}{" "}
            <div>
              <p className="black14 text-start">
                {user?.firstName} {user?.LastName}
              </p>
              <p className="companyLocation" style={{ marginTop: "6px" }}>
                {getTimeElapsed(post?.createdAt)}
              </p>
            </div>
          </div>
        )}
        {post?.content && (
          <p style={{ marginTop: "0.75rem" }} className="posttext">
            {post?.content}
          </p>
        )}
        {post?.media && (
          <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
            {/* <PostMedia media={post?.media} /> */}
          </div>
        )}
      </div>
    </div>
  );
};
export default PostContainer;
