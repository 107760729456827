import React, { useState } from "react";
import { useMediaQuery } from "@mui/material";
import finance from "../../assets/images/icons/Finance.png";
import img from "../../assets/images/reachout.png";

import p1 from "../../assets/images/newu1.png";
import p2 from "../../assets/images/newu2.png";
import p3 from "../../assets/images/newu3.png";
import p4 from "../../assets/images/newu4.png";
import p5 from "../../assets/images/newu5.png";
const GetInsights = () => {
  const isNonMobile = useMediaQuery("(min-width:768px)");

  const isNonMobile1441 = useMediaQuery("(min-width:1441px)");

  const [MouseHover, setStartHover] = useState(false);
  const skills = [
    "Company Analysis",
    "Data Interpretation",
    "DFC Analysis",
    "M&A Screening",
    "M&A Process Understanding",
  ];
  return (
    <div
      className="marginsleftrightCompany "
      style={{
        paddingTop: isNonMobile ? "8.125rem" : "5rem",
        paddingBottom: isNonMobile ? "8.125rem" : "5rem",
      }}
    >
      {" "}
      <div
        className="d-flex"
        style={{ justifyContent: "center", position: "relative" }}
      >
        <div
          className={` headerCompany   ${
            isNonMobile && "d-flex sectioncenter"
          }`}
          style={{ gap: "1rem" }}
        >
          <div
            className="widthfirst d-flex"
            style={{ alignItems: "center", width: isNonMobile ? "60%" : "" }}
          >
            <div>
              {/* <p className=" sparkInterest">
                Get <span>Insights</span>
              </p> */}
              <p style={{ width: "100%" }} className="itsearly ">
                Connect with students who share{" "}
                <span className="pinkspan">your journey</span> and get useful
                insights.
              </p>
              <div className={`marginfromabove  `} style={{ gap: "0.5rem" }}>
                <p className={`  learnmoreabout text-start`}>
                  Millions of students want to break into similar career fields.
                  Join a group to discuss experiences about application
                  proceeses and employers, to get first-hand insights.
                </p>
                <div style={{ marginTop: "1.25rem" }}>
                  {" "}
                  <div
                    className={`  ${isNonMobile && "section d-flex"}`}
                    style={{ marginTop: "1rem" }}
                  >
                    <div className="imgsunderuni ">
                      <img
                        className=" move imgshadow rounded-5"
                        style={{ zIndex: 5 }}
                        src={p1}
                        alt="person"
                        height={"44px"}
                        width={"44px"}
                      />
                      <img
                        className=" move imgshadow rounded-5"
                        style={{ left: "-1.5rem", zIndex: 4 }}
                        src={p2}
                        height={"44px"}
                        width={"44px"}
                        alt="person"
                      />
                      <img
                        className=" move imgshadow rounded-5"
                        style={{ left: "-2.5rem", zIndex: 3 }}
                        src={p3}
                        height={"44px"}
                        width={"44px"}
                        alt="person"
                      />
                      <img
                        className=" move imgshadow rounded-5"
                        style={{ left: "-3.5rem", zIndex: 2 }}
                        src={p4}
                        height={"44px"}
                        width={"44px"}
                        alt="person"
                      />
                      <img
                        className=" move imgshadow rounded-5"
                        style={{ left: "-4.5rem", zIndex: 1 }}
                        src={p5}
                        height={"44px"}
                        width={"44px"}
                        alt="person"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="widthsecond d-flex"
            style={{
              justifyContent: isNonMobile ? "end" : "",
              marginTop: "1rem",
            }}
          >
            <div>
              <img
                src={img}
                alt="learnskill"
                className={`img-fluid ${!isNonMobile && "mt-4"}`}
                style={{ padding: isNonMobile ? "4rem" : "" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default GetInsights;
