import "./secondsection.css";
import { useMediaQuery } from "@mui/material";
const NewSecondSection = ({
  title,
  information,
  difficulty,
  tools,
  studentInfo,
  company,
  color,
  newTasks,
}) => {
  let totalTime = 0;

  newTasks.forEach((task) => {
    if (task.time) {
      totalTime += Number(task.time);
    }
  });

  const isNonMobile1140 = useMediaQuery("(min-width:1140px)");
  return (
    <div id="overview" className="newSecondSectionBackground">
      <div className="marginsleftrightCompany">
        <div className="d-flex" style={{ justifyContent: "center" }}>
          <div className={`headerCompany`} style={{ padding: "6.75rem 0rem" }}>
            <p className="black16 text-start" style={{ color: color }}>
              Overview
            </p>
            <p
              style={{ marginTop: "0.75rem", maxWidth: "35.75rem" }}
              className="registeration"
            >
              Challenge details
            </p>
            <div style={{ marginTop: "2.5rem" }}>
              <div
                className={`${isNonMobile1140 ? "space" : "d-flex"} flex-wrap`}
                style={{ gap: "1rem" }}
              >
                <div className="d-flex" style={{ gap: "1.25rem" }}>
                  <div className="newsvgcontainer">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="20"
                      viewBox="0 0 19 20"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.29188 12.4931L6.84807 9.89505C6.92904 9.534 7.11143 9.20359 7.37378 8.94267L12.4557 3.90647C12.971 3.3815 13.6707 3.07812 14.4062 3.06076C14.9608 3.05281 15.4957 3.26676 15.8919 3.65505C16.7575 4.67788 16.6458 6.20531 15.6404 7.09124L10.5585 12.1731C10.2976 12.4355 9.96721 12.6179 9.60616 12.6989L7.00807 13.255H6.86331C6.67337 13.2623 6.49196 13.1759 6.37792 13.0239C6.26387 12.8718 6.23172 12.6734 6.29188 12.4931ZM8.1814 9.7579C8.07465 9.86136 8.00049 9.99378 7.96807 10.1389L7.59474 11.9141L9.36997 11.5331C9.51505 11.5007 9.64747 11.4266 9.75093 11.3198L14.8328 6.2379C15.3895 5.79221 15.5 4.98874 15.0843 4.40933C14.9022 4.23539 14.6578 4.14203 14.4062 4.15028C13.9723 4.16771 13.5623 4.35356 13.2633 4.66838L8.1814 9.7579Z"
                        fill={color ? color : "#E31B54"}
                      />
                      <path
                        d="M15.5262 9.18648C15.2123 9.19058 14.9588 9.44403 14.9547 9.7579V14.0931C14.965 14.7059 14.7287 15.2971 14.2989 15.7341C13.8691 16.171 13.2819 16.417 12.669 16.417H5.40807C4.14131 16.3921 3.12626 15.3601 3.12236 14.0931V6.86267C3.14316 5.59416 4.17749 4.57678 5.44617 4.57695H9.78141C10.097 4.57695 10.3528 4.32111 10.3528 4.00552C10.3528 3.68993 10.097 3.43409 9.78141 3.43409H5.40807C3.50826 3.43398 1.96228 4.96297 1.94141 6.86267V14.0931C1.94141 16.0077 3.49349 17.5598 5.40807 17.5598H12.6385C14.5502 17.5556 16.0976 16.0048 16.0976 14.0931V9.7579C16.0935 9.44403 15.84 9.19058 15.5262 9.18648Z"
                        fill={color ? color : "#E31B54"}
                      />
                    </svg>
                  </div>
                  <div className="d-flex flex-column" style={{ gap: "0.5rem" }}>
                    <p
                      className="black16500 text-start"
                      style={{ fontWeight: "400", color: color }}
                    >
                      Difficulty
                    </p>
                    <p className="black14" style={{ fontWeight: "500" }}>
                      {difficulty}
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    height: "52px",
                    width: "1px",
                    background: "#D0D5DD",
                  }}
                ></div>
                {tools.length > 0 && (
                  <>
                    {" "}
                    <div className="d-flex" style={{ gap: "1.25rem" }}>
                      <div className="newsvgcontainer">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="19"
                          height="20"
                          viewBox="0 0 32 32"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M24.6896 4H16.7963C14.5872 4 12.7963 5.79086 12.7963 8V23.04C12.7963 25.2491 14.5872 27.04 16.7963 27.04H24.6896C26.8988 27.04 28.6896 25.2491 28.6896 23.04V8C28.6896 5.79086 26.8988 4 24.6896 4ZM14.8497 14.6667H15.943C16.3112 14.6667 16.6097 14.9651 16.6097 15.3333V15.76C16.6097 16.1282 16.3112 16.4267 15.943 16.4267H14.8497V14.6667ZM24.6896 25.0667C25.7942 25.0667 26.6896 24.1712 26.6896 23.0667V8C26.6896 6.89543 25.7942 6 24.6896 6H16.7963C15.6917 6 14.7963 6.89543 14.7963 8V12.64H15.8896C17.3624 12.64 18.5563 13.8339 18.5563 15.3067V15.7333C18.5563 17.2061 17.3624 18.4 15.8896 18.4H14.7963V23.0667C14.7963 24.1712 15.6917 25.0667 16.7963 25.0667H24.6896Z"
                            fill={color ? color : "#E31B54"}
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M2.67631 8.56C2.51567 6.2433 4.25418 4.23091 6.56964 4.05333C8.87466 4.2515 10.592 6.266 10.423 8.57333V20.9333C10.4381 21.5747 10.2617 22.206 9.91631 22.7467L7.42298 26.6C7.24636 26.9166 6.9122 27.1129 6.54964 27.1129C6.18708 27.1129 5.85292 26.9166 5.67631 26.6L3.18298 22.7467C2.84541 22.2026 2.66967 21.5736 2.67631 20.9333V8.56ZM8.22298 21.6533C8.35691 21.4373 8.42631 21.1875 8.42298 20.9333L8.44965 8.56C8.46298 7.2 7.59632 6.05333 6.56965 6.05333C5.55631 6.05333 4.68965 7.22667 4.68965 8.56V20.9333C4.67828 21.1849 4.73837 21.4345 4.86298 21.6533L6.54298 24.32L8.22298 21.6533Z"
                            fill={color ? color : "#E31B54"}
                          />
                          <path
                            d="M6.55632 6.90667C6.00403 6.90667 5.55632 7.35438 5.55632 7.90667V12.52C5.55632 13.0723 6.00403 13.52 6.55632 13.52C7.1086 13.52 7.55632 13.0723 7.55632 12.52V7.90667C7.55994 7.64036 7.45575 7.38389 7.26742 7.19556C7.07909 7.00724 6.82263 6.90304 6.55632 6.90667Z"
                            fill={color ? color : "#E31B54"}
                          />
                          <path
                            d="M24.3963 8.18667H19.9296C19.3773 8.18667 18.9296 8.63438 18.9296 9.18667C18.9296 9.73895 19.3773 10.1867 19.9296 10.1867H24.3963C24.9486 10.1867 25.3963 9.73895 25.3963 9.18667C25.3963 8.63438 24.9486 8.18667 24.3963 8.18667Z"
                            fill={color ? color : "#E31B54"}
                          />
                        </svg>
                      </div>
                      <div
                        className="d-flex flex-column"
                        style={{ gap: "0.5rem" }}
                      >
                        <p
                          className="black16500 text-start"
                          style={{ fontWeight: "400", color: color }}
                        >
                          Required Tools
                        </p>
                        <div className="d-flex" style={{ gap: "0.75rem" }}>
                          {tools.slice(0, 2).map((tol, index) => (
                            <p
                              key={index}
                              className="black14"
                              style={{ fontWeight: "500" }}
                            >
                              {tol}
                            </p>
                          ))}
                          {tools.length > 2 && (
                            <p
                              className="black14"
                              style={{ fontWeight: "500" }}
                            >
                              +{tools.length - 2}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        height: "52px",
                        width: "1px",
                        background: "#D0D5DD",
                      }}
                    ></div>
                  </>
                )}
                <div className="d-flex" style={{ gap: "1.25rem" }}>
                  <div className="newsvgcontainer">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 32 32"
                      fill="none"
                    >
                      <path
                        d="M13.5467 12.4089C13.8128 12.4089 14.0677 12.5164 14.2534 12.707L16.7067 15.147C16.8943 15.3343 16.9998 15.5885 17.0001 15.8537V17.707C17.0001 18.2593 16.5523 18.707 16.0001 18.707C15.4478 18.707 15.0001 18.2593 15.0001 17.707V16.267L12.8401 14.1203C12.4501 13.7299 12.4501 13.0974 12.8401 12.707C13.0258 12.5164 13.2806 12.4089 13.5467 12.4089Z"
                        fill={color ? color : "#E31B54"}
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M24 14.0403V13.4137C24.006 11.4348 22.8535 9.63556 21.0534 8.81366V5.33366C21.0534 3.8609 19.8595 2.66699 18.3867 2.66699H13.6267C12.1539 2.66699 10.96 3.8609 10.96 5.33366V8.81366C9.15241 9.62893 7.993 11.4307 8.00003 13.4137V19.3203C7.99819 21.3165 9.17467 23.1257 11 23.9337V27.707C11 29.1798 12.1939 30.3737 13.6667 30.3737H18.3867C19.8595 30.3737 21.0534 29.1798 21.0534 27.707V23.9337C22.8741 23.1203 24.0484 21.3145 24.0534 19.3203V18.707C24.6026 18.6998 25.0462 18.2563 25.0534 17.707V15.0403C25.0538 14.7656 24.9411 14.5028 24.7419 14.3137C24.5426 14.1245 24.2744 14.0257 24 14.0403ZM13.0534 5.33366C13.0534 4.98756 13.3339 4.70699 13.68 4.70699H18.3867C18.7328 4.70699 19.0134 4.98756 19.0134 5.33366V8.37366H13.0534V5.33366ZM18.4267 28.3337C18.7728 28.3337 19.0534 28.0531 19.0534 27.707L19 24.3603H13V27.707C13 28.0531 13.2806 28.3337 13.6267 28.3337H18.4267ZM22.0934 19.3203C22.0934 20.1407 21.7675 20.9275 21.1874 21.5076C20.6072 22.0878 19.8204 22.4137 19 22.4137H13.0534C11.345 22.4137 9.96003 21.0287 9.96003 19.3203V13.4137C9.96737 11.7105 11.3502 10.3336 13.0534 10.3337H18.96C20.6632 10.3336 22.046 11.7105 22.0534 13.4137L22.0934 19.3203Z"
                        fill={color ? color : "#E31B54"}
                      />
                    </svg>
                  </div>
                  <div className="d-flex flex-column" style={{ gap: "0.5rem" }}>
                    <p
                      className="black16500 text-start"
                      style={{ fontWeight: "400", color: color }}
                    >
                      Time Commitment
                    </p>
                    <p className="black14" style={{ fontWeight: "500" }}>
                      {totalTime} mins
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    height: "52px",
                    width: "1px",
                    background: "#D0D5DD",
                  }}
                ></div>
                <div className="d-flex" style={{ gap: "1.25rem" }}>
                  <div className="newsvgcontainer">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="20"
                      viewBox="0 0 32 32"
                      fill="none"
                    >
                      <path
                        d="M17.9508 18.5364C17.9508 17.314 16.9599 16.323 15.7375 16.323C14.5151 16.323 13.5242 17.314 13.5242 18.5364C13.5224 19.0546 13.7069 19.5562 14.0442 19.9497C14.384 20.3038 14.5018 20.816 14.3508 21.283L14.0708 22.1097C13.9626 22.4814 14.0341 22.8824 14.2644 23.1938C14.4946 23.5051 14.857 23.691 15.2442 23.6964H16.2308C16.6308 23.6962 17.0062 23.5032 17.2389 23.1779C17.4717 22.8526 17.5332 22.435 17.4042 22.0564L17.0708 21.2297C16.9199 20.7627 17.0377 20.2505 17.3775 19.8964C17.7224 19.5244 17.9253 19.0429 17.9508 18.5364Z"
                        fill={color ? color : "#E31B54"}
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M19.5908 10.363C21.4436 10.2896 23.2349 11.0352 24.4883 12.4016C25.7417 13.768 26.3303 15.6168 26.0975 17.4564L24.8309 25.1497C24.2213 28.2403 21.4734 30.442 18.3242 30.363H13.1508C10.024 30.4325 7.2956 28.2543 6.67085 25.1897L5.40418 17.4964C4.96247 14.6017 6.6422 11.8021 9.40418 10.8297V6.66969C9.40345 5.58291 9.84495 4.54262 10.6271 3.78811C11.4093 3.03361 12.4648 2.62984 13.5508 2.66969H17.9242C19.0102 2.62984 20.0657 3.03361 20.8479 3.78811C21.6301 4.54262 22.0716 5.58291 22.0709 6.66969C22.0709 7.22197 21.6231 7.66969 21.0708 7.66969C20.5186 7.66969 20.0708 7.22197 20.0708 6.66969C20.0204 5.5332 19.0609 4.65119 17.9242 4.69635H13.5508C12.4141 4.65119 11.4547 5.5332 11.4042 6.66969V10.363H19.5908ZM22.4042 24.7497L23.7375 17.0297C23.8698 16.0309 23.5524 15.025 22.8708 14.283C22.042 13.3431 20.8439 12.8122 19.5908 12.8297H11.8842C10.6354 12.8055 9.43793 13.3263 8.60418 14.2564C7.93887 15.0161 7.64574 16.0323 7.80418 17.0297L9.07085 24.7497C9.48425 26.6515 11.2064 27.9797 13.1508 27.8964H18.3242C20.2686 27.9797 21.9908 26.6515 22.4042 24.7497Z"
                        fill={color ? color : "#E31B54"}
                      />
                    </svg>
                  </div>
                  <div className="d-flex flex-column" style={{ gap: "0.5rem" }}>
                    <p
                      className="black16500 text-start"
                      style={{ fontWeight: "400", color: color }}
                    >
                      Access
                    </p>
                    <p className="black14" style={{ fontWeight: "500" }}>
                      Full Access
                    </p>
                  </div>
                </div>{" "}
                <div
                  style={{
                    height: "52px",
                    width: "1px",
                    background: "#D0D5DD",
                  }}
                ></div>
                <div className="d-flex" style={{ gap: "1.25rem" }}>
                  <div className="newsvgcontainer">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 32 32"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M13.1327 17.0802H11.2793C10.9211 17.0803 10.5778 16.9362 10.327 16.6803C10.0762 16.4245 9.93885 16.0784 9.94602 15.7202V13.8669C9.94602 13.1305 10.543 12.5335 11.2793 12.5335H13.1327C13.8691 12.5335 14.466 13.1305 14.466 13.8669V15.7469C14.466 16.4833 13.8691 17.0802 13.1327 17.0802ZM12.4927 14.5069H11.9193V15.0802H12.4927V14.5069Z"
                        fill={color ? color : "#E31B54"}
                      />
                      <path
                        d="M10.8393 20.4535H16.746C17.2983 20.4535 17.746 20.0058 17.746 19.4535C17.746 18.9013 17.2983 18.4535 16.746 18.4535H10.8393C10.2871 18.4535 9.83935 18.9013 9.83935 19.4535C9.83935 20.0058 10.2871 20.4535 10.8393 20.4535Z"
                        fill={color ? color : "#E31B54"}
                      />
                      <path
                        d="M18.666 21.8269H10.8393C10.2871 21.8269 9.83935 22.2746 9.83935 22.8269C9.83935 23.3792 10.2871 23.8269 10.8393 23.8269H18.666C19.2183 23.8269 19.666 23.3792 19.666 22.8269C19.666 22.2746 19.2183 21.8269 18.666 21.8269Z"
                        fill={color ? color : "#E31B54"}
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M26.0393 12.5869L16.706 3.25355C16.5096 3.08518 16.258 2.995 15.9993 3.00021H11.9993C8.50155 3.00021 5.66602 5.83574 5.66602 9.33355V22.6669C5.66602 26.1647 8.50155 29.0002 11.9993 29.0002H19.9993C23.4972 29.0002 26.3327 26.1647 26.3327 22.6669V13.3335C26.3372 13.0557 26.2318 12.7873 26.0393 12.5869ZM16.9993 6.38688L22.946 12.3335H18.666C17.7455 12.3335 16.9993 11.5874 16.9993 10.6669V6.38688ZM7.66602 22.6669C7.67334 25.0571 9.60915 26.9929 11.9993 27.0002H19.9993C22.3895 26.9929 24.3254 25.0571 24.3327 22.6669V14.3335H18.666C16.641 14.3335 14.9993 12.6919 14.9993 10.6669V5.00021H11.9993C9.60915 5.00753 7.67334 6.94335 7.66602 9.33355V22.6669Z"
                        fill={color ? color : "#E31B54"}
                      />
                    </svg>
                  </div>
                  <div className="d-flex flex-column" style={{ gap: "0.5rem" }}>
                    <p
                      className="black16500 text-start"
                      style={{ fontWeight: "400", color: color }}
                    >
                      CV Share
                    </p>
                    <p className="black14" style={{ fontWeight: "500" }}>
                      {studentInfo && studentInfo === "CV Optional"
                        ? "Optional"
                        : "None"}
                    </p>
                  </div>
                </div>{" "}
              </div>
            </div>
            <div className="shadowa">
              <div
                style={{ marginTop: "1.5rem" }}
                className="detailsnewbackdiv"
              >
                <p className="black16 text-start" style={{ color: color }}>
                  Overview
                </p>
              </div>
              <div
                style={{
                  background: "#FFF",
                  padding: "14px",
                  borderRadius: "0px 0px 5px 5px",
                }}
              >
                <div
                  className="informationtext tablescroll"
                  style={{
                    maxHeight: "348px",
                    overflow: "auto",
                  }}
                  dangerouslySetInnerHTML={{ __html: information }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewSecondSection;
