import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  studentauth: false,
  employerauth: false,
  employer: null,
  user: null,
  token: null,
  Uni: null,
  email: null,
  Interest: [],
  role: null,
  uniqueId: null,
  step: null,
  company: null,
  preview: null,
  demo: true,
  editChallenge: null,
  editJob: null,
  progress: null,
  selectedEvent: null,
  selectedChallenge: null,
  selectedJob: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLogin: (state, action) => {
      state.user = action.payload.user;
    },
    setEvent: (state, action) => {
      state.selectedEvent = action.payload.selectedEvent;
    },
    setSelectedChallenge: (state, action) => {
      state.selectedChallenge = action.payload.selectedChallenge;
    },
    setProgress: (state, action) => {
      state.progress = action.payload.progress;
    },
    setDemo: (state, action) => {
      state.demo = action.payload.demo;
    },
    setEditChallenge: (state, action) => {
      state.editChallenge = action.payload.editChallenge;
    },

    setEditJob: (state, action) => {
      state.editJob = action.payload.editJob;
    },
    setEmployer: (state, action) => {
      state.employer = action.payload.employer;
    },

    setPreview: (state, action) => {
      state.preview = action.payload.preview;
    },
    setCompany: (state, action) => {
      state.company = action.payload.company;
    },
    setStep: (state, action) => {
      state.step = action.payload.step;
    },
    setId: (state, action) => {
      state.uniqueId = action.payload.uniqueId;
    },
    setRole: (state, action) => {
      state.role = action.payload.role;
    },
    setLogout: (state) => {
      state.user = null;
      state.token = null;
    },

    setUni: (state, action) => {
      state.Uni = action.payload.Uni;
    },
    setInterest: (state, action) => {
      state.Interest = action.payload.Interest;
    },
    setEmail: (state, action) => {
      state.email = action.payload.email;
    },
  },
});

export const {
  setUni,
  setLogin,
  setLogout,
  setInterest,
  setEmail,
  setRole,
  setId,
  setStep,
  setCompany,
  setPreview,
  setEmployer,
  setSelect,
  setEditChallenge,
  setEditJob,
  setDemo,
  setProgress,
  setEvent,
  setSelectedChallenge,
} = authSlice.actions;
export default authSlice.reducer;
