import Navbar from "../TestAgain/navbar";
import { useEffect, useState } from "react";
import logo from "../../assets/images/logo.png";
import { Spiral as Hamburger } from "hamburger-react";
import CloseIcon from "@mui/icons-material/Close";
const PreviewTaskHeader = ({
  questionNumber,
  taskPreview,
  steps,
  setShowPreview,
  isNonMobile700,
  title,
  taskCounter,
  total,
}) => {
  const [percentage, setPercentage] = useState(0);
  const [timeLeft, setTimeLeft] = useState(taskPreview?.time * 60);
  const [Test, setTest] = useState(false);
  useEffect(() => {
    if (steps !== 4) {
      setPercentage((taskCounter / total) * 100);
    } else {
      setPercentage(100);
    }
  }, [taskCounter, total]);

  useEffect(() => {
    if (timeLeft > 0) {
      const timerInterval = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timerInterval);
    }
  }, [timeLeft]);

  const formatTime = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;

    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };

  const fillBarStyle = {
    width: `100%`,
    height: "10px", // Adjust as needed
    background: `linear-gradient(to right, #E31B54 ${percentage}%, #EAECF0 ${percentage}%)`,
    transition: "width 0.3s ease", // Optional: Add transition for a smoother effect
  };

  return (
    <div
      className="challengeattemptheader space shadowa"
      style={{
        paddingTop: "0.5rem",
        paddingBottom: "0.5rem",
        background: "#FFF",
        position: "absolute",
        width: "100vw",
      }}
    >
      <div className="d-flex" style={{ gap: "1.25rem", alignItems: "center" }}>
        <img
          src={logo}
          alt="logo"
          height="36px"
          width="36px"
          className="rounded-3 shadowa"
        />
        <p className="black14">{title}</p>
      </div>
      <div className="d-flex" style={{ alignItems: "center", gap: "1rem" }}>
        {isNonMobile700 ? (
          <>
            <div style={{ width: "327px" }}>
              <div
                className="d-flex "
                style={{ justifyContent: "space-between" }}
              >
                <p className="rate">Progress {percentage}%</p>
                <p
                  className="pink16"
                  style={{ fontSize: "16px", lineHeight: "16px" }}
                >
                  {formatTime(timeLeft)}
                </p>
              </div>
              <div
                className="fill-bar rounded-4 mt-2"
                style={fillBarStyle}
              ></div>
            </div>
            <CloseIcon
              onClick={() => setShowPreview(false)}
              style={{
                height: "22px",
                width: "22px",
                color: "#98A2B3",
                cursor: "pointer",
              }}
            />
          </>
        ) : (
          <button
            onClick={() => setTest(!Test)}
            className="p-0"
            style={{
              background: "transparent",
              border: "none",
              zIndex: 101,
            }}
          >
            <Hamburger toggled={Test} size={24} direction="right" />
          </button>
        )}
      </div>
      <div
        className={`menu ${Test ? "active" : "inactive"}`}
        style={{
          width: "100vw",
          height: "100vh",
          background: "#F9FAFB",
          zIndex: 100,

          position: "fixed",
        }}
      >
        <div className="w-100" style={{ padding: "1.25rem" }}>
          <p className="black14 text-start" style={{ width: "80%" }}>
            {title}
          </p>
          <div className="" style={{ width: "100%", marginTop: "1.5rem" }}>
            <div
              className="d-flex "
              style={{ justifyContent: "space-between" }}
            >
              <p className="rate">Progress {percentage}%</p>
              <p
                className="pink16"
                style={{ fontSize: "16px", lineHeight: "16px" }}
              >
                {formatTime(timeLeft)}
              </p>
            </div>
            <div className="fill-bar rounded-4 mt-2" style={fillBarStyle}></div>
          </div>
          <div style={{ marginTop: "2.5rem" }}>
            <Navbar
              questions={taskPreview.questions}
              steps={steps}
              questionNumber={questionNumber}
            />
          </div>
          <button
            className="backbutton w-100"
            style={{ marginTop: "2rem" }}
            onClick={() => setShowPreview(false)}
          >
            Exit Preview
          </button>
        </div>
      </div>
    </div>
  );
};
export default PreviewTaskHeader;
