import "./firstsection.css";
import PreviewHeader from "../previewHeader";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
const FirstSection = ({
  selectedFile,
  company,
  description,
  type,
  setTaskSteps,
  title,
  step,
  setStep,
  check,
  test,
  Userloggedin,
  uniqueId,
  checker,
  no,
  color,
  tester,
  setShowPreviewa,
}) => {
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width: 768px)");
  const isNonMobile1024 = useMediaQuery("(min-width: 1024px)");
  return (
    <>
      <div
        className={`previewPageFirstSection`}
        style={{
          backgroundImage: selectedFile
            ? `linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%), url(${
                selectedFile instanceof File
                  ? URL.createObjectURL(selectedFile)
                  : selectedFile.includes("http")
                  ? selectedFile
                  : `${process.env.REACT_APP_BACKEND_URL}${selectedFile}`
              })`
            : "none",
          backgroundSize: "cover",
          backgroundPosition: "center",
          paddingTop: tester ? "" : "1.25rem",
        }}
      >
        {check && (
          <PreviewHeader
            step={step}
            setShowPreviewa={setShowPreviewa}
            setStep={setStep}
            setTaskSteps={setTaskSteps}
          />
        )}
        <div
          className="marginsleftrightCompany"
          style={{ marginTop: "1.25rem", marginTop: "7.188rem" }}
        >
          <div className="d-flex" style={{ justifyContent: "center" }}>
            <div className="headerCompany d-flex">
              <div
                className="previewpageFirstSectionWhite"
                style={{ maxWidth: "587px" }}
              >
                <div className="" style={{ gap: "1.5rem" }}>
                  <div className="center companyimagecontainer">
                    {company && company.img && (
                      <img
                        src={company.img}
                        alt="company logo"
                        className="img-fluid rounded-2"
                        style={{ maxHeight: "2.938rem", maxWidth: "6.125rem" }}
                      />
                    )}
                  </div>
                  <div style={{ marginTop: "1.25rem" }}>
                    <p className="white16" style={{ color: color }}>
                      {type && type}{" "}
                      <span style={{ color: color }}>Challenge</span>
                    </p>
                    <p className="registeration" style={{ marginTop: "1rem" }}>
                      {title && title}
                    </p>
                  </div>
                </div>
                <p
                  style={{ marginTop: "0.5rem" }}
                  className="black16 text-start"
                >
                  {description && description}
                </p>
                <div
                  style={{
                    marginTop: "3.25rem",
                    gap: "1rem",
                    alignItems: "center",
                  }}
                  className="d-flex flex-wrap"
                >
                  <p
                    className="black16 text-start"
                    style={{ maxWidth: "12.688rem" }}
                  >
                    Free, engage and hire <span>students online.</span>
                  </p>

                  <button
                    className="continuebutton"
                    style={{
                      maxHeight: "40px",
                      border: "none",
                      background: color,
                    }}
                    onClick={() => {
                      if (no) {
                      } else {
                        if (Userloggedin && !checker) {
                          navigate(`/challenge/attempt/${uniqueId}`);
                        } else if (Userloggedin && checker) {
                          navigate("/dashboard/Challenges");
                        } else {
                          navigate("/login");
                        }
                      }
                    }}
                  >
                    {test
                      ? Userloggedin
                        ? "Start Challenge"
                        : "Signin to start"
                      : "Start Challenge"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="center secondSectionTopDiv marginsleftrightCompany">
        <div className="headerCompany">
          <div className="d-flex" style={{ justifyContent: "space-between" }}>
            <img
              src={company?.img}
              alt="company img"
              className=" shadowa rounded-2"
              height="36px"
              width={"36px"}
              style={{ borderRadius: "4.5px", padding: "0.25rem" }}
            />
            {isNonMobile && (
              <div
                className="d-flex border border-1 rounded-3"
                style={{ padding: "0.25px", alignItems: "center" }}
              >
                <a
                  href="#overview"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <button
                    className="btn gray14 "
                    style={{
                      fontWeight: "500",
                      width: isNonMobile1024 ? "180px" : "",
                      color: color,
                      background: "#F2F4F7",
                    }}
                  >
                    Overview
                  </button>
                </a>
                <a
                  href="#skills"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <button
                    className="btn gray14 "
                    style={{
                      fontWeight: "500",
                      width: isNonMobile1024 ? "180px" : "",
                    }}
                  >
                    Skills
                  </button>
                </a>
                <a
                  href="#tasks"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  {" "}
                  <button
                    className="btn gray14 "
                    style={{
                      fontWeight: "500",
                      width: isNonMobile1024 ? "180px" : "",
                    }}
                  >
                    Your Tasks
                  </button>
                </a>
              </div>
            )}
            <button
              className="continuebutton"
              style={{
                maxHeight: "40px",
                border: "none",
                background: color,
              }}
              onClick={() => {
                if (no) {
                } else {
                  if (Userloggedin && !checker) {
                    navigate(`/challenge/attempt/${uniqueId}`);
                  } else if (Userloggedin && checker) {
                    navigate("/dashboard/Challenges");
                  } else {
                    navigate("/login");
                  }
                }
              }}
            >
              {test
                ? Userloggedin
                  ? "Start Challenge"
                  : "Signin to start"
                : "Start Challenge"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default FirstSection;
