// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.megaMenu {
  background: #fff;
  padding: 1.5rem 0rem; /* Fixed padding */
  position: absolute;
  top: -100%; /* Initially hide the menu */
  opacity: 0; /* Initially transparent */
  transition: all 0.3s ease-in-out; /* Smooth transition */
  width: 100vw;
  z-index: 999; /* Ensure it appears above other elements */
}

.megaMenu.megamenuactive {
  top: 40px; /* Show the menu by setting top */
  opacity: 1; /* Make the menu visible */
}
`, "",{"version":3,"sources":["webpack://./src/pages/NewPages/main.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,oBAAoB,EAAE,kBAAkB;EACxC,kBAAkB;EAClB,UAAU,EAAE,4BAA4B;EACxC,UAAU,EAAE,0BAA0B;EACtC,gCAAgC,EAAE,sBAAsB;EACxD,YAAY;EACZ,YAAY,EAAE,2CAA2C;AAC3D;;AAEA;EACE,SAAS,EAAE,iCAAiC;EAC5C,UAAU,EAAE,0BAA0B;AACxC","sourcesContent":[".megaMenu {\n  background: #fff;\n  padding: 1.5rem 0rem; /* Fixed padding */\n  position: absolute;\n  top: -100%; /* Initially hide the menu */\n  opacity: 0; /* Initially transparent */\n  transition: all 0.3s ease-in-out; /* Smooth transition */\n  width: 100vw;\n  z-index: 999; /* Ensure it appears above other elements */\n}\n\n.megaMenu.megamenuactive {\n  top: 40px; /* Show the menu by setting top */\n  opacity: 1; /* Make the menu visible */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
