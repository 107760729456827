import { useSelector } from "react-redux";
import PostingTab from "./PostingTab";
import { useMediaQuery } from "@mui/material";
import { useEffect, useState, useCallback } from "react";
import axios from "axios";
import PostContainer from "./PostContainer";
import InfiniteScroll from "react-infinite-scroll-component";

const SocialMain = () => {
  const user = useSelector((state) => state.user);
  const [posts, setPosts] = useState([]);
  const isNonMobile1024 = useMediaQuery("(min-width:1024px)");
  const isNonMobile450 = useMediaQuery("(min-width:450px)");
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const fetchPosts = useCallback(async () => {
    // if (!loading && user && hasMore) {
    //   setLoading(true);
    //   try {
    //     const response = await axios.post(
    //       `${process.env.REACT_APP_BACKEND_URL}/get/posts`,
    //       {
    //         userId: user._id,
    //         page: page,
    //         limit: 10,
    //       }
    //     );
    //     if (response.data.msg === "success") {
    //       setPosts((prevPosts) => [...prevPosts, ...response.data.posts]);
    //       if (response.data.posts.length < 10) {
    //         setHasMore(false); // No more posts to load
    //       }
    //     } else {
    //       setHasMore(false); // Error or no more posts
    //     }
    //   } catch (error) {
    //     console.log(error);
    //     setHasMore(false); // Error occurred
    //   } finally {
    //     setLoading(false);
    //   }
    // }
  }, [user, page, hasMore, loading]);

  useEffect(() => {
    fetchPosts();
  }, [user, fetchPosts]);

  const fetchMorePosts = () => {
    if (!loading) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  return (
    <div className="paddingmaincontent">
      <div style={{ gap: "1.5rem" }} className="d-flex">
        <div style={{ width: isNonMobile1024 ? "68.94%" : "100%" }}>
          <PostingTab
            id={user?._id}
            photo={user?.profilePhoto}
            isNonMobile450={isNonMobile450}
            isNonMobile1024={isNonMobile1024}
          />
          <div
            className="d-flex flex-column"
            id="scrollableDiv"
            style={{ marginTop: "1.5rem", gap: "1.5rem" }}
          >
            <InfiniteScroll
              dataLength={posts.length}
              next={fetchMorePosts}
              hasMore={hasMore}
              loader={<h4>Loading...</h4>}
              endMessage={
                <p style={{ textAlign: "center" }}>
                  <b>Yay! You have seen it all</b>
                </p>
              }
            >
              {posts.map((post, index) => (
                <div key={index} style={{ marginBottom: "1.5rem" }}>
                  <PostContainer post={post} />
                </div>
              ))}
            </InfiniteScroll>
          </div>
        </div>
        {isNonMobile1024 && (
          <div style={{ width: "28.9%" }}>
            {/* Additional content for non-mobile view */}
          </div>
        )}
      </div>
    </div>
  );
};

export default SocialMain;
