import Banner from "../../components/Company";
const CompanyRegister = () => {
  return (
    <>
      <div>
        <Banner />
      </div>
    </>
  );
};

export default CompanyRegister;
