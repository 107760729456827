import Cast from "../../assets/images/icons/Cast";
import Location from "../../assets/images/icons/Location";
import Calender from "../../assets/images/icons/Calender";
import Slider from "react-slick";
const Step1 = ({ type, setType, isNonMobile1000 }) => {
  const cards = [
    {
      title: "varyance Livestream",
      text: "Students join live event and can ask questions upfront.",
      icon: <Cast color={type === "varyance Livestream" ? "#E31b54" : ""} />,
    },
    {
      title: "Physical Event",
      text: "Students gain insights in an evaluated and time constrained challenge.",
      icon: <Location color={type === "Physical Event" ? "#E31b54" : ""} />,
    },
    {
      title: "Job Schedule",
      text: "Application opening or application closing.",
      icon: <Calender color={type === "Job Schedule" ? "#E31b54" : ""} />,
    },
  ];

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: true,
          arrows: false,
          centerMode: true,
          centerPadding: "8%",
        },
      },
    ],
  };

  return (
    <div className="">
      <p className="BasicInforMationHeading">Select Event Type</p>

      <p className="UnderBasicHeading">
        What kind of event are you planning to publish?
      </p>
      <div className="  " style={{ marginTop: "2.5rem" }}>
        {isNonMobile1000 ? (
          <div className="d-flex" style={{ gap: "1rem" }}>
            {cards.map((card, index) => (
              <div
                className={`rolecards  ${
                  type === card.title ? "selectedrolecard" : ""
                }`}
                key={index}
                style={{
                  maxWidth: "318px",
                  width: "32%",
                  padding: "20px",
                  position: "relative",
                  zIndex: 2,
                }}
                onClick={() => setType(card.title)}
              >
                {card.icon}
                <p
                  className={`rolecardsheading ${
                    type === card.title ? "recruiter-text" : "default-text"
                  }`}
                  style={{ marginTop: "1.25rem" }}
                >
                  {card.title}
                </p>
                <p
                  className={`rolecardstext mt-2 ${
                    type === card.title ? "recruiter-text" : "default-text"
                  }`}
                >
                  {card.text}
                </p>
              </div>
            ))}
          </div>
        ) : (
          <Slider {...settings}>
            <div className="d-flex w-full centerimportant">
              {cards.map((card, index) => (
                <div
                  className={`rolecards  ${
                    type === card.title ? "selectedrolecardmobile" : ""
                  }`}
                  style={{
                    maxWidth: "390px",
                    height: "210px",
                    padding: "29px 24px",
                  }}
                  onClick={() => setType(card.title)}
                >
                  {card.icon}
                  <p
                    className={`rolecardsheading mt-2 ${
                      type === card.title ? "recruiter-text" : "default-text"
                    }`}
                  >
                    {card.title}
                  </p>
                  <p
                    className={`rolecardstext mt-2 ${
                      type === card.title ? "recruiter-text" : "default-text"
                    }`}
                  >
                    {card.text}
                  </p>
                </div>
              ))}
            </div>
          </Slider>
        )}
      </div>
    </div>
  );
};
export default Step1;
