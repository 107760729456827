import React from "react";
import chart from "../../assets/images/icons/Chart.png";
import clip from "../../assets/images/icons/Clip.png";
import { useNavigate } from "react-router-dom";
const Right = () => {
  const items = [
    "On-Platform Application",
    "Analytics",
    "Link Challenge",
    "Shortlist",
    "Virtual Interviews",
  ];
  const navigate = useNavigate();
  return (
    <div className="CompanyDashboardChallengeRight ">
      <p className="learnmoretext ">Learn more</p>
      <p className=" belowLearnMoreText" style={{ marginTop: "1rem" }}>
        Build your <span>Talent Pool</span> on varyance.
      </p>
      <p className=" belowLearnMoreText" style={{ marginTop: "1.25rem" }}>
        Post upcoming jobs, internships and other work placements and invite
        ideal candidates to <span>on-platform interviews</span> or design job
        specific <span>Fast-Track Challenges</span> (skills evaluation) to find
        the right candidates.
      </p>
      <div
        className="d-flex flex-wrap"
        style={{ gap: "17px 14px", marginTop: "1.688rem" }}
      >
        {items &&
          items.map((item, index) => (
            <div
              className="newdashboardsidechips "
              key={index}
              style={{
                background: item === "Link Challenge" ? "#FFF5F6" : "",
                color: item === "Link Challenge" ? "#E31B54" : "",
                border: item === "Link Challenge" ? "0.75px solid #E31B54" : "",
                gap: "0.75rem",
              }}
            >
              {item === "Analytics" && (
                <img src={chart} alt="chart" height="16px" width="16px" />
              )}
              {item === "Link Challenge" && (
                <img src={clip} alt="chart" height="16px" width="16px" />
              )}
              <p className="namecountrya"> {item}</p>
            </div>
          ))}
      </div>
      <hr style={{ marginTop: "1.688rem", marginBottom: "1.688rem" }} />
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22Z"
            fill="white"
            stroke="#E31B54"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 16C14.21 16 16 14.21 16 12C16 9.79 14.21 8 12 8C9.79 8 8 9.79 8 12C8 14.21 9.79 16 12 16Z"
            fill="white"
            stroke="#E31B54"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21.17 8.00086C18.15 7.34086 15.02 7.34086 12 8.00086H21.17Z"
            fill="white"
          />
          <path
            d="M21.17 8.00086C18.15 7.34086 15.02 7.34086 12 8.00086"
            stroke="#E31B54"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3.94922 6.06055L3.96922 6.12055C4.97922 9.01055 6.52922 11.6905 8.53922 14.0005"
            fill="white"
          />
          <path
            d="M3.94922 6.06055L3.96922 6.12055C4.97922 9.01055 6.52922 11.6905 8.53922 14.0005"
            stroke="#E31B54"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.8789 21.94C12.9389 19.67 14.4889 16.99 15.4289 14.08L15.4589 14"
            fill="white"
          />
          <path
            d="M10.8789 21.94C12.9389 19.67 14.4889 16.99 15.4289 14.08L15.4589 14"
            stroke="#E31B54"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <div>
          <p style={{ marginTop: "0.5rem" }} className="letsStartTogether">
            What are the next steps?
          </p>
          <p style={{ marginTop: "0.25rem" }} className="feelfree">
            Feel free to contact us to work on your varyance recruiting
            pipeline.
          </p>
        </div>
        <button
          style={{ marginTop: "1rem", width: "150px" }}
          className="backbutton black14"
          onClick={() => {
            navigate("/support");
          }}
        >
          Contact us
        </button>
      </div>
    </div>
  );
};

export default Right;
