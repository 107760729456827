import TuneIcon from "@mui/icons-material/Tune";
import { useState, useEffect, useRef } from "react";
import "./filter.css";
import CloseIcon from "@mui/icons-material/Close";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import iso6391 from "iso-639-1";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import France from "../../assets/images/countries/France.png";
import Switzerland from "../../assets/images/countries/Switzerland.png";
import Germany from "../../assets/images/countries/Germany.png";
import UnitedKingdom from "../../assets/images/countries/United Kingdom.png";
const Filter = ({
  bulkAction,
  setBulkAction,
  isNonMob768,
  engageselect,
  setEngageSelect,
  locationfilter,
  setLocationFilter,
  languagefilter,
  setLanguageFilter,
  studyLevel,
  setStudyLevels,
  graduationFilter,
  setGraduationFilter,
  academicFilter,
  setAcademicFilter,
  setApplyFilter,
  handleBulkAction,
  searchFilter,
  setSearchFilter,
  TRUE,
}) => {
  const [filterPopup, setFilterPopup] = useState(false);
  const [engagement, setEngagement] = useState(false);
  const [study, setStudy] = useState(false);
  const [Language, setLanguage] = useState(false);
  const [studylevel, setStudyLevel] = useState(false);
  const [graduation, setGraduation] = useState(false);
  const [academic, setAcademic] = useState(false);
  const allLanguages = iso6391.getAllNames();
  const [popup, setPopUp] = useState(false);
  const filterRef = useRef(null);
  const iconRef = useRef();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });

    function handleClickOutside(event) {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setFilterPopup(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (iconRef.current && !iconRef.current.contains(event.target)) {
        setPopUp(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const Countries = [
    {
      icon: France,
      name: "France",
    },

    {
      icon: Germany,
      name: "Germany",
    },
    {
      icon: UnitedKingdom,
      name: "United Kingdom",
    },
    {
      icon: Switzerland,
      name: "Switzerland",
    },
  ];

  const bulkOptions = [TRUE ? "Shortlist" : false, "Remove"];

  return (
    <div className="d-flex flex-wrap space" style={{ gap: "1.5rem" }}>
      <div className="d-flex flex-wrap" style={{ gap: "1.5rem" }}>
        <div>
          {" "}
          <div
            className="forminput space"
            style={{ cursor: "pointer", width: "190px" }}
            onClick={() => setPopUp(!popup)}
          >
            {bulkAction ? bulkAction : "Select Bulk Action"}
            <KeyboardArrowDownIcon />
          </div>
          {popup && (
            <div
              className="w-100"
              style={{ position: "relative", zIndex: 2, marginTop: "2px" }}
              ref={iconRef}
            >
              <div className="w-100" style={{ position: "absolute" }}>
                <div
                  className="d-flex shadowa w-100"
                  style={{
                    flexDirection: "column",
                    background: "transparent",
                    boxShadow: "2px solid #000",
                    borderRadius: "5px",
                  }}
                >
                  {bulkOptions.map(
                    (option, index) =>
                      option && (
                        <button
                          className="d-flex popupbuttontext btn"
                          style={{
                            padding: "11px 1rem",
                            maxHeight: "2rem",
                            borderRadius:
                              index === 0
                                ? "5px 5px 0px 0px"
                                : index === bulkOptions.length - 1
                                ? "0px 0px 5px 5px"
                                : "0px",
                            gap: "10px",
                            border: "none",
                            borderTop:
                              index !== 0 ? "1px solid #DEE3E6" : "none",
                            background: "#FFF",

                            width: "100%",
                            minHeight: "42px",
                          }}
                          onClick={() => {
                            setBulkAction(option);
                            setPopUp(false);
                          }}
                        >
                          {option}
                        </button>
                      )
                  )}
                </div>
              </div>
            </div>
          )}
        </div>

        <button
          className="continuebutton"
          disabled={!bulkAction}
          onClick={() => handleBulkAction()}
        >
          Apply
        </button>
      </div>
      <div className="d-flex flex-wrap" style={{ gap: "1rem" }}>
        <div className="forminput">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M19 19L15.5001 15.5M18 9.5C18 14.1944 14.1944 18 9.5 18C4.80558 18 1 14.1944 1 9.5C1 4.80558 4.80558 1 9.5 1C14.1944 1 18 4.80558 18 9.5Z"
              stroke="#475467"
              strokeWidth="1.70603"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <input
            value={searchFilter}
            onChange={(e) => setSearchFilter(e.target.value)}
            type="search"
            placeholder="Search"
            className="searchinputuni"
          />
        </div>
        {/* <button
          className="newSupportButton"
          style={{ background: "transparent" }}
          onClick={() => setFilterPopup(!filterPopup)}
        >
          Filter
          <TuneIcon />
        </button>
        {filterPopup && (
          <div
            className="FilterTables shadowa"
            ref={filterRef}
            style={{
              position: "absolute",
              marginLeft: isNonMob768 ? "-5rem" : "0rem",
              marginTop: "1rem",
            }}
          >
            <div className="space" style={{ padding: "0.5rem 1.5rem" }}>
              <p className="black14 text-start">Filter</p>
              <CloseIcon
                onClick={() => setFilterPopup(false)}
                style={{ cursor: "pointer" }}
              />
            </div>
            <div
              className="tablescroll"
              style={{
                padding: "1rem",
                background: "#FFF",
                borderRadius: "5",
                width: "100%",
                maxHeight: "300px",
                overflow: "auto",
              }}
            >
              <button
                className="d-flex black14 space"
                style={{
                  padding: "11px 1rem",
                  maxHeight: "2rem",
                  borderRadius: "5px 5px 0px 0px",
                  gap: "10px",
                  background: "#FFF",
                  border: "none",
                  width: "100%",
                  minHeight: "42px",
                }}
                onClick={() => {
                  setEngagement(!engagement);
                }}
              >
                Engagement level{" "}
                {engagement ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </button>
              {engagement && (
                <select
                  className="forminput"
                  style={{
                    width: "85%",
                    marginLeft: "1rem",
                    marginBottom: "1.25rem",
                  }}
                  onChange={(e) => setEngageSelect(e.target.value)}
                  value={engageselect}
                >
                  <option disabled value="" className="formtext">
                    Select Level
                  </option>
                  <option value="Low" className="formtext">
                    Low
                  </option>
                  <option value="Medium" className="formtext">
                    Medium
                  </option>
                  <option value="High" className="formtext">
                    High
                  </option>
                  <option value="Your Fan" className="formtext">
                    Your Fan
                  </option>
                </select>
              )}
              <button
                className="d-flex black14 space"
                style={{
                  padding: "11px 1rem",
                  maxHeight: "2rem",
                  borderRadius: "0px 0px 5px 5px",
                  gap: "10px",
                  background: "#FFF",
                  border: "none",
                  width: "100%",
                  minHeight: "42px",
                  borderTop: "1px solid #d9d9d9",
                }}
                onClick={() => {
                  setStudy(!study);
                }}
              >
                Study Location
                {study ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </button>
              {study && (
                <select
                  className="forminput"
                  style={{
                    width: "85%",
                    marginLeft: "1rem",
                    marginBottom: "1.25rem",
                  }}
                  onChange={(e) => setLocationFilter(e.target.value)}
                  value={locationfilter}
                >
                  <option disabled value="" className="formtext">
                    Select Level
                  </option>
                  {Countries.map((country) => (
                    <option value={country.name} className="formtext">
                      {country.name}
                    </option>
                  ))}
                </select>
              )}
              <button
                className="d-flex black14 space"
                style={{
                  padding: "11px 1rem",
                  maxHeight: "2rem",
                  borderRadius: "0px 0px 5px 5px",
                  gap: "10px",
                  background: "#FFF",
                  border: "none",
                  width: "100%",
                  minHeight: "42px",
                  borderTop: "none",
                }}
                onClick={() => {
                  setLanguage(!Language);
                }}
              >
                Language Requirements
                {Language ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </button>
              {Language && (
                <select
                  className="forminput tablescroll"
                  style={{
                    width: "85%",
                    marginLeft: "1rem",
                    marginBottom: "1.25rem",
                    maxHeight: "12rem",
                  }}
                  onChange={(e) => setLanguageFilter(e.target.value)}
                  value={languagefilter}
                >
                  <option disabled value="" className="formtext">
                    Select Language
                  </option>
                  {allLanguages.map((country) => (
                    <option value={country} className="formtext">
                      {country}
                    </option>
                  ))}
                </select>
              )}
              <button
                className="d-flex black14 space"
                style={{
                  padding: "11px 1rem",
                  maxHeight: "2rem",
                  borderRadius: "0px 0px 5px 5px",
                  gap: "10px",
                  background: "#FFF",
                  border: "none",
                  width: "100%",
                  minHeight: "42px",
                  borderTop: "1px solid #d9d9d9",
                }}
                onClick={() => {
                  setStudyLevel(!studylevel);
                }}
              >
                Study level
                {studylevel ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </button>
              {studylevel && (
                <select
                  className="forminput"
                  style={{
                    width: "85%",
                    marginLeft: "1rem",
                    marginBottom: "1.25rem",
                  }}
                  onChange={(e) => setStudyLevels(e.target.value)}
                  value={studyLevel}
                >
                  <option value="" className="formtext">
                    All Levels
                  </option>
                  <option value="First Year" className="formtext">
                    First Year
                  </option>
                  <option value="2nd Year" className="formtext">
                    2nd Year
                  </option>
                  <option value="3rd Year" className="formtext">
                    3rd Year
                  </option>
                  <option value="Masters Program" className="formtext">
                    Masters Program
                  </option>
                  <option value="MBA" className="formtext">
                    MBA
                  </option>
                  <option value="State Examination" className="formtext">
                    State Examination{" "}
                  </option>
                  <option value="Doctorate" className="formtext">
                    Doctorate
                  </option>
                  <option value="Postdoctoral Studies" className="formtext">
                    Postdoctoral Studies
                  </option>
                </select>
              )}
              <button
                className="d-flex black14 space"
                style={{
                  padding: "11px 1rem",
                  maxHeight: "2rem",
                  borderRadius: "0px 0px 5px 5px",
                  gap: "10px",
                  background: "#FFF",
                  border: "none",
                  width: "100%",
                  minHeight: "42px",
                  borderTop: "none",
                }}
                onClick={() => {
                  setGraduation(!graduation);
                }}
              >
                Graduation Year
                {graduation ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </button>
              {graduation && (
                <select
                  className="forminput tablescroll"
                  style={{
                    width: "85%",
                    marginLeft: "1rem",
                    marginBottom: "1.25rem",
                    maxHeight: "12rem",
                  }}
                  onChange={(e) => setGraduationFilter(e.target.value)}
                  value={graduationFilter}
                >
                  <option disabled value="" className="formtext">
                    Select Year
                  </option>
                  {Array.from({ length: 31 }, (_, index) => (
                    <option value={2015 + index} className="formtext">
                      {2015 + index}
                    </option>
                  ))}
                </select>
              )}
              <button
                className="d-flex black14 space"
                style={{
                  padding: "11px 1rem",
                  maxHeight: "2rem",
                  borderRadius: "0px 0px 5px 5px",
                  gap: "10px",
                  background: "#FFF",
                  border: "none",
                  width: "100%",
                  minHeight: "42px",
                  borderTop: "none",
                }}
                onClick={() => {
                  setAcademic(!academic);
                }}
              >
                Academic Background
                {academic ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </button>
              {academic && (
                <select
                  className="forminput tablescroll"
                  style={{
                    width: "85%",
                    marginLeft: "1rem",
                    marginBottom: "1.25rem",
                    maxHeight: "12rem",
                  }}
                  onChange={(e) => setAcademicFilter(e.target.value)}
                  value={academicFilter}
                >
                  <option value="" className="formtext">
                    All Courses
                  </option>
                  <option value="Business & Finance" className="formtext">
                    Business & Finance
                  </option>
                  <option value="Social Science" className="formtext">
                    Social Science
                  </option>
                  <option value="Computer Science" className="formtext">
                    Computer Science
                  </option>
                  <option value="STEM" className="formtext">
                    STEM
                  </option>
                  <option value="Law" className="formtext">
                    Law
                  </option>
                  <option value="Humanities" className="formtext">
                    Humanities{" "}
                  </option>
                  <option value="Health Science" className="formtext">
                    Health Science
                  </option>
                </select>
              )}
            </div>
            <div
              className="space flex-wrap"
              style={{ marginTop: "1rem", marginBottom: "0.5rem" }}
            >
              <button
                className="backbutton"
                onClick={() => {
                  setEngageSelect("");
                  setLocationFilter("");
                  setLanguageFilter("");
                  setStudyLevels("");
                  setGraduationFilter("");
                  setAcademicFilter("");
                  setApplyFilter(true);
                }}
              >
                Reset
              </button>
              <button
                className="continuebutton"
                onClick={() => setApplyFilter(true)}
              >
                Apply
              </button>
            </div>
          </div>
        )} */}
      </div>
    </div>
  );
};
export default Filter;
