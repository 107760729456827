// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/images/sliderbackground.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.backgroundslidercover {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  padding: 1.875rem 1rem 1rem 1.5rem;
}
.whitefilmo18 {
  color: #fff;

  font-size: 18.967px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 23.709px */
}
.white10400 {
  color: var(--Gray-25, #fcfcfd);
  font-family: Poppins;
  font-size: 10.322px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 9.484px */
}
.newshadowa {
  box-shadow: 0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02);
}
`, "",{"version":3,"sources":["webpack://./src/pages/University/Toolkit/Main.css"],"names":[],"mappings":"AAAA;EACE,mDAA4D;EAC5D,kCAAkC;AACpC;AACA;EACE,WAAW;;EAEX,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,aAAa;AAClC;AACA;EACE,8BAA8B;EAC9B,oBAAoB;EACpB,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,YAAY;AACjC;AACA;EACE;;;kDAGgD;AAClD","sourcesContent":[".backgroundslidercover {\n  background: url(../../../assets/images/sliderbackground.png);\n  padding: 1.875rem 1rem 1rem 1.5rem;\n}\n.whitefilmo18 {\n  color: #fff;\n\n  font-size: 18.967px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 125%; /* 23.709px */\n}\n.white10400 {\n  color: var(--Gray-25, #fcfcfd);\n  font-family: Poppins;\n  font-size: 10.322px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: 150%; /* 9.484px */\n}\n.newshadowa {\n  box-shadow: 0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),\n    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),\n    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),\n    0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
