import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { setEmployer } from "../../state";
import { setCompany as set } from "../../state";
import AddIcon from "@mui/icons-material/Add";
import Cards from "./Cards";
import Smart from "./smartAnalysis";
import Analytics from "./Analytics";
import { toast } from "react-toastify";
import Table from "./Table";
import NewCards from "./NewCards";
import "./main.css";
import NewAnalytics from "./Analyticsnew";
import ChallengeRanking from "./ChallengeRanking";
import Published from "./PublisheJobs";

const DashboardMain = () => {
  const email = useSelector((state) => state.email);
  const isNonMob1024 = useMediaQuery("(min-width:1024px)");
  const isNonMob768 = useMediaQuery("(min-width:768px)");
  const isNonMob1440 = useMediaQuery("(min-width:1440px)");
  const isNonMob1441 = useMediaQuery("(min-width:1441px)");
  const [engaged, setEngaged] = useState(0);
  const [engagedData, setEngagedData] = useState([]);
  const [complete, setComplete] = useState(0);
  const [touch, setTouch] = useState(0);
  const [user, setUser] = useState([]);
  const [firstChal, setFirst] = useState(null);
  const dispatch = useDispatch();
  const [analytics, setAnalytics] = useState("12 months");
  const [testResults, setTestResults] = useState([]);
  const navigate = useNavigate();
  const step = useSelector((state) => state.step);
  const [company, setCompany] = useState();
  const id = useSelector((state) => state.uniqueId);
  const [challenges, setChallenges] = useState([]);
  const [jobposts, setJobposts] = useState([]);
  const [DATA, setData] = useState(false);
  const [blocked, setBlocked] = useState(null);
  const [shortListed, setShortListed] = useState([]);
  const [usersWithDetails, setUsersWithDetails] = useState([]);

  const [loading, setLoading] = useState(true);

  const handleShortList = async (ida) => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/company/shortlist/student`,
        {
          id: ida,
          companyId: company.uniqueId,
          msg: "sjdfhajksdfjkdashfjhasdjfhaksfasjk",
          type: "Talentpool",
          owner: user._id,
        }
      )
      .then((res) => {
        if (res.data.msg === "success") {
          toast.success("Added to Shortlist");
          setData(!DATA);
        }
      });
  };

  const handleRemove = async (id) => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/company/blocked/student`,
        {
          id: id,
          companyId: company.uniqueId,
          msg: "sjdfhajksdfjkdashfjhasdjfhaksfasjk",
        }
      )
      .then((res) => {
        if (res.data.msg === "success") {
          toast.success("Removed");
          setData(true);
        }
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const userInfoResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/getUserInfo/uniqueId`,
          { email: id }
        );

        if (userInfoResponse.data.message === "Found") {
          setUser(userInfoResponse.data.user);
          dispatch(setEmployer({ employer: userInfoResponse.data.user }));
        } else {
          navigate("/company/register");
          return;
        }

        const companyInfoResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/getCompanyInfo`,
          { id: userInfoResponse.data.user.company }
        );
        if (companyInfoResponse.data.msg === "Logout") {
          navigate("/company/register");
          return;
        }

        setCompany(companyInfoResponse.data);

        const resultres = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/get/challenges/results`,
          {
            uniqueId: companyInfoResponse.data.uniqueId,
          }
        );
        if (resultres.data.msg === "success") {
          const sortedResults = resultres.data.results.sort(
            (a, b) => b.percentage - a.percentage
          );
          setTestResults(sortedResults);
        }

        dispatch(set({ company: companyInfoResponse.data.uniqueId }));
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/get/company/challenges`,
          {
            uniqueId: companyInfoResponse.data.uniqueId,
          }
        );
        if (response.data.msg === "success") {
          const sortedChallenges = response.data.challenges.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );

          setChallenges(sortedChallenges);

          const firstNonDraftChallenge =
            sortedChallenges.find((challenge) => !challenge.draft) || null;
          setFirst(firstNonDraftChallenge);
        } else if (response.data.msg === "Record not found") {
        } else {
          toast.error("Error Fetching Challenges");
        }
        setLoading(false);
        const responsea = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/get/company/jobs`,
          {
            uniqueId: companyInfoResponse.data.uniqueId,
          }
        );

        const shortlistT = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/company/get/shortlist/student`,
          {
            companyId: companyInfoResponse.data.uniqueId,
            msg: "sdakfksjfbjkasfjkfdas",
            type: "Talentpool",
            owner: userInfoResponse.data.user._id,
          }
        );
        if (shortlistT.data.msg === "success") {
          setShortListed((prev) => [...shortlistT.data.list]);
        }

        const blocked = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/company/get/blocked/student`,
          {
            companyId: companyInfoResponse.data.uniqueId,
            msg: "sdakfksjfbjkasfjkfdas",
          }
        );
        if (blocked.data.msg === "success") {
          setBlocked(blocked.data.list);
        }
        if (responsea.data.msg === "success") {
          const sortedChallenges = responsea.data.challenges.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );

          setJobposts(sortedChallenges);
        } else if (responsea.data.msg === "Record not found") {
          setJobposts([]);
        } else {
          setJobposts([]);
          toast.error("Error Fetching Job posts");
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [email, DATA]);

  useEffect(() => {
    const fetchData = async () => {
      const users = []; // Initialize an empty array to store unique users
      const userSet = new Set();

      if (engagedData?.length > 0) {
        engagedData.forEach((count) => {
          if (!userSet.has(count)) {
            // Check if the user ID is not already in the Set
            userSet.add(count); // Add the user ID to the Set
            users.push(count); // Add the unique user ID to the users array
          }
        });

        const results = await Promise.all(
          users.map(async (user) => {
            try {
              const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/get/user/details`,
                {
                  userId: user,
                  msg: "fjksabnfjkbnasjfbajsbfjasjhfbjahsbjkfbk",
                }
              );

              return {
                userId: user,
                userDetails: response.data,
              };
            } catch (error) {
              console.error("Error fetching user details:", error);
              return null;
            }
          })
        );

        setUsersWithDetails(results);
      }
      // setUsersWithDetails(results.filter(Boolean));
      // setUsersWithDetails(
      //   Array(100)
      //     .fill()
      //     .flatMap(() => results.filter(Boolean))
      // );
    };
    if (firstChal) {
      fetchData();
    }
  }, [challenges, firstChal, engagedData]);

  const processDateData = (dates, currentDay) => {
    const currentMonth = new Date().getMonth() + 1;
    const data = Array.from({ length: currentDay }, (_, index) => {
      const day = index + 1;

      const count = dates.filter(
        (date) =>
          new Date(date.date).getDate() === day &&
          new Date(date.date).getMonth() + 1 === currentMonth
      ).length;
      return count;
    });

    return data;
  };

  useEffect(() => {
    if (challenges.length > 0 || jobposts.length > 0) {
      let totalClicks = 0;
      let totalImpressions = 0;
      let totalCount = 0;

      const ids = new Set(); // Use Set for unique IDs

      const today = new Date();
      const currentDay = today.getDate();
      const check = [...challenges, ...jobposts] || [];

      const impressionDates = check.reduce((allDates, item) => {
        return [...allDates, ...item.impressionDates];
      }, []);

      const clickDates = check.reduce((allDates, item) => {
        return [...allDates, ...item.clickDates];
      }, []);

      const data = processDateData(
        [...impressionDates, ...clickDates],
        currentDay
      );

      const endDate = new Date();
      const startDate = new Date();
      startDate.setDate(
        startDate.getDate() -
          (analytics === "7 days" ? 7 : analytics === "30 days" ? 30 : 365)
      );

      challenges.forEach((challenge) => {
        const filteredClicks = challenge.clickDates
          .filter((date) => new Date(date.date) >= startDate)
          .map((click) => click.userId)
          .filter(Boolean); // Filter out undefined values

        totalClicks += filteredClicks.length;
        filteredClicks.forEach((id) => ids.add(id)); // Add unique IDs to Set

        const filteredImpressions = challenge.impressionDates
          .filter((date) => new Date(date.date) >= startDate)
          .map((impression) => impression.userId)
          .filter(Boolean); // Filter out undefined values

        totalImpressions += filteredImpressions.length;

        const filteredCount = challenge.countDates
          .filter((date) => new Date(date.date) >= startDate)
          .map((count) => count.userId)
          .filter(Boolean); // Filter out undefined values

        totalCount += filteredCount.length;
        filteredCount.forEach((id) => ids.add(id)); // Add unique IDs to Set
      });

      jobposts.forEach((jobpost) => {
        const filteredClicks = jobpost.clickDates
          .filter((date) => new Date(date.date) >= startDate)
          .map((click) => click.userId)
          .filter(Boolean); // Filter out undefined values

        totalClicks += filteredClicks.length;
        filteredClicks.forEach((id) => ids.add(id)); // Add unique IDs to Set

        const filteredImpressions = jobpost.impressionDates
          .filter((date) => new Date(date.date) >= startDate)
          .map((impression) => impression.userId)
          .filter(Boolean); // Filter out undefined values

        totalImpressions += filteredImpressions.length;
      });

      const uniqueIds = Array.from(ids); // Convert Set to Array

      setEngagedData(uniqueIds); // Set unique IDs

      setEngaged(uniqueIds.length);
      // Set count of unique IDs
      setTouch(totalImpressions + totalClicks);
      setComplete(totalCount);
    } else {
      // setEngaged(0);
      setTouch(0);
      setComplete(0);
    }
  }, [challenges, jobposts, analytics]);

  return (
    <div className="paddingmaincontent">
      <div
        className="space flex-wrap align-items-center"
        style={{ gap: "1rem" }}
      >
        <p className="registeration">
          Hi
          {user && (
            <>
              , {user.firstName} {user?.lastName}
            </>
          )}
        </p>
        <button
          className="continuebutton"
          onClick={() => {
            navigate("/dashboard/Team");
          }}
        >
          <AddIcon style={{ height: "20px", width: "20px" }} />
          Invite Team
        </button>
      </div>
      <div style={{ marginTop: "1.5rem" }}>
        <NewCards
          navigate={navigate}
          challenge={firstChal}
          loading={loading}
          engaged={engaged}
          complete={complete}
          touch={touch}
          isNonMob1024={isNonMob1024}
          shortlisted={shortListed.reduce(
            (total, user) => total + (user?.list?.length || 0),
            0
          )}
          usersWithDetails={usersWithDetails}
        />
      </div>
      <div
        className={`d-flex ${!isNonMob1024 && "flex-column"}`}
        style={{ marginTop: "1.875rem", gap: "1.5rem" }}
      >
        <div className="" style={{ width: isNonMob1024 ? "31.9%" : "100%" }}>
          <div
            className="selectedEvent-inner shadowa"
            style={{ minHeight: "368px" }}
          >
            <NewAnalytics
              usersWithDetails={usersWithDetails}
              engagedData={engagedData}
              loading={loading}
            />
          </div>
        </div>
        <div className="" style={{ width: isNonMob1024 ? "31.9%" : "100%" }}>
          <div
            className="selectedEvent-inner shadowa"
            style={{ padding: "0rem", paddingTop: "1rem", minHeight: "368px" }}
          >
            <ChallengeRanking
              navigate={navigate}
              testResults={testResults}
              usersWithDetails={usersWithDetails}
              loading={loading}
            />
          </div>
        </div>
        <div className="" style={{ width: isNonMob1024 ? "31.9%" : "100%" }}>
          <div
            className="selectedEvent-inner shadowa"
            style={{ padding: "0rem", paddingTop: "1rem", minHeight: "368px" }}
          >
            <Published
              jobposts={jobposts}
              loading={loading}
              navigate={navigate}
            />
          </div>
        </div>
      </div>
      {/* <div style={{ padding: "1.775rem 1.875rem 0rem 1.875rem" }}>
        <div>
          <Cards
            navigate={navigate}
            isNonMob1440={isNonMob1440}
            isNonMob1024={isNonMob1024}
          />
        </div>
        <div style={{ marginTop: "3.75rem" }}>
          <Smart
            text={"Smart Analytics"}
            analytics={analytics}
            setAnalytics={setAnalytics}
          />
          <div style={{ marginTop: "1.875rem" }}>
            <Analytics
              isNonMob1441={isNonMob1441}
              isNonMob1024={isNonMob1024}
              challenges={challenges}
              jobposts={jobposts}
              analytics={analytics}
              setEngaged={setEngaged}
              engaged={engaged}
              setComplete={setComplete}
              complete={complete}
              setTouch={setTouch}
              touch={touch}
            />
          </div>
        </div>
      </div>
      <div style={{ margin: "2.5rem 0rem" }}>
        <Table
          challenges={challenges}
          jobposts={jobposts}
          handleShortList={handleShortList}
          handleRemove={handleRemove}
          blocked={blocked}
          shortListed={shortListed}
        />
      </div> */}
    </div>
  );
};
export default DashboardMain;
