import logo from "../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { PiChatTextLight } from "react-icons/pi";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";

import "./support.css";
import { useState } from "react";
const Support = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div className="backgroundimg">
        <div className="marginsleftrightCompany">
          <div className="d-flex" style={{ justifyContent: "center" }}>
            <div
              className="headerCompany space"
              style={{ marginTop: "2.5rem" }}
            >
              <img
                src={logo}
                alt="logo"
                height="36px"
                width="36px"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/dashboard");
                }}
              />
              <CloseIcon
                className="hoverdarkblacksvg"
                style={{ cursor: "pointer" }}
                onClick={() => navigate(-1)}
              />
            </div>
          </div>
        </div>
        <div className="marginsleftrightCompany">
          <div className="d-flex" style={{ justifyContent: "center" }}>
            <div
              className="headerCompany "
              style={{ marginTop: "clamp(2.5rem,10vw,6.25rem)" }}
            >
              <p className="pink16" style={{ fontWeight: "600" }}>
                Support
              </p>
              <p className="registeration" style={{ marginTop: "1rem" }}>
                We’d love to hear from you
              </p>
              <div
                className="d-flex flex-wrap"
                style={{ gap: "1.5rem", marginTop: "2.5rem" }}
              >
                <div
                  className="supportseconddiv shadowa"
                  onClick={() =>
                    (window.location.href = "mailto:employers@varyance.io")
                  }
                >
                  <PiChatTextLight className="testingicon" size={32} />
                  <p
                    className="black16 text-start"
                    style={{ fontWeight: "600", marginTop: "2rem" }}
                  >
                    Email
                  </p>
                  <p
                    className="black14"
                    style={{ marginTop: "0.5rem", fontWeight: "400" }}
                  >
                    We’re here to help.
                  </p>
                  <p
                    className="pink16"
                    style={{
                      marginTop: "1.25rem",
                      fontWeight: "600",
                    }}
                  >
                    employers@varyance.io
                  </p>
                </div>
                <div
                  className="supportseconddiv shadowa"
                  onClick={() => {
                    window.location.href =
                      "https://varyance.io/employer-hub/knowledge-base";
                  }}
                  style={{ minWidth: "190px" }}
                >
                  <ArticleOutlinedIcon className="testingicon" size={32} />
                  <p
                    className="black16 text-start"
                    style={{ fontWeight: "600", marginTop: "2rem" }}
                  >
                    Knowledge Base
                  </p>
                  <p
                    className="black14"
                    style={{ marginTop: "0.5rem", fontWeight: "400" }}
                  >
                    Find the right ressources.
                  </p>
                  <p
                    className="pink16"
                    style={{ marginTop: "1.25rem", fontWeight: "600" }}
                  >
                    Learn more
                  </p>
                </div>
                <div
                  className="supportseconddiv shadowa"
                  style={{ minWidth: "190px" }}
                >
                  <LocalPhoneOutlinedIcon className="testingicon" size={32} />
                  <p
                    className="black16 text-start"
                    style={{ fontWeight: "600", marginTop: "2rem" }}
                  >
                    Call us
                  </p>
                  <p
                    className="black14"
                    style={{ marginTop: "0.5rem", fontWeight: "400" }}
                  >
                    Mon-Fri, 9am - 5pm GMT
                  </p>
                  <p
                    className="pink16"
                    style={{ marginTop: "1.25rem", fontWeight: "600" }}
                  >
                    <a
                      href="tel:00447495652796"
                      className="pink16"
                      style={{ color: "inherit", textDecoration: "none" }}
                    >
                      0044 – 749 565 2796
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Support;
