import React from "react";
import whiteLogo from "../../assets/images/whiteLogo.png";
import { useMediaQuery } from "@mui/material";
const Card4 = () => {
  const isNonMobile450 = useMediaQuery("(min-width:450px)");
  return (
    <div
      className="companyCard4 rounded-3 shadowa pb-4"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div
        className="card1paddings d-flex  "
        style={{
          height: "100%",
          justifyContent: "space-between",
          flexDirection: "column",
          paddingBottom: "1rem",
        }}
      >
        <div>
          <img
            src={whiteLogo}
            alt="whitelogo"
            className="rounded-3 shadowa"
            height="45px"
            width="45px"
          />

          <p className="card4text">
            Let’s talk or simply register to create your first challenge.
          </p>
        </div>
      </div>

      <div className=" card1paddings d-flex" style={{ gap: "1rem" }}>
        <button className="letstalkbutton">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <circle cx="8.64237" cy="9.14286" r="3.14286" fill="#17D74D" />
          </svg>
          Let's Talk!
        </button>
        <button
          className="backbutton"
          style={{ color: "#E31B54", padding: "8px 20px" }}
        >
          Register
        </button>
      </div>
    </div>
  );
};

export default Card4;
