import Marquee from "react-fast-marquee";
import slider1 from "../../../assets/images/slider11.png";
import slider2 from "../../../assets/images/slider12.png";
import slider3 from "../../../assets/images/slider13.png";
import logo from "../../../assets/images/whitelogo2.png";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const Third = ({ navigate }) => {
  const texts = [
    {
      text: "Google Slides",
      color: "#079455",
      background: "rgba(7, 148, 85, 0.10)",
    },
    {
      text: "PowerPoint",
      color: "#0077B6",
      background: "rgba(0, 119, 182, 0.10)",
    },
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M8.97542 9.26818C8.97542 8.65698 8.47995 8.16151 7.86876 8.16151C7.25756 8.16151 6.76209 8.65698 6.76209 9.26818C6.76118 9.5273 6.85346 9.7781 7.02209 9.97484C7.19201 10.1519 7.25091 10.408 7.17542 10.6415L7.03542 11.0548C6.98128 11.2407 7.01707 11.4412 7.13218 11.5969C7.24729 11.7525 7.42851 11.8455 7.62209 11.8482H8.11542C8.31542 11.8481 8.5031 11.7516 8.61947 11.5889C8.73584 11.4263 8.7666 11.2175 8.70209 11.0282L8.53542 10.6148C8.45994 10.3813 8.51884 10.1252 8.68876 9.94818C8.86119 9.7622 8.96267 9.52147 8.97542 9.26818Z"
            fill="#FC7E00"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.79542 5.18151C10.7218 5.14478 11.6174 5.51758 12.2441 6.20079C12.8708 6.884 13.1651 7.80839 13.0488 8.72818L12.4154 12.5748C12.1106 14.1202 10.7367 15.221 9.16209 15.1815H6.57542C5.01201 15.2163 3.6478 14.1271 3.33542 12.5948L2.70209 8.74818C2.48124 7.30084 3.3211 5.90107 4.70209 5.41484V3.33484C4.70173 2.79146 4.92248 2.27131 5.31356 1.89406C5.70465 1.5168 6.2324 1.31492 6.77542 1.33484H8.96209C9.50511 1.31492 10.0329 1.5168 10.424 1.89406C10.815 2.27131 11.0358 2.79146 11.0354 3.33484C11.0354 3.61099 10.8116 3.83484 10.5354 3.83484C10.2593 3.83484 10.0354 3.61099 10.0354 3.33484C10.0102 2.7666 9.53045 2.3256 8.96209 2.34818H6.77542C6.20707 2.3256 5.72733 2.7666 5.70209 3.33484V5.18151H9.79542ZM11.2021 12.3748L11.8688 8.51484C11.9349 8.01547 11.7762 7.51248 11.4354 7.14151C11.021 6.67155 10.4219 6.40611 9.79542 6.41484H5.94209C5.31769 6.40274 4.71897 6.66316 4.30209 7.12818C3.96944 7.50806 3.82287 8.01615 3.90209 8.51484L4.53542 12.3748C4.74212 13.3257 5.60322 13.9898 6.57542 13.9482H9.16209C10.1343 13.9898 10.9954 13.3257 11.2021 12.3748Z"
            fill="#FC7E00"
          />
        </svg>
      ),
      text: "Customizable",
      color: "#FC7E00",
      background: "rgba(252, 126, 0, 0.10)",
    },
  ];

  const slides = [
    {
      img: slider1,
      title: "Student Onboarding",
      version: 3,
      date: "June, 2024",
      link: "Introduction to varyance for students (all faculties)",
    },
    {
      img: slider2,
      title: "MBA Class Onboarding",
      version: 1,
      date: "August, 2024",
      link: "Introduction to varyance for MBA Students",
    },
    {
      img: slider3,
      title: "varyance.io Faculty Desk",
      version: 2,
      date: "June, 2024",
      link: "Introduction to varyance for university staff",
    },
  ];

  return (
    <div>
      <div className="marginsleftrightCompany">
        <div className="center">
          <div className="headerCompany">
            <p className="gray80016500">Toolkit 1</p>
            <p
              className="newheadingtextthirdsection text-start"
              style={{ margin: "1.875rem 0rem 1rem 0rem" }}
            >
              Presentations
            </p>
            <p
              className="gray95016500"
              style={{ maxWidth: "496px", fontWeight: 400 }}
            >
              Here you find presentations for students, faculty, and staff,
              designed to introduce varyance and highlight its key benefits.
            </p>
            <p
              className="gray95016500"
              style={{ fontWeight: 400, maxWidth: "700px", marginTop: "2rem" }}
            >
              Add your university's branding and tailor the content to engage
              your audience.
            </p>
            <div
              className="d-flex flex-wrap"
              style={{ marginTop: "1.875rem", gap: "1.25rem" }}
            >
              {texts.map((txt, index) => (
                <div
                  key={index}
                  className="newCompanyChips border-0"
                  style={{ color: txt.color, background: txt.background }}
                >
                  {txt?.icon}
                  {txt.text}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "4.375rem" }}>
        <Marquee pauseOnHover>
          <div className="d-flex " style={{}}>
            {slides.map((slide, index) => (
              <div key={index} style={{ marginRight: "2.5rem" }}>
                <div className="d-flex newshadowa">
                  <div
                    className="backgroundslidercover space flex-column"
                    style={{
                      width: "245px",
                      height: "300px",
                    }}
                  >
                    <div
                      className="d-flex align-items-center"
                      style={{ gap: "0.25rem" }}
                    >
                      <img
                        src={logo}
                        alt="white logo"
                        style={{
                          height: "12px",
                          width: "12px",
                          objectFit: "contain",
                        }}
                      />
                      <p
                        style={{
                          fontSize: "10px",
                          fontWeight: 400,
                          color: "#FFF",
                        }}
                      >
                        varyance
                      </p>
                    </div>
                    <div>
                      <p style={{ maxWidth: "135px" }} className="whitefilmo18">
                        {slide.title}
                      </p>
                    </div>
                    <div>
                      <p className="white10400" style={{ fontSize: "6px" }}>
                        Version {slide.version}
                      </p>
                      <p className="white10400">{slide.date}</p>
                    </div>
                  </div>
                  <img
                    src={slide.img}
                    alt="slider image"
                    style={{
                      height: "300px",
                      width: "245px",
                      objectFit: "contain",
                    }}
                  />
                </div>
                <div
                  className="space align-items-center"
                  style={{ marginTop: "1.5rem", cursor: "pointer" }}
                  onClick={() => {
                    navigate("/career-center/contact");
                  }}
                >
                  <p style={{ maxWidth: "209px" }} className="gray95016500">
                    {slide.link}
                  </p>
                  <ArrowForwardIosIcon
                    style={{ height: "24px", width: "24px", color: "#E31b54" }}
                  />
                </div>
              </div>
            ))}
          </div>
        </Marquee>
      </div>
      <hr style={{ margin: "5.625rem 0rem" }} />
    </div>
  );
};
export default Third;
