import React, { useState, useEffect, useRef } from "react";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import CheckBoxOutlineBlankRoundedIcon from "@mui/icons-material/CheckBoxOutlineBlankRounded";
import "./resulttable.css";
import EmptySpace from "../../../assets/images/EmptyState.svg";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Filter from "./Filter";
import SelectedBox from "../../../assets/images/icons/selectedbox";
import UnselectedBox from "../../../assets/images/icons/unselectedBox";
const ResultTable = ({
  results,
  livechallengesLength,
  selectedStudents,
  setSelectedStudent,
  DATA,
  setData,
  company,
  user,
  TRUE,
  challenges,
  bulkAction,
  setBulkAction,
  bulkOptions,
  handleBulkAction,
  searchFilter,
  setSearchFilter,
}) => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const entriesPerPage = 5;
  const [challenge, setChallenge] = useState(results);
  const [showIcon, setShowIcon] = useState(false);
  const [Index, setIndex] = useState(-1);
  const [oldData, setOldData] = useState([]);

  let indexOfLastEntry = currentPage * entriesPerPage;
  let indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const [currentEntries, setCurrentEntries] = useState(
    challenge.slice(indexOfFirstEntry, indexOfLastEntry)
  );

  let totalEntries = challenge.length;
  let totalPages = Math.ceil(totalEntries / entriesPerPage);

  useEffect(() => {
    let filteredData = [...oldData];
    console.log(filteredData);
    if (searchFilter) {
      filteredData = filteredData.filter((entry) =>
        `${entry?.user?.firstName} ${entry?.user?.LastName}`
          .toLowerCase()
          .includes(searchFilter.toLowerCase())
      );
      setChallenge(filteredData);
    } else {
      setChallenge(oldData);
    }

    setCurrentPage(1);
  }, [searchFilter]);

  useEffect(() => {
    setChallenge(results);
    setOldData(results);
    indexOfLastEntry = currentPage * entriesPerPage;
    indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
    setCurrentEntries(challenge.slice(indexOfFirstEntry, indexOfLastEntry));
    totalEntries = challenge.length;
    totalPages = Math.ceil(totalEntries / entriesPerPage);
  }, [results]);

  const handleShortList = async (ida) => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/company/shortlist/student`,
        {
          id: ida,
          companyId: company.uniqueId,
          msg: "sjdfhajksdfjkdashfjhasdjfhaksfasjk",
          type: "Challenge",
          owner: user?._id,
        }
      )
      .then((res) => {
        if (res.data.msg === "success") {
          toast.success("Added to Shortlist");
          setData((prev) => !prev);
        }
      });
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    setCurrentEntries(challenge.slice(indexOfFirstEntry, indexOfLastEntry));
  }, [currentPage, challenge, indexOfFirstEntry, indexOfLastEntry]);

  const handleSort = () => {
    setChallenge(challenge.reverse());
    setOldData(challenge.reverse());
    setCurrentEntries(challenge.slice(indexOfFirstEntry, indexOfLastEntry));
  };

  const handleIconClick = (id) => {
    setSelectedStudent((prevSelectedStudents) => {
      if (prevSelectedStudents.includes(id)) {
        return prevSelectedStudents.filter((studentId) => studentId !== id);
      } else {
        return [...prevSelectedStudents, id];
      }
    });
  };

  const iconRef = useRef(null);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });

    function handleClickOutside(event) {
      if (iconRef.current && !iconRef.current.contains(event.target)) {
        setShowIcon(false);
        setIndex(-1);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleRemove = async (ida) => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/company/blocked/student`,
        {
          id: ida,
          companyId: company.uniqueId,
          msg: "sjdfhajksdfjkdashfjhasdjfhaksfasjk",
          type: "Challenge",
          owner: user?._id,
        }
      )
      .then((res) => {
        if (res.data.msg === "success") {
          toast.success("Removed");
          setData((prev) => !prev);
        }
      });
  };

  const handleRemoveShortlist = async (ida) => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/company/remove/shortlist/student`,
        {
          id: ida,
          companyId: company.uniqueId,
          msg: "sjdfhajksdfjkdashfjhasdjfhaksfasjk",
          type: "Challenge",
          owner: user?._id,
        }
      )
      .then((res) => {
        if (res.data.msg === "success") {
          toast.success("Removed");
          setData((prev) => !prev);
        }
      });
  };

  const renderPageButtons = () => {
    const maxButtons = 5; // Maximum number of buttons to display
    const buttons = [];

    // Calculate the range of buttons to display around the current page
    let startPage = Math.max(1, currentPage - Math.floor(maxButtons / 2));
    let endPage = Math.min(totalPages, startPage + maxButtons - 1);

    // Adjust startPage and endPage if there are not enough buttons
    if (endPage - startPage + 1 < maxButtons) {
      if (currentPage <= Math.floor(maxButtons / 2) + 1) {
        endPage = Math.min(totalPages, maxButtons);
      } else {
        startPage = Math.max(1, totalPages - maxButtons + 1);
      }
    }

    // Render the buttons in the calculated range
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          key={i}
          style={
            i === currentPage
              ? { backgroundColor: "#E31B54", color: "#FFF" }
              : { color: "#000", backgroundColor: "transparent" } // Adjust colors as needed
          }
          onClick={() => handlePageChange(i)}
          className={`btn normalbuttons ${i === currentPage && " rounded-3"}`}
        >
          {i}
        </button>
      );
    }

    return buttons;
  };

  const checkChallenges = (user) => {
    let count = 0;

    if (challenges?.length > 0) {
      challenges.forEach((challenge) => {
        if (challenge?.countDates?.some((res) => res.userId === user?._id)) {
          return count++;
        }
      });
    }

    return `${count} / ${livechallengesLength}`;
  };

  return (
    <div>
      <Filter
        bulkAction={bulkAction}
        setBulkAction={setBulkAction}
        bulkOptions={bulkOptions}
        handleBulkAction={handleBulkAction}
        searchFilter={searchFilter}
        setSearchFilter={setSearchFilter}
      />
      <div
        className="tablescroll maindashboardtopcards"
        style={{ marginTop: "0.75rem", overflowX: "auto" }}
      >
        <table
          className="tableofchallenges"
          style={{ width: "100%", overflowX: "scroll" }}
        >
          <thead className="thead">
            <tr className="theadtr">
              <th className="theadth ">
                Name{" "}
                {/* <div
                  className="btn"
                  //  onClick={() => handleSort()}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M21.8413 13.01L17.2013 8.36C16.8989 8.08169 16.4337 8.08169 16.1313 8.36L11.5213 13.01C11.3332 13.3001 11.3678 13.6812 11.6051 13.9327C11.8423 14.1842 12.2207 14.2409 12.5213 14.07L15.7413 10.85V19.75C15.7413 20.1642 16.0771 20.5 16.4913 20.5C16.9055 20.5 17.2413 20.1642 17.2413 19.75V10.56L20.7513 14.07C21.0441 14.3625 21.5185 14.3625 21.8113 14.07C22.1122 13.7855 22.1256 13.311 21.8413 13.01Z"
                      fill="#475467"
                    />
                    <path
                      d="M11.5213 9.22C11.2285 8.92755 10.7541 8.92755 10.4613 9.22L7.27129 12.44V3.75C7.27129 3.33579 6.93551 3 6.52129 3C6.10708 3 5.77129 3.33579 5.77129 3.75V12.75L2.26129 9.22C1.96578 8.94464 1.50528 8.95277 1.21967 9.23838C0.934059 9.52399 0.925934 9.98449 1.20129 10.28L5.84129 14.93C6.13753 15.2236 6.61505 15.2236 6.91129 14.93L11.5213 10.28C11.8137 9.98718 11.8137 9.51282 11.5213 9.22Z"
                      fill="#475467"
                    />
                  </svg>
                </div> */}
              </th>
              <th className="theadth"> University</th>
              <th className="theadth ">Course</th>
              <th className="theadth ">Location</th>
              <th className="theadth ">Completed Challenges</th>

              <th className="theadth text-center">Action</th>
            </tr>
          </thead>
          <tbody className="tbody">
            {currentEntries && currentEntries.length > 0 && (
              <>
                {currentEntries.map((challenge, index) => (
                  <tr
                    key={index}
                    className="tbodytr"
                    style={
                      {
                        // border: index + 1 === currentEntries.length && "none",
                      }
                    }
                  >
                    <td className="tbodytrtd">
                      <div className="d-flex" style={{ gap: "0.75rem" }}>
                        <div
                          className="center"
                          style={{ alignItems: "center", cursor: "pointer" }}
                          onClick={() => handleIconClick(challenge?.user?._id)}
                        >
                          {selectedStudents.includes(challenge?.user?._id) ? (
                            <SelectedBox style={{ color: "#E31b54" }} />
                          ) : (
                            <UnselectedBox
                              style={{ color: "#475467", cursor: "pointer" }}
                            />
                          )}
                        </div>
                        <div className="d-flex" style={{ gap: "11px" }}>
                          <img
                            src={challenge?.user?.profilePhoto}
                            alt="user image"
                            height="48px"
                            className="shadowa"
                            style={{ borderRadius: "125px" }}
                            width="48px"
                          />
                          <div>
                            <p
                              className="resultuserName hoverred"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                navigate(
                                  `/dashboard/user/${challenge?.user?.uniqueId}`
                                )
                              }
                            >
                              {challenge?.user?.firstName}{" "}
                              {challenge?.user?.LastName}
                            </p>
                            <p className="resultintaketable">
                              Intake {challenge?.user?.intake?.Year}
                            </p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="tbodytrtd">
                      <p
                        className="resultuniname"
                        style={{ maxWidth: "14.375rem" }}
                      >
                        {challenge?.user?.uni?.name}
                      </p>
                    </td>

                    <td className="tbodytrtd ">
                      <p
                        className="resultuniname"
                        style={{ maxWidth: "14.375rem", fontWeight: "400" }}
                      >
                        {" "}
                        {challenge?.user?.levelofstudy} of{" "}
                        {challenge?.user?.study}
                      </p>
                    </td>
                    <td className="tbodytrtd ">
                      <div className="countryholder center">
                        {challenge?.user?.uni?.area}
                      </div>
                    </td>
                    <td className="tbodytrtd ">
                      {checkChallenges(challenge?.user)}
                    </td>
                    <td className="textofthecount tbodytrtd ">
                      <div className="center" style={{}}>
                        <div style={{ position: "absolute" }}>
                          {showIcon && Index === index && (
                            <div
                              style={{
                                position: "absolute",
                                background: "transparent",
                                zIndex: 10,

                                marginLeft: "-140px",
                              }}
                              ref={iconRef}
                            >
                              <div
                                className="d-flex shadowa"
                                style={{
                                  flexDirection: "column",
                                  background: "transparent",
                                }}
                              >
                                <button
                                  className="d-flex popuptext"
                                  style={{
                                    padding: "11px 1rem",
                                    maxHeight: "2rem",
                                    borderRadius: "5px 5px 0px 0px",
                                    gap: "10px",
                                    background: "#FFF",
                                    border: "none",
                                    width: "160px",
                                    minHeight: "42px",
                                  }}
                                  onClick={() => {
                                    setShowIcon(false);

                                    navigate(
                                      `/dashboard/chat?id=${challenge?.user?.uniqueId}`
                                    );

                                    setIndex(-1);
                                  }}
                                >
                                  Message
                                </button>
                                {TRUE && (
                                  <button
                                    className="d-flex popuptext"
                                    style={{
                                      padding: "11px 1rem",
                                      maxHeight: "2rem",
                                      borderRadius: "0px 0px 5px 5px",
                                      gap: "10px",
                                      background: "#FFF",
                                      border: "none",
                                      width: "160px",
                                      minHeight: "42px",
                                      borderTop: "1px solid #d9d9d9",
                                    }}
                                    onClick={() => {
                                      // setNewTasks((prevTasks) => {
                                      //   return prevTasks.filter(
                                      //     (task, i) => i !== index
                                      //   );
                                      // });

                                      setShowIcon(false);
                                      setIndex(-1);

                                      handleShortList(challenge?.user?._id);
                                    }}
                                  >
                                    Short List
                                  </button>
                                )}
                                <button
                                  className="d-flex popuptext"
                                  style={{
                                    padding: "11px 1rem",
                                    maxHeight: "2rem",
                                    borderRadius: "0px 0px 5px 5px",
                                    gap: "10px",
                                    background: "#FFF",
                                    border: "none",
                                    width: "160px",
                                    minHeight: "42px",
                                    borderTop: "1px solid #d9d9d9",
                                  }}
                                  onClick={() => {
                                    setShowIcon(false);
                                    setIndex(-1);
                                    if (TRUE) {
                                      handleRemove(challenge?.user?._id);
                                    } else {
                                      handleRemoveShortlist(
                                        challenge?.user?._id
                                      );
                                    }
                                  }}
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                          )}
                        </div>

                        <MoreVertIcon
                          className="hoverred"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setIndex(index);
                            setShowIcon(true);
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </>
            )}
            {currentEntries.length > 0 && currentEntries.length < 5 && (
              <>
                {Array.from({ length: 5 - currentEntries.length }).map(
                  (_, index) => (
                    <tr
                      key={index}
                      className="tbodytr"
                      style={{ border: "none" }}
                    >
                      <td className="tbodytrtd"></td>
                      <td className="tbodytrtd"></td>
                      <td className="tbodytrtd"></td>
                      <td className="tbodytrtd"></td>
                      <td className="tbodytrtd"></td>
                      <td className="tbodytrtd"></td>
                    </tr>
                  )
                )}
              </>
            )}
          </tbody>
        </table>

        {currentEntries.length === 0 && (
          <div
            className="center col-md-12 col-12"
            style={{
              alignItems: "center",
              flexDirection: "column",
              width: "100%",
              height: "400px",
              background: "#FFF",
            }}
          >
            <img
              src={EmptySpace}
              alt="Emptyspace"
              height="225px"
              width="180px"
              className="img-fluid"
            />
            <p className="pink16">No Data yet</p>
            <p className="black14" style={{ fontWeight: "500" }}>
              <span className="black14">Please come back later.</span> The first
              students will engage with your company in no time!
            </p>
          </div>
        )}
      </div>
      <div
        className="d-flex"
        style={{ marginTop: "1rem", justifyContent: "end" }}
      >
        <div
          className="d-flex"
          style={{ justifyContent: "center", alignItems: "center" }}
        >
          {renderPageButtons()}
          {indexOfLastEntry < challenge.length && (
            <button
              onClick={handleNextPage}
              disabled={indexOfLastEntry >= challenge.length}
              className="backbutton"
            >
              Next
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResultTable;
