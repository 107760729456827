import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

import SortOutlinedIcon from "@mui/icons-material/SortOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import SideBar from "./sidebar";
import {
  setCompany,
  setEditChallenge,
  setEditJob,
  setEmployer,
  setId,
  setPreview,
} from "../../../state/index";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
const Header = ({ user, component, setComponent, company }) => {
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:767px)");
  const isNonMobile350 = useMediaQuery("(min-width:350px)");
  const isNonMobile1024 = useMediaQuery("(min-width:1024px)");
  const isNonMobile1450 = useMediaQuery("(min-width:450px)");
  const [popup, setPopUp] = useState(false);
  const sidebarRef = useRef(null);
  const dispatch = useDispatch();
  const [profilePopup, setProfilePopup] = useState(false);
  const iconRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setPopUp(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sidebarRef]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });

    function handleClickOutside(event) {
      if (iconRef.current && !iconRef.current.contains(event.target)) {
        setProfilePopup(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSignOut = () => {
    dispatch(setId({ uniqueId: null }));
    dispatch(setCompany({ company: null }));
    dispatch(setEmployer({ employer: null }));
    dispatch(setPreview({ preview: null }));
    dispatch(setEditChallenge({ editChallenge: null }));
    dispatch(setEditJob({ editJob: null }));
  };

  return (
    <>
      {" "}
      <div
        className="col-md-12 col-12 d-flex"
        style={{
          justifyContent: isNonMobile1024 ? "end" : "space-between",
          gap: "10px",

          // position: "absolute",
          marginTop: isNonMobile1024 ? "1.875rem" : "0rem",

          right: "1.875rem",
        }}
      >
        {!isNonMobile1024 && (
          <div className="center" style={{ alignItems: "center" }}>
            <SortOutlinedIcon
              onClick={() => setPopUp(!popup)}
              style={{ cursor: "pointer" }}
            />
          </div>
        )}
        <div
          className="d-flex"
          style={{ gap: "10px", position: "relative", zIndex: 100 }}
        >
          {/* <button className="btn headerbutton">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M21 21L17.5001 17.5M20 11.5C20 16.1944 16.1944 20 11.5 20C6.80558 20 3 16.1944 3 11.5C3 6.80558 6.80558 3 11.5 3C16.1944 3 20 6.80558 20 11.5Z"
              stroke="#101828"
              strokeWidth="1.70603"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button> 
        <button className="btn headerbutton">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M21.1066 15.48C20.6623 15.1 20.4065 14.5446 20.4066 13.96V9.14C20.4066 5.19 16.8266 2 12.4066 2C7.98657 2 4.40657 5.19 4.40657 9.14V13.96C4.40661 14.5446 4.15084 15.1 3.70657 15.48C2.19657 16.83 3.26657 19.13 5.40657 19.13H9.20657C9.69141 20.4804 10.9718 21.3811 12.4066 21.3811C13.8414 21.3811 15.1217 20.4804 15.6066 19.13H19.4066C21.5466 19.13 22.6166 16.83 21.1066 15.48ZM12.4069 19.8799C11.8117 19.8776 11.2508 19.6009 10.8869 19.1299H13.8869C13.5346 19.5935 12.9891 19.8699 12.4069 19.8799ZM19.4167 17.6299C19.7802 17.6629 20.1264 17.4679 20.2867 17.1399C20.3645 16.9424 20.2937 16.7173 20.1167 16.5999C19.3632 15.9278 18.9276 14.9695 18.9167 13.9599V9.13994C18.9167 6.02994 15.9967 3.49994 12.4167 3.49994C8.83668 3.49994 5.91668 6.02994 5.91668 9.13994V13.9599C5.90571 14.9695 5.47012 15.9278 4.71668 16.5999C4.536 16.7143 4.46076 16.94 4.53668 17.1399C4.69698 17.4679 5.04312 17.6629 5.40668 17.6299H19.4167Z"
              fill="#101828"
            />
          </svg>
        </button>*/}
          <button
            className={`${
              isNonMobile1024 ? "supportbutton" : "newSupportButton"
            }   btn `}
            onClick={() => navigate("/support")}
          >
            <InfoOutlinedIcon /> {isNonMobile1450 && "Support"}
          </button>

          {user.profilephoto ? (
            <img
              src={user.profilephoto}
              alt="User Profile"
              className="userimage shadowa mt-0 mb-0 border border-2 border-light"
              style={{ height: "45px", width: "45px", cursor: "pointer" }}
              onClick={() => setProfilePopup(true)}
            />
          ) : (
            <div>{"\u00A0"}</div>
          )}
          {profilePopup && (
            <div
              ref={iconRef}
              className="innerglass shadowa"
              style={{
                position: "absolute",
                right: "0",
                top: "3rem",

                minWidth: isNonMobile350 ? "314px" : "90vw",
                padding: "1rem",
                maxWidth: "90vw",
              }}
            >
              <div className="d-flex" style={{ gap: "1rem" }}>
                {user.profilephoto ? (
                  <img
                    src={user.profilephoto}
                    alt="User Profile"
                    className="userimage shadowa mt-0 mb-0 border border-2 border-light"
                    style={{ height: "45px", width: "45px" }}
                  />
                ) : (
                  <div>{"\u00A0"}</div>
                )}
                <div>
                  <p className="black14" style={{ color: "#101828" }}>
                    {user?.firstName} {user?.lastName}
                  </p>
                  <p className="gray12" style={{ marginTop: "0.5rem" }}>
                    {user?.tagline}
                  </p>
                </div>
              </div>
              <hr style={{ marginTop: "1rem", marginBottom: "0.5rem" }} />
              <p className="CompanyDashboardSidebarToolstext">Tools</p>
              <div className="d-flex flex-column">
                <button
                  className=" buttonblack12"
                  style={{ marginTop: "0.5rem" }}
                  onClick={() => {
                    navigate("/dashboard/Team");
                    setProfilePopup(false);
                  }}
                >
                  Invite Team
                </button>
                <button
                  className="buttonblack12"
                  style={{ marginTop: "0.75rem" }}
                  onClick={() => {
                    navigate("/dashboard/settings");
                    setProfilePopup(false);
                  }}
                >
                  Settings
                </button>
              </div>
              <hr style={{ marginTop: "1rem", marginBottom: "1rem" }} />
              <div className="d-flex" style={{ gap: "1rem" }}>
                {company.img && (
                  <img
                    src={company.img}
                    alt="User Profile"
                    className="rounded-2 shadowa mt-0 mb-0 border border-2 border-light"
                    style={{
                      height: "45px",
                      width: "45px",
                      cursor: "pointer",
                    }}
                    onClick={() => setProfilePopup(true)}
                  />
                )}
                <div>
                  <p className="black14" style={{ color: "#101828" }}>
                    {company.title}
                  </p>
                  <button
                    className=" pink12 p-0 text-start hovercolorback"
                    style={{ background: "transparent", border: "none" }}
                    onClick={() =>
                      navigate(`/dashboard/company/${company?.link}`)
                    }
                  >
                    View Company Page
                  </button>
                </div>
              </div>
              <hr style={{ marginTop: "1rem", marginBottom: "1rem" }} />
              <button className="buttonblack12" onClick={() => handleSignOut()}>
                Signout
              </button>
            </div>
          )}
        </div>
      </div>
      {!isNonMobile1024 && (
        <div
          className={`sidebarcompanydashboardmove ${
            popup ? "actived" : "inactived"
          }`}
          ref={sidebarRef}
        >
          <div className="d-flex justify-content-end">
            <CloseOutlinedIcon
              style={{
                position: "relative",
                marginBottom: "-30px",
                top: "35px",
                cursor: "pointer",
                marginRight: "20px",
                zIndex: 101,
              }}
              onClick={() => setPopUp(false)}
            />
          </div>
          <SideBar
            company={company}
            setComponent={setComponent}
            component={component}
            setPop={setPopUp}
          />
        </div>
      )}
    </>
  );
};
export default Header;
