import { useEffect, useState } from "react";
import axios from "axios";
import SelectedBox from "../../assets/images/icons/selectedbox";
import UnselectedBox from "../../assets/images/icons/unselectedBox";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import StarIcon from "@mui/icons-material/Star";

const ListItem = ({
  res,
  user,
  navigate,
  setSelectedChats,
  selectedChats,
  starredChats,
  setStarredChats,
  isNonMobile600,
  isNonMob1024,
  isNonMobile300,
  setTest,
  alldata,
  setCheck,
}) => {
  const userId = res?.users[0] === user?._id ? res?.users[1] : res?.users[0];
  const [otheruser, setUser] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await axios
          .post(`${process.env.REACT_APP_BACKEND_URL}/get/user/details`, {
            userId: userId,
          })
          .then((res) => {
            if (res?.data?.message === "Found") {
              setUser(res?.data?.user);
            }
          })
          .catch((err) => {
            console.log(err.message);
          });
      } catch (error) {
        console.error(error);
      }
    };

    if (!alldata.find((el) => el?._id === userId)) {
      fetchData();
    } else {
      setUser(alldata.find((el) => el?._id === userId) ?? null);
    }
  }, [userId, alldata, res]);

  const formatTimestampToDate = (timestamp) => {
    const date = new Date(timestamp);
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const day = date.getDate();
    const month = monthNames[date.getMonth()];
    return `${day} ${month}`;
  };

  return (
    <div
      className="listItem__chat align-items-center d-flex"
      style={{ gap: isNonMobile600 ? "2rem" : "1rem" }}
      onClick={() => {
        setTest((prev) => !prev);
        navigate(`/dashboard/chat?id=${otheruser?.uniqueId}`);
      }}
    >
      <div className="d-flex align-items-center " style={{ gap: "1rem" }}>
        <div
          className="center align-items-center"
          onClick={(e) => {
            e.stopPropagation();
            selectedChats.includes(res?._id)
              ? setSelectedChats(selectedChats.filter((el) => el !== res?._id))
              : setSelectedChats([...selectedChats, res?._id]);
          }}
        >
          {selectedChats.length > 0 && selectedChats.includes(res?._id) ? (
            <SelectedBox />
          ) : (
            <UnselectedBox />
          )}
        </div>
        <div
          className="center align-items-center"
          onClick={(e) => {
            e.stopPropagation();
            starredChats.includes(res?._id)
              ? setStarredChats(starredChats.filter((el) => el !== res?._id))
              : setStarredChats([...starredChats, res?._id]);
            setCheck((prev) => !prev);
          }}
        >
          {starredChats.length > 0 && starredChats.includes(res?._id) ? (
            <StarIcon
              style={{ height: "20px", width: "20px", color: "#E31b54" }}
            />
          ) : (
            <StarOutlineIcon style={{ height: "20px", width: "20px" }} />
          )}
        </div>
        {otheruser?.profilephoto && (
          <img
            src={otheruser?.profilephoto}
            height={"36px"}
            width={"36px"}
            alt="profile"
            className="companyheader__profilepic"
          />
        )}
        {otheruser?.profilePhoto && (
          <img
            src={otheruser?.profilePhoto}
            height={"36px"}
            width={"36px"}
            alt="profile"
            className="companyheader__profilepic"
          />
        )}
      </div>
      <p
        className="black14"
        style={{
          fontWeight: "400",
          minWidth: isNonMob1024
            ? "18%"
            : isNonMobile600
            ? "50%"
            : isNonMobile300
            ? "30%"
            : "20%",
          maxWidth: isNonMob1024
            ? "18%"
            : isNonMobile600
            ? "50%"
            : isNonMobile300
            ? "30%"
            : "15%",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {otheruser?.firstName} {otheruser?.lastName} {otheruser?.LastName}
      </p>
      {isNonMob1024 && (
        <div
          className="allpoppins14 tablescroll text-start"
          style={{
            fontWeight: "500",
            minWidth: isNonMob1024 ? "50%" : "40%",
            maxWidth: isNonMob1024 ? "50%" : "40%",
            whiteSpace: "nowrap",
            maxHeight: "22px",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          dangerouslySetInnerHTML={{
            __html: res?.messages[res?.messages?.length - 1]?.content,
          }}
        />
      )}
      <div className="w-100 d-flex justify-content-end align-items-center">
        <p className="companyLocation" style={{ minWidth: "60px" }}>
          {formatTimestampToDate(
            res?.messages[res?.messages?.length - 1]?.timestamp
          )}
        </p>
      </div>
    </div>
  );
};

export default ListItem;
