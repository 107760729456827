import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import pic1 from "../../../assets/images/logosCOmpany/1.png";
import pic2 from "../../../assets/images/logosCOmpany/2.png";
import pic4 from "../../../assets/images/logosCOmpany/3.png";
import pic3 from "../../../assets/images/logosCOmpany/4.png";
import pic5 from "../../../assets/images/logosCOmpany/5.png";
const First = () => {
  return (
    <div
      className="marginsleftrightCompany overflowshown"
      style={{ position: "relative" }}
    >
      <div className="center">
        <div className="headerCompany " style={{ overflow: "visible" }}>
          <div
            className="center align-items-center"
            style={{ minHeight: "calc(100vh - 65px)", overflow: "hidden" }}
          >
            <div className="circle-background-large">
              <div class="circle-container"></div>
              <div className="">
                <div className="center">
                  <div className="hellocareer">
                    <AutoAwesomeIcon
                      style={{
                        height: "20px",
                        width: "20px",
                        color: "#FFD700",
                        transform: "rotate(180deg)",
                      }}
                    />
                    Hi, career center teams!
                  </div>
                </div>
                <div className="center">
                  <p
                    className="mainheadingcompany text-center"
                    style={{ maxWidth: "860px", marginTop: "1.875rem" }}
                  >
                    Empower your students Elevate their career start
                  </p>
                </div>
                <div className="center">
                  <p
                    className="text-center gray95020400"
                    style={{
                      maxWidth: "650px",
                      marginTop: "1.5rem",
                      fontFamily: "Poppins",
                    }}
                  >
                    Enhance your career services and enrich the student
                    experience with tools that seamlessly connect students to
                    top-employers.
                  </p>
                </div>
                <div className="center">
                  <div style={{ marginTop: "2rem" }}>
                    <div
                      className="center align-items-center flex-wrap"
                      style={{ gap: "0.75rem" }}
                    >
                      <div className="d-flex maxWidthClampedCompanysmalltext">
                        <img
                          src={pic1}
                          alt="company"
                          className="Companypagelogoimages shadowa rounded-5"
                          style={{ position: "relative", zIndex: 5 }}
                        />
                        <img
                          src={pic2}
                          alt="company"
                          className="Companypagelogoimages moveLeftCompany shadowa rounded-5"
                          style={{
                            position: "relative",
                            zIndex: 4,
                            left: "-20px",
                          }}
                        />
                        <img
                          src={pic3}
                          alt="company"
                          className="Companypagelogoimages shadowa rounded-5"
                          style={{
                            position: "relative",
                            zIndex: 3,
                            left: "-40px",
                          }}
                        />
                        <img
                          src={pic4}
                          alt="company"
                          className="Companypagelogoimages shadowa rounded-5 moveLeftCompany"
                          style={{
                            position: "relative",
                            zIndex: 2,
                            left: "-55px",
                          }}
                        />
                        <img
                          src={pic5}
                          alt="company"
                          className="Companypagelogoimages shadowa rounded-5 moveLeftCompany"
                          style={{
                            position: "relative",
                            zIndex: 1,
                            left: "-70px",
                          }}
                        />
                      </div>
                      <p className="pink16 text-center">
                        Join 200+ Universities & Business Schools
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default First;
