import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const Find = () => {
  const { uniqueId } = useParams();
  const navigate = useNavigate();
  if (!uniqueId) {
    navigate(-1);
  }

  return (
    <div>
      <div className="d-flex" style={{ justifyContent: "center" }}>
        <div>
          <div className="center">
            <div className="searchicon ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.73872 8.94722C6.40262 4.95182 10.3012 2.34472 14.6292 2.33316C19.2938 2.30859 23.4414 5.29641 24.8954 9.72862C26.3495 14.1608 24.7778 19.0249 21.0051 21.7683C17.2325 24.5116 12.1208 24.5076 8.35256 21.7582L4.99256 25.1182C4.65094 25.4594 4.09752 25.4594 3.7559 25.1182C3.4147 24.7765 3.4147 24.2231 3.7559 23.8815L7.0109 20.6265C3.97045 17.5463 3.07482 12.9426 4.73872 8.94722ZM6.29873 16.5303C7.69578 19.8969 10.9843 22.0895 14.6292 22.0848V22.0382C19.5762 22.0319 23.5922 18.0367 23.6242 13.0898C23.6289 9.44485 21.4363 6.15638 18.0697 4.75932C14.703 3.36227 10.8263 4.1321 8.2489 6.70949C5.67151 9.28688 4.90168 13.1636 6.29873 16.5303Z"
                  fill="#E31b54"
                />
              </svg>{" "}
            </div>
          </div>
          <p
            className="registeration text-center"
            style={{ marginTop: "1.5rem" }}
          >
            Find your Institution
          </p>
        </div>
      </div>
    </div>
  );
};
export default Find;
