import React, { useState } from "react";
import { toast } from "react-toastify";
import { Country } from "country-state-city";
const AddExperience = ({ newExperience, setNewExperience, handleConfirm }) => {
  const fileInputRef = React.createRef();

  const allCountries = Country.getAllCountries();

  const [search, setSearch] = useState("");
  const [filteredLocations, setFilteredLocations] = useState([]);
  const handleCountrySearch = (e) => {
    setSearch(e.target.value);
    const filteredCountries = allCountries.filter((country) =>
      country.name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredLocations(filteredCountries);
  };

  const handleSelectCountry = (location) => {
    setNewExperience((prev) => {
      return {
        ...prev,
        location: location,
      };
    });

    setSearch("");
  };

  const openFileInput = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();

      reader.onload = function (event) {
        const base64String = event.target.result;

        setNewExperience((prev) => {
          return {
            ...prev,
            logo: base64String,
          };
        });
      };

      reader.readAsDataURL(file);
    } else {
      toast.error("Please select a valid image file.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  return (
    <div className="mt-4">
      <p className="black14 mt-4">Company Name:</p>
      <input
        className="col-md-12 col-12 forminput mt-3"
        value={newExperience?.company}
        placeholder="Enter Company Name"
        onChange={(e) => {
          setNewExperience((prev) => {
            return {
              ...prev,
              company: e.target.value,
            };
          });
        }}
      />
      <p className="black14 mt-4">Company Logo(optional):</p>
      <div className="mt-3 border p-4 rounded-3">
        {newExperience?.logo && (
          <div className="center">
            <img
              src={
                newExperience?.logo instanceof File
                  ? URL.createObjectURL(newExperience?.logo)
                  : newExperience?.logo
              }
              alt="selectefFile"
              className="rounded-2"
              style={{ maxHeight: "200px", maxWidth: "200px" }}
            />
          </div>
        )}
        <div style={{ gap: "5px", justifyContent: "center" }}>
          <p
            className="text-center clicktoupload"
            onClick={openFileInput}
            style={{ cursor: "pointer" }}
          >
            {newExperience?.logo
              ? "Click to change Image"
              : "Click to upload Image"}
          </p>
          <p className="svgpngtext">SVG, PNG, JPG or GIF (max. 12MB)</p>
        </div>

        <input
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          style={{ display: "none" }}
          ref={fileInputRef}
        />
      </div>

      <p className="black14 mt-4">Position:</p>
      <input
        className="col-md-12 col-12 forminput mt-3"
        value={newExperience?.position}
        placeholder="Enter Position at company"
        onChange={(e) => {
          setNewExperience((prev) => {
            return {
              ...prev,
              position: e.target.value,
            };
          });
        }}
      />
      <p className="black14 mt-4">Country:</p>
      <input
        value={search}
        placeholder="Search Country"
        onChange={(e) => handleCountrySearch(e)}
        className="forminput col-md-12 col-12 mt-3"
      />
      <div className="d-flex">
        {" "}
        {newExperience?.location?.name && (
          <div className={`tagscard  mt-3`}>
            {" "}
            {newExperience?.location && newExperience?.location?.flag}
            <p className="itemstexts">{newExperience.location.name}</p>
          </div>
        )}
      </div>
      {filteredLocations.length > 0 && search && (
        <div
          className="rounded-2 d-flex flex-column tablescroll shadowa"
          style={{
            background: "#FFF",
            gap: "1rem",
            minWidth: "300px",
            maxHeight: "200px",
            padding: "1rem",
            overflow: "auto",
            zIndex: 3,
            marginTop: "0.75rem",
          }}
        >
          {filteredLocations.map((location) => (
            <p
              className="d-flex gap-3 black14"
              key={location.name}
              style={{ cursor: "pointer" }}
              onClick={() => handleSelectCountry(location)}
            >
              <span> {location.flag}</span>
              {location.name}
            </p>
          ))}
        </div>
      )}
      <p className="black14 mt-4">From:</p>
      <select
        className="col-md-12 col-12 forminput mt-3"
        value={newExperience.from}
        onChange={(e) => {
          setNewExperience((prev) => {
            return {
              ...prev,
              from: e.target.value,
            };
          });
        }}
      >
        <option value="">Select Year</option>
        {Array.from({ length: 50 }, (_, i) => new Date().getFullYear() - i).map(
          (year) => (
            <option key={year} value={year}>
              {year}
            </option>
          )
        )}
      </select>

      <p className="black14 mt-4">To:</p>
      <select
        className="col-md-12 col-12 forminput mt-3"
        value={newExperience.to}
        onChange={(e) => {
          setNewExperience((prev) => {
            return {
              ...prev,
              to: e.target.value,
            };
          });
        }}
      >
        <option value="" disabled>
          Select Year
        </option>
        <option value={"Present"}>Present</option>
        {Array.from({ length: 50 }, (_, i) => new Date().getFullYear() - i).map(
          (year) => (
            <option
              key={year}
              value={year}
              disabled={year < parseInt(newExperience.from)}
            >
              {year}
            </option>
          )
        )}
      </select>

      <p className="black14 mt-4">Description:</p>
      <textarea
        className="col-md-12 col-12 forminput mt-3"
        value={newExperience.description}
        placeholder="Explain your experience there"
        style={{ minHeight: "200px" }}
        onChange={(e) => {
          setNewExperience((prev) => {
            return {
              ...prev,
              description: e.target.value,
            };
          });
        }}
      />
      <button
        className="continuebutton mt-4 mb-5"
        onClick={() => handleConfirm()}
      >
        Confirm
      </button>
    </div>
  );
};
export default AddExperience;
