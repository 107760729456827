const MegaMenu = ({ megaMenu, setMegaMenu }) => {
  return (
    <div className={`megaMenu ${megaMenu ? "megamenuactive" : ""}`}>
      <h2>MegaMenu</h2>
      <h2>MegaMenu</h2>
      <h2>MegaMenu</h2>
      <h2>MegaMenu</h2>
      <h2>MegaMenu</h2>
      <h2>MegaMenu</h2>
    </div>
  );
};

export default MegaMenu;
