import React, { useEffect, useState } from "react";
import Right from "./Right";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

import JobCard from "./jobCard";
const OverView = ({ challenges, setLiveChallengesLength }) => {
  const navigate = useNavigate();

  const [hoveredChallenge, setHoveredChallenge] = useState(null);
  const [challengestoshow, setShowChallenges] = useState([]);
  const isNonMobile = useMediaQuery("(min-width:767px)");
  const isNonMobile1024 = useMediaQuery("(min-width:1024px)");
  const [addHover, setAddHover] = useState(false);

  const handleMouseEnter = (index) => {
    setHoveredChallenge(index);
  };

  const handleMouseLeave = () => {
    setHoveredChallenge(null);
  };

  useEffect(() => {
    if (challenges.length > 0) {
      setShowChallenges(challenges.filter((challe) => challe.draft === false));
      setLiveChallengesLength(
        challenges.filter((challe) => challe.draft === false)
      );
    }
  }, [challenges]);

  return (
    <div
      className=" d-flex"
      style={{
        justifyContent: "space-evenly",
        gap: "1.875rem",
      }}
    >
      <div style={{ width: "100%" }}>
        <div
          className={`  flex-wrap d-flex`}
          style={{
            gap: "1rem",
          }}
        >
          {challengestoshow &&
            challengestoshow.length > 0 &&
            challengestoshow.map((challe, index) => (
              <div
                key={index}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate(`/dashboard/job/details/${challe.uniqueId}`)
                }
              >
                <JobCard challe={challe} index={index} />
              </div>
            ))}
          <div
            className="addChallengeDiv shadowa"
            onMouseEnter={() => setAddHover(true)}
            onMouseLeave={() => setAddHover(false)}
            onClick={() => navigate("/create/job")}
          >
            <div>
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100"
                height="100"
                viewBox="0 0 100 100"
                fill="none"
              >
                <path
                  d="M82.2917 45.8333H52.0833V15.625C52.0833 13.8991 50.6842 12.5 48.9583 12.5C47.2324 12.5 45.8333 13.8991 45.8333 15.625V45.8333H15.625C13.8991 45.8333 12.5 47.2324 12.5 48.9583C12.5 50.6842 13.8991 52.0833 15.625 52.0833H45.8333V82.2917C45.8333 84.0176 47.2324 85.4167 48.9583 85.4167C50.6842 85.4167 52.0833 84.0176 52.0833 82.2917V52.0833H82.2917C84.0176 52.0833 85.4167 50.6842 85.4167 48.9583C85.4167 47.2324 84.0176 45.8333 82.2917 45.8333Z"
                  fill={addHover ? "#E31B54" : "white"}
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      {isNonMobile1024 && <Right />}
    </div>
  );
};
export default OverView;
