const AddBox = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.00002 1.66666H15C16.841 1.66666 18.3334 3.15904 18.3334 4.99999V15C18.3334 16.8409 16.841 18.3333 15 18.3333H5.00002C3.15907 18.3333 1.66669 16.8409 1.66669 15V4.99999C1.66669 3.15904 3.15907 1.66666 5.00002 1.66666ZM16.4201 16.4201C16.7968 16.0435 17.0084 15.5326 17.0084 15V4.99999C17.0084 4.46735 16.7968 3.95652 16.4201 3.57988C16.0435 3.20325 15.5327 2.99166 15 2.99166H5.00002C3.89085 2.99166 2.99169 3.89082 2.99169 4.99999V15C2.99169 15.5326 3.20328 16.0435 3.57992 16.4201C3.95655 16.7967 4.46738 17.0083 5.00002 17.0083H15C15.5327 17.0083 16.0435 16.7967 16.4201 16.4201Z"
        fill="#E31B54"
      />
      <path
        d="M13.3334 9.37499H10.625V6.66666C10.625 6.32148 10.3452 6.04166 10 6.04166C9.65484 6.04166 9.37502 6.32148 9.37502 6.66666V9.37499H6.66669C6.32151 9.37499 6.04169 9.65481 6.04169 9.99999C6.04169 10.3452 6.32151 10.625 6.66669 10.625H9.37502V13.3333C9.37502 13.6785 9.65484 13.9583 10 13.9583C10.3452 13.9583 10.625 13.6785 10.625 13.3333V10.625H13.3334C13.6785 10.625 13.9584 10.3452 13.9584 9.99999C13.9584 9.65481 13.6785 9.37499 13.3334 9.37499Z"
        fill="#E31B54"
      />
    </svg>
  );
};
export default AddBox;
