import React, { useState, useEffect, useRef } from "react";
import { motion, useTransform, useScroll } from "framer-motion";
import { useMediaQuery } from "@mui/material";
import Card1 from "./card1";
import Card2 from "./card2";
import Card3 from "./card3";
import Slider from "react-slick";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import Card4 from "./card4";
import ArrowCircleDownRoundedIcon from "@mui/icons-material/ArrowCircleDownRounded";
import Varyance from "../../assets/videos/Varyance.mp4";
const HomeSecondSection = () => {
  const isNonMobile = useMediaQuery("(min-width:450px)");
  const isNonMobile1440 = useMediaQuery("(min-width:1440px)");
  const isNonMobile1000 = useMediaQuery(
    "(min-width: 1025px) and (max-width: 1440px)"
  );
  const videoRef = useRef(null);

  useEffect(() => {
    if (isNonMobile) {
      const video = videoRef.current;
      const playPromise = video.play();

      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            // Autoplay started
          })
          .catch((error) => {
            // Autoplay was prevented
            // You can handle this case by displaying a play button or message to the user
          });
      }
    }
  }, []);

  const handleContextMenu = (e) => {
    e.preventDefault(); // Prevent right-click context menu
  };

  const isNonMobile768 = useMediaQuery(
    "(min-width: 750px) and (max-width: 1024px)"
  );
  const [MouseHover, setStartHover] = useState(false);

  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 100,
    slidesToShow: 4,
    slidesToScroll: 2,
    centerMode: true,
    centerPadding: "4%",
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          dots: true,
          arrows: false,
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          dots: true,
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          dots: true,
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className="Prev_arrow p-2 rounded-5 shadowa"
        style={{
          ...style,
          left: "0",
          zIndex: 1,
          position: "absolute",
          top: "100%",
          left: "65%",
          transform: "translateY(-50%)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          backgroundColor: "#FFF",
        }}
        onClick={onClick}
      >
        <MdKeyboardArrowLeft size={24} />
      </div>
    );
  }
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className="Next_arrow p-2 rounded-5 shadowa "
        style={{
          ...style,
          right: "0",
          zIndex: 1,
          position: "absolute",
          top: "100%",
          right: "10%",
          transform: "translateY(-50%)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          backgroundColor: "#FFF",
        }}
        onClick={onClick}
      >
        <MdKeyboardArrowRight size={24} />
      </div>
    );
  }

  const HorizontalScrollCarousel = () => {
    const targetRef = useRef();
    const { scrollYProgress } = useScroll({
      target: targetRef,
    });
    const x = useTransform(scrollYProgress, [0, 1], ["0%", "-100%"]);
    return (
      <>
        <section ref={targetRef} className="stylestest ">
          <div
            style={{
              position: "relative",
              // width: "100% !important",
            }}
          >
            <motion.div
              className="d-flex fullwidthscroller testingscrolls"
              style={{ x }}
            >
              <div
                className="d-flex"
                style={{ gap: "40px", height: "100vh", alignItems: "center" }}
              >
                <Card1 />
                <Card2 />
                <Card3 />
                {/* <Card4 /> */}
                <div
                  style={{
                    position: "relative",

                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    className=" d-flex paddingsLeftRightCompany "
                    style={{
                      position: "absolute",

                      display: "flex",
                      width: "90vw",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="d-flex headerCompany "
                      style={{
                        justifyContent: "center",
                        height: "520px",
                        overflow: "hidden",
                        position: "relative",
                        objectFit: "contain",
                      }}
                    >
                      <div
                        className=""
                        style={{
                          width: "100%",
                          height: "auto",
                          position: "relative",
                        }}
                      >
                        <video
                          ref={videoRef}
                          src={Varyance}
                          autoPlay
                          loop
                          muted
                          controls={false}
                          className="rounded-3 shadowa "
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                          onContextMenu={handleContextMenu}
                        ></video>
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        </section>
      </>
    );
  };

  return (
    <>
      <div className="marginsleftrightCompany">
        <div
          className="d-flex"
          style={{ justifyContent: "center", position: "relative" }}
        >
          <div
            className={`${isNonMobile1440 && "headerCompany"}`}
            style={!isNonMobile1440 ? { width: "100%" } : {}}
          >
            <div
            // className="secondSectionMargintopCompany "
            >
              {isNonMobile && <HorizontalScrollCarousel />}
            </div>
          </div>
        </div>
      </div>
      {!isNonMobile && (
        <Slider
          {...settings}
          className="d-flex"
          style={{ justifyContent: "center" }}
        >
          <Card1 />

          <Card2 />

          <Card3 />

          {/* <Card4 /> */}
        </Slider>
      )}
    </>
  );
};

export default HomeSecondSection;
