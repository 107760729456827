const StudentDashboardHeader = ({ user }) => {
  return (
    <div>
      <button
        className="btn shadowa mt-0 mb-0 m-2"
        style={{ background: "#FFF", padding: "5px", maxHeight: "36px" }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M21 21L17.5001 17.5M20 11.5C20 16.1944 16.1944 20 11.5 20C6.80558 20 3 16.1944 3 11.5C3 6.80558 6.80558 3 11.5 3C16.1944 3 20 6.80558 20 11.5Z"
            stroke="#101828"
            strokeWidth="1.70603"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      <button
        className="btn shadowa mt-0 mb-0 m-2"
        style={{ background: "#FFF", padding: "5px", maxHeight: "36px" }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.1066 15.48C20.6623 15.1 20.4065 14.5446 20.4066 13.96V9.14C20.4066 5.19 16.8266 2 12.4066 2C7.98657 2 4.40657 5.19 4.40657 9.14V13.96C4.40661 14.5446 4.15084 15.1 3.70657 15.48C2.19657 16.83 3.26657 19.13 5.40657 19.13H9.20657C9.69141 20.4804 10.9718 21.3811 12.4066 21.3811C13.8414 21.3811 15.1217 20.4804 15.6066 19.13H19.4066C21.5466 19.13 22.6166 16.83 21.1066 15.48ZM12.4069 19.88C11.8117 19.8778 11.2508 19.601 10.8869 19.13H13.8869C13.5346 19.5936 12.9891 19.87 12.4069 19.88ZM19.4167 17.63C19.7802 17.6629 20.1264 17.468 20.2867 17.14C20.3645 16.9424 20.2937 16.7173 20.1167 16.6C19.3632 15.9279 18.9276 14.9696 18.9167 13.96V9.14C18.9167 6.03 15.9967 3.5 12.4167 3.5C8.83668 3.5 5.91668 6.03 5.91668 9.14V13.96C5.90571 14.9696 5.47012 15.9279 4.71668 16.6C4.536 16.7144 4.46076 16.9401 4.53668 17.14C4.69698 17.468 5.04312 17.6629 5.40668 17.63H19.4167Z"
            fill="#101828"
          />
        </svg>
      </button>
      {user?.profilePhoto && (
        <img
          src={user?.profilePhoto}
          alt="userprofile"
          className="shadowa"
          width="36px"
          height="36px"
          style={{ borderRadius: "72px", border: "2px solid #FFF" }}
        />
      )}
    </div>
  );
};
export default StudentDashboardHeader;
