import React, { useState, useRef, useEffect } from "react";

import uniicon from "../../assets/images/uniicon.png";
import { useMediaQuery } from "@mui/material";
import SchoolIcon from "@mui/icons-material/School";
import { FixedSizeList as List } from "react-window";
import { setUni } from "../../state";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
const HomeFourthSection = ({
  selectedUni,
  setSelectedUni,
  country,
  setCountry,
  Countries,
  unilist,
  loading,
  checker,
  setChecker,
  truncateString,
  isNonMobile1025,
}) => {
  const [showUni, setShowUni] = useState(false);
  const [search, setSearch] = useState(null);
  const dispatch = useDispatch();
  const [changeLocation, ShowChangeLocation] = useState(country ? false : true);
  const isNonMobile = useMediaQuery("(min-width:768px)");
  const isNonMobile500 = useMediaQuery("(min-width:450px)");
  const isNonMobile1440 = useMediaQuery("(min-width:1440px)");
  const navigate = useNavigate();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        unilistinputdivRef.current &&
        !unilistinputdivRef.current.contains(event.target)
      ) {
        setShowUni(false); // Close showUni when clicked outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleUniClick = () => {
    setShowUni(!showUni);
  };

  const [searchList, setSearchList] = useState(unilist);
  useEffect(() => {
    setSearchList(unilist);
  }, [unilist]);

  const filterUniversities = () => {
    if (!search) {
      setSearchList(unilist); // Reset searchList if search input is empty
    } else {
      const filteredUniversities = unilist.filter((uni) =>
        uni.name.toLowerCase().includes(search.toLowerCase())
      );
      setSearchList(filteredUniversities);
    }
  };

  useEffect(() => {
    filterUniversities();
  }, [search]);

  const [countrySearchList, SetCountriesList] = useState(Countries);
  const unilistinputdivRef = useRef(null);

  const renderUniversityItem = ({ index, style }) => {
    const uni = searchList[index];
    return (
      <div
        key={index}
        className="d-flex unilists "
        style={{
          ...style,
          gap: "0.5rem",
          width: "97%",
          // marginLeft: "1rem",
          // marginTop: "1.25rem",
          alignItems: "center",
        }}
        onClick={() => {
          setSelectedUni(uni);
          setShowUni(false);
          dispatch(setUni({ Uni: uni }));
        }}
      >
        {uni.logo && ValidateLogo(uni.logo) ? (
          <img
            src={`${uni.logo}`}
            alt="University Logo"
            style={{ width: "40px", height: "40px", objectFit: "contain" }}
            className="Interest_img_icon shadowa rounded-5"
          />
        ) : (
          <img
            src={uniicon}
            alt="University Logo"
            style={{ width: "40px", height: "40px", objectFit: "contain" }}
            className="Interest_img_icon shadowa rounded-5"
          />
        )}
        <p className="uniselecttext d-flex" style={{ alignItems: "center" }}>
          {uni.name}
        </p>
      </div>
    );
  };

  const renderUniversityList = () => (
    <List
      height={250}
      itemCount={searchList.length}
      itemSize={isNonMobile ? 55 : 70}
      width={"100%"}
      className="overflowx whitescrollbar"
    >
      {renderUniversityItem}
    </List>
  );

  const isValidImage = async (base64String) => {
    try {
      await new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve();
        img.onerror = () => reject();
        img.src = `${base64String}`;
      });
      return true; // Image loaded successfully
    } catch (error) {
      return false; // Error occurred while loading image
    }
  };

  const ValidateLogo = async (base64String) => {
    return await isValidImage(base64String);
  };

  return (
    <div className="d-flex" style={{ justifyContent: "center" }}>
      <div
        className="forthsectionpadding headerCompany d-flex"
        style={{ justifyContent: "center" }}
      >
        <div className="fourthsectionhome ">
          <div>
            <p className=" sparkInterest text-center">
              Register <span>Now</span>
            </p>
            <p className="undercase2 text-center">
              <span className="undercase2 ">Start your journey. </span>
              <br />
              Join students{" "}
              <span className="undercase3">around the world </span>
              <br /> and kickstart{" "}
              <span className="undercase2">your career.</span>
            </p>{" "}
            <div
              className="d-flex forthsectionmarginTop"
              style={{ justifyContent: "center", gap: "1rem" }}
            >
              <span
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  position: "relative",
                  // top: isNonMobile1024 ? "-5px" : "10px",
                  gap: "1rem",

                  alignItems: "center",
                }}
                className="mt-4"
              >
                <div
                  className="unilistinputdiv d-flex"
                  ref={unilistinputdivRef}
                  onClick={handleUniClick}
                  style={{ cursor: "pointer" }}
                >
                  <div className="uniselect">
                    {selectedUni &&
                    selectedUni.logo &&
                    ValidateLogo(selectedUni.logo) ? (
                      <img
                        src={`${selectedUni.logo}`}
                        className="rounded-3"
                        alt={uniicon}
                        style={{
                          objectFit: "contain",
                          maxWidth: isNonMobile1440 ? "40px" : "32px",
                          maxHeight: isNonMobile1440 ? "40px" : "32px",
                          // maxWidth: "100%",
                          // maxHeight: "100%",
                        }}
                      />
                    ) : (
                      <div
                        className={`rounded-5 d-flex ${
                          isNonMobile1440 ? "p-2" : "p-1"
                        } notselecteduni `}
                        style={{
                          height: isNonMobile1440 ? "40px" : "32px",
                          width: isNonMobile1440 ? "40px" : "32px",
                          backgroundColor: country ? "#FFF" : "",
                        }}
                      >
                        {country && country.icon ? (
                          <img
                            src={country.icon}
                            alt={uniicon}
                            className="rounded-3"
                            style={{
                              objectFit: "contain",
                              maxWidth: "100%",
                              maxHeight: "100%",
                            }}
                          />
                        ) : (
                          <SchoolIcon
                            style={{
                              color: "white",
                            }}
                          />
                        )}
                      </div>
                    )}

                    <p className="uniselecttext">
                      {selectedUni
                        ? isNonMobile1025
                          ? truncateString(`${selectedUni.name}`, 100)
                          : truncateString(selectedUni.name, 53)
                        : "Select your university to register"}
                    </p>
                  </div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M5 7.5L10 12.5L15 7.5"
                      stroke="#1D2939"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>

                {showUni && (
                  <div
                    ref={unilistinputdivRef}
                    style={{
                      position: "absolute",
                      overflow: "hidden",
                      top: "0",
                      zIndex: 10,
                    }}
                    className="showUnilistmodal "
                  >
                    <div className="paddingsinsearchlist">
                      <div className="searchinput">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M19 19L15.5001 15.5M18 9.5C18 14.1944 14.1944 18 9.5 18C4.80558 18 1 14.1944 1 9.5C1 4.80558 4.80558 1 9.5 1C14.1944 1 18 4.80558 18 9.5Z"
                            stroke="#475467"
                            strokeWidth="1.70603"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <input
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                          type="text"
                          placeholder="Search"
                          className="searchinputuni"
                        />
                      </div>
                      <hr style={{ width: "97%" }} />
                      <div
                        className=""
                        style={{ overflow: "auto", maxHeight: "20.438rem" }}
                      >
                        {changeLocation && !checker ? (
                          countrySearchList.map((con, index) => (
                            <div
                              key={index} // Make sure to add a unique key when mapping
                              className="d-flex unilists"
                              style={{ gap: "0.5rem", marginTop: "1.25rem" }}
                              onClick={() => {
                                setCountry(con);
                                ShowChangeLocation(false);
                                setSearch(""); // Assuming 'con' is the selected university in this context
                              }}
                            >
                              <img
                                src={con.icon}
                                alt="uni logo"
                                height="40px"
                                width="40px"
                                className="Interest_img_icon rounded-5"
                              />
                              <p
                                className="uniselecttext d-flex"
                                style={{ alignItems: "center" }}
                              >
                                {con.name}
                              </p>
                            </div>
                          ))
                        ) : (
                          <>
                            {loading ? (
                              <div
                                className="d-flex"
                                style={{
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <p
                                  style={{
                                    fontSize: "1rem",
                                    fontFamily: "Poppins",
                                    fontWeight: "500",
                                  }}
                                >
                                  Fetching Universities
                                </p>
                                <div class="loader"></div>
                              </div>
                            ) : (
                              <>{renderUniversityList()}</>
                            )}
                          </>
                        )}
                        <div
                          className="d-flex unilists"
                          style={{ gap: "0.5rem", marginTop: "1.25rem" }}
                          onClick={() => {
                            setSelectedUni({ name: "other" });
                            navigate("/register?other=true");

                            setShowUni(false);
                          }}
                        >
                          <p
                            className="uniselecttext d-flex"
                            style={{ alignItems: "center" }}
                          >
                            other
                          </p>
                        </div>
                      </div>
                      <hr style={{ width: "97%" }} />
                    </div>
                    <div className="bottomsearchlist">
                      <div
                        className="d-flex"
                        style={{
                          justifyContent: "space-between",
                          // width: "85%",
                        }}
                      >
                        <div className="countrydisplay">
                          {country ? (
                            <>
                              <img
                                src={country.icon}
                                alt="country flag"
                                width="16px"
                                height="16px"
                              />
                              {country.name}
                            </>
                          ) : (
                            "select country"
                          )}
                        </div>
                        <button
                          className="changelocationbutton btn p-0"
                          onClick={() => {
                            ShowChangeLocation(true);
                            setChecker(false);
                          }}
                        >
                          {" "}
                          Change Location
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                <div
                  className="d-flex"
                  style={{ justifyContent: "center", alignItems: "center" }}
                >
                  <button
                    className={`continuebutton `}
                    style={{
                      height: "50px",
                      position: "relative",
                      maxHeight: isNonMobile1440 ? "" : "48px",
                      width: isNonMobile500 ? "" : "90vw",
                    }}
                    onClick={() => navigate("/register")}
                  >
                    Join now
                  </button>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeFourthSection;
