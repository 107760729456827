import React, { useState, useEffect, useRef } from "react";
import fasttrack from "../../../../assets/images/fasttrack.svg";
import virtual from "../../../../assets/images/virtual.svg";
import insight from "../../../../assets/images/insight.svg";
import logo from "../../../../assets/images/logo.png";
import linkedin from "../../../../assets/images/icons/linkedin.png";
import lamp from "../../../../assets/images/Lamp.png";

import { useMediaQuery } from "@mui/material";
import "./fourthsection.css";
import Slider from "react-slick";

const FourthSection = ({
  type,
  test,
  totalTime,

  difficulty,
  totalSkills,
  totalLearns,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    dots: true,
    beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
    speed: 500,
    slidesToShow: 1, // Adjust this based on how many slides you want to show
    slidesToScroll: 1,
    arrows: false,
    centerMode: true,
    customPaging: (i) => <CustomDots active={i === currentSlide} />,
    appendDots: (dots) => (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "10px",
        }}
      >
        {dots}
      </div>
    ),
    centerPadding: "0px", // Ensure no extra padding is added
  };

  // Using MUI useMediaQuery hook to handle responsive behavior
  const isNonMon768 = useMediaQuery("(min-width:768px)");
  const isNonMon1250 = useMediaQuery("(min-width:1250px)");

  function Carousel() {
    const slides = [
      <>
        {type === "Fast-Track" && (
          <div className="previewpageCards shadowa" key={1}>
            <div
              className="center"
              style={{ alignItems: "center", width: "100%", height: "100%" }}
            >
              <div style={{ maxWidth: "458px" }}>
                <div className="center">
                  <img
                    src={fasttrack}
                    alt="fasttrack"
                    height="40px"
                    width="40px"
                  />
                </div>
                <div className="" style={{ marginTop: "48px" }}>
                  <p className="registeration text-center">Fast Track</p>
                </div>
                <div className="">
                  {" "}
                  <p
                    className="text-center black16"
                    style={{ marginTop: "1rem" }}
                  >
                    Gain insights in an evaluated and time-constrained challenge
                    and compete against students globally.
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        {type === "Virtual Experience" && (
          <div className="previewpageCards shadowa" key={1}>
            <div
              className="center"
              style={{ alignItems: "center", width: "100%", height: "100%" }}
            >
              <div style={{ maxWidth: "458px" }}>
                <div className="center">
                  {" "}
                  <img
                    src={virtual}
                    alt="fasttrack"
                    height="40px"
                    width="40px"
                  />
                </div>
                <div className="center" style={{ marginTop: "1.5rem" }}>
                  <p className="registeration text-center">
                    Virtual Experience
                  </p>
                </div>
                <div className="center">
                  {" "}
                  <p
                    className="text-center black16"
                    style={{ marginTop: "1rem" }}
                  >
                    Students experience the challenge in a virtual setting.
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        {type === "Insights" && (
          <div className="previewpageCards shadowa" key={1}>
            <div
              className="center"
              style={{ alignItems: "center", width: "100%", height: "100%" }}
            >
              <div style={{ maxWidth: "458px" }}>
                <div className="center">
                  {" "}
                  <img
                    src={insight}
                    alt="fasttrack"
                    height="40px"
                    width="40px"
                  />
                </div>
                <div className="center" style={{ marginTop: "1.5rem" }}>
                  <p className="registeration text-center">Insights</p>
                </div>
                <div className="center">
                  {" "}
                  <p
                    className="text-center black16"
                    style={{ marginTop: "1rem" }}
                  >
                    Students gain insights in a self-assessment style challenge.
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </>,
      <div className="previewpageCards shadowa" key={2}>
        <div
          className="center"
          style={{ alignItems: "center", width: "100%", height: "100%" }}
        >
          <div style={{ maxWidth: "458px" }}>
            <div className="center">
              <p className="registeration text-center">Earn Certificate</p>
            </div>
            <div className="center">
              <p className="text-center black16" style={{ marginTop: "1rem" }}>
                You can share your certificate on your varyance and LinkedIn
                profile and use it on your resume.{" "}
              </p>
            </div>
            <div
              className="center"
              style={{ marginTop: "1.5rem", gap: "0.75rem" }}
            >
              <img src={logo} alt="fasttrack" height="40px" width="40px" />
              <img src={linkedin} alt="fasttrack" height="40px" width="40px" />
            </div>
          </div>
        </div>
      </div>,
      <div className="previewpageCards shadowa" key={1}>
        <div
          className="center"
          style={{ alignItems: "center", width: "100%", height: "100%" }}
        >
          <div style={{ maxWidth: "458px" }}>
            <div className="center">
              <img src={lamp} alt="fasttrack" height="40px" width="40px" />
            </div>
            <div className="center" style={{ marginTop: "1.5rem" }}>
              <p className="registeration text-center">Skill Points</p>
            </div>
            <div className="center">
              {" "}
              <p className="text-center black16" style={{ marginTop: "1rem" }}>
                You will receive a total of{" "}
                <span style={{ color: "#E31B54" }}>
                  {test ? (totalTime ? totalTime : `tbc`) : "tbc"}
                </span>{" "}
                Skill-Points for successfully completing this challenge.
              </p>
            </div>
          </div>
        </div>
      </div>,
    ];

    const [currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentSlide((currentSlide) => (currentSlide + 1) % slides.length);
      }, 3000);

      return () => clearInterval(interval);
    }, []);

    const goToSlide = (index) => {
      setCurrentSlide(index);
    };

    return (
      <div className="carouselaa">
        {slides.map((slide, index) => (
          <div
            className={
              index === currentSlide
                ? "carousel-slideaa activeaa"
                : "carousel-slideaa"
            }
            key={index}
          >
            {slide}
          </div>
        ))}
        <div className="carousel-dotsa">
          {slides.map((_, index) => (
            <span
              key={index}
              className={
                index === currentSlide
                  ? "carousel-dota activea"
                  : "carousel-dota"
              }
              onClick={() => goToSlide(index)}
            />
          ))}
        </div>
      </div>
    );
  }
  let points =
    (difficulty === "Basic" && 10) ||
    (difficulty === "Intermediate" && 20) ||
    (difficulty === "Advanced" && 30);

  let totalpoints = points * (totalLearns + totalSkills);

  const items = [
    {
      title: "Fast Track",
      icon1: fasttrack,
      icon2: null,
      text: "Gain insights in an evaluated and time-constrained challenge and compete against students globally.",
    },
    {
      title: "Earn Certificate",
      text: "You can share your certificate on your varyance and LinkedIn profile and use it on your resume.",
      icon1: logo,
      icon2: linkedin,
    },
    {
      title: `Skill Points`,
      text: `You will receive a total of <span  class="text-start black16" style="color:#E31B54;">${totalpoints}</span> Skill-Points for successfully completing this challenge.`,
      icon1: lamp,
    },
  ];

  {
    /* <Carousel
                responsive={responsive}
                className=" "
                partialVisbile={true}
                autoPlay={true}
                pauseOnHover={true}
                partialVisible={true}
                additionalTransfrom={0}
                arrows
                autoPlaySpeed={3000}
                containerClass=""
                dotListClass=""
                draggable
                focusOnSelect={false}
                infinite
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
              > */
  }
  {
    /* <>
                  {type === "Fast-Track" && (
                    <div className="previewpageCards " key={1}>
                      <div
                        className="center"
                        style={{
                          alignItems: "center",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <div style={{ maxWidth: "458px" }}>
                          <div className="center">
                            <img
                              src={fasttrack}
                              alt="fasttrack"
                              height="40px"
                              width="40px"
                            />
                          </div>
                          <div
                            className="center"
                            style={{ marginTop: "1.5rem" }}
                          >
                            <p className="registeration text-center">
                              Fast Track
                            </p>
                          </div>
                          <div className="center">
                            {" "}
                            <p
                              className="text-center black16"
                              style={{ marginTop: "1rem" }}
                            >
                              Gain insights in an evaluated and time-constrained
                              challenge and compete against students globally.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {type === "Virtual Experience" && (
                    <div className="previewpageCards " key={1}>
                      <div
                        className="center"
                        style={{
                          alignItems: "center",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <div style={{ maxWidth: "458px" }}>
                          <div className="center">
                            {" "}
                            <img
                              src={virtual}
                              alt="fasttrack"
                              height="40px"
                              width="40px"
                            />
                          </div>
                          <div
                            className="center"
                            style={{ marginTop: "1.5rem" }}
                          >
                            <p className="registeration text-center">
                              Virtual Experience
                            </p>
                          </div>
                          <div className="center">
                            {" "}
                            <p
                              className="text-center black16"
                              style={{ marginTop: "1rem" }}
                            >
                              Students experience the challenge in a virtual
                              setting.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {type === "Insights" && (
                    <div className="previewpageCards " key={1}>
                      <div
                        className="center"
                        style={{
                          alignItems: "center",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <div style={{ maxWidth: "458px" }}>
                          <div className="center">
                            {" "}
                            <img
                              src={insight}
                              alt="fasttrack"
                              height="40px"
                              width="40px"
                            />
                          </div>
                          <div
                            className="center"
                            style={{ marginTop: "1.5rem" }}
                          >
                            <p className="registeration text-center">
                              Insights
                            </p>
                          </div>
                          <div className="center">
                            {" "}
                            <p
                              className="text-center black16"
                              style={{ marginTop: "1rem" }}
                            >
                              Students gain insights in a self-assessment style
                              challenge.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
                <div className="previewpageCards " key={2}>
                  <div
                    className="center"
                    style={{
                      alignItems: "center",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <div style={{ maxWidth: "458px" }}>
                      <div className="center">
                        <p className="registeration text-center">
                          Earn Certificate
                        </p>
                      </div>
                      <div className="center">
                        <p
                          className="text-center black16"
                          style={{ marginTop: "1rem" }}
                        >
                          You can share your certificate on your varyance and
                          LinkedIn profile and use it on your resume.{" "}
                        </p>
                      </div>
                      <div
                        className="center"
                        style={{ marginTop: "1.5rem", gap: "0.75rem" }}
                      >
                        <img
                          src={logo}
                          alt="fasttrack"
                          height="40px"
                          width="40px"
                        />
                        <img
                          src={linkedin}
                          alt="fasttrack"
                          height="40px"
                          width="40px"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="previewpageCards " key={1}>
                  <div
                    className="center"
                    style={{
                      alignItems: "center",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <div style={{ maxWidth: "458px" }}>
                      <div className="center">
                        <img
                          src={lamp}
                          alt="fasttrack"
                          height="40px"
                          width="40px"
                        />
                      </div>
                      <div className="center" style={{ marginTop: "1.5rem" }}>
                        <p className="registeration text-center">
                          Skill Points
                        </p>
                      </div>
                      <div className="center">
                        {" "}
                        <p
                          className="text-center black16"
                          style={{ marginTop: "1rem" }}
                        >
                          You will receive a total of{" "}
                          <span style={{ color: "#E31B54" }}>
                            {test ? (totalTime ? totalTime : `tbc`) : "tbc"}
                          </span>{" "}
                          Skill-Points for successfully completing this
                          challenge.
                        </p>
                      </div>
                    </div>
                  </div>
                </div> */
  }
  const sliderRef = useRef(null);
  let isDown = false;
  let startX;
  let scrollLeft;

  const handleMouseDown = (e) => {
    e.stopPropagation();
    isDown = true;
    startX = e.pageX - sliderRef.current.offsetLeft;
    scrollLeft = sliderRef.current.scrollLeft;
  };

  const handleMouseLeave = () => {
    isDown = false;
  };

  const handleMouseUp = () => {
    isDown = false;
  };

  const handleMouseMove = (e) => {
    e.stopPropagation();
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX - sliderRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Scroll speed
    sliderRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleTouchStart = (e) => {
    startX = e.touches[0].pageX - sliderRef.current.offsetLeft;
    scrollLeft = sliderRef.current.scrollLeft;
  };

  const handleTouchMove = (e) => {
    const x = e.touches[0].pageX - sliderRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Scroll speed
    sliderRef.current.scrollLeft = scrollLeft - walk;
  };

  return (
    <div
      className="newcarousel"
      style={{
        paddingTop: "4rem",
        paddingBottom: "4rem",
      }}
    >
      <div
        className="marginsleftrightCompany"
        style={{
          overflow: "visible", // Allow overflow to be visible
        }}
      >
        <div
          className="d-flex"
          style={{
            justifyContent: "center",
          }}
        >
          <div className="headerCompany">
            <div className="dsasdad">
              <Slider {...settings} infinite={false}>
                {items.map((item, index) => (
                  <div className="newPreviewPageCards" key={index}>
                    <div className="d-flex" style={{ gap: "0.75rem" }}>
                      <img
                        src={item.icon1}
                        alt="fasttrack"
                        height="40px"
                        width="40px"
                      />
                      {item?.icon2 && (
                        <img
                          src={item?.icon2}
                          alt="fasttrack"
                          height="40px"
                          width="40px"
                        />
                      )}
                    </div>
                    <div style={{ marginTop: "1.5rem" }}>
                      <p className="registeration">{item.title}</p>

                      <div
                        className="black16 text-start"
                        style={{ marginTop: "1rem", maxWidth: "458px" }}
                        dangerouslySetInnerHTML={{
                          __html: item?.text,
                        }}
                      />
                      {/* <p
                        className="text-start black16"
                        style={{ marginTop: "1rem", maxWidth: "458px" }}
                      >
                        {item?.text}
                      </p> */}
                    </div>
                  </div>
                ))}{" "}
              </Slider>
              {/* <Carousel
                responsive={responsive}
                className=" "
                partialVisbile={true}
                autoPlay={true}
                pauseOnHover={true}
                partialVisible={true}
                additionalTransfrom={0}
                arrows
                autoPlaySpeed={3000}
                containerClass=""
                dotListClass=""
                draggable
                focusOnSelect={false}
                infinite
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
              > */}
              {/* <>
                  {type === "Fast-Track" && (
                    <div className="previewpageCards " key={1}>
                      <div
                        className="center"
                        style={{
                          alignItems: "center",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <div style={{ maxWidth: "458px" }}>
                          <div className="center">
                            <img
                              src={fasttrack}
                              alt="fasttrack"
                              height="40px"
                              width="40px"
                            />
                          </div>
                          <div
                            className="center"
                            style={{ marginTop: "1.5rem" }}
                          >
                            <p className="registeration text-center">
                              Fast Track
                            </p>
                          </div>
                          <div className="center">
                            {" "}
                            <p
                              className="text-center black16"
                              style={{ marginTop: "1rem" }}
                            >
                              Gain insights in an evaluated and time-constrained
                              challenge and compete against students globally.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {type === "Virtual Experience" && (
                    <div className="previewpageCards " key={1}>
                      <div
                        className="center"
                        style={{
                          alignItems: "center",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <div style={{ maxWidth: "458px" }}>
                          <div className="center">
                            {" "}
                            <img
                              src={virtual}
                              alt="fasttrack"
                              height="40px"
                              width="40px"
                            />
                          </div>
                          <div
                            className="center"
                            style={{ marginTop: "1.5rem" }}
                          >
                            <p className="registeration text-center">
                              Virtual Experience
                            </p>
                          </div>
                          <div className="center">
                            {" "}
                            <p
                              className="text-center black16"
                              style={{ marginTop: "1rem" }}
                            >
                              Students experience the challenge in a virtual
                              setting.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {type === "Insights" && (
                    <div className="previewpageCards " key={1}>
                      <div
                        className="center"
                        style={{
                          alignItems: "center",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <div style={{ maxWidth: "458px" }}>
                          <div className="center">
                            {" "}
                            <img
                              src={insight}
                              alt="fasttrack"
                              height="40px"
                              width="40px"
                            />
                          </div>
                          <div
                            className="center"
                            style={{ marginTop: "1.5rem" }}
                          >
                            <p className="registeration text-center">
                              Insights
                            </p>
                          </div>
                          <div className="center">
                            {" "}
                            <p
                              className="text-center black16"
                              style={{ marginTop: "1rem" }}
                            >
                              Students gain insights in a self-assessment style
                              challenge.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
                <div className="previewpageCards " key={2}>
                  <div
                    className="center"
                    style={{
                      alignItems: "center",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <div style={{ maxWidth: "458px" }}>
                      <div className="center">
                        <p className="registeration text-center">
                          Earn Certificate
                        </p>
                      </div>
                      <div className="center">
                        <p
                          className="text-center black16"
                          style={{ marginTop: "1rem" }}
                        >
                          You can share your certificate on your varyance and
                          LinkedIn profile and use it on your resume.{" "}
                        </p>
                      </div>
                      <div
                        className="center"
                        style={{ marginTop: "1.5rem", gap: "0.75rem" }}
                      >
                        <img
                          src={logo}
                          alt="fasttrack"
                          height="40px"
                          width="40px"
                        />
                        <img
                          src={linkedin}
                          alt="fasttrack"
                          height="40px"
                          width="40px"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="previewpageCards " key={1}>
                  <div
                    className="center"
                    style={{
                      alignItems: "center",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <div style={{ maxWidth: "458px" }}>
                      <div className="center">
                        <img
                          src={lamp}
                          alt="fasttrack"
                          height="40px"
                          width="40px"
                        />
                      </div>
                      <div className="center" style={{ marginTop: "1.5rem" }}>
                        <p className="registeration text-center">
                          Skill Points
                        </p>
                      </div>
                      <div className="center">
                        {" "}
                        <p
                          className="text-center black16"
                          style={{ marginTop: "1rem" }}
                        >
                          You will receive a total of{" "}
                          <span style={{ color: "#E31B54" }}>
                            {test ? (totalTime ? totalTime : `tbc`) : "tbc"}
                          </span>{" "}
                          Skill-Points for successfully completing this
                          challenge.
                        </p>
                      </div>
                    </div>
                  </div>
                </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FourthSection;

const CustomDots = ({ onClick, active }) => {
  return (
    <div
      onClick={onClick}
      style={{
        width: active ? "40px" : "8px",
        height: "8px",
        backgroundColor: active ? "#e31b54" : "#ccc",
        borderRadius: "4px",
        margin: "0 4px",
        cursor: "pointer",
        transition: "all 0s ease-in-out",
      }}
    />
  );
};
