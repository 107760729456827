// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.theme16 {
  color: #060606;

  font-size: 2.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.625rem; /* 116.667% */
}

.carousel-container {
  display: flex;
  overflow: hidden;
}

.carousel-item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1.5rem;
}

.theme16 {
  font-size: 1.6rem;
}

.parent {
  overflow: hidden;
}

.slider img {
  display: block;
}
`, "",{"version":3,"sources":["webpack://./src/pages/CompanyPage/gallery.css"],"names":[],"mappings":"AAAA;EACE,cAAc;;EAEd,kBAAkB;EAClB,kBAAkB;EAClB,gBAAgB;EAChB,qBAAqB,EAAE,aAAa;AACtC;;AAEA;EACE,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,oBAAoB;AACtB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".theme16 {\n  color: #060606;\n\n  font-size: 2.25rem;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 2.625rem; /* 116.667% */\n}\n\n.carousel-container {\n  display: flex;\n  overflow: hidden;\n}\n\n.carousel-item {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-right: 1.5rem;\n}\n\n.theme16 {\n  font-size: 1.6rem;\n}\n\n.parent {\n  overflow: hidden;\n}\n\n.slider img {\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
