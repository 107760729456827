const GroupIcon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.1166 12.2832L18.2333 12.8582C18.3603 13.3494 18.2567 13.8717 17.9517 14.2772C17.6468 14.6827 17.1738 14.9273 16.6666 14.9415H13.4916C13.4923 16.2903 12.423 17.3966 11.0749 17.4415H4.16659C3.40371 17.4289 2.68836 17.0686 2.2241 16.4631C1.75984 15.8576 1.59749 15.0732 1.78326 14.3332L1.95826 13.4999C2.16175 12.3519 3.15087 11.5096 4.31659 11.4915H10.9333C11.2286 11.1386 11.6648 10.9342 12.1249 10.9332H16.5333C17.3116 10.958 17.969 11.5186 18.1166 12.2832ZM16.6499 13.6832C16.7516 13.6794 16.8463 13.6306 16.9083 13.5499C17.0173 13.4399 17.0721 13.2874 17.0583 13.1332L16.9416 12.5582C16.919 12.3719 16.77 12.2264 16.5833 12.2082H12.1749C12.079 12.2088 11.9892 12.2553 11.9333 12.3332C11.6943 12.6084 11.3477 12.7665 10.9833 12.7665H4.36659C3.7961 12.7804 3.31808 13.2022 3.23326 13.7665L3.05826 14.5999C2.97278 15.0103 3.07348 15.4375 3.33326 15.7665C3.53617 16.0224 3.84029 16.1775 4.16659 16.1915H11.0499C11.7206 16.147 12.236 15.5801 12.2166 14.9082C12.2302 14.2276 12.7859 13.6831 13.4666 13.6832H16.6499Z"
      fill={color || "#344054"}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9166 6.66653C10.9166 8.50748 9.42421 9.99987 7.58326 9.99987C5.74231 9.99987 4.24992 8.50748 4.24992 6.66653V5.3582C4.24992 3.8118 5.50353 2.5582 7.04992 2.5582H8.14159C9.67822 2.57192 10.9167 3.82151 10.9166 5.3582V6.66653ZM7.04992 3.8082C6.19388 3.8082 5.49992 4.50216 5.49992 5.3582L5.52492 6.66653C5.65792 7.71584 6.55055 8.50248 7.60826 8.50248C8.66596 8.50248 9.5586 7.71584 9.69159 6.66653V5.3582C9.69159 4.50216 8.99763 3.8082 8.14159 3.8082H7.04992Z"
      fill={color || "#344054"}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.2333 6.87486C17.2333 8.25558 16.114 9.37486 14.7333 9.37486C13.3525 9.37486 12.2333 8.25558 12.2333 6.87486V5.91653C12.2331 4.76963 13.1532 3.83474 14.2999 3.81653H15.1333C16.2931 3.81653 17.2333 4.75673 17.2333 5.91653V6.87486ZM14.3166 5.0832C13.8564 5.0832 13.4833 5.45629 13.4833 5.91653V6.87486C13.4833 7.56522 14.0429 8.12486 14.7333 8.12486C15.4236 8.12486 15.9833 7.56522 15.9833 6.87486V5.91653C15.9833 5.45629 15.6102 5.0832 15.1499 5.0832H14.3166Z"
      fill={color || "#344054"}
    />
  </svg>
);

export default GroupIcon;
