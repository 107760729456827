import { useEffect, useState } from "react";
import logo from "../../assets/images/logo.png";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import "./result.css";
import chart from "../../assets/images/Chart.png";
import { setLogin } from "../../state";
const Result = () => {
  const navigate = useNavigate();

  const { id } = useParams();
  const user = useSelector((state) => state.user);
  const [result, setResult] = useState(null);
  const [isMounted, setIsMounted] = useState(true);
  const [average, setAverage] = useState(null);
  const [updatedUser, setUpdatedUser] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/get/results`,
          {
            id: id,
            email: user.EmailAddress,
          }
        );

        if (isMounted) {
          if (response.data === "unAuthorized") {
            navigate("/student/dashboard");
          } else if (response.data === "Record not found") {
            navigate("/student/dashboard");
          } else if (response.data.msg === "success") {
            setResult(response.data.result);
            setAverage(response.data.average);
            setUpdatedUser(response.data.user);
            // dispatch(setLogin(response.data.user));
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (id) {
      fetchData();
    }

    // Cleanup function to set isMounted to false when the component is unmounted
    return () => {
      setIsMounted(false);
    };
  }, [id, isMounted]);

  return (
    <>
      <div
        style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <div
          className="p-3 d-flex shadowa"
          style={{
            justifyContent: "space-between",
            background: "#FFF",
            position: "relative",
          }}
        >
          {" "}
          <div className="mt-0 mb-0 m-4">
            <img
              src={logo}
              alt="logo img"
              height="45px"
              width="45px"
              className="shadowa rounded-3"
              onClick={() => navigate("/")}
              style={{ cursor: "pointer" }}
            />
          </div>{" "}
          <div
            className=""
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/student/dashboard/challenges")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="23"
              viewBox="0 0 22 23"
              fill="none"
            >
              <path
                d="M12.0693 11.5074L19.0318 4.56837C19.3227 4.27475 19.3227 3.79908 19.0318 3.50546C18.7461 3.20659 18.2742 3.19761 17.9775 3.4854L11.0149 10.4244L4.14186 3.4854C4.00095 3.33514 3.80489 3.25 3.59977 3.25C3.39466 3.25 3.1986 3.33514 3.05769 3.4854C2.79978 3.76909 2.79978 4.20447 3.05769 4.48815L9.93075 11.4171L2.96817 18.3461C2.67728 18.6397 2.67728 19.1154 2.96817 19.409C3.10665 19.552 3.29711 19.6317 3.49533 19.6296C3.69738 19.6461 3.89768 19.5812 4.05234 19.4491L11.0149 12.5101L17.9775 19.5294C18.116 19.6724 18.3064 19.7521 18.5047 19.75C18.7027 19.7509 18.8927 19.6714 19.0318 19.5294C19.3227 19.2357 19.3227 18.7601 19.0318 18.4664L12.0693 11.5074Z"
                fill="#98A2B3"
              />
            </svg>
          </div>
        </div>
        <div
          className="resultbackgrounddiv d-flex"
          style={{
            minHeight: "calc(100vh - 80px)",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="row col-md-9">
            <div className="col-md-8 ">
              <div style={{ display: "flex" }}>
                <div>
                  <div className="d-flex" style={{ gap: "33px" }}>
                    <div
                      className="rounded-3 shadowa p-4 d-flex"
                      style={{
                        background: "#FFF",
                        justifyContent: "center",
                        alignItems: "center",
                        minHeight: "129px",
                        minWidth: "129px",
                      }}
                    >
                      {result && (
                        <img
                          src={JSON.parse(result.preview.company).img}
                          alt="company image"
                          height="45px"
                        />
                      )}
                    </div>
                    <div>
                      {result && (
                        <p className="congrats">
                          {result.percentage > 33
                            ? "Congratuliations! You just competed a challenge."
                            : "Opps, Better Luck Next Time"}
                        </p>
                      )}
                      {result && (
                        <p className="introduction">{result.preview.title}</p>
                      )}
                    </div>
                  </div>

                  <div className="cantget">
                    <p>
                      Can't get enough?{" "}
                      <span
                        onClick={() =>
                          navigate("/student/dashboard/challenges")
                        }
                      >
                        Explore more challenges
                      </span>
                    </p>{" "}
                  </div>
                </div>
              </div>
              <div className="downloadcard  p-4 shadowa">
                <div style={{ cursor: "pointer" }}>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="16"
                      viewBox="0 0 19 16"
                      fill="none"
                    >
                      <path
                        d="M8.92482 11.7833L5.81648 8.68333C5.68468 8.55199 5.6106 8.37357 5.6106 8.1875C5.6106 8.00143 5.68468 7.82301 5.81648 7.69167C6.09368 7.42605 6.53095 7.42605 6.80815 7.69167L8.71649 9.60833V1.2C8.71649 0.813401 9.02989 0.5 9.41648 0.5C9.80309 0.5 10.1165 0.813401 10.1165 1.2V9.60833L12.0248 7.69167C12.302 7.42605 12.7393 7.42605 13.0165 7.69167C13.1483 7.82301 13.2224 8.00143 13.2224 8.1875C13.2224 8.37357 13.1483 8.55199 13.0165 8.68333L9.90815 11.7833C9.77987 11.9174 9.60204 11.9928 9.41648 11.9917C9.23053 11.9948 9.05192 11.9191 8.92482 11.7833Z"
                        fill="#E31B54"
                      />
                      <path
                        d="M13.7665 5.01667H14.7748C16.8717 5.06223 18.537 6.79463 18.4998 8.89167V12.0667C18.537 14.1637 16.8717 15.8961 14.7748 15.9417L4.05816 15.875C1.96129 15.8294 0.295947 14.097 0.333157 12V8.825C0.308567 7.81347 0.687524 6.83374 1.38639 6.10204C2.08525 5.37035 3.04657 4.94684 4.05816 4.925H5.06649C5.45309 4.925 5.76649 5.2384 5.76649 5.625C5.76649 6.0116 5.45309 6.325 5.06649 6.325H4.05816C3.41704 6.34447 2.81039 6.61977 2.37357 7.08946C1.93676 7.55915 1.70614 8.18415 1.73316 8.825V11.975C1.70614 12.6158 1.93676 13.2408 2.37357 13.7105C2.81039 14.1802 3.41704 14.4555 4.05816 14.475L14.7748 14.5917C15.4159 14.5722 16.0226 14.2969 16.4594 13.8272C16.8962 13.3575 17.1268 12.7325 17.0998 12.0917V8.91667C17.1268 8.27582 16.8962 7.65082 16.4594 7.18113C16.0226 6.71144 15.4159 6.43614 14.7748 6.41667H13.7665C13.3799 6.41667 13.0665 6.10327 13.0665 5.71667C13.0665 5.33007 13.3799 5.01667 13.7665 5.01667Z"
                        fill="#E31B54"
                      />
                    </svg>
                  </div>
                  <p className="download">Download Certicate</p>
                  <p className="PDF">PDF</p>
                </div>
              </div>
            </div>
            <div
              className="col-md-3 rightdiv shadowa"
              style={{
                paddingTop: "20px",
                paddingLeft: "30px",
                paddingBottom: "45px",
              }}
            >
              <p className="totalscore">Total Score</p>
              <div className="d-flex">
                <p className="percentage">{result && result.percentage} %</p>
                {result && average && (
                  <p className="greaterless">
                    {result.percentage > average && (
                      <>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="24"
                          viewBox="0 0 14 24"
                          fill="none"
                        >
                          <path
                            d="M7.00004 9L13 17H1L7.00004 9Z"
                            fill="#00B929"
                          />
                        </svg>
                        {(result.percentage - average).toFixed(2)} %
                      </>
                    )}
                    {result.percentage < average && (
                      <>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="24"
                          viewBox="0 0 14 24"
                          fill="none"
                        >
                          <path
                            d="M7.00004 15L1 7H13L7.00004 15Z"
                            fill="#FF0000"
                          />
                        </svg>
                        {(average - result.percentage).toFixed(2)}
                      </>
                    )}
                  </p>
                )}
              </div>{" "}
              <p className="compared">Compared to {average}% average.</p>
              <div
                className=" d-flex col-md-12"
                style={{ justifyContent: "center", marginTop: "39px" }}
              >
                <img src={chart} alt="chart img" height="77px" width="220px" />
              </div>
              {result && average && (
                <p className="expanded mt-5">
                  {result.percentage > 33 && "Expanded"} Skills
                </p>
              )}
              {result && average && (
                <p className="goodjob ">
                  {result.percentage > 33 && "Good job"}
                  {result.percentage < 33 && "Better luck next time"}
                </p>
              )}
              {result && average && (
                <div
                  className="whitescrollbar "
                  style={{ overflow: "auto", marginTop: "17px", width: "100%" }}
                >
                  {result.preview.learns.map((ski, index) => (
                    <div
                      key={index}
                      className="d-flex"
                      style={{
                        gap: "8px",
                        marginTop: "12px",
                      }}
                    >
                      <div
                        className="d-flex"
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                        >
                          <circle cx="7" cy="7" r="7" fill="white" />
                          <circle cx="7" cy="7" r="2" fill="#17D74D" />
                        </svg>
                      </div>
                      <p className="skillname" style={{ width: "92px" }}>
                        {" "}
                        {ski}
                      </p>
                      <p className="scoregot" style={{ width: "62px" }}>
                        + {result.score}
                      </p>

                      <p
                        className="totalgainedpoints"
                        style={{ width: "31px" }}
                      >
                        {updatedUser &&
                          updatedUser.Skills.length > 0 &&
                          updatedUser.Skills.find(
                            (skillObj) => skillObj.skill === ski
                          ).points}
                      </p>
                    </div>
                  ))}
                  {result &&
                    result.preview.skills.map((ski, index) => (
                      <div
                        key={index}
                        className="d-flex"
                        style={{
                          gap: "8px",
                          marginTop: "12px",
                        }}
                      >
                        <div
                          className="d-flex"
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                          >
                            <circle cx="7" cy="7" r="7" fill="white" />
                            <circle cx="7" cy="7" r="2" fill="#17D74D" />
                          </svg>
                        </div>
                        <p className="skillname" style={{ width: "92px" }}>
                          {" "}
                          {ski}
                        </p>
                        <p className="scoregot" style={{ width: "62px" }}>
                          + {result.score}
                        </p>

                        <p
                          className="totalgainedpoints"
                          style={{ width: "31px" }}
                        >
                          {updatedUser &&
                            updatedUser.Skills.length > 0 &&
                            updatedUser.Skills.find(
                              (skillObj) => skillObj.skill === ski
                            ).points}
                        </p>
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Result;
