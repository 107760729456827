import EditIcon from "@mui/icons-material/Edit";
const Volunteering = ({ owner, user }) => {
  return (
    <div className=" shadowa">
      <div
        className="innerglass tablescroll"
        style={{ maxHeight: "300px", overflow: "auto", padding: "1.25rem" }}
      >
        <div className="space flex-wrap" style={{ gap: "1rem" }}>
          <p className="filmo20">Volunteering & Leaderships</p>
          {owner && (
            <EditIcon
              className="hoverred"
              style={{ marginTop: "0.25rem", width: "24px", cursor: "pointer" }}
            />
          )}
        </div>
        {user?.volunteer?.map((vol, index) => (
          <div key={index} style={{ marginTop: "1.5rem" }}>
            <div className="d-flex flex-wrap" style={{ gap: "1rem" }}>
              {vol?.logo && (
                <img
                  src={vol?.logo}
                  alt="university logo"
                  className="shadowa"
                  style={{
                    maxWidth: "32px",
                    maxHeight: "32px",
                    padding: "2px",
                    background: "#FFF",
                  }}
                />
              )}
              <div>
                <p className="black16 text-start">{vol?.position}</p>
                <p className="gray14" style={{ marginTop: "0.25rem" }}>
                  {vol?.companyName}
                </p>
                <p className="gray14" style={{ marginTop: "0.75rem" }}>
                  {vol?.description}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default Volunteering;
