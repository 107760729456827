const User = ({ usersWithDetails, index, test, navigate }) => {
  const user = usersWithDetails.find(
    (user) => user?.userDetails?.user?._id === test?.user?._id
  );

  return (
    <tr key={index} className="bottomborder">
      <td
        className="text-start trnew d-flex align-items-center w-100"
        onClick={() => {
          navigate(`/dashboard/user/${test?.user?.uniqueId}`);
        }}
        style={{ width: "40%", paddingRight: "0" }}
      >
        <img
          className="shadowa"
          src={
            user?.userDetails?.user?.profilePhoto
              ? user?.userDetails?.user?.profilePhoto
              : test?.user?.profilePhoto
          }
          alt="profile"
          height={"32px"}
          width={"32px"}
          style={{ borderRadius: "32px", marginRight: "8px" }}
        />
        <p className="trnew" style={{ padding: "0px" }}>
          {user?.userDetails?.user?.firstName
            ? user?.userDetails?.user?.firstName
            : test?.user?.firstName}{" "}
          {user?.userDetails?.user?.LastName
            ? user?.userDetails?.user?.LastName
            : test?.user?.LastName}
        </p>
      </td>
      <td className="text-center trnew" style={{ width: "30%" }}>
        {index + 1}
      </td>
      <td className="text-center trnew" style={{ width: "30%" }}>
        {test?.percentage}%
      </td>
    </tr>
  );
};
export default User;
