import { useEffect, useState } from "react";
import "./jobpage.css";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import TurnedInIcon from "@mui/icons-material/TurnedIn";
import WorkIcon from "@mui/icons-material/Work";
import AccessTimeSharpIcon from "@mui/icons-material/AccessTimeSharp";
import TurnedInNotOutlinedIcon from "@mui/icons-material/TurnedInNotOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
const JobPage = ({ job, handleNumChange }) => {
  const [company, setCompany] = useState(() => {
    try {
      return job?.company ? JSON.parse(job.company) : null;
    } catch (error) {
      console.error("Failed to parse company data:", error);
      return null;
    }
  });

  useEffect(() => {
    setCompany(job?.company ? JSON.parse(job.company) : null);
  }, [job]);

  return (
    <div style={{ width: "100%" }} className="jobpage">
      <div className="space w-100" style={{ alignItems: "center" }}>
        {company?.img && (
          <div
            style={{
              padding: "0.5rem",
              alignItems: "center",
              height: "36px",
              width: "36px",
            }}
            className="shadowa rounded-3 center"
          >
            <img
              className="jobcard__logo rounded-2"
              src={company?.img}
              height="20px"
              width="20px"
            />
          </div>
        )}
        <MoreVertIcon style={{ cursor: "pointer" }} />
      </div>
      <div style={{ marginTop: "1.25rem" }}>
        <p className="black16 text-start">{job?.title}</p>
        <p style={{ marginTop: "0.4rem" }} className="pink12">
          {company?.title}
        </p>
      </div>

      <div
        className="d-flex flex-wrap"
        style={{ marginTop: "1.25rem", gap: "0.75rem" }}
      >
        <div className="jobskillwrapper">
          <WorkIcon
            style={{ height: "16px", width: "16px", color: "#696969" }}
          />
          {job?.position}
        </div>
        <div className="jobskillwrapper">
          <AccessTimeSharpIcon
            style={{ height: "16px", width: "16px", color: "#696969" }}
          />
          {job?.timeRequirement}
        </div>
        {job?.selectedItem?.map((tag, index) => (
          <div key={index} className="jobskillwrapper">
            <img
              src={tag?.icon}
              alt="ski.name"
              height={"16px"}
              width={"16px"}
            />
            {tag?.text}
          </div>
        ))}
        {job?.country?.map((con, index) => (
          <div key={index} className="jobskillwrapper">
            {con?.City?.map((city, index) => city?.name)}
            <div>{con?.Country?.flag}</div>
          </div>
        ))}
      </div>
      <div style={{ marginTop: "0.75rem" }}>
        <div className="resultintaketable d-flex flex-wrap">
          {company?.industry}
          {company?.country?.map((con, index) => (
            <div key={index} className="d-flex">
              {con?.City?.map((city, i) => (
                <p className="resultintaketable" key={i}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="4"
                    height="5"
                    viewBox="0 0 4 5"
                    fill="none"
                    style={{ marginLeft: "6px", marginRight: "6px" }}
                  >
                    <circle cx="2" cy="2.06268" r="2" fill="#D9D9D9" />
                  </svg>
                  {city?.name}, {con?.Country?.name}
                </p>
              ))}
            </div>
          ))}
        </div>
      </div>
      <div style={{ marginTop: "1.5rem" }}>
        <div
          className="allpoppins tablescroll"
          style={{
            overflow: "auto",
            fontFamily: "'Poppins', sans-serif",
            fontSize: "14px",
          }}
          dangerouslySetInnerHTML={{
            __html: job?.jobDescription,
          }}
        />
      </div>

      <div className="center">
        <div
          style={{ position: "fixed", bottom: "40px" }}
          className="jobpagebuttonwrapper"
        >
          <button className="continuebutton" style={{ width: "180px" }}>
            Apply
          </button>
          <button className="buttonwrappers">
            <TurnedInNotOutlinedIcon
              style={{ width: "20px", height: "20px" }}
            />
          </button>
          <button className="buttonwrappers">
            <ShareOutlinedIcon style={{ width: "20px", height: "20px" }} />
          </button>
          <button
            className="buttonwrappers"
            onClick={() => handleNumChange(-1)}
          >
            <KeyboardArrowLeftOutlinedIcon
              style={{ width: "20px", height: "20px" }}
            />
          </button>

          <button className="buttonwrappers" onClick={() => handleNumChange(1)}>
            <KeyboardArrowRightOutlinedIcon
              style={{ width: "20px", height: "20px" }}
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default JobPage;
