const HeadingLogo = ({ taskCounter, steps, companyimg, title, name }) => {
  return (
    <div
      className="challengeattemptheader space"
      style={{ marginTop: "2.5rem" }}
    >
      <div>
        {steps !== 4 && (
          <p className="black14">
            Task {taskCounter + 1} : {title}
          </p>
        )}
        <p style={{ marginTop: "0.75rem", fontSize: "1.25rem" }}>
          {steps === 0 &&
            taskCounter === 0 &&
            `Hi ${name}! Welcome to the challenge`}
          {steps === 0 &&
            taskCounter > 0 &&
            `Hi ${name}! Welcome to the next Task`}
          {steps === 1 && "Important key terms and frameworks"}
          {steps === 2 && "Relevant Resources"}
          {steps === 3 && "Questions"}
        </p>
      </div>
      <div className="companylogotestpagediv center shadowa">
        <img src={companyimg} alt="company logo" className="companyImg" />
      </div>
    </div>
  );
};
export default HeadingLogo;
