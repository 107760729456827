import { useEffect, useRef } from "react";
import ChatBottom from "./ChatBottom";
import ChatTop from "./ChatTop";
import Message from "./Message";

const Chat = ({
  otheruser,
  messages,
  message,
  setMessage,
  handleSendMessage,
  navigate,
  isNonMob560,
  chatmessages,
  setTest2,
  user,
  handleShortList,
  isNonMobile600,
}) => {
  const messagesEndRef = useRef(null);

  useEffect(() => {
    scrollToBottom();
  }, [chatmessages]);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "instant" });
    }
  };

  return (
    <div
      style={{
        width: "100%",
        height: isNonMob560
          ? "calc(100vh - 4.375rem)"
          : "calc(100vh - 7.375rem)",
        overflowY: "auto",
        borderRight: "1px solid #EAECF0",
        position: "relative",
      }}
      className="space flex-column"
    >
      <ChatTop
        otheruser={otheruser}
        navigate={navigate}
        user={user}
        chatmessages={chatmessages}
        setTest2={setTest2}
        handleShortList={handleShortList}
      />

      <div
        style={{
          height: "100%",
          overflowY: "auto",
          padding: isNonMobile600 ? "24px 30px 24px 30px" : "0.5rem",
          gap: "1.5rem",

          overflowX: "hidden",
        }}
        className="whitescrollbar d-flex flex-column"
      >
        {chatmessages &&
        chatmessages?.messages &&
        chatmessages?.messages?.length > 0 ? (
          chatmessages?.messages?.map((msg, index) => (
            <div key={index}>
              <Message
                msg={msg}
                otheruser={otheruser}
                user={user}
                isNonMobile600={isNonMobile600}
              />
              <div ref={messagesEndRef} />
            </div>
          ))
        ) : (
          <div
            style={{ height: "100%", width: "100%" }}
            className="center align-items-center"
          >
            <p className="black14">No Messages yet, start a conversation</p>
          </div>
        )}
      </div>

      <ChatBottom
        message={message}
        setMessage={setMessage}
        handleSendMessage={handleSendMessage}
      />
    </div>
  );
};
export default Chat;
