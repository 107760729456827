// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.timecommitment {
  color: var(--Gray-900, #101828);
  /* Sub Header 2/semi bold */
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 145%; /* 29px */
}
.expectedduration {
  color: var(--Gray-900, #101828);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.toolshow {
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: #fff;
}
.toolshow p {
  color: var(--Gray-800, #1d2939);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
  cursor: pointer;
}
.selected {
  background-color: #fea3b4;
}
.selected p {
  color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Challenge/step4.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;EAC/B,2BAA2B;EAC3B,oBAAoB;EACpB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,SAAS;AAC9B;AACA;EACE,+BAA+B;EAC/B,oBAAoB;EACpB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,aAAa;AAClC;;AAEA;EACE,aAAa;EACb,iBAAiB;EACjB,uBAAuB;EACvB,mBAAmB;EACnB,QAAQ;EACR,kBAAkB;EAClB,0CAA0C;EAC1C,gBAAgB;AAClB;AACA;EACE,+BAA+B;EAC/B,oBAAoB;EACpB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,SAAS;EAC5B,eAAe;AACjB;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,WAAW;AACb","sourcesContent":[".timecommitment {\n  color: var(--Gray-900, #101828);\n  /* Sub Header 2/semi bold */\n  font-family: Poppins;\n  font-size: 20px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: 145%; /* 29px */\n}\n.expectedduration {\n  color: var(--Gray-900, #101828);\n  font-family: Poppins;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: 20px; /* 142.857% */\n}\n\n.toolshow {\n  display: flex;\n  padding: 8px 12px;\n  justify-content: center;\n  align-items: center;\n  gap: 8px;\n  border-radius: 6px;\n  border: 1px solid var(--Gray-300, #d0d5dd);\n  background: #fff;\n}\n.toolshow p {\n  color: var(--Gray-800, #1d2939);\n  font-family: Poppins;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 20px; /* 125% */\n  cursor: pointer;\n}\n.selected {\n  background-color: #fea3b4;\n}\n.selected p {\n  color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
