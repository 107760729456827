// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.supportdivs {
  padding: 24px;
  max-width: 374px;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0;
  border-radius: 5px;

  /* Trans Card v2 */
}

.hoverdarkblacksvg {
  fill: #98a2b3;
  color: #98a2b3;
  transition: color 0.3s ease-in-out;
}
.hoverdarkblacksvg:hover {
  fill: #0c111d;
}
.supportseconddiv {
  padding: 24px;
  max-width: 374px;
  gap: 24px;
  flex: 1 0;
  cursor: pointer;
  border-radius: 5px;
  border-top: 2px solid var(--Base-White, #fff);
  border-left: 2px solid var(--Base-White, #fff);
  background: rgba(255, 255, 255, 0.2);
  transition: all ease-in-out 0.2s;
  /* Trans Card v2 */
}
.supportseconddiv:hover {
  background: #fff 90%;
}
.supportseconddiv:hover .black16 {
  color: #e31b54;
}
.supportseconddiv:hover .testingicon {
  color: #e31b54;
}
.supportThirdDiv {
  padding: 24px;
  max-width: 374px;

  gap: 24px;
  flex: 1 0;

  border-radius: 5px;
  border-top: 2px solid var(--Base-White, #fff);
  border-left: 2px solid var(--Base-White, #fff);
  background: rgba(255, 255, 255, 0.2);
}
`, "",{"version":3,"sources":["webpack://./src/pages/Support/support.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gBAAgB;EAChB,uBAAuB;EACvB,SAAS;EACT,SAAW;EACX,kBAAkB;;EAElB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,cAAc;EACd,kCAAkC;AACpC;AACA;EACE,aAAa;AACf;AACA;EACE,aAAa;EACb,gBAAgB;EAChB,SAAS;EACT,SAAW;EACX,eAAe;EACf,kBAAkB;EAClB,6CAA6C;EAC7C,8CAA8C;EAC9C,oCAAoC;EACpC,gCAAgC;EAChC,kBAAkB;AACpB;AACA;EACE,oBAAoB;AACtB;AACA;EACE,cAAc;AAChB;AACA;EACE,cAAc;AAChB;AACA;EACE,aAAa;EACb,gBAAgB;;EAEhB,SAAS;EACT,SAAW;;EAEX,kBAAkB;EAClB,6CAA6C;EAC7C,8CAA8C;EAC9C,oCAAoC;AACtC","sourcesContent":[".supportdivs {\n  padding: 24px;\n  max-width: 374px;\n  align-items: flex-start;\n  gap: 24px;\n  flex: 1 0 0;\n  border-radius: 5px;\n\n  /* Trans Card v2 */\n}\n\n.hoverdarkblacksvg {\n  fill: #98a2b3;\n  color: #98a2b3;\n  transition: color 0.3s ease-in-out;\n}\n.hoverdarkblacksvg:hover {\n  fill: #0c111d;\n}\n.supportseconddiv {\n  padding: 24px;\n  max-width: 374px;\n  gap: 24px;\n  flex: 1 0 0;\n  cursor: pointer;\n  border-radius: 5px;\n  border-top: 2px solid var(--Base-White, #fff);\n  border-left: 2px solid var(--Base-White, #fff);\n  background: rgba(255, 255, 255, 0.2);\n  transition: all ease-in-out 0.2s;\n  /* Trans Card v2 */\n}\n.supportseconddiv:hover {\n  background: #fff 90%;\n}\n.supportseconddiv:hover .black16 {\n  color: #e31b54;\n}\n.supportseconddiv:hover .testingicon {\n  color: #e31b54;\n}\n.supportThirdDiv {\n  padding: 24px;\n  max-width: 374px;\n\n  gap: 24px;\n  flex: 1 0 0;\n\n  border-radius: 5px;\n  border-top: 2px solid var(--Base-White, #fff);\n  border-left: 2px solid var(--Base-White, #fff);\n  background: rgba(255, 255, 255, 0.2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
