// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gray95024400 {
  color: var(--Gray-950, #0c111d);

  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 31.2px */
}
`, "",{"version":3,"sources":["webpack://./src/pages/JobDetails/main.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;;EAE/B,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,WAAW;AAChC","sourcesContent":[".gray95024400 {\n  color: var(--Gray-950, #0c111d);\n\n  font-size: 24px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 130%; /* 31.2px */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
