import React, { useEffect } from "react";
import "./style.scss";
import { useState } from "react";
import { motion } from "framer-motion";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import axios from "axios";
function Step4({
  onEnterPress,
  selectedItem,
  selectedCards,
  setSelectedCards,
}) {
  const [company, setCompany] = useState("");
  useEffect(() => {
    localStorage.setItem("selectedNetworks", JSON.stringify(selectedCards));
  }, [selectedCards]);

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className="Next_arrow" onClick={onClick}>
        <MdKeyboardArrowRight size={16} />
      </div>
    );
  }

  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/getRelatedCompany`, {
        selectedItem,
      })
      .then((res) => {
        if (res.data.companies) {
          setCompany(res.data.companies);
        } else if (res.data.message === "no record") {
          setCompany([]);
        } else {
          console.log("err");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className="Prev_arrow" onClick={onClick}>
        <MdKeyboardArrowLeft size={16} />
      </div>
    );
  }

  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 1000,
    slidesToShow: 5,
    slidesToScroll: 2,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          dots: true,
          arrows: false,
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          dots: true,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleCardClick = (uniqueId) => {
    const isSelected = selectedCards.includes(uniqueId);

    if (isSelected) {
      // If the card is already selected, remove it from the selection
      setSelectedCards((prevSelected) =>
        prevSelected.filter((selected) => selected !== uniqueId)
      );
    } else {
      // If the card is not selected, add it to the selection
      setSelectedCards((prevSelected) => [...prevSelected, uniqueId]);
    }
  };

  return (
    <motion.div
      id="Step4Kxi39"
      initial={{ opacity: 0.2, scale: 0.2, x: "50%" }}
      animate={{ opacity: 1, scale: 1, x: 0 }}
      transition={{ duration: 0.5 }}
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          onEnterPress(e);
        }
      }}
    >
      <div className="stepWrapper">
        <div className="section_title">
          <h3>Build your network</h3>
        </div>

        <div
          className=" flex-wrap   "
          style={{
            gap: "20px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {company.length !== 0 ? (
            <Slider {...settings}>
              {company.map((logo, index) => (
                <div
                  key={index}
                  className={` card_wrapper `}
                  onClick={() => handleCardClick(logo.uniqueId)}
                  style={{
                    width: "264px",
                    height: "264px",
                  }}
                >
                  <div
                    className="carda rounded-3 d-flex"
                    style={{
                      background: "#FFF",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className=" d-flex"
                      style={{ justifyContent: "center", alignItems: "center" }}
                    >
                      <div className="d-flex" style={{ justifyContent: "end" }}>
                        {" "}
                        {selectedCards.includes(logo.uniqueId) && (
                          <div
                            className="rounded-3"
                            style={{
                              border: "2px solid #17D74D",
                              position: "relative",
                              top: "-60px",
                              left: "60px",
                            }}
                          >
                            {" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M9.20948 17.5602C9.04155 17.5676 8.87603 17.5183 8.73948 17.4202L4.24948 13.7102C3.9602 13.4541 3.91665 13.0186 4.14948 12.7102C4.40515 12.4223 4.83734 12.3748 5.14948 12.6002L9.14948 15.8602L19.6495 6.15024C19.96 5.91711 20.3971 5.95953 20.657 6.24802C20.9169 6.5365 20.9136 6.97565 20.6495 7.26024L9.71948 17.3602C9.58026 17.488 9.39843 17.5593 9.20948 17.5602Z"
                                fill="#17D74D"
                              />
                            </svg>
                          </div>

                          // <svg
                          //   xmlns="http://www.w3.org/2000/svg"
                          //   width="30"
                          //   height="30"
                          //   viewBox="0 0 24 24"
                          //   fill="none"
                          // >
                          //   <path
                          //     fillRule="evenodd"
                          //     clipRule="evenodd"
                          //     d="M6 2H18C20.2091 2 22 3.79086 22 6V18C22 20.2091 20.2091 22 18 22H6C3.79086 22 2 20.2091 2 18V6C2 3.79086 3.79086 2 6 2ZM19.7041 19.7041C20.1561 19.2522 20.41 18.6392 20.41 18V6C20.41 5.36083 20.1561 4.74784 19.7041 4.29587C19.2522 3.84391 18.6392 3.59 18 3.59H6C4.66899 3.59 3.59 4.66899 3.59 6V18C3.59 18.6392 3.84391 19.2522 4.29587 19.7041C4.74784 20.1561 5.36083 20.41 6 20.41H18C18.6392 20.41 19.2522 20.1561 19.7041 19.7041Z"
                          //     fill="#667085"
                          //   />
                          //   <path
                          //     d="M16 11.25H12.75V8C12.75 7.58579 12.4142 7.25 12 7.25C11.5858 7.25 11.25 7.58579 11.25 8V11.25H8C7.58579 11.25 7.25 11.5858 7.25 12C7.25 12.4142 7.58579 12.75 8 12.75H11.25V16C11.25 16.4142 11.5858 16.75 12 16.75C12.4142 16.75 12.75 16.4142 12.75 16V12.75H16C16.4142 12.75 16.75 12.4142 16.75 12C16.75 11.5858 16.4142 11.25 16 11.25Z"
                          //     fill="#667085"
                          //   />
                          // </svg>
                        )}
                      </div>
                      <img
                        src={logo.img}
                        alt="logo"
                        className="Logo_img img-fluid rounded-3 "
                        height="80%"
                        style={{ position: "absolute" }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          ) : (
            company && <p>No Companies with the selected Interests</p>
          )}
        </div>
      </div>
    </motion.div>
  );
}

export default Step4;
