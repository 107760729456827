import on from "../../assets/images/on.svg";
import off from "../../assets/images/off.png";
const Step3 = ({
  levelofstudylist,
  schoolYear,
  handleSubmit,
  relevantCourses,
  options,
  handleSubmitRelevant,
  groups,
  targetGroups,
  handleSubmitTarget,
  setSettings,
  settings,
  isNonMobile1000,
}) => {
  return (
    <div style={{ width: "100%", maxWidth: "100vw" }}>
      <p className="BasicInforMationHeading">Requirements</p>
      <p className="UnderBasicHeading" style={{ marginTop: "0.25rem" }}>
        Add relevant information to publish the event
      </p>

      <div style={{ marginTop: "2.5rem" }}>
        <p className="thumbnailcreate">School Year (Optional)</p>
        <div
          className="d-flex flex-wrap"
          style={{ marginTop: "0.75rem", gap: "0.75rem" }}
        >
          {levelofstudylist.map((study, index) => (
            <div
              className="difficultybuttons"
              key={index}
              style={{
                cursor: "pointer",
                backgroundColor: schoolYear.some((s) => s === study)
                  ? "#FFF5F6"
                  : "",
                color: schoolYear.some((s) => s === study) ? "#E31B54" : "",
                border: schoolYear.some((s) => s === study)
                  ? "1px solid #FFE4E8"
                  : "",
              }}
              onClick={() => handleSubmit(study)}
            >
              {study}
            </div>
          ))}
        </div>
        <hr style={{ marginTop: "2.5rem", marginBottom: "2.5rem" }} />
        <p className="thumbnailcreate">Relevant Courses (Optional)</p>
        <p className="underthumbnailcreate" style={{ marginTop: "0.25rem" }}>
          Filter students by major.
        </p>
        <div
          className="d-flex flex-wrap"
          style={{ marginTop: "0.75rem", gap: "0.75rem" }}
        >
          {options.map((study, index) => (
            <div
              className="difficultybuttons"
              key={index}
              style={{
                cursor: "pointer",
                backgroundColor: relevantCourses.some((s) => s === study)
                  ? "#FFF5F6"
                  : "",
                color: relevantCourses.some((s) => s === study)
                  ? "#E31B54"
                  : "",
                border: relevantCourses.some((s) => s === study)
                  ? "1px solid #FFE4E8"
                  : "",
              }}
              onClick={() => handleSubmitRelevant(study)}
            >
              {study}
            </div>
          ))}
        </div>
        <div style={{ marginTop: "2.5rem" }}>
          <p className="thumbnailcreate">Targeted Groups (Optional)</p>
          <p className="underthumbnailcreate" style={{ marginTop: "0.25rem" }}>
            Filter students by major.
          </p>

          <div
            className="d-flex flex-wrap"
            style={{ marginTop: "0.75rem", gap: "0.75rem" }}
          >
            {groups.map((study, index) => (
              <div
                className="difficultybuttons"
                key={index}
                style={{
                  cursor: "pointer",
                  backgroundColor: targetGroups.some((s) => s === study)
                    ? "#FFF5F6"
                    : "",
                  color: targetGroups.some((s) => s === study) ? "#E31B54" : "",
                  border: targetGroups.some((s) => s === study)
                    ? "1px solid #FFE4E8"
                    : "",
                }}
                onClick={() => handleSubmitTarget(study)}
              >
                {study}
              </div>
            ))}
          </div>
          <hr style={{ marginTop: "2.5rem", marginBottom: "2.5rem" }} />

          <div
            className="space"
            style={{
              flexDirection: isNonMobile1000 ? "row" : "column",
              gap: "1rem",
            }}
          >
            <div className="" style={{ maxWidth: "280px" }}>
              <p className="thumbnailcreate">Data collection</p>
              <p
                className="underthumbnailcreate"
                style={{ marginTop: "0.25rem" }}
              >
                You can change these settings to set data collection during the
                event
              </p>
            </div>
            <div className="d-flex flex-column " style={{ gap: "1rem" }}>
              <div className="d-flex" style={{ gap: "1rem" }}>
                <img
                  src={settings.StudentProfile ? on : off}
                  alt="button"
                  width="36px"
                  height="20px"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setSettings({
                      ...settings,
                      StudentProfile: !settings.StudentProfile,
                    });
                  }}
                />{" "}
                <p className="gray14new">Student Profile</p>
              </div>
              <div className="d-flex " style={{ gap: "1rem" }}>
                <img
                  src={settings.CV ? on : off}
                  alt="button"
                  width="36px"
                  height="20px"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setSettings({
                      ...settings,
                      CV: !settings.CV,
                      CVimp: !settings.CV && false,
                    });
                  }}
                />{" "}
                <p className="gray14new">CV</p>
              </div>
              {settings.CV && (
                <div className="d-flex " style={{ gap: "1rem" }}>
                  <img
                    src={settings.CVimp ? on : off}
                    alt="button"
                    width="36px"
                    height="20px"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setSettings({
                        ...settings,
                        CVimp: !settings.CVimp,
                      });
                    }}
                  />{" "}
                  <p className="gray14new" style={{ fontSize: "12px" }}>
                    CV(mandatory)
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Step3;
