import EditIcon from "@mui/icons-material/Edit";
const Test = ({ owner, user }) => {
  return (
    <div className=" shadowa">
      <div
        className="innerglass tablescroll"
        style={{ maxHeight: "250px", overflow: "auto", padding: "1.25rem" }}
      >
        <div className="space flex-wrap" style={{ gap: "1rem" }}>
          <p className="filmo20">Test Scores</p>
          {owner && (
            <EditIcon
              className="hoverred"
              style={{ marginTop: "0.25rem", width: "24px", cursor: "pointer" }}
            />
          )}
        </div>
        {user?.testScores?.map((cert, index) => (
          <div key={index} style={{ marginTop: "1.5rem" }}>
            <p className="black16 text-start">{cert?.title}</p>
            <p className="gray14" style={{ marginTop: "0.5rem" }}>
              Score {cert?.score}{" "}
              <div
                className=""
                style={{
                  background: "#667085",
                  height: "4px",
                  width: "4px",
                  borderRadius: "125px",
                }}
              />{" "}
              {cert?.month} {cert?.year}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};
export default Test;
