// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CompanyPage-First-First {
  padding: 40px 20px;

  border-radius: 8px 8px 0px 0px;
  background: #fff;
}
.thisone {
  overflow: hidden;
}

.thatone {
  scroll-behavior: smooth;
}
`, "",{"version":3,"sources":["webpack://./src/pages/NewCompanyProfile/main.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;;EAElB,8BAA8B;EAC9B,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":[".CompanyPage-First-First {\n  padding: 40px 20px;\n\n  border-radius: 8px 8px 0px 0px;\n  background: #fff;\n}\n.thisone {\n  overflow: hidden;\n}\n\n.thatone {\n  scroll-behavior: smooth;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
