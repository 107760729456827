const Calender = ({ color, width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : "40"}
      height={height ? height : "40"}
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        d="M27.4997 13.5993H12.4997C11.8093 13.5993 11.2497 14.159 11.2497 14.8493C11.2497 15.5397 11.8093 16.0993 12.4997 16.0993H27.4997C28.19 16.0993 28.7497 15.5397 28.7497 14.8493C28.7497 14.159 28.19 13.5993 27.4997 13.5993Z"
        fill={color ? color : "#0C111D"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.1497 5.94935H28.333C32.9354 5.94935 36.6663 9.68031 36.6663 14.2827V29.2827C36.6663 33.8851 32.9354 37.616 28.333 37.616H11.6663C9.4562 37.616 7.33659 36.738 5.77378 35.1752C4.21098 33.6124 3.33301 31.4928 3.33301 29.2827V14.2827C3.33301 9.68031 7.06397 5.94935 11.6663 5.94935H11.8163V2.91602C11.8163 2.22566 12.376 1.66602 13.0663 1.66602C13.7567 1.66602 14.3163 2.22566 14.3163 2.91602V5.94935H25.6497V2.91602C25.6497 2.22566 26.2093 1.66602 26.8997 1.66602C27.59 1.66602 28.1497 2.22566 28.1497 2.91602V5.94935ZM28.333 35.116C31.5547 35.116 34.1663 32.5043 34.1663 29.2827V14.2827C34.1663 11.061 31.5547 8.44935 28.333 8.44935H11.6663C8.44468 8.44935 5.83301 11.061 5.83301 14.2827V29.2827C5.83301 32.5043 8.44468 35.116 11.6663 35.116H28.333Z"
        fill={color ? color : "#0C111D"}
      />
    </svg>
  );
};
export default Calender;
