import "./styles.scss";

import Inputs from "./field/input";
import PrimaryFooter from "../../layouts/components/Footer/PrimaryFooter";

import Header from "./field/Header";

const Banner = ({ role, company, user, dsa }) => {
  return (
    <div
      className="backgroundimg d-flex"
      style={{ justifyContent: "space-between", flexDirection: "column" }}
    >
      <div className="marginsleftrightCompany">
        <div className="d-flex" style={{ justifyContent: "center" }}>
          <div className="headerCompany">
            <Header />
          </div>
        </div>

        <div className="d-flex" style={{ justifyContent: "center" }}>
          <div className="headerCompany">
            <Inputs role={role} company={company} user={user} dsa={dsa} />
          </div>
        </div>
      </div>
      <div className="marginsleftrightCompany mb-5">
        <div className="d-flex" style={{ justifyContent: "center" }}>
          <div className="headerCompany">
            <PrimaryFooter />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
