import { useEffect, useState, useRef } from "react";
import upload from "../../assets/images/export.svg";
import background from "../../assets/images/aa.png";
import { useMediaQuery } from "@mui/material";
import "./before6.css";
import TaskStep1 from "./TaskStep1";
import { toast } from "react-toastify";
import TaskStep2 from "./TaskStep2";
import TaskStep3 from "./TaskStep3";
import TaskStep4 from "./TaskStep4";
import TaskStep5 from "./TaskStep5";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PreviewWhole from "./PreviewWhole/PreviewWholeChallenge";
import ChallengeType from "../MainDashBoard/type";
const Before = ({
  step,
  setStep,
  settings,
  setSettings,
  edit,
  handleSubmit,
  tastTitle,
  color,
  setTaskTitle,
  taskBackground,
  handleSetPreview,
  setTaskBackground,
  showPreview,
  setShowPreview,
  showPreviewa,
  setShowPreviewa,
  terms,
  setTerms,
  task,
  taskHeader,
  setTaskHeader,
  setTasks,
  external,
  newTasks,
  setNewTasks,
  setExternal,
  taskCount,
  setTaskCount,
  introVideo,
  setIntroVideo,
  documents,
  setSelectedDocuments,
  handleSave,
  questions,
  setQuestions,
  handleAddAnotherTask,

  loading,
  setTime,
  time,
  setLoading,
  updatePreviewTasks,
  title,
  company,
  selectedFile,
  alltask,
  taskSteps,
  setTaskSteps,
  setPreview,
  preview,

  description,
  type,
}) => {
  const [addHover, setAddHover] = useState(false);
  const isNonMob1024 = useMediaQuery("(min-width:1024px)");
  const [showIcon, setShowIcon] = useState(false);
  const [indextoshow, setIndexToShow] = useState(-1);
  const isNonMob5oo = useMediaQuery("(min-width:500px)");
  const isNonMob = useMediaQuery("(min-width:768px)");
  const [taskCounter, setTaskCounter] = useState(-1);
  // const [preview] = useState(false);
  const handleTaskChange = () => {
    setTaskHeader(true);

    setNewTasks((prevTasks) => {
      const updatedTasks = [...prevTasks];
      updatedTasks.push({
        taskTitle: "",
        taskBackground: "",
        terms: null,
        task: null,
        time: 5,
        settings: {
          keyterms: false,
          files: false,
          externalLinks: false,
        },
        external: [],
        settings,
        introVideo: null,
        caseDocuments: [],
        additionalDocuments: [],
        questions: [
          {
            question: "",
            answer: "",
            description: "",
            type: "Multiple Choice Question",
            explaination: "",
            files: [],
            options: [
              {
                value: "",
                isCorrect: false,
              },
              {
                value: "",
                isCorrect: false,
              },
            ],
            isMultiSelect: false,
          },
        ],
      });
      setTaskCount(updatedTasks.length - 1);
      return updatedTasks;
    });
    setTaskSteps(1);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });

    function handleClickOutside(event) {
      if (iconRef.current && !iconRef.current.contains(event.target)) {
        setShowIcon(false);
        setIndexToShow(-1);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [taskSteps]);

  const iconRef = useRef(null);
  return (
    <>
      {preview ? (
        <PreviewWhole
          company={company}
          newTasks={newTasks}
          title={title}
          setPreview={setPreview}
          color={color}
          taskCounter={taskCounter}
        />
      ) : (
        <>
          {taskSteps === 0 && (
            <div
              className="basicInforMationPADDINGS"
              style={{ width: "100vw" }}
            >
              <div className="space flex-wrap" style={{ gap: "1rem" }}>
                <div>
                  <p className="BasicInforMationHeading ">{title && title}</p>

                  <p
                    className="UnderBasicHeading"
                    style={{ marginTop: "0.25rem" }}
                  >
                    Add a minimum of <span>1 task</span> to publish this
                    challenge.{" "}
                    <span style={{ fontWeight: "500" }}>Max. 6 tasks</span>
                  </p>
                </div>
                <div className="d-flex flex-wrap" style={{ gap: "1rem" }}>
                  {edit && (
                    <button
                      className="backbutton"
                      style={{ maxHeight: "40px" }}
                      onClick={() => {
                        setStep(1);
                        setTaskSteps(-1);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M6.49075 12.7251L7.09909 9.88344C7.18765 9.48854 7.38714 9.12715 7.67409 8.84177L13.2324 3.33344C13.7961 2.75925 14.5614 2.42742 15.3658 2.40844C15.9724 2.39974 16.5574 2.63375 16.9908 3.05844C17.9376 4.17716 17.8153 5.84779 16.7158 6.81677L11.1574 12.3751C10.872 12.6621 10.5106 12.8615 10.1158 12.9501L7.27409 13.5584H7.11575C6.90801 13.5664 6.70959 13.4719 6.58486 13.3056C6.46012 13.1393 6.42495 12.9223 6.49075 12.7251ZM8.55742 9.73344C8.44066 9.84659 8.35955 9.99143 8.32409 10.1501L7.91575 12.0918L9.85742 11.6751C10.0161 11.6396 10.1609 11.5585 10.2741 11.4418L15.8324 5.88344C16.4413 5.39596 16.5622 4.51717 16.1074 3.88344C15.9083 3.69319 15.641 3.59108 15.3658 3.6001C14.8912 3.61916 14.4428 3.82244 14.1158 4.16677L8.55742 9.73344Z"
                          fill="#475467"
                        />
                        <path
                          d="M16.5908 9.10844C16.2475 9.11293 15.9702 9.39014 15.9658 9.73344V14.4751C15.9769 15.1453 15.7185 15.792 15.2484 16.2699C14.7784 16.7478 14.1361 17.0169 13.4658 17.0168H5.52409C4.13856 16.9896 3.02835 15.8609 3.02409 14.4751V6.56677C3.04684 5.17934 4.17814 4.06658 5.56576 4.06677H10.3074C10.6526 4.06677 10.9324 3.78695 10.9324 3.44177C10.9324 3.09659 10.6526 2.81677 10.3074 2.81677H5.52409C3.44617 2.81664 1.75526 4.48898 1.73242 6.56677V14.4751C1.73242 16.5692 3.43001 18.2668 5.52409 18.2668H13.4324C15.5232 18.2622 17.2158 16.5659 17.2158 14.4751V9.73344C17.2113 9.39014 16.9341 9.11293 16.5908 9.10844Z"
                          fill="#475467"
                        />
                      </svg>{" "}
                      Edit Challenge
                    </button>
                  )}
                  <button
                    className="continuebutton"
                    style={{ maxHeight: "40px" }}
                    disabled={newTasks.length < 1 || loading === true}
                    onClick={() => {
                      setShowPreviewa(true);
                    }}
                  >
                    Preview Challenge
                  </button>
                  <button
                    className="continuebutton"
                    style={{ maxHeight: "40px" }}
                    onClick={() => handleSubmit()}
                    disabled={newTasks.length < 1 || loading === true}
                  >
                    <img src={upload} alt="upload" width="20px" height="20px" />{" "}
                    Publish Challenge
                  </button>
                </div>
              </div>
              <div className="space" style={{ marginTop: "1.875rem" }}>
                <div className="d-flex flex-wrap" style={{ gap: "1.5rem" }}>
                  {newTasks.length > 0 &&
                    newTasks.map((task, index) => (
                      <div
                        key={index}
                        className="addChallengeDiva shadowa space"
                        style={{ flexDirection: "column" }}
                      >
                        <div>
                          {" "}
                          <div
                            className="space"
                            style={{ alignItems: "center" }}
                          >
                            <p className="black14">Task {index + 1}</p>
                            <div className="d-flex">
                              <MoreVertIcon
                                onClick={() => {
                                  setIndexToShow(index);
                                  setShowIcon(true);
                                }}
                              />
                              {showIcon && indextoshow === index && (
                                <div
                                  style={{
                                    position: "absolute",
                                    background: "transparent",
                                    marginTop: "30px",
                                    marginLeft: isNonMob5oo ? "" : "-140px",
                                  }}
                                  ref={iconRef}
                                >
                                  <div
                                    className="d-flex shadowa"
                                    style={{
                                      flexDirection: "column",
                                      background: "transparent",
                                    }}
                                  >
                                    <button
                                      className="d-flex black14"
                                      style={{
                                        padding: "11px 1rem",
                                        maxHeight: "2rem",
                                        borderRadius: "5px 5px 0px 0px",
                                        gap: "10px",
                                        background: "#FFF",
                                        border: "none",
                                        width: "160px",
                                        minHeight: "42px",
                                      }}
                                      onClick={() => {
                                        setTaskCount(index);
                                        setTaskSteps(1);
                                        setShowIcon(false);
                                        setTaskHeader(true);
                                        setIndexToShow(-1);
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                      >
                                        <path
                                          d="M11.05 3L4.20829 10.2417C3.94996 10.5167 3.69996 11.0583 3.64996 11.4333L3.34162 14.1333C3.23329 15.1083 3.93329 15.775 4.89996 15.6083L7.58329 15.15C7.95829 15.0833 8.48329 14.8083 8.74162 14.525L15.5833 7.28334C16.7666 6.03334 17.3 4.60834 15.4583 2.86667C13.625 1.14167 12.2333 1.75 11.05 3Z"
                                          stroke="#475467"
                                          strokeWidth="1.5"
                                          strokeMiterlimit="10"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M9.9082 4.20834C10.2665 6.50834 12.1332 8.26667 14.4499 8.5"
                                          stroke="#475467"
                                          strokeWidth="1.5"
                                          strokeMiterlimit="10"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M2.5 18.3333H17.5"
                                          stroke="#475467"
                                          strokeWidth="1.5"
                                          strokeMiterlimit="10"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                      Edit
                                    </button>
                                    <button
                                      className="d-flex black14"
                                      style={{
                                        padding: "11px 1rem",
                                        maxHeight: "2rem",
                                        borderRadius: "0px 0px 5px 5px",
                                        gap: "10px",
                                        background: "#FFF",
                                        border: "none",
                                        width: "160px",
                                        minHeight: "42px",
                                        borderTop: "1px solid #d9d9d9",
                                      }}
                                      onClick={() => {
                                        setNewTasks((prevTasks) => {
                                          return prevTasks.filter(
                                            (task, i) => i !== index
                                          );
                                        });

                                        setShowIcon(false);
                                        setIndexToShow(-1);
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                      >
                                        <path
                                          d="M17.5 4.98333C14.725 4.70833 11.9333 4.56667 9.15 4.56667C7.5 4.56667 5.85 4.65 4.2 4.81667L2.5 4.98333"
                                          stroke="#475467"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M7.0835 4.14166L7.26683 3.05C7.40016 2.25833 7.50016 1.66666 8.9085 1.66666H11.0918C12.5002 1.66666 12.6085 2.29166 12.7335 3.05833L12.9168 4.14166"
                                          stroke="#475467"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M15.7082 7.61667L15.1665 16.0083C15.0748 17.3167 14.9998 18.3333 12.6748 18.3333H7.32484C4.99984 18.3333 4.92484 17.3167 4.83317 16.0083L4.2915 7.61667"
                                          stroke="#475467"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M8.6084 13.75H11.3834"
                                          stroke="#475467"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M7.9165 10.4167H12.0832"
                                          stroke="#475467"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                      Delete
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <p
                            className="black16 text-start"
                            style={{ marginTop: "1rem", fontWeight: "600" }}
                          >
                            {task.taskTitle ? task.taskTitle : "Task Title"}
                          </p>
                        </div>
                        <div>
                          <div
                            className="space"
                            style={{ alignItems: "center" }}
                          >
                            <p className="pink16" style={{ fontSize: "12px" }}>
                              No. of questions
                            </p>
                            <p className="black12">{task.questions.length}</p>
                          </div>
                          <div
                            className="space"
                            style={{
                              marginTop: "0.5rem",
                              alignItems: "center",
                            }}
                          >
                            <p className="pink16" style={{ fontSize: "12px" }}>
                              Duration:
                            </p>
                            <p className="black12">{task.time} mins</p>
                          </div>
                          <div
                            className="d-flex"
                            style={{
                              marginTop: "1.25rem",
                              justifyContent: "end",
                            }}
                          >
                            <button
                              className="continuebutton"
                              style={{
                                padding: "0.5rem 1.5rem",
                                maxHeight: "2rem",
                                background: "transparent",
                                color: "#E31B54",
                                borderColor: "#E31B54",
                                width: "100%",
                              }}
                              onClick={() => {
                                setTaskCounter(index);
                                setPreview(true);
                              }}
                            >
                              Preview
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}

                  {newTasks.length < 6 && (
                    <div
                      className="addChallengeDiva shadowa space"
                      style={{ flexDirection: "column" }}
                      onMouseEnter={() => setAddHover(true)}
                      onMouseLeave={() => setAddHover(false)}
                      onClick={() => handleTaskChange()}
                    >
                      <div>
                        <p
                          className="text-start pink16"
                          style={{ fontSize: "0.875rem" }}
                        >
                          Add Task
                        </p>
                      </div>
                      <div
                        className="center"
                        style={{
                          height: "100%",
                          width: "100%",
                          alignItems: "center",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="100"
                          height="100"
                          viewBox="0 0 100 100"
                          fill="none"
                        >
                          <path
                            d="M82.2917 45.8333H52.0833V15.625C52.0833 13.8991 50.6842 12.5 48.9583 12.5C47.2324 12.5 45.8333 13.8991 45.8333 15.625V45.8333H15.625C13.8991 45.8333 12.5 47.2324 12.5 48.9583C12.5 50.6842 13.8991 52.0833 15.625 52.0833H45.8333V82.2917C45.8333 84.0176 47.2324 85.4167 48.9583 85.4167C50.6842 85.4167 52.0833 84.0176 52.0833 82.2917V52.0833H82.2917C84.0176 52.0833 85.4167 50.6842 85.4167 48.9583C85.4167 47.2324 84.0176 45.8333 82.2917 45.8333Z"
                            fill={addHover ? "#E31B54" : "white"}
                          />
                        </svg>
                      </div>
                    </div>
                  )}
                </div>
                {isNonMob1024 && (
                  <div
                    style={{ width: "324px" }}
                    className="whitedivrightsideaddtasks"
                  >
                    <div>
                      <p
                        className="text-start black16"
                        style={{ fontWeight: "600" }}
                      >
                        Challenge Overview
                      </p>
                      <div style={{ marginTop: "1rem" }}>
                        <p className="nextstepsright">Preview</p>

                        <div
                          className="ChallengesCardNew-Outer shadowa "
                          style={{ borderRadius: "5px", marginTop: "0.875rem" }}
                        >
                          {selectedFile && (
                            <img
                              src={
                                selectedFile instanceof File // Check if selectedFile is a File or Blob object
                                  ? URL.createObjectURL(selectedFile) // If it is, create a local URL for the file
                                  : selectedFile.includes("http")
                                  ? selectedFile
                                  : `${process.env.REACT_APP_BACKEND_URL}${selectedFile}`
                              }
                              alt="challenge image"
                              className="img-fluid"
                              style={{
                                borderTopLeftRadius: "5px",
                                borderTopRightRadius: "5px",
                                maxHeight: "6.875rem",
                                minHeight: "6.875rem",
                                width: "100%",
                                objectFit: "cover",
                                // ensures the image covers the container without stretching or compressing
                              }}
                            />
                          )}

                          <div className="ChallengesCardNew-Inner space flex-column">
                            <div>
                              {company && (
                                <div
                                  className="shadowa center align-items-center"
                                  style={{
                                    padding: "2px",
                                    background: "#FFF",
                                    borderRadius: "5px",
                                    height: "48px",
                                    width: "48px",
                                    position: "absolute",
                                    marginTop: "-46px",
                                  }}
                                >
                                  <img
                                    src={company.img}
                                    alt="card-bg"
                                    style={{
                                      maxHeight: "44px",
                                      maxWidth: "44px",
                                      height: "44px",
                                      borderRadius: "3px",
                                      objectFit: "cover",
                                      width: "100%",
                                    }}
                                  />
                                </div>
                              )}
                              <p
                                className="gray60011400"
                                style={{
                                  fontSize: "10px",
                                  marginTop: "0.5rem",
                                }}
                              >
                                {company?.title}
                              </p>
                              <p
                                style={{ marginTop: "0.25rem" }}
                                className="gray95016500"
                              >
                                {title}
                              </p>
                            </div>
                            <div>
                              <div className=" space align-items-center">
                                <ChallengeType type={type} />
                                <div
                                  className="d-flex align-items-center"
                                  style={{ gap: "0.25rem" }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                    fill="none"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M1.54938 7.79185C1.54938 4.89236 3.89989 2.54185 6.79938 2.54185C9.69888 2.54185 12.0494 4.89236 12.0494 7.79185C12.0494 10.6913 9.69888 13.0419 6.79938 13.0419C3.89989 13.0419 1.54938 10.6913 1.54938 7.79185ZM2.42438 7.79185C2.42438 10.2081 4.38314 12.1669 6.79938 12.1669C7.95971 12.1669 9.0725 11.7059 9.89298 10.8854C10.7134 10.065 11.1744 8.95217 11.1744 7.79185C11.1744 5.37561 9.21563 3.41685 6.79938 3.41685C4.38314 3.41685 2.42438 5.37561 2.42438 7.79185Z"
                                      fill="#344054"
                                    />
                                    <path
                                      d="M5.42855 5.56935C5.25617 5.40873 4.98755 5.41347 4.82094 5.58007C4.65433 5.74668 4.64959 6.0153 4.81022 6.18769L6.36188 7.73935V10.1252C6.36188 10.3668 6.55776 10.5627 6.79938 10.5627C7.04101 10.5627 7.23688 10.3668 7.23688 10.1252V7.55852C7.23678 7.44253 7.19062 7.33132 7.10855 7.24935L5.42855 5.56935Z"
                                      fill="#344054"
                                    />
                                    <path
                                      d="M12.3586 3.41685C11.6384 2.45639 10.6818 1.69876 9.58188 1.21769C9.47454 1.16903 9.3521 1.16574 9.2423 1.20856C9.13249 1.25138 9.04461 1.3367 8.99855 1.44519C8.94818 1.55231 8.94401 1.67542 8.98702 1.78571C9.03004 1.896 9.11645 1.98378 9.22605 2.02852C10.1868 2.45123 11.0225 3.11417 11.6527 3.95352C11.7353 4.06368 11.865 4.12852 12.0027 4.12852C12.0974 4.12895 12.1897 4.0982 12.2652 4.04102C12.3624 3.97213 12.4273 3.86661 12.4449 3.74882C12.4625 3.63103 12.4313 3.51115 12.3586 3.41685Z"
                                      fill="#344054"
                                    />
                                    <path
                                      d="M1.94022 3.94185C2.57044 3.10251 3.40615 2.43956 4.36688 2.01685C4.47648 1.97211 4.5629 1.88433 4.60591 1.77404C4.64892 1.66376 4.64476 1.54064 4.59438 1.43352C4.54832 1.32503 4.46044 1.23972 4.35064 1.19689C4.24083 1.15407 4.1184 1.15736 4.01105 1.20602C2.91195 1.69121 1.95732 2.4529 1.24022 3.41685C1.11421 3.60468 1.15215 3.85759 1.32772 4.00019C1.40325 4.05737 1.49549 4.08811 1.59022 4.08769C1.72298 4.09478 1.85177 4.04112 1.94022 3.94185Z"
                                      fill="#344054"
                                    />
                                  </svg>
                                  <p className="gray75010500">
                                    {Number(
                                      alltask.reduce(
                                        (acc, task) => acc + task.time,
                                        0
                                      )
                                    ) + Number(time)}{" "}
                                    mins
                                  </p>
                                </div>
                              </div>
                              <div className="ChallengenewCarddescription ">
                                <p className="gray40012400">
                                  {description && description}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div
                          className="Challengescardouterwhitediv"
                          style={{ marginTop: "0.875rem" }}
                        >
                          <div className="Challengescardinnergreydiv">
                            <div className="challengescardinsidegreybackgroundimagediv">
                              <img
                                src={company && company.img}
                                alt="card-bg"
                                className="challengescardinsidegreybackgroundimagedivcompanycard"
                                style={{ maxHeight: "48px", maxWidth: "48px" }}
                              />

                              {selectedFile && (
                                <img
                                  src={
                                    selectedFile instanceof File // Check if selectedFile is a File or Blob object
                                      ? URL.createObjectURL(selectedFile) // If it is, create a local URL for the file
                                      : selectedFile.includes("http")
                                      ? selectedFile
                                      : `${process.env.REACT_APP_BACKEND_URL}${selectedFile}` // If it's not a URL, assume it's a path and prepend the base URL
                                  }
                                  alt="challenge image"
                                  height="166px"
                                  style={{
                                    borderTopLeftRadius: "10px",
                                    borderTopRightRadius: "10px",
                                    maxHeight: "7.813rem",
                                    width: "100%",
                                  }}
                                />
                              )}
                            </div>
                            <div
                              style={{
                                padding: "0.75rem 0.46rem 0.75rem 0.5rem",
                              }}
                            >
                              <p className="ChallengecardTaskCounter text-start">
                                {alltask.length + 1}{" "}
                                {alltask.length + 1 > 1 ? "Tasks" : "Task"}
                                {" / "}
                                {Number(
                                  alltask.reduce(
                                    (acc, task) => acc + task.time,
                                    0
                                  )
                                ) + Number(time)}
                                mins
                              </p>

                              <p
                                className="ChallengecardChallengeTitle"
                                style={{ marginTop: "0.625rem" }}
                              >
                                {title && title}
                              </p>

                              <p
                                className="ChallengeCardCompanyName"
                                style={{ marginTop: "0.375rem" }}
                              >
                                {company && company.title}
                              </p>
                            </div>
                          </div>
                          <div
                            className="d-flex"
                            style={{
                              justifyContent: "space-between",
                              marginTop: "0.5rem",
                            }}
                          >
                            <div
                              className="d-flex"
                              style={{ alignItems: "center", gap: "0.5rem" }}
                            >
                              {type === "Fast-Track" && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M4.84257 1.66602H15.16C16.9133 1.66602 18.3346 3.08733 18.3346 4.84062V15.1581C18.3346 16.9114 16.9133 18.3327 15.16 18.3327H4.84257C3.08929 18.3327 1.66797 16.9114 1.66797 15.1581V4.84062C1.66797 3.08733 3.08929 1.66602 4.84257 1.66602ZM15.16 17.1422C16.2558 17.1422 17.1442 16.2539 17.1442 15.1581V4.84062C17.1442 3.74482 16.2558 2.85649 15.16 2.85649H4.84257C3.74677 2.85649 2.85844 3.74482 2.85844 4.84062V15.1581C2.85844 16.2539 3.74677 17.1422 4.84257 17.1422H15.16Z"
                                    fill="#0C111D"
                                  />
                                  <path
                                    d="M14.3664 7.41998H11.9854C11.6567 7.41998 11.3902 7.68648 11.3902 8.01522C11.3902 8.34396 11.6567 8.61046 11.9854 8.61046H12.7235L9.89813 11.4359L8.31083 9.84856C7.88961 9.4288 7.20823 9.4288 6.78702 9.84856L4.71559 11.8565C4.48348 12.0889 4.48348 12.4654 4.71559 12.6978C4.82661 12.8102 4.97823 12.8731 5.13622 12.8724C5.29439 12.874 5.44636 12.8109 5.55686 12.6978L7.51718 10.7374L9.10448 12.3247C9.52841 12.7454 10.2123 12.7454 10.6362 12.3247L13.7394 9.22157V10.3962C13.7394 10.7249 14.0059 10.9914 14.3346 10.9914C14.6634 10.9914 14.9299 10.7249 14.9299 10.3962V8.01522C14.9264 7.70006 14.6809 7.4407 14.3664 7.41998Z"
                                    fill="#0C111D"
                                  />
                                </svg>
                              )}
                              {type === "Virtual Experience" && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M17.0641 7.42392H17.9732C18.7142 7.42887 19.3137 8.02836 19.3186 8.76937V10.1694C19.3186 10.9124 18.7162 11.5148 17.9732 11.5148H17.2732V12.3057C17.3296 13.6901 16.2572 14.86 14.8732 14.9239H13.4368C12.7782 14.9143 12.1542 14.6271 11.7186 14.133C11.292 13.6259 10.6631 13.3332 10.0004 13.3332C9.33774 13.3332 8.70889 13.6259 8.28224 14.133C7.84661 14.6271 7.22267 14.9143 6.56406 14.9239H5.1277C3.74366 14.86 2.67121 13.6901 2.7277 12.3057V11.5148H2.00952C1.26644 11.5148 0.664062 10.9124 0.664062 10.1694V8.76937C0.669019 8.02836 1.26851 7.42887 2.00952 7.42392H2.91861C3.25605 6.48851 4.13356 5.85656 5.1277 5.83301H14.8732C15.8606 5.86393 16.729 6.49456 17.0641 7.42392ZM2.04588 8.76937V10.1512L2.7277 10.1421V8.76937H2.04588ZM15.9095 12.3057C15.9625 12.9361 15.5022 13.4933 14.8732 13.5603H13.4368C13.1649 13.5585 12.9081 13.4351 12.7368 13.2239C12.0465 12.4412 11.0532 11.9928 10.0095 11.9928C8.96588 11.9928 7.97255 12.4412 7.28224 13.2239C7.11095 13.4351 6.85414 13.5585 6.58224 13.5603H5.1277C4.49867 13.4933 4.03836 12.9361 4.09134 12.3057V8.45119C4.03836 7.82083 4.49867 7.26361 5.1277 7.19664H14.8732C15.5022 7.26361 15.9625 7.82083 15.9095 8.45119V12.3057ZM17.2732 8.77846V10.1512L17.955 10.1603V8.77846H17.2732Z"
                                    fill="#0C111D"
                                  />
                                  <path
                                    d="M8.18224 9.41483H5.45497C5.07841 9.41483 4.77315 9.72009 4.77315 10.0966C4.77315 10.4732 5.07841 10.7785 5.45497 10.7785H8.18224C8.5588 10.7785 8.86406 10.4732 8.86406 10.0966C8.86406 9.72009 8.5588 9.41483 8.18224 9.41483Z"
                                    fill="#0C111D"
                                  />
                                  <path
                                    d="M13.3004 9.61483C13.2361 9.54822 13.1587 9.49561 13.0732 9.46028C12.9063 9.39666 12.7218 9.39666 12.555 9.46028C12.4694 9.49561 12.392 9.54822 12.3277 9.61483C12.2048 9.74509 12.1364 9.91753 12.1368 10.0966C12.1333 10.1838 12.1488 10.2707 12.1822 10.3512C12.2165 10.4352 12.2658 10.5122 12.3277 10.5785C12.4598 10.7053 12.6355 10.7768 12.8186 10.7785C12.9063 10.7778 12.9929 10.7592 13.0732 10.7239C13.1571 10.6897 13.2341 10.6404 13.3004 10.5785C13.4267 10.4496 13.4983 10.277 13.5004 10.0966C13.5007 10.0059 13.4821 9.91616 13.4459 9.83301C13.4103 9.75244 13.3611 9.67862 13.3004 9.61483Z"
                                    fill="#0C111D"
                                  />
                                  <path
                                    d="M11.255 9.46937C11.0881 9.40575 10.9037 9.40575 10.7368 9.46937C10.6525 9.50232 10.5776 9.55538 10.5186 9.62392C10.389 9.75049 10.3167 9.92456 10.3186 10.1057C10.3197 10.1925 10.335 10.2785 10.3641 10.3603C10.3984 10.4464 10.4512 10.524 10.5186 10.5876C10.6463 10.7155 10.8197 10.7874 11.0004 10.7876C11.0881 10.7868 11.1747 10.7683 11.255 10.733C11.3389 10.6988 11.416 10.6495 11.4822 10.5876C11.6069 10.4577 11.6783 10.2857 11.6822 10.1057C11.6821 9.92497 11.6101 9.75166 11.4822 9.62392C11.4179 9.55731 11.3406 9.5047 11.255 9.46937Z"
                                    fill="#0C111D"
                                  />
                                  <path
                                    d="M15.1186 9.60573C14.9214 9.41277 14.6284 9.35559 14.3732 9.46028C14.291 9.49797 14.2145 9.54693 14.1459 9.60573C14.0864 9.67383 14.0373 9.75044 14.0004 9.83301C13.983 9.91696 13.983 10.0036 14.0004 10.0876C13.9823 10.1745 13.9823 10.2642 14.0004 10.3512C14.0349 10.4323 14.0843 10.5063 14.1459 10.5694C14.2095 10.6368 14.287 10.6896 14.3732 10.7239C14.4563 10.7602 14.5461 10.7787 14.6368 10.7785C14.7246 10.7788 14.8114 10.7602 14.8913 10.7239C14.9774 10.6896 15.055 10.6368 15.1186 10.5694C15.1802 10.5063 15.2296 10.4323 15.2641 10.3512C15.2931 10.2663 15.3085 10.1773 15.3095 10.0876C15.3077 10.0009 15.2923 9.91499 15.2641 9.83301C15.2243 9.75202 15.1755 9.67578 15.1186 9.60573Z"
                                    fill="#0C111D"
                                  />
                                </svg>
                              )}
                              {type === "Insights" && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M5.89943 2.67498C6.51855 2.67517 7.08654 3.01854 7.37443 3.56665L9.0161 6.73331C9.28337 7.24921 9.26268 7.86707 8.9615 8.36393C8.66032 8.8608 8.12212 9.16495 7.5411 9.16665H4.25776C3.67082 9.17672 3.12186 8.87727 2.81258 8.37832C2.5033 7.87937 2.47931 7.25451 2.74943 6.73331L4.4161 3.56665C4.70537 3.01588 5.27732 2.67207 5.89943 2.67498ZM7.5411 7.93331C7.68737 7.93417 7.82337 7.85826 7.89943 7.73331V7.71665C7.97531 7.60296 7.98788 7.4584 7.93276 7.33331L6.2661 4.16665C6.20354 4.0334 6.07155 3.94649 5.92443 3.94165C5.76827 3.93617 5.62268 4.02029 5.54943 4.15832L3.88276 7.32498C3.81542 7.45495 3.82121 7.61072 3.89802 7.73532C3.97483 7.85993 4.1114 7.93509 4.25776 7.93331H7.5411Z"
                                    fill="#0C111D"
                                  />
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M17.4161 5.83333C17.4161 7.67428 15.9237 9.16667 14.0828 9.16667C12.2418 9.16667 10.7494 7.67428 10.7494 5.83333C10.7494 3.99238 12.2418 2.5 14.0828 2.5C15.9237 2.5 17.4161 3.99238 17.4161 5.83333ZM16.1661 5.83333C16.1661 4.68274 15.2334 3.75 14.0828 3.75C13.5302 3.75 13.0003 3.96949 12.6096 4.36019C12.2189 4.7509 11.9994 5.2808 11.9994 5.83333C11.9994 6.98393 12.9322 7.91667 14.0828 7.91667C15.2334 7.91667 16.1661 6.98393 16.1661 5.83333Z"
                                    fill="#0C111D"
                                  />
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M12.4161 10.8333H15.7494C16.6699 10.8333 17.4161 11.5795 17.4161 12.5V15.8333C17.4161 16.7538 16.6699 17.5 15.7494 17.5H12.4161C11.4956 17.5 10.7494 16.7538 10.7494 15.8333V12.5C10.7494 11.5795 11.4956 10.8333 12.4161 10.8333ZM15.7494 16.25C15.9795 16.25 16.1661 16.0635 16.1661 15.8333V12.5C16.1661 12.2699 15.9795 12.0833 15.7494 12.0833H12.4161C12.186 12.0833 11.9994 12.2699 11.9994 12.5V15.8333C11.9994 16.0635 12.186 16.25 12.4161 16.25H15.7494Z"
                                    fill="#0C111D"
                                  />
                                  <path
                                    d="M8.8411 10.9667C8.72502 10.8475 8.56575 10.7804 8.39943 10.7804C8.23311 10.7804 8.07384 10.8475 7.95776 10.9667L5.89943 13.025L3.8411 10.9667C3.59484 10.7372 3.21109 10.744 2.97308 10.982C2.73507 11.22 2.7283 11.6037 2.95776 11.85L4.99943 13.9083L2.95776 15.9667C2.83864 16.0827 2.77146 16.242 2.77146 16.4083C2.77146 16.5747 2.83864 16.7339 2.95776 16.85C3.07586 16.9657 3.23409 17.0314 3.39943 17.0333C3.56443 17.0297 3.72206 16.9643 3.8411 16.85L5.89943 14.7917L7.95776 16.85C8.07586 16.9657 8.23409 17.0314 8.39943 17.0333C8.56443 17.0297 8.72206 16.9643 8.8411 16.85C9.08481 16.606 9.08481 16.2107 8.8411 15.9667L6.78276 13.9083L8.8411 11.85C9.08481 11.606 9.08481 11.2107 8.8411 10.9667Z"
                                    fill="#0C111D"
                                  />
                                </svg>
                              )}
                              <p className="challengetypetext">
                                {type && type}
                              </p>
                            </div>
                            <button className="PreviewCompanyDashboardButton border-0">
                              Start
                            </button>{" "}
                          </div>
                        </div> */}
                      </div>{" "}
                      <div style={{ marginTop: "1.875rem" }}>
                        <p className="nextstepsright">Quick Tips</p>

                        <p className="black14" style={{ marginTop: "0.75rem" }}>
                          Encourage students to approach challenge tasks by
                          breaking down complex problems into smaller,
                          manageable tasks.
                        </p>
                        <p className="black12" style={{ marginTop: "1rem" }}>
                          Emphasize the importance of gathering relevant
                          information and proposing well-thought-out and
                          creative solutions.
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          <div
            className="pt-4"
            style={{
              minWidth: isNonMob ? "700px" : "",
              maxWidth: "700px",
              padding: isNonMob ? "" : "1.25rem",
            }}
          >
            {taskSteps === 1 && (
              <TaskStep1
                toast={toast}
                taskCount={taskCount}
                newTasks={newTasks}
                setNewTasks={setNewTasks}
                setTaskSteps={setTaskSteps}
              />
            )}

            {taskSteps === 2 && (
              <TaskStep2
                toast={toast}
                settings={settings}
                setSettings={setSettings}
                taskCount={taskCount}
                newTasks={newTasks}
                setNewTasks={setNewTasks}
                setTaskSteps={setTaskSteps}
              />
            )}
            {/* {taskSteps === 3 && (
          <TaskStep3
            toast={toast}
            taskCount={taskCount}
            newTasks={newTasks}
            setNewTasks={setNewTasks}
            setTaskSteps={setTaskSteps}
          />
        )} */}
            {taskSteps === 3 && (
              <TaskStep4
                toast={toast}
                taskCount={taskCount}
                newTasks={newTasks}
                setNewTasks={setNewTasks}
                setTaskSteps={setTaskSteps}
              />
            )}
            {taskSteps === 4 && (
              <TaskStep5
                toast={toast}
                taskCount={taskCount}
                newTasks={newTasks}
                setNewTasks={setNewTasks}
                setTaskSteps={setTaskSteps}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Before;
