import { useMediaQuery } from "@mui/material";
import "./footer.css";
import { useNavigate } from "react-router-dom";
const Footer = () => {
  const isNonMobile = useMediaQuery("(min-width:500px)");
  const navigate = useNavigate();
  return (
    <>
      <div
        className="footer mb-5 d-flex"
        style={{ justifyContent: isNonMobile ? "space-between" : "center" }}
      >
        {isNonMobile && (
          <div className="left">
            <p className="ltd">&#169; All rights reserved</p>
          </div>
        )}
        <div className="right d-flex" style={{ gap: "15px" }}>
          <a
            className="footertext"
            href={`${process.env.REACT_APP_FRONTEND_URL}/privacy`}
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            Privacy Policy
          </a>
          <a
            className="footertext"
            href={`${process.env.REACT_APP_FRONTEND_URL}/terms`}
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            Terms of Use
          </a>
        </div>
      </div>
    </>
  );
};
export default Footer;
