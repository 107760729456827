import React from "react";

import logo from "../../assets/images/logo.png";
import linkedin from "../../assets/images/icons/linkedin.png";
import { useMediaQuery } from "@mui/material";
const Card4 = () => {
  const isNonMobile1440 = useMediaQuery("(min-width:1440px)");
  return (
    <div
      className="company3rdcard rounded-3 shadowa d-flex"
      style={{ justifyContent: "space-between", flexDirection: "column" }}
    >
      <div className="card1paddings">
        {/* <div className="d-flex">
          <div className="firstcard1button">
            <p
              className="firstcard1buttontext d-flex"
              style={{
                gap: "0.5rem",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M6.6585 7.94169H12.2418C12.5526 7.90538 12.7869 7.64208 12.7869 7.32919C12.7869 7.0163 12.5526 6.753 12.2418 6.71669H6.6585C6.42261 6.68913 6.19186 6.79947 6.06524 7.00039C5.93861 7.2013 5.93861 7.45708 6.06524 7.65799C6.19186 7.8589 6.42261 7.96925 6.6585 7.94169Z"
                  fill="#E31B54"
                />
                <path
                  d="M6.6585 14.6084H10.6418C10.8777 14.6359 11.1085 14.5256 11.2351 14.3247C11.3617 14.1237 11.3617 13.868 11.2351 13.6671C11.1085 13.4661 10.8777 13.3558 10.6418 13.3834H6.6585C6.42261 13.3558 6.19186 13.4661 6.06524 13.6671C5.93861 13.868 5.93861 14.1237 6.06524 14.3247C6.19186 14.5256 6.42261 14.6359 6.6585 14.6084Z"
                  fill="#E31B54"
                />
                <path
                  d="M13.8335 11.275H6.6585C6.42261 11.3026 6.19186 11.1922 6.06524 10.9913C5.93861 10.7904 5.93861 10.5346 6.06524 10.3337C6.19186 10.1328 6.42261 10.0225 6.6585 10.05H13.8335C14.1443 10.0863 14.3786 10.3496 14.3786 10.6625C14.3786 10.9754 14.1443 11.2387 13.8335 11.275Z"
                  fill="#E31B54"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M13.1585 18.825H7.32516C5.11928 18.8204 3.33349 17.0309 3.3335 14.825V5.65835C3.33808 3.45572 5.12253 1.67127 7.32516 1.66669H13.1585C15.3644 1.66668 17.1539 3.45247 17.1585 5.65835V14.825C17.1585 15.8859 16.7371 16.9033 15.9869 17.6534C15.2368 18.4036 14.2194 18.825 13.1585 18.825ZM7.32516 2.99169C5.8543 2.99626 4.66307 4.18749 4.6585 5.65835V14.825C4.65849 16.2991 5.85106 17.4954 7.32516 17.5H13.1585C14.6359 17.5 15.8335 16.3024 15.8335 14.825V5.65835C15.8289 4.18425 14.6326 2.99168 13.1585 2.99169H7.32516Z"
                  fill="#E31B54"
                />
              </svg>
              Challenge Certificate
            </p>
          </div>
        </div> */}
        <p className="text-start firstcard2ndtext">Earn a certificate</p>
        <div
          className="d-flex "
          style={{
            height: "100%",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "2rem",
          }}
        >
          {/* <p className="text-start firstcard3rdtext">
            Master a challenge and earn a certificate from the world’s leading
            employers.
          </p> */}
          <p className="text-start firstcard3rdtext ">
            Download or share your certificate on your <span>varyance</span> and{" "}
            <span>Linkedin</span> profiles and showcase your skills to potential
            employers.
          </p>
        </div>
      </div>
      <div>
        <p className="black16 text-start card1paddings"> Social-Share</p>
        <div
          className="d-flex card1paddings"
          style={{ gap: "1rem", paddingBottom: "1rem" }}
        >
          <img
            className="rounded-2 shadowa"
            src={logo}
            alt="sd"
            height="45px"
            width="45px"
          />
          <img
            className="rounded-2 shadowa"
            src={linkedin}
            alt="sd"
            height="45px"
            width="45px"
          />
        </div>
      </div>
    </div>
  );
};

export default Card4;
