import "./tab.css";

const TabButtons = ({ buttons, setSelected, selected }) => {
  return (
    <div className="newTabOuter">
      {buttons.map((btn, index) => (
        <button
          key={index}
          className={`newTabButton ${selected === btn.name && "enabled"}`}
          onClick={() => {
            setSelected(btn.name);
          }}
        >
          {btn.name}
        </button>
      ))}
    </div>
  );
};
export default TabButtons;
