import { useMediaQuery } from "@mui/material";

import img from "../../assets/images/reachoutt.png";
import book from "../../assets/images/book.png";
import trophy from "../../assets/images/trophy.png";
import user from "../../assets/images/user.png";
import search from "../../assets/images/searcha.png";
import briefcase from "../../assets/images/briefcase.png";
import building from "../../assets/images/building.png";

import p1 from "../../assets/images/p1.png";
import p2 from "../../assets/images/newp2.png";
import p3 from "../../assets/images/newp3.png";
import p4 from "../../assets/images/newp4.png";
import p5 from "../../assets/images/newp5.png";
const ReachOut = () => {
  const isNonMobile = useMediaQuery("(min-width:768px)");

  const isNonMobile1441 = useMediaQuery("(min-width:1441px)");

  const skills = [
    {
      name: "Internship",
      icon: trophy,
    },
    {
      name: "Full-Time",
      icon: briefcase,
    },
    {
      name: "Thesis",
      icon: user,
    },
    {
      name: "Research Grant",
      icon: building,
    },
    {
      name: "Insights",
      icon: search,
    },
    { name: "Pre-Skilling", icon: book },
  ];

  return (
    <>
      <div
        className="marginsleftrightCompany "
        style={{
          paddingTop: isNonMobile ? "8.125rem" : "5rem",
          paddingBottom: isNonMobile ? "8.125rem" : "5rem",
        }}
      >
        <div
          className="d-flex"
          style={{ justifyContent: "center", position: "relative" }}
        >
          <div
            className={` headerCompany   ${
              isNonMobile && "d-flex sectioncenter"
            }`}
            style={{ gap: "1rem" }}
          >
            <div className="widthfirst d-flex" style={{ alignItems: "center" }}>
              <div>
                {/* <p className=" sparkInterest">
                  Reach <span>Out</span>
                </p> */}
                <p
                  style={{
                    marginTop: "1rem",
                    maxWidth: "562px",
                    width: "100%",
                  }}
                  className="itsearly "
                >
                  Connect <span className="pinkspan">directly</span> with campus
                  recruiters and current interns.
                </p>
                <div className={`marginfromabove `} style={{ gap: "0.5rem" }}>
                  <p className={`  learnmoreabout text-start`}>
                    Skip formal emails and connect directly with recruiters and
                    alumni from your dream company to start the conversation.
                  </p>
                  {/* <div className={`mt-2`}>
                  <div
                    className="d-flex flex-wrap "
                    style={{
                      gap: "0.625rem 0.5rem",

                      marginTop: isNonMobile1441 ? "" : "1rem",
                    }}
                  >
                    {skills.map((ski, index) => (
                      <div className="newskillwrapper">
                        <img
                          src={ski.icon}
                          alt="ski.name"
                          height={"16px"}
                          width={"16px"}
                        />
                        {ski.name}
                      </div>
                    ))}
                  </div>
                </div> */}
                </div>

                <div
                  className={`  ${isNonMobile && "section d-flex"}`}
                  style={{ marginTop: "1rem" }}
                >
                  <div className="imgsunderuni ">
                    <img
                      className="shadowa rounded-5"
                      style={{ zIndex: 5 }}
                      src={p1}
                      alt="person"
                      height="44px"
                      width="44px"
                    />
                    <img
                      className=" move imgshadow rounded-5"
                      style={{ left: "-1.5rem", zIndex: 4 }}
                      src={p2}
                      height={"44px"}
                      width={"44px"}
                      alt="person"
                    />
                    <img
                      className=" move imgshadow rounded-5"
                      style={{ left: "-2.5rem", zIndex: 3 }}
                      src={p3}
                      height={"44px"}
                      width={"44px"}
                      alt="person"
                    />
                    <img
                      className=" move imgshadow rounded-5"
                      style={{ left: "-3.5rem", zIndex: 2 }}
                      src={p4}
                      height={"44px"}
                      width={"44px"}
                      alt="person"
                    />
                    <img
                      className=" move imgshadow rounded-5"
                      style={{
                        left: "-4.5rem",
                        zIndex: 1,
                        padding: "2px",
                        background: "#FFF",
                      }}
                      src={p5}
                      height={"44px"}
                      width={"44px"}
                      alt="person"
                    />
                  </div>
                </div>
              </div>{" "}
            </div>

            <div
              className="widthsecond d-flex mt-4"
              style={{
                justifyContent: isNonMobile ? "center" : "",
                // marginTop: "1rem",
              }}
            >
              <div>
                <img
                  src={img}
                  alt="newimg"
                  className={`img-fluid ${!isNonMobile && "mt-4"}`}
                  style={{ padding: isNonMobile ? "4rem" : "" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReachOut;
