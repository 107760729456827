import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import Joblist from "./Joblist";
import axios from "axios";
import { toast } from "react-toastify";
const JobFlow = () => {
  const user = useSelector((state) => state.user);
  const [selected, setSelected] = useState("Explore Jobs");
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:767px)");
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [jobs, setJobs] = useState([]);
  const isNonMobile450 = useMediaQuery("(min-width:450px)");
  const [selectedJob, setSelectedJob] = useState(null);
  const [jobNum, setJobNum] = useState(0);

  useEffect(() => {
    if (!user) {
      navigate("/login");
    } else {
      setLoading(true);

      axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/getJobs`, {
          selectedItem: user.selectedItem,
          id: user?._id,
        })
        .then((res) => {
          if (res?.data?.jobs) {
            setJobs(res.data.jobs);
            setSelectedJob(res.data.jobs[jobNum]);
          } else {
            setJobs([]);
          }
          setLoading(false);
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  }, []);

  const handleNumChange = (value) => {
    if (jobNum + value < 0 || jobNum + value > jobs.length - 1) {
      toast.error("No more jobs to show");
    } else {
      setSelectedJob(jobs[jobNum + value]);
      setJobNum((prev) => prev + value);
    }
  };

  return (
    <div className="paddingmaincontent">
      <div>
        <div
          className={`transition ${isNonMobile && "d-flex"} `}
          style={{
            justifyContent: "space-between",
            flexFlow: "wrap",
            gap: "10px",
          }}
        >
          <div className=" behindbuttons ">
            <button
              className={`  border-0  ${
                selected === "Explore Jobs"
                  ? "selectedbutton rounded-2 "
                  : "secondbuttontext"
              } ${isNonMobile ? "" : "p-1  "}`}
              style={
                isNonMobile
                  ? {
                      minWidth: "188px",
                    }
                  : {}
              }
              onClick={() => {
                setSelected("Explore Jobs");
              }}
            >
              Explore {isNonMobile450 && "Jobs"}
            </button>
            <button
              className={` border-0   ${
                selected === "Saved Jobs"
                  ? "selectedbutton rounded-2 "
                  : "secondbuttontext"
              } ${isNonMobile ? "" : "p-1  "}`}
              style={isNonMobile ? { minWidth: "188px" } : {}}
              onClick={() => {
                setSelected("Saved Jobs");
              }}
            >
              Saved {isNonMobile450 && "Jobs"}
            </button>

            <button
              className={`   border-0 ${
                selected === "Applied Jobs"
                  ? "selectedbutton rounded-2 "
                  : "secondbuttontext"
              } ${isNonMobile ? "" : "p-1  "}`}
              style={isNonMobile ? { minWidth: "188px" } : {}}
              onClick={() => {
                setSelected("Applied Jobs");
              }}
            >
              Applied {isNonMobile450 && "Jobs"}
            </button>
          </div>
          <div
            className={`d-flex  ${!isNonMobile && "mt-5"}`}
            style={{
              gap: "10px",
            }}
          >
            <div className="forminput">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M19 19L15.5001 15.5M18 9.5C18 14.1944 14.1944 18 9.5 18C4.80558 18 1 14.1944 1 9.5C1 4.80558 4.80558 1 9.5 1C14.1944 1 18 4.80558 18 9.5Z"
                  stroke="#475467"
                  strokeWidth="1.70603"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <input
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                type="text"
                placeholder="Search"
                className="searchinputuni"
              />
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "1.5rem" }}>
        {selected === "Explore Jobs" && (
          <Joblist
            jobs={jobs}
            loading={loading}
            selectedJob={selectedJob}
            setSelectedJob={setSelectedJob}
            handleNumChange={handleNumChange}
            setJobNum={setJobNum}
          />
        )}
      </div>
    </div>
  );
};
export default JobFlow;
