import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

const Second = ({ company }) => {
  return (
    <div
      id="Details"
      className="CompanyPage-First-First"
      style={{ marginTop: "2.5rem", borderRadius: "12px" }}
    >
      <h4 className="gray95024400">Company Description</h4>
      {company && company?.details && (
        <div>
          {company?.details && (
            <p
              className="allpoppins tablescroll"
              style={{
                marginTop: "0.75rem",
                paddingBottom: "1rem",
                fontWeight: "400",
                maxHeight: "260px",
                overflow: "auto",
                fontFamily: "Poppins, sans-serif", // Set the font to Poppins
              }}
              dangerouslySetInnerHTML={{ __html: company?.details }} // Render the HTML content
            />
          )}
        </div>
      )}
      {company?.website && (
        <div style={{ marginTop: "0.75rem" }}>
          <a
            href={company?.website}
            className="pink14 d-flex align-items-center"
            target="_blank"
            style={{
              color: company?.color ? company?.color : "#E31B54",
              textDecoration: "none",
              gap: "0.25rem",
            }}
          >
            {company?.website}
            <ArrowOutwardIcon style={{ height: "14px", width: "14px" }} />
          </a>
        </div>
      )}
      <div
        className="d-flex flex-wrap"
        style={{ gap: "0.75rem", marginTop: "1.25rem" }}
      >
        {company?.interest?.map((item, index) => (
          <div className="newCompanyChips">
            <img src={item.icon} alt="icon" height={"16px"} width={"16px"} />

            {item.text}
          </div>
        ))}
      </div>
    </div>
  );
};
export default Second;
