import { useState } from "react";
import CropFreeIcon from "@mui/icons-material/CropFree";
import Shortlist from "../../assets/images/shortlistTalent.png";
import animation from "../../assets/images/Animation1.mp4";
const ShortListTalent = ({ isNonMobile1000 }) => {
  const [test, setTest] = useState(false);
  const handleContextMenu = (e) => {
    e.preventDefault(); // Prevent right-click context menu
  };
  return (
    <>
      <div
        className="d-flex transition"
        style={{
          justifyContent: "center",
        }}
      >
        <div className="headerCompany">
          <div
            className="d-flex"
            style={{
              background: "#FFF",
              flexDirection: isNonMobile1000 ? "row" : "column",
            }}
          >
            <div
              className="ShortListTalentouter"
              style={{
                width: isNonMobile1000 ? (test ? "30%" : "50%") : "100%",
              }}
            >
              <div>
                <p className="ShortListTalenttitle">
                  Spark interest
                  <br /> with challenges
                </p>
                <p
                  className="gray95016500 text-start"
                  style={{ marginTop: "1.5rem", fontWeight: 400 }}
                >
                  Engage early talent in{" "}
                  <span className="gray95016500 " style={{ fontWeight: 600 }}>
                    a new and exciting way
                  </span>{" "}
                  by showcasing real-world case studies. Design challenges and
                  give potential candidates the opportunity to{" "}
                  <span className="gray95016500 " style={{ fontWeight: 600 }}>
                    explore their future career at your company
                  </span>
                  .
                </p>
              </div>
            </div>
            <div
              style={{
                width: isNonMobile1000 ? (test ? "70%" : "50%") : "100%",
              }}
            >
              {" "}
              <div style={{ position: "relative" }}>
                {isNonMobile1000 && (
                  <button
                    className=""
                    style={{
                      position: "absolute",
                      bottom: "1.5rem",
                      right: "1.5rem",
                      background: "transparent",
                      border: "none",
                      zIndex: 1,
                    }}
                    onClick={() => {
                      setTest(!test);
                    }}
                  >
                    <CropFreeIcon
                      style={{
                        height: "24px",
                        width: "24px",
                        color: "#FFF",
                        animation: "pulsate 2s infinite",
                      }}
                    />
                  </button>
                )}
                <video
                  src={animation}
                  autoPlay
                  loop
                  muted
                  playsInline
                  controls={false}
                  className="img-fluid"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover", // Ensure video covers the entire area
                    display: "block", // Remove any default inline space
                  }}
                  onContextMenu={handleContextMenu}
                />
              </div>
            </div>
          </div>
        </div>{" "}
      </div>
    </>
  );
};
export default ShortListTalent;
