// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.black10 {
  color: var(--Gray-700, #344054);
  text-align: center;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 12.823px; /* 128.226% */
}
.pink12 {
  color: var(--primary-500-p, var(--Primary-500, #f63d68));
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 133.333% */
}
.jobecardChallengeTitle {
  color: var(--Gray-800, #1d2939);

  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 120% */
}
.jobCardBlueChip {
  display: flex;
  padding: 4px 6px;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  margin-top: 0.5rem;
  border-radius: 24px;
  border: 1px solid #eff0f1;
  background: var(--Secondary-25, #fff);
}

.analyticsdiv {
  display: flex;
  min-width: 300px;
  width: 32%;
  min-height: 176px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 5px;
  background: var(--Base-White, #fff);

  /* Trans Card: Icons */
}

@media (max-width: 450px) {
  .analyticsdiv {
    min-width: 0px;
    width: 90vw;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/JobPost/main.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;EAC/B,kBAAkB;EAClB,oBAAoB;EACpB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,qBAAqB,EAAE,aAAa;AACtC;AACA;EACE,wDAAwD;EACxD,oBAAoB;EACpB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,aAAa;AAClC;AACA;EACE,+BAA+B;;EAE/B,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,SAAS;AAC9B;AACA;EACE,aAAa;EACb,gBAAgB;EAChB,mBAAmB;EACnB,QAAQ;EACR,mBAAmB;EACnB,kBAAkB;EAClB,mBAAmB;EACnB,yBAAyB;EACzB,qCAAqC;AACvC;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,UAAU;EACV,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,SAAS;EACT,kBAAkB;EAClB,mCAAmC;;EAEnC,sBAAsB;AACxB;;AAEA;EACE;IACE,cAAc;IACd,WAAW;EACb;AACF","sourcesContent":[".black10 {\n  color: var(--Gray-700, #344054);\n  text-align: center;\n  font-family: Poppins;\n  font-size: 10px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 12.823px; /* 128.226% */\n}\n.pink12 {\n  color: var(--primary-500-p, var(--Primary-500, #f63d68));\n  font-family: Poppins;\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: 16px; /* 133.333% */\n}\n.jobecardChallengeTitle {\n  color: var(--Gray-800, #1d2939);\n\n  font-size: 20px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 24px; /* 120% */\n}\n.jobCardBlueChip {\n  display: flex;\n  padding: 4px 6px;\n  align-items: center;\n  gap: 4px;\n  align-self: stretch;\n  margin-top: 0.5rem;\n  border-radius: 24px;\n  border: 1px solid #eff0f1;\n  background: var(--Secondary-25, #fff);\n}\n\n.analyticsdiv {\n  display: flex;\n  min-width: 300px;\n  width: 32%;\n  min-height: 176px;\n  padding: 24px;\n  flex-direction: column;\n  align-items: flex-start;\n  gap: 24px;\n  border-radius: 5px;\n  background: var(--Base-White, #fff);\n\n  /* Trans Card: Icons */\n}\n\n@media (max-width: 450px) {\n  .analyticsdiv {\n    min-width: 0px;\n    width: 90vw;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
