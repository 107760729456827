// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.animated-beam-svg {
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 0;
  transform: translate3d(0, 0, 0);
  stroke-width: 2;
}

.animated-gradient {
  transform: translate3d(0, 0, 0);
}
`, "",{"version":3,"sources":["webpack://./src/components/magicui/AnimatedBeam.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,kBAAkB;EAClB,OAAO;EACP,MAAM;EACN,+BAA+B;EAC/B,eAAe;AACjB;;AAEA;EACE,+BAA+B;AACjC","sourcesContent":[".animated-beam-svg {\n  pointer-events: none;\n  position: absolute;\n  left: 0;\n  top: 0;\n  transform: translate3d(0, 0, 0);\n  stroke-width: 2;\n}\n\n.animated-gradient {\n  transform: translate3d(0, 0, 0);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
