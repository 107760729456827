// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo-overlap {
  position: relative;
}

/* Adjust the negative margin as needed */
.logo-overlap img + img {
  margin-left: -15px; /* Adjust this value to control the overlap amount */
}
`, "",{"version":3,"sources":["webpack://./src/pages/TalentPool/TargetedSchools/target.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA,yCAAyC;AACzC;EACE,kBAAkB,EAAE,oDAAoD;AAC1E","sourcesContent":[".logo-overlap {\n  position: relative;\n}\n\n/* Adjust the negative margin as needed */\n.logo-overlap img + img {\n  margin-left: -15px; /* Adjust this value to control the overlap amount */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
