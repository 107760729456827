// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profilephoto{

    transform: rotate(-0.219896deg);
    filter: drop-shadow(2.94359px 2.82954px 1.17744px rgba(0, 0, 0, 0.07))
      drop-shadow(7.07386px 5.32778px 2.82954px rgba(0, 0, 0, 0.035))
      drop-shadow(13.3194px 9.50385px 5.32778px rgba(0, 0, 0, 0.0282725))
      drop-shadow(23.7596px 17.7759px 9.50385px rgba(0, 0, 0, 0.0196802))
      drop-shadow(44.4398px 42.5489px 17.7759px rgba(0, 0, 0, 0.01));
}
.profileName{
    color: var(--Gray-900, #101828);
/* Header/Header 2 */

font-size: 32px;
font-style: normal;
font-weight: 400;
line-height: 135%; 
}`, "",{"version":3,"sources":["webpack://./src/pages/user/body.css"],"names":[],"mappings":"AAAA;;IAEI,+BAA+B;IAC/B;;;;oEAIgE;AACpE;AACA;IACI,+BAA+B;AACnC,oBAAoB;;AAEpB,eAAe;AACf,kBAAkB;AAClB,gBAAgB;AAChB,iBAAiB;AACjB","sourcesContent":[".profilephoto{\n\n    transform: rotate(-0.219896deg);\n    filter: drop-shadow(2.94359px 2.82954px 1.17744px rgba(0, 0, 0, 0.07))\n      drop-shadow(7.07386px 5.32778px 2.82954px rgba(0, 0, 0, 0.035))\n      drop-shadow(13.3194px 9.50385px 5.32778px rgba(0, 0, 0, 0.0282725))\n      drop-shadow(23.7596px 17.7759px 9.50385px rgba(0, 0, 0, 0.0196802))\n      drop-shadow(44.4398px 42.5489px 17.7759px rgba(0, 0, 0, 0.01));\n}\n.profileName{\n    color: var(--Gray-900, #101828);\n/* Header/Header 2 */\n\nfont-size: 32px;\nfont-style: normal;\nfont-weight: 400;\nline-height: 135%; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
