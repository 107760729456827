const Welcome = ({ selectedVideo, isNonMobile1024 }) => {
  return (
    <>
      {selectedVideo instanceof File ? (
        <video
          src={URL.createObjectURL(selectedVideo)}
          controls={true}
          autoPlay={false}
          className="rounded-3 shadowa"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            maxHeight: "500px",
          }}
        ></video>
      ) : selectedVideo.includes("http") ? (
        <div
          id="container"
          style={{
            position: "relative",
            width: "100%",
            paddingTop: isNonMobile1024 ? "36.25%" : "56.25%",
          }}
        >
          <iframe
            title="Embedded Video"
            src={selectedVideo}
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            autoPlay={false}
            frameBorder={0}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "fill",
            }}
          ></iframe>
        </div>
      ) : (
        <video
          src={`${process.env.REACT_APP_BACKEND_URL}/uploads/${selectedVideo}`}
          controls={true}
          autoPlay={false}
          className="rounded-3 shadowa"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            maxHeight: "500px",
          }}
        ></video>
      )}
    </>
  );
};
export default Welcome;
