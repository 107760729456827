import React, { useState, useEffect } from "react";
import Header from "../../components/CompanyHome/Header";
import "./home.css";
import { useNavigate } from "react-router-dom";
import "../CompanyHome/home.css";
import HomeFirstSection from "../../components/Home/HomeFirstSection";
import "../../components/Card/styles.scss";
import HomeSecondSection from "../../components/Home/HomeSecondSection";
import HomeThirdSection from "../../components/Home/HomeThirdSection";
import HomeFooter from "../../components/Home/HomeFooter";
import { useMediaQuery } from "@mui/material";
import HomeNewSection from "../../components/Home/HomeNewSection";
import France from "../../assets/images/countries/France.png";
import Switzerland from "../../assets/images/countries/Switzerland.png";
import Germany from "../../assets/images/countries/Germany.png";
import UnitedKingdom from "../../assets/images/countries/United Kingdom.png";
import Estonia from "../../assets/images/countries/Estonia.png";

import Romania from "../../assets/images/countries/Romania.png";
import Finland from "../../assets/images/countries/Finland.png";
import Georgia from "../../assets/images/countries/Georgia.png";
import Greece from "../../assets/images/countries/Greece.png";
import Hungary from "../../assets/images/countries/Hungary.png";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Ireland from "../../assets/images/countries/Ireland.png";
import Italy from "../../assets/images/countries/Italy.png";

import Jordan from "../../assets/images/countries/Jordan.png";

import Latvia from "../../assets/images/countries/Latvia.png";
import Liechtenstein from "../../assets/images/countries/Liechtenstein.png";
import Lithuania from "../../assets/images/countries/Lithuania.png";
import Luxembourg from "../../assets/images/countries/Luxembourg.png";
import Malta from "../../assets/images/countries/Malta.png";
import Moldova from "../../assets/images/countries/Moldova.png";
import Netherlands from "../../assets/images/countries/Netherlands.png";
import NewZealand from "../../assets/images/countries/New Zealand.png";

import NorthMacedonia from "../../assets/images/countries/North Macedonia.png";
import Norway from "../../assets/images/countries/Norway.png";

import Poland from "../../assets/images/countries/Poland.png";
import Portugal from "../../assets/images/countries/Portugal.png";
import Qatar from "../../assets/images/countries/Qatar.png";

import Singapore from "../../assets/images/countries/Singapore.png";
import Slovakia from "../../assets/images/countries/Slovakia.png";
import Slovenia from "../../assets/images/countries/Slovenia.png";

import Spain from "../../assets/images/countries/Spain.png";
import Sweden from "../../assets/images/countries/Sweden.png";

import Turkey from "../../assets/images/countries/Turkey.png";

import axios from "axios";
import OnePlatform from "../../components/Home/OnePlatform";
import AllNewSection from "../../components/Home/AllNewSections";
import LearnSkills from "../../components/Home/AllNewSection2";
import ReachOut from "../../components/Home/AllNewSection3";

import GetInsights from "../../components/Home/AllNewSection4";
import NewHeader from "./TestHeader";
const Home = () => {
  const isNonMobile450 = useMediaQuery("(min-width:450px)");
  const [selectedUni, setSelectedUni] = useState();
  const isNonMobile1024 = useMediaQuery("(min-width:1025px)");
  const [Test, setTest] = useState(false);
  const [country, setCountry] = useState("");
  const [checker, setChecker] = useState(false);
  const [unilist, setUniList] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const Countries = [
    {
      icon: France,
      name: "France",
    },

    {
      icon: Germany,
      name: "Germany",
    },
    {
      icon: UnitedKingdom,
      name: "United Kingdom",
    },
    {
      icon: Switzerland,
      name: "Switzerland",
    },
    {
      icon: Estonia,
      name: "Estonia",
    },

    {
      icon: Finland,
      name: "Finland",
    },

    {
      icon: Georgia,
      name: "Georgia",
    },

    {
      icon: Greece,
      name: "Greece",
    },

    {
      icon: Hungary,
      name: "Hungary",
    },

    {
      icon: Ireland,
      name: "Ireland",
    },

    {
      icon: Italy,
      name: "Italy",
    },

    {
      icon: Latvia,
      name: "Latvia",
    },

    {
      icon: Liechtenstein,
      name: "Liechtenstein",
    },

    {
      icon: Lithuania,
      name: "Lithuania",
    },

    {
      icon: Luxembourg,
      name: "Luxembourg",
    },

    {
      icon: Malta,
      name: "Malta",
    },

    {
      icon: Moldova,
      name: "Moldova",
    },

    {
      icon: Netherlands,
      name: "Netherlands",
    },

    // {
    //   icon: NewZealand,
    //   name: "New Zealand",
    // },

    {
      icon: NorthMacedonia,
      name: "North Macedonia",
    },

    {
      icon: Norway,
      name: "Norway",
    },

    {
      icon: Poland,
      name: "Poland",
    },

    {
      icon: Portugal,
      name: "Portugal",
    },

    // {
    //   icon: Qatar,
    //   name: "Qatar",
    // },

    {
      icon: Romania,
      name: "Romania",
    },

    // {
    //   icon: Singapore,
    //   name: "Singapore",
    // },

    {
      icon: Slovakia,
      name: "Slovakia",
    },

    {
      icon: Slovenia,
      name: "Slovenia",
    },

    {
      icon: Spain,
      name: "Spain",
    },

    {
      icon: Sweden,
      name: "Sweden",
    },

    {
      icon: Turkey,
      name: "Turkey",
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      if (country) {
        setLoading(true);
        await axios
          .post(`${process.env.REACT_APP_BACKEND_URL}/get/university`, {
            name: country.name,
          })
          .then((res) => {
            if (res.data.msg === "success") {
              setUniList(res.data.university);
              setLoading(false);
            } else {
              console.log(res.data);
            }
          })
          .catch((err) => {
            setLoading(false);
          });

        // setUniList(data);
        setLoading(false);
      }
    };
    fetchData();
  }, [country]);

  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const response = await fetch(
          "https://apiip.net/api/check?accessKey=5576a302-6489-4661-8958-67430c4fccee"
        );
        const data = await response.json();

        const userCountry = data.countryName || "Unknown";

        const matchingCountry = Countries.find(
          (country) => country.name.toLowerCase() === userCountry.toLowerCase()
        );

        if (matchingCountry) {
          setCountry(matchingCountry);
          setChecker(true);
        } else {
          setCountry(null);
        }
      } catch (error) {
        console.error("Error fetching country:", error.message);
      }
    };

    fetchCountry();
  }, []);
  const truncateString = (str, maxLength) => {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + "...";
    } else {
      return str;
    }
  };

  return (
    <div style={{ width: "100vw", overflowX: "clip" }}>
      <div className="topnotificationbar">
        <div className="marginsleftrightCompany ">
          <div className="center">
            <div className="headerCompany center">
              <div
                className="d-flex align-items-center"
                style={{ gap: "0.75rem" }}
              >
                <div
                  className="hover-container d-flex align-items-center"
                  style={{ gap: "0.75rem" }}
                  onClick={() => {
                    navigate("/company");
                  }}
                >
                  <p className="topnoticationtext">
                    Hi, Campus Recruiter!{" "}
                    <span>
                      We are currently onboarding employers in the Netherlands
                    </span>
                  </p>
                  <div
                    className="d-flex align-items-center"
                    style={{ gap: "0.5rem" }}
                  >
                    {/* <img
                      src={France}
                      alt="france"
                      style={{ height: "20px", width: "20px" }}
                    />
                    <img
                      src={Germany}
                      alt="germany"
                      style={{ height: "20px", width: "20px" }}
                    /> */}
                    <img
                      src={Netherlands}
                      alt="netherlands"
                      style={{ height: "20px", width: "20px" }}
                    />
                    <ChevronRightIcon
                      style={{ height: "20px", width: "20px" }}
                      className="topnoticationright"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NewHeader setTest={setTest} Test={Test} />
      <div className="newHomeBackground">
        {/* <Header setTest={setTest} Test={Test} /> */}
        {/* {!Test && (
        <> */}
        <div className="marginsleftrightCompany">
          <HomeFirstSection
            isNonMobile450={isNonMobile450}
            unilist={unilist}
            selectedUni={selectedUni}
            setSelectedUni={setSelectedUni}
            country={country}
            setCountry={setCountry}
            Countries={Countries}
            loading={loading}
            checker={checker}
            setChecker={setChecker}
            truncateString={truncateString}
            isNonMobile1024={isNonMobile1024}
          />
        </div>
        <HomeSecondSection />
        <div
          className=""
          style={{
            width: "100vw",
            marginTop: isNonMobile450 ? "2rem" : "5rem",
            paddingBottom: isNonMobile450 ? "10rem" : "4rem",
          }}
        >
          <div className="marginsleftrightCompany ">
            <div className="d-flex" style={{ justifyContent: "center" }}>
              <div className="headerCompany">
                <HomeNewSection />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <OnePlatform />
      </div>

      <div style={{ background: "#F2F3F7" }}>
        <AllNewSection />
        <LearnSkills />
        <ReachOut />
        <GetInsights />
      </div>
      <div className="marginsleftrightCompany ">
        <HomeThirdSection
          unilist={unilist}
          selectedUni={selectedUni}
          setSelectedUni={setSelectedUni}
          country={country}
          setCountry={setCountry}
          Countries={Countries}
          loading={loading}
          checker={checker}
          setChecker={setChecker}
          truncateString={truncateString}
        />
        <div className="d-flex" style={{ justifyContent: "center" }}>
          <div className="headerCompany">
            <HomeFooter />{" "}
          </div>
        </div>
      </div>
      {/* </>
      )} */}
    </div>
  );
};

export default Home;
