import { useState } from "react";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { toast } from "react-toastify";
import axios from "axios";
const Password = ({ user, isNonMobile }) => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setNewShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handleSubmit = () => {
    if (password !== confirmPassword) {
      toast.error("New Password does not match with Confirm Password");
      return;
    } else if (password.length < 6) {
      toast.error("Password should be atleast 6 characters long");
      return;
    } else if (password === currentPassword) {
      toast.error("New Password cannot be same as current password");
    } else {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/api/change/organiser/Password`,
          {
            currentPassword,
            password,
            confirmPassword,
            email: user.email,
          }
        )
        .then((res) => {
          if (res.data.msg === "success") {
            toast.success("Password changed successfully");
            setCurrentPassword("");
            setPassword("");
            setConfirmPassword("");
          } else if (res.data.msg === "wrongpass") {
            toast.error("Wrong current Password");
          } else {
            toast.error("Error Changing Password");
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  };
  return (
    <div
      style={{
        marginTop: "1.25rem",
        width: "100%",
        maxWidth: isNonMobile ? "50vw" : "",
      }}
    >
      <div className="col-md-12 col-12">
        <div className="" style={{ marginTop: "1.5rem" }}>
          <p className="Email"> Password</p>
          <p style={{ marginTop: "0.25rem" }} className="underthumbnailcreate">
            Please enter your current password to change your password.
          </p>
          <p className="thumbnailcreate" style={{ marginTop: "1.5rem" }}>
            Current Password
          </p>
        </div>
        <div className="position-relative">
          <input
            value={currentPassword}
            type={showPassword ? "text" : "password"}
            onChange={(e) => setCurrentPassword(e.target.value)}
            className="rounded-3 newinputs  col-md-12 col-12 "
            style={{ marginTop: "0.75rem" }}
          />
          <div
            className="position-absolute end-10 top-50 translate-middle-y"
            style={{
              right: "10px",
              cursor: "pointer",
            }}
            onClick={togglePasswordVisibility}
          >
            {showPassword ? (
              <VisibilityOffOutlinedIcon />
            ) : (
              <RemoveRedEyeOutlinedIcon />
            )}
          </div>
        </div>
        <p className="thumbnailcreate" style={{ marginTop: "1.5rem" }}>
          New Password
        </p>
        <div className="position-relative">
          <input
            value={password}
            type={showNewPassword ? "text" : "password"}
            onChange={(e) => setPassword(e.target.value)}
            className="rounded-3 newinputs  col-md-12 col-12 "
            style={{ marginTop: "0.75rem" }}
          />
          <div
            className="position-absolute end-10 top-50 translate-middle-y"
            style={{
              right: "10px",
              cursor: "pointer",
            }}
            onClick={() => setNewShowPassword(!showNewPassword)}
          >
            {showNewPassword ? (
              <VisibilityOffOutlinedIcon />
            ) : (
              <RemoveRedEyeOutlinedIcon />
            )}
          </div>
        </div>
        <p className="thumbnailcreate" style={{ marginTop: "1.5rem" }}>
          Confirm new Password
        </p>
        <div className="position-relative">
          <input
            value={confirmPassword}
            type={showConfirmPassword ? "text" : "password"}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="rounded-3 newinputs  col-md-12 col-12 "
            style={{ marginTop: "0.75rem" }}
          />
          <div
            className="position-absolute end-10 top-50 translate-middle-y"
            style={{
              right: "10px",
              cursor: "pointer",
            }}
            onClick={() => setConfirmShowPassword(!showConfirmPassword)}
          >
            {showConfirmPassword ? (
              <VisibilityOffOutlinedIcon />
            ) : (
              <RemoveRedEyeOutlinedIcon />
            )}
          </div>
        </div>
        <hr style={{ marginTop: "2.5rem", marginBottom: "1.25rem" }} />
        <div className="d-flex" style={{ justifyContent: "end" }}>
          <div className="d-flex flex-wrap" style={{ gap: "1rem" }}>
            <button
              className="backbutton"
              style={{ maxHeight: "40px" }}
              onClick={() => {
                setCurrentPassword("");
                setPassword("");
                setConfirmPassword("");
              }}
            >
              Cancel
            </button>
            <button
              className="continuebutton"
              style={{ maxHeight: "40px" }}
              disabled={!currentPassword || !password || !confirmPassword}
              onClick={() => {
                handleSubmit();
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Password;
