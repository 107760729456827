const Location = ({ color, width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : "40"}
      height={height ? height : "40"}
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        d="M28.033 25.809C27.4249 25.5034 26.6841 25.7404 26.3663 26.3423C26.0727 26.9554 26.3155 27.691 26.9163 28.009C28.383 28.759 29.2163 29.6756 29.2163 30.559C29.2163 32.4423 25.283 34.5423 20.0163 34.5423C14.7497 34.5423 10.7997 32.459 10.7997 30.6256C10.7997 29.759 11.633 28.8256 13.0997 28.0756C13.5395 27.8993 13.8418 27.4894 13.8803 27.017C13.9189 26.5447 13.687 26.0913 13.2816 25.8459C12.8761 25.6006 12.3668 25.6057 11.9663 25.859C9.94284 26.6216 8.53214 28.4724 8.33301 30.6256C8.33301 34.3256 13.333 37.109 19.9997 37.109C26.6663 37.109 31.6663 34.3256 31.6663 30.6256C31.4856 28.4537 30.0717 26.5794 28.033 25.809Z"
        fill={color ? color : "#0C111D"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7497 31.359V24.159C13.2612 23.4977 9.23722 18.6761 9.56845 13.1579C9.89968 7.63959 14.4715 3.33398 19.9997 3.33398C25.5279 3.33398 30.0997 7.63959 30.4309 13.1579C30.7621 18.6761 26.7382 23.4977 21.2497 24.159V31.359C21.2497 32.0493 20.69 32.609 19.9997 32.609C19.3093 32.609 18.7497 32.0493 18.7497 31.359ZM27.3734 10.7359C26.136 7.75742 23.2249 5.81888 19.9997 5.82562C15.6128 5.83479 12.0588 9.38876 12.0497 13.7756C12.0429 17.0009 13.9815 19.912 16.9599 21.1493C19.9384 22.3867 23.369 21.7061 25.6496 19.4256C27.9302 17.145 28.6107 13.7143 27.3734 10.7359Z"
        fill={color ? color : "#0C111D"}
      />
      <path
        d="M15.983 15.6256C16.6734 15.6256 17.233 15.066 17.233 14.3756C17.233 12.461 18.7851 10.909 20.6997 10.909C21.39 10.909 21.9497 10.3493 21.9497 9.65895C21.9497 8.9686 21.39 8.40895 20.6997 8.40895C19.1128 8.41337 17.5927 9.04799 16.4737 10.1732C15.3547 11.2984 14.7286 12.8221 14.733 14.409C14.7374 14.7361 14.8716 15.048 15.106 15.2762C15.3404 15.5044 15.6559 15.6301 15.983 15.6256Z"
        fill={color ? color : "#0C111D"}
      />
    </svg>
  );
};
export default Location;
