import React from "react";
import "./styles.css";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
function PrimaryFooter() {
  const isNonMobile = useMediaQuery("(min-width: 768px)");
  const navigate = useNavigate();
  return (
    <div
      className={`d-flex   `}
      style={{
        justifyContent: isNonMobile ? "end" : "center",
      }}
    >
      <button
        className="footertextnew btn"
        style={{ cursor: "pointer", textDecoration: "none" }}
        onClick={() => window.open("/privacy", "_blank")}
      >
        Privacy Policy
      </button>
      <button
        className="footertextnew btn"
        style={{ cursor: "pointer", textDecoration: "none" }}
        onClick={() => window.open("/terms", "_blank")}
      >
        Terms of Use
      </button>
    </div>
  );
}

export default PrimaryFooter;
