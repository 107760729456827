// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slider-container {
  min-width: 300px;
  max-width: 600px;
  position: relative;
}

.custom-slider {
  appearance: none;
  width: 100%;
  height: 10px;
  border-radius: 5px;
  outline: none;
  opacity: 1;
  transition: opacity 0.2s;
  cursor: pointer;
  position: relative;
  z-index: 1;
}

.custom-slider:hover {
  opacity: 1;
}

.custom-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 20px; /* Set the width of the knob */
  height: 20px; /* Set the height of the knob */
  background-color: #e31b53; /* Set the color of the knob */
  border-radius: 50%; /* Make it circular */
  border: 2px solid #fff; /* Add a border for better visibility */
  /* Adjust vertical positioning */
}
`, "",{"version":3,"sources":["webpack://./src/pages/Challenge/taskstep5.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EAEE,gBAAgB;EAChB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,UAAU;EACV,wBAAwB;EACxB,eAAe;EACf,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,wBAAwB;EACxB,WAAW,EAAE,8BAA8B;EAC3C,YAAY,EAAE,+BAA+B;EAC7C,yBAAyB,EAAE,8BAA8B;EACzD,kBAAkB,EAAE,qBAAqB;EACzC,sBAAsB,EAAE,uCAAuC;EAC/D,gCAAgC;AAClC","sourcesContent":[".slider-container {\n  min-width: 300px;\n  max-width: 600px;\n  position: relative;\n}\n\n.custom-slider {\n  -webkit-appearance: none;\n  appearance: none;\n  width: 100%;\n  height: 10px;\n  border-radius: 5px;\n  outline: none;\n  opacity: 1;\n  transition: opacity 0.2s;\n  cursor: pointer;\n  position: relative;\n  z-index: 1;\n}\n\n.custom-slider:hover {\n  opacity: 1;\n}\n\n.custom-slider::-webkit-slider-thumb {\n  -webkit-appearance: none;\n  width: 20px; /* Set the width of the knob */\n  height: 20px; /* Set the height of the knob */\n  background-color: #e31b53; /* Set the color of the knob */\n  border-radius: 50%; /* Make it circular */\n  border: 2px solid #fff; /* Add a border for better visibility */\n  /* Adjust vertical positioning */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
