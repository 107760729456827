import JobCard from "../../JobPost/jobCard";
import Carousel from "react-multi-carousel";
const LatestJobposts = ({ color, jobposts, navigate }) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1440 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 1440, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div
      className="marginsleftrightCompany"
      style={{ marginTop: "8.125rem" }}
      id="jobposts"
    >
      <div className="d-flex" style={{ justifyContent: "center" }}>
        <div className={`headerCompany`}>
          {console.log(jobposts)}
          <div
            className="space flex-wrap"
            style={{ gap: "1rem", alignItems: "center" }}
          >
            <p className="registeration">Latest Job Posts</p>
            <p className="black16" style={{ color: color, cursor: "pointer" }}>
              See all Challenges
            </p>
          </div>
          <div style={{ marginTop: "2.5rem", gap: "1.25rem" }}>
            <Carousel responsive={responsive} className="p-2 pb-3 ">
              {jobposts &&
                jobposts.length > 0 &&
                jobposts.map((challe, index) => (
                  <div key={index}>
                    <JobCard challe={challe} index={index} TRUE={true} />
                  </div>
                ))}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LatestJobposts;
