import { useMediaQuery } from "@mui/material";

import img from "../../assets/images/getfasttracked.png";
import book from "../../assets/images/book.png";
import trophy from "../../assets/images/trophy.png";
import user from "../../assets/images/user.png";
import search from "../../assets/images/searcha.png";
import briefcase from "../../assets/images/briefcase.png";
import building from "../../assets/images/building.png";

const AllNewSection = () => {
  const isNonMobile = useMediaQuery("(min-width:768px)");

  const isNonMobile1441 = useMediaQuery("(min-width:1441px)");

  const skills = [
    {
      name: "Internship",
      icon: trophy,
    },
    {
      name: "Full-Time",
      icon: briefcase,
    },
    {
      name: "Thesis",
      icon: user,
    },
    {
      name: "Research Grant",
      icon: building,
    },
    {
      name: "Insights",
      icon: search,
    },
    { name: "Pre-Skilling", icon: book },
  ];

  return (
    <div
      className="marginsleftrightCompany "
      style={{
        paddingTop: isNonMobile ? "8.125rem" : "5rem",
        paddingBottom: isNonMobile ? "8.125rem" : "5rem",
      }}
    >
      <div
        className="d-flex"
        style={{ justifyContent: "center", position: "relative" }}
      >
        <div
          className={` headerCompany d-flex  ${
            isNonMobile && " sectioncenter"
          }`}
          style={{
            gap: "1.5rem",
            flexDirection: isNonMobile ? "row" : "column",
          }}
        >
          <div className="widthfirst d-flex" style={{ alignItems: "center" }}>
            <div>
              {/* <p className=" sparkInterest">
                Get <span>Fast-Tracked</span>
              </p> */}
              <p
                style={{
                  marginTop: "1rem",
                  maxWidth: isNonMobile ? "478px" : "",
                  width: "100%",
                }}
                className="itsearly "
              >
                Complete challenges to{" "}
                <span className="pinkspan">fast-track</span> your application.
              </p>
              <div
                className={`marginfromabove ${isNonMobile1441 && ""} `}
                style={{ gap: "0.5rem" }}
              >
                <p className={`  learnmoreabout text-start`}>
                  Challenges don’t only let you explore potential employers but
                  gives you an edge for your job or internship application.
                </p>
                <div>
                  <div
                    className="d-flex flex-wrap "
                    style={{
                      gap: "0.625rem 0.5rem",

                      marginTop: isNonMobile1441 ? "2rem" : "2rem",
                    }}
                  >
                    {skills.map((ski, index) => (
                      <div className="newskillwrapper">
                        <img
                          src={ski.icon}
                          alt="ski.name"
                          height={"16px"}
                          width={"16px"}
                        />
                        {ski.name}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {/*  */}
            </div>
          </div>{" "}
          <div
            className="widthsecond d-flex"
            style={{
              justifyContent: isNonMobile ? "center" : "",
              // marginTop: "1rem",
            }}
          >
            <div>
              <img src={img} alt="newimg" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AllNewSection;
