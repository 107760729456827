// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grid-container {
  display: grid;
  gap: 20px;
  width: 100%;
}

.grid-item {
  display: flex;
  height: 284px;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0;
  justify-content: end;
  border-radius: 5px;
  border-left: 2px solid #fff;
}

/* Define number of columns based on media queries */
.columns-1 {
  grid-template-columns: repeat(1, 1fr); /* 1 column */
}

.hovercolorback:hover {
  color: black;
}

.columns-2 {
  grid-template-columns: repeat(2, 1fr); /* 2 columns */
}

.columns-3 {
  grid-template-columns: repeat(3, 1fr); /* 3 columns */
}

.columns-4 {
  grid-template-columns: repeat(4, 1fr); /* 4 columns */
}
`, "",{"version":3,"sources":["webpack://./src/pages/TestAgain/Document.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;EACT,WAAW;AACb;;AAEA;EACE,aAAa;EACb,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,SAAS;EACT,SAAW;EACX,oBAAoB;EACpB,kBAAkB;EAClB,2BAA2B;AAC7B;;AAEA,oDAAoD;AACpD;EACE,qCAAqC,EAAE,aAAa;AACtD;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,qCAAqC,EAAE,cAAc;AACvD;;AAEA;EACE,qCAAqC,EAAE,cAAc;AACvD;;AAEA;EACE,qCAAqC,EAAE,cAAc;AACvD","sourcesContent":[".grid-container {\n  display: grid;\n  gap: 20px;\n  width: 100%;\n}\n\n.grid-item {\n  display: flex;\n  height: 284px;\n  padding: 16px;\n  flex-direction: column;\n  align-items: flex-start;\n  gap: 10px;\n  flex: 1 0 0;\n  justify-content: end;\n  border-radius: 5px;\n  border-left: 2px solid #fff;\n}\n\n/* Define number of columns based on media queries */\n.columns-1 {\n  grid-template-columns: repeat(1, 1fr); /* 1 column */\n}\n\n.hovercolorback:hover {\n  color: black;\n}\n\n.columns-2 {\n  grid-template-columns: repeat(2, 1fr); /* 2 columns */\n}\n\n.columns-3 {\n  grid-template-columns: repeat(3, 1fr); /* 3 columns */\n}\n\n.columns-4 {\n  grid-template-columns: repeat(4, 1fr); /* 4 columns */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
