// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.black16 {
  color: var(--Gray-950, #0c111d);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}
.black18 {
  color: var(--Gray-950, #0c111d);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 133.333% */
}

.newblack18 {
  color: var(--Gray-950, #0c111d);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 4 00;
  line-height: 28px;
}
.newblack18 span {
  color: var(--Gray-900, #101828);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Challenge/PreviewPage/FifthSection/fifthSection.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;EAC/B,kBAAkB;EAClB,oBAAoB;EACpB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,SAAS;AAC9B;AACA;EACE,+BAA+B;EAC/B,oBAAoB;EACpB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,aAAa;AAClC;;AAEA;EACE,+BAA+B;EAC/B,oBAAoB;EACpB,eAAe;EACf,kBAAkB;EAClB,iBAAiB;EACjB,iBAAiB;AACnB;AACA;EACE,+BAA+B;EAC/B,oBAAoB;EACpB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;AACnB","sourcesContent":[".black16 {\n  color: var(--Gray-950, #0c111d);\n  text-align: center;\n  font-family: Poppins;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 24px; /* 150% */\n}\n.black18 {\n  color: var(--Gray-950, #0c111d);\n  font-family: Poppins;\n  font-size: 18px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 24px; /* 133.333% */\n}\n\n.newblack18 {\n  color: var(--Gray-950, #0c111d);\n  font-family: Poppins;\n  font-size: 18px;\n  font-style: normal;\n  font-weight: 4 00;\n  line-height: 28px;\n}\n.newblack18 span {\n  color: var(--Gray-900, #101828);\n  font-family: Poppins;\n  font-size: 18px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 28px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
