import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
const NewSkillSelect = ({ setStep, learns, setLearns, selectedItem }) => {
  const [percentage, setPercentage] = useState(0);
  const [selected, setSelected] = useState({ text: "Conceptual Skills" });
  const [learn, setLearn] = useState("");
  const handlelearnSubmitForm = (concept) => {
    if (!learns.includes(concept)) {
      if (learns.length < 12) {
        setLearns([...learns, concept]);
        setLearn("");
      } else {
        toast.error("Only upto 12 skills are allowed");
      }
    } else {
      setLearns(learns.filter((learn) => learn !== concept));
    }
  };

  useEffect(() => {
    setPercentage((learns.length / 5) * 100);
  }, [learns]);

  const handlelearnRemove = (ski) => {
    setLearns(learns.filter((learn) => learn !== ski));
  };

  const handlelearnSubmit = (e) => {
    e.preventDefault();
    if (learn !== "" && !learns.includes(learn)) {
      if (learns.length < 12) {
        setLearns([...learns, learn]);
        setLearn("");
      } else {
        toast.error("Only upto 12 skills are allowed");
      }
    }
  };

  const conceptual = {
    conceptual: [
      "Analytical Thinking",
      "Business Processes",
      "Creative Thinking",
      "Critical thinking",
      "Interpersonal Skills",
      "Effective Communication",
      "Cross-team Collaboration",
      "Negotiation",
      "Presentation Skills",
      "Relationship Building",
      "Strategic Thinking",
      "Team Building",
      "Teamwork",
      "Written Communication",
      "Decision-Making",
    ],
    consulting: [
      "Analytical Procedures",
      "Audit Deliverable",
      "Audit Planning",
      "Project Planning",
      "Industry Analysis",
      "Risk Assessment",
      "Policy Analysis",
      "Accounting",
      "Revenue Optimization",
      "Prioritization",
      "Commercial Awareness",
      "Risk management",
      "Attention to detail",
      "MS Excel",
      "MS Powerpoint",
    ],
    computer: [
      "Data Structures",
      "Java",
      "Python",
      "Data Visualization",
      "SQL",
      "TDD",
      "Risk management",
      "Process optimization",
    ],
    brand: [
      "Brand and Image Management",
      "Social Media",
      "Market Research",
      "Data Analysis",
      "Public Relations Management",
      "Advertising",
      "Funnel Strategy",
      "Digital Marketing",
      "CRM Management",
      "Project Management ",
    ],
    supply: [
      "Project Management",
      "ERP Systems",
      "Inventory Management Software",
      "Supply Chain Management Software",
      "Forecasting Techniques",
      "Procurement and Sourcing Tools",
      "Logistics and Transportation Management",
      "Warehouse Management Systems (WMS)",
      "QMS",
      "Automation",
      "R Programming",
      "Python",
    ],
    government: [
      "Legal Research",
      "Policy Analysis",
      "Project Management",
      "Public Administration",
      "Legislative Process",
      "Quantitative Methods",
      "Budgeting",
      "Policy implementation",
      "Cybersecurity Awareness",
      "GIS and Mapping",
    ],
    software: [
      "Programming Languages",
      "Machine Learning and AI Frameworks",
      "Data Manipulation and Analysis",
      "Deep Learning",
      "Natural Language Processing (NLP)",
      "Computer Vision",
      "Version Control Systems",
      "Cloud Computing",
      "Software Development Lifecycle (SDLC)",
      "Problem-Solving and Algorithm Design",
    ],
    finance: [
      "Company Analysis",
      "Credit Analysis",
      "DCF",
      "DCM",
      "Deal Structuring",
      "ECM",
      "Financial Analysis",
      "Financial Data",
      "M&A Process Understanding",
      "M&A Screening",
      "Financial Modelling",
      "Risk Assessment",
      "Transaction Analysis",
      "Industry Analysis",
      "Derivatives",
    ],
    economics: [
      "Economic Research",
      "Economic Analysis",
      "Econometrics",
      "Project Management",
      "Policy Analysis",
      "SAS",
      "Data Collection",
      "Statistical Analysis",
      "Interest Rates",
      "Macroeconomic Analysis",
      "Financial Modelling",
      "MS Excel",
      "MS Powerpoint",
      "SQL",
      "Forecasting",
    ],
    corporate: [
      "Attention to Detail",
      "Contract Law",
      "Client Communication Skills",
      "Due Diligence",
      "Contract Negotiation",
      "Contract Analysis",
      "Document Structuring",
      "Note-Taking",
      "Identifying Legal Issues",
      "Legal Research",
      "Commercial Awareness",
      "Drafting",
      "Document review",
    ],
    hr: [
      "HR Planning",
      "Commercial Awareness",
      "HR Information Systems",
      "HR Analytics",
      "Workforce Planning",
      "Performance Management",
      "Training and Development",
      "Compensation and Benefits ",
      "Workplace Health and Safety",
      "HR Metrics and Analytics",
      "Onboarding and Orientation",
      "Recruitment and Selection Techniques",
    ],
    aviation: [
      "Project Management",
      "Aviation Regulations",
      "Aircraft Maintenance",
      "Safety Management Systems (SMS)",
      "Air Traffic Management",
      "Flight Planning and Dispatch",
      "Aircraft Design and Engineering",
      "Aviation Security",
      "Airport Operations",
      "Emergency Response Procedures",
    ],
    pharma: [
      "Commercial Awareness",
      "Pharmacoeconomics",
      "Biotechnology",
      "Regulatory Compliance",
      "Laboratory Techniques",
      "Analytical Instrumentation",
      "Quality Assurance",
      "Quality Control",
      "Manufacturing",
    ],
    technology: [
      "Machine Learning",
      "Python",
      "JavaScript",
      "AI Algorithms",
      "Decision Trees",
      "Data Structures",
      "TensorFlow",
      "Project Management",
      "Cloud Computing",
      "Networks",
    ],
    marketing: [
      "Account Management",
      "Digital Transformation",
      "Product Development",
      "Project Management",
      "Market research",
      "Public relations management",
      "Social Media",
      "Advertising",
      "Funnel Strategy",
    ],
    estate: [
      "Market Research",
      "Financial Analysis",
      "Property Valuation",
      "Real Estate Investment Analysis",
      "Real Estate Law",
      "Transaction Management",
      "Property Management",
      "Real Estate Marketing",
      "RE Development",
      "Geographic Information Systems",
      "Real Estate Financing",
      "Due Diligence",
    ],
    start: [
      "Agility",
      "Product Management",
      "Digital Marketing",
      "Programming",
      "Web Development",
      "App Development",
      "Cloud Computing",
      "E-commerce",
      "CRM",
      "Social Media Management",
      "Content Creation",
    ],
    automotive: [
      "Automotive Engineering",
      "CAD/CAM Software",
      "Automotive Electronics",
      "Powertrain Systems",
      "Control Systems",
      "EV Technology",
      "Hybrid Technology",
      "Manufacturing Processes",
      "Charging Infrastructure",
      "Battery Technology",
      "Sustainability",
    ],
    fmcg: [
      "Category Management",
      "Forecasting",
      "Demand Planning",
      "Supply Chain Management",
      "Retail Analytics",
      "Product Development",
      "Product Innovation",
      "Trade Marketing",
      "Quality Assurance",
      "Consumer Insights",
    ],
    engineering: [
      "Project Management",
      "Technical Drawing",
      "CAD Modeling",
      "Mechanical Engineering",
      "Electrical Engineering",
      "Software Programming",
      "Robotics",
      "Materials Science",
      "Structural Analysis",
    ],
    data: [
      "Data Analysis",
      "Data Modeling",
      "Data Presentation",
      "Data Understanding",
      "Data Interpretation",
      "Data Mining",
      "Process Mining",
      "Statistical Analysis",
      "MS Excel",
      "MS Power BI",
      "MS PowerPoint",
    ],
  };

  const handleContinue = () => {
    if (learns.length < 5) {
      toast.error("Please select at least 5 skills");
      return;
    } else {
      setStep(5);
    }
  };

  return (
    <div className=" col-md-12">
      <div className="">
        <p className="BasicInforMationHeading">Categorize your challenge</p>
        <p className="UnderBasicHeading">
          Categorize the challenge to attract the right students.
        </p>
        <div className=" " style={{ marginTop: "2.5rem" }}>
          <p className="thumnailcreate">Skills students will learn</p>
          <p className="underthumbnailcreate" style={{ marginTop: "0.25rem" }}>
            Select a minimum of 5 (maximum 12 skills) students will learn, apply
            or expand on.
          </p>
          <div
            className="d-flex align-items-center"
            style={{ marginTop: "0.5rem", gap: "0.5rem" }}
          >
            <div style={{ height: "32px", width: "32px" }}>
              <CircularProgressbar
                value={percentage}
                strokeWidth={13}
                styles={buildStyles({
                  textColor: "transparent", // Hide the text
                  pathColor: learns.length > 4 ? "#079455" : "#e31b54",
                  trailColor: "#EAECF0",
                })}
              />
            </div>
            <p className="black14500"> {learns.length}/5 selected</p>
          </div>
          <div
            className="d-flex flex-wrap"
            style={{ marginTop: "0.75rem", gap: "12px" }}
          >
            <div
              className={`ChipCover ${
                selected.text === "Conceptual Skills"
                  ? "selected_interests_new"
                  : ""
              }`}
              onClick={() => setSelected({ text: "Conceptual Skills" })}
              whileTap={{ scale: 0.9 }}
            >
              {" "}
              <p
                className={`ChipsText  ${
                  selected.text === "Conceptual Skills"
                    ? "chipstextactivated"
                    : ""
                } `}
              >
                Conceptual Skills
              </p>
            </div>

            {selectedItem.length > 0 &&
              selectedItem.map((item, index) => (
                <div
                  className={`ChipCover ${
                    selected.text === item.text ? "selected_interests_new" : ""
                  }`}
                  onClick={() => setSelected(item)}
                  whileTap={{ scale: 0.9 }}
                  key={index}
                >
                  {item.icon !== null && (
                    <img
                      src={item.icon}
                      alt={item.text}
                      height="20px"
                      width="20px"
                      className="interest_icon"
                      style={{ maxHeight: "1.25rem", maxWidth: "1.25rem" }}
                    />
                  )}
                  <p
                    className={`ChipsText  ${
                      selected.text === item.text ? "chipstextactivated" : ""
                    } `}
                  >
                    {item.text}
                  </p>
                </div>
              ))}
            <div
              className={`ChipCover ${
                selected.text === "Data Skills" ? "selected_interests_new" : ""
              }`}
              onClick={() => setSelected({ text: "Data Skills" })}
              whileTap={{ scale: 0.9 }}
            >
              <p
                className={`ChipsText  ${
                  selected.text === "Data Skills" ? "chipstextactivated" : ""
                } `}
              >
                Data Skills
              </p>
            </div>
          </div>
          <div style={{ marginTop: "1rem", width: "100%" }}>
            {Object.entries(conceptual).map(([interest, skills], index) => {
              if (
                selected.text.toLowerCase().indexOf(interest.toLowerCase()) !==
                -1
              ) {
                return (
                  <div key={index}>
                    <div
                      className="newSkillSelectouter"
                      style={{ width: "100%" }}
                    >
                      {selected.icon && (
                        <img
                          src={selected.icon}
                          alt={selected.text}
                          height="20px"
                          width="20px"
                          className="interest_icon"
                          style={{
                            maxHeight: "1.25rem",
                            maxWidth: "1.25rem",
                          }}
                        />
                      )}

                      {selected.text}
                    </div>
                    <div className="newSkillSelectlower">
                      <div
                        className="d-grid"
                        style={{
                          marginTop: "1rem",
                          marginBottom: "1rem",
                          gap: "1rem 0rem",
                          gridTemplateColumns: `repeat(auto-fill, minmax(200px, 1fr))`,
                        }}
                      >
                        {skills.map((skill, idx) => (
                          <div
                            className="d-flex conceptskillstext "
                            key={idx}
                            style={{
                              gap: "0.5rem",
                              cursor: "pointer",
                            }}
                            onClick={() => handlelearnSubmitForm(skill)}
                          >
                            {" "}
                            <div className="">
                              {learns.includes(skill) ? (
                                <>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z"
                                      fill="#E31B54"
                                    />
                                  </svg>
                                </>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M5.00521 1.66602H15.0052C16.8462 1.66602 18.3385 3.1584 18.3385 4.99935V14.9993C18.3385 16.8403 16.8462 18.3327 15.0052 18.3327H5.00521C3.16426 18.3327 1.67188 16.8403 1.67188 14.9993V4.99935C1.67188 3.1584 3.16426 1.66602 5.00521 1.66602ZM16.4783 16.4725C16.869 16.0818 17.0885 15.5519 17.0885 14.9993V4.99935C17.0885 4.44681 16.869 3.91691 16.4783 3.52621C16.0876 3.13551 15.5577 2.91602 15.0052 2.91602H5.00521C3.85461 2.91602 2.92188 3.84876 2.92188 4.99935V14.9993C2.92188 15.5519 3.14137 16.0818 3.53207 16.4725C3.92277 16.8632 4.45267 17.0827 5.00521 17.0827H15.0052C15.5577 17.0827 16.0876 16.8632 16.4783 16.4725Z"
                                    fill="#344054"
                                  />
                                </svg>
                              )}
                            </div>
                            {skill}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                );
              }
              return null;
            })}
          </div>
          <div style={{ marginTop: "2.5rem" }}>
            <p className="thumbnailcreate " style={{ marginTop: "1.5rem" }}>
              Can’t find the right skills from the list?
            </p>
            <p className=" underthumbnailcreate" style={{ marginTop: "" }}>
              Here you can add additional skills.
            </p>
            <form onSubmit={(e) => handlelearnSubmit(e)}>
              <div
                className="forminput col-md-12 col-12 mt-2 space align-items-center"
                style={{ gap: "0.5rem" }}
              >
                <div className="col-md-8 col-8">
                  <input
                    value={learn}
                    className="gray14"
                    onChange={(e) => setLearn(e.target.value)}
                    style={{ border: "none", width: "100%", outline: "none" }}
                    placeholder="e.g, Data Presentation, Communication Skills,  etc."
                  />
                </div>
                <div
                  className="d-flex"
                  style={{ gap: "0.5rem", alignItems: "center" }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M12.3 3.9834H5.69164C5.34646 3.9834 5.06664 4.26322 5.06664 4.6084C5.06664 4.95358 5.34646 5.2334 5.69164 5.2334H12.2583C13.9324 5.2788 15.2605 6.65881 15.2416 8.3334V10.1417C15.2416 11.8446 13.8612 13.2251 12.1583 13.2251H6.24997L7.62497 11.8334C7.7441 11.7173 7.81128 11.5581 7.81128 11.3917C7.81128 11.2254 7.7441 11.0661 7.62497 10.9501C7.38096 10.7064 6.98565 10.7064 6.74164 10.9501L4.29997 13.3917C4.24348 13.4495 4.19826 13.5174 4.16664 13.5917C4.10852 13.7476 4.10852 13.9192 4.16664 14.0751C4.19826 14.1494 4.24348 14.2173 4.29997 14.2751L6.74164 16.7167C6.85821 16.8348 7.01742 16.9008 7.18331 16.9001C7.34938 16.9018 7.50895 16.8356 7.62497 16.7167C7.7441 16.6007 7.81128 16.4414 7.81128 16.2751C7.81128 16.1087 7.7441 15.9495 7.62497 15.8334L6.24997 14.4584H12.1583C14.5515 14.4584 16.4916 12.5183 16.4916 10.1251V8.3334C16.5152 5.98264 14.65 4.04694 12.3 3.9834Z"
                      fill="#475467"
                    />
                  </svg>
                  <p className="gray14">Press Enter</p>
                </div>
              </div>
            </form>
            <div className="d-flex flex-wrap" style={{ gap: "1rem" }}>
              {learns &&
                learns.map((ski, i) => (
                  <div
                    key={i}
                    className=" skillsshow d-flex hoverdarkblacksvg"
                    style={{ cursor: "pointer", marginTop: "0.75rem" }}
                  >
                    <p className="skillsshowtext"> {ski}</p>
                    <div
                      onClick={() => handlelearnRemove(ski)}
                      className="hoverdarkblacksvgdiv"
                    >
                      <CloseOutlinedIcon className="" />
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <hr
          style={{
            marginTop: "5.063rem",
            marginBottom: "1.25rem",
            zIndex: 0,
          }}
        />
        <div
          className="d-flex"
          style={{ justifyContent: "space-between", gap: "10px" }}
        >
          <button className="backbutton" onClick={() => setStep(3)}>
            Back
          </button>
          <button className="continuebutton" onClick={() => handleContinue()}>
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};
export default NewSkillSelect;
