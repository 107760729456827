import React, { useState } from "react";
import OtpInput from "react-otp-input";
import { toast } from "react-toastify";

const Step5 = ({
  EmailAddress,
  optform,
  setOtpForm,
  generateOtp,
  agree,
  setAgree,
}) => {
  return (
    <div>
      <p className="sectionheadingclamped" style={{ marginBottom: "1.5rem" }}>
        Check your email inbox
      </p>
      <p className="sentanemail">
        We sent an email to <span> {EmailAddress && EmailAddress} </span>
        Enter the verification code to complete your registration.
      </p>
      <div className="otp_wrapper" style={{ marginTop: "1.875rem" }}>
        <OtpInput
          value={optform}
          onChange={setOtpForm}
          numInputs={4}
          renderSeparator={<span className="Seperator" />}
          renderInput={(props) => (
            <input {...props} className="OTPInput shadowa" />
          )}
        />
        <p className="didnt" style={{ marginTop: "0.75rem" }}>
          Didnt receive a code?
          <span
            onClick={() => {
              generateOtp();
              toast.success("Otp sent to your email");
            }}
          >
            {" "}
            Resend code
          </span>
        </p>
        <div className="d-flex" style={{ marginTop: "1rem", gap: "0.5rem" }}>
          {/* <div className="d-flex" style={{ justifyContent: "center" }}> */}{" "}
          {agree ? (
            <>
              {" "}
              <svg
                onClick={() => setAgree(!agree)}
                style={{ cursor: "pointer" }}
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z"
                  fill="#E31B54"
                />
              </svg>
            </>
          ) : (
            <svg
              onClick={() => setAgree(!agree)}
              style={{ cursor: "pointer" }}
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.00521 1.66602H15.0052C16.8462 1.66602 18.3385 3.1584 18.3385 4.99935V14.9993C18.3385 16.8403 16.8462 18.3327 15.0052 18.3327H5.00521C3.16426 18.3327 1.67188 16.8403 1.67188 14.9993V4.99935C1.67188 3.1584 3.16426 1.66602 5.00521 1.66602ZM16.4783 16.4725C16.869 16.0818 17.0885 15.5519 17.0885 14.9993V4.99935C17.0885 4.44681 16.869 3.91691 16.4783 3.52621C16.0876 3.13551 15.5577 2.91602 15.0052 2.91602H5.00521C3.85461 2.91602 2.92188 3.84876 2.92188 4.99935V14.9993C2.92188 15.5519 3.14137 16.0818 3.53207 16.4725C3.92277 16.8632 4.45267 17.0827 5.00521 17.0827H15.0052C15.5577 17.0827 16.0876 16.8632 16.4783 16.4725Z"
                fill="#344054"
              />
            </svg>
          )}
          <p className="agreetoour">
            You agree to our{" "}
            <a
              href="/terms"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
            >
              Terms of Use
            </a>{" "}
            and
            <a
              href="/privacy"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
            >
              {" "}
              Privacy Policy
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default Step5;
