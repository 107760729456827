import "./body.css";
import image from "../../assets/images/guideline.png";
import { useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import SelectedBox from "../../assets/images/icons/selectedbox";
import UnselectedBox from "../../assets/images/icons/unselectedBox";
const Guideline = () => {
  const [agree, setAgree] = useState(false);
  const [agree2, setAgree2] = useState(false);
  const uniqueId = useSelector((state) => state.uniqueId);

  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:767px)");
  const handleSubmit = () => {
    if (!agree) {
      toast.error("Please confirm that you agree to Terms and Privacy Policy");
      return;
    }

    navigate("/dashboard/progress");
  };

  useEffect(() => {
    if (!uniqueId) {
      navigate("/company/register");
    }
  }, []);
  return (
    <div>
      <div className="d-flex" style={{ justifyContent: "space-between" }}>
        <div className="row d-flex">
          <p className="registeration">varyance guidelines</p>
          <p
            style={{
              fontSize: "14px",
              fontFamily: "Poppins",
              color: "#1D2939",
              marginTop: "0.5rem",
            }}
          >
            Cultivating a trusted environment for students is paramount at
            varyance.io
          </p>
        </div>
      </div>
      <div
        className={` mt-5 ${isNonMobile ? "d-flex" : ""}`}
        style={{ justifyContent: "space-between" }}
      >
        <div className="col-md-5">
          <div className="imagewrapper  pb-0">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="33"
                viewBox="0 0 32 33"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M13.2428 6.52394C13.6196 5.28178 14.7535 4.44063 16.0289 4.45727C17.3115 4.44414 18.4478 5.29788 18.8151 6.55061L19.6784 9.21727C20.066 10.4514 21.1932 11.2875 22.4645 11.2839H25.1852C26.4864 11.2337 27.664 12.0648 28.0758 13.324C28.4876 14.5832 28.0347 15.9683 26.9641 16.7239L24.7274 18.3906C23.6964 19.1455 23.2627 20.4933 23.6548 21.7239L24.5181 24.3906C24.8148 25.3137 24.6545 26.3262 24.0882 27.1074C23.5218 27.8886 22.6194 28.342 21.6666 28.3239C21.0487 28.3192 20.4485 28.1137 19.9531 27.7373L17.8079 26.0706C16.7791 25.3057 15.3834 25.3057 14.3546 26.0706L12.1048 27.7373C11.6035 28.1485 10.9823 28.3783 10.339 28.3906C9.37923 28.3987 8.47589 27.9293 7.91852 27.1329C7.36116 26.3364 7.2198 25.313 7.53976 24.3906L8.40306 21.7239C8.819 20.497 8.40598 19.1368 7.38279 18.3639L5.14604 16.6973C4.10925 15.9391 3.67307 14.5841 4.06761 13.3471C4.46214 12.1101 5.59666 11.2757 6.87266 11.2839H9.59338C10.8718 11.2839 12.0012 10.4353 12.3795 9.19061L13.2428 6.52394ZM16.7222 7.21727C16.6418 6.89799 16.3519 6.68058 16.0289 6.69727C15.6899 6.68544 15.3942 6.93002 15.3357 7.27061L14.4724 9.93727C13.7863 12.0924 11.8161 13.5514 9.59338 13.5506H6.82034C6.51211 13.5597 6.24244 13.7645 6.14581 14.063C6.04918 14.3615 6.14628 14.6897 6.38868 14.8839L8.62543 16.5506C10.4296 17.8808 11.1854 20.2458 10.4959 22.4039L9.63262 25.0706C9.54783 25.2896 9.58264 25.5379 9.72418 25.7239C9.87098 25.9394 10.1081 26.0725 10.3651 26.0839C10.5221 26.0792 10.6734 26.0231 10.7968 25.9239L13.0074 24.2573C14.8091 22.9229 17.2488 22.9229 19.0505 24.2573L21.2349 25.8439C21.3583 25.9431 21.5096 25.9992 21.6666 26.0039C21.912 25.9877 22.1374 25.8605 22.2814 25.6573C22.4229 25.4713 22.4577 25.2229 22.3729 25.0039L21.5096 22.3373C20.8202 20.1791 21.576 17.8141 23.3801 16.4839L25.6169 14.8306C25.8593 14.6363 25.9564 14.3081 25.8597 14.0096C25.7631 13.7112 25.4934 13.5063 25.1852 13.4973H22.4645C20.2417 13.4981 18.2716 12.039 17.5855 9.88394L16.7222 7.21727Z"
                  fill="#E31B54"
                />
              </svg>
            </div>
            <p className="HeadingFont mt-4">Our values</p>
            <p className="mt-2 smalltext">
              varyance.io offers unique opportunities to engage students. To
              ensure a positive experience for all participants and maintain the
              integrity of our platform, we hope you share our values.
            </p>
            <img src={image} alt="guideline image" className="img-fluid" />
          </div>
        </div>
        <div className={`col-md-6 ${isNonMobile ? "" : "mt-5"}`}>
          <p className="Text-1">
            Students around the world place their trust in varyance.io and the
            companies on our platform. To uphold this trust, all employers on
            varyance.io must adhere to the following general guidelines,
            alongside our Terms of Use :
          </p>
          <p className="Text-4 " style={{ marginTop: "2rem" }}>
            <span className="Text-3">Be Accurate and Transparent:</span> Tell
            the truth about your company, your team and the jobs available.
            Maintain open and honest communication with students regarding job
            opportunities, expectations, and application processes.
          </p>
          <p className="Text-4 " style={{ marginTop: "2rem" }}>
            <span className="Text-3">Be Fair:</span> Offer fair and inclusive
            employment opportunities to all qualified candidates, regardless of
            race, gender, religion, sexual orientation, or any other protected
            characteristic.
          </p>
          <p className="Text-4 mt-3" style={{ marginTop: "2rem" }}>
            <span className="Text-3">Be Authentic:</span> Design challenges that
            are relevant to the industry or field of your company and reflect
            your company culture. Ensure that the challenges align with the
            skills and competencies you are seeking in potential candidates.
          </p>
          <p className="Text-4 mt-3" style={{ marginTop: "2rem" }}>
            <span className="Text-3">Keep Student Info Confidential:</span>{" "}
            Safeguard the privacy and confidentiality of student information
            obtained in your talent pool. Do not disclose any personal
            information without the prior consent of a student.
          </p>
        </div>
      </div>
      <div className="row " style={{ marginTop: "3.75rem" }}>
        <div className=" d-flex" style={{ alignItems: "center" }}>
          <div
            className="d-flex align-items-center"
            style={{ marginRight: "10px", cursor: "pointer" }}
            onClick={() => setAgree(!agree)}
          >
            {agree ? <SelectedBox /> : <UnselectedBox />}
          </div>

          <p className="Text-4" style={{ fontSize: "14px" }}>
            I agree to the{" "}
            <span
              className="Text-5 hoverdarkred"
              style={{
                fontSize: "14px",
                cursor: "pointer",
                textDecoration: "none",
              }}
              onClick={() => {
                window.open("/terms", "_blank");
              }}
            >
              Terms of Use
            </span>{" "}
            and{" "}
            <span
              className="Text-5 hoverdarkred"
              style={{
                fontSize: "14px",
                cursor: "pointer",
                textDecoration: "none",
              }}
              onClick={() => {
                window.open("/privacy", "_blank");
              }}
            >
              Privacy Policy
            </span>
            . *
          </p>
        </div>
        <div
          className="mt-3 d-flex align-items-center"
          style={{ gap: "0.75rem" }}
        >
          <div
            className="d-flex align-items-center"
            style={{ cursor: "pointer" }}
            onClick={() => setAgree2(!agree2)}
          >
            {agree2 ? <SelectedBox /> : <UnselectedBox />}
          </div>
          <div className="">
            <p className="Text-4" style={{ fontSize: "14px" }}>
              I agree to receive messages about product updates and new features
              from varyance.
            </p>
          </div>
        </div>
        <div>
          <button
            className="continuebutton mt-5 mb-5"
            onClick={() => handleSubmit()}
          >
            Save and continue
          </button>
        </div>
      </div>
    </div>
  );
};
export default Guideline;
