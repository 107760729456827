// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.whitechip {
  border-radius: 30px;
  border: 1px solid var(--Gray-200, #eaecf0);
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0.2) 100%
    ),
    var(--Base-White, #fff);
  display: flex;
  padding: 8px 14px;
  align-items: center;
  gap: 6px;
  color: var(--Gray-950, #0c111d);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
}
`, "",{"version":3,"sources":["webpack://./src/pages/CreatEvent/main.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,0CAA0C;EAC1C;;;;;2BAKyB;EACzB,aAAa;EACb,iBAAiB;EACjB,mBAAmB;EACnB,QAAQ;EACR,+BAA+B;EAC/B,oBAAoB;EACpB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,aAAa;AAClC","sourcesContent":[".whitechip {\n  border-radius: 30px;\n  border: 1px solid var(--Gray-200, #eaecf0);\n  background: linear-gradient(\n      0deg,\n      rgba(255, 255, 255, 0.2) 0%,\n      rgba(255, 255, 255, 0.2) 100%\n    ),\n    var(--Base-White, #fff);\n  display: flex;\n  padding: 8px 14px;\n  align-items: center;\n  gap: 6px;\n  color: var(--Gray-950, #0c111d);\n  font-family: Poppins;\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 16px; /* 133.333% */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
