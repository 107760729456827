import "./recommended.css";
import Card from "./Card";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const Recomended = ({ challenges, shortListed, handleShortList, navigate }) => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1440 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 1440, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 550, min: 0 },
      items: 1,
    },
  };

  return (
    <div>
      <div style={{ margin: "0rem 1.875rem 0rem 1.875rem" }}>
        <div className="space gap-3 flex-wrap">
          <div>
            <p className="black20">Recommended</p>
            <p
              style={{
                marginTop: "0.75rem",
                maxWidth: "20.563rem",
                fontWeight: "400",
              }}
              className="black14"
            >
              Here you can find talent which boast high engagement-levels with
              your company.
            </p>
          </div>
          <div className="d-flex align-items-center">
            <button
              className="continuebutton"
              style={{ maxHeight: "42px" }}
              onClick={() => {
                navigate("/dashboard/TalentPool");
              }}
            >
              View all
            </button>
          </div>
        </div>
      </div>
      <div
        style={
          {
            //   padding: "1.875rem 1.875rem 0rem 1.875rem",
          }
        }
      >
        {challenges && challenges.length > 0 && (
          <Carousel
            responsive={responsive}
            className="pb-5"
            showDots={true}
            swipeable={true}
            arrows={false}
            dotListClass="custom-dot-list-style"
            renderDotsOutside={false}
            customDot={<CustomDot />}
          >
            {challenges &&
              challenges.length > 0 &&
              challenges.map((chal, index) => (
                <div
                  key={index}
                  style={{
                    margin: "1.875rem 0rem 0rem 1.857rem",
                  }}
                >
                  <Card
                    challenge={chal}
                    shortListed={shortListed}
                    handleShortList={handleShortList}
                  />
                </div>
              ))}
          </Carousel>
        )}
      </div>
    </div>
  );
};

const CustomDot = ({ onClick, ...rest }) => {
  const { index, active } = rest;
  return (
    <li
      className={`custom-dot ${active ? "activecustombutton" : ""}`}
      key={index}
      onClick={() => onClick()}
    >
      <button></button>
    </li>
  );
};

export default Recomended;
