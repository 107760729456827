const LastSection = ({ isNonMob, navigate }) => {
  return (
    <>
      <div className="d-flex" style={{ justifyContent: "center" }}>
        <div
          className="lastSection headerCompany d-flex"
          style={{
            justifyContent: isNonMob ? "space-between" : "center",
            flexDirection: isNonMob ? "row" : "column",
          }}
        >
          <p className={`startjourney ${isNonMob && "section"}`}>
            Start your journey today
            <br />
            and explore your future career.
          </p>
          <div
            className="d-flex flex-wrap"
            style={{
              alignItems: "center",
              gap: "1rem",
              marginTop: isNonMob ? "" : "1rem",
            }}
          >
            <button
              className={`backbutton `}
              onClick={() => {
                navigate("/login");
              }}
            >
              Login
            </button>
            <button
              className={`continuebutton  
              `}
              onClick={() => {
                navigate("/register");
              }}
            >
              Register
            </button>
          </div>
        </div>
      </div>
      <hr className="horiline " />
    </>
  );
};
export default LastSection;
