import { useSelector } from "react-redux";
import SelectedEvent from "../SelectedEvent";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

const SelectedView = () => {
  const selectedEvent = useSelector((state) => state.selectedEvent);
  const navigate = useNavigate();

  const isNonMobile1023 = useMediaQuery("(min-width:1023px)");

  useEffect(() => {
    if (!selectedEvent) {
      navigate(-1);
    }
  }, []);

  function getDayOfWeek(datetime) {
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const date = new Date(datetime);
    return days[date.getDay()]; // getDay() returns an index from 0 to 6
  }

  // Function to return the day of the month (e.g., 16, 25, 28)
  function getDayOfMonth(datetime) {
    const date = new Date(datetime);
    return date.getDate(); // getDate() returns the day of the month
  }

  function isWithinNextThreeDays(eventDatetime) {
    const eventDate = new Date(eventDatetime);
    const now = new Date();

    // Get the difference in time
    const timeDiff = eventDate.getTime() - now.getTime();

    // Convert time difference from milliseconds to days
    const dayDiff = timeDiff / (1000 * 3600 * 24);

    return dayDiff >= 0 && dayDiff <= 3;
  }

  function getTimeRange(datetime, duration) {
    const startTime = new Date(datetime);

    // Parse the duration and convert to minutes
    let durationMinutes = 0;
    if (duration === "30 mins") {
      durationMinutes = 30;
    } else if (duration === "1 hour") {
      durationMinutes = 60;
    } else if (duration === "1:30 hour") {
      durationMinutes = 90;
    } else if (duration.includes("hour")) {
      const [hours, mins] = duration.split(":");
      durationMinutes = parseInt(hours) * 60 + parseInt(mins) || 0;
    }

    // Calculate end time by adding duration to start time
    const endTime = new Date(startTime.getTime() + durationMinutes * 60000);

    // Format times as HH.MM
    const formatTime = (date) => {
      const hours = date.getHours();
      const minutes = date.getMinutes() === 0 ? ":00" : date.getMinutes();
      return `${hours}.${minutes}`;
    };

    return `${formatTime(startTime)}-${formatTime(endTime)}`;
  }
  return (
    <div className="paddingmaincontent mb-5 pb-3">
      <SelectedEvent
        selectedEvent={selectedEvent}
        getDayOfWeek={getDayOfWeek}
        getDayOfMonth={getDayOfMonth}
        isWithinNextThreeDays={isWithinNextThreeDays}
        getTimeRange={getTimeRange}
        isNonMobile1023={isNonMobile1023}
      />
    </div>
  );
};
export default SelectedView;
