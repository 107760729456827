import { useState } from "react";
import logo from "../../assets/images/logo.png";

const Header = ({}) => {
  const [buttons, setButtons] = useState([
    { text: "Students", link: "/" },
    { text: "Employer-Hub", link: "/" },
    { text: "Career Centers", link: "/" },
  ]);
  const [selected, setSelected] = useState("Employer-Hub");
  return (
    <div
      style={{
        borderBottom: "1px solid #EAECF0",
        background: "#FFF",
        zIndex: 2,
        position: "relative",
      }}
    >
      <div className="marginsleftrightCompany">
        <div className="center">
          <div
            className="headerCompany space align-items-center"
            style={{ padding: "0.5rem 0rem" }}
          >
            <a href="/" style={{ textDecoration: "none" }}>
              <img
                src={logo}
                alt="logo"
                height={"36px"}
                width={"36px"}
                className="rounded-1"
                style={{ cursor: "pointer" }}
              />
            </a>

            <div
              className="d-flex align-items-center"
              style={{ width: "60%", gap: "2rem" }}
            >
              {buttons.map((item, index) => (
                <a
                  href={item.link}
                  key={index}
                  style={{ textDecoration: "none" }}
                >
                  <p
                    className={`${
                      selected === item.text ? "gray95014400" : "gray50012500"
                    }`}
                    style={{ fontSize: item.text === selected ? "" : "14px" }}
                  >
                    {item.text}
                  </p>
                </a>
              ))}
            </div>
            <div className="d-flex align-items-center" style={{ gap: "1rem" }}>
              <a href="/" style={{ textDecoration: "none" }}>
                <p className="continuebutton" style={{ maxHeight: "36px" }}>
                  Let's Talk
                </p>
              </a>
              <a href="/" style={{ textDecoration: "none" }}>
                <p className="backbutton" style={{ maxHeight: "36px" }}>
                  Sign Up (free)
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
