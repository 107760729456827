import logo from "../../assets/images/logo.png";
import "./preview.css";
import { useMediaQuery } from "@mui/material";
import time from "../../assets/images/icons/time.png";
import videoicon from "../../assets/images/icons/videoicon.png";
import ReactPlayer from "react-player";
import image from "../../assets/images/guideline.png";
import linkedin from "../../assets/images/icons/linkedin.png";
import Footer from "../../components/Company/Footer/footer";
import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import PreviewHeader from "./previewHeader";
import FirstSection from "./PreviewPage/FirstSection";
import SecondSection from "./PreviewPage/SecondSection/SecondSection";
import ThirdSection from "./PreviewPage/ThirdSection/ThirdSection";
import FourthSection from "./PreviewPage/FourthSection/FourthSection";
import FifthSection from "./PreviewPage/FifthSection/FifthSection";
import NewSection from "./PreviewPage/NewSection/NewSection";
import NewFirstSection from "./PreviewPage/NewFirstSection";
import NewSecondSection from "./PreviewPage/SecondSection/NewSecondSection";
import TaskSection from "./PreviewPage/Tasksection/TaskSection";
import PreviewWhole from "./PreviewWhole/PreviewWholeChallenge";
const PreviewPage = ({
  step,
  setStep,
  selectedFile,
  skills,
  tester,
  learns,
  title,
  description,
  selectedItem,
  selectedLanguage,
  defaults,
  type,
  duration,
  difficulty,
  setTaskSteps,
  tools,
  selectedVideo,
  company,
  information,
  studentInfo,
  setShowPreviewa,
  newTasks,
}) => {
  const isNonMob1024 = useMediaQuery("(min-width:1024px)");

  const check = true;
  const navigate = useNavigate();
  const email = useSelector((state) => state.email);
  // const user = useSelector((state) => state.user);
  const [user, setUser] = useState("");
  const [color, setColor] = useState("#E31b54");
  const [preview, setPreview] = useState(false);

  useEffect(() => {
    if (company?.color) {
      setColor(company?.color);
    }
  }, [company]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let id;
        const userInfoResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/getUserInfo`,
          { email: email }
        );

        if (userInfoResponse.data.message === "Found") {
          setUser(userInfoResponse.data.user);
          id = userInfoResponse.data.user.company;
        } else {
          navigate("/register/organiser");
          return; // Exit early if user not found
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [email]);

  return (
    <>
      {preview ? (
        <PreviewWhole
          company={company}
          newTasks={newTasks}
          title={title}
          setPreview={setPreview}
          color={color}
        />
      ) : (
        <div style={{ position: "relative" }}>
          {/* <PreviewHeader /> */}
          {check && (
            <PreviewHeader
              step={step}
              setShowPreviewa={setShowPreviewa}
              setStep={setStep}
              setTaskSteps={setTaskSteps}
            />
          )}

          <NewFirstSection
            setTaskSteps={setTaskSteps}
            selectedFile={selectedFile}
            company={company}
            type={type}
            title={title}
            description={description}
            step={step}
            setStep={setStep}
            color={color}
            check={check}
            tester={tester}
            no={true}
            setShowPreviewa={setShowPreviewa}
            setPreview={setPreview}
          />

          {/* <FirstSection
          check={check}
          setTaskSteps={setTaskSteps}
          selectedFile={selectedFile}
          company={company}
          type={type}
          title={title}
          description={description}
          step={step}
          no={true}
          setStep={setStep}
          color={color}
          tester={tester}
          setShowPreviewa={setShowPreviewa}
        /> */}
          <div style={{ background: "#FFF" }}>
            <ThirdSection
              selectedVideo={selectedVideo}
              user={user}
              type={type}
              company={company}
              learns={learns}
              color={color}
            />
          </div>
          <div>
            <NewSecondSection
              difficulty={difficulty}
              title={title}
              information={information}
              tools={tools}
              studentInfo={studentInfo}
              company={company}
              newTasks={newTasks}
              color={color}
            />
            <div
              className="marginsleftrightCompany"
              id="skills"
              style={{ padding: "4rem 0rem" }}
            >
              <div className="d-flex" style={{ justifyContent: "center" }}>
                <div
                  className={`headerCompany`}
                  style={{ padding: "3.75rem 0rem" }}
                >
                  <div
                    style={
                      {
                        // paddingTop: isNonMob1024 ? "10rem" : "5rem",
                        // marginBottom: "6.875rem",
                      }
                    }
                  >
                    <p
                      className="black16 text-start"
                      style={{
                        color: color,
                      }}
                    >
                      Skills you will learn
                    </p>
                    <div
                      className="space "
                      style={{
                        gap: isNonMob1024 ? "2.875rem" : "1rem",
                        // marginTop: "8.125rem",
                        flexDirection: isNonMob1024 ? "row" : "column",
                      }}
                    >
                      <div style={{ width: isNonMob1024 ? "36.3%" : "100%" }}>
                        <div>
                          <p
                            className="sectionSectiontitle"
                            style={{ wordBreak: "keep-all" }}
                          >
                            Advance skills for your future career
                          </p>
                          <p
                            className="welcometo"
                            style={{ marginTop: "0.75rem" }}
                          >
                            Learn, apply and expand conceptual and technical
                            skills in this challenge relevant to your career
                            start.
                          </p>
                        </div>
                      </div>

                      <div style={{ width: isNonMob1024 ? "59%" : "100%" }}>
                        <div
                          className="d-flex flex-wrap"
                          style={{ gap: "0.875rem 1rem" }}
                        >
                          {learns &&
                            learns.map((ski, i) => (
                              <div
                                key={i}
                                className=" skillsshow d-flex"
                                style={{ cursor: "pointer" }}
                              >
                                <p className="skillsshowtext"> {ski}</p>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <SecondSection
          difficulty={difficulty}
          title={title}
          information={information}
          tools={tools}
          studentInfo={studentInfo}
          company={company}
          color={color}
        /> */}
          </div>

          <div className="newtasksbackground">
            <TaskSection
              newTasks={newTasks}
              color={color}
              check={check}
              tester={tester}
              no={true}
              setPreview={setPreview}
            />
          </div>
          <div>
            <div style={{ maxWidth: "100vw" }}>
              <FourthSection
                type={type}
                difficulty={difficulty}
                totalSkills={skills.length}
                totalLearns={learns.length}
              />
            </div>
          </div>
          <div>
            <FifthSection
              company={company}
              type={type}
              no={true}
              color={color}
              setPreview={setPreview}
            />
          </div>
        </div>
      )}
    </>
  );
};
export default PreviewPage;
