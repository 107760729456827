import "./cards.css";
import pentagon from "../../assets/images/pentagon.svg";
import earth from "../../assets/images/Earth.svg";
import square from "../../assets/images/square.svg";
import users from "../../assets/images/users.svg";

const Cards = ({ navigate, isNonMob1440, isNonMob1024 }) => {
  const cards = [
    {
      icon: pentagon,

      icontext: "Engage",
      heading: "Create a Challenge",
      description:
        "Accelerate your employer brand by designing real-world challenges",
      button: "Create Now",
      function: () => {
        navigate("/create/challenge");
      },
    },
    {
      icon: earth,

      icontext: "Attract",
      heading: "Post a Job",
      description:
        "Publish relevant internships and job posts to build your talent pool.",
      button: "Post Now",
      function: () => {
        navigate("/create/job");
      },
    },
    {
      icon: square,

      icontext: "Hire",
      heading: "Access Talent Pool",
      description:
        "Build your talent pool of curious and motivated students globally.",
      button: "Access Now",
      function: () => {
        navigate("/dashboard/TalentPool");
      },
    },
    isNonMob1440 && {
      icon: users,

      icontext: "Organize",
      heading: "Invite Team",
      description:
        "Get your hiring team organized so that everyone stays in the loop",
      button: "Invite Now",
      function: () => {
        navigate("/dashboard/Team");
      },
    },
  ];

  return (
    <div
      className={`${isNonMob1024 ? "d-flex" : "d-grid"} `}
      style={{
        gap: "1.125rem",
        gridTemplateColumns: isNonMob1024
          ? `repeat(auto-fill, minmax(${
              isNonMob1440 ? "320px" : "271px"
            }, 1fr))`
          : "",
      }}
    >
      {cards.map(
        (card, index) =>
          card?.heading && (
            <div className="maindashboardtopcards " key={index}>
              <div className="innermaindashboardcarddiv">
                <img
                  src={card.icon}
                  alt="card icon"
                  width="20px"
                  height="20px"
                />
                <p
                  className="black10 text-start hiverpink"
                  style={{
                    marginTop: "0.75rem",
                    fontSize: "10px",
                    lineHeight: "10px",
                  }}
                >
                  {card.icontext}
                </p>
                <p
                  className="hiverpink"
                  style={{ fontSize: "18px", marginTop: "0.75rem" }}
                >
                  {card.heading}
                </p>
                <p className="black12" style={{ marginTop: "0.5rem" }}>
                  {card.description}
                </p>
                <button
                  className="continuebutton"
                  style={{ marginTop: "1rem" }}
                  onClick={card.function}
                >
                  {card.button}
                </button>
              </div>
            </div>
          )
      )}
    </div>
  );
};
export default Cards;
