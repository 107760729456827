import { useEffect, useState } from "react";
import "./newfirstSection.css";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
const NewFirstSection = ({
  selectedFile,
  company,
  description,
  type,
  StartChallenge,
  title,

  test,
  Userloggedin,
  uniqueId,
  checker,
  setPreview,
  no,
  color,
}) => {
  const navigate = useNavigate();
  const isNonMobile1024 = useMediaQuery("(min-width:1024px)");
  const isNonMobile = useMediaQuery("(min-width: 768px)");
  const [selected, setSelected] = useState("overview");
  const generateRandomNumber = () => {
    return Math.floor(Math.random() * 20) + 1;
  };

  const hexToRgba = (hex, alpha) => {
    let r = 0,
      g = 0,
      b = 0;
    // 3 digits
    if (hex.length === 4) {
      r = parseInt(hex[1] + hex[1], 16);
      g = parseInt(hex[2] + hex[2], 16);
      b = parseInt(hex[3] + hex[3], 16);
    }
    // 6 digits
    else if (hex.length === 7) {
      r = parseInt(hex[1] + hex[2], 16);
      g = parseInt(hex[3] + hex[4], 16);
      b = parseInt(hex[5] + hex[6], 16);
    }
    return `rgba(${r},${g},${b},${alpha})`;
  };

  const [randomNumbers, setRandomNumbers] = useState([]);

  useEffect(() => {
    const numbers = new Set();
    while (numbers.size < 5) {
      numbers.add(generateRandomNumber());
    }
    setRandomNumbers(Array.from(numbers));
  }, []);

  return (
    <>
      <div className="firstSectionbackground">
        <div className="marginsleftrightCompany">
          <div className="d-flex" style={{ justifyContent: "center" }}>
            <div className="headerCompany ">
              <div className=" " style={{ marginTop: "3.75rem" }}>
                {company && company.img && (
                  <img
                    src={company.img}
                    alt="company logo"
                    className="img-fluid rounded-2 shadowa"
                    style={{
                      maxHeight: "4.5rem",
                      maxWidth: "4.5rem",
                      background: "#FFF",
                    }}
                  />
                )}
              </div>

              <div
                className={`${isNonMobile1024 ? "space" : ""}`}
                style={{ marginTop: "20px" }}
              >
                <div style={{ width: isNonMobile1024 ? "50%" : "" }}>
                  <p
                    className={`${
                      isNonMobile1024 ? "g95040048" : "registeration"
                    }`}
                    style={{ marginTop: "1rem" }}
                  >
                    {title && title}
                  </p>
                  <div
                    className="d-flex flex-wrap"
                    style={{ marginTop: "1.5rem", gap: "1rem" }}
                  >
                    {" "}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative",
                        height: "40px",
                        width: "120px",
                      }}
                    >
                      {randomNumbers.map((ran, index) => (
                        <img
                          key={index}
                          src={require(`../../../assets/images/users/${ran}.png`)}
                          alt="img"
                          className="shadowa rounded-5"
                          style={{
                            position: "absolute",
                            left: `${index * 20}px`, // Adjust 20px according to half of the image width (40px / 2)
                            zIndex:
                              index === 0
                                ? 5
                                : index === 1
                                ? 4
                                : index === 2
                                ? 3
                                : index === 3
                                ? 2
                                : index === 4
                                ? 1
                                : 0,
                          }}
                          height={"40px"}
                          width={"40px"}
                        />
                      ))}
                    </div>
                    <div>
                      {" "}
                      <button
                        className="continuebutton"
                        style={{
                          maxHeight: "40px",
                          border: "none",
                          background: color,
                        }}
                        onClick={() => {
                          if (no) {
                            setPreview(true);
                          } else {
                            if (Userloggedin && !checker) {
                              StartChallenge();
                            } else if (Userloggedin && checker) {
                              navigate("/dashboard/Challenges");
                            } else {
                              navigate("/login");
                            }
                          }
                        }}
                      >
                        {test
                          ? Userloggedin
                            ? "Start Challenge"
                            : "Signin to start"
                          : "Start Challenge"}
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: isNonMobile1024 ? "47%" : "",
                    marginTop: !isNonMobile1024 && "2.5rem",
                  }}
                >
                  <div
                    className="challengetypediv"
                    style={{
                      background: color
                        ? hexToRgba(color, 0.06)
                        : "rgba(227, 27, 84, 0.06)",
                    }}
                  >
                    <p
                      className="challengetypetextnew"
                      style={{ color: color ? color : "#E31b54" }}
                    >
                      {type} <span>Challenge</span>
                    </p>
                  </div>
                  <div style={{ marginTop: "0.75rem" }}>
                    <p className="g80040016 text-start">
                      {description && description}
                    </p>
                  </div>
                </div>
              </div>
              <div style={{ marginTop: "2.5rem" }}>
                <img
                  className="img-fluid"
                  src={
                    selectedFile instanceof File
                      ? URL.createObjectURL(selectedFile)
                      : selectedFile.includes("http")
                      ? selectedFile
                      : `${process.env.REACT_APP_BACKEND_URL}${selectedFile}`
                  }
                  alt="image"
                  width={"100%"}
                  style={{
                    // maxHeight: "500px",
                    borderRadius: "12px 12px 0px 0px",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="center secondSectionTopDiv"
        style={{
          position: "sticky",
          top: 0,
          zIndex: 50, // Ensure it stays above other content
          backgroundColor: "white", // Optional: To see if it's being covered
        }}
      >
        <div className="marginsleftrightCompany w-100">
          <div className="center">
            <div className="headerCompany">
              <div className="space w-100">
                <img
                  src={company?.img}
                  alt="company img"
                  className=" shadowa rounded-2"
                  height="36px"
                  width={"36px"}
                  style={{ borderRadius: "4.5px", padding: "0.25rem" }}
                />
                {isNonMobile && (
                  <div
                    className="d-flex border border-1 rounded-3"
                    style={{ padding: "0.25px", alignItems: "center" }}
                  >
                    <a
                      href="#overview"
                      style={{ textDecoration: "none", color: "inherit" }}
                      onClick={() => {
                        setSelected("overview");
                      }}
                    >
                      <button
                        className="btn gray14 "
                        style={{
                          fontWeight: "500",
                          width: isNonMobile1024 ? "180px" : "",
                          color: selected === "overview" ? color : "",
                          background: selected === "overview" ? "#F2F4F7" : "",
                        }}
                      >
                        Overview
                      </button>
                    </a>
                    <a
                      href="#skills"
                      style={{ textDecoration: "none", color: "inherit" }}
                      onClick={() => {
                        setSelected("skills");
                      }}
                    >
                      <button
                        className="btn gray14 "
                        style={{
                          fontWeight: "500",
                          width: isNonMobile1024 ? "180px" : "",
                          color: selected === "skills" ? color : "",
                          background: selected === "skills" ? "#F2F4F7" : "",
                        }}
                      >
                        Skills
                      </button>
                    </a>
                    <a
                      href="#tasks"
                      style={{ textDecoration: "none", color: "inherit" }}
                      onClick={() => {
                        setSelected("tasks");
                      }}
                    >
                      {" "}
                      <button
                        className="btn gray14 "
                        style={{
                          fontWeight: "500",
                          width: isNonMobile1024 ? "180px" : "",
                          color: selected === "tasks" ? color : "",
                          background: selected === "tasks" ? "#F2F4F7" : "",
                        }}
                      >
                        Your Tasks
                      </button>
                    </a>
                  </div>
                )}
                <button
                  className="continuebutton"
                  style={{
                    maxHeight: "40px",
                    border: "none",
                    background: color,
                  }}
                  onClick={() => {
                    if (no) {
                      setPreview(true);
                    } else {
                      if (Userloggedin && !checker) {
                        StartChallenge();
                      } else if (Userloggedin && checker) {
                        navigate("/dashboard/Challenges");
                      } else {
                        navigate("/login");
                      }
                    }
                  }}
                >
                  {test
                    ? Userloggedin
                      ? "Start Challenge"
                      : "Signin to start"
                    : "Start Challenge"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default NewFirstSection;
