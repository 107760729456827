import React from "react";
import SwiperEx from "./Swiper";

const Third = ({ company }) => {
  return (
    <div
      className="CompanyPage-First-First thisone"
      style={{ marginTop: "2.5rem", borderRadius: "12px", padding: "0rem" }}
    >
      <p
        className="text-center gray95016500"
        style={{
          color: company?.color ? company?.color : "#E31B54",
          paddingTop: "2.5rem",
        }}
      >
        Gallery
      </p>
      <p style={{ marginTop: "0.75rem" }} className="text-center gray95024400">
        Build the career you <br /> never imagined.
      </p>
      <SwiperEx company={company} />
    </div>
  );
};

export default Third;
