import { useSelector } from "react-redux";

import "./test.css";
import Left from "./left";
import TestHeader from "./header";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import Step4 from "./step4";
import Document from "./Step";
import Terms from "./Terms";
import Tasks from "./tasks";
import axios from "axios";
import Result from "./Results";
const Attempt = () => {
  // const preview = useSelector((state) => state.preview);
  const [steps, setSteps] = useState(1);
  const navigate = useNavigate();
  const [task, setTask] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [show, setShow] = useState([]);
  const user = useSelector((state) => state.user);
  const [results, setResults] = useState("");
  const { uniqueId } = useParams();
  const [preview, setPreview] = useState(null);
  const [id, setId] = useState("");
  useEffect(() => {
    if (!user) {
      navigate(-1);
    }
  }, []);
  useEffect(() => {
    if (uniqueId) {
      axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/get/specific/challenge`, {
          uniqueId,
          id: user ? user._id : null,
        })
        .then((res) => {
          if (res.data.msg == "success") {
            console.log(res.data);
            setPreview(res.data.challenge);
          } else {
            navigate("/student/dashboard/challenges");
            console.log(res.data);
          }
        })
        .catch((err) => console.log(err));
    } else {
      navigate("/student/dashboard/challenges");
    }
  }, []);

  useEffect(() => {
    if (!uniqueId) {
      navigate("/student/dashboard");
    }
  }, []);

  const addtask = () => {
    if (preview.alltask.length > task + 1) {
      setTask(task + 1);
      setSteps(1);
    } else {
      setSteps(7);
      axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/Test/Results`, {
          preview,
          user,
          selectedAnswers,
        })
        .then((res) => {
          if (res.data.msg === "DONE") {
            setId(res.data.uniqueId);
          }
        });
      setTask(task + 1);
    }
  };

  return (
    <div
      style={{ background: "#F6F7FB", minHeight: "100vh", minWidth: "100vw" }}
    >
      {preview && preview.alltask && preview.alltask.length > 0 && (
        <TestHeader
          tasks={preview.alltask[task]}
          task={task}
          preview={preview}
          steps={steps}
        />
      )}

      <div className="row">
        {" "}
        {preview && (
          <>
            <div className="col-md-9 pt-4 ">
              {steps === 1 &&
                preview &&
                preview.alltask &&
                preview.alltask.length > 0 && (
                  <div className="mt-4">
                    <Step1
                      task={preview.alltask[task]}
                      setSteps={setSteps}
                      tasks={task}
                      preview={preview}
                    />
                  </div>
                )}
              {steps === 2 &&
                preview &&
                preview.alltask &&
                preview.alltask.length > 0 && (
                  <div className="mt-4">
                    <Step2
                      task={preview.alltask[task]}
                      setSteps={setSteps}
                      tasks={task}
                      preview={preview}
                    />
                  </div>
                )}
              {steps === 3 &&
                preview &&
                preview.alltask &&
                preview.alltask.length > 0 && (
                  <div className="mt-4">
                    <Terms
                      task={preview.alltask[task]}
                      setSteps={setSteps}
                      tasks={task}
                      preview={preview}
                    />
                  </div>
                )}
              {steps === 4 &&
                preview &&
                preview.alltask &&
                preview.alltask.length > 0 && (
                  <div className="mt-4">
                    <Tasks
                      task={preview.alltask[task]}
                      setSteps={setSteps}
                      tasks={task}
                      preview={preview}
                    />
                  </div>
                )}
              {/* {steps === 5 &&
                preview &&
                preview.alltask &&
                preview.alltask.length > 0 && (
                  <div className="mt-4">
                    <Document
                      task={preview.alltask[task]}
                      setSteps={setSteps}
                      tasks={task}
                      preview={preview}
                    />
                  </div>
                )} */}
              {steps === 5 &&
                preview &&
                preview.alltask &&
                preview.alltask.length > 0 && (
                  <div className="mt-4">
                    <Step3
                      task={preview.alltask[task]}
                      setSteps={setSteps}
                      addtask={addtask}
                      tasks={task}
                      preview={preview}
                      selectedAnswers={selectedAnswers}
                      setSelectedAnswers={setSelectedAnswers}
                      show={show}
                      setShow={setShow}
                    />
                  </div>
                )}
              {steps === 7 &&
                preview &&
                preview.alltask &&
                preview.alltask.length > 0 && (
                  <div className="mt-4">
                    <Step4
                      id={id}
                      task={preview.alltask[task]}
                      setSteps={setSteps}
                      addtask={addtask}
                    />
                  </div>
                )}
            </div>
            {steps !== 8 && preview && (
              <Left preview={preview} task={task} steps={steps} />
            )}
          </>
        )}
      </div>
    </div>
  );
};
export default Attempt;
