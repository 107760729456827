import "./main.css";
import logo from "../../../assets/images/logo.png";

import France from "../../../assets/images/countries/France.png";
import Switzerland from "../../../assets/images/countries/Switzerland.png";
import Germany from "../../../assets/images/countries/Germany.png";
import UnitedKingdom from "../../../assets/images/countries/United Kingdom.png";
import Estonia from "../../../assets/images/countries/Estonia.png";

import Romania from "../../../assets/images/countries/Romania.png";
import Finland from "../../../assets/images/countries/Finland.png";
import Georgia from "../../../assets/images/countries/Georgia.png";
import Greece from "../../../assets/images/countries/Greece.png";
import Hungary from "../../../assets/images/countries/Hungary.png";

import Ireland from "../../../assets/images/countries/Ireland.png";
import Italy from "../../../assets/images/countries/Italy.png";

import Jordan from "../../../assets/images/countries/Jordan.png";

import Latvia from "../../../assets/images/countries/Latvia.png";
import Liechtenstein from "../../../assets/images/countries/Liechtenstein.png";
import Lithuania from "../../../assets/images/countries/Lithuania.png";
import Luxembourg from "../../../assets/images/countries/Luxembourg.png";
import Malta from "../../../assets/images/countries/Malta.png";
import Moldova from "../../../assets/images/countries/Moldova.png";
import Netherlands from "../../../assets/images/countries/Netherlands.png";
import NewZealand from "../../../assets/images/countries/New Zealand.png";

import NorthMacedonia from "../../../assets/images/countries/North Macedonia.png";
import Norway from "../../../assets/images/countries/Norway.png";

import Poland from "../../../assets/images/countries/Poland.png";
import Portugal from "../../../assets/images/countries/Portugal.png";
import Qatar from "../../../assets/images/countries/Qatar.png";

import Singapore from "../../../assets/images/countries/Singapore.png";
import Slovakia from "../../../assets/images/countries/Slovakia.png";
import Slovenia from "../../../assets/images/countries/Slovenia.png";

import Spain from "../../../assets/images/countries/Spain.png";
import Sweden from "../../../assets/images/countries/Sweden.png";

import Turkey from "../../../assets/images/countries/Turkey.png";

import Steps from "./Steps/Steps";
import { useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";
import { motion, useAnimate } from "framer-motion";
import { useSelector, useDispatch } from "react-redux";
import emailjs from "@emailjs/browser";
import axios from "axios";
import NameCard from "../../Card/Namecard";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { setLogin } from "../../../state";

const StudentReg = () => {
  const isNonMobile1024 = useMediaQuery("(min-width: 1024px)");
  const isNonMobile450 = useMediaQuery("(min-width: 450px)");
  const isNonMobile768 = useMediaQuery("(min-width: 768px)");
  const location = useLocation();
  const { selectedItems } = location.state || {};
  const [selectedItem, setSelectedItem] = useState(
    (selectedItems && selectedItems?.length > 0 && selectedItems) || []
  );

  const [selectedUni, setSelectedUni] = useState();
  const [firstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [optform, setOtpForm] = useState("");

  const queryParams = new URLSearchParams(location.search);
  const otherFlag = queryParams.get("other");

  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [CustomUni, setCustomUni] = useState("");
  const [unilist, setUniList] = useState([]);
  const [EmailAddress, setEmailAddress] = useState("");
  const Uni = useSelector((state) => state.Uni);
  const [step, setStep] = useState(Uni ? 3 : otherFlag ? 2 : 1);

  const [loading, setLoading] = useState(false);
  let lastStep = 8;
  let width = Math.floor(step * (100 / lastStep));
  const [country, setCountry] = useState("");
  const [loader, setLoader] = useState(false);

  const [scope, animate] = useAnimate();

  const [agree, setAgree] = useState(false);
  const Countries = [
    {
      icon: France,
      name: "France",
    },

    {
      icon: Germany,
      name: "Germany",
    },
    {
      icon: UnitedKingdom,
      name: "United Kingdom",
    },
    {
      icon: Switzerland,
      name: "Switzerland",
    },
    {
      icon: Estonia,
      name: "Estonia",
    },

    {
      icon: Finland,
      name: "Finland",
    },

    {
      icon: Georgia,
      name: "Georgia",
    },

    {
      icon: Greece,
      name: "Greece",
    },

    {
      icon: Hungary,
      name: "Hungary",
    },

    {
      icon: Ireland,
      name: "Ireland",
    },

    {
      icon: Italy,
      name: "Italy",
    },

    {
      icon: Latvia,
      name: "Latvia",
    },

    {
      icon: Liechtenstein,
      name: "Liechtenstein",
    },

    {
      icon: Lithuania,
      name: "Lithuania",
    },

    {
      icon: Luxembourg,
      name: "Luxembourg",
    },

    {
      icon: Malta,
      name: "Malta",
    },

    {
      icon: Moldova,
      name: "Moldova",
    },

    {
      icon: Netherlands,
      name: "Netherlands",
    },

    {
      icon: NewZealand,
      name: "New Zealand",
    },

    {
      icon: NorthMacedonia,
      name: "North Macedonia",
    },

    {
      icon: Norway,
      name: "Norway",
    },

    {
      icon: Poland,
      name: "Poland",
    },

    {
      icon: Portugal,
      name: "Portugal",
    },

    // {
    //   icon: Qatar,
    //   name: "Qatar",
    // },

    {
      icon: Romania,
      name: "Romania",
    },

    // {
    //   icon: Singapore,
    //   name: "Singapore",
    // },

    {
      icon: Slovakia,
      name: "Slovakia",
    },

    {
      icon: Slovenia,
      name: "Slovenia",
    },

    {
      icon: Spain,
      name: "Spain",
    },

    {
      icon: Sweden,
      name: "Sweden",
    },

    {
      icon: Turkey,
      name: "Turkey",
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      if (country) {
        setLoading(true);
        await axios
          .post(`${process.env.REACT_APP_BACKEND_URL}/get/university`, {
            name: country.name,
          })
          .then((res) => {
            if (res.data.msg === "success") {
              setUniList(res.data.university);
              setLoading(false);
            } else {
              console.log(res.data);
            }
          })
          .catch((err) => {
            setLoader(false);
          });

        // setUniList(data);
        setLoading(false);
      }
    };
    fetchData();
  }, [country]);

  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const response = await fetch(
          "http://apiip.net/api/check?accessKey=5576a302-6489-4661-8958-67430c4fccee"
        );
        const data = await response.json();

        const userCountry = data.countryName || "Unknown";

        const matchingCountry = Countries.find(
          (country) => country.name === userCountry
        );

        if (matchingCountry) {
          setCountry(matchingCountry);
        } else {
          setCountry(null);
        }
      } catch (error) {
        console.error("Error fetching country:", error.message);
      }
    };

    fetchCountry();
  }, []);

  const generateOtp = async () => {
    const randomOtp = Math.floor(1000 + Math.random() * 9000);
    setOtp(randomOtp.toString());

    // Wait for a short delay to simulate asynchronous behavior
    await new Promise((resolve) => setTimeout(resolve, 200));

    // Now send the email and show the alert
    sendEmail(randomOtp.toString());
  };

  const sendEmail = (ad) => {
    // Replace with the actual recipient's email
    const payload = {
      code: ad,
      to_email: EmailAddress,
      to_name: firstName + " " + LastName, // Use the static recipient for testing
    };

    console.log("Recipient's Email Address:", EmailAddress);

    emailjs
      .send("service_08a640e", "template_u5ommri", payload, "FCKBh2iU92kGQhhAA")
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const HandleFormSubmit = (e) => {
    e.preventDefault();
    HandleNextSteps();
  };

  const HandleNextSteps = async () => {
    if (step === 1) {
      if (selectedUni) {
        setStep(3);
        return;
      } else {
        toast.error("Please Select a univeristy first");
        return;
      }
    } else if (step === 2) {
      if (CustomUni) {
        setStep(3);
        return;
      } else {
        toast.error("Please Enter a univeristy Name");
        return;
      }
    } else if (step === 3) {
      if (selectedItem.length === 0) {
        toast.error("Please select at least one interest");
        return;
      } else {
        setStep(4);
        return;
      }
    } else if (step === 4) {
      if (firstName) {
        setStep(5);
        return;
      } else {
        toast.error("Please Enter First Name");
        return;
      }
    } else if (step === 5) {
      if (LastName) {
        setStep(6);
        return;
      } else {
        toast.error("Please Enter Last Name");
        return;
      }
    } else if (step === 6) {
      if (!EmailAddress) {
        toast.error("Please enter a university email address");
        return;
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailRegex.test(EmailAddress)) {
        if (Uni) {
          const enteredDomain = EmailAddress.split("@")[1];
          if (enteredDomain.toLowerCase().includes(Uni.domain.toLowerCase())) {
            generateOtp();
            setStep(7);
            return;
          } else {
            toast.error(
              "Enter a valid university email address for this university"
            );
            return;
          }
        } else {
          const publicEmailDomainPattern =
            /@(gmail\.com|yahoo\.com|hotmail\.com|outlook\.com|aol\.com|protonmail\.com|mail\.com)$/;
          if (!publicEmailDomainPattern.test(EmailAddress)) {
            generateOtp();
            setStep(7);

            return;
          } else {
            toast.error("Please Enter a valid University Email Address");
            return;
          }
        }
      } else {
        toast.error("Please enter a valid email address");
        return;
      }
    } else if (step === 7) {
      if (!password || password.length < 8) {
        toast.error("Please Enter at least 6 digits of password");
        return;
      } else {
        setStep(8);
      }
    } else if (step === 8) {
      window.alert(otp);
      if (!agree) {
        toast.error("Kindly confirm that you agree with our Terms and Policy");
        return;
      }
      if (otp === optform) {
        const userData = {
          firstName,
          LastName,
          EmailAddress,
          uni: Uni,
          selectedItem,
          selectedCards: [],
          checker: Uni ? true : false,
          password,
        };
        setLoader(true);
        // console.log(selectedItem);
        await axios
          .post(`${process.env.REACT_APP_BACKEND_URL}/newUser`, userData)
          .then((res) => {
            setLoader(false);
            if (res.data.msg === "matched") {
              localStorage.setItem("token", res.data.token);
              localStorage.setItem("userId", res.data.newUser.uniqueId);
              setTimeout(() => {
                navigate("/student/dashboard");
                window.scrollTo(0, 0);
              }, 100);
            } else if (res.data.msg === "request") {
              setStep(9);
            } else if (res.data.msg === "Error") {
              toast.error("Error saving Details");
            } else if (res.data.msg === "alreadyreg") {
              setStep(6);
              toast.error("Email already Register");
              return;
            }
          })
          .catch((err) => {
            console.log(err);
          });
        return;
      } else {
        toast.error("Please Enter the correct OTP");
        return;
      }
    } else if (step === 9) {
      navigate("/");
    }
  };

  const BackStage = () => {
    if (step === 1) {
      navigate("/");
    } else if (step === 3) {
      if (CustomUni) {
        setStep(2);
      } else if (selectedUni) {
        setStep(1);
      } else {
        navigate("/");
      }
    } else if (step === 2) {
      if (CustomUni) {
        navigate("/");
      } else {
        setStep(1);
      }
    } else setStep(step - 1);
  };

  useEffect(() => {
    if (scope?.current) {
      animate(scope?.current, { width: `${width}%` }, { duration: 0.3 });
    }
  }, [step]);

  return (
    <div
      className="StudentRegBackground space"
      style={{ flexDirection: "column" }}
    >
      <motion.div
        ref={scope}
        className="ProgressBarForm"
        style={{
          width: `${width}%`,
        }}
      ></motion.div>
      <div className={`headerBackgroundChange ${!isNonMobile768 && "shadowa"}`}>
        <div className={`marginsleftrightCompany  `}>
          <div className="center" style={{ width: "100%" }}>
            <div className="headerCompany ">
              <div className={`space studentRegMarginTop `}>
                <div className="logoCompany">
                  <img
                    src={logo}
                    alt="logo"
                    height="36px"
                    width="36px"
                    onClick={() => navigate("/")}
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <button
                  className="newsupportbutton supportbuttontext btn border-0 d-flex"
                  style={{
                    alignItems: "center",
                    gap: "0.5rem",
                  }}
                  onClick={() => navigate("/support")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M9.83021 6.69935C9.51061 6.73619 9.2694 7.0068 9.2694 7.32852C9.2694 7.65024 9.51061 7.92084 9.83021 7.95768C9.99813 7.96001 10.1597 7.89376 10.2777 7.77423C10.3957 7.65469 10.4598 7.49222 10.4552 7.32435C10.4507 6.98105 10.1735 6.70384 9.83021 6.69935Z"
                      fill="#101828"
                    />
                    <path
                      d="M9.83021 9.09935C9.66376 9.09708 9.50347 9.1622 9.38577 9.27991C9.26806 9.39761 9.20294 9.5579 9.20521 9.72435V12.3243C9.20521 12.6695 9.48503 12.9493 9.83021 12.9493C10.1754 12.9493 10.4552 12.6695 10.4552 12.3243V9.74102C10.4597 9.57239 10.3959 9.4091 10.2781 9.28826C10.1604 9.16742 9.9989 9.09929 9.83021 9.09935Z"
                      fill="#101828"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M9.83021 1.66602C5.32639 1.67061 1.67647 5.32053 1.67188 9.82435C1.67188 14.3301 5.32449 17.9827 9.83021 17.9827C14.3359 17.9827 17.9885 14.3301 17.9885 9.82435C17.9839 5.32053 14.334 1.67061 9.83021 1.66602ZM9.83021 16.7327C6.01484 16.7327 2.92188 13.6397 2.92188 9.82435C2.92188 6.00898 6.01484 2.91602 9.83021 2.91602C13.6456 2.91602 16.7385 6.00898 16.7385 9.82435C16.7339 13.6378 13.6437 16.7281 9.83021 16.7327Z"
                      fill="#101828"
                    />
                  </svg>
                  Support
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="marginsleftrightCompany ">
        <div className="center" style={{ width: "100%", height: "100%" }}>
          <div className="headerCompany " style={{ height: "100%" }}>
            <div
              className={`${
                isNonMobile768 &&
                (step === 4 || step === 5 || step === 6) &&
                "space"
              }`}
            >
              <div
                className={`${
                  isNonMobile768 &&
                  (step === 4 || step === 5 || step === 6) &&
                  "col-md-6"
                }`}
              >
                {isNonMobile768 && step !== 9 && (
                  <button
                    className="StudentRegBackButton p-0"
                    onClick={() => BackStage()}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <path
                        d="M8.00143 10.7492C7.80269 10.7485 7.61226 10.6694 7.47143 10.5292L3.47143 6.5292C3.32974 6.38923 3.25 6.19836 3.25 5.9992C3.25 5.80003 3.32974 5.60916 3.47143 5.4692L7.47143 1.4692C7.6584 1.26854 7.93999 1.18595 8.20572 1.25381C8.47145 1.32168 8.67895 1.52917 8.74682 1.79491C8.81468 2.06064 8.73208 2.34223 8.53143 2.5292L5.06143 5.9992L8.53143 9.4692C8.67312 9.60916 8.75286 9.80003 8.75286 9.9992C8.75286 10.1984 8.67312 10.3892 8.53143 10.5292C8.3906 10.6694 8.20017 10.7485 8.00143 10.7492Z"
                        fill="black"
                      />
                    </svg>
                    Back
                  </button>
                )}
                <form onSubmit={(e) => HandleFormSubmit(e)}>
                  <Steps
                    LastName={LastName}
                    setLastName={setLastName}
                    firstName={firstName}
                    setFirstName={setFirstName}
                    CustomUni={CustomUni}
                    setCustomUni={setCustomUni}
                    country={country}
                    setCountry={setCountry}
                    Countries={Countries}
                    loading={loading}
                    setLoading={setLoading}
                    unilist={unilist}
                    setUniList={setUniList}
                    selectedUni={selectedUni}
                    setSelectedUni={setSelectedUni}
                    step={step}
                    setStep={setStep}
                    selectedItem={selectedItem}
                    setSelectedItem={setSelectedItem}
                    EmailAddress={EmailAddress}
                    setEmailAddress={setEmailAddress}
                    optform={optform}
                    setOtpForm={setOtpForm}
                    generateOtp={generateOtp}
                    password={password}
                    setPassword={setPassword}
                    agree={agree}
                    setAgree={setAgree}
                  />
                </form>
                <div
                  className="d-flex"
                  style={{
                    gap: "2.5rem",
                    alignItems: "center",
                    marginTop: "1.5rem",
                  }}
                >
                  {!isNonMobile768 && step !== 9 && (
                    <p
                      className="StudentRegBackButton"
                      onClick={() => BackStage()}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                      >
                        <path
                          d="M8.00143 10.7492C7.80269 10.7485 7.61226 10.6694 7.47143 10.5292L3.47143 6.5292C3.32974 6.38923 3.25 6.19836 3.25 5.9992C3.25 5.80003 3.32974 5.60916 3.47143 5.4692L7.47143 1.4692C7.6584 1.26854 7.93999 1.18595 8.20572 1.25381C8.47145 1.32168 8.67895 1.52917 8.74682 1.79491C8.81468 2.06064 8.73208 2.34223 8.53143 2.5292L5.06143 5.9992L8.53143 9.4692C8.67312 9.60916 8.75286 9.80003 8.75286 9.9992C8.75286 10.1984 8.67312 10.3892 8.53143 10.5292C8.3906 10.6694 8.20017 10.7485 8.00143 10.7492Z"
                          fill="black"
                        />
                      </svg>
                      Back
                    </p>
                  )}
                  <button
                    className="continuebutton "
                    type="submit"
                    style={{ width: isNonMobile450 ? "143px" : "100%" }}
                    disabled={loader}
                    onClick={() => HandleNextSteps()}
                  >
                    {step === 9 ? "Finish" : "Next"}
                  </button>

                  {isNonMobile1024 && (
                    <div
                      className="d-flex StudentRegPressEnter"
                      style={{ gap: "10px", alignItems: "center" }}
                    >
                      <div
                        className=" d-flex"
                        style={{
                          height: "24px",
                          width: "24px",
                          justifyContent: "center",
                          alignItems: "center",
                          background: "rgba(192, 16, 72, 0.30)",
                          borderRadius: "2px",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="21"
                          viewBox="0 0 20 21"
                          fill="none"
                        >
                          <path
                            d="M12.3 4.48334H5.69164C5.34646 4.48334 5.06664 4.76316 5.06664 5.10834C5.06664 5.45352 5.34646 5.73334 5.69164 5.73334H12.2583C13.9324 5.77874 15.2605 7.15875 15.2416 8.83334V10.6417C15.2416 12.3445 13.8612 13.725 12.1583 13.725H6.24997L7.62497 12.3333C7.7441 12.2173 7.81128 12.058 7.81128 11.8917C7.81128 11.7253 7.7441 11.5661 7.62497 11.45C7.38096 11.2063 6.98565 11.2063 6.74164 11.45L4.29997 13.8917C4.24348 13.9495 4.19826 14.0173 4.16664 14.0917C4.10852 14.2475 4.10852 14.4191 4.16664 14.575C4.19826 14.6494 4.24348 14.7172 4.29997 14.775L6.74164 17.2167C6.85821 17.3347 7.01742 17.4008 7.18331 17.4C7.34938 17.4017 7.50895 17.3355 7.62497 17.2167C7.7441 17.1006 7.81128 16.9413 7.81128 16.775C7.81128 16.6087 7.7441 16.4494 7.62497 16.3333L6.24997 14.9583H12.1583C14.5515 14.9583 16.4916 13.0182 16.4916 10.625V8.83334C16.5152 6.48257 14.65 4.54688 12.3 4.48334Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                      or Press Enter
                    </div>
                  )}
                </div>
              </div>
              {isNonMobile768 && (step === 4 || step === 5 || step === 6) && (
                <div className="col-md-5">
                  <NameCard
                    Countries={Countries}
                    firstName={firstName}
                    selectedItem={selectedItem}
                    LastName={LastName}
                    CustomUni={CustomUni}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="marginsleftrightCompany ">
        <div className="center" style={{ width: "100%", height: "100%" }}>
          <div className="headerCompany " style={{ height: "100%" }}>
            <div
              className="d-flex mb-4"
              style={{ justifyContent: isNonMobile1024 ? "end" : "center" }}
            >
              {/* <div className="d-flex">
                <button
                  className="footertextnew btn "
                  onClick={() => navigate("/privacy")}
                >
                  Privacy Policy
                </button>
                <button
                  className="footertextnew btn"
                  onClick={() => navigate("/terms")}
                >
                  Terms of Use
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default StudentReg;
