import { useEffect, useRef, useState } from "react";
import axios from "axios";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EmptySpace from "../../assets/images/EmptyState.svg";
import { useNavigate } from "react-router-dom";
import Recomended from "./Recommended";
const Table = ({
  challenges,
  jobposts,
  handleShortList,
  handleRemove,
  blocked,
  shortListed,
}) => {
  const [usersWithDetails, setUsersWithDetails] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const entriesPerPage = 6;
  const [showIcon, setShowIcon] = useState(false);
  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const [currentEntries, setCurrentEntries] = useState([]);
  const [Index, setIndex] = useState(-1);
  const [totalPages, setTotalPages] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    setCurrentEntries(usersWithDetails);

    setTotalPages(Math.ceil(usersWithDetails.length / entriesPerPage));
  }, [currentPage, usersWithDetails, indexOfFirstEntry, indexOfLastEntry]);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const renderPageButtons = () => {
    const buttons = [];
    for (let i = 1; i <= totalPages; i++) {
      buttons.push(
        <button
          key={i}
          style={
            i === currentPage
              ? { backgroundColor: "#E31B54", color: "#FFF" }
              : { color: "#000", backgroundColor: "transparent" } // Adjust colors as needed
          }
          onClick={() => handlePageChange(i)}
          className={`btn normalbuttons ${i === currentPage && " rounded-3"}`}
        >
          {i}
        </button>
      );
    }
    return buttons;
  };

  useEffect(() => {
    const fetchData = async () => {
      const users = [];

      const check = [...challenges, ...jobposts] || [];
      if (check.length > 0) {
        const clickDates = check.reduce((allDates, challenge) => {
          return [...allDates, ...challenge.clickDates];
        }, []);

        clickDates.forEach((date) => {
          if (!users.includes(date.userId)) {
            users.push(date.userId);
          }
        });

        const results = await Promise.all(
          users.map(async (user) => {
            let count = 0;
            let complete = 0;
            let apply = 0;

            check.forEach((chec) => {
              chec.clickDates.forEach((click) => {
                if (click.userId === user) {
                  count++;
                }
              });
            });
            challenges.forEach((chec) => {
              chec.countDates.forEach((click) => {
                if (click.userId === user) {
                  complete++;
                }
              });
            });
            jobposts.forEach((chec) => {
              chec.countDates.forEach((click) => {
                if (click.userId === user) {
                  apply++;
                }
              });
            });

            let levelofEngagement =
              (count / check.length) * 20 + (complete / challenges.length) * 60;

            if (apply > 0) {
              levelofEngagement += 10;
            }
            try {
              const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/get/user/details`,
                {
                  userId: user,
                  msg: "fjksabnfjkbnasjfbajsbfjasjhfbjahsbjkfbk",
                }
              );

              return {
                userId: user,
                levelofEngagement: levelofEngagement,
                userDetails: response.data,
              };
            } catch (error) {
              console.error("Error fetching user details:", error);
              return null;
            }
          })
        );
        results.sort((a, b) => b.levelofEngagement - a.levelofEngagement);
        if (blocked) {
          const filteredResults = results?.filter(
            (user) =>
              !blocked?.list?.some(
                (li) => li.user === user.userDetails.user._id
              )
          );
          setUsersWithDetails(filteredResults);
        } else {
          setUsersWithDetails(results);
        }

        // setUsersWithDetails(results.filter(Boolean));
        // setUsersWithDetails(
        //   Array(100)
        //     .fill()
        //     .flatMap(() => results.filter(Boolean))
        // );
      }
    };

    fetchData();
  }, [challenges, jobposts, blocked]);
  const [filtered, setFiltered] = useState([]);
  useEffect(() => {
    setFiltered(() => {
      let students = [];
      usersWithDetails.map((us) => {
        if (us?.userDetails?.user?._id) {
          students.push(us);
        }
      });

      return students;
    });
  }, [usersWithDetails]);

  const ShowUserRecord = async (user, index) => {
    console.log({ user });

    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/get/user/details`,
        {
          userId: user.userId,
          msg: "fjksabnfjkbnasjfbajsbfjasjhfbjahsbjkfbk",
        }
      );

      return (
        <tr key={index} className="tbodytr">
          <td className="tbodytrtd">a</td>
        </tr>
      );
    } catch (error) {
      console.error("Error fetching user details:", error);
      return null; // or handle the error accordingly
    }
  };

  const iconRef = useRef(null);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });

    function handleClickOutside(event) {
      if (iconRef.current && !iconRef.current.contains(event.target)) {
        setShowIcon(false);
        setIndex(-1);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      {filtered && filtered.length > 0 && (
        <Recomended
          challenges={filtered}
          shortListed={shortListed}
          handleShortList={handleShortList}
          navigate={navigate}
        />
      )}
      {/* <div
        className="tablescroll maindashboardtopcards shadowa "
        style={{ marginTop: "0.6rem", overflowX: "auto" }}
      >
        <table
          className="tableofchallenges innermaindashboardcarddiv"
          style={{ width: "100%", overflowX: "scroll", padding: "1rem" }}
        >
          <thead className="thead">
            <tr className="theadtr">
              <th className="theadth">Name</th>
              <th className="theadth">University Name</th>
              <th className="theadth">Course</th>
              <th className="theadth">Location</th>
              <th className="theadth">Level of Engagement</th>

              <th className="theadth">Action</th>
            </tr>
          </thead>

          {currentEntries && currentEntries.length > 0 && (
            <tbody className="tbody" style={{ marginTop: "1.25rem" }}>
              {currentEntries.map((user, index) => (
                <>
                  {user?.userDetails?.user?.profilePhoto && (
                    <>
                      <tr key={index} className="tbodytr">
                        <td className="tbodytrtd">
                          <div className="d-flex">
                            {console.log(user)}
                            {user?.userDetails?.user?.profilePhoto && (
                              <img
                                src={user.userDetails.user.profilePhoto}
                                alt="user profile"
                                height="45px"
                                className="shadowa"
                                width="45px"
                                style={{ borderRadius: "100px" }}
                              />
                            )}
                            <p
                              className="mt-0 mb-0 m-2 black14 d-flex hoverred"
                              style={{
                                justifyContent: "center",
                                alignItems: "center",
                                fontWeight: "500",
                                maxWidth: "300px",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                navigate(
                                  `/dashboard/user/${user?.userDetails?.user?.uniqueId}`
                                )
                              }
                            >
                              {user?.userDetails?.user?.firstName}{" "}
                              {user?.userDetails?.user?.LastName}
                            </p>
                          </div>
                        </td>
                        <td>
                          <p
                            className="mt-0 mb-0 m-2 black14 d-flex "
                            style={{
                              justifyContent: "center",
                              alignItems: "center",
                              fontWeight: "500",
                              maxWidth: "300px",
                            }}
                          >
                            {user?.userDetails?.user?.uni.name}
                          </p>
                        </td>
                        <td>
                          <p
                            className="mt-0 mb-0 m-2 black14 d-flex"
                            style={{
                              justifyContent: "center",
                              alignItems: "center",
                              fontWeight: "500",
                            }}
                          >
                            {user?.userDetails?.user?.levelofstudy} of{" "}
                            {user?.userDetails?.user?.study}
                          </p>
                        </td>
                        <td>
                          <p
                            className="mt-0 mb-0 m-2 black14 d-flex"
                            style={{
                              justifyContent: "center",
                              alignItems: "center",
                              fontWeight: "500",
                            }}
                          >
                            {user?.userDetails?.user?.uni?.area}
                          </p>
                        </td>
                        <td>
                          <div
                            className="d-flex"
                            style={{
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <p
                              className="mt-0 mb-0 m-2 black14 d-flex"
                              style={{
                                justifyContent: "center",
                                alignItems: "center",
                                fontWeight: "500",
                              }}
                            >
                              {user?.levelofEngagement.toFixed(2)}%
                            </p>
                            <div
                              className=""
                              style={{
                                width: `90%`,
                                height: "4px",
                                borderRadius: "100px",
                                background: `linear-gradient(to right, #8098F9 0%, #C01048 ${user?.levelofEngagement}%, #DBDFF1 ${user?.levelofEngagement}%)`,
                              }}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="center" style={{}}>
                            <div style={{ position: "relative" }}>
                              {showIcon && Index === index && (
                                <div
                                  style={{
                                    position: "absolute",
                                    background: "transparent",

                                    marginLeft: "-140px",
                                  }}
                                  ref={iconRef}
                                >
                                  <div
                                    className="d-flex shadowa"
                                    style={{
                                      flexDirection: "column",
                                      background: "transparent",
                                    }}
                                  >
                                    <button
                                      className="d-flex black14"
                                      style={{
                                        padding: "11px 1rem",
                                        maxHeight: "2rem",
                                        borderRadius: "5px 5px 0px 0px",
                                        gap: "10px",
                                        background: "#FFF",
                                        border: "none",
                                        width: "160px",
                                        minHeight: "42px",
                                      }}
                                      onClick={() => {
                                        setShowIcon(false);
                                        navigate(
                                          `/dashboard/chat?id=${user?.userDetails?.user?.uniqueId}`
                                        );
                                        setIndex(-1);
                                      }}
                                    >
                                      Message
                                    </button>
                                    <button
                                      className="d-flex black14"
                                      style={{
                                        padding: "11px 1rem",
                                        maxHeight: "2rem",
                                        borderRadius: "0px 0px 5px 5px",
                                        gap: "10px",
                                        background: "#FFF",
                                        border: "none",
                                        width: "160px",
                                        minHeight: "42px",
                                        borderTop: "1px solid #d9d9d9",
                                      }}
                                      onClick={() => {
                                        setShowIcon(false);
                                        setIndex(-1);
                                        handleShortList(
                                          user.userDetails.user._id
                                        );
                                      }}
                                    >
                                      Short List
                                    </button>
                                    <button
                                      className="d-flex black14"
                                      style={{
                                        padding: "11px 1rem",
                                        maxHeight: "2rem",
                                        borderRadius: "0px 0px 5px 5px",
                                        gap: "10px",
                                        background: "#FFF",
                                        border: "none",
                                        width: "160px",
                                        minHeight: "42px",
                                        borderTop: "1px solid #d9d9d9",
                                      }}
                                      onClick={() => {
                                        setShowIcon(false);
                                        setIndex(-1);
                                        handleRemove(user.userDetails.user._id);
                                      }}
                                    >
                                      Remove
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>
                            <MoreVertIcon
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setIndex(index);
                                setShowIcon(true);
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    </>
                  )}
                </>
              ))}{" "}
            </tbody>
          )}
         
        </table>
        {!currentEntries ||
          (currentEntries.length === 0 && (
            <div
              className="center col-md-12 col-12"
              style={{
                alignItems: "center",
                flexDirection: "column",
                width: "100%",
                height: "400px",
              }}
            >
              <img
                src={EmptySpace}
                alt="Emptyspace"
                height="225px"
                width="180px"
                className="img-fluid"
              />
              <p className="pink16">No Data yet</p>
              <p className="black14" style={{ fontWeight: "500" }}>
                <span className="black14">Please come back later.</span> The
                first students will engage with your company in no time!
              </p>
            </div>
          ))}
      </div>
      <div
        className="d-flex mb-5"
        style={{ marginTop: "2.5rem", justifyContent: "end" }}
      >
        <div
          className="d-flex flex-wrap"
          style={{
            justifyContent: "center",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          {usersWithDetails && usersWithDetails.length > 6 && (
            <button
              className="backbutton"
              onClick={() => navigate("/dashboard/TalentPool")}
            >
              View All
            </button>
          )}
        </div>
      </div> */}
    </>
  );
};

export default Table;
