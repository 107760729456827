import "./dashboard.css";
import { useMediaQuery } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import SideBar from "./Sidebar/Sidebar";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import StudentDashboardHeader from "./Header/Header";
const StudentDashboard = () => {
  const isNonMobile = useMediaQuery("(min-width:767px)");
  const isNonMobile1024 = useMediaQuery("(min-width:1024px)");
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  useEffect(() => {
    if (!user) {
      navigate("/");
    }
  }, []);
  return (
    <div className="d-flex backgrounddashboard">
      <div style={{ position: "absolute", top: "1.5rem", right: "1.5rem" }}>
        <StudentDashboardHeader user={user} />
      </div>
      {isNonMobile && (
        <div
          className="  pt-2 "
          style={{
            backgroundColor: "#FFF",
            display: isNonMobile1024 ? "" : "none",
          }}
        >
          <SideBar user={user} navigate={navigate} />
        </div>
      )}
      <div
        className=""
        style={{
          width: "100%",
          minHeight: "100vh",
        }}
      >
        <Outlet />
      </div>
    </div>
  );
};
export default StudentDashboard;
