const Adduser = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9102 11.6666C13.2183 11.6932 14.3265 12.6376 14.5602 13.9249L14.7519 14.8749C14.9412 15.6949 14.7551 16.5566 14.2442 17.2253C13.7334 17.894 12.9511 18.3002 12.1102 18.3333H4.36852C3.52648 18.3032 2.74213 17.8978 2.23067 17.2282C1.71921 16.5586 1.53437 15.6952 1.72685 14.8749L1.91852 13.9249C2.14855 12.6389 3.25403 11.6939 4.56018 11.6666H11.9102ZM12.1102 17.0833C12.5313 17.0795 12.9274 16.883 13.1852 16.5499C13.5156 16.1512 13.6418 15.6215 13.5268 15.1166L13.3352 14.1666C13.2176 13.4629 12.623 12.9379 11.9102 12.9083H4.56018C3.84928 12.9386 3.25751 13.4643 3.14352 14.1666L2.99352 15.1249C2.88128 15.6272 3.00738 16.1532 3.33518 16.5499C3.5838 16.8718 3.96215 17.0671 4.36852 17.0833H12.1102Z"
        fill="#344054"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.65185 9.99995H7.81852C5.97757 9.99995 4.48518 8.50757 4.48518 6.66662V4.46662C4.48518 2.92022 5.73878 1.66662 7.28518 1.66662H9.16852C9.91468 1.65993 10.6325 1.95224 11.1617 2.47832C11.6909 3.00439 11.9874 3.72042 11.9852 4.46662V6.66662C11.9852 8.50757 10.4928 9.99995 8.65185 9.99995ZM7.28518 2.91662C6.42914 2.91662 5.73518 3.61057 5.73518 4.46662V6.66662C5.73965 7.80887 6.65967 8.73631 7.80185 8.74995H8.63518C9.18772 8.74995 9.71762 8.53046 10.1083 8.13975C10.499 7.74905 10.7185 7.21915 10.7185 6.66662V4.46662C10.7185 3.61057 10.0246 2.91662 9.16852 2.91662H7.28518Z"
        fill="#344054"
      />
      <path
        d="M17.8185 5.20828H16.6685V4.06662C16.6685 3.72144 16.3887 3.44162 16.0435 3.44162C15.6983 3.44162 15.4185 3.72144 15.4185 4.06662V5.20828H14.2852C13.94 5.20828 13.6602 5.4881 13.6602 5.83328C13.6602 6.17846 13.94 6.45828 14.2852 6.45828H15.4268V7.59995C15.4268 7.94513 15.7067 8.22495 16.0518 8.22495C16.397 8.22495 16.6768 7.94513 16.6768 7.59995V6.45828H17.8185C18.1637 6.45828 18.4435 6.17846 18.4435 5.83328C18.4435 5.4881 18.1637 5.20828 17.8185 5.20828Z"
        fill="#344054"
      />
    </svg>
  );
};
export default Adduser;
