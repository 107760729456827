import "./right.css";
import React, { useState } from "react";
import { useMediaQuery } from "@mui/material";
import background from "../../assets/images/aa.png";
const Right = ({
  step,
  selectedFile,
  company,
  alltask,
  time,
  title,
  description,
  type,
}) => {
  const maxLines = 4;

  const [hoveredChallenge, setHoveredChallenge] = useState(false);

  const handleMouseEnter = () => {
    setHoveredChallenge(true);
  };

  const handleMouseLeave = () => {
    setHoveredChallenge(false);
  };

  const getClampedText = (text, maxLines) => {
    const lineHeight = 1.2; // Adjust this value based on your line height
    const maxHeight = maxLines * lineHeight;

    return {
      maxHeight: `${maxHeight}em`,
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: maxLines,
    };
  };

  const isNonMobile = useMediaQuery("(min-width:767px)");

  return (
    <div className=" ">
      <p className="nextstepsright">Next steps</p>
      <div className="d-flex " style={{ marginTop: "1.25rem", gap: "0.75rem" }}>
        <div
          className="d-flex"
          style={{ alignItems: "center", flexDirection: "column" }}
        >
          <div className=" rightsidesvgcontainer ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.43906 7.63268L17.0641 10.0494C17.6019 10.21 17.9966 10.6698 18.0737 11.2258C18.1508 11.7819 17.8963 12.3317 17.4224 12.6327L14.7641 14.2994C14.5881 14.4084 14.4398 14.5567 14.3307 14.7327L12.6641 17.391C12.4132 17.7984 11.9675 18.045 11.4891 18.041C10.8501 18.0455 10.2826 17.6334 10.0891 17.0244L7.6724 9.39935C7.51446 8.89848 7.64834 8.35134 8.0197 7.97998C8.39106 7.60862 8.93819 7.47475 9.43906 7.63268ZM14.0557 13.1493L16.7141 11.4827C16.742 11.4564 16.7456 11.4132 16.7224 11.3827C16.7141 11.3243 16.6557 11.3243 16.6557 11.3243L9.03073 8.90768V8.95768L11.4474 16.5827C11.4559 16.6347 11.5033 16.6711 11.5557 16.666L13.2224 13.9827C13.4381 13.6491 13.7221 13.3651 14.0557 13.1493Z"
                fill="#E31B54"
              />
              <path
                d="M4.56406 3.69102C4.32681 3.47881 3.96798 3.47881 3.73073 3.69102C3.52581 3.931 3.52581 4.28436 3.73073 4.52435L5.39739 6.27435C5.51643 6.38866 5.67406 6.45409 5.83906 6.45768C6.00441 6.45576 6.16263 6.39009 6.28073 6.27435C6.4139 6.16099 6.49268 5.99641 6.49744 5.82158C6.50221 5.64676 6.43252 5.47813 6.30573 5.35768L4.56406 3.69102Z"
                fill="#E31B54"
              />
              <path
                d="M8.1224 5.35768C8.28818 5.35332 8.44543 5.28325 8.55952 5.1629C8.67362 5.04255 8.73521 4.88179 8.73073 4.71602V2.29102C8.73073 1.94584 8.45091 1.66602 8.10573 1.66602C7.76055 1.66602 7.48073 1.94584 7.48073 2.29102V4.71602C7.47609 4.88758 7.5422 5.0535 7.66356 5.17485C7.78491 5.29621 7.95083 5.36232 8.1224 5.35768Z"
                fill="#E31B54"
              />
              <path
                d="M10.4474 5.30768C10.6133 5.30847 10.7725 5.24238 10.8891 5.12435L12.6057 3.40768C12.7729 3.25187 12.8418 3.01722 12.7852 2.79578C12.7287 2.57433 12.5557 2.40141 12.3343 2.34486C12.1129 2.28831 11.8782 2.35714 11.7224 2.52435L10.0057 4.24102C9.76202 4.48503 9.76202 4.88033 10.0057 5.12435C10.1223 5.24238 10.2815 5.30847 10.4474 5.30768Z"
                fill="#E31B54"
              />
              <path
                d="M4.28073 9.96602L2.56406 11.6827C2.32035 11.9267 2.32035 12.322 2.56406 12.566C2.68009 12.6849 2.83965 12.7511 3.00573 12.7493C3.17162 12.7501 3.33083 12.684 3.4474 12.566L5.16406 10.8493C5.33127 10.6935 5.4001 10.4589 5.34355 10.2374C5.287 10.016 5.11408 9.84308 4.89264 9.78653C4.67119 9.72998 4.43654 9.79881 4.28073 9.96602Z"
                fill="#E31B54"
              />
              <path
                d="M4.7224 7.49102H2.28906C1.94388 7.49102 1.66406 7.77084 1.66406 8.11602C1.66406 8.46119 1.94388 8.74102 2.28906 8.74102H4.7224C5.06757 8.74102 5.3474 8.46119 5.3474 8.11602C5.3474 7.77084 5.06757 7.49102 4.7224 7.49102Z"
                fill="#E31B54"
              />
            </svg>
          </div>
          <div
            className="rounded-5"
            style={{
              width: "2px",
              height: "20px",
              backgroundColor: "#E31B54",
              marginTop: "0.25rem",
              marginBottom: "0.25rem",
            }}
          ></div>
        </div>
        <div className=" ">
          <p className="rightheadingtext" style={{ color: "#E31B54" }}>
            Type of Challenge
          </p>
          <p className="righttext" style={{ color: "#E31B54" }}>
            Select the challenge type
          </p>
        </div>
      </div>

      <div className="d-flex " style={{ gap: "0.75rem" }}>
        <div
          className="d-flex"
          style={{ alignItems: "center", flexDirection: "column" }}
        >
          <div className=" rightsidesvgcontainer ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M13.1532 6.79102H9.06155C8.74859 6.79102 8.49488 6.53731 8.49488 6.22435C8.49488 5.91139 8.74859 5.65768 9.06155 5.65768H13.1532C13.4662 5.65768 13.7199 5.91139 13.7199 6.22435C13.7199 6.53731 13.4662 6.79102 13.1532 6.79102Z"
                fill={step >= 2 ? "#E31B54" : "#344054"}
              />
              <path
                d="M9.08655 10.5493H12.0032C12.3162 10.5493 12.5699 10.8031 12.5699 11.116C12.5699 11.429 12.3162 11.6827 12.0032 11.6827H9.08655C8.77359 11.6827 8.51988 11.429 8.51988 11.116C8.51988 10.8031 8.77359 10.5493 9.08655 10.5493Z"
                fill={step >= 2 ? "#E31B54" : "#344054"}
              />
              <path
                d="M14.3199 8.09935H9.06155C8.84114 8.07267 8.62507 8.17526 8.50643 8.36292C8.38779 8.55059 8.38779 8.78978 8.50643 8.97744C8.62507 9.1651 8.84114 9.26769 9.06155 9.24102H14.3199C14.6086 9.20607 14.8258 8.96102 14.8258 8.67018C14.8258 8.37934 14.6086 8.1343 14.3199 8.09935Z"
                fill={step >= 2 ? "#E31B54" : "#344054"}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.98655 1.66602H13.3865C15.5727 1.66602 17.3449 3.43822 17.3449 5.62435V11.7243C17.3602 13.3824 16.3384 14.8736 14.7865 15.4577C14.6268 17.1773 13.1802 18.4901 11.4532 18.4827H7.28655C5.10042 18.4827 3.32821 16.7105 3.32821 14.5243V7.67435C3.31597 5.97893 4.57833 4.54443 6.26155 4.34102C6.80761 2.74763 8.30221 1.67433 9.98655 1.66602ZM7.25321 17.1993H11.4199C12.3514 17.2 13.1692 16.5799 13.4199 15.6827H9.98655C7.80042 15.6827 6.02821 13.9105 6.02821 11.7243V5.65768C5.15018 5.91889 4.54732 6.72496 4.54488 7.64102V14.491C4.54488 15.9868 5.75744 17.1993 7.25321 17.1993ZM16.0949 11.7243C16.0949 13.2201 14.8823 14.4327 13.3865 14.4327H9.98655C8.49267 14.4281 7.28279 13.2182 7.27821 11.7243V5.62435C7.28279 4.13048 8.49267 2.92059 9.98655 2.91602H13.3865C14.8823 2.91602 16.0949 4.12858 16.0949 5.62435V11.7243Z"
                fill={step >= 2 ? "#E31B54" : "#344054"}
              />
            </svg>
          </div>
          <div
            className="rounded-5"
            style={{
              width: "2px",
              height: "20px",
              backgroundColor: step >= 2 ? "#E31B54" : "#344054",
              opacity: step >= 2 ? 1 : 0.5,
              marginTop: "0.25rem",
              marginBottom: "0.25rem",
            }}
          ></div>
        </div>
        <div className=" ">
          <p
            className="rightheadingtext"
            style={step >= 2 ? { color: "#E31B54" } : { color: "#344054" }}
          >
            Basic Information
          </p>
          <p
            className="righttext"
            style={step >= 2 ? { color: "#E31B54" } : { color: "#344054" }}
          >
            What is this challenge about?{" "}
          </p>
        </div>
      </div>

      <div className="d-flex " style={{ gap: "0.75rem" }}>
        <div
          className="d-flex"
          style={{ alignItems: "center", flexDirection: "column" }}
        >
          <div className="rightsidesvgcontainer ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.4743 8.84827C12.2251 8.83023 12.8958 8.37423 13.1886 7.68271C13.2511 7.70906 13.3179 7.72359 13.3857 7.72556H16.8143C17.1693 7.72556 17.4571 7.43778 17.4571 7.08279C17.4571 6.7278 17.1693 6.44002 16.8143 6.44002H13.4286C13.3916 6.43517 13.3541 6.43517 13.3171 6.44002C13.0716 5.47552 12.1269 4.86086 11.1455 5.02708C10.1641 5.19331 9.47458 6.08478 9.56047 7.07631C9.64636 8.06785 10.4789 8.82751 11.4743 8.82256V8.84827ZM11.4743 6.32004C11.8199 6.32004 12.1 6.60014 12.1 6.94567C12.1 7.29119 11.8199 7.5713 11.4743 7.5713C11.1287 7.5713 10.8486 7.29119 10.8486 6.94567C10.8486 6.60014 11.1287 6.32004 11.4743 6.32004Z"
                fill={step >= 3 ? "#E31B54" : "#344054"}
              />
              <path
                d="M3.14286 6.44002H8.28572C8.64076 6.44002 8.92857 6.7278 8.92857 7.08279C8.92857 7.43778 8.64076 7.72556 8.28572 7.72556H3.14286C2.78782 7.72556 2.5 7.43778 2.5 7.08279C2.5 6.7278 2.78782 6.44002 3.14286 6.44002Z"
                fill={step >= 3 ? "#E31B54" : "#344054"}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.78 13.2962C5.04848 12.537 5.76599 12.0289 6.57143 12.0278V12.0107C7.5407 12.0095 8.35746 12.7338 8.47192 13.6962C8.58638 14.6585 7.96231 15.5542 7.01978 15.7803C6.07726 16.0064 5.11468 15.4913 4.78 14.5818H3.14286C2.78782 14.5818 2.5 14.294 2.5 13.939C2.5 13.584 2.78782 13.2962 3.14286 13.2962H4.78ZM5.94675 13.9841C5.95264 14.325 6.23044 14.5984 6.57143 14.5989V14.5646C6.917 14.5646 7.19714 14.2845 7.19714 13.939C7.17846 13.5986 6.89059 13.3358 6.54982 13.3481C6.20905 13.3604 5.94086 13.6432 5.94675 13.9841Z"
                fill={step >= 3 ? "#E31B54" : "#344054"}
              />
              <path
                d="M10 13.2962H16.8571C17.2122 13.2962 17.5 13.584 17.5 13.939C17.5 14.294 17.2122 14.5818 16.8571 14.5818H10C9.64496 14.5818 9.35714 14.294 9.35714 13.939C9.35714 13.584 9.64496 13.2962 10 13.2962Z"
                fill={step >= 3 ? "#E31B54" : "#344054"}
              />
            </svg>
          </div>
          <div
            className="rounded-5"
            style={{
              width: "2px",
              height: "20px",
              backgroundColor: step >= 3 ? "#E31B54" : "#344054",
              opacity: step >= 3 ? 1 : 0.5,
              marginTop: "0.25rem",
              marginBottom: "0.25rem",
            }}
          ></div>
        </div>
        <div className="">
          <p
            className="rightheadingtext"
            style={step >= 3 ? { color: "#E31B54" } : { color: "#344054" }}
          >
            Details
          </p>
          <p
            className="righttext"
            style={step >= 3 ? { color: "#E31B54" } : { color: "#344054" }}
          >
            Add filter options and skills{" "}
          </p>
        </div>
      </div>

      <div className="d-flex " style={{ gap: "0.75rem" }}>
        <div
          className="d-flex"
          style={{ alignItems: "center", flexDirection: "column" }}
        >
          <div className="rightsidesvgcontainer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M15.5786 1.66602H12.404C12.0283 1.66602 11.7237 1.97058 11.7237 2.34629C11.7237 2.72199 12.0283 3.02656 12.404 3.02656H15.5786C16.2048 3.02656 16.7124 3.53417 16.7124 4.16035V7.33495C16.7124 7.71065 17.0169 8.01522 17.3926 8.01522C17.7683 8.01522 18.0729 7.71065 18.0729 7.33495V4.16035C18.0729 2.78277 16.9562 1.66602 15.5786 1.66602Z"
                fill={step >= 4 ? "#E31B54" : "#344054"}
              />
              <path
                d="M3.90058 16.9721H7.07519C7.45089 16.9721 7.75546 17.2767 7.75546 17.6524C7.75546 18.0281 7.45089 18.3327 7.07519 18.3327H3.90058C2.523 18.3327 1.40625 17.2159 1.40625 15.8384V12.6637C1.40625 12.4207 1.53591 12.1961 1.74639 12.0746C1.95686 11.9531 2.21618 11.9531 2.42666 12.0746C2.63714 12.1961 2.76679 12.4207 2.76679 12.6637V15.8384C2.76679 16.4645 3.27441 16.9721 3.90058 16.9721Z"
                fill={step >= 4 ? "#E31B54" : "#344054"}
              />
              <path
                d="M16.7124 12.6647C16.7124 12.2885 17.0169 11.9835 17.3926 11.9835C17.7648 11.9834 18.068 12.2829 18.0729 12.6556V15.8348C18.0729 17.2143 16.9562 18.3327 15.5786 18.3327H12.404C12.0283 18.3327 11.7237 18.0277 11.7237 17.6514C11.7237 17.2752 12.0283 16.9702 12.404 16.9702H15.5786C16.2048 16.9702 16.7124 16.4619 16.7124 15.8348V12.6647Z"
                fill={step >= 4 ? "#E31B54" : "#344054"}
              />
              <path
                d="M3.90058 1.66602H7.07519C7.45089 1.66602 7.75546 1.97058 7.75546 2.34629C7.75546 2.72199 7.45089 3.02656 7.07519 3.02656H3.90058C3.27441 3.02656 2.76679 3.53417 2.76679 4.16035V7.33495C2.76679 7.71065 2.46223 8.01522 2.08652 8.01522C1.71082 8.01522 1.40625 7.71065 1.40625 7.33495V4.16035C1.40625 2.78277 2.523 1.66602 3.90058 1.66602Z"
                fill={step >= 4 ? "#E31B54" : "#344054"}
              />
            </svg>
          </div>
          <div
            className="rounded-5"
            style={{
              width: "2px",
              height: "20px",
              backgroundColor: step >= 4 ? "#E31B54" : "#344054",
              opacity: step >= 4 ? 1 : 0.5,
              marginTop: "0.25rem",
              marginBottom: "0.25rem",
            }}
          ></div>
        </div>
        <div className="">
          <p
            className="rightheadingtext"
            style={step >= 4 ? { color: "#E31B54" } : { color: "#344054" }}
          >
            Overview Page
          </p>
          <p
            className="righttext"
            style={step >= 4 ? { color: "#E31B54" } : { color: "#344054" }}
          >
            Create an overview page
          </p>
        </div>
      </div>

      <div className="d-flex " style={{ gap: "0.75rem" }}>
        <div className="rightsidesvgcontainer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M18.5177 3H12.5977C10.9409 3 9.59772 4.34315 9.59772 6V17.28C9.59772 18.9369 10.9409 20.28 12.5977 20.28H18.5177C20.1746 20.28 21.5177 18.9369 21.5177 17.28V6C21.5177 4.34315 20.1746 3 18.5177 3ZM11.1377 11H11.9577C12.2339 11 12.4577 11.2239 12.4577 11.5V11.82C12.4577 12.0961 12.2339 12.32 11.9577 12.32H11.1377V11ZM18.5177 18.8C19.3461 18.8 20.0177 18.1284 20.0177 17.3V6C20.0177 5.17157 19.3461 4.5 18.5177 4.5H12.5977C11.7693 4.5 11.0977 5.17157 11.0977 6V9.48H11.9177C13.0223 9.48 13.9177 10.3754 13.9177 11.48V11.8C13.9177 12.9046 13.0223 13.8 11.9177 13.8H11.0977V17.3C11.0977 18.1284 11.7693 18.8 12.5977 18.8H18.5177Z"
              fill={step >= 5 ? "#E31B54" : "#344054"}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2.00772 6.42C1.88724 4.68248 3.19112 3.17319 4.92772 3.04C6.65648 3.18862 7.94452 4.6995 7.81772 6.43V15.7C7.82907 16.181 7.69677 16.6545 7.43772 17.06L5.56772 19.95C5.43526 20.1875 5.18464 20.3347 4.91272 20.3347C4.6408 20.3347 4.39018 20.1875 4.25772 19.95L2.38772 17.06C2.13454 16.6519 2.00274 16.1802 2.00772 15.7V6.42ZM6.16772 16.24C6.26817 16.078 6.32022 15.8906 6.31772 15.7L6.33772 6.42C6.34772 5.4 5.69772 4.54 4.92772 4.54C4.16772 4.54 3.51772 5.42 3.51772 6.42V15.7C3.5092 15.8887 3.55427 16.0759 3.64772 16.24L4.90772 18.24L6.16772 16.24Z"
              fill={step >= 5 ? "#E31B54" : "#344054"}
            />
            <path
              d="M4.91772 5.18C4.50351 5.18 4.16772 5.51579 4.16772 5.93V9.39C4.16772 9.80421 4.50351 10.14 4.91772 10.14C5.33194 10.14 5.66773 9.80421 5.66773 9.39V5.93C5.67044 5.73027 5.5923 5.53792 5.45105 5.39667C5.30981 5.25543 5.11746 5.17728 4.91772 5.18Z"
              fill={step >= 5 ? "#E31B54" : "#344054"}
            />
            <path
              d="M18.2977 6.14H14.9477C14.5335 6.14 14.1977 6.47579 14.1977 6.89C14.1977 7.30421 14.5335 7.64 14.9477 7.64H18.2977C18.7119 7.64 19.0477 7.30421 19.0477 6.89C19.0477 6.47579 18.7119 6.14 18.2977 6.14Z"
              fill={step >= 5 ? "#E31B54" : "#344054"}
            />
          </svg>
        </div>
        <div className="">
          <p
            className="rightheadingtext"
            style={step >= 5 ? { color: "#E31B54" } : { color: "#344054" }}
          >
            Add Tasks
          </p>
          <p className="righttext">Create the actual challenge tasks</p>
        </div>
      </div>
      {step === 2 && (
        <div style={{ marginTop: "2.5rem" }}>
          <p className="nextstepsright">Preview</p>
          <div
            className="Challengescardouterwhitediv"
            onMouseEnter={() => handleMouseEnter()}
            onMouseLeave={handleMouseLeave}
            style={{ marginTop: "1.5rem" }}
          >
            <div className="Challengescardinnergreydiv">
              <div
                className="challengescardinsidegreybackgroundimagediv"
                style={isNonMobile ? {} : { display: "none" }}
              >
                <img
                  src={company && company.img}
                  alt="card-bg"
                  className="challengescardinsidegreybackgroundimagedivcompanycard"
                  style={{ maxHeight: "48px", maxWidth: "48px" }}
                />

                <img
                  src={
                    selectedFile
                      ? selectedFile instanceof File
                        ? `${URL.createObjectURL(selectedFile)}`
                        : selectedFile.includes("http")
                        ? selectedFile
                        : `${process.env.REACT_APP_BACKEND_URL}${selectedFile}`
                      : background
                  }
                  alt="challenge image"
                  height="166px"
                  style={{
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                    maxHeight: "7.813rem",
                    width: "100%",
                  }}
                />
              </div>
              <div style={{ padding: "0.75rem 0.46rem 0.75rem 0.5rem" }}>
                <p className="ChallengecardTaskCounter text-start">
                  {alltask.length + 1}{" "}
                  {alltask.length + 1 > 1 ? "Tasks" : "Task"}
                  {" / "}
                  {Number(alltask.reduce((acc, task) => acc + task.time, 0)) +
                    Number(time)}
                  mins
                </p>

                <p
                  className="ChallengecardChallengeTitle"
                  style={{ marginTop: "0.625rem" }}
                >
                  {title}
                </p>

                <p
                  className="ChallengeCardCompanyName"
                  style={{ marginTop: "0.375rem" }}
                >
                  {company && company.title}
                </p>
              </div>
            </div>
            <div
              className="d-flex"
              style={{
                justifyContent: "space-between",
                marginTop: "0.5rem",
              }}
            >
              <div
                className="d-flex"
                style={{ alignItems: "center", gap: "0.5rem" }}
              >
                {type === "Fast-Track" && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M4.84257 1.66602H15.16C16.9133 1.66602 18.3346 3.08733 18.3346 4.84062V15.1581C18.3346 16.9114 16.9133 18.3327 15.16 18.3327H4.84257C3.08929 18.3327 1.66797 16.9114 1.66797 15.1581V4.84062C1.66797 3.08733 3.08929 1.66602 4.84257 1.66602ZM15.16 17.1422C16.2558 17.1422 17.1442 16.2539 17.1442 15.1581V4.84062C17.1442 3.74482 16.2558 2.85649 15.16 2.85649H4.84257C3.74677 2.85649 2.85844 3.74482 2.85844 4.84062V15.1581C2.85844 16.2539 3.74677 17.1422 4.84257 17.1422H15.16Z"
                      fill="#0C111D"
                    />
                    <path
                      d="M14.3664 7.41998H11.9854C11.6567 7.41998 11.3902 7.68648 11.3902 8.01522C11.3902 8.34396 11.6567 8.61046 11.9854 8.61046H12.7235L9.89813 11.4359L8.31083 9.84856C7.88961 9.4288 7.20823 9.4288 6.78702 9.84856L4.71559 11.8565C4.48348 12.0889 4.48348 12.4654 4.71559 12.6978C4.82661 12.8102 4.97823 12.8731 5.13622 12.8724C5.29439 12.874 5.44636 12.8109 5.55686 12.6978L7.51718 10.7374L9.10448 12.3247C9.52841 12.7454 10.2123 12.7454 10.6362 12.3247L13.7394 9.22157V10.3962C13.7394 10.7249 14.0059 10.9914 14.3346 10.9914C14.6634 10.9914 14.9299 10.7249 14.9299 10.3962V8.01522C14.9264 7.70006 14.6809 7.4407 14.3664 7.41998Z"
                      fill="#0C111D"
                    />
                  </svg>
                )}
                {type === "Virtual Experience" && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M17.0641 7.42392H17.9732C18.7142 7.42887 19.3137 8.02836 19.3186 8.76937V10.1694C19.3186 10.9124 18.7162 11.5148 17.9732 11.5148H17.2732V12.3057C17.3296 13.6901 16.2572 14.86 14.8732 14.9239H13.4368C12.7782 14.9143 12.1542 14.6271 11.7186 14.133C11.292 13.6259 10.6631 13.3332 10.0004 13.3332C9.33774 13.3332 8.70889 13.6259 8.28224 14.133C7.84661 14.6271 7.22267 14.9143 6.56406 14.9239H5.1277C3.74366 14.86 2.67121 13.6901 2.7277 12.3057V11.5148H2.00952C1.26644 11.5148 0.664062 10.9124 0.664062 10.1694V8.76937C0.669019 8.02836 1.26851 7.42887 2.00952 7.42392H2.91861C3.25605 6.48851 4.13356 5.85656 5.1277 5.83301H14.8732C15.8606 5.86393 16.729 6.49456 17.0641 7.42392ZM2.04588 8.76937V10.1512L2.7277 10.1421V8.76937H2.04588ZM15.9095 12.3057C15.9625 12.9361 15.5022 13.4933 14.8732 13.5603H13.4368C13.1649 13.5585 12.9081 13.4351 12.7368 13.2239C12.0465 12.4412 11.0532 11.9928 10.0095 11.9928C8.96588 11.9928 7.97255 12.4412 7.28224 13.2239C7.11095 13.4351 6.85414 13.5585 6.58224 13.5603H5.1277C4.49867 13.4933 4.03836 12.9361 4.09134 12.3057V8.45119C4.03836 7.82083 4.49867 7.26361 5.1277 7.19664H14.8732C15.5022 7.26361 15.9625 7.82083 15.9095 8.45119V12.3057ZM17.2732 8.77846V10.1512L17.955 10.1603V8.77846H17.2732Z"
                      fill="#0C111D"
                    />
                    <path
                      d="M8.18224 9.41483H5.45497C5.07841 9.41483 4.77315 9.72009 4.77315 10.0966C4.77315 10.4732 5.07841 10.7785 5.45497 10.7785H8.18224C8.5588 10.7785 8.86406 10.4732 8.86406 10.0966C8.86406 9.72009 8.5588 9.41483 8.18224 9.41483Z"
                      fill="#0C111D"
                    />
                    <path
                      d="M13.3004 9.61483C13.2361 9.54822 13.1587 9.49561 13.0732 9.46028C12.9063 9.39666 12.7218 9.39666 12.555 9.46028C12.4694 9.49561 12.392 9.54822 12.3277 9.61483C12.2048 9.74509 12.1364 9.91753 12.1368 10.0966C12.1333 10.1838 12.1488 10.2707 12.1822 10.3512C12.2165 10.4352 12.2658 10.5122 12.3277 10.5785C12.4598 10.7053 12.6355 10.7768 12.8186 10.7785C12.9063 10.7778 12.9929 10.7592 13.0732 10.7239C13.1571 10.6897 13.2341 10.6404 13.3004 10.5785C13.4267 10.4496 13.4983 10.277 13.5004 10.0966C13.5007 10.0059 13.4821 9.91616 13.4459 9.83301C13.4103 9.75244 13.3611 9.67862 13.3004 9.61483Z"
                      fill="#0C111D"
                    />
                    <path
                      d="M11.255 9.46937C11.0881 9.40575 10.9037 9.40575 10.7368 9.46937C10.6525 9.50232 10.5776 9.55538 10.5186 9.62392C10.389 9.75049 10.3167 9.92456 10.3186 10.1057C10.3197 10.1925 10.335 10.2785 10.3641 10.3603C10.3984 10.4464 10.4512 10.524 10.5186 10.5876C10.6463 10.7155 10.8197 10.7874 11.0004 10.7876C11.0881 10.7868 11.1747 10.7683 11.255 10.733C11.3389 10.6988 11.416 10.6495 11.4822 10.5876C11.6069 10.4577 11.6783 10.2857 11.6822 10.1057C11.6821 9.92497 11.6101 9.75166 11.4822 9.62392C11.4179 9.55731 11.3406 9.5047 11.255 9.46937Z"
                      fill="#0C111D"
                    />
                    <path
                      d="M15.1186 9.60573C14.9214 9.41277 14.6284 9.35559 14.3732 9.46028C14.291 9.49797 14.2145 9.54693 14.1459 9.60573C14.0864 9.67383 14.0373 9.75044 14.0004 9.83301C13.983 9.91696 13.983 10.0036 14.0004 10.0876C13.9823 10.1745 13.9823 10.2642 14.0004 10.3512C14.0349 10.4323 14.0843 10.5063 14.1459 10.5694C14.2095 10.6368 14.287 10.6896 14.3732 10.7239C14.4563 10.7602 14.5461 10.7787 14.6368 10.7785C14.7246 10.7788 14.8114 10.7602 14.8913 10.7239C14.9774 10.6896 15.055 10.6368 15.1186 10.5694C15.1802 10.5063 15.2296 10.4323 15.2641 10.3512C15.2931 10.2663 15.3085 10.1773 15.3095 10.0876C15.3077 10.0009 15.2923 9.91499 15.2641 9.83301C15.2243 9.75202 15.1755 9.67578 15.1186 9.60573Z"
                      fill="#0C111D"
                    />
                  </svg>
                )}
                {type === "Insights" && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 40 40"
                    fill="none"
                  >
                    <path
                      d="M19.9151 14.1663C19.2285 14.1574 18.6741 13.6029 18.6651 12.9163V4.58301C18.6651 3.89265 19.2247 3.33301 19.9151 3.33301C20.6055 3.33301 21.1651 3.89265 21.1651 4.58301V12.9163C21.1561 13.6029 20.6017 14.1574 19.9151 14.1663Z"
                      fill="#0C111D"
                    />
                    <path
                      d="M19.9151 37.4997C19.2285 37.4907 18.6741 36.9363 18.6651 36.2497V27.9163C18.6651 27.226 19.2247 26.6663 19.9151 26.6663C20.6055 26.6663 21.1651 27.226 21.1651 27.9163V36.2497C21.1561 36.9363 20.6017 37.4907 19.9151 37.4997Z"
                      fill="#0C111D"
                    />
                    <path
                      d="M15.6151 15.5163C15.2039 15.5194 14.8175 15.3199 14.5818 14.983L9.8151 8.16634C9.62136 7.89498 9.54407 7.55746 9.6004 7.22882C9.65674 6.90019 9.84204 6.60768 10.1151 6.41634C10.6697 6.07934 11.3894 6.21607 11.7818 6.73301L16.5818 13.5497C16.8615 13.9277 16.9019 14.4319 16.6859 14.8496C16.47 15.2674 16.0353 15.526 15.5651 15.5163H15.6151Z"
                      fill="#0C111D"
                    />
                    <path
                      d="M28.9984 34.633C28.5872 34.636 28.2009 34.4366 27.9651 34.0997L23.2484 27.283C22.8526 26.7169 22.9907 25.9371 23.5568 25.5413C24.1229 25.1455 24.9026 25.2836 25.2984 25.8497L30.0651 32.6663C30.3448 33.0444 30.3852 33.5486 30.1693 33.9663C29.9533 34.384 29.5186 34.6426 29.0484 34.633H28.9984Z"
                      fill="#0C111D"
                    />
                    <path
                      d="M12.8651 19.0997C12.7216 19.1227 12.5753 19.1227 12.4318 19.0997L4.6151 16.183C4.29015 16.0673 4.02643 15.8241 3.88488 15.5095C3.74333 15.195 3.73619 14.8363 3.8651 14.5163C3.976 14.1882 4.21976 13.9218 4.5368 13.7823C4.85384 13.6428 5.21492 13.6431 5.53177 13.783L13.2484 16.683C13.808 16.8981 14.1361 17.4803 14.0303 18.0704C13.9245 18.6605 13.4146 19.0924 12.8151 19.0997H12.8651Z"
                      fill="#0C111D"
                    />
                    <path
                      d="M34.7984 27.083C34.6504 27.0795 34.5039 27.0513 34.3651 26.9997L26.5818 24.1497C26.2538 24.038 25.9868 23.7953 25.8446 23.4793C25.7025 23.1633 25.6978 22.8025 25.8318 22.483C25.9475 22.1581 26.1907 21.8943 26.5052 21.7528C26.8198 21.6112 27.1785 21.6041 27.4984 21.733L35.3151 24.583C35.6401 24.6987 35.9038 24.9419 36.0453 25.2565C36.1869 25.5711 36.194 25.9297 36.0651 26.2497C35.8788 26.781 35.3602 27.1222 34.7984 27.083Z"
                      fill="#0C111D"
                    />
                    <path
                      d="M4.6151 25.7663C3.97441 25.7826 3.42526 25.3116 3.34365 24.6759C3.26204 24.0402 3.67443 23.4458 4.29843 23.2997L12.3484 21.1497C12.6689 21.0631 13.0107 21.1076 13.2983 21.2733C13.586 21.439 13.7959 21.7124 13.8818 22.033C14.0523 22.6992 13.6603 23.3797 12.9984 23.5663L4.9151 25.7163C4.81776 25.7462 4.71687 25.763 4.6151 25.7663Z"
                      fill="#0C111D"
                    />
                    <path
                      d="M27.1651 19.733C26.5959 19.7306 26.0982 19.3488 25.9484 18.7997C25.8619 18.4792 25.9064 18.1374 26.0721 17.8498C26.2378 17.5622 26.5111 17.3522 26.8318 17.2663L34.9151 15.1163C35.5824 14.9368 36.2689 15.3323 36.4484 15.9997C36.6279 16.667 36.2324 17.3535 35.5651 17.533L27.5151 19.683C27.4021 19.7193 27.2838 19.7362 27.1651 19.733Z"
                      fill="#0C111D"
                    />
                    <path
                      d="M9.73177 33.7497C9.24869 33.7456 8.81119 33.4636 8.60806 33.0253C8.40494 32.587 8.47257 32.0709 8.78177 31.6997L14.1318 25.4163C14.5859 24.9022 15.3641 24.8361 15.8984 25.2663C16.1535 25.4802 16.3127 25.7869 16.3409 26.1185C16.369 26.4501 16.2638 26.7793 16.0484 27.033L10.6984 33.3497C10.4515 33.6198 10.0974 33.7664 9.73177 33.7497Z"
                      fill="#0C111D"
                    />
                  </svg>
                )}
                <p className="challengetypetext">{type}</p>
              </div>
              <button className="PreviewCompanyDashboardButton border-0">
                Start
              </button>{" "}
            </div>
          </div>
        </div>
      )}
      <div className="" style={{ marginTop: "2.5rem" }}>
        <p className="nextstepsright">Quick Tips</p>
        <p className=" tipstext" style={{ marginTop: "1.25rem" }}>
          {step === 1 && "Tips for challenge type selection:"}
          {step === 2 && "Tips for good short descriptions:"}
          {step === 3 && "Select the right skills"}
          {step === 4 && "Tips for good challenge descriptions:"}
        </p>
        <p className="tipsundertext" style={{ marginTop: "1rem" }}>
          {step === 1 && (
            <>
              Publish your first challenge as a<span> Fast-Track</span> or{" "}
              <span>Virtual Experience</span> challenge for maximum talent
              reach.
              <br />
              <br />
              Once you have sparked interest of students who feel that they have
              both, enjoyed and mastered their first challenge, it is more
              likely that they will want to know more about the everyday-tasks.
              <br />
              <br />
              To showcase the daily work routine at your company use{" "}
              <span>Insights</span> in a second step.
            </>
          )}
          {step === 2 && (
            <>
              Explain what the student will be working on in the challenge as
              well as key learnings.
              <br />
              <br />
              Examples:
              <br />
              <br />
              “Advise your client, on a suitable M&A target to expand their
              operations in Asia as a junior Investment Banker.”
              <br />
              <br />
              “Apply your data analytics & visualization skills to advise a
              social media client on their content creation strategy.”
              <br />
              <br />
              “Generate ideas to help a fictional luxury watch company increase
              sales revenue and learn how to XYZ.”
              <br />
              <br />
              “Advise our clients on how to approach patent litigation as part
              of our Intellectual Property Litigation team.”
            </>
          )}
          {step === 3 && (
            <>
              Select relevant skills which students will expand on in this
              challenge. Use a good mix of conceptual skills and technical
              skills.
              <br />
              <br />
              Students will gather <span>skill-points</span> for each completed
              challenge to better understand their strengths and interests.
            </>
          )}
          {step === 4 && (
            <>
              Give some more context about the challenge such as the point of
              view the challenge, e.g. “step into the shoes of a....” as well as
              how it will benefit participants.
            </>
          )}
        </p>
        {/* <p className="mt-3 tipstext">Tips for good job titles :</p>
        <ul>
          <li className="tipsundertext mt-2">
            Spell out words instead of using abbreviations (“ Senior” instead of
            “Sr”).
          </li>
          <li className="tipsundertext">Avoid using all caps.</li>
          <li className="tipsundertext">Avoid number or special characters.</li>
          <li className="tipsundertext">Keep it concise at 2-5 words.</li>
        </ul> */}
      </div>
    </div>
  );
};
export default Right;
