import React from "react";
import Intercom from "@intercom/messenger-js-sdk";

export default function Intercoms({ user }) {
  Intercom({
    app_id: "alsy6lni",
    user_id: user?.uniqueId, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
    name: `${user?.firstName} ${user?.lastName}`, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
    email: user?.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
    created_at: user?.createdAt, // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
  });

  return null;
}
