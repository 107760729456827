import React, { useRef } from "react";
import AnimatedBeam from "../../components/magicui/animated-beam";
import place from "../../assets/images/grop2.png";
import place2 from "../../assets/images/grop22.png";
import logo from "../../assets/images/logo.png";
import to2 from "../../assets/images/grop3.png";
import bcg from "../../assets/images/logosCOmpany/bcg.png";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const Circle = React.forwardRef(({ children, className }, ref) => {
  return (
    <div
      ref={ref}
      className={`d-flex align-items-center justify-content-center ${className}`}
      style={{ zIndex: 10 }}
    >
      {children}
    </div>
  );
});

const Icons = {
  user: () => (
    <div style={{ maxHeight: "276px", maxWidth: "285px" }}>
      <img src={place} alt="place" className="img-fluid square-image" />
    </div>
  ),
  user2: () => (
    <div style={{ maxHeight: "276px", maxWidth: "285px" }}>
      <img src={place2} alt="place" className="img-fluid square-image" />
    </div>
  ),
  logo: () => (
    <img
      src={logo}
      alt="logo"
      className="img-fluid shadowa"
      style={{
        minWidth: "57px",
        maxWidth: "57px",
        minHeight: "57px",
        maxHeight: "57px",
      }}
    />
  ),
  end: () => (
    <div style={{ maxHeight: "235px", maxWidth: "302px" }}>
      <img src={to2} alt="to" className="img-fluid" />
    </div>
  ),
};

const AnimatedBeamDemo = ({ isNonMobile700 }) => {
  const containerRef = useRef(null);
  const div1Ref = useRef(null);
  const div2Ref = useRef(null);
  const div3Ref = useRef(null);

  return (
    <div
      className="position-relative d-flex w-100 max-w-890 mx-auto overflow-hidden testingscale"
      ref={containerRef}
    >
      <div className="d-flex w-100 flex-column justify-content-between gap-5">
        <div
          className={`d-flex ${
            isNonMobile700 ? "flex-row" : "flex-column"
          } justify-content-between gap-5`}
        >
          <Circle ref={div1Ref}>
            {isNonMobile700 ? <Icons.user /> : <Icons.user2 />}
          </Circle>

          <Circle ref={div2Ref}>
            <Icons.logo />
          </Circle>
          <Circle ref={div3Ref}>
            <Icons.end />
          </Circle>
        </div>
      </div>

      <AnimatedBeam
        duration={3}
        containerRef={containerRef}
        startXOffset={isNonMobile700 ? 100 : 0}
        startYOffset={isNonMobile700 ? 50 : 20}
        fromRef={isNonMobile700 ? div1Ref : div2Ref}
        toRef={isNonMobile700 ? div2Ref : div1Ref}
      />
      <AnimatedBeam
        duration={3}
        containerRef={containerRef}
        fromRef={div2Ref}
        toRef={div3Ref}
      />
    </div>
  );
};

const NewThirdSection = ({ isNonMobile450, isNonMobile700, navigate }) => {
  return (
    <div
      className="d-flex"
      style={{ justifyContent: "center", position: "relative" }}
    >
      <div className="headerCompany">
        <div className="center">
          <div className="center flex-column" style={{ maxWidth: "700px" }}>
            <p className="sparkInterest text-center">
              Candidate <span>Experience</span>
            </p>
            <p
              className="newheadingtextthirdsection text-center"
              style={{ marginTop: "1rem" }}
            >
              What is <span>varyance</span>?
            </p>
            <p
              className="gray95014400 text-center"
              style={{ marginTop: "1rem", fontSize: "16px" }}
            >
              Varyance is a{" "}
              <span className="gray95016500">
                candidate engagement platform
              </span>{" "}
              that equips top employers with the right tools to connect with
              early talent at scale.
            </p>
          </div>
        </div>

        <div className="center" style={{ marginTop: "3rem" }}>
          <AnimatedBeamDemo isNonMobile700={isNonMobile700} />
        </div>
        <div className="center">
          <p
            className="text-center gray95014400"
            style={{
              marginTop: "2.5rem",
              // marginBottom: "2.5rem",
              maxWidth: "700px",
              fontSize: "16px",
            }}
          >
            Our solution enhances your{" "}
            <span className="gray95016500">employer brand</span> and expands
            your <span className="gray95016500">talent pool</span> by delivering
            what candidates value most: Genuine insights into their future
            careers!
          </p>
        </div>
        <div className="center" style={{ marginTop: "2.5rem" }}>
          <div
            className="d-flex flex-wrap align-items-center"
            style={{ gap: "1.25rem" }}
          >
            <button
              className="continuebutton"
              onClick={() => {
                navigate("/contact");
                window.scrollTo({ top: 0, behavior: "instant" });
              }}
            >
              Get a demo
            </button>
            <button
              className="backbutton"
              onClick={() => {
                navigate("/company/register");
                window.scrollTo({ top: 0, behavior: "instant" });
              }}
            >
              Start Onboarding (Free)
            </button>
          </div>
          {/* <p
            className="pink16 hoverdarkred"
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/company/register");
            }}
          >
            Start Onboarding <ArrowForwardIcon />
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default NewThirdSection;
