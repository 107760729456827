import "./styles.scss";
import React, { useEffect, useRef } from "react";
import Step1 from "./RegisterationSteps/step1";

import { IoReturnDownBackSharp } from "react-icons/io5";
import Enter from "../../assets/images/icons/Enter.png";
import NameCard from "../Card/Namecard";
import logo from "../../assets/images/logo.png";
import Step2 from "./RegisterationSteps/step2";
import { useState } from "react";
import { motion, useAnimate, AnimatePresence } from "framer-motion";
import { IoIosArrowBack } from "react-icons/io";
import { toast } from "react-toastify";
import { useMediaQuery } from "@mui/material";
import bg1 from "../../assets/images/bg3.png";
import bg2 from "../../assets/images/otp_bg.png";
import Step3 from "./RegisterationSteps/step3";
import Step4 from "./RegisterationSteps/step4";
import Step5 from "./RegisterationSteps/step5";
import Step6 from "./RegisterationSteps/step6";
import Step7 from "./RegisterationSteps/step7";
import Step8 from "./RegisterationSteps/step8";
import France from "../../assets/images/countries/France.png";
import Switzerland from "../../assets/images/countries/Switzerland.png";
import Germany from "../../assets/images/countries/Germany.png";
import UnitedKingdom from "../../assets/images/countries/United Kingdom.png";
import emailjs from "@emailjs/browser";
import { setLogin } from "../../state";
import { useDispatch } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function StudentRegister() {
  const [currentStep, setCurrentStep] = useState(1);
  const [isNext, setIsNext] = useState(false);
  const isNonMobile = useMediaQuery("(min-width:767px)");
  const isNonMobile1024 = useMediaQuery("(min-width:1024px)");
  const isNonMobile450 = useMediaQuery("(min-width:450px)");
  const isNon = useMediaQuery("(min-width:900px)");
  const navigate = useNavigate();
  const uni = useSelector((state) => state.Uni);
  let lastStep = 6;
  let width = Math.floor(currentStep * (100 / lastStep));
  const [scope, animate] = useAnimate();
  // formData
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [EmailAddress, setEmailAddress] = useState("");
  const [otp, setOtp] = useState("");
  const [optform, setOtpForm] = useState("");
  const [selectedCards, setSelectedCards] = useState([]);

  const form = useRef();

  const sendEmail = (ad) => {
    // Replace with the actual recipient's email
    const payload = {
      code: ad,
      to_email: EmailAddress,
      to_name: firstName + " " + LastName, // Use the static recipient for testing
    };

    console.log("Recipient's Email Address:", EmailAddress);

    emailjs
      .send("service_08a640e", "template_u5ommri", payload, "FCKBh2iU92kGQhhAA")
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const Countries = [
    {
      icon: France,
      name: "France",
    },

    {
      icon: Germany,
      name: "Germany",
    },
    {
      icon: UnitedKingdom,
      name: "United Kingdom",
    },
    {
      icon: Switzerland,
      name: "Switzerland",
    },
  ];

  const generateOtp = async () => {
    const randomOtp = Math.floor(1000 + Math.random() * 9000);
    setOtp(randomOtp.toString());

    // Wait for a short delay to simulate asynchronous behavior
    await new Promise((resolve) => setTimeout(resolve, 200));

    // Now send the email and show the alert
    sendEmail(randomOtp.toString());
  };

  const CreateUser = async (payload) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/users`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // You might need additional headers like authorization token
          },
          body: JSON.stringify(payload),
        }
      );

      // Check if the request was successful (status code 2xx)
      if (response.ok) {
        const data = await response.json();
        return { status: response.status, data };
      } else {
        // If the request was not successful, handle the error
        const errorData = await response.json();
        return { status: response.status, errorData };
      }
    } catch (error) {
      // Handle any other errors that might occur during the request
      console.error("Error during createUser request:", error);
      return { status: 500, error: "Internal Server Error" };
    }
  };

  const handleSubmit = async (code) => {
    console.log("code", code);
    const storedFirstName = localStorage.getItem("firstName");
    const storedLastName = localStorage.getItem("lastName");
    const storedEmail = localStorage.getItem("emailAddress");
    const storedInterests = JSON.parse(localStorage.getItem("interests")) || [];
    const selectedNetworks =
      JSON.parse(localStorage.getItem("selectedNetworks")) || [];

    const payload = {
      firstName: storedFirstName,
      lastName: storedLastName,
      email: storedEmail,
      code: code,
      interests: storedInterests,
      network: selectedNetworks,
      groups: [1, 2, 3],
    };
    if (currentStep === 4) {
      if (!EmailAddress?.length) {
        toast.error("Please Enter Your University Email", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return;
      }
    }
    const response = await CreateUser(payload);
    console.log("response", response);
    if (response.status === 200) {
      setIsNext(true);
    } else {
      // const error = response;
      setIsNext(false);
      toast.error("Please enter your valid domain university email", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const UserVerify = async (payload) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/verify-user`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // You might need additional headers like authorization token
          },
          body: JSON.stringify(payload),
        }
      );

      // Check if the request was successful (status code 2xx)
      if (response.ok) {
        const data = await response.json();
        return { status: response.status, data };
      } else {
        // If the request was not successful, handle the error
        const errorData = await response.json();
        return { status: response.status, errorData };
      }
    } catch (error) {
      // Handle any other errors that might occur during the request
      console.error("Error during createUser request:", error);
      return { status: 500, error: "Internal Server Error" };
    }
  };

  const handleUserVerify = async () => {
    const storedEmail = localStorage.getItem("emailAddress");
    console.log("handleUserVerify code", otp);

    const payload = {
      email: storedEmail,
      code: otp,
    };
    const response = await UserVerify(payload);
    console.log("response", response);
    if (response.status === 200) {
    } else {
      const error = response;
      console.log(error);
    }
  };

  const NextStep = () => {
    if (currentStep === lastStep) {
      return;
    }
    setCurrentStep((prev) => prev + 1);
  };
  const BackSetp = () => {
    if (currentStep === 1) {
      navigate("/");
      return;
    }
    setCurrentStep((prev) => prev - 1);
  };
  const checkStages = async (e) => {
    e.preventDefault();
    if (currentStep === 1) {
      if (!selectedItem?.length) {
        toast.error("Please Select 1 Interest", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",

          style: {
            backgroundColor: "#F97066",
            fontFamily: "Poppins",
          },
        });
        return;
      }
      NextStep();
      return;
    }
    if (currentStep === 2) {
      if (!firstName?.length) {
        toast.error("Please Enter Your First Name", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          style: {
            backgroundColor: "#F97066",
            fontFamily: "Poppins, sans-serif",
          },
        });
        return;
      }
      NextStep();
      return;
    }
    if (currentStep === 3) {
      if (!LastName?.length) {
        toast.error("Please Enter Your Last Name", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          style: {
            backgroundColor: "#F97066",
            fontFamily: "Poppins, sans-serif",
          },
        });
        return;
      }
      NextStep();
      return;
    }
    if (currentStep === 4) {
      if (!EmailAddress?.length) {
        toast.error("Please Enter Your University Email", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          style: {
            backgroundColor: "#F97066",
            fontFamily: "Poppins",
          },
        });
        return;
      }
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(EmailAddress)) {
        toast.error("Please Enter a Valid Email Address", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          style: {
            backgroundColor: "#F97066",
            fontFamily: "Poppins, sans-serif",
          },
        });
        return;
      }

      const publicEmailDomainPattern =
        /@(gmail\.com|yahoo\.com|hotmail\.com|outlook\.com|aol\.com|protonmail\.com|mail\.com)$/;

      if (publicEmailDomainPattern.test(EmailAddress)) {
        toast.error("Please use a valid university email address", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          style: {
            backgroundColor: "#F97066",
            fontFamily: "Poppins, sans-serif",
          },
        });
        return;
      } else {
        await generateOtp();
      }

      if (isNext) {
        NextStep();
      }
      // Call generateOtp before handleSubmit
      // await handleSubmit();
      NextStep();

      return;
    }
    // NextStep();

    if (currentStep === 5) {
      if (!optform.length) {
        toast.error("Please Enter  OTP", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        return;
      }
      window.alert(otp);
      if (optform !== otp) {
        toast.error("Please Enter correct OTP", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        return;
      } else {
        const userData = {
          firstName,
          LastName,
          EmailAddress,
          uni,
          selectedItem,
          selectedCards,
        };

        axios
          .post(`${process.env.REACT_APP_BACKEND_URL}/newUser`, userData)
          .then((res) => {
            if (res.data.msg === "matched") {
              // toast.success("Login SUCCESS", {
              //   position: "top-right",
              //   autoClose: 5000,
              //   hideProgressBar: false,
              //   closeOnClick: true,
              //   pauseOnHover: true,
              //   draggable: true,
              //   progress: undefined,
              //   theme: "colored",
              // });
              dispatch(setLogin({ user: res.data.newUser }));
              navigate("student/dashboard");
            } else if (res.data === "unmatched") {
              toast.error("Please Enter  Correct University Domain", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              BackSetp();
            } else if (res.data === "Pending") {
              toast.success("PENDING Request", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            } else if (res.data === "Already Registered") {
              toast.error("Email Already Registered", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              BackSetp();
            }
          })
          .catch((err) => {
            console.log(err.message);
          });
      }

      // await handleUserVerify();
      // navigate("/success");
      return;
    }
    NextStep();
  };

  useEffect(() => {
    if (scope?.current) {
      animate(scope?.current, { width: `${width}%` }, { duration: 0.3 });
    }
  }, [currentStep]);
  return (
    <div id="StudentRegister127">
      <motion.div
        ref={scope}
        className="ProgressBarForm"
        style={{
          width: `${width}%`,
        }}
      ></motion.div>
      <div
        className="wrapper d-flex"
        style={{
          backgroundImage: `url(${bg1})`,
          minHeight: "100vh",
          justifyContent: "space-between",
          flexDirection: "column",
        }}
      >
        <div className="marginsleftrightCompany">
          <div className="d-flex" style={{ justifyContent: "center" }}>
            <div
              className="headerCompany d-flex"
              style={{ justifyContent: "space-between", marginTop: "2.5rem" }}
            >
              <div className="logoCompany">
                <img
                  src={logo}
                  alt="logo"
                  height="36px"
                  width="36px"
                  onClick={() => navigate("/")}
                  style={{ cursor: "pointer" }}
                />
              </div>

              <button
                className="newsupportbutton supportbuttontext btn border-0 d-flex"
                style={{
                  alignItems: "center",
                  gap: "0.5rem",
                }}
                onClick={() => navigate("/support")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M9.83021 6.69935C9.51061 6.73619 9.2694 7.0068 9.2694 7.32852C9.2694 7.65024 9.51061 7.92084 9.83021 7.95768C9.99813 7.96001 10.1597 7.89376 10.2777 7.77423C10.3957 7.65469 10.4598 7.49222 10.4552 7.32435C10.4507 6.98105 10.1735 6.70384 9.83021 6.69935Z"
                    fill="#101828"
                  />
                  <path
                    d="M9.83021 9.09935C9.66376 9.09708 9.50347 9.1622 9.38577 9.27991C9.26806 9.39761 9.20294 9.5579 9.20521 9.72435V12.3243C9.20521 12.6695 9.48503 12.9493 9.83021 12.9493C10.1754 12.9493 10.4552 12.6695 10.4552 12.3243V9.74102C10.4597 9.57239 10.3959 9.4091 10.2781 9.28826C10.1604 9.16742 9.9989 9.09929 9.83021 9.09935Z"
                    fill="#101828"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.83021 1.66602C5.32639 1.67061 1.67647 5.32053 1.67188 9.82435C1.67188 14.3301 5.32449 17.9827 9.83021 17.9827C14.3359 17.9827 17.9885 14.3301 17.9885 9.82435C17.9839 5.32053 14.334 1.67061 9.83021 1.66602ZM9.83021 16.7327C6.01484 16.7327 2.92188 13.6397 2.92188 9.82435C2.92188 6.00898 6.01484 2.91602 9.83021 2.91602C13.6456 2.91602 16.7385 6.00898 16.7385 9.82435C16.7339 13.6378 13.6437 16.7281 9.83021 16.7327Z"
                    fill="#101828"
                  />
                </svg>
                Support
              </button>
            </div>
          </div>
        </div>
        <div className="marginsleftrightCompany ">
          <div>
            <div
              className="d-flex"
              style={{
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <div className="headerCompany">
                <form
                  onSubmit={(e) => checkStages(e)}
                  className="d-flex justify-content-center"
                  style={{ width: "100%" }}
                  // style={isNon ? { minHeight: "48vh" } : {}}
                >
                  <div
                    className={`${
                      (currentStep === 2 ||
                        currentStep === 3 ||
                        currentStep === 4) &&
                      "d-flex"
                    }`}
                    style={{ width: "100%" }}
                  >
                    <div
                      className={`${
                        (currentStep === 2 ||
                          currentStep === 3 ||
                          currentStep === 4) &&
                        isNonMobile1024
                          ? "col-md-6"
                          : "col-md-12"
                      }`}
                    >
                      {isNonMobile && (
                        <div
                          className={`backButton d-flex ${
                            currentStep === 7 && "p-2"
                          }`}
                        >
                          {currentStep !== 7 && (
                            <p
                              className=" BackbuttonFONT text-start "
                              onClick={BackSetp}
                            >
                              <IoIosArrowBack size={20} />
                              Back
                            </p>
                          )}
                        </div>
                      )}
                      <div
                        className="choices_wrapper "
                        style={
                          isNon
                            ? {
                                // minHeight: "15.25rem",
                                minHeight: "43.57142857143vh",
                                marginTop: "1rem",
                              }
                            : {}
                        }
                      >
                        {currentStep === 1 ? (
                          <Step1
                            selectedItem={selectedItem}
                            onEnterPress={checkStages}
                            setSelectedItem={setSelectedItem}
                          />
                        ) : currentStep === 2 ? (
                          <Step2
                            firstName={firstName}
                            Countries={Countries}
                            setFirstName={setFirstName}
                            selectedItem={selectedItem}
                          />
                        ) : currentStep === 3 ? (
                          <Step3
                            firstName={firstName}
                            LastName={LastName}
                            setLastName={setLastName}
                            selectedItem={selectedItem}
                          />
                        ) : // currentStep === 4 ? (
                        //   <Step4
                        //     selectedCards={selectedCards}
                        //     setSelectedCards={setSelectedCards}
                        //     onEnterPress={checkStages}
                        //     selectedItem={selectedItem}
                        //   />
                        // ) :
                        //  :
                        // currentStep === 5 ? (
                        //   <Step5 onEnterPress={checkStages} />
                        // )
                        currentStep === 4 ? (
                          <>
                            <Step6
                              firstName={firstName}
                              LastName={LastName}
                              setEmailAddress={setEmailAddress}
                              EmailAddress={EmailAddress}
                              handleSubmit={handleSubmit}
                              selectedItem={selectedItem}
                            />
                          </>
                        ) : currentStep === 5 ? (
                          <Step7
                            EmailAddress={EmailAddress}
                            optform={optform}
                            setOtpForm={setOtpForm}
                            generateOtp={generateOtp}
                          />
                        ) : currentStep === 6 ? (
                          <Step8 EmailAddress={EmailAddress} />
                        ) : null}
                        {(currentStep === 2 ||
                          currentStep === 3 ||
                          currentStep === 4) &&
                          !isNonMobile1024 && (
                            <div className="">
                              <AnimatePresence>
                                <motion.div
                                  id="Step2Ki33"
                                  initial={{
                                    opacity: 0.2,
                                    scale: 0.2,
                                    x: "50%",
                                  }}
                                  animate={{ opacity: 1, scale: 1, x: 0 }}
                                  exit={{ opacity: 0.2, x: "-100%" }}
                                  transition={{ duration: 0.5 }}
                                >
                                  <NameCard
                                    Countries={Countries}
                                    firstName={firstName}
                                    selectedItem={selectedItem}
                                    LastName={LastName}
                                  />
                                </motion.div>
                              </AnimatePresence>
                            </div>
                          )}
                      </div>
                      <div
                        className="d-flex"
                        style={{
                          justifyContent: "center",
                        }}
                      >
                        <div
                          className="headerCompany"
                          style={{
                            //  height: "6.25rem",
                            marginTop: isNon ? "" : "1.5rem",
                          }}
                        >
                          <div className=" ">
                            <div className="choices_wrapper">
                              <div
                                className="action_Btn "
                                // style={{ marginTop: "1.5rem" }}
                              >
                                {!isNonMobile && (
                                  <div
                                    className="d-flex "
                                    style={{ alignItems: "center" }}
                                  >
                                    {currentStep !== 7 && (
                                      <p
                                        onClick={BackSetp}
                                        className="BackbuttonFONT btn d-flex"
                                        style={{ padding: "10px 20px" }}
                                      >
                                        <IoIosArrowBack size={16} /> {"    "}
                                        Back
                                      </p>
                                    )}
                                  </div>
                                )}
                                {currentStep !== 7 && (
                                  <>
                                    <button
                                      className="primary_btn cutsPdn"
                                      onClick={checkStages}
                                      type="submit"
                                      style={{
                                        width: !isNonMobile ? "245px" : "",
                                      }}
                                    >
                                      Next
                                    </button>
                                    {isNonMobile1024 && (
                                      <div
                                        className="Next_Info"
                                        style={{
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                          height: "24px",
                                          width: "126px",
                                          display: "flex",
                                          gap: "10px",
                                        }}
                                      >
                                        {" "}
                                        <div className="entericon">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="21"
                                            viewBox="0 0 20 21"
                                            fill="none"
                                          >
                                            <path
                                              d="M12.3 4.48334H5.69164C5.34646 4.48334 5.06664 4.76316 5.06664 5.10834C5.06664 5.45352 5.34646 5.73334 5.69164 5.73334H12.2583C13.9324 5.77874 15.2605 7.15875 15.2416 8.83334V10.6417C15.2416 12.3445 13.8612 13.725 12.1583 13.725H6.24997L7.62497 12.3333C7.7441 12.2173 7.81128 12.058 7.81128 11.8917C7.81128 11.7253 7.7441 11.5661 7.62497 11.45C7.38096 11.2063 6.98565 11.2063 6.74164 11.45L4.29997 13.8917C4.24348 13.9495 4.19826 14.0173 4.16664 14.0917C4.10852 14.2475 4.10852 14.4191 4.16664 14.575C4.19826 14.6494 4.24348 14.7172 4.29997 14.775L6.74164 17.2167C6.85821 17.3347 7.01742 17.4008 7.18331 17.4C7.34938 17.4017 7.50895 17.3355 7.62497 17.2167C7.7441 17.1006 7.81128 16.9413 7.81128 16.775C7.81128 16.6087 7.7441 16.4494 7.62497 16.3333L6.24997 14.9583H12.1583C14.5515 14.9583 16.4916 13.0182 16.4916 10.625V8.83334C16.5152 6.48257 14.65 4.54688 12.3 4.48334Z"
                                              fill="white"
                                            />
                                          </svg>
                                        </div>{" "}
                                        or Press Enter
                                      </div>
                                    )}
                                  </>
                                )}
                              </div>{" "}
                              {/* {currentStep === 1 && (
                    <div
                      className="testingtopright d-flex"
                      style={{
                        marginTop: "30px",
                        height: "26px",
                        justifyContent: isNonMobile450 ? "start" : "center",
                      }}
                    >
                      <div>
                        <p className="text-start alreadyacc">
                          Already have an account?{" "}
                          {isNonMobile450 && (
                            <span onClick={() => navigate("/login")}>
                              Log in here
                            </span>
                          )}
                        </p>
                        {!isNonMobile450 && (
                          <p
                            className="alreadyacc"
                            onClick={() => navigate("/login")}
                            style={{ marginTop: "0.25rem" }}
                          >
                            <span> Log in here</span>
                          </p>
                        )}
                      </div>
                    </div>
                  )} */}
                            </div>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                    {(currentStep === 2 ||
                      currentStep === 3 ||
                      currentStep === 4) &&
                      isNonMobile1024 && (
                        <div className="" style={{ width: "100%" }}>
                          <AnimatePresence>
                            <motion.div
                              id="Step2Ki33"
                              initial={{ opacity: 0.2, scale: 0.2, x: "50%" }}
                              animate={{ opacity: 1, scale: 1, x: 0 }}
                              exit={{ opacity: 0.2, x: "-100%" }}
                              transition={{ duration: 0.5 }}
                            >
                              <NameCard
                                LastName={LastName}
                                Countries={Countries}
                                firstName={firstName}
                                selectedItem={selectedItem}
                              />
                            </motion.div>
                          </AnimatePresence>
                        </div>
                      )}
                  </div>
                </form>
              </div>
            </div>
          </div>
          {/* <div className="cntentWrapper">
          <form
            onSubmit={(e) => checkStages(e)}
            className="d-flex justify-content-center"
            style={isNon ? { minHeight: "48vh" } : {}}
          >
            <div>
              <div className={currentStep === 5 ? " mt-5" : " mt-5"}>
                {" "}
                {isNonMobile && (
                  <div className=" margintop">
                    <div className="choices_wrapper ">
                      <div
                        className={`backButton d-flex ${
                          currentStep === 7 && "p-2"
                        }`}
                        onClick={BackSetp}
                      >
                        {currentStep !== 7 && (
                          <p className="BackbuttonFONT text-start ">
                            <IoIosArrowBack size={20} /> {"    "}
                            Back
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                <div
                  className="choices_wrapper "
                  style={isNon ? { minHeight: "48vh", marginTop: "2rem" } : {}}
                >
                  {currentStep === 1 ? (
                    <Step1
                      selectedItem={selectedItem}
                      onEnterPress={checkStages}
                      setSelectedItem={setSelectedItem}
                    />
                  ) : currentStep === 2 ? (
                    <Step2
                      firstName={firstName}
                      setFirstName={setFirstName}
                      selectedItem={selectedItem}
                    />
                  ) : currentStep === 3 ? (
                    <Step3
                      firstName={firstName}
                      LastName={LastName}
                      setLastName={setLastName}
                      selectedItem={selectedItem}
                    />
                  ) : currentStep === 4 ? (
                    <Step4
                      selectedCards={selectedCards}
                      setSelectedCards={setSelectedCards}
                      onEnterPress={checkStages}
                      selectedItem={selectedItem}
                    />
                  ) : //  :
                  // currentStep === 5 ? (
                  //   <Step5 onEnterPress={checkStages} />
                  // )
                  currentStep === 5 ? (
                    <>
                      <Step6
                        firstName={firstName}
                        LastName={LastName}
                        setEmailAddress={setEmailAddress}
                        EmailAddress={EmailAddress}
                        handleSubmit={handleSubmit}
                        selectedItem={selectedItem}
                      />
                    </>
                  ) : currentStep === 6 ? (
                    <Step7
                      EmailAddress={EmailAddress}
                      optform={optform}
                      setOtpForm={setOtpForm}
                      generateOtp={generateOtp}
                    />
                  ) : currentStep === 7 ? (
                    <Step8 EmailAddress={EmailAddress} />
                  ) : null}
                </div>
              </div>
            </div>
          </form>
        </div> */}
        </div>{" "}
        <div
          className="marginsleftrightCompany"
          style={{ marginBottom: "0.5rem" }}
        >
          <div
            className="d-flex"
            style={{
              justifyContent: "center",
            }}
          >
            <div
              className="headerCompany d-flex"
              style={{
                justifyContent: isNonMobile ? "end" : "center",
              }}
            >
              {/* {isNonMobile && (
                <div
                  className="left d-flex"
                  style={{ justifyContent: "center", alignItems: "center" }}
                >
                  <p className="AllRightsReserved ">
                    &#169; All rights reserved
                  </p>
                </div>
              )} */}
              <div className="right">
                <button
                  className="footertextnew btn"
                  style={{ cursor: "pointer", textDecoration: "none" }}
                  onClick={() => window.open("/privacy", "_blank")}
                >
                  Privacy Policy
                </button>
                <button
                  className="footertextnew btn"
                  style={{ cursor: "pointer", textDecoration: "none" }}
                  onClick={() => window.open("/terms", "_blank")}
                >
                  Terms of Use
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentRegister;
