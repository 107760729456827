import { useState, useRef, useEffect } from "react";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
const Password = ({ password, setPassword }) => {
  const [showPassword, setShowPassword] = useState(false);

  const inputRef = useRef(null);
  useEffect(() => {
    // Focus on the input field when the component loads
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);
  return (
    <div>
      <p className="sectionheadingclamped" style={{ marginBottom: "1.5rem" }}>
        Set your password
      </p>
      <div className="position-relative col-md-6">
        <input
          ref={inputRef}
          value={password}
          type={showPassword ? "text" : "password"}
          onChange={(e) => setPassword(e.target.value)}
          className="rounded-3 newinputs mt-3 col-md-12 col-12 "
          style={{ marginTop: "14px" }}
        />
        <div
          className="position-absolute end-10 top-50 translate-middle-y"
          style={{
            right: "10px",
            cursor: "pointer",
            maxWidth: "440px",
          }}
          onClick={() => setShowPassword(!showPassword)}
        >
          {showPassword ? (
            <VisibilityOffOutlinedIcon />
          ) : (
            <RemoveRedEyeOutlinedIcon />
          )}
        </div>
      </div>
      <p className="sentanemail" style={{ marginTop: "0.5rem" }}>
        Must be at least 8 characters
      </p>
    </div>
  );
};

export default Password;
