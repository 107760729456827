import React from "react";

const Step6 = () => {
  return (
    <div>
      <p className="sectionheadingclamped" style={{ marginBottom: "1.5rem" }}>
        Almost there
      </p>
      <p className="sentanemail">
        Please bear with us. We will onboard your university in the next 24h.
      </p>
    </div>
  );
};

export default Step6;
