import React, { useEffect, useRef, useState } from "react";
import ReactCrop, {
  centerCrop,
  convertToPixelCrop,
  makeAspectCrop,
} from "react-image-crop";
import { toast } from "react-toastify";
import setCanvasPreview from "./setCanvasPreview";
const ImageCropper = ({ selectedFile, setcroppedImage, setChangeImage }) => {
  const [imgSrc, setImgSrc] = useState();

  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  useEffect(() => {
    const onSelect = () => {
      const file = selectedFile;
      if (!file) return;

      const reader = new FileReader();
      reader.addEventListener("load", () => {
        const imageUrl = reader.result?.toString() || "";

        setImgSrc(imageUrl);
      });
      reader.readAsDataURL(file);
    };
    onSelect();
  }, [selectedFile]);

  const [crop, setCrop] = useState();
  const minWidth = 150;

  const [aspect, setAspect] = useState(1);

  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;
    const cropWidthinpercent = (minWidth / width) * 100;
    const crop = makeAspectCrop(
      {
        unit: "%",
        width: cropWidthinpercent,
      },
      aspect,
      width,
      height
    );
    const centeredCrop = centerCrop(crop, width, height);
    setCrop(centeredCrop);
  };

  return (
    <>
      <ReactCrop
        crop={crop}
        keepSelection
        aspect={aspect}
        minWidth={minWidth}
        minHeight={minWidth}
        onChange={(pixelCrop, percentCrop) => setCrop(percentCrop)}
      >
        <img
          ref={imgRef}
          src={imgSrc}
          alt="Selected"
          className=""
          style={{ maxWidth: "70vw", maxHeight: "50vh", width: "100%" }}
          onLoad={onImageLoad}
        />
      </ReactCrop>
      <button
        className="continuebutton   "
        style={{ fontSize: "14px" }}
        onClick={() => {
          setCanvasPreview(
            imgRef.current,
            previewCanvasRef.current,
            convertToPixelCrop(
              crop,
              imgRef.current.width,
              imgRef.current.height
            )
          );
          const dataUrl = previewCanvasRef.current.toDataURL();
          setcroppedImage(dataUrl);
          setChangeImage(false);
        }}
      >
        Crop Image
      </button>
      {crop && (
        <canvas
          ref={previewCanvasRef}
          className="mt-4"
          style={{
            display: "none",
            border: "1px solid black",
            objectFit: "contain",
            width: 300,
            height: 150,
          }}
        />
      )}
    </>
  );
};

export default ImageCropper;
