import Slider from "react-slick";
import { useMediaQuery } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { useState } from "react";
import clock from "../../assets/images/clock.png";
import user from "../../assets/images/user.png";
import chart from "../../assets/images/icons/Chart.png";
import book from "../../assets/images/icons/Book.png";
import clip from "../../assets/images/icons/Clip.png";
import medal from "../../assets/images/icons/Medal.png";
import office from "../../assets/images/icons/office.png";
import video from "../../assets/images/icons/videoicon.png";
import file from "../../assets/images/File.png";
import virtual from "../../assets/images/virtualslide.svg";
import fast from "../../assets/images/fastslide.svg";
import insight from "../../assets/images/insightslide.svg";
const Step3 = ({ type, setType, setStep }) => {
  const isNonMobile = useMediaQuery("(min-width:1000px");
  const isNonMobile1200 = useMediaQuery("(min-width:1200px");
  const [popup, setPopup] = useState(false);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: true,
          arrows: false,
          centerMode: true,
          centerPadding: "8%",
        },
      },
    ],
  };

  const navigate = useNavigate();

  const typesofdata = [
    {
      type: "Fast-Track",
      text: `Create an assessment-style challenge by asking students <span class="black14" style="font-weight:600;">multiple-choice questions</span>. This allows students to prove their knowledge and better understand their performance relative to other students.`,
      img: fast,
      requirement: ["Webcam"],
      chips: [
        {
          name: "Time constrained",
          icon: clock,
        },
        {
          name: "Track student performance",
          icon: user,
        },
        {
          name: "Track relative performance",
          icon: chart,
        },
        {
          name: "Add ressources",
          icon: book,
        },
        {
          name: "Link job posts",
          icon: clip,
          pink: true,
        },
        {
          name: "Branded & graded certificate",
          icon: medal,
        },
      ],
    },
    {
      type: "Virtual Experience",
      text: `Create an immersive experience by a <span class="black14" style="font-weight:600;">point-of-view </span> styled challenge sequence in which students “walk” through your offices to receive the challenge brief from you and to work on tasks. Students get the full experience of their future work.`,
      img: virtual,
      requirement: ["VideoCam", "Phone camera", "Meta glasses"],
      chips: [
        {
          name: "Self-paced",
          icon: clock,
        },
        {
          name: "Self-assessed",
          icon: user,
        },
        {
          name: "Office tour",
          icon: office,
        },
        {
          name: "Video reply from students",
          icon: video,
        },
        {
          name: "Add ressources",
          icon: book,
        },
        {
          name: "Attach job posts",
          icon: clip,
        },
        {
          name: "Branded certificate",
          icon: medal,
        },
      ],
    },
    {
      type: "Insights",
      text: `Students gain insights by <span class="black14" style="font-weight:600;">self-assessed</span> challenges created by your team or current interns. Tasks such as “write an e-mail to client XYZ” or “prepare a presentation for..” help students to better understand the daily work routine.`,
      img: insight,
      requirement: ["VideoCam", "Phone camera", "Meta glasses"],
      chips: [
        {
          name: "Self-paced",
          icon: clock,
        },
        {
          name: "Self-assessed",
          icon: user,
        },
        {
          name: "Office tour",
          icon: office,
        },
        {
          name: "File uploads from students",
          icon: file,
        },
        {
          name: "Add ressources",
          icon: book,
        },
        {
          name: "Attach job posts",
          icon: clip,
        },
        {
          name: "Branded certificate",
          icon: medal,
        },
      ],
    },
  ];

  return (
    <div className="p-2" style={{ width: "100%", maxWidth: "100vw" }}>
      <div className="">
        <p className="BasicInforMationHeading ">Select type of challenge</p>

        <p className="UnderBasicHeading" style={{ marginTop: "0.25rem" }}>
          Learn about our challenge types and select the right one for this
          challenge.
        </p>
        <div style={{ marginTop: "2.5rem" }}>
          {isNonMobile ? (
            <div
              className=" d-flex "
              style={{ gap: "26px" }}
              onMouseEnter={() => setPopup(true)}
              onMouseLeave={() => setPopup(false)}
            >
              {popup && isNonMobile1200 && (
                <div style={{ position: "absolute" }}>
                  <div
                    style={{
                      position: "relative",
                      left: "-110%",
                      width: "220px",
                      marginTop: "3rem",
                    }}
                    className="tipscover"
                  >
                    <div style={{ position: "absolute", marginLeft: "12rem" }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                      >
                        <path
                          d="M26.25 12.8349C27.9167 13.7972 27.9167 16.2028 26.25 17.1651L11.25 25.8253C9.58333 26.7876 7.5 25.5848 7.5 23.6603L7.5 6.33974C7.5 4.41524 9.58333 3.21243 11.25 4.17468L26.25 12.8349Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <div className="tipsinner">
                      <p
                        className="characterslimittext"
                        style={{ fontWeight: "600" }}
                      >
                        Tips type selection:
                      </p>
                      <p
                        className="tipstextpopup"
                        style={{ marginTop: "1rem" }}
                      >
                        Publish your first challenge as a{" "}
                        <span>Fast-Track</span> or{" "}
                        <span>Virtual Experience</span> challenge for maximum
                        talent reach.
                        <br />
                        <br />
                        To showcase the daily work routine at your company use{" "}
                        <span>Insights</span> in a second step.
                      </p>
                    </div>
                  </div>
                </div>
              )}
              <div
                className={`rolecards  ${
                  type === "Fast-Track" ? "selectedrolecard" : ""
                }`}
                style={{
                  maxWidth: "318px",
                  width: "32%",
                  padding: "20px",
                  position: "relative",
                  zIndex: 2,
                }}
                onClick={() => setType("Fast-Track")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.68258 3.33301H30.3175C33.8241 3.33301 36.6667 6.17564 36.6667 9.68221V30.3171C36.6667 33.8237 33.8241 36.6663 30.3175 36.6663H9.68258C6.17601 36.6663 3.33337 33.8237 3.33337 30.3171V9.68221C3.33337 6.17564 6.17601 3.33301 9.68258 3.33301ZM30.3175 34.2854C32.5091 34.2854 34.2858 32.5087 34.2858 30.3171V9.68221C34.2858 7.49061 32.5091 5.71396 30.3175 5.71396H9.68258C7.49097 5.71396 5.71433 7.49061 5.71433 9.68221V30.3171C5.71433 32.5087 7.49097 34.2854 9.68258 34.2854H30.3175Z"
                    fill={type === "Fast-Track" ? "#E31B54" : "#000000"}
                  />
                  <path
                    d="M28.7302 14.8409H23.9683C23.3108 14.8409 22.7778 15.3739 22.7778 16.0314C22.7778 16.6889 23.3108 17.2219 23.9683 17.2219H25.4445L19.7937 22.8727L16.6191 19.6981C15.7767 18.8586 14.4139 18.8586 13.5715 19.6981L9.42861 23.714C8.9644 24.1787 8.9644 24.9317 9.42861 25.3965C9.65065 25.6213 9.95391 25.7472 10.2699 25.7457C10.5862 25.749 10.8902 25.6229 11.1112 25.3965L15.0318 21.4759L18.2064 24.6505C19.0543 25.4918 20.422 25.4918 21.2699 24.6505L27.4762 18.4441V20.7933C27.4762 21.4508 28.0092 21.9838 28.6667 21.9838C29.3242 21.9838 29.8572 21.4508 29.8572 20.7933V16.0314C29.8503 15.4011 29.3592 14.8824 28.7302 14.8409Z"
                    fill={type === "Fast-Track" ? "#E31B54" : "#000000"}
                  />
                </svg>
                <p
                  className={`rolecardsheading ${
                    type === "Fast-Track" ? "recruiter-text" : "default-text"
                  }`}
                  style={{ marginTop: "1.25rem" }}
                >
                  Fast-Track
                </p>
                <p
                  className={`rolecardstext mt-2 ${
                    type === "Fast-Track" ? "recruiter-text" : "default-text"
                  }`}
                >
                  Students gain insights in an evaluated and time constrained
                  challenge.
                </p>
              </div>
              <div
                className={`rolecards  ${
                  type === "Virtual Experience" ? "selectedrolecard" : ""
                }`}
                style={{
                  maxWidth: "318px",
                  width: "32%",
                  padding: "20px",
                }}
                onClick={() => setType("Virtual Experience")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M34.1274 14.8488H35.9456C37.4276 14.8587 38.6266 16.0577 38.6365 17.5397V20.3397C38.6365 21.8259 37.4317 23.0306 35.9456 23.0306H34.5456V24.6124C34.6586 27.3812 32.5137 29.7211 29.7456 29.8488H26.8728C25.5556 29.8295 24.3077 29.2551 23.4365 28.267C22.5832 27.2528 21.3255 26.6674 20.0001 26.6674C18.6747 26.6674 17.4171 27.2528 16.5638 28.267C15.6925 29.2551 14.4446 29.8295 13.1274 29.8488H10.2547C7.48658 29.7211 5.34168 27.3812 5.45467 24.6124V23.0306H4.0183C2.53215 23.0306 1.32739 21.8259 1.32739 20.3397V17.5397C1.33731 16.0577 2.53628 14.8587 4.0183 14.8488H5.83648C6.51138 12.978 8.2664 11.7141 10.2547 11.667H29.7456C31.7204 11.7288 33.4573 12.9901 34.1274 14.8488ZM4.09103 17.5397V20.3034L5.45467 20.2852V17.5397H4.09103ZM31.8183 24.6124C31.9243 25.8732 31.0036 26.9876 29.7456 27.1215H26.8728C26.3291 27.1179 25.8154 26.8711 25.4728 26.4488C24.0922 24.8834 22.1056 23.9865 20.0183 23.9865C17.931 23.9865 15.9444 24.8834 14.5638 26.4488C14.2212 26.8711 13.7075 27.1179 13.1638 27.1215H10.2547C8.9966 26.9876 8.07599 25.8732 8.18194 24.6124V16.9034C8.07599 15.6426 8.9966 14.5282 10.2547 14.3943H29.7456C31.0036 14.5282 31.9243 15.6426 31.8183 16.9034V24.6124ZM34.5456 17.5579V20.3034L35.9092 20.3215V17.5579H34.5456Z"
                    fill={type === "Virtual Experience" ? "#E31B54" : "#000000"}
                  />
                  <path
                    d="M16.3638 18.8306H10.9092C10.1561 18.8306 9.54557 19.4411 9.54557 20.1943C9.54557 20.9474 10.1561 21.5579 10.9092 21.5579H16.3638C17.1169 21.5579 17.7274 20.9474 17.7274 20.1943C17.7274 19.4411 17.1169 18.8306 16.3638 18.8306Z"
                    fill={type === "Virtual Experience" ? "#E31B54" : "#000000"}
                  />
                  <path
                    d="M26.6001 19.2306C26.4715 19.0974 26.3167 18.9922 26.1456 18.9215C25.8118 18.7943 25.4429 18.7943 25.1092 18.9215C24.938 18.9922 24.7833 19.0974 24.6547 19.2306C24.4088 19.4912 24.2721 19.836 24.2728 20.1943C24.2658 20.3686 24.2968 20.5423 24.3638 20.7034C24.4323 20.8713 24.5309 21.0253 24.6547 21.1579C24.919 21.4116 25.2702 21.5546 25.6365 21.5579C25.8118 21.5565 25.985 21.5194 26.1456 21.4488C26.3135 21.3803 26.4676 21.2817 26.6001 21.1579C26.8526 20.9002 26.9959 20.555 27.0001 20.1943C27.0007 20.0128 26.9635 19.8333 26.891 19.667C26.8199 19.5059 26.7215 19.3582 26.6001 19.2306Z"
                    fill={type === "Virtual Experience" ? "#E31B54" : "#000000"}
                  />
                  <path
                    d="M22.5092 18.9397C22.1755 18.8125 21.8066 18.8125 21.4728 18.9397C21.3044 19.0056 21.1546 19.1117 21.0365 19.2488C20.7772 19.502 20.6327 19.8501 20.6365 20.2124C20.6387 20.386 20.6694 20.558 20.7274 20.7215C20.7961 20.8938 20.9016 21.0489 21.0365 21.1761C21.292 21.4319 21.6386 21.5758 22.0001 21.5761C22.1755 21.5747 22.3487 21.5376 22.5092 21.467C22.6772 21.3985 22.8312 21.2999 22.9638 21.1761C23.2132 20.9164 23.3559 20.5724 23.3638 20.2124C23.3634 19.8509 23.2196 19.5043 22.9638 19.2488C22.8351 19.1156 22.6804 19.0104 22.5092 18.9397Z"
                    fill={type === "Virtual Experience" ? "#E31B54" : "#000000"}
                  />
                  <path
                    d="M30.2365 19.2124C29.8422 18.8265 29.2561 18.7122 28.7456 18.9215C28.5813 18.9969 28.4283 19.0948 28.291 19.2124C28.172 19.3486 28.0739 19.5018 28.0001 19.667C27.9653 19.8349 27.9653 20.0082 28.0001 20.1761C27.9638 20.35 27.9638 20.5295 28.0001 20.7034C28.0691 20.8656 28.1678 21.0136 28.291 21.1397C28.4182 21.2746 28.5734 21.3801 28.7456 21.4488C28.9119 21.5213 29.0914 21.5585 29.2728 21.5579C29.4484 21.5586 29.6221 21.5214 29.7819 21.4488C29.9542 21.3801 30.1093 21.2746 30.2365 21.1397C30.3597 21.0136 30.4584 20.8656 30.5274 20.7034C30.5855 20.5335 30.6162 20.3556 30.6183 20.1761C30.6146 20.0027 30.5839 19.8309 30.5274 19.667C30.4478 19.505 30.3502 19.3525 30.2365 19.2124Z"
                    fill={type === "Virtual Experience" ? "#E31B54" : "#000000"}
                  />
                </svg>
                <p
                  className={`rolecardsheading  ${
                    type === "Virtual Experience"
                      ? "recruiter-text"
                      : "default-text"
                  }`}
                  style={{ marginTop: "1.25rem" }}
                >
                  Virtual Experience
                </p>
                <p
                  className={`rolecardstext mt-2 ${
                    type === "Virtual Experience"
                      ? "recruiter-text"
                      : "default-text"
                  }`}
                >
                  Students experience the challenge in a virtual setting.
                </p>
              </div>
              <div
                className={`rolecards  ${
                  type === "Insights" ? "selectedrolecard" : ""
                }`}
                style={{
                  maxWidth: "318px",
                  width: "32%",
                  padding: "20px",
                }}
                onClick={() => setType("Insights")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                >
                  <path
                    d="M19.9151 14.1663C19.2285 14.1574 18.6741 13.6029 18.6651 12.9163V4.58301C18.6651 3.89265 19.2247 3.33301 19.9151 3.33301C20.6055 3.33301 21.1651 3.89265 21.1651 4.58301V12.9163C21.1561 13.6029 20.6017 14.1574 19.9151 14.1663Z"
                    fill={type === "Insights" ? "#E31B54" : "#000000"}
                  />
                  <path
                    d="M19.9151 37.4997C19.2285 37.4907 18.6741 36.9363 18.6651 36.2497V27.9163C18.6651 27.226 19.2247 26.6663 19.9151 26.6663C20.6055 26.6663 21.1651 27.226 21.1651 27.9163V36.2497C21.1561 36.9363 20.6017 37.4907 19.9151 37.4997Z"
                    fill={type === "Insights" ? "#E31B54" : "#000000"}
                  />
                  <path
                    d="M15.6151 15.5163C15.2039 15.5194 14.8175 15.3199 14.5818 14.983L9.8151 8.16634C9.62136 7.89498 9.54407 7.55746 9.6004 7.22882C9.65674 6.90019 9.84204 6.60768 10.1151 6.41634C10.6697 6.07934 11.3894 6.21607 11.7818 6.73301L16.5818 13.5497C16.8615 13.9277 16.9019 14.4319 16.6859 14.8496C16.47 15.2674 16.0353 15.526 15.5651 15.5163H15.6151Z"
                    fill={type === "Insights" ? "#E31B54" : "#000000"}
                  />
                  <path
                    d="M28.9984 34.633C28.5872 34.636 28.2009 34.4366 27.9651 34.0997L23.2484 27.283C22.8526 26.7169 22.9907 25.9371 23.5568 25.5413C24.1229 25.1455 24.9026 25.2836 25.2984 25.8497L30.0651 32.6663C30.3448 33.0444 30.3852 33.5486 30.1693 33.9663C29.9533 34.384 29.5186 34.6426 29.0484 34.633H28.9984Z"
                    fill={type === "Insights" ? "#E31B54" : "#000000"}
                  />
                  <path
                    d="M12.8651 19.0997C12.7216 19.1227 12.5753 19.1227 12.4318 19.0997L4.6151 16.183C4.29015 16.0673 4.02643 15.8241 3.88488 15.5095C3.74333 15.195 3.73619 14.8363 3.8651 14.5163C3.976 14.1882 4.21976 13.9218 4.5368 13.7823C4.85384 13.6428 5.21492 13.6431 5.53177 13.783L13.2484 16.683C13.808 16.8981 14.1361 17.4803 14.0303 18.0704C13.9245 18.6605 13.4146 19.0924 12.8151 19.0997H12.8651Z"
                    fill={type === "Insights" ? "#E31B54" : "#000000"}
                  />
                  <path
                    d="M34.7984 27.083C34.6504 27.0795 34.5039 27.0513 34.3651 26.9997L26.5818 24.1497C26.2538 24.038 25.9868 23.7953 25.8446 23.4793C25.7025 23.1633 25.6978 22.8025 25.8318 22.483C25.9475 22.1581 26.1907 21.8943 26.5052 21.7528C26.8198 21.6112 27.1785 21.6041 27.4984 21.733L35.3151 24.583C35.6401 24.6987 35.9038 24.9419 36.0453 25.2565C36.1869 25.5711 36.194 25.9297 36.0651 26.2497C35.8788 26.781 35.3602 27.1222 34.7984 27.083Z"
                    fill={type === "Insights" ? "#E31B54" : "#000000"}
                  />
                  <path
                    d="M4.6151 25.7663C3.97441 25.7826 3.42526 25.3116 3.34365 24.6759C3.26204 24.0402 3.67443 23.4458 4.29843 23.2997L12.3484 21.1497C12.6689 21.0631 13.0107 21.1076 13.2983 21.2733C13.586 21.439 13.7959 21.7124 13.8818 22.033C14.0523 22.6992 13.6603 23.3797 12.9984 23.5663L4.9151 25.7163C4.81776 25.7462 4.71687 25.763 4.6151 25.7663Z"
                    fill={type === "Insights" ? "#E31B54" : "#000000"}
                  />
                  <path
                    d="M27.1651 19.733C26.5959 19.7306 26.0982 19.3488 25.9484 18.7997C25.8619 18.4792 25.9064 18.1374 26.0721 17.8498C26.2378 17.5622 26.5111 17.3522 26.8318 17.2663L34.9151 15.1163C35.5824 14.9368 36.2689 15.3323 36.4484 15.9997C36.6279 16.667 36.2324 17.3535 35.5651 17.533L27.5151 19.683C27.4021 19.7193 27.2838 19.7362 27.1651 19.733Z"
                    fill={type === "Insights" ? "#E31B54" : "#000000"}
                  />
                  <path
                    d="M9.73177 33.7497C9.24869 33.7456 8.81119 33.4636 8.60806 33.0253C8.40494 32.587 8.47257 32.0709 8.78177 31.6997L14.1318 25.4163C14.5859 24.9022 15.3641 24.8361 15.8984 25.2663C16.1535 25.4802 16.3127 25.7869 16.3409 26.1185C16.369 26.4501 16.2638 26.7793 16.0484 27.033L10.6984 33.3497C10.4515 33.6198 10.0974 33.7664 9.73177 33.7497Z"
                    fill={type === "Insights" ? "#E31B54" : "#000000"}
                  />
                  <path
                    d="M24.7318 15.9163C24.4395 15.9225 24.155 15.8217 23.9318 15.633C23.6767 15.4192 23.5175 15.1125 23.4893 14.7809C23.4612 14.4493 23.5664 14.1201 23.7818 13.8663L29.1318 7.48301C29.3456 7.22797 29.6523 7.06873 29.9839 7.04058C30.3155 7.01242 30.6447 7.11767 30.8984 7.33301C31.3753 7.76448 31.4406 8.48998 31.0484 8.99967L25.6984 15.4163C25.4663 15.7181 25.1122 15.9013 24.7318 15.9163Z"
                    fill={type === "Insights" ? "#E31B54" : "#000000"}
                  />
                  <path
                    d="M19.9151 14.1663C19.2285 14.1574 18.6741 13.6029 18.6651 12.9163V4.58301C18.6651 3.89265 19.2247 3.33301 19.9151 3.33301C20.6055 3.33301 21.1651 3.89265 21.1651 4.58301V12.9163C21.1561 13.6029 20.6017 14.1574 19.9151 14.1663Z"
                    fill={type === "Insights" ? "#E31B54" : "#000000"}
                  />
                  <path
                    d="M19.9151 37.4997C19.2285 37.4907 18.6741 36.9363 18.6651 36.2497V27.9163C18.6651 27.226 19.2247 26.6663 19.9151 26.6663C20.6055 26.6663 21.1651 27.226 21.1651 27.9163V36.2497C21.1561 36.9363 20.6017 37.4907 19.9151 37.4997Z"
                    fill={type === "Insights" ? "#E31B54" : "#000000"}
                  />
                  <path
                    d="M15.6151 15.5163C15.2039 15.5194 14.8175 15.3199 14.5818 14.983L9.8151 8.16634C9.62136 7.89498 9.54407 7.55746 9.6004 7.22882C9.65674 6.90019 9.84204 6.60768 10.1151 6.41634C10.6697 6.07934 11.3894 6.21607 11.7818 6.73301L16.5818 13.5497C16.8615 13.9277 16.9019 14.4319 16.6859 14.8496C16.47 15.2674 16.0353 15.526 15.5651 15.5163H15.6151Z"
                    fill={type === "Insights" ? "#E31B54" : "#000000"}
                  />
                  <path
                    d="M28.9984 34.633C28.5872 34.636 28.2009 34.4366 27.9651 34.0997L23.2484 27.283C22.8526 26.7169 22.9907 25.9371 23.5568 25.5413C24.1229 25.1455 24.9026 25.2836 25.2984 25.8497L30.0651 32.6663C30.3448 33.0444 30.3852 33.5486 30.1693 33.9663C29.9533 34.384 29.5186 34.6426 29.0484 34.633H28.9984Z"
                    fill={type === "Insights" ? "#E31B54" : "#000000"}
                  />
                  <path
                    d="M12.8651 19.0997C12.7216 19.1227 12.5753 19.1227 12.4318 19.0997L4.6151 16.183C4.29015 16.0673 4.02643 15.8241 3.88488 15.5095C3.74333 15.195 3.73619 14.8363 3.8651 14.5163C3.976 14.1882 4.21976 13.9218 4.5368 13.7823C4.85384 13.6428 5.21492 13.6431 5.53177 13.783L13.2484 16.683C13.808 16.8981 14.1361 17.4803 14.0303 18.0704C13.9245 18.6605 13.4146 19.0924 12.8151 19.0997H12.8651Z"
                    fill={type === "Insights" ? "#E31B54" : "#000000"}
                  />
                  <path
                    d="M34.7984 27.083C34.6504 27.0795 34.5039 27.0513 34.3651 26.9997L26.5818 24.1497C26.2538 24.038 25.9868 23.7953 25.8446 23.4793C25.7025 23.1633 25.6978 22.8025 25.8318 22.483C25.9475 22.1581 26.1907 21.8943 26.5052 21.7528C26.8198 21.6112 27.1785 21.6041 27.4984 21.733L35.3151 24.583C35.6401 24.6987 35.9038 24.9419 36.0453 25.2565C36.1869 25.5711 36.194 25.9297 36.0651 26.2497C35.8788 26.781 35.3602 27.1222 34.7984 27.083Z"
                    fill={type === "Insights" ? "#E31B54" : "#000000"}
                  />
                  <path
                    d="M4.6151 25.7663C3.97441 25.7826 3.42526 25.3116 3.34365 24.6759C3.26204 24.0402 3.67443 23.4458 4.29843 23.2997L12.3484 21.1497C12.6689 21.0631 13.0107 21.1076 13.2983 21.2733C13.586 21.439 13.7959 21.7124 13.8818 22.033C14.0523 22.6992 13.6603 23.3797 12.9984 23.5663L4.9151 25.7163C4.81776 25.7462 4.71687 25.763 4.6151 25.7663Z"
                    fill={type === "Insights" ? "#E31B54" : "#000000"}
                  />
                  <path
                    d="M27.1651 19.733C26.5959 19.7306 26.0982 19.3488 25.9484 18.7997C25.8619 18.4792 25.9064 18.1374 26.0721 17.8498C26.2378 17.5622 26.5111 17.3522 26.8318 17.2663L34.9151 15.1163C35.5824 14.9368 36.2689 15.3323 36.4484 15.9997C36.6279 16.667 36.2324 17.3535 35.5651 17.533L27.5151 19.683C27.4021 19.7193 27.2838 19.7362 27.1651 19.733Z"
                    fill={type === "Insights" ? "#E31B54" : "#000000"}
                  />
                  <path
                    d="M9.73177 33.7497C9.24869 33.7456 8.81119 33.4636 8.60806 33.0253C8.40494 32.587 8.47257 32.0709 8.78177 31.6997L14.1318 25.4163C14.5859 24.9022 15.3641 24.8361 15.8984 25.2663C16.1535 25.4802 16.3127 25.7869 16.3409 26.1185C16.369 26.4501 16.2638 26.7793 16.0484 27.033L10.6984 33.3497C10.4515 33.6198 10.0974 33.7664 9.73177 33.7497Z"
                    fill={type === "Insights" ? "#E31B54" : "#000000"}
                  />
                  <path
                    d="M24.7318 15.9163C24.4395 15.9225 24.155 15.8217 23.9318 15.633C23.6767 15.4192 23.5175 15.1125 23.4893 14.7809C23.4612 14.4493 23.5664 14.1201 23.7818 13.8663L29.1318 7.48301C29.3456 7.22797 29.6523 7.06873 29.9839 7.04058C30.3155 7.01242 30.6447 7.11767 30.8984 7.33301C31.3753 7.76448 31.4406 8.48998 31.0484 8.99967L25.6984 15.4163C25.4663 15.7181 25.1122 15.9013 24.7318 15.9163Z"
                    fill={type === "Insights" ? "#E31B54" : "#000000"}
                  />
                </svg>

                <p
                  className={`rolecardsheading  ${
                    type === "Insights" ? "recruiter-text" : "default-text"
                  }`}
                  style={{ marginTop: "1.25rem" }}
                >
                  Insights
                </p>
                <p
                  className={`rolecardstext mt-2 ${
                    type === "Insights" ? "recruiter-text" : "default-text"
                  }`}
                >
                  Students gain insights in a{" "}
                  <span
                    className="rolecardstext"
                    style={{
                      fontWeight: "600",
                      color: type === "Insights" ? "#E31B54" : "",
                    }}
                  >
                    self-assessment
                  </span>{" "}
                  style challenge.
                </p>
              </div>
            </div>
          ) : (
            <Slider {...settings}>
              <div className="d-flex w-full centerimportant">
                <div
                  className={`rolecards  ${
                    type === "Fast-Track" ? "selectedrolecardmobile" : ""
                  }`}
                  style={{
                    maxWidth: "390px",
                    height: "210px",
                    padding: "29px 24px",
                  }}
                  onClick={() => setType("Fast-Track")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M9.68258 3.33301H30.3175C33.8241 3.33301 36.6667 6.17564 36.6667 9.68221V30.3171C36.6667 33.8237 33.8241 36.6663 30.3175 36.6663H9.68258C6.17601 36.6663 3.33337 33.8237 3.33337 30.3171V9.68221C3.33337 6.17564 6.17601 3.33301 9.68258 3.33301ZM30.3175 34.2854C32.5091 34.2854 34.2858 32.5087 34.2858 30.3171V9.68221C34.2858 7.49061 32.5091 5.71396 30.3175 5.71396H9.68258C7.49097 5.71396 5.71433 7.49061 5.71433 9.68221V30.3171C5.71433 32.5087 7.49097 34.2854 9.68258 34.2854H30.3175Z"
                      fill={type === "Fast-Track" ? "#E31B54" : "#000000"}
                    />
                    <path
                      d="M28.7302 14.8409H23.9683C23.3108 14.8409 22.7778 15.3739 22.7778 16.0314C22.7778 16.6889 23.3108 17.2219 23.9683 17.2219H25.4445L19.7937 22.8727L16.6191 19.6981C15.7767 18.8586 14.4139 18.8586 13.5715 19.6981L9.42861 23.714C8.9644 24.1787 8.9644 24.9317 9.42861 25.3965C9.65065 25.6213 9.95391 25.7472 10.2699 25.7457C10.5862 25.749 10.8902 25.6229 11.1112 25.3965L15.0318 21.4759L18.2064 24.6505C19.0543 25.4918 20.422 25.4918 21.2699 24.6505L27.4762 18.4441V20.7933C27.4762 21.4508 28.0092 21.9838 28.6667 21.9838C29.3242 21.9838 29.8572 21.4508 29.8572 20.7933V16.0314C29.8503 15.4011 29.3592 14.8824 28.7302 14.8409Z"
                      fill={type === "Fast-Track" ? "#E31B54" : "#000000"}
                    />
                  </svg>
                  <p
                    className={`rolecardsheading mt-2 ${
                      type === "Fast-Track" ? "recruiter-text" : "default-text"
                    }`}
                  >
                    Fast-Track
                  </p>
                  <p
                    className={`rolecardstext mt-2 ${
                      type === "Fast-Track" ? "recruiter-text" : "default-text"
                    }`}
                  >
                    Students gain insights in an evaluated and time constrained
                    challenge.
                  </p>
                </div>
              </div>
              <div className="d-flex w-full centerimportant">
                <div
                  className={`rolecards  ${
                    type === "Virtual Experience"
                      ? "selectedrolecardmobile"
                      : ""
                  }`}
                  style={{
                    maxWidth: "390px",
                    height: "210px",
                    padding: "29px 24px",
                  }}
                  onClick={() => setType("Virtual Experience")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M34.1274 14.8488H35.9456C37.4276 14.8587 38.6266 16.0577 38.6365 17.5397V20.3397C38.6365 21.8259 37.4317 23.0306 35.9456 23.0306H34.5456V24.6124C34.6586 27.3812 32.5137 29.7211 29.7456 29.8488H26.8728C25.5556 29.8295 24.3077 29.2551 23.4365 28.267C22.5832 27.2528 21.3255 26.6674 20.0001 26.6674C18.6747 26.6674 17.4171 27.2528 16.5638 28.267C15.6925 29.2551 14.4446 29.8295 13.1274 29.8488H10.2547C7.48658 29.7211 5.34168 27.3812 5.45467 24.6124V23.0306H4.0183C2.53215 23.0306 1.32739 21.8259 1.32739 20.3397V17.5397C1.33731 16.0577 2.53628 14.8587 4.0183 14.8488H5.83648C6.51138 12.978 8.2664 11.7141 10.2547 11.667H29.7456C31.7204 11.7288 33.4573 12.9901 34.1274 14.8488ZM4.09103 17.5397V20.3034L5.45467 20.2852V17.5397H4.09103ZM31.8183 24.6124C31.9243 25.8732 31.0036 26.9876 29.7456 27.1215H26.8728C26.3291 27.1179 25.8154 26.8711 25.4728 26.4488C24.0922 24.8834 22.1056 23.9865 20.0183 23.9865C17.931 23.9865 15.9444 24.8834 14.5638 26.4488C14.2212 26.8711 13.7075 27.1179 13.1638 27.1215H10.2547C8.9966 26.9876 8.07599 25.8732 8.18194 24.6124V16.9034C8.07599 15.6426 8.9966 14.5282 10.2547 14.3943H29.7456C31.0036 14.5282 31.9243 15.6426 31.8183 16.9034V24.6124ZM34.5456 17.5579V20.3034L35.9092 20.3215V17.5579H34.5456Z"
                      fill={
                        type === "Virtual Experience" ? "#E31B54" : "#000000"
                      }
                    />
                    <path
                      d="M16.3638 18.8306H10.9092C10.1561 18.8306 9.54557 19.4411 9.54557 20.1943C9.54557 20.9474 10.1561 21.5579 10.9092 21.5579H16.3638C17.1169 21.5579 17.7274 20.9474 17.7274 20.1943C17.7274 19.4411 17.1169 18.8306 16.3638 18.8306Z"
                      fill={
                        type === "Virtual Experience" ? "#E31B54" : "#000000"
                      }
                    />
                    <path
                      d="M26.6001 19.2306C26.4715 19.0974 26.3167 18.9922 26.1456 18.9215C25.8118 18.7943 25.4429 18.7943 25.1092 18.9215C24.938 18.9922 24.7833 19.0974 24.6547 19.2306C24.4088 19.4912 24.2721 19.836 24.2728 20.1943C24.2658 20.3686 24.2968 20.5423 24.3638 20.7034C24.4323 20.8713 24.5309 21.0253 24.6547 21.1579C24.919 21.4116 25.2702 21.5546 25.6365 21.5579C25.8118 21.5565 25.985 21.5194 26.1456 21.4488C26.3135 21.3803 26.4676 21.2817 26.6001 21.1579C26.8526 20.9002 26.9959 20.555 27.0001 20.1943C27.0007 20.0128 26.9635 19.8333 26.891 19.667C26.8199 19.5059 26.7215 19.3582 26.6001 19.2306Z"
                      fill={
                        type === "Virtual Experience" ? "#E31B54" : "#000000"
                      }
                    />
                    <path
                      d="M22.5092 18.9397C22.1755 18.8125 21.8066 18.8125 21.4728 18.9397C21.3044 19.0056 21.1546 19.1117 21.0365 19.2488C20.7772 19.502 20.6327 19.8501 20.6365 20.2124C20.6387 20.386 20.6694 20.558 20.7274 20.7215C20.7961 20.8938 20.9016 21.0489 21.0365 21.1761C21.292 21.4319 21.6386 21.5758 22.0001 21.5761C22.1755 21.5747 22.3487 21.5376 22.5092 21.467C22.6772 21.3985 22.8312 21.2999 22.9638 21.1761C23.2132 20.9164 23.3559 20.5724 23.3638 20.2124C23.3634 19.8509 23.2196 19.5043 22.9638 19.2488C22.8351 19.1156 22.6804 19.0104 22.5092 18.9397Z"
                      fill={
                        type === "Virtual Experience" ? "#E31B54" : "#000000"
                      }
                    />
                    <path
                      d="M30.2365 19.2124C29.8422 18.8265 29.2561 18.7122 28.7456 18.9215C28.5813 18.9969 28.4283 19.0948 28.291 19.2124C28.172 19.3486 28.0739 19.5018 28.0001 19.667C27.9653 19.8349 27.9653 20.0082 28.0001 20.1761C27.9638 20.35 27.9638 20.5295 28.0001 20.7034C28.0691 20.8656 28.1678 21.0136 28.291 21.1397C28.4182 21.2746 28.5734 21.3801 28.7456 21.4488C28.9119 21.5213 29.0914 21.5585 29.2728 21.5579C29.4484 21.5586 29.6221 21.5214 29.7819 21.4488C29.9542 21.3801 30.1093 21.2746 30.2365 21.1397C30.3597 21.0136 30.4584 20.8656 30.5274 20.7034C30.5855 20.5335 30.6162 20.3556 30.6183 20.1761C30.6146 20.0027 30.5839 19.8309 30.5274 19.667C30.4478 19.505 30.3502 19.3525 30.2365 19.2124Z"
                      fill={
                        type === "Virtual Experience" ? "#E31B54" : "#000000"
                      }
                    />
                  </svg>
                  <p
                    className={`rolecardsheading mt-2 ${
                      type === "Virtual Experience"
                        ? "recruiter-text"
                        : "default-text"
                    }`}
                  >
                    Virtual Experience
                  </p>
                  <p
                    className={`rolecardstext mt-2 ${
                      type === "Virtual Experience"
                        ? "recruiter-text"
                        : "default-text"
                    }`}
                  >
                    Students experience the challenge in a virtual setting.
                  </p>
                </div>
              </div>

              <div className="d-flex w-full centerimportant">
                <div
                  className={`rolecards   ${
                    type === "Insights" ? "selectedrolecardmobile" : ""
                  }`}
                  style={{
                    maxWidth: "390px",
                    height: "210px",

                    padding: "29px 24px",
                  }}
                  onClick={() => setType("Insights")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M11.8 5.34996C13.0382 5.35035 14.1742 6.03707 14.75 7.1333L18.0333 13.4666C18.5678 14.4984 18.5265 15.7341 17.9241 16.7279C17.3217 17.7216 16.2453 18.3299 15.0833 18.3333H8.51663C7.34274 18.3534 6.24481 17.7545 5.62625 16.7566C5.00769 15.7587 4.95972 14.509 5.49996 13.4666L8.83329 7.1333C9.41185 6.03176 10.5557 5.34414 11.8 5.34996ZM15.0833 15.8666C15.3758 15.8683 15.6478 15.7165 15.8 15.4666V15.4333C15.9517 15.2059 15.9769 14.9168 15.8666 14.6666L12.5333 8.3333C12.4082 8.06681 12.1442 7.89297 11.85 7.8833C11.5376 7.87234 11.2465 8.04058 11.1 8.31663L7.76663 14.65C7.63194 14.9099 7.64352 15.2214 7.79715 15.4706C7.95077 15.7199 8.22389 15.8702 8.51663 15.8666H15.0833Z"
                      fill={type === "Insights" ? "#E31B54" : "#000000"}
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M34.8333 11.6667C34.8333 15.3486 31.8485 18.3333 28.1666 18.3333C24.4847 18.3333 21.5 15.3486 21.5 11.6667C21.5 7.98477 24.4847 5 28.1666 5C31.8485 5 34.8333 7.98477 34.8333 11.6667ZM32.3333 11.6667C32.3333 9.36548 30.4678 7.5 28.1666 7.5C27.0616 7.5 26.0017 7.93899 25.2203 8.72039C24.4389 9.50179 24 10.5616 24 11.6667C24 13.9679 25.8654 15.8333 28.1666 15.8333C30.4678 15.8333 32.3333 13.9679 32.3333 11.6667Z"
                      fill={type === "Insights" ? "#E31B54" : "#000000"}
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M24.8333 21.6667H31.5C33.3409 21.6667 34.8333 23.159 34.8333 25V31.6667C34.8333 33.5076 33.3409 35 31.5 35H24.8333C22.9923 35 21.5 33.5076 21.5 31.6667V25C21.5 23.159 22.9923 21.6667 24.8333 21.6667ZM31.5 32.5C31.9602 32.5 32.3333 32.1269 32.3333 31.6667V25C32.3333 24.5398 31.9602 24.1667 31.5 24.1667H24.8333C24.3731 24.1667 24 24.5398 24 25V31.6667C24 32.1269 24.3731 32.5 24.8333 32.5H31.5Z"
                      fill={type === "Insights" ? "#E31B54" : "#000000"}
                    />
                    <path
                      d="M17.6833 21.9333C17.4511 21.6951 17.1326 21.5607 16.8 21.5607C16.4673 21.5607 16.1488 21.6951 15.9166 21.9333L11.8 26.05L7.68329 21.9333C7.19078 21.4744 6.42328 21.4879 5.94726 21.964C5.47124 22.44 5.45769 23.2075 5.91663 23.7L9.99996 27.8167L5.91663 31.9333C5.67838 32.1655 5.54401 32.484 5.54401 32.8167C5.54401 33.1493 5.67838 33.4679 5.91663 33.7C6.15283 33.9315 6.46927 34.0628 6.79996 34.0667C7.12995 34.0595 7.44522 33.9286 7.68329 33.7L11.8 29.5833L15.9166 33.7C16.1528 33.9315 16.4693 34.0628 16.8 34.0667C17.13 34.0595 17.4452 33.9286 17.6833 33.7C18.1707 33.212 18.1707 32.4214 17.6833 31.9333L13.5666 27.8167L17.6833 23.7C18.1707 23.212 18.1707 22.4214 17.6833 21.9333Z"
                      fill={type === "Insights" ? "#E31B54" : "#000000"}
                    />
                  </svg>
                  <p
                    className={`rolecardsheading mt-2 ${
                      type === "Insights" ? "recruiter-text" : "default-text"
                    }`}
                  >
                    Insights
                  </p>
                  <p
                    className={`rolecardstext mt-2 ${
                      type === "Insights" ? "recruiter-text" : "default-text"
                    }`}
                  >
                    Students gain insights in a self-assessment style challenge.
                  </p>
                </div>
              </div>
            </Slider>
          )}
        </div>
        {/* <Type type={type} /> */}
        <div style={{ marginTop: "2.5rem" }}>
          {typesofdata.map((data, index) => (
            <>
              {data.type === type && (
                <div key={index}>
                  <p className="black16 text-start">
                    <span
                      className="black16 text-start"
                      style={{ fontWeight: 600 }}
                    >
                      {data.type}
                    </span>{" "}
                    Guide
                  </p>
                  {/* <p style={{ marginTop: "10px" }}>{data.text}</p> */}
                  <div
                    className="black14"
                    style={{ marginTop: "10px", fontWeight: 400 }}
                    dangerouslySetInnerHTML={{
                      __html: data.text,
                    }}
                  />
                  <div
                    className="d-flex flex-wrap"
                    style={{ marginTop: "1.25rem", gap: "12px" }}
                  >
                    {data.chips.map((chi, index) => (
                      <div
                        className="newdashboardsidechips"
                        key={index}
                        style={{
                          border: chi.pink ? "0.75px solid #FECDD6" : "",
                          background: chi.pink
                            ? "linear-gradient(0deg, var(--Primary-25, #FFF5F6) 0%, var(--Primary-25, #FFF5F6) 100%), rgba(255, 255, 255, 0.20)"
                            : "",
                        }}
                      >
                        <img
                          src={chi.icon}
                          alt={chi.name}
                          height={"16px"}
                          width={"16px"}
                        />
                        {chi.name}
                      </div>
                    ))}
                  </div>
                  <div style={{ marginTop: "2.5rem" }}>
                    <img src={data.img} alt="img" className="img-fluid" />
                  </div>
                  <div
                    style={{ marginTop: "1.25rem", gap: "14px" }}
                    className="d-flex flex-wrap align-items-center"
                  >
                    <p className="black14" style={{ fontWeight: 500 }}>
                      Requirements
                    </p>
                    {data.requirement.map((req, index) => (
                      <div
                        className="d-flex align-items-center"
                        key={index}
                        style={{ gap: "12px" }}
                      >
                        {index !== 0 && (
                          <p className="black14" style={{ fontWeight: 500 }}>
                            or
                          </p>
                        )}
                        <div className="newdashboardsidechips">{req}</div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </>
          ))}
        </div>
        <hr
          style={{
            borderColor: "#D9D9D9",
            borderWidth: "1px",
            marginTop: "2.5rem",
            marginBottom: "2.5rem",
          }}
        />
        <div className="space">
          <button
            className="backbutton"
            onClick={() => navigate("/dashboard/Challenges")}
          >
            Back
          </button>
          <button className="continuebutton" onClick={() => setStep(2)}>
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};
export default Step3;
