import { useEffect, useState } from "react";
import { useLocation, useNavigate, Outlet } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import NewSideBar from "./Sidebar/NewSideBar";
import NewStudentHeader from "./Header/NewHeader";
import MobileHeader from "./Header/MobileHeader";
import BottomNav from "./Sidebar/BottomNav";
import io from "socket.io-client";
const socket = io(`${process.env.REACT_APP_BACKEND_URL}`);
const NewStudentDashboard = () => {
  const [selected, setSelected] = useState("Home");
  const location = useLocation();
  const navigate = useNavigate();
  const isNonMobile1024 = useMediaQuery("(min-width:1024px)");
  const isNonMobile1200 = useMediaQuery("(min-width:1200px)");
  const isNonMobile1450 = useMediaQuery("(min-width:450px)");
  const isNonMobile768 = useMediaQuery("(min-width:560px)");

  const user = useSelector((state) => state.user);

  const [company, setCompany] = useState();
  const [secondSelected, setSecondSelected] = useState("");
  const [isClose, setIsClose] = useState(false);

  useEffect(() => {
    socket.emit("register", { userId: user?._id, type: "user" });
  }, [user]);

  useEffect(() => {
    if (!user) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    const path = location.pathname;
    if (path === "/student/dashboard") {
      setSelected("Home");
    } else if (path === "/student/dashboard/challenges") {
      setSelected("Challenges");
    } else if (path === "/student/dashboard/jobs") {
      setSelected("Jobs");
    } else if (path === "/student/dashboard/settings") {
      setSelected("Settings");
      // } else if (path === "/dashboard/Team") {
      //   setSelected("Team");
      // } else if (path === "/dashboard/settings") {
      //   setSelected("Settings");
      // } else if (path === "/dashboard/TalentPool/Short-list") {
      //   setSelected("Talent Pool");
      //   setSecondSelected("Shortlisted");
      // } else if (path === "/dashboard/TalentPool/TargetSchool") {
      //   setSelected("Talent Pool");
      //   setSecondSelected("Target Schools");
    }
  }, [location]);

  return (
    <>
      <div className="new-dashboard">
        {isNonMobile768 ? (
          <div
            className={`sidebar__wrapper ${
              isNonMobile1200 && !isClose ? "open" : "close"
            }  `}
          >
            {isNonMobile1200 && (
              <button
                className="sidebar__openclosebtn shadowa"
                onClick={() => {
                  setIsClose((prev) => !prev);
                }}
                style={{
                  position: "absolute",
                  right: 0,
                  marginRight: "-1.5rem",
                  zIndex: 4,
                  marginTop: "80px",
                }}
              >
                {isClose ? <ChevronRightIcon /> : <ChevronLeftIcon />}
              </button>
            )}
            <NewSideBar
              setSelected={setSelected}
              selected={selected}
              company={company}
              setSecondSelected={setSecondSelected}
              secondSelected={secondSelected}
              isNonMobile1200={isNonMobile1200}
              setIsClose={setIsClose}
              isClose={isClose}
              user={user}
            />
          </div>
        ) : (
          <BottomNav
            setSelected={setSelected}
            selected={selected}
            navigate={navigate}
            setSecondSelected={setSecondSelected}
          />
        )}
        <div className="content-wrapper">
          {isNonMobile768 ? (
            <NewStudentHeader
              text={selected}
              navigate={navigate}
              user={user}
              isNonMobile1200={isNonMobile1200}
              isClose={isClose}
            />
          ) : (
            <MobileHeader navigate={navigate} user={user} />
          )}
          <div
            className={`main-content ${
              isNonMobile1200 && !isClose ? "open" : "close"
            } ${isNonMobile768 ? "testingshadow" : ""} `}
            style={{
              position: isNonMobile768 ? "fixed" : "fixed",
              overflow: "auto",
            }}
          >
            <div style={{ position: "block", overflow: "auto" }}>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default NewStudentDashboard;
