import Automotive from "../../../assets/images/icons/Automotive.png";
import Brand from "../../../assets/images/icons/Brand.png";
import Consulting from "../../../assets/images/icons/Consulting.png";
import Economics from "../../../assets/images/icons/Economics.png";
import HR from "../../../assets/images/icons/HR.png";
import Aviation from "../../../assets/images/icons/Aviation.png";
import marketing from "../../../assets/images/icons/marketing.png";
import realstate from "../../../assets/images/icons/realstate.png";
import FMCG from "../../../assets/images/icons/FMCG.png";
import Engineering from "../../../assets/images/icons/Engineering.png";
import Computer from "../../../assets/images/icons/Computer.png";
import Finance from "../../../assets/images/icons/Finance.png";
import Government from "../../../assets/images/icons/Government.png";

import Law from "../../../assets/images/icons/Law.png";
import Pharma from "../../../assets/images/icons/Pharma.png";
import Startup from "../../../assets/images/icons/Startup.png";
import Supply from "../../../assets/images/icons/Supply.png";
import Technology from "../../../assets/images/icons/Technology.png";
import { useState } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { toast } from "react-toastify";
import SkillsList from "../../Challenge/skillslists";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import InterestItems from "../../../components/InterestItems/InterestItems";
const Step4 = ({
  setSteps,
  selectedItem,
  setSelectedItem,
  learns,
  setLearns,
  tools,
  setTools,

  setSelectedLanguage,
}) => {
  const language = [
    {
      text: "French",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <g clipPath="url(#clip0_3910_21827)">
            <path
              d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
              fill="#F0F0F0"
            />
            <path
              d="M16.0001 8.00045C16.0001 4.56073 13.8291 1.62839 10.7827 0.498047V15.5029C13.8291 14.3725 16.0001 11.4402 16.0001 8.00045Z"
              fill="#D80027"
            />
            <path
              d="M0.000488281 8.00045C0.000488281 11.4402 2.17152 14.3725 5.21789 15.5029V0.498047C2.17152 1.62839 0.000488281 4.56073 0.000488281 8.00045Z"
              fill="#0052B4"
            />
          </g>
          <defs>
            <clipPath id="clip0_3910_21827">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ),
      select: false,
    },
    {
      text: "Italian",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <g clipPath="url(#clip0_3910_21830)">
            <path
              d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
              fill="#F0F0F0"
            />
            <path
              d="M16.0001 7.99948C16.0001 4.55976 13.8291 1.62741 10.7827 0.49707V15.5019C13.8291 14.3715 16.0001 11.4392 16.0001 7.99948Z"
              fill="#D80027"
            />
            <path
              d="M0 7.99948C0 11.4392 2.171 14.3715 5.21741 15.5019V0.49707C2.171 1.62741 0 4.55976 0 7.99948Z"
              fill="#6DA544"
            />
          </g>
          <defs>
            <clipPath id="clip0_3910_21830">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ),
      select: false,
    },
    {
      text: "German",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <g clipPath="url(#clip0_3910_21833)">
            <path
              d="M0.497559 10.7835C1.6279 13.8299 4.56025 16.0009 7.99996 16.0009C11.4397 16.0009 14.372 13.8299 15.5024 10.7835L7.99996 10.0879L0.497559 10.7835Z"
              fill="#FFDA44"
            />
            <path
              d="M7.99996 0.000976562C4.56025 0.000976562 1.6279 2.17198 0.497559 5.21838L7.99996 5.91401L15.5024 5.21835C14.372 2.17198 11.4397 0.000976562 7.99996 0.000976562Z"
              fill="black"
            />
            <path
              d="M0.497594 5.2168C0.176031 6.08345 0 7.02083 0 7.99939C0 8.97795 0.176031 9.91533 0.497594 10.782H15.5024C15.824 9.91533 16 8.97795 16 7.99939C16 7.02083 15.824 6.08345 15.5024 5.2168H0.497594Z"
              fill="#D80027"
            />
          </g>
          <defs>
            <clipPath id="clip0_3910_21833">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ),
      select: false,
    },
    {
      text: "Portuguese",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <g clipPath="url(#clip0_3910_21836)">
            <path
              d="M-0.000488281 8.00045C-0.000488281 11.4401 2.17051 14.3725 5.21686 15.5028L5.91254 8.00042L5.21686 0.498047C2.17051 1.62842 -0.000488281 4.56073 -0.000488281 8.00045Z"
              fill="#6DA544"
            />
            <path
              d="M15.9999 8C15.9999 3.58175 12.4182 0 7.99994 0C7.02138 0 6.08397 0.176031 5.21729 0.497594V15.5024C6.08397 15.824 7.02138 16 7.99994 16C12.4182 16 15.9999 12.4183 15.9999 8Z"
              fill="#D80027"
            />
            <path
              d="M5.21765 10.783C6.75444 10.783 8.00025 9.53715 8.00025 8.00037C8.00025 6.46358 6.75444 5.21777 5.21765 5.21777C3.68087 5.21777 2.43506 6.46358 2.43506 8.00037C2.43506 9.53715 3.68087 10.783 5.21765 10.783Z"
              fill="#FFDA44"
            />
            <path
              d="M3.65186 6.6084V8.34753C3.65186 9.21196 4.35261 9.91275 5.21708 9.91275C6.08154 9.91275 6.7823 9.212 6.7823 8.34753V6.6084H3.65186Z"
              fill="#D80027"
            />
            <path
              d="M5.21755 8.86875C4.92986 8.86875 4.6958 8.63468 4.6958 8.34699V7.65137H5.73927V8.34703C5.73927 8.63468 5.50521 8.86875 5.21755 8.86875Z"
              fill="#F0F0F0"
            />
          </g>
          <defs>
            <clipPath id="clip0_3910_21836">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ),
      select: false,
    },
    {
      text: "Spanish",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M15.5033 5.21741C14.373 2.17103 11.4407 0 8.00094 0C4.56122 0 1.62888 2.17103 0.498535 5.21741H15.5033Z"
            fill="#D80027"
          />
          <path
            d="M0.498047 10.7832C1.62839 13.8296 4.56073 16.0006 8.00045 16.0006C11.4402 16.0006 14.3725 13.8296 15.5029 10.7832H0.498047Z"
            fill="#D80027"
          />
        </svg>
      ),
      select: false,
    },
  ];

  const [languages, setLanguages] = useState([...language]);
  const [languageshow, setLanguagesShow] = useState(false);
  const [search, setSearch] = useState("Please Select");

  const studyLevelList = [
    "All",
    "First Year",
    "2nd Year",
    "3rd Year",
    "State Examination",
    "Masters Program",
    "MBA",
    "Doctorate",
    "Postdoctoral Studies",
  ];

  const handlelearnSubmit = (e) => {
    e.preventDefault();
    if (learn !== "" && !learns.includes(learn)) {
      if (learns.length < 12) {
        setLearns([...learns, learn]);
        setLearn("");
      } else {
        toast.error("Only upto 12 skills are allowed");
      }
    }
  };

  const handlelearnRemove = (ski) => {
    setLearns(learns.filter((learn) => learn !== ski));
  };

  const [learn, setLearn] = useState("");
  const handlelearnSubmitForm = (concept) => {
    if (!learns.includes(concept)) {
      if (learns.length < 12) {
        setLearns([...learns, concept]);
        setLearn("");
      } else {
        toast.error("Only upto 12 skills are allowed");
      }
    } else {
      setLearns(learns.filter((learn) => learn !== concept));
    }
  };

  const handleSubmit = () => {
    if (selectedItem.length < 1) {
      toast.error("Please select at least one of the interests");
      return;
    } else {
      setSteps(5);
    }
  };

  return (
    <div className="">
      <p className="BasicInforMationHeading">Categorize job</p>
      <p className="UnderBasicHeading">
        Add filters based on student interests and must-have qualifications to
        refine candidate matches.
      </p>
      <div className=" " style={{ marginTop: "2.5rem" }}>
        <p className="thumbnailcreate "> Student Interests</p>
        <p className="underthumbnailcreate" style={{ marginTop: "0.25rem" }}>
          Categorize challenge based on student interests. Add a maximum of 3
          interest fields.
        </p>
        <div style={{ marginTop: "0.75rem" }}>
          <InterestItems
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            TRUE={true}
          />
        </div>

        {/* <SkillsList
          learns={learns}
          selectedItem={selectedItem}
          handlelearnSubmitForm={handlelearnSubmitForm}
        /> */}
        {/* <div className="skillsstudentwilllearn">
          <div>
            <div style={{ padding: "1.5rem 1.5rem 0rem 1.5rem" }}>
              <p className="thumbnailcreate ">Relevant Skills</p>
              <p
                className=" underthumbnailcreate"
                style={{ marginTop: "0.25rem" }}
              >
                Select a minimum of 5 and up to 12 skills students should
                exhibit.
              </p>
              <hr style={{ marginTop: "1.5rem", marginBottom: "0rem" }} />
            </div>
            <div
              className="tablescroll"
              style={{
                height: "311px",
                paddingTop: "1.5rem",
                overflow: "auto",
                marginRight: "0.5rem",

                padding: "1.5rem 0rem 1rem 1.5rem",
              }}
            >
              <p className="thumbnailcreate ">Conceptual Skills</p>
              <div
                className="d-grid grid-auto-rows grid-auto-columns"
                style={{ marginTop: "1rem", gap: "1.25rem 0rem" }}
              >
                {conceptual.map((concept, index) => (
                  <div
                    className="d-flex conceptskillstext "
                    key={index}
                    style={{
                      alignItems: "center",
                      gap: "0.5rem",
                    }}
                  >
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => handlelearnSubmitForm(concept)}
                    >
                      {learns.includes(concept) ? (
                        <>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z"
                              fill="#E31B54"
                            />
                          </svg>
                        </>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M5.00521 1.66602H15.0052C16.8462 1.66602 18.3385 3.1584 18.3385 4.99935V14.9993C18.3385 16.8403 16.8462 18.3327 15.0052 18.3327H5.00521C3.16426 18.3327 1.67188 16.8403 1.67188 14.9993V4.99935C1.67188 3.1584 3.16426 1.66602 5.00521 1.66602ZM16.4783 16.4725C16.869 16.0818 17.0885 15.5519 17.0885 14.9993V4.99935C17.0885 4.44681 16.869 3.91691 16.4783 3.52621C16.0876 3.13551 15.5577 2.91602 15.0052 2.91602H5.00521C3.85461 2.91602 2.92188 3.84876 2.92188 4.99935V14.9993C2.92188 15.5519 3.14137 16.0818 3.53207 16.4725C3.92277 16.8632 4.45267 17.0827 5.00521 17.0827H15.0052C15.5577 17.0827 16.0876 16.8632 16.4783 16.4725Z"
                            fill="#344054"
                          />
                        </svg>
                      )}
                    </div>
                    {concept}
                  </div>
                ))}
              </div>
              <hr style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }} />
              <p className="thumbnailcreate ">Business</p>
              <div
                className="d-flex flex-wrap"
                style={{ marginTop: "1rem", gap: "1.25rem 0rem" }}
              >
                {conceptual.map((concept, index) => (
                  <div
                    className="d-flex conceptskillstext "
                    key={index}
                    style={{
                      alignItems: "center",
                      gap: "0.5rem",
                      width: "25%",
                    }}
                  >
                    <div
                      style={{
                        width: "16px",
                        height: "16px",
                        border: "1px solid black",
                      }}
                      className="rounded-1"
                    ></div>
                    {concept}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div> */}
        {/* <p className="thumbnailcreate " style={{ marginTop: "1.5rem" }}>
          b) Custom Skills
        </p>
        <p className=" underthumbnailcreate" style={{ marginTop: "" }}>
          Can’t find the right skills from the list? Here you can add additional
          skills.
        </p>
        <form onSubmit={(e) => handlelearnSubmit(e)}>
          <div
            className="forminput col-md-12 col-12 mt-2 space align-items-center"
            style={{ gap: "0.5rem" }}
          >
            <div className="col-md-8 col-8">
              <input
                value={learn}
                className="gray14"
                onChange={(e) => setLearn(e.target.value)}
                style={{ border: "none", width: "100%" }}
                placeholder="e.g, Data Presentation, Communication Skills,  etc."
              />
            </div>
            <div
              className="d-flex"
              style={{ gap: "0.5rem", alignItems: "center" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M12.3 3.9834H5.69164C5.34646 3.9834 5.06664 4.26322 5.06664 4.6084C5.06664 4.95358 5.34646 5.2334 5.69164 5.2334H12.2583C13.9324 5.2788 15.2605 6.65881 15.2416 8.3334V10.1417C15.2416 11.8446 13.8612 13.2251 12.1583 13.2251H6.24997L7.62497 11.8334C7.7441 11.7173 7.81128 11.5581 7.81128 11.3917C7.81128 11.2254 7.7441 11.0661 7.62497 10.9501C7.38096 10.7064 6.98565 10.7064 6.74164 10.9501L4.29997 13.3917C4.24348 13.4495 4.19826 13.5174 4.16664 13.5917C4.10852 13.7476 4.10852 13.9192 4.16664 14.0751C4.19826 14.1494 4.24348 14.2173 4.29997 14.2751L6.74164 16.7167C6.85821 16.8348 7.01742 16.9008 7.18331 16.9001C7.34938 16.9018 7.50895 16.8356 7.62497 16.7167C7.7441 16.6007 7.81128 16.4414 7.81128 16.2751C7.81128 16.1087 7.7441 15.9495 7.62497 15.8334L6.24997 14.4584H12.1583C14.5515 14.4584 16.4916 12.5183 16.4916 10.1251V8.3334C16.5152 5.98264 14.65 4.04694 12.3 3.9834Z"
                  fill="#475467"
                />
              </svg>
              <p className="gray14">Press Enter</p>
            </div>
          </div>
        </form> */}
        {/* <div className="d-flex flex-wrap" style={{ gap: "1rem" }}>
          {learns &&
            learns.map((ski, i) => (
              <div
                key={i}
                className=" skillsshow d-flex"
                style={{ cursor: "pointer", marginTop: "0.75rem" }}
              >
                <p className="skillsshowtext"> {ski}</p>
                <div
                  onClick={() => handlelearnRemove(ski)}
                  className="hoverdarkblacksvgdiv"
                >
                  <CloseOutlinedIcon className="" />
                </div>
              </div>
            ))}
        </div> */}
        {/* <p style={{ marginTop: "2.5rem" }} className="thumbnailcreate">
          Study Level
        </p>
        <div
          className="d-flex flex-wrap"
          style={{ marginTop: "0.75rem", gap: "0.75rem" }}
        >
          {studyLevelList.map((study, index) => (
            <div
              className="difficultybuttons"
              key={index}
              style={{
                cursor: "pointer",
                backgroundColor: tools === study ? "#FFF5F6" : "",
                color: tools === study ? "#E31B54" : "",
                border: tools === study ? "1px solid #FFE4E8" : "",
              }}
              onClick={() => setTools(study)}
            >
              {study}
            </div>
          ))}
        </div>
        <p style={{ marginTop: "2.5rem" }} className="thumbnailcreate">
          Change language requirements (optional)
        </p>
        <p className="underthumbnailcreate" style={{ marginTop: "0.75rem" }}>
          To ensure maximum exposure of each job post, the default language for
          all job posts is English. Please select the language primarily needed
          for this job if other than English.{" "}
        </p>
        <div
          className="forminput col-md-12 d-flex"
          style={{
            justifyContent: "space-between",
            marginTop: "0.75rem",
            cursor: "pointer",
          }}
          onClick={() => setLanguagesShow(!languageshow)}
        >
          {search}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M10.0007 13.9584C9.8348 13.9592 9.67559 13.8931 9.55902 13.7751L2.89235 7.10845C2.66289 6.86219 2.66966 6.47844 2.90767 6.24043C3.14568 6.00242 3.52943 5.99565 3.77569 6.22512L10.0007 12.4501L16.2257 6.22512C16.4719 5.99565 16.8557 6.00242 17.0937 6.24043C17.3317 6.47844 17.3385 6.86219 17.109 7.10845L10.4424 13.7751C10.3258 13.8931 10.1666 13.9592 10.0007 13.9584Z"
              fill="#1D2939"
            />
          </svg>
        </div>
        {languageshow && (
          <div className="col-md-12">
            <div
              className="formtext d-flex rounded-3 tablescroll"
              style={{
                flexDirection: "column",
                maxHeight: "300px",

                overflow: "auto",
                marginTop: "0.75rem",
                background: "#FFF",
                zIndex: 1,
                padding: "1rem",
                gap: "0.5rem",
              }}
            >
              {languages &&
                languages.map((sea, index) => (
                  <div
                    className="d-flex formtext col-md-12 col-12"
                    key={index}
                    style={{ gap: "0.5rem", cursor: "pointer" }}
                    onClick={() => {
                      setSelectedLanguage([sea]);
                      setSearch(sea.text);
                      setLanguagesShow(false);
                    }}
                  >
                    {sea.icon} {sea.text}
                  </div>
                ))}
            </div>
          </div>
        )} */}
        <hr style={{ marginTop: "2.5rem", marginBottom: "1.25rem" }} />
        <div
          className="d-flex flex-wrap mb-5"
          style={{ justifyContent: "space-between", gap: "1.25rem" }}
        >
          <button className="backbutton" onClick={() => setSteps(3)}>
            Back
          </button>
          <button
            className="continuebutton"
            onClick={() => {
              handleSubmit();
            }}
          >
            Continue <ChevronRightIcon />
          </button>
        </div>
      </div>
    </div>
  );
};
export default Step4;
