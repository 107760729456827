import { useState } from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { toast } from "react-toastify";
import TripOriginIcon from "@mui/icons-material/TripOrigin";
const Questions = ({
  questions,
  questionNumber,
  setQuestionNumber,
  isNonMobile1440,
  selectedAnswers,
  setSelectedAnswers,
  taskCounter,
}) => {
  const handleSelectOption = (option) => {
    setSelectedAnswers((prevAnswers) => {
      const updatedAnswers = [...prevAnswers];
      if (questions[questionNumber]?.isMultiSelect) {
        console.log("Multiselect");
        if (
          updatedAnswers[taskCounter].some(
            (selected) =>
              selected?.index === questionNumber &&
              selected?.taskCounter === taskCounter &&
              selected?.option?.value === option.value
          )
        ) {
          console.log("FILTERING");
          updatedAnswers[taskCounter] = updatedAnswers[taskCounter].filter(
            (selected) =>
              !(
                selected?.index === questionNumber &&
                selected?.taskCounter === taskCounter &&
                selected?.option?.value === option.value
              )
          );
        } else {
          let check = 0;
          updatedAnswers[taskCounter].map((select) => {
            questions[questionNumber].options.map((option) => {
              if (select?.option?.value === option.value) {
                check++;
              }
            });
          });
          console.log(check);
          if (check + 1 === questions[questionNumber].options.length) {
            toast.error("Cannot Select all options");
          } else {
            updatedAnswers[taskCounter].push({
              index: questionNumber,
              taskCounter,
              option,
            });
          }
        }
      } else {
        const existingIndex = updatedAnswers[taskCounter]?.findIndex(
          (selected) =>
            selected?.index === questionNumber &&
            selected?.taskCounter === taskCounter
        );
        if (existingIndex !== -1) {
          updatedAnswers[taskCounter][existingIndex] = {
            index: questionNumber,
            taskCounter,
            option,
          };
        } else {
          updatedAnswers[taskCounter].push({
            index: questionNumber,
            taskCounter,
            option,
          });
        }
      }
      return updatedAnswers;
    });
  };

  console.log("selectedAnswers", selectedAnswers);
  return (
    <div className="pb-4">
      <p className="black16 text-start" style={{ fontWeight: "600" }}>
        Question {questionNumber + 1}
      </p>
      {questions[questionNumber]?.isMultiSelect && (
        <div
          className="d-flex flex-wrap"
          style={{
            marginTop: "0.75rem",
            gap: "0.5rem",
            alignItems: "center",
          }}
        >
          <div
            className="center"
            style={{
              height: "24px",
              width: "24px",
              background: "#FEF0C7",
              padding: "1px",
              borderRadius: "125px",
              alignItems: "center",
            }}
          >
            <ErrorOutlineIcon style={{ color: "#DC6803" }} />
          </div>
          <p className="black12">Multiple right answers possible!</p>
        </div>
      )}
      <p className="black14" style={{ marginTop: "1rem" }}>
        {questions[questionNumber].question}
      </p>

      <div
        className={`grid-container columns-${isNonMobile1440 ? "2" : "1"}`}
        style={{ gap: "1.5rem", marginTop: "3.125rem" }}
      >
        {questions[questionNumber].options.map((opt, index) => (
          <div
            className={`optionDiv shadowa space ${
              selectedAnswers &&
              selectedAnswers.length > 0 &&
              selectedAnswers[taskCounter]?.some(
                (ap) => ap?.option?.value === opt?.value
              ) &&
              "selectedoption"
            }`}
            key={index}
            onClick={() => handleSelectOption(opt)}
          >
            <p
              className="black14"
              style={{ fontWeight: "500", lineHeight: "1.25rem" }}
            >
              {opt.value}
            </p>
            <div>
              {selectedAnswers &&
              selectedAnswers.length > 0 &&
              selectedAnswers[taskCounter]?.some(
                (ap) => ap?.option?.value === opt?.value
              ) ? (
                <div
                  className="d-flex p-0 rounded-5"
                  style={{
                    background: "transparent",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "20px",
                    width: "20px",
                  }}
                >
                  <TripOriginIcon
                    style={{ color: "#16B364", height: "20px", width: "20px" }}
                  />
                </div>
              ) : (
                <div
                  className="d-flex p-0 rounded-5"
                  style={{
                    // borderRadius: "125px",
                    background: "#F6F7FB",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "20px",
                    width: "20px",
                  }}
                >
                  <TripOriginIcon
                    style={{ color: "#AFB2C5", height: "20px", width: "20px" }}
                  />
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default Questions;
