import { useState } from "react";
import Header from "./Header";
import "./main.css";
import SecondHeader from "./SecondHeader";
import MegaMenu from "./MegaMenu";

const Solutions = () => {
  const [megaMenu, setMegaMenu] = useState(false);
  return (
    <div
      style={{
        maxWidth: "100vw",
        minWidth: "100vw",
        minHeight: "200vh",
        // overflow: "hidden",
      }}
    >
      <Header />
      <div style={{ position: "relative" }}>
        <SecondHeader
          text={"Challenges"}
          setMegaMenu={setMegaMenu}
          megaMenu={megaMenu}
        />
        <MegaMenu megaMenu={megaMenu} setMegaMenu={setMegaMenu} />
      </div>
    </div>
  );
};

export default Solutions;
