import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

const Search = ({ search, setSearch }) => {
  return (
    <div className="newSearchInput">
      <div className="newSearchInner">
        <SearchOutlinedIcon
          style={{ height: "20px", width: "20px", color: "#667085" }}
        />
        <input
          type="search"
          placeholder="Search"
          className="newSearchInputInner"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
      </div>
    </div>
  );
};
export default Search;
