import React, { useState, useEffect } from "react";
import logo from "../../assets/images/logo.png";
import search from "../../assets/images/search.svg";
import Bell from "../../assets/images/Bell.svg";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
const LogHeader = ({ user }) => {
  const [isVisible, setIsVisible] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    const handleScroll = () => {
      const scrollThreshold = window.innerHeight / 2;
      if (window.scrollY > scrollThreshold) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    };

    const handleMouseMove = (event) => {
      const mouseY = event.clientY;
      const headerThreshold = 30;
      if (mouseY <= headerThreshold) {
        setIsVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <div
      className={`header shadowa ${isVisible ? "" : "hidden"}`}
      style={{
        background: "#FFF",
      }}
    >
      <div className={`headerhomepageCompany `}>
        <div className=" headerCompany" style={{}}>
          <div className="justifySpace">
            <div className="d-flex" style={{ alignItems: "center" }}>
              <img
                src={logo}
                alt="logo"
                className="logoimage "
                onClick={() => navigate("/")}
                style={{ cursor: "pointer" }}
              />
            </div>
            <div className="welcomemenucontainer">
              {/* <button
                className="welcomebackbutton p-2 shadowa"
                style={{ maxHeight: "48px" }}
              >
                <img src={search} alt="search" height="20px" width="20px" />
              </button>
              <button
                className="welcomebackbutton p-2 shadowa"
                style={{ maxHeight: "48px" }}
              >
                <img src={Bell} alt="search" height="20px" width="20px" />
              </button> */}
              {user && user?.profilePhoto ? (
                <div
                  className=" shadowa center"
                  style={{
                    borderRadius: "72px",
                    height: "36px",
                    width: "36px",
                    padding: "1px",
                    background: "#FFF",
                    alignItems: "center",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={user?.profilePhoto}
                    alt="profile"
                    className="img-fluid shadowa"
                    style={{ borderRadius: "72px" }}
                  />
                </div>
              ) : (
                <div
                  className=" shadowa center"
                  style={{
                    borderRadius: "72px",
                    height: "36px",
                    width: "36px",
                    padding: "1px",
                    background: "#FFF",
                    alignItems: "center",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={user?.profilephoto}
                    alt="profile"
                    className="img-fluid"
                    style={{ borderRadius: "72px" }}
                  />
                </div>
              )}
              <CloseIcon
                className="hoverdarkblacksvg"
                style={{ cursor: "pointer", height: "30px", width: "30px" }}
                onClick={() => {
                  navigate(-1);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogHeader;
