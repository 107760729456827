import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CoverPhoto from "../StudentProfilePage/backgroundImg";
import Profile from "./Profile";
import { useMediaQuery } from "@mui/material";
import on from "../../assets/images/icons/on.png";
import off from "../../assets/images/newoff.png";
import "./main.css";
import { toast } from "react-toastify";
import SelectedBox from "../../assets/images/icons/selectedbox";

const Recruiter = () => {
  const { url } = useParams();
  const navigate = useNavigate();
  const isNonMobile450 = useMediaQuery("(min-width:500px)");
  const isNonMobile1024 = useMediaQuery("(min-width:1024px)");
  const isNonMobile = useMediaQuery("(min-width:768px)");

  const id = useSelector((state) => state.uniqueId);
  const [companies, setCompanies] = useState([]);
  const [viewedCompany, setViewedCompany] = useState(null);
  const [loading, setLoading] = useState(false);

  const [settings, setSettings] = useState({
    openformsg: "",
    socialfeed: "",
  });

  const texts = ["Open", "After First Contact", "Never"];
  const texts2 = ["Own Profile", "Company Profile", "Nope!"];

  const [viewedUser, setViewedUser] = useState(null);
  const [similar, setSimilar] = useState([]);
  const [user, setUser] = useState(null);
  const [company, setCompany] = useState(null);

  useEffect(() => {
    if (viewedUser && viewedUser?.settings) {
      setSettings(viewedUser?.settings);
    }
  }, [viewedUser]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/get/u/details`, { url })
        .then((res) => {
          if (res.data.msg === "Found") {
            setViewedUser(res.data.user);
            setViewedCompany(res.data.company);
            setSimilar(res.data.users);
            setCompanies(res.data.companies);
          } else {
            navigate(-1);
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
      setLoading(false);
    };

    if (url) {
      fetchData();
    } else {
      navigate(-1);
    }
  }, [url]);

  const handleFollow = (userid, companyid) => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/follow/company`, {
        userid,
        companyid,
      })
      .then((res) => {
        const { msg, company } = res.data;
        if (msg === "followed" || msg === "unfollowed") {
          toast.success(`Company has been ${msg}`);
          setCompanies((prev) => {
            return prev.map((c) =>
              c.name._id === company._id ? { id: c.id, name: company } : c
            );
          });
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const handleChangeSetting = async (type, txt) => {
    setSettings((prev) => {
      const updatedSettings = { ...prev, [type]: txt };

      axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/set/setting`, {
          settings: updatedSettings,
          userid: user?.uniqueId,
        })
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err.message);
        });

      return updatedSettings;
    });
  };

  useEffect(() => {
    const getChallenges = async () => {
      try {
        const userInfoResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/getUserInfo/uniqueId`,
          { email: id }
        );

        if (userInfoResponse.data.message === "Found") {
          setUser(userInfoResponse.data.user);
        } else {
          navigate("/company/register");
          return;
        }

        const companyInfo = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/getCompanyInfo`,
          { id: userInfoResponse?.data?.user?.company }
        );
        setCompany(companyInfo.data);
      } catch (error) {
        console.error(error);
      }
    };
    if (id) {
      getChallenges();
    }
  }, [id]);

  return (
    <>
      {loading ? (
        <p className="gray95014400 paddingmaincontent"> Loading...</p>
      ) : (
        <div
          className={`paddingmaincontent mb-5 pb-5 space ${
            isNonMobile1024 ? "" : "flex-column"
          }`}
          style={{ height: "100%", width: "100%", gap: "1.5rem" }}
        >
          <div
            style={{
              width:
                similar.length > 0
                  ? isNonMobile1024
                    ? "68.947%"
                    : "100%"
                  : "100%",
            }}
            className=""
          >
            <div className="shadowa rounded-2">
              <CoverPhoto user={viewedUser} True={true} />
              <Profile
                viewedUser={viewedUser}
                isNonMobile450={isNonMobile450}
                viewedCompany={viewedCompany}
                user={user}
                navigate={navigate}
                isNonMobile1024={isNonMobile1024}
                setViewedUser={setViewedUser}
              />
            </div>
            {user?._id === viewedUser?._id && (
              <div
                className="selectedEvent-inner "
                style={{ marginTop: "1.875rem" }}
              >
                <div className="space flex-wrap " style={{ gap: "1rem" }}>
                  <div style={{ width: isNonMobile ? "35.6234096692%" : "" }}>
                    <p className="gray95014400" style={{ fontWeight: 500 }}>
                      Open for Messages from Students
                    </p>
                    <p
                      className="gray80014400"
                      style={{ marginTop: "0.25rem" }}
                    >
                      Should students be able to send you messages directly or
                      only once you have contacted them first?{" "}
                    </p>
                  </div>
                  <div
                    className="d-flex flex-column"
                    style={{ gap: "1rem", width: isNonMobile ? "22.64%" : "" }}
                  >
                    {texts.map((txt, index) => (
                      <div
                        className="d-flex align-items-center"
                        style={{ gap: "1rem", cursor: "pointer" }}
                        key={index}
                        onClick={() => {
                          handleChangeSetting("openformsg", txt);
                        }}
                      >
                        {settings.openformsg === txt ? (
                          <img
                            src={on}
                            alt="on"
                            style={{ height: "20px", width: "36px" }}
                          />
                        ) : (
                          <img
                            src={off}
                            alt="off"
                            style={{ height: "20px", width: "36px" }}
                          />
                        )}
                        <p className="gray80014400"> {txt}</p>
                      </div>
                    ))}
                  </div>
                </div>
                <div
                  className="w-100"
                  style={{
                    borderBottom: "1px solid #E4E6E8",
                    margin: "2.5rem 0rem",
                  }}
                />
                <div className="space flex-wrap " style={{ gap: "1rem" }}>
                  <div style={{ width: isNonMobile ? "35.6234096692%" : "" }}>
                    <p className="gray95014400" style={{ fontWeight: 500 }}>
                      Social Feed
                    </p>
                    <p
                      className="gray80014400"
                      style={{ marginTop: "0.25rem" }}
                    >
                      Would you like to post on the social feed?
                    </p>
                  </div>
                  <div
                    className="d-flex flex-column"
                    style={{ gap: "1rem", width: isNonMobile ? "22.64%" : "" }}
                  >
                    {texts2.map((txt, index) => (
                      <div
                        className="d-flex align-items-center"
                        style={{ gap: "1rem", cursor: "pointer" }}
                        key={index}
                        onClick={() => {
                          handleChangeSetting("socialfeed", txt);
                        }}
                      >
                        {settings.socialfeed === txt ? (
                          <img
                            src={on}
                            alt="on"
                            style={{ height: "20px", width: "36px" }}
                          />
                        ) : (
                          <img
                            src={off}
                            alt="off"
                            style={{ height: "20px", width: "36px" }}
                          />
                        )}
                        <p className="gray80014400"> {txt}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
          {similar.length > 0 && (
            <div style={{ width: isNonMobile1024 ? "28.421%" : "100%" }}>
              <div className="selectedEvent-inner shadowa">
                <p className="gray95016500">Similar Profiles</p>

                {similar?.map((use, index) => (
                  <div
                    key={index}
                    className="d-flex flex-wrap"
                    style={{
                      marginTop: "1.5rem",
                      gap: "0.75rem",
                      borderBottom:
                        index !== similar.length - 1 && "1px solid #EAECF0",
                      paddingBottom: "0.75rem",
                    }}
                  >
                    <img
                      src={use?.profilephoto}
                      alt="profile"
                      className="center"
                      height={isNonMobile450 ? "48px" : "48px"}
                      width={isNonMobile450 ? "48px" : "48px"}
                      style={{
                        borderRadius: "125px",
                        alignItems: "center",

                        background: "#FFF",

                        padding: "2px",
                      }}
                    />
                    <div>
                      {" "}
                      <p className="gray95014400" style={{ fontWeight: 600 }}>
                        {use?.firstName} {use?.lastName}
                      </p>
                      {companies.find((com) => com.id === use?.uniqueId) && (
                        <p
                          style={{ marginTop: "0.25rem" }}
                          className="gray50012500"
                        >
                          {companies.find((com) => com.id === use?.uniqueId)
                            ?.name?.title || ""}
                        </p>
                      )}
                      {use?.url && (
                        <p
                          style={{ marginTop: "6px", cursor: "pointer" }}
                          className="text-start navrounddivtextactive"
                          onClick={() => {
                            navigate(`/dashboard/u/${use?.url}`);
                          }}
                        >
                          Preview Profile
                        </p>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              <div
                className="selectedEvent-inner shadowa"
                style={{ marginTop: "1.875rem" }}
              >
                <p className="gray95016500">Companies people also viewed</p>

                {companies &&
                  companies?.map((use, index) => (
                    <div
                      key={index}
                      className="d-flex "
                      style={{
                        marginTop: "1.5rem",
                        gap: "0.75rem",
                        borderBottom:
                          index !== companies.length - 1 && "1px solid #EAECF0",
                        paddingBottom: "0.75rem",
                      }}
                    >
                      <div
                        className="center p-2 align-items-center rounded-2 shadowa"
                        style={{
                          background: "#FFF",
                          maxHeight: "50px",
                          maxWidth: "50px",
                          height: "35px",
                          width: "35px",
                        }}
                      >
                        <img
                          src={use?.name?.img}
                          alt="img"
                          style={{ maxWidth: "30px", maxHeight: "30px" }}
                        />
                      </div>
                      <div>
                        {" "}
                        <p className="gray95014400" style={{ fontWeight: 600 }}>
                          {use?.name?.title}
                        </p>
                        {use?.name?.tagline && (
                          <p
                            style={{ marginTop: "0.25rem" }}
                            className="gray50012500"
                          >
                            {use?.name?.tagline}
                          </p>
                        )}
                        <p
                          style={{ marginTop: "6px", cursor: "pointer" }}
                          className="text-start navrounddivtextactive"
                          onClick={() => {
                            handleFollow(user?.uniqueId, use?.name?.uniqueId);
                          }}
                        >
                          {use?.name?.followers?.some(
                            (fol) => fol.userid === user?.uniqueId
                          )
                            ? "Unfollow"
                            : "Follow"}
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Recruiter;
