import React, { useRef, useEffect } from "react";
import { useSelector } from "react-redux";
const AddUni = ({ CustomUni, setCustomUni, setStep }) => {
  const inputRef = useRef(null);
  useEffect(() => {
    // Focus on the input field when the component loads
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const Uni = useSelector((state) => state.Uni);

  // useEffect(() => {
  //   if (Uni) {
  //     setStep(3);
  //   }
  // }, []);

  useEffect(() => {
    const adjustFontSize = () => {
      const input = inputRef.current;

      if (input) {
        const maxWidth = input.clientWidth;
        let newSize = 58; // Set a default font size
        input.style.fontSize = `${newSize}px`;

        // Loop until the scroll width is less than or equal to the max width
        while (input.scrollWidth > maxWidth && newSize > 0) {
          newSize -= 1;
          input.style.fontSize = `${newSize}px`;
        }
      }
    };

    adjustFontSize();
  }, [CustomUni]);

  return (
    <div className="w-full">
      <p className="sectionheadingclamped" style={{ marginBottom: "1.5rem" }}>
        Add your university
      </p>
      <div
        className="center"
        style={{
          height: "100%",
          flexDirection: "column",
          position: "relative",
          height: "100%",
          width: "100%",
        }}
      >
        <input
          ref={inputRef}
          type="text"
          className="Name_field "
          style={{ width: "80vw" }}
          value={CustomUni}
          onChange={(e) => setCustomUni(e.target.value)}
        />
      </div>
    </div>
  );
};

export default AddUni;
