// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup {
  position: fixed;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
  min-height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: white;

  min-width: 50vw;
  min-height: 50vh;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
`, "",{"version":3,"sources":["webpack://./src/pages/StudentDashboard/Challenges/popup.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,QAAQ;EACR,OAAO;EACP,2BAA2B;EAC3B,WAAW;EACX,gBAAgB;EAChB,oCAAoC;EACpC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;;EAEvB,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,wCAAwC;AAC1C","sourcesContent":[".popup {\n  position: fixed;\n  top: 50%;\n  left: 0;\n  transform: translateY(-50%);\n  width: 100%;\n  min-height: 100%;\n  background-color: rgba(0, 0, 0, 0.3);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.popup-content {\n  background-color: white;\n\n  min-width: 50vw;\n  min-height: 50vh;\n  border-radius: 10px;\n  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
