import mobile1 from "../../assets/images/mobileslide1.png";
import mobile2 from "../../assets/images/mobileslide2.png";
import mobile3 from "../../assets/images/mobileslide3.png";
const Section2Component = ({ isNonMobile500 }) => {
  const slides = [
    {
      img: mobile1,
      title: (
        <>
          Attract early talent with {!isNonMobile500 && <br />}
          virtual challenges.
        </>
      ),
      desc: "Design virtual challenges in which students practice the skills sets required to strive while getting to know your company.",
    },
    {
      img: mobile2,
      title: <>Engage with{!isNonMobile500 && <br />} students at scale</>,
      desc: "Scale your organization’s recruitment efforts with messaging, events, challenge campaigns & more. ",
    },
    {
      img: mobile3,
      title: (
        <>
          Add matching candidates{!isNonMobile500 && <br />} to your talent pool
        </>
      ),
      desc: "Invite ideal candidates to on-platform interviews or design job specific Fast-Track Challenges.",
    },
  ];
  return (
    <div
      className="d-flex flex-column gap-4"
      style={{ position: "relative", overflow: "hidden" }}
    >
      {slides.map((slide, index) => (
        <div key={index} style={{ width: "100%" }}>
          <div style={{ borderRadius: "12px", overflow: "hidden" }}>
            <img
              src={slide.img}
              alt="slide"
              className="img-fluid"
              style={{
                objectFit: "cover",
                minHeight: "304px",
                width: "100%",
              }}
            />
          </div>
          <div style={{ marginTop: "2rem" }}>
            <h4 className="newhomesmallheading">{slide.title}</h4>
            <p style={{ marginTop: "1rem" }} className="newhomesmalltext">
              {slide.desc}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};
export default Section2Component;
