import logo from "../../assets/images/logo.png";
import CloseIcon from "@mui/icons-material/Close";
const Header = ({ navigate, text }) => {
  return (
    <div
      className=""
      style={{
        background: "#FFF",
        paddingTop: "0.5rem",
        paddingBottom: "0.5rem",
      }}
    >
      <div className="marginsleftrightCompany ">
        <div className="d-flex" style={{ justifyContent: "center" }}>
          <div
            className={`headerCompany space`}
            style={{ alignItems: "center" }}
          >
            <div
              className="d-flex "
              style={{ gap: "1rem", alignItems: "center" }}
            >
              {" "}
              <img
                src={logo}
                alt="logo"
                height={`36px`}
                width={`36px`}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/dashboard");
                }}
              />
              <p className="black16">{text}</p>
            </div>
            <CloseIcon
              className="hoverdarkblacksvg"
              style={{ height: "20px", width: "20px", cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;
