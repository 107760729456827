// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/organbackground.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../assets/images/newCompanyBackground.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.banner_wrapperad {
  /* White color with 0.6 opacity */
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-width: 96vw;
  border: solid 1px #fff;
  border-radius: 30px;
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
  /* Trans Card v2 */
}

/* Optional: You can add media queries to adjust styles for different screen sizes */
@media (max-width: 768px) {
  .banner_wrapperad { /* Adjust the margin for smaller screens */
    /* Take full width on smaller screens */
    min-width: 100vw;
    min-height: 100vh;
    background-size: cover;
    margin-top: 0px;
    margin-left: 0px;
  }
  .backgroundimg {
    padding: 0px;
    display: block;
  }
}
.backgroundimg {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  min-width: 100vw;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Company/styles.scss"],"names":[],"mappings":"AAAA;EACE,iCAAA;EACA,yDAAA;EACA,4BAAA;EACA,sBAAA;EACA,2BAAA;EACA,eAAA;EACA,sBAAA;EAEA,mBAAA;EACA,0BAAA;EACA,2BAAA;EAEA,kBAAA;AAAF;;AAGA,oFAAA;AACA;EACE,oBACE,0CAAA;IACA,uCAAA;IACA,gBAAA;IACA,iBAAA;IACA,sBAAA;IACA,eAAA;IACA,gBAAA;EAAF;EAEA;IACE,YAAA;IACA,cAAA;EAAF;AACF;AAEA;EACE,WAAA;EACA,YAAA;EACA,iBAAA;EACA,gBAAA;EACA,mDAAA;EACA,4BAAA;EACA,sBAAA;EACA,2BAAA;AAAF","sourcesContent":[".banner_wrapperad {\n  /* White color with 0.6 opacity */\n  background-image: url(../../assets/images/organbackground.png);\n  background-repeat: no-repeat;\n  background-size: cover;\n  background-position: center;\n  min-width: 96vw;\n  border: solid 1px #fff;\n  border-radius: 20px;\n  border-radius: 30px;\n  border-top: 2px solid #fff;\n  border-left: 2px solid #fff;\n\n  /* Trans Card v2 */\n}\n\n/* Optional: You can add media queries to adjust styles for different screen sizes */\n@media (max-width: 768px) {\n  .banner_wrapperad {\n    margin-left: 10px; /* Adjust the margin for smaller screens */\n    /* Take full width on smaller screens */\n    min-width: 100vw;\n    min-height: 100vh;\n    background-size: cover;\n    margin-top: 0px;\n    margin-left: 0px;\n  }\n  .backgroundimg {\n    padding: 0px;\n    display: block;\n  }\n}\n.backgroundimg {\n  width: 100%;\n  height: 100%;\n  min-height: 100vh;\n  min-width: 100vw;\n  background: url(../../assets/images/newCompanyBackground.png);\n  background-repeat: no-repeat;\n  background-size: cover;\n  background-position: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
