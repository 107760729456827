const AddedBox = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 1.66666H15C16.8409 1.66666 18.3333 3.15904 18.3333 4.99999V15C18.3333 16.8409 16.8409 18.3333 15 18.3333H5C3.15905 18.3333 1.66666 16.8409 1.66666 15V4.99999C1.66666 3.15904 3.15905 1.66666 5 1.66666ZM10.625 10.625H13.3333C13.6785 10.625 13.9583 10.3452 13.9583 9.99999C13.9583 9.65481 13.6785 9.37499 13.3333 9.37499H10.625V6.66666C10.625 6.32148 10.3452 6.04166 10 6.04166C9.65482 6.04166 9.375 6.32148 9.375 6.66666V9.37499H6.66666C6.32149 9.37499 6.04166 9.65481 6.04166 9.99999C6.04166 10.3452 6.32149 10.625 6.66666 10.625H9.375V13.3333C9.375 13.6785 9.65482 13.9583 10 13.9583C10.3452 13.9583 10.625 13.6785 10.625 13.3333V10.625Z"
        fill="#5FDCB3"
      />
    </svg>
  );
};
export default AddedBox;
