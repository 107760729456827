import logo from "../../assets/images/logo.png";
import flag from "../../assets/images/flags/flagGermany.png";
import { useNavigate } from "react-router-dom";
import "./card.css";
import { useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import { useEffect } from "react";
const NameCard = ({
  firstName,
  selectedItem,
  Countries,
  LastName,
  CustomUni,
}) => {
  const isNon1024 = useMediaQuery("(min-width: 1024px)");
  const Uni = useSelector((state) => state.Uni);
  console.log(Uni, Countries);
  const Country = Countries.find((country) =>
    country.name?.toLowerCase().includes(Uni.area.toLowerCase()) ? Uni.area : ""
  );

  const navigate = useNavigate();
  // useEffect(() => {
  //   if (!Uni) {
  //     navigate("/");
  //   }
  // }, []);

  return (
    <div>
      <div className="" style={{ display: "contents" }}>
        <div className="registerationcard" style={{ position: "relative" }}>
          <div className="nameLetter shadowa">
            {firstName && firstName?.length ? (
              <p className="cardNameofuser">
                {" "}
                {firstName?.slice(0, 1)}
                {LastName && <>.{LastName?.slice(0, 1)}</>}
              </p>
            ) : (
              "\u00A0"
            )}
          </div>
          <div className="card_details">
            <p
              className="cardshownames text-start"
              style={{
                marginTop: "3.563rem",
                display: "inline-block",
                wordBreak: "break-all",
              }}
            >
              {firstName ? firstName : "\u00A0"}{" "}
              {LastName?.length ? LastName : "\u00A0"}
            </p>
            <p className="studentatnew text-start">
              Student at {Uni && Uni.name}
              {CustomUni && CustomUni}
            </p>
            <div
              className="card_moreInfo "
              style={{ alignItems: "start", marginTop: "1.375rem" }}
            >
              <div
                className="d-flex flex-wrap"
                style={{ gap: "8px", width: "100%" }}
              >
                {Country && (
                  <div
                    className="countryLogo d-flex "
                    style={{ alignItems: "center", gap: "0.625rem" }}
                  >
                    <img
                      src={Country.icon}
                      alt=""
                      style={{
                        width: "26px",
                        height: "26px",
                        maxWidth: "26px",
                        maxHeight: "26px",
                      }}
                    />

                    <p className="namecountry">{Country.name}</p>
                  </div>
                )}
                {selectedItem && (
                  <>
                    {selectedItem.slice(0, 2).map((item, index) => (
                      <div key={index} className="interests ">
                        <div className="interest_itemaaa">
                          <img
                            src={item.icon}
                            alt=""
                            height="16px"
                            width="16px"
                            className="Interest_img_icon"
                          />
                          <p className="namecountry">{item.id}</p>
                        </div>
                      </div>
                    ))}
                    {selectedItem.length > 2 && (
                      <div className="interests ">
                        <div className="interest_itemaaa">
                          <p className="namecountry">
                            +{selectedItem.length - 2}
                          </p>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row ">
          <div className="col">
            <div className="nameLetter shadowa">
              {firstName?.length ? (
                <p className="cardNameofuser"> {firstName?.slice(0, 1)} </p>
              ) : (
                "\u00A0"
              )}
            </div>
          </div>
        </div>
        <div className="card_details">
          <p className="cardshownames " style={{ marginTop: "3.563rem" }}>
            {firstName ? firstName : "\u00A0"}
          </p>

          <p className="studentatnew">Student at {Uni && Uni.name}</p>
          <div
            className="card_moreInfo "
            style={{ alignItems: "start", marginTop: "1.375rem" }}
          >
            <div className="d-flex flex-wrap" style={{ gap: "8px" }}>
              {Country && (
                <div
                  className="countryLogo d-flex mt-1"
                  style={{ alignItems: "center" }}
                >
                  <div className="imgCountryContainer">
                    <img src={Country.icon} alt="" />
                  </div>
                  <p className="namecountry">{Country.name}</p>
                </div>
              )}
              {selectedItem &&
                selectedItem.map((item, index) => (
                  <div key={index} className="interests ">
                    <div className="interest_itemaaa">
                      <img
                        src={item.icon}
                        alt=""
                        height="16px"
                        width="16px"
                        className="Interest_img_icon"
                      />
                      <p className="namecountry">{item.id}</p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};
export default NameCard;
