import "./nav.css";

import { useMediaQuery } from "@mui/material";

const First = () => {
  const isNonMobile1024 = useMediaQuery("(min-width:1024px)");

  return (
    <>
      {/* <div>
        <div
          className={` d-flex ${!isNonMobile1024 && "mt-5"}`}
          style={{ justifyContent: "space-between" }}
        >
          <div className="col-md-3">
            <p className="registeration">Job Posts</p>
          </div>
        </div>
        <div className="">
          <p className="headersmalltext" style={{ marginTop: "0.5rem" }}>
            Create events for students around the globe and attract talent.
          </p>
        </div>
      </div> */}
    </>
  );
};
export default First;
