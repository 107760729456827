const Terms = ({ terms }) => {
  return (
    <div>
      <div
        className="termstextTest tablescroll"
        dangerouslySetInnerHTML={{ __html: terms }}
      />
    </div>
  );
};
export default Terms;
