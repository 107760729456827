// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.maindashboardtopcards {
  border-radius: 5px;
  /* border-top: 2px solid #fff;
  border-left: 2px solid #fff; */
  /* background: rgba(255, 255, 255, 0.4);
  padding: 0.5rem; */

  box-shadow: 0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02);
  transition: all 0.3s ease-in-out;
}
.innermaindashboardcarddiv {
  border-radius: 7.71px;
  background: #fff;
  height: 100%;
  width: 100%;
  padding: 1.5rem 0.75rem;
}
.maindashboardtopcards:hover {
  box-shadow: 0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02);
}

.hiverpink {
  transition: all 0.3s ease-in-out;
}
.maindashboardtopcards:hover .hiverpink {
  color: #e31b54;
}
`, "",{"version":3,"sources":["webpack://./src/pages/MainDashBoard/cards.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB;gCAC8B;EAC9B;oBACkB;;EAElB;;;kDAGgD;EAChD,gCAAgC;AAClC;AACA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,YAAY;EACZ,WAAW;EACX,uBAAuB;AACzB;AACA;EACE;;;kDAGgD;AAClD;;AAEA;EACE,gCAAgC;AAClC;AACA;EACE,cAAc;AAChB","sourcesContent":[".maindashboardtopcards {\n  border-radius: 5px;\n  /* border-top: 2px solid #fff;\n  border-left: 2px solid #fff; */\n  /* background: rgba(255, 255, 255, 0.4);\n  padding: 0.5rem; */\n\n  box-shadow: 0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),\n    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),\n    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),\n    0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02);\n  transition: all 0.3s ease-in-out;\n}\n.innermaindashboardcarddiv {\n  border-radius: 7.71px;\n  background: #fff;\n  height: 100%;\n  width: 100%;\n  padding: 1.5rem 0.75rem;\n}\n.maindashboardtopcards:hover {\n  box-shadow: 0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),\n    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),\n    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),\n    0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02);\n}\n\n.hiverpink {\n  transition: all 0.3s ease-in-out;\n}\n.maindashboardtopcards:hover .hiverpink {\n  color: #e31b54;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
