import Header from "./Header";
import "./main.css";
import { useNavigate, useLocation } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import axios from "axios";

import { toast } from "react-toastify";
import Analytics from "./Analytics";
import OverView from "./Overview";
import Talent from "../../components/Company/Dashboard/EngagedTalent";
const Jobpost = () => {
  const isNonMobile = useMediaQuery("(min-width:767px)");
  const navigate = useNavigate();
  const [selectedStudents, setSelectedStudent] = useState([]);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const page = searchParams.get("page");
  const [selected, setSelected] = useState(
    page === "3" ? "Applications" : page === "2" ? "Analytics" : "Overview"
  );
  const [challenges, setChallenges] = useState([]);
  const [user, setUser] = useState([]);
  const [DATA, setData] = useState(false);
  const [bulkAction, setBulkAction] = useState("");
  const [results, setResults] = useState([]);
  const com = useSelector((state) => state.company);
  const id = useSelector((state) => state.uniqueId);
  const [company, setCompany] = useState(null);
  const [searchFilter, setSearchFilter] = useState("");
  const [livechallengesLength, setLiveChallengesLength] = useState(0);
  useEffect(() => {
    const getChallenges = async () => {
      try {
        const companyInfo = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/getCompanyInfo`,
          { id: com }
        );
        setCompany(companyInfo.data);
        const userInfoResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/getUserInfo/uniqueId`,
          { email: id }
        );

        if (userInfoResponse.data.message === "Found") {
          setUser(userInfoResponse.data.user);
        } else {
          navigate("/company/register");
          return;
        }

        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/get/company/jobs`,
          {
            uniqueId: companyInfo.data.uniqueId,
          }
        );
        if (response.data.msg === "success") {
          setChallenges(response.data.challenges);
          console.log(response.data);
        } else if (response.data.msg === "Record not found") {
          console.log("");
        } else {
          toast.error("Error Fetching Job posts");
        }

        // const resultres = await axios.post(
        //   `${process.env.REACT_APP_BACKEND_URL}/get/challenges/results`,
        //   {
        //     uniqueId: companyInfo.data.uniqueId,
        //   }
        // );
        // if (resultres.data.msg === "success") {
        //   setResults(resultres.data.results);
        // }
      } catch (error) {
        console.error(error);
      }
    };
    if (com) {
      getChallenges();
    }
  }, [com]);
  const handleBulkAction = async () => {
    if (bulkAction === "Shortlist") {
      if (selectedStudents.length > 0) {
        selectedStudents.map(async (id) => {
          await axios
            .post(
              `${process.env.REACT_APP_BACKEND_URL}/api/company/shortlist/student`,
              {
                id: id,
                companyId: company.uniqueId,
                msg: "sjdfhajksdfjkdashfjhasdjfhaksfasjk",
                type: "Challenge",
                owner: user._id,
              }
            )
            .then((res) => {
              if (res.data.msg === "success") {
                setData((prev) => !prev);
              }
            });
        });
        toast.success("Added successfully");
        setSelectedStudent([]);
      }
    } else if (bulkAction === "Remove") {
      if (selectedStudents.length > 0) {
        selectedStudents.map(async (id) => {
          await axios
            .post(
              `${process.env.REACT_APP_BACKEND_URL}/api/company/blocked/student`,
              {
                id: id,
                companyId: company.uniqueId,
                msg: "sjdfhajksdfjkdashfjhasdjfhaksfasjk",
                type: "Challenge",
                owner: user._id,
              }
            )
            .then((res) => {
              if (res.data.msg === "success") {
                setData((prev) => !prev);
              }
            });
        });
        toast.success("Removed successfully");
        setSelectedStudent([]);
      }
    }
  };

  return (
    <div className="paddingmaincontent" style={{ minHeight: "90vh" }}>
      <div
        className={`transition ${isNonMobile && "d-flex"} `}
        style={{
          justifyContent: "space-between",
          flexFlow: "wrap",
          gap: "10px",
        }}
      >
        <div className=" behindbuttons ">
          <button
            className={` d-flex border-0  ${
              selected === "Overview"
                ? "selectedbutton rounded-2 "
                : "secondbuttontext"
            } ${isNonMobile ? "" : "p-1  "}`}
            style={
              isNonMobile
                ? {
                    minWidth: "188px",
                  }
                : {}
            }
            onClick={() => {
              setSelected("Overview");
              navigate("/dashboard/Jobposts?page=1");
            }}
          >
            Overview
            {livechallengesLength.length > 0 && (
              <div className="countheading">{livechallengesLength.length}</div>
            )}
          </button>
          <button
            className={` d-flex border-0   ${
              selected === "Analytics"
                ? "selectedbutton rounded-2 "
                : "secondbuttontext"
            } ${isNonMobile ? "" : "p-1  "}`}
            style={isNonMobile ? { minWidth: "188px" } : {}}
            onClick={() => {
              setSelected("Analytics");
              navigate("/dashboard/Jobposts?page=2");
            }}
          >
            Analytics{" "}
            {challenges?.length > 0 && (
              <div className="countheading">{challenges?.length}</div>
            )}
          </button>
          <button
            className={`   border-0 ${
              selected === "Applications"
                ? "selectedbutton rounded-2 "
                : "secondbuttontext"
            } ${isNonMobile ? "" : "p-1  "}`}
            style={isNonMobile ? { minWidth: "188px" } : {}}
            onClick={() => {
              setSelected("Applications");
              navigate("/dashboard/Jobposts?page=3");
            }}
          >
            Applications
          </button>
        </div>
        <div
          className={`d-flex  ${!isNonMobile && "mt-5"}`}
          style={{
            gap: "10px",
          }}
        >
          <button
            className="d-flex border continuebuttonsmaller"
            style={{ gap: "10px" }}
            onClick={() => navigate("/create/job")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 25 24"
              fill="none"
            >
              <path
                d="M20.6016 11H13.3516V3.75C13.3516 3.33579 13.0158 3 12.6016 3C12.1873 3 11.8516 3.33579 11.8516 3.75V11H4.60156C4.18735 11 3.85156 11.3358 3.85156 11.75C3.85156 12.1642 4.18735 12.5 4.60156 12.5H11.8516V19.75C11.8516 20.1642 12.1873 20.5 12.6016 20.5C13.0158 20.5 13.3516 20.1642 13.3516 19.75V12.5H20.6016C21.0158 12.5 21.3516 12.1642 21.3516 11.75C21.3516 11.3358 21.0158 11 20.6016 11Z"
                fill="white"
              />
            </svg>
            Create Job
          </button>
        </div>
      </div>

      <div style={{ marginTop: "1.25rem" }}>
        {selected === "Analytics" && <Analytics challenges={challenges} />}
        {selected === "Overview" && (
          <OverView
            challenges={challenges}
            setLiveChallengesLength={setLiveChallengesLength}
          />
        )}

        {/* {selected === "Applications" && (
          <Talent
            results={results}
            livechallengesLength={livechallengesLength}
          />
        )} */}
        {selected === "Applications" && (
          <Talent
            results={results}
            TRUE={true}
            livechallengesLength={livechallengesLength}
            selectedStudents={selectedStudents}
            setSelectedStudent={setSelectedStudent}
            DATA={DATA}
            setData={setData}
            company={company}
            user={user}
            bulkAction={bulkAction}
            setBulkAction={setBulkAction}
            handleBulkAction={handleBulkAction}
            searchFilter={searchFilter}
            setSearchFilter={setSearchFilter}
            challenges={challenges}
          />
        )}
      </div>
    </div>
  );
};

export default Jobpost;
