import React, { useEffect, useState } from "react";
import Right from "./Rightbar";
import { useMediaQuery } from "@mui/material";
import { toast } from "react-toastify";
import "./create.css";

import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import { setStep as set } from "../../state";
import { useDispatch } from "react-redux";
import upload from "../../assets/images/icons/upload.png";
import Footer from "../../components/Company/Footer/footer";
const Create = ({
  step,
  setStep,
  selectedFile,
  setSelectedFile,
  title,
  setTitle,
  description,
  setDescription,
  setSearch,
  setSelectedLanguage,
  search,
}) => {
  const navigate = useNavigate();
  const [popup, setPopup] = useState(false);
  const [popup2, setPopup2] = useState(false);

  const language = [
    {
      text: "French",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <g clipPath="url(#clip0_3910_21827)">
            <path
              d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
              fill="#F0F0F0"
            />
            <path
              d="M16.0001 8.00045C16.0001 4.56073 13.8291 1.62839 10.7827 0.498047V15.5029C13.8291 14.3725 16.0001 11.4402 16.0001 8.00045Z"
              fill="#D80027"
            />
            <path
              d="M0.000488281 8.00045C0.000488281 11.4402 2.17152 14.3725 5.21789 15.5029V0.498047C2.17152 1.62839 0.000488281 4.56073 0.000488281 8.00045Z"
              fill="#0052B4"
            />
          </g>
          <defs>
            <clipPath id="clip0_3910_21827">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ),
      select: false,
    },
    {
      text: "Italian",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <g clipPath="url(#clip0_3910_21830)">
            <path
              d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
              fill="#F0F0F0"
            />
            <path
              d="M16.0001 7.99948C16.0001 4.55976 13.8291 1.62741 10.7827 0.49707V15.5019C13.8291 14.3715 16.0001 11.4392 16.0001 7.99948Z"
              fill="#D80027"
            />
            <path
              d="M0 7.99948C0 11.4392 2.171 14.3715 5.21741 15.5019V0.49707C2.171 1.62741 0 4.55976 0 7.99948Z"
              fill="#6DA544"
            />
          </g>
          <defs>
            <clipPath id="clip0_3910_21830">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ),
      select: false,
    },
    {
      text: "German",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <g clipPath="url(#clip0_3910_21833)">
            <path
              d="M0.497559 10.7835C1.6279 13.8299 4.56025 16.0009 7.99996 16.0009C11.4397 16.0009 14.372 13.8299 15.5024 10.7835L7.99996 10.0879L0.497559 10.7835Z"
              fill="#FFDA44"
            />
            <path
              d="M7.99996 0.000976562C4.56025 0.000976562 1.6279 2.17198 0.497559 5.21838L7.99996 5.91401L15.5024 5.21835C14.372 2.17198 11.4397 0.000976562 7.99996 0.000976562Z"
              fill="black"
            />
            <path
              d="M0.497594 5.2168C0.176031 6.08345 0 7.02083 0 7.99939C0 8.97795 0.176031 9.91533 0.497594 10.782H15.5024C15.824 9.91533 16 8.97795 16 7.99939C16 7.02083 15.824 6.08345 15.5024 5.2168H0.497594Z"
              fill="#D80027"
            />
          </g>
          <defs>
            <clipPath id="clip0_3910_21833">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ),
      select: false,
    },
    {
      text: "Portuguese",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <g clipPath="url(#clip0_3910_21836)">
            <path
              d="M-0.000488281 8.00045C-0.000488281 11.4401 2.17051 14.3725 5.21686 15.5028L5.91254 8.00042L5.21686 0.498047C2.17051 1.62842 -0.000488281 4.56073 -0.000488281 8.00045Z"
              fill="#6DA544"
            />
            <path
              d="M15.9999 8C15.9999 3.58175 12.4182 0 7.99994 0C7.02138 0 6.08397 0.176031 5.21729 0.497594V15.5024C6.08397 15.824 7.02138 16 7.99994 16C12.4182 16 15.9999 12.4183 15.9999 8Z"
              fill="#D80027"
            />
            <path
              d="M5.21765 10.783C6.75444 10.783 8.00025 9.53715 8.00025 8.00037C8.00025 6.46358 6.75444 5.21777 5.21765 5.21777C3.68087 5.21777 2.43506 6.46358 2.43506 8.00037C2.43506 9.53715 3.68087 10.783 5.21765 10.783Z"
              fill="#FFDA44"
            />
            <path
              d="M3.65186 6.6084V8.34753C3.65186 9.21196 4.35261 9.91275 5.21708 9.91275C6.08154 9.91275 6.7823 9.212 6.7823 8.34753V6.6084H3.65186Z"
              fill="#D80027"
            />
            <path
              d="M5.21755 8.86875C4.92986 8.86875 4.6958 8.63468 4.6958 8.34699V7.65137H5.73927V8.34703C5.73927 8.63468 5.50521 8.86875 5.21755 8.86875Z"
              fill="#F0F0F0"
            />
          </g>
          <defs>
            <clipPath id="clip0_3910_21836">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ),
      select: false,
    },
    {
      text: "Spanish",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M15.5033 5.21741C14.373 2.17103 11.4407 0 8.00094 0C4.56122 0 1.62888 2.17103 0.498535 5.21741H15.5033Z"
            fill="#D80027"
          />
          <path
            d="M0.498047 10.7832C1.62839 13.8296 4.56073 16.0006 8.00045 16.0006C11.4402 16.0006 14.3725 13.8296 15.5029 10.7832H0.498047Z"
            fill="#D80027"
          />
        </svg>
      ),
      select: false,
    },
  ];

  const [languages, setLanguages] = useState([...language]);

  const [languageshow, setLanguagesShow] = useState(false);

  const isNonMobile = useMediaQuery("(min-width:767px)");
  const isNonMobile1230 = useMediaQuery("(min-width:1200px)");
  const dispatch = useDispatch();
  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file && file.type.startsWith("image/")) {
      const img = new Image();
      img.onload = function () {
        setSelectedFile(file);
      };
      img.src = URL.createObjectURL(file);
    } else {
      toast.error("Please select a valid image file.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  useEffect(() => {
    dispatch(set({ step: 1 }));
  }, []);

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [{ align: [] }],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "link",
    "image",
    "align",
  ];

  const openFileInput = () => {
    fileInputRef.current.click();
  };

  const fileInputRef = React.createRef();

  const handleSubmit = () => {
    if (!title) {
      toast.error("Please Enter Challenge Title", {});
      return;
    } else if (!selectedFile) {
      toast.error("Please upload Challenge Thumbnail", {});
      return;
    } else if (description.replace(/(<([^>]+)>)/gi, "").length === 0) {
      toast.error("Please Enter Challenge description", {});
      return;
    } else {
      setStep(3);
    }
  };

  const maxCharacters = 180;

  const handleChange = (value) => {
    console.log(value.replace(/(<([^>]+)>)/gi, "").length);
    // Check if the content exceeds the character limit
    if (value.replace(/(<([^>]+)>)/gi, "").length <= maxCharacters) {
      setDescription(value);
    } else {
      setDescription(
        value.replace(/(<([^>]+)>)/gi, "").slice(0, maxCharacters)
      );
    }
  };

  return (
    <div className="" style={{ minHeight: "100vh", width: "100%" }}>
      <div className="">
        <p className="BasicInforMationHeading">Basic Information</p>
        <p className="UnderBasicHeading">What is this challenge about?</p>
        <div className=" " style={{ marginTop: "2.5rem" }}>
          <p className="thumbnailcreate">
            Challenge title
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <g clipPath="url(#clip0_7164_23381)">
                <path
                  d="M6.0626 6.00065C6.21934 5.5551 6.52871 5.17939 6.93591 4.94007C7.34311 4.70076 7.82187 4.61328 8.28739 4.69313C8.75291 4.77297 9.17515 5.015 9.47932 5.37634C9.7835 5.73767 9.94997 6.195 9.94927 6.66732C9.94927 8.00065 7.94927 8.66732 7.94927 8.66732M8.0026 11.334H8.00927M14.6693 8.00065C14.6693 11.6826 11.6845 14.6673 8.0026 14.6673C4.32071 14.6673 1.33594 11.6826 1.33594 8.00065C1.33594 4.31875 4.32071 1.33398 8.0026 1.33398C11.6845 1.33398 14.6693 4.31875 14.6693 8.00065Z"
                  stroke="#0C111D"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_7164_23381">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </p>
          <p className="underthumbnailcreate" style={{ marginTop: "0.25rem" }}>
            Max. 50 Characters
          </p>
          {popup && isNonMobile1230 && (
            <div style={{ position: "absolute" }}>
              <div
                style={{
                  position: "relative",
                  left: "-110%",
                  width: "220px",
                }}
                className="tipscover"
              >
                <div style={{ position: "absolute", marginLeft: "12rem" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                  >
                    <path
                      d="M26.25 12.8349C27.9167 13.7972 27.9167 16.2028 26.25 17.1651L11.25 25.8253C9.58333 26.7876 7.5 25.5848 7.5 23.6603L7.5 6.33974C7.5 4.41524 9.58333 3.21243 11.25 4.17468L26.25 12.8349Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div className="tipsinner">
                  <p
                    className="characterslimittext"
                    style={{ fontWeight: "600" }}
                  >
                    Tips type selection:
                  </p>
                  <p className="tipstextpopup" style={{ marginTop: "1rem" }}>
                    Publish your first challenge as a <span>Fast-Track</span> or{" "}
                    <span>Virtual Experience</span> challenge for maximum talent
                    reach.
                    <br />
                    <br />
                    To showcase the daily work routine at your company use{" "}
                    <span>Insights</span> in a second step.
                  </p>
                </div>
              </div>
            </div>
          )}

          <input
            className="col-md-12 col-12 forminput"
            onFocus={() => setPopup(true)}
            onBlur={() => setPopup(false)}
            style={{ marginTop: "0.75rem" }}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            maxLength={50}
          />
        </div>

        <div className=" " style={{ marginTop: "2.5rem" }}>
          <div className="">
            <p className="thumbnailcreate">Thumbnail</p>
            <p
              className="underthumbnailcreate"
              style={{ marginTop: "0.25rem" }}
            >
              Give your course a thumbnail. Similar to a book cover, this photo
              will appear at the top of your course overview page. For best
              results, use a ratio of 3:2.
            </p>
          </div>
          <div
            className="col-md-12 col-12  border rounded-3 d-flex circlebackground"
            style={{
              backgroundColor: "#FFF",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "356px",
              maxHeight: "357px",
              marginTop: "0.75rem",
            }}
          >
            <div>
              <div style={{ justifyContent: "center" }}>
                {selectedFile ? (
                  <img
                    src={
                      selectedFile instanceof File // Check if selectedFile is a File or Blob object
                        ? URL.createObjectURL(selectedFile) // If it is, create a local URL for the file
                        : selectedFile.includes("http")
                        ? selectedFile
                        : `${process.env.REACT_APP_BACKEND_URL}${selectedFile}` // If it's not a URL, assume it's a path and prepend the base URL
                    }
                    alt="Selected"
                    className="img-fluid"
                    style={
                      isNonMobile
                        ? { maxHeight: "250px" }
                        : { maxHeight: "200px" }
                    }
                  />
                ) : (
                  <>
                    <div
                      className="d-flex"
                      style={{ justifyContent: "center" }}
                    >
                      {selectedFile ? (
                        <p
                          style={{
                            color: "#E31B54",
                            fontFamily: "Poppins",
                            fontWeight: "600",
                            fontSize: "14px",
                            cursor: "pointer",
                          }}
                        >
                          {selectedFile.name && selectedFile.name}
                        </p>
                      ) : (
                        <img
                          src={upload}
                          alt="upload"
                          height="40px"
                          width="40px"
                        />
                      )}
                    </div>
                  </>
                )}
              </div>

              <div
                className="d-flex mt-3 mb-3"
                style={{ justifyContent: "center" }}
              >
                <div>
                  <div style={{ gap: "5px", justifyContent: "center" }}>
                    <p
                      className="text-center clicktoupload hoverdarkred "
                      onClick={openFileInput}
                      style={{ cursor: "pointer" }}
                    >
                      {selectedFile
                        ? "Click to change Image"
                        : "Click to upload Image"}
                    </p>
                    <p className="svgpngtext">
                      SVG, PNG, JPG or GIF (max. 12MB)
                    </p>
                  </div>

                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    ref={fileInputRef}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <p className="thumbnailcreate " style={{ marginTop: "2.5rem" }}>
          Short Challenge Description
        </p>
        <p className="underthumbnailcreate" style={{ marginTop: "0.25rem" }}>
          What is the challenge about? Max. 120 Characters
        </p>
        {popup2 && isNonMobile1230 && (
          <div style={{ position: "absolute" }}>
            <div
              style={{
                position: "relative",
                left: "-110%",
                width: "220px",
              }}
              className="tipscover"
            >
              <div style={{ position: "absolute", marginLeft: "12rem" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                >
                  <path
                    d="M26.25 12.8349C27.9167 13.7972 27.9167 16.2028 26.25 17.1651L11.25 25.8253C9.58333 26.7876 7.5 25.5848 7.5 23.6603L7.5 6.33974C7.5 4.41524 9.58333 3.21243 11.25 4.17468L26.25 12.8349Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div className="tipsinner">
                <p
                  className="characterslimittext"
                  style={{ fontWeight: "600" }}
                >
                  Tips type selection:
                </p>
                <p className="tipstextpopup" style={{ marginTop: "1rem" }}>
                  Publish your first challenge as a <span>Fast-Track</span> or{" "}
                  <span>Virtual Experience</span> challenge for maximum talent
                  reach.
                  <br />
                  <br />
                  To showcase the daily work routine at your company use{" "}
                  <span>Insights</span> in a second step.
                </p>
              </div>
            </div>
          </div>
        )}
        <div style={{ overflow: "hidden", marginTop: "0.75rem" }}>
          <textarea
            value={description}
            maxLength={120}
            onChange={(e) => setDescription(e.target.value)}
            onFocus={() => setPopup2(true)}
            onBlur={() => setPopup2(false)}
            style={{
              // // fontFamily: "Poppins",
              // padding: "0.5rem 0.5rem 0.5rem 0.5rem",
              transition: "all 0s ease-in-out",
              minHeight: "100px",
            }}
            className="col-md-12 col-12 rounded-3 whitescrollbar forminput "
          />
        </div>
        <p
          className="mt-2"
          style={{
            fontFamily: "Poppins",
            fontSize: "14px",
            fontWeight: "400",
          }}
        >
          {description.replace(/(<([^>]+)>)/gi, "").length}/120 Characters
        </p>
        <p className="thumbnailcreate" style={{ marginTop: "1.5rem" }}>
          Language Requirements
        </p>
        <p className=" underthumbnailcreate" style={{ marginTop: "0.75rem" }}>
          To give most students a chance to participate, the default language
          for all challenges is English.
          <br />
          <br />
          However, if your challenge covers national regulations, laws or local
          concepts or in case canditates must meet language proficiency in
          another language other than English you can change the default
          language.
        </p>
        <div
          className="forminput col-md-12 d-flex"
          style={{
            justifyContent: "space-between",
            marginTop: "0.75rem",
            cursor: "pointer",
          }}
          onClick={() => setLanguagesShow(!languageshow)}
        >
          {search}

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M10.0007 13.9584C9.8348 13.9592 9.67559 13.8931 9.55902 13.7751L2.89235 7.10845C2.66289 6.86219 2.66966 6.47844 2.90767 6.24043C3.14568 6.00242 3.52943 5.99565 3.77569 6.22512L10.0007 12.4501L16.2257 6.22512C16.4719 5.99565 16.8557 6.00242 17.0937 6.24043C17.3317 6.47844 17.3385 6.86219 17.109 7.10845L10.4424 13.7751C10.3258 13.8931 10.1666 13.9592 10.0007 13.9584Z"
              fill="#1D2939"
            />
          </svg>
        </div>
        {languageshow && (
          <div className="col-md-12">
            <div
              className="formtext d-flex rounded-3 tablescroll shadowa"
              style={{
                flexDirection: "column",
                maxHeight: "300px",

                overflow: "auto",
                marginTop: "0.75rem",
                background: "#FFF",
                zIndex: 1,
                padding: "1rem",
                gap: "0.5rem",
              }}
            >
              {languages &&
                languages.map((sea, index) => (
                  <div
                    className="d-flex formtext col-md-12 col-12 hoverred"
                    key={index}
                    style={{ gap: "0.5rem", cursor: "pointer" }}
                    onClick={() => {
                      setSelectedLanguage([sea]);
                      setSearch(sea.text);
                      setLanguagesShow(false);
                    }}
                  >
                    {sea.icon} {sea.text}
                  </div>
                ))}
            </div>
          </div>
        )}
        <hr style={{ marginTop: "2.5rem", marginBottom: "1.25rem" }} />
        <div
          className="d-flex col-md-12 flex-wrap mb-5"
          style={{ justifyContent: "space-between", gap: "10px" }}
        >
          <button className="backbutton" onClick={() => setStep(1)}>
            Back
          </button>
          <button className="continuebutton" onClick={() => handleSubmit()}>
            Continue
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M6.66351 17.2924C6.8294 17.2931 6.98861 17.227 7.10518 17.109L13.7718 10.4424C14.0156 10.1983 14.0156 9.80303 13.7718 9.55902L7.10518 2.89235C6.85892 2.66289 6.47517 2.66966 6.23716 2.90767C5.99915 3.14568 5.99238 3.52943 6.22185 3.77569L12.4468 10.0007L6.22185 16.2257C5.97813 16.4697 5.97813 16.865 6.22185 17.109C6.33842 17.227 6.49762 17.2931 6.66351 17.2924Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
        {/* <div className="mt-5">
          <Footer />
        </div> */}
      </div>
    </div>
  );
};
export default Create;
