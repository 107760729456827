// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.resultsrightdiv {
  padding: 16px;

  border-radius: 5px;
  background: rgba(255, 255, 255, 0.5);
}
.gray70032400 {
  color: var(--Night-Blue, var(--Gray-700, #344054));
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px; /* 125% */
  letter-spacing: -0.44px;
}

.resultscardsdiv {
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 70px;
  flex: 1 0;
  border-radius: 5px;
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
  background: #fff;

  /* Shadow/Blue Shadow */
}
`, "",{"version":3,"sources":["webpack://./src/pages/ChallengeResult/main.css"],"names":[],"mappings":"AAAA;EACE,aAAa;;EAEb,kBAAkB;EAClB,oCAAoC;AACtC;AACA;EACE,kDAAkD;EAClD,oBAAoB;EACpB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,SAAS;EAC5B,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,uBAAuB;EACvB,SAAS;EACT,SAAW;EACX,kBAAkB;EAClB,0BAA0B;EAC1B,2BAA2B;EAC3B,gBAAgB;;EAEhB,uBAAuB;AACzB","sourcesContent":[".resultsrightdiv {\n  padding: 16px;\n\n  border-radius: 5px;\n  background: rgba(255, 255, 255, 0.5);\n}\n.gray70032400 {\n  color: var(--Night-Blue, var(--Gray-700, #344054));\n  font-family: Poppins;\n  font-size: 32px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 40px; /* 125% */\n  letter-spacing: -0.44px;\n}\n\n.resultscardsdiv {\n  display: flex;\n  padding: 20px;\n  flex-direction: column;\n  justify-content: space-between;\n  align-items: flex-start;\n  gap: 70px;\n  flex: 1 0 0;\n  border-radius: 5px;\n  border-top: 2px solid #fff;\n  border-left: 2px solid #fff;\n  background: #fff;\n\n  /* Shadow/Blue Shadow */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
