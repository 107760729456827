import companydemo from "../../assets/images/companydemo.svg";

const Banner = ({ banner }) => {
  return (
    <img
      src={banner ? banner : companydemo}
      alt=""
      className="img-fluid"
      style={{
        minWidth: "100vw",
        width: "100vw",
        maxWidth: "100vw",
        maxHeight: "360px",
        marginTop: "3.75rem",
        objectFit: "cover",
      }}
    />
  );
};

export default Banner;
