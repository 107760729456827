import axios from "axios";
import { useEffect, useState } from "react";
const OwnerComponent = ({ ownerId }) => {
  const [ownerName, setOwnerName] = useState("");

  useEffect(() => {
    const fetchOwnerName = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/get/Owner/Name`,
          { id: ownerId }
        );
        if (response.data.msg === "success") {
          setOwnerName(response.data.user);
        }
      } catch (err) {
        console.log(err);
      }
    };

    if (ownerId) {
      fetchOwnerName();
    }
  }, [ownerId]);

  return (
    <>
      {ownerName && (
        <p className="upcoming-host">
          Host: <span>{ownerName}</span>
        </p>
      )}
    </>
  );
};

export default OwnerComponent;
