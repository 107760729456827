import React from "react";
import { useEffect } from "react";
const SkillsList = ({ learns, handlelearnSubmitForm, selectedItem }) => {
  const conceptual = {
    conceptual: [
      "Analytical Thinking",
      "Business Processes",
      "Creative Thinking",
      "Critical thinking",
      "Interpersonal Skills",
      "Effective Communication",
      "Cross-team Collaboration",
      "Negotiation",
      "Presentation Skills",
      "Relationship Building",
      "Strategic Thinking",
      "Team Building",
      "Teamwork",
      "Written Communication",
      "Decision-Making",
    ],
    consulting: [
      "Analytical Procedures",
      "Audit Deliverable",
      "Audit Planning",
      "Project Planning",
      "Industry Analysis",
      "Risk Assessment",
      "Policy Analysis",
      "Accounting",
      "Revenue Optimization",
      "Prioritization",
      "Commercial Awareness",
      "Risk management",
      "Attention to detail",
      "MS Excel",
      "MS Powerpoint",
    ],
    computer: [
      "Data Structures",
      "Java",
      "Python",
      "Data Visualization",
      "SQL",
      "TDD",
      "Risk management",
      "Process optimization",
    ],
    Brand: [
      "Brand and Image Management",
      "Social Media",
      "Market Research",
      "Data Analysis",
      "Public Relations Management",
      "Advertising",
      "Funnel Strategy",
      "Digital Marketing",
      "CRM Management",
      "Project Management ",
      "Project Management ",
    ],
    supply: [
      "Project Management",
      "ERP Systems",
      "Inventory Management Software",
      "Supply Chain Management Software",
      "Forecasting Techniques",
      "Procurement and Sourcing Tools",
      "Logistics and Transportation Management",
      "Warehouse Management Systems (WMS)",
      "QMS",
      "Automation",
      "R Programming",
      "Python",
    ],
    government: [
      "Legal Research",
      "Policy Analysis",
      "Project Management",
      "Public Administration",
      "Legislative Process",
      "Quantitative Methods",
      "Budgeting",
      "Policy implementation",
      "Cybersecurity Awareness",
      "GIS and Mapping",
    ],
    software: [
      "Programming Languages",
      "Machine Learning and AI Frameworks",
      "Data Manipulation and Analysis",
      "Deep Learning",
      "Natural Language Processing (NLP)",
      "Computer Vision",
      "Version Control Systems",
      "Cloud Computing",
      "Software Development Lifecycle (SDLC)",
      "Problem-Solving and Algorithm Design",
    ],
    finance: [
      "Company Analysis",
      "Credit Analysis",
      "DCF",
      "DCM",
      "Deal Structuring",
      "ECM",
      "Financial Analysis",
      "Financial Data",
      "M&A Process Understanding",
      "M&A Screening",
      "Financial Modelling",
      "Risk Assessment",
      "Transaction Analysis",
      "Industry Analysis",
      "Derivatives",
    ],
    economics: [
      "Economic Research",
      "Economic Analysis",
      "Econometrics",
      "Project Management",
      "Policy Analysis",
      "SAS",
      "Data Collection",
      "Statistical Analysis",
      "Interest Rates",
      "Macroeconomic Analysis",
      "Financial Modelling",
      "MS Excel",
      "MS Powerpoint",
      "SQL",
      "Forecasting",
    ],
    corporate: [
      "Attention to Detail",
      "Contract Law",
      "Client Communication Skills",
      "Due Diligence",
      "Contract Negotiation",
      "Contract Analysis",
      "Document Structuring",
      "Note-Taking",
      "Identifying Legal Issues",
      "Legal Research",
      "Commercial Awareness",
      "Drafting",
      "Document review",
    ],
    hr: [
      "HR Planning",
      "Commercial Awareness",
      "HR Information Systems",
      "HR Analytics",
      "Workforce Planning",
      "Performance Management",
      "Training and Development",
      "Compensation and Benefits ",
      "Workplace Health and Safety",
      "HR Metrics and Analytics",
      "Onboarding and Orientation",
      "Recruitment and Selection Techniques",
    ],
    aviation: [
      "Project Management",
      "Aviation Regulations",
      "Aircraft Maintenance",
      "Safety Management Systems (SMS)",
      "Air Traffic Management",
      "Flight Planning and Dispatch",
      "Aircraft Design and Engineering",
      "Aviation Security",
      "Airport Operations",
      "Emergency Response Procedures",
    ],
    pharma: [
      "Commercial Awareness",
      "Pharmacoeconomics",
      "Biotechnology",
      "Regulatory Compliance",
      "Laboratory Techniques",
      "Analytical Instrumentation",
      "Quality Assurance",
      "Quality Control",
      "Manufacturing",
    ],
    technology: [
      "Machine Learning",
      "Python",
      "JavaScript",
      "AI Algorithms",
      "Decision Trees",
      "Data Structures",
      "TensorFlow",
      "Project Management",
      "Cloud Computing",
      "Networks",
    ],
    marketing: [
      "Account Management",
      "Digital Transformation",
      "Product Development",
      "Project Management",
      "Market research",
      "Public relations management",
      "Social Media",
      "Advertising",
      "Funnel Strategy",
    ],
    Estate: [
      "Market Research",
      "Financial Analysis",
      "Property Valuation",
      "Real Estate Investment Analysis",
      "Real Estate Law",
      "Transaction Management",
      "Property Management",
      "Real Estate Marketing",
      "RE Development",
      "Geographic Information Systems",
      "Real Estate Financing",
      "Due Diligence",
    ],
    start: [
      "Agility",
      "Product Management",
      "Digital Marketing",
      "Programming",
      "Web Development",
      "App Development",
      "Cloud Computing",
      "E-commerce",
      "CRM",
      "Social Media Management",
      "Content Creation",
    ],
    automotive: [
      "Automotive Engineering",
      "CAD/CAM Software",
      "Automotive Electronics",
      "Powertrain Systems",
      "Control Systems",
      "EV Technology",
      "Hybrid Technology",
      "Manufacturing Processes",
      "Charging Infrastructure",
      "Battery Technology",
      "Sustainability",
    ],
    fmcg: [
      "Category Management",
      "Forecasting",
      "Demand Planning",
      "Supply Chain Management",
      "Retail Analytics",
      "Product Development",
      "Product Innovation",
      "Trade Marketing",
      "Quality Assurance",
      "Consumer Insights",
    ],
    engineering: [
      "Project Management",
      "Technical Drawing",
      "CAD Modeling",
      "Mechanical Engineering",
      "Electrical Engineering",
      "Software Programming",
      "Robotics",
      "Materials Science",
      "Structural Analysis",
    ],
    data: [
      "Data Analysis",
      "Data Modeling",
      "Data Presentation",
      "Data Understanding",
      "Data Interpretation",
      "Data Mining",
      "Process Mining",
      "Statistical Analysis",
      "MS Excel",
      "MS Power BI",
      "MS PowerPoint",
    ],
  };

  return (
    <>
      {" "}
      <p className="thumbnailcreate mt-3">Skills student will learn</p>
      <p className=" underthumbnailcreate" style={{ marginTop: "0.25rem" }}>
        Select a minimum of 5 and up to 12 skills students will learn, apply or
        expand on. Learn more about challenge skills.
      </p>
      <p className="thumbnailcreate mt-4">a) Predefined Skills</p>
      <div
        className="skillsstudentwilllearn"
        style={{ overflow: "hidden", padding: "1rem 0rem" }}
      >
        <div>
          {/* <div style={{ padding: "1.5rem 1.5rem 0rem 1.5rem" }}>
            <hr style={{ marginTop: "0.5rem", marginBottom: "0rem" }} />
          </div> */}
          <div
            className="tablescroll"
            style={{
              height: "311px",
              paddingTop: "1.5rem",
              overflow: "auto",

              marginRight: "0.5rem",

              padding: "1.5rem 0rem 1rem 1.5rem",
            }}
          >
            <p className="thumbnailcreate ">Conceptual Skills</p>
            <div
              className="d-grid "
              style={{
                marginTop: "1rem",
                marginBottom: "1rem",
                gap: "1rem 0rem",
                gridTemplateColumns: `repeat(auto-fill, minmax(200px, 1fr))`,
              }}
            >
              {conceptual.conceptual.map((concept, index) => (
                <div
                  className="d-flex conceptskillstext "
                  key={index}
                  style={{
                    alignItems: "center",
                    gap: "0.5rem",
                    cursor: "pointer",
                  }}
                  onClick={() => handlelearnSubmitForm(concept)}
                >
                  <div style={{ cursor: "pointer" }}>
                    {learns.includes(concept) ? (
                      <>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z"
                            fill="#E31B54"
                          />
                        </svg>
                      </>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M5.00521 1.66602H15.0052C16.8462 1.66602 18.3385 3.1584 18.3385 4.99935V14.9993C18.3385 16.8403 16.8462 18.3327 15.0052 18.3327H5.00521C3.16426 18.3327 1.67188 16.8403 1.67188 14.9993V4.99935C1.67188 3.1584 3.16426 1.66602 5.00521 1.66602ZM16.4783 16.4725C16.869 16.0818 17.0885 15.5519 17.0885 14.9993V4.99935C17.0885 4.44681 16.869 3.91691 16.4783 3.52621C16.0876 3.13551 15.5577 2.91602 15.0052 2.91602H5.00521C3.85461 2.91602 2.92188 3.84876 2.92188 4.99935V14.9993C2.92188 15.5519 3.14137 16.0818 3.53207 16.4725C3.92277 16.8632 4.45267 17.0827 5.00521 17.0827H15.0052C15.5577 17.0827 16.0876 16.8632 16.4783 16.4725Z"
                          fill="#344054"
                        />
                      </svg>
                    )}
                  </div>
                  {concept}
                </div>
              ))}
            </div>{" "}
            {Object.entries(conceptual).map(([interest, skills], index) => {
              if (
                interest !== "conceptual" &&
                interest !== "data" &&
                selectedItem.some(
                  (select) =>
                    select.id.toLowerCase().indexOf(interest.toLowerCase()) !==
                    -1
                )
              ) {
                return (
                  <React.Fragment key={index}>
                    <hr
                      style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}
                    />

                    <p className="thumbnailcreate">
                      {selectedItem
                        .filter((select) =>
                          select.id
                            .toLowerCase()
                            .includes(interest.toLowerCase())
                        )
                        .map((select) => (
                          <span key={select.id} className="thumbnailcreate">
                            {select.id} Skills
                          </span>
                        ))}
                    </p>

                    <div
                      className="d-grid"
                      style={{
                        marginTop: "1rem",
                        marginBottom: "1rem",
                        gap: "1rem 0rem",
                        gridTemplateColumns: `repeat(auto-fill, minmax(200px, 1fr))`,
                      }}
                    >
                      {skills.map((skill, idx) => (
                        <div
                          className="d-flex conceptskillstext align-items-center"
                          key={idx}
                          style={{
                            gap: "0.5rem",
                            cursor: "pointer",
                          }}
                          onClick={() => handlelearnSubmitForm(skill)}
                        >
                          {" "}
                          <div className="">
                            {learns.includes(skill) ? (
                              <>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z"
                                    fill="#E31B54"
                                  />
                                </svg>
                              </>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M5.00521 1.66602H15.0052C16.8462 1.66602 18.3385 3.1584 18.3385 4.99935V14.9993C18.3385 16.8403 16.8462 18.3327 15.0052 18.3327H5.00521C3.16426 18.3327 1.67188 16.8403 1.67188 14.9993V4.99935C1.67188 3.1584 3.16426 1.66602 5.00521 1.66602ZM16.4783 16.4725C16.869 16.0818 17.0885 15.5519 17.0885 14.9993V4.99935C17.0885 4.44681 16.869 3.91691 16.4783 3.52621C16.0876 3.13551 15.5577 2.91602 15.0052 2.91602H5.00521C3.85461 2.91602 2.92188 3.84876 2.92188 4.99935V14.9993C2.92188 15.5519 3.14137 16.0818 3.53207 16.4725C3.92277 16.8632 4.45267 17.0827 5.00521 17.0827H15.0052C15.5577 17.0827 16.0876 16.8632 16.4783 16.4725Z"
                                  fill="#344054"
                                />
                              </svg>
                            )}
                          </div>
                          {skill}
                        </div>
                      ))}
                    </div>
                  </React.Fragment>
                );
              }
              return null;
            })}
            <hr style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }} />
            <p className="thumbnailcreate ">Data-Related Skills</p>
            <div
              className="d-grid"
              style={{
                marginTop: "1rem",
                marginBottom: "1rem",
                gap: "1rem 0rem",
                gridTemplateColumns: `repeat(auto-fill, minmax(200px, 1fr))`,
              }}
            >
              {conceptual.data.map((concept, index) => (
                <div
                  className="d-flex conceptskillstext "
                  key={index}
                  style={{
                    alignItems: "center",
                    gap: "0.5rem",
                    cursor: "pointer",
                  }}
                  onClick={() => handlelearnSubmitForm(concept)}
                >
                  <div>
                    {learns.includes(concept) ? (
                      <>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z"
                            fill="#E31B54"
                          />
                        </svg>
                      </>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M5.00521 1.66602H15.0052C16.8462 1.66602 18.3385 3.1584 18.3385 4.99935V14.9993C18.3385 16.8403 16.8462 18.3327 15.0052 18.3327H5.00521C3.16426 18.3327 1.67188 16.8403 1.67188 14.9993V4.99935C1.67188 3.1584 3.16426 1.66602 5.00521 1.66602ZM16.4783 16.4725C16.869 16.0818 17.0885 15.5519 17.0885 14.9993V4.99935C17.0885 4.44681 16.869 3.91691 16.4783 3.52621C16.0876 3.13551 15.5577 2.91602 15.0052 2.91602H5.00521C3.85461 2.91602 2.92188 3.84876 2.92188 4.99935V14.9993C2.92188 15.5519 3.14137 16.0818 3.53207 16.4725C3.92277 16.8632 4.45267 17.0827 5.00521 17.0827H15.0052C15.5577 17.0827 16.0876 16.8632 16.4783 16.4725Z"
                          fill="#344054"
                        />
                      </svg>
                    )}
                  </div>
                  {concept}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SkillsList;
