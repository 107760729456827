import React from "react";

import { useMediaQuery } from "@mui/material";
import onLight from "../../assets/images/onLight.png";
import onLight2 from "../../assets/images/onLight2.png";
const Card3 = () => {
  const isNonMobile450 = useMediaQuery("(min-width:450px)");
  return (
    <div className="company3rdcard rounded-3 shadowa">
      <div className="card1paddings">
        <div className="d-flex">
          <div className="firstcard1button">
            <p className="firstcard1buttontext">03 Hire</p>
          </div>
        </div>
        <p className="text-start firstcard2ndtext">Link relevant jobs</p>
        <div
          className="d-flex"
          style={{
            height: "100%",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "2rem",
          }}
        >
          <div
            className={`${isNonMobile450 && "d-flex"} `}
            style={{
              gap: "20px",
              marginTop: "1.313rem",
              height: "auto",
              width: "auto",
            }}
          >
            <img
              className=" shadowa rounded-3 img-fluid"
              src={onLight}
              alt="card"
              style={{ maxWidth: "260px", maxHeight: "260px" }}
            />
            {isNonMobile450 && (
              <img
                className=" shadowa rounded-3 img-fluid"
                src={onLight2}
                alt="card"
                style={{ maxWidth: "260px", maxHeight: "260px" }}
              />
            )}
          </div>
          <p className="text-start firstcard3rdtext">
            Publish relevant internships and job posts and receive applications
            from the<span> right candidates</span> on the platform or your
            career website.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Card3;
