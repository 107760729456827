import { useEffect, useState } from "react";
import profilephoto from "../../assets/images/avatar/profile.png";
import EmptySpace from "../../assets/images/EmptyState.svg";
const Table = ({ invites, Roles, search, setSearch }) => {
  const RoleIcon = (role) => {
    const foundRole = Roles.find(
      (r) => r.name.toLowerCase() === role.toLowerCase()
    );
    return foundRole ? foundRole.icon : null; // Return the icon if role is found, otherwise return null
  };

  console.log(invites);
  const [currentPage, setCurrentPage] = useState(1);
  const entriesPerPage = 6;
  let indexOfLastEntry = currentPage * entriesPerPage;
  let indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  let [currentEntries, setCurrentEntries] = useState([]);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    setCurrentPage(1);
    setTotalPages(Math.ceil(invites.length / entriesPerPage));
    setCurrentEntries(invites.slice(indexOfFirstEntry, indexOfLastEntry));
    indexOfLastEntry = currentPage * entriesPerPage;
    indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  }, [invites]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const renderPageButtons = () => {
    const maxButtons = 5; // Maximum number of buttons to display
    const buttons = [];

    // Calculate the range of buttons to display around the current page
    let startPage = Math.max(1, currentPage - Math.floor(maxButtons / 2));
    let endPage = Math.min(totalPages, startPage + maxButtons - 1);

    // Adjust startPage and endPage if there are not enough buttons
    if (endPage - startPage + 1 < maxButtons) {
      if (currentPage <= Math.floor(maxButtons / 2) + 1) {
        endPage = Math.min(totalPages, maxButtons);
      } else {
        startPage = Math.max(1, totalPages - maxButtons + 1);
      }
    }

    // Render the buttons in the calculated range
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          key={i}
          style={
            i === currentPage
              ? { backgroundColor: "#E31B54", color: "#FFF" }
              : { color: "#000", backgroundColor: "transparent" } // Adjust colors as needed
          }
          onClick={() => handlePageChange(i)}
          className={`btn normalbuttons ${i === currentPage && " rounded-3"}`}
        >
          {i}
        </button>
      );
    }

    return buttons;
  };

  useEffect(() => {
    setCurrentEntries(invites.slice(indexOfFirstEntry, indexOfLastEntry));
  }, [currentPage, indexOfFirstEntry, indexOfLastEntry]);

  return (
    <div>
      {" "}
      <div className=" shadowa " style={{ marginTop: "2.5rem" }}>
        <div className="innerglass">
          <div
            className="space flex-wrap"
            style={{ gap: "1rem", alignItems: "center" }}
          >
            <p className="black16 text-start">Your Team</p>
            <div>
              <div className="forminput">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M19 19L15.5001 15.5M18 9.5C18 14.1944 14.1944 18 9.5 18C4.80558 18 1 14.1944 1 9.5C1 4.80558 4.80558 1 9.5 1C14.1944 1 18 4.80558 18 9.5Z"
                    stroke="#475467"
                    strokeWidth="1.70603"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <input
                  value={search}
                  placeholder="Search by email"
                  onChange={(e) => setSearch(e.target.value)}
                  type="text"
                  className="searchinputuni"
                />
              </div>
            </div>
          </div>

          <div className="tablescroll" style={{ overflow: "auto" }}>
            <table className="mt-4 " style={{ width: "100%" }}>
              <thead>
                <tr className="theadtr ">
                  <th className="theadtrth">User</th>
                  <th className="theadtrth">Role</th>
                  <th className="theadtrth">Location</th>
                  <th className="theadtrth">Department</th>
                  <th className="theadtrth">Status</th>
                </tr>
              </thead>
              {currentEntries.length > 0 && (
                <tbody>
                  {currentEntries.map((invite, index) => (
                    <tr className="tbodytr " key={index}>
                      <td>
                        <div
                          className="d-flex p-3"
                          style={{ gap: "0.75rem", alignItems: "center" }}
                        >
                          {invite.profilephoto ? (
                            <img
                              src={invite.profilephoto}
                              className="rounded-5 shadowa"
                              height={"48px"}
                              width={"48px"}
                              alt="profile"
                            />
                          ) : (
                            <img
                              src={profilephoto}
                              height={"48px"}
                              width={"48px"}
                              alt="profile"
                            />
                          )}
                          <div>
                            {invite.firstName && (
                              <p className="black14 mb-2">
                                {invite.firstName + " " + invite.lastName}
                              </p>
                            )}
                            <p className="gray12" style={{ fontWeight: "400" }}>
                              {invite.userEmail
                                ? invite.userEmail
                                : invite.email}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <p className="backbutton ">
                          {RoleIcon(invite?.Role ? invite?.Role : invite?.role)}
                          {invite?.Role ? invite?.Role : invite?.role}
                        </p>
                      </td>
                      <td className="p-3">
                        {invite?.country ? (
                          <>
                            <p
                              className="black14"
                              style={{ fontWeight: "500" }}
                            >
                              {invite?.country?.City?.name}
                            </p>
                            <p className="gray12" style={{ fontWeight: "400" }}>
                              {invite?.country?.Country?.flag}{" "}
                              {invite?.country?.Country?.name}
                            </p>
                          </>
                        ) : (
                          "-"
                        )}
                      </td>
                      <td className="p-3">
                        <p className="black14" style={{ fontWeight: "500" }}>
                          {invite?.department ? invite?.department : "-"}
                        </p>
                      </td>
                      <td className="p-3">
                        <p
                          className={`${
                            invite?.firstName ? "Registered" : "Pending"
                          }`}
                        >
                          {invite?.firstName ? (
                            <>Registered</>
                          ) : (
                            <>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="9"
                                height="8"
                                viewBox="0 0 9 8"
                                fill="none"
                              >
                                <circle
                                  cx="4.84717"
                                  cy="4"
                                  r="3"
                                  fill="#F97066"
                                />
                              </svg>
                              Pending
                            </>
                          )}
                        </p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
            {invites.length === 0 && (
              <div
                className="center col-md-12 col-12"
                style={{
                  alignItems: "center",
                  flexDirection: "column",
                  width: "100%",
                  height: "400px",
                }}
              >
                <img
                  src={EmptySpace}
                  alt="Emptyspace"
                  height="225px"
                  width="180px"
                  className="img-fluid"
                />
                <p className="pink16">No Data yet</p>
                <p className="black14" style={{ fontWeight: "500" }}>
                  <span className="black14">Invite Teammates now.</span>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      {invites.length > 6 && (
        <div
          className="d-flex"
          style={{ marginTop: "1rem", justifyContent: "end" }}
        >
          <div
            className="d-flex"
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            {/* <button
              onClick={handlePrevPage}
              disabled={currentPage === 1}
              className="btn"
              style={{ fontFamily: "Poppins" }}
            >
              Back
            </button> */}
            {renderPageButtons()}
            {indexOfLastEntry < invites.length && (
              <button
                onClick={handleNextPage}
                disabled={indexOfLastEntry >= invites.length}
                className="backbutton"
              >
                Next
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default Table;
