import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import sandclock from "../../../assets/images/sandclock.svg";
const Step6 = ({
  setSteps,
  title,
  position,
  locationType,
  country,
  timeRequirement,
  employmentType,
  relativeSkills,
  selectedItem,
  company,
  studyLevel,
  jobDescription,
  language,
  isNonMobile1024,
}) => {
  const MAX_LENGTH = 80;

  const truncatedTitle =
    title.length > MAX_LENGTH ? `${title.substring(0, MAX_LENGTH)}...` : title;

  return (
    <div className="d-flex">
      <div className="" style={{ width: "100%" }}>
        <p className="BasicInforMationHeading">One last check</p>
        <p className="UnderBasicHeading" style={{ marginTop: "0.25rem" }}>
          Confirm everything looks good before posting your job to{" "}
          <span>varyance</span>
        </p>
        <div
          className="shadowa"
          style={{
            marginTop: "2.5rem",
            minHeight: "27rem",
            background: "#FFF",
            padding: "1.5rem",
          }}
        >
          <div className="space">
            <p className="black16" style={{ fontWeight: "600" }}>
              Basic Information
            </p>
            <p
              className="d-flex pink16"
              onClick={() => setSteps(1)}
              style={{
                fontSize: "14px",
                gap: "0.75rem",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              Edit
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.49173 12.725L7.10006 9.88331C7.18863 9.48842 7.38811 9.12703 7.67506 8.84165L13.2334 3.33331C13.797 2.75913 14.5623 2.4273 15.3667 2.40831C15.9734 2.39962 16.5584 2.63363 16.9917 3.05831C17.9386 4.17704 17.8163 5.84766 16.7167 6.81665L11.1584 12.375C10.873 12.6619 10.5116 12.8614 10.1167 12.95L7.27506 13.5583H7.11673C6.90899 13.5663 6.71057 13.4718 6.58583 13.3054C6.4611 13.1391 6.42593 12.9222 6.49173 12.725ZM8.5584 9.73331C8.44164 9.84647 8.36053 9.9913 8.32506 10.15L7.91673 12.0916L9.8584 11.675C10.0171 11.6395 10.1619 11.5584 10.2751 11.4416L15.8334 5.88331C16.4423 5.39583 16.5631 4.51705 16.1084 3.88331C15.9093 3.69307 15.642 3.59096 15.3667 3.59998C14.8922 3.61904 14.4438 3.82231 14.1167 4.16665L8.5584 9.73331Z"
                  fill="#E31B54"
                />
                <path
                  d="M16.5917 9.10831C16.2484 9.1128 15.9712 9.39001 15.9667 9.73331V14.475C15.9779 15.1452 15.7195 15.7919 15.2494 16.2697C14.7793 16.7476 14.1371 17.0167 13.4667 17.0166H5.52507C4.13954 16.9895 3.02933 15.8608 3.02507 14.475V6.56665C3.04781 5.17922 4.17912 4.06646 5.56673 4.06665H10.3084C10.6536 4.06665 10.9334 3.78683 10.9334 3.44165C10.9334 3.09647 10.6536 2.81665 10.3084 2.81665H5.52507C3.44715 2.81652 1.75623 4.48886 1.7334 6.56665V14.475C1.7334 16.5691 3.43099 18.2666 5.52507 18.2666H13.4334C15.5242 18.2621 17.2167 16.5658 17.2167 14.475V9.73331C17.2122 9.39001 16.935 9.1128 16.5917 9.10831Z"
                  fill="#E31B54"
                />
              </svg>
            </p>
          </div>
          <div style={{ marginTop: "1.5rem" }}>
            <p className="thumbnailcreate">Job title</p>
            <p
              className="underthumbnailcreate"
              style={{ marginTop: "0.75rem" }}
            >
              {title}
            </p>
            <p style={{ marginTop: "1.5rem" }} className="thumbnailcreate">
              Position type
            </p>
            <p
              className="underthumbnailcreate"
              style={{ marginTop: "0.75rem" }}
            >
              {position}
            </p>
            <p className="thumbnailcreate" style={{ marginTop: "1.5rem" }}>
              Job description
            </p>
            <div
              className="allpoppins tablescroll"
              style={{
                marginTop: "0.75rem",
                maxHeight: "152px",
                overflow: "auto",
              }}
              dangerouslySetInnerHTML={{ __html: jobDescription }}
            />
          </div>
        </div>
        <div
          className="shadowa"
          style={{
            marginTop: "2.5rem",
            minHeight: "14rem",
            background: "#FFF",
            padding: "1.5rem",
          }}
        >
          <div className="space">
            <p className="black16" style={{ fontWeight: "600" }}>
              Location Requirement
            </p>
            <p
              className="d-flex pink16"
              onClick={() => setSteps(3)}
              style={{
                fontSize: "14px",
                gap: "0.75rem",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              Edit
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.49173 12.725L7.10006 9.88331C7.18863 9.48842 7.38811 9.12703 7.67506 8.84165L13.2334 3.33331C13.797 2.75913 14.5623 2.4273 15.3667 2.40831C15.9734 2.39962 16.5584 2.63363 16.9917 3.05831C17.9386 4.17704 17.8163 5.84766 16.7167 6.81665L11.1584 12.375C10.873 12.6619 10.5116 12.8614 10.1167 12.95L7.27506 13.5583H7.11673C6.90899 13.5663 6.71057 13.4718 6.58583 13.3054C6.4611 13.1391 6.42593 12.9222 6.49173 12.725ZM8.5584 9.73331C8.44164 9.84647 8.36053 9.9913 8.32506 10.15L7.91673 12.0916L9.8584 11.675C10.0171 11.6395 10.1619 11.5584 10.2751 11.4416L15.8334 5.88331C16.4423 5.39583 16.5631 4.51705 16.1084 3.88331C15.9093 3.69307 15.642 3.59096 15.3667 3.59998C14.8922 3.61904 14.4438 3.82231 14.1167 4.16665L8.5584 9.73331Z"
                  fill="#E31B54"
                />
                <path
                  d="M16.5917 9.10831C16.2484 9.1128 15.9712 9.39001 15.9667 9.73331V14.475C15.9779 15.1452 15.7195 15.7919 15.2494 16.2697C14.7793 16.7476 14.1371 17.0167 13.4667 17.0166H5.52507C4.13954 16.9895 3.02933 15.8608 3.02507 14.475V6.56665C3.04781 5.17922 4.17912 4.06646 5.56673 4.06665H10.3084C10.6536 4.06665 10.9334 3.78683 10.9334 3.44165C10.9334 3.09647 10.6536 2.81665 10.3084 2.81665H5.52507C3.44715 2.81652 1.75623 4.48886 1.7334 6.56665V14.475C1.7334 16.5691 3.43099 18.2666 5.52507 18.2666H13.4334C15.5242 18.2621 17.2167 16.5658 17.2167 14.475V9.73331C17.2122 9.39001 16.935 9.1128 16.5917 9.10831Z"
                  fill="#E31B54"
                />
              </svg>
            </p>
          </div>
          <div style={{ marginTop: "1.5rem" }}>
            <p className="thumbnailcreate">Location type</p>
            <p
              className="underthumbnailcreate"
              style={{ marginTop: "0.75rem" }}
            >
              {locationType}
            </p>
            {locationType !== "Remote" && (
              <>
                <p style={{ marginTop: "1.5rem" }} className="thumbnailcreate">
                  Onsite location
                </p>
                {country &&
                  country.length > 0 &&
                  country.map((job, index) => (
                    <div key={index}>
                      <p
                        className="underthumbnailcreate"
                        style={{ marginTop: "0.75rem", fontWeight: "600" }}
                      >
                        {job.Country.name}:
                      </p>
                      <p
                        className="underthumbnailcreate d-flex flex-wrap"
                        style={{ marginTop: "0.75rem", gap: "1rem" }}
                      >
                        {job.City &&
                          job.City.length > 0 &&
                          job.City.map((city, index) => (
                            <span className="underthumbnailcreate" key={index}>
                              {city.name}
                            </span>
                          ))}
                      </p>
                    </div>
                  ))}
              </>
            )}
          </div>
        </div>
        <div
          className="shadowa"
          style={{
            marginTop: "2.5rem",
            minHeight: "14rem",
            background: "#FFF",
            padding: "1.5rem",
          }}
        >
          <div className="space">
            <p className="black16" style={{ fontWeight: "600" }}>
              Time Requirements
            </p>
            <p
              className="d-flex pink16"
              onClick={() => setSteps(2)}
              style={{
                fontSize: "14px",
                gap: "0.75rem",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              Edit
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.49173 12.725L7.10006 9.88331C7.18863 9.48842 7.38811 9.12703 7.67506 8.84165L13.2334 3.33331C13.797 2.75913 14.5623 2.4273 15.3667 2.40831C15.9734 2.39962 16.5584 2.63363 16.9917 3.05831C17.9386 4.17704 17.8163 5.84766 16.7167 6.81665L11.1584 12.375C10.873 12.6619 10.5116 12.8614 10.1167 12.95L7.27506 13.5583H7.11673C6.90899 13.5663 6.71057 13.4718 6.58583 13.3054C6.4611 13.1391 6.42593 12.9222 6.49173 12.725ZM8.5584 9.73331C8.44164 9.84647 8.36053 9.9913 8.32506 10.15L7.91673 12.0916L9.8584 11.675C10.0171 11.6395 10.1619 11.5584 10.2751 11.4416L15.8334 5.88331C16.4423 5.39583 16.5631 4.51705 16.1084 3.88331C15.9093 3.69307 15.642 3.59096 15.3667 3.59998C14.8922 3.61904 14.4438 3.82231 14.1167 4.16665L8.5584 9.73331Z"
                  fill="#E31B54"
                />
                <path
                  d="M16.5917 9.10831C16.2484 9.1128 15.9712 9.39001 15.9667 9.73331V14.475C15.9779 15.1452 15.7195 15.7919 15.2494 16.2697C14.7793 16.7476 14.1371 17.0167 13.4667 17.0166H5.52507C4.13954 16.9895 3.02933 15.8608 3.02507 14.475V6.56665C3.04781 5.17922 4.17912 4.06646 5.56673 4.06665H10.3084C10.6536 4.06665 10.9334 3.78683 10.9334 3.44165C10.9334 3.09647 10.6536 2.81665 10.3084 2.81665H5.52507C3.44715 2.81652 1.75623 4.48886 1.7334 6.56665V14.475C1.7334 16.5691 3.43099 18.2666 5.52507 18.2666H13.4334C15.5242 18.2621 17.2167 16.5658 17.2167 14.475V9.73331C17.2122 9.39001 16.935 9.1128 16.5917 9.10831Z"
                  fill="#E31B54"
                />
              </svg>
            </p>
          </div>
          <div style={{ marginTop: "1.5rem" }}>
            <p className="thumbnailcreate">Schedule</p>
            <p
              className="underthumbnailcreate"
              style={{ marginTop: "0.75rem" }}
            >
              {timeRequirement}
            </p>

            <p style={{ marginTop: "1.5rem" }} className="thumbnailcreate">
              Employment duration
            </p>

            <p
              className="underthumbnailcreate"
              style={{ marginTop: "0.75rem" }}
            >
              {employmentType}
            </p>
          </div>
        </div>
        <div
          className="shadowa"
          style={{
            marginTop: "2.5rem",
            minHeight: "24.75rem",
            background: "#FFF",
            padding: "1.5rem",
          }}
        >
          <div className="space">
            <p className="black16" style={{ fontWeight: "600" }}>
              Categorize your job
            </p>
            <p
              className="d-flex pink16"
              onClick={() => setSteps(4)}
              style={{
                fontSize: "14px",
                gap: "0.75rem",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              Edit
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.49173 12.725L7.10006 9.88331C7.18863 9.48842 7.38811 9.12703 7.67506 8.84165L13.2334 3.33331C13.797 2.75913 14.5623 2.4273 15.3667 2.40831C15.9734 2.39962 16.5584 2.63363 16.9917 3.05831C17.9386 4.17704 17.8163 5.84766 16.7167 6.81665L11.1584 12.375C10.873 12.6619 10.5116 12.8614 10.1167 12.95L7.27506 13.5583H7.11673C6.90899 13.5663 6.71057 13.4718 6.58583 13.3054C6.4611 13.1391 6.42593 12.9222 6.49173 12.725ZM8.5584 9.73331C8.44164 9.84647 8.36053 9.9913 8.32506 10.15L7.91673 12.0916L9.8584 11.675C10.0171 11.6395 10.1619 11.5584 10.2751 11.4416L15.8334 5.88331C16.4423 5.39583 16.5631 4.51705 16.1084 3.88331C15.9093 3.69307 15.642 3.59096 15.3667 3.59998C14.8922 3.61904 14.4438 3.82231 14.1167 4.16665L8.5584 9.73331Z"
                  fill="#E31B54"
                />
                <path
                  d="M16.5917 9.10831C16.2484 9.1128 15.9712 9.39001 15.9667 9.73331V14.475C15.9779 15.1452 15.7195 15.7919 15.2494 16.2697C14.7793 16.7476 14.1371 17.0167 13.4667 17.0166H5.52507C4.13954 16.9895 3.02933 15.8608 3.02507 14.475V6.56665C3.04781 5.17922 4.17912 4.06646 5.56673 4.06665H10.3084C10.6536 4.06665 10.9334 3.78683 10.9334 3.44165C10.9334 3.09647 10.6536 2.81665 10.3084 2.81665H5.52507C3.44715 2.81652 1.75623 4.48886 1.7334 6.56665V14.475C1.7334 16.5691 3.43099 18.2666 5.52507 18.2666H13.4334C15.5242 18.2621 17.2167 16.5658 17.2167 14.475V9.73331C17.2122 9.39001 16.935 9.1128 16.5917 9.10831Z"
                  fill="#E31B54"
                />
              </svg>
            </p>
          </div>
          <div style={{ marginTop: "1.5rem" }}>
            <p className="thumbnailcreate">Student interest</p>
            <div
              className="d-flex flex-wrap"
              style={{ gap: "6px 1rem", marginTop: "0.75rem" }}
            >
              {selectedItem.map((item, index) => (
                <div
                  className={`interestcont ${
                    selectedItem.some(
                      (selectedIte) => selectedIte.id === item.id
                    )
                      ? "active"
                      : ""
                  }`}
                  key={index}
                  style={{
                    padding: "10px 10px",
                    gap: "0.5rem",
                    cursor: "pointer",
                  }}
                >
                  {item.icon && (
                    <img
                      src={item.icon}
                      alt="icon"
                      height="20px"
                      width="20px"
                    />
                  )}
                  <p
                    className="interesttext"
                    style={{
                      color: selectedItem.some(
                        (selectedIte) => selectedIte.id === item.id
                      )
                        ? "white"
                        : "",
                      fontWeight: "400",
                      lineHeight: "1.125rem",
                    }}
                  >
                    {item.text}
                  </p>
                </div>
              ))}
            </div>
            <p style={{ marginTop: "1.5rem" }} className="thumbnailcreate">
              Skills
            </p>
            <p
              className="underthumbnailcreate d-flex flex-wrap"
              style={{ marginTop: "0.75rem", gap: "0.75rem" }}
            >
              {relativeSkills.map((item, index) => (
                <span key={index} style={{ fontFamily: "Poppins" }}>
                  {item}
                </span>
              ))}
            </p>
            <p style={{ marginTop: "1.5rem" }} className="thumbnailcreate">
              Study level
            </p>
            <p
              className="underthumbnailcreate"
              style={{ marginTop: "0.75rem" }}
            >
              {studyLevel}
            </p>
            {language && (
              <>
                <p style={{ marginTop: "1.5rem" }} className="thumbnailcreate">
                  language
                </p>

                <p
                  className="underthumbnailcreate"
                  style={{ marginTop: "0.75rem" }}
                >
                  {language[0]?.text}
                </p>
              </>
            )}{" "}
          </div>
        </div>
        <hr style={{ marginTop: "2.5rem", marginBottom: "1.25rem" }} />
        <div
          className="d-flex flex-wrap mb-5"
          style={{ justifyContent: "space-between", gap: "1.25rem" }}
        >
          <button className="backbutton" onClick={() => setSteps(6)}>
            Back
          </button>
          <button
            className="continuebutton"
            onClick={() => {
              setSteps(8);
            }}
          >
            Continue <ChevronRightIcon />
          </button>
        </div>
      </div>
      {isNonMobile1024 && (
        <div
          className="d-flex"
          style={{
            minWidth: "305px",
            justifyContent: "end",
            marginTop: "6rem",
          }}
        >
          <div
            className="Challengescardouterwhitediv"
            style={{ position: "sticky", top: "10%" }}
          >
            <div
              className="Challengescardinnergreydiv"
              style={{ overflow: "hidden" }}
            >
              <div style={{ padding: "1.5rem 0.75rem" }}>
                <p className=" text-start black10">just now</p>
                <p className="pink12" style={{ marginTop: "0.75rem" }}>
                  {company.title}
                </p>
                <p
                  className="jobecardChallengeTitle"
                  style={{ marginTop: "0.625rem" }}
                >
                  {truncatedTitle}
                </p>
              </div>
            </div>
            <div className="space ">
              <div
                className="center shadowa"
                style={{
                  height: "56px",
                  maxHeight: "56px",
                  width: "56px",
                  maxWidth: "56px",
                  padding: "auto",
                  background: "#FFF",
                  alignItems: "center",
                  borderRadius: "6px",
                  position: "relative",
                  marginTop: "-28px",
                  marginLeft: "8px",
                }}
              >
                <img
                  src={company.img}
                  alt="company"
                  style={{ maxWidth: "42px" }}
                />
              </div>
              <div className="jobCardBlueChip">
                <img
                  src={sandclock}
                  alt="sand clock"
                  height="14px"
                  width="14px"
                />
                <p className="black12">{position}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Step6;
