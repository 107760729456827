import React, { useState, useEffect, useRef } from "react";
import Chart from "chart.js/auto";

const Completed = ({ totalCount, challenges, previousMonthCompleted }) => {
  const [chartData, setChartData] = useState({});

  const processDateData = (dates, currentDay) => {
    const currentMonth = new Date().getMonth() + 1; // Adding 1 because getMonth() returns zero-based index
    const data = Array.from({ length: currentDay }, (_, index) => {
      const day = index + 1;

      const count = dates.filter(
        (date) =>
          new Date(date.date).getDate() === day &&
          new Date(date.date).getMonth() + 1 === currentMonth // Check if the date is in the current month
      ).length;
      return count;
    });

    return data;
  };

  const [percentagevs, setPercentageDifference] = useState(0);

  useEffect(() => {
    if (totalCount >= previousMonthCompleted) {
      const difference = totalCount - previousMonthCompleted;
      const percentageDiff = (
        (difference / previousMonthCompleted) *
        100
      ).toFixed(0);
      setPercentageDifference(percentageDiff);
    } else {
      const difference = previousMonthCompleted - totalCount;
      const percentageDiff = ((difference / totalCount) * 100).toFixed(0);

      setPercentageDifference(percentageDiff);
    }
  }, [totalCount, previousMonthCompleted]);

  const chartRef = useRef(null);

  useEffect(() => {
    if (challenges.length > 0) {
      const clickDates = challenges.reduce((allDates, challenge) => {
        return [...allDates, ...challenge.countDates];
      }, []);

      const today = new Date();

      const currentDay = today.getDate();
      const data = processDateData(clickDates, currentDay);

      const gradient = chartRef.current
        ?.getContext("2d")
        .createLinearGradient(0, 0, 0, 64);
      gradient.addColorStop(0, "#ECFDF3"); // Top color
      gradient.addColorStop(1, "#FFF");

      setChartData({
        labels: Array.from(
          { length: data.length },
          (_, index) => `${index + 1}`
        ),
        datasets: [
          {
            label: false,
            fill: true,
            lineTension: 0.5,
            backgroundColor: gradient,
            borderColor: "#17B26A",
            borderWidth: 2,
            data: data,
          },
        ],
      });
    }
  }, [challenges]);

  useEffect(() => {
    if (chartRef.current) {
      // Destroy existing Chart instance
      const existingChart = Chart.getChart(chartRef.current);
      if (existingChart) {
        existingChart.destroy();
      }
      chartRef.current.width = 128;
      chartRef.current.height = 64;
      // Create a new Chart instance
      const newChart = new Chart(chartRef.current, {
        type: "line",
        data: chartData,
        options: {
          scales: {
            x: {
              display: false,
            },
            y: {
              display: false,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
          tooltips: {
            enabled: false,
          },
        },
      });
    }
  }, [chartData]);

  return (
    <div
      className="col-md-12 col-12 d-flex"
      style={{ marginTop: "24px", justifyContent: "space-between" }}
    >
      <div className="col-md-7">
        <p className="col-md-12 numbertesttaken"> {totalCount}</p>
        {previousMonthCompleted !== 0 && (
          <p
            className={`col-md-12  ${
              totalCount > previousMonthCompleted ? "vslastmonthtext" : "red"
            }`}
            style={{ marginTop: "1rem" }}
          >
            <span>
              {totalCount > previousMonthCompleted ||
              totalCount === previousMonthCompleted ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M9.99935 15.8337V4.16699M9.99935 4.16699L4.16602 10.0003M9.99935 4.16699L15.8327 10.0003"
                    stroke="#17B26A"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M9.99935 4.16699V15.8337M9.99935 15.8337L4.16602 10.0003M9.99935 15.8337L15.8327 10.0003"
                    stroke="red"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    transform="rotate(0 10 10)"
                  />
                </svg>
              )}
              {percentagevs}%
            </span>{" "}
            vs last month
          </p>
        )}
      </div>
      <div className="col-md-4 d-flex" style={{ justifyContent: "end" }}>
        <div style={{ height: "64px", width: "128px" }}>
          <canvas className="" ref={chartRef}></canvas>
        </div>
      </div>
    </div>
  );
};

export default Completed;
