import { useMediaQuery } from "@mui/material";
import StarBorderIcon from "@mui/icons-material/StarBorder";
const Fourth = () => {
  const list1 = [
    {
      title: "Create a Profile",
      text: "Create an institution profile for employers to explore with all relevant metrics.",
    },
    {
      title: "Increase Visibility",
      text: "Boost your institution's presence making it easier for more employers to discover and connect with your university.",
    },
    {
      title: "Publish Career Fairs",
      text: "Offer employers opportunities to participate and sponsor on-campus career fairs.",
    },
    {
      title: "Connect & Discuss",
      text: "Connect with employers on varyance and discuss opportunities via messenger.",
    },
  ];

  const isNon = useMediaQuery("(max-width:768px)");

  return (
    <div className="marginsleftrightCompany">
      <div className="center">
        <div className="headerCompany">
          <p className="text-center gray80016500">Employer Connect</p>
          <p
            className="text-center newheadingtextthirdsection"
            style={{ marginTop: "1rem" }}
          >
            Expand employer relations
          </p>
          <div className="center">
            <p
              className="text-center gray95016500"
              style={{ marginTop: "1rem", maxWidth: "500px", fontWeight: 400 }}
            >
              Expand your reach by connecting with more employers eager to
              engage with the exceptional talent at your university.
            </p>
          </div>
          <div
            className="d-flex flex-wrap"
            style={{ marginTop: "3.75rem", gap: "1.5rem" }}
          >
            {list1.map((item, index) => (
              <div
                key={index}
                className="center align-items-center"
                style={{
                  background: "#FFF",
                  width: !isNon
                    ? "calc((100% - 1 * 1.5rem) / 2)"
                    : isNon
                    ? "100%"
                    : "100%", // Width calculation for 3 items
                  maxWidth: !isNon
                    ? "calc((100% - 1 * 1.5rem) / 2)"
                    : isNon
                    ? "100%"
                    : "100%",
                  minHeight: "232px",
                  borderRadius: "5px",
                  border: "1px solid var(--Secondary-Color-Gray-200, #EAECF0)",
                  background: "#FCFCFD",
                  padding: "1.25rem",
                  boxSizing: "border-box", // Ensure padding is included in width
                }}
              >
                <div>
                  <div className="center">
                    <div className="stepschips">Step {index + 1}</div>
                  </div>

                  <p
                    className="gray95020400 text-center"
                    style={{
                      fontWeight: 500,
                      marginTop: "1.25rem",
                      fontFamily: "Poppins",
                    }}
                  >
                    {item.title}
                  </p>
                  <p
                    style={{ marginTop: "0.75rem", fontSize: "1rem" }}
                    className="gray70014400 text-center"
                  >
                    {item.text}
                  </p>
                </div>
              </div>
            ))}
            <div
              className="center align-items-center"
              style={{
                background: "#FFF",
                width: "100%", // Width calculation for 3 items
                maxWidth: "100%",
                minHeight: "232px",
                borderRadius: "5px",
                border: "1px solid var(--Secondary-Color-Gray-200, #EAECF0)",
                background: "#FCFCFD",
                padding: "1.25rem",
                boxSizing: "border-box", // Ensure padding is included in width
              }}
            >
              <div>
                <div className="center">
                  <div
                    className="stepschips"
                    style={{
                      background: "rgba(227, 27, 84, 0.10)",
                      color: "#E31B54",
                      width: "fit-content",
                    }}
                  >
                    <StarBorderIcon
                      style={{
                        height: "16px",
                        width: "16px",
                        color: "#E31b54",
                      }}
                    />{" "}
                    Employer Events
                  </div>
                </div>

                <p
                  className="gray95020400 text-center"
                  style={{
                    fontWeight: 500,
                    marginTop: "1.25rem",
                    fontFamily: "Poppins",
                  }}
                >
                  Campus Recruiting
                </p>
                <p
                  style={{
                    marginTop: "0.75rem",
                    fontSize: "1rem",
                    maxWidth: "510px",
                  }}
                  className="gray70014400 text-center"
                >
                  Offer employers the opportunity to advertise and host both
                  on-campus and online events for your students and graduates
                  within your terms and conditions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Fourth;
