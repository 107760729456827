import logo from "../../assets/images/logo.png";
import { useMediaQuery } from "@mui/material";
import "./login.css";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setId } from "../../state";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
const CreateNewPassword = () => {
  const isNonMobile768 = useMediaQuery("(min-width: 768px)");
  const { uniqueId } = useParams();

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");

  const [error, setError] = useState("");
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!uniqueId) {
      navigate("/company/login");
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!password) {
      toast.error("Please enter Password");
      return;
    } else if (password.length < 8) {
      toast.error("Password must be at least 8 characters");
      return;
    } else if (password !== confirmPass) {
      toast.error("Passwords do not match");
      return;
    }
    setLoader(true);
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/company/update/password`,
        {
          password: password,
          uniqueId: uniqueId,
        }
      )
      .then((res) => {
        if (res.data?.msg === "success") {
          toast.success("Password Updated Successfully");
          navigate("/company/login");
        } else if (res.data?.msg === "User Not found") {
          toast.error("User Not found");
        } else if (res.data?.msg === "Enter Password") {
          toast.error("Enter Password");
        }
        setLoader(false);
      })
      .catch((err) => {
        setError("Error Updating Password");
        console.log(err);
        setLoader(false);
      });
  };

  return (
    <div className="loginbackground d-flex">
      <div style={{ width: isNonMobile768 ? "50%" : "100%" }}>
        <div
          className="marginsleftrightCompany"
          style={{ marginTop: "2.5rem" }}
        >
          <img
            src={logo}
            alt="logo"
            height="36px"
            width="36px"
            onClick={() => navigate("/")}
            style={{ cursor: "pointer" }}
          />
          <div
            style={{
              width: "100%",
              marginTop: "2.5rem",
            }}
          >
            <form onSubmit={(e) => handleSubmit(e)}>
              <p className="registeration">Update Password</p>
              <p style={{ marginTop: "6px" }} className="headersmalltext">
                Please enter your new password.
              </p>

              <div style={{ marginTop: "1.25rem" }}>
                <label htmlFor="password" className="Email">
                  Password
                </label>
                <div className="position-relative">
                  <input
                    id="password"
                    value={password}
                    required
                    type={showPassword ? "text" : "password"}
                    onChange={(e) => setPassword(e.target.value)}
                    className="rounded-3 newinputs mt-3 col-md-12 col-12 "
                    style={{ marginTop: "14px" }}
                  />
                  <div
                    className="position-absolute end-10 top-50 translate-middle-y"
                    style={{
                      right: "10px",
                      cursor: "pointer",
                      maxWidth: "440px",
                    }}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <VisibilityOffOutlinedIcon />
                    ) : (
                      <RemoveRedEyeOutlinedIcon />
                    )}
                  </div>
                </div>
              </div>
              {error && (
                <p
                  className="headersmalltext"
                  style={{ marginTop: "0.5rem", color: "red" }}
                >
                  {error}
                </p>
              )}
              <div style={{ marginTop: "1.25rem" }}>
                <label htmlFor="confirmpassword" className="Email">
                  Confirm Password
                </label>
                <div className="position-relative">
                  <input
                    id="confirmpassword"
                    value={confirmPass}
                    required
                    type={showPassword2 ? "text" : "password"}
                    onChange={(e) => setConfirmPass(e.target.value)}
                    className="rounded-3 newinputs mt-3 col-md-12 col-12 "
                    style={{ marginTop: "14px" }}
                  />
                  <div
                    className="position-absolute end-10 top-50 translate-middle-y"
                    style={{
                      right: "10px",
                      cursor: "pointer",
                      maxWidth: "440px",
                    }}
                    onClick={() => setShowPassword2(!showPassword2)}
                  >
                    {showPassword2 ? (
                      <VisibilityOffOutlinedIcon />
                    ) : (
                      <RemoveRedEyeOutlinedIcon />
                    )}
                  </div>
                </div>
              </div>
              <button
                className="continuebutton col-md-12 col-12"
                style={{ marginTop: "1.25rem" }}
                type="submit"
                disabled={loader}
              >
                Update Password
              </button>
            </form>

            <p className="didnt text-center " style={{ marginTop: "1.5rem" }}>
              Back to
              <span
                className="hoverdarkred"
                onClick={() => navigate("/company/login")}
              >
                {" "}
                Login
              </span>
            </p>
          </div>
        </div>
      </div>
      {isNonMobile768 && (
        <div style={{ width: "50%" }} className="companyrightimg"></div>
      )}
    </div>
  );
};

export default CreateNewPassword;
