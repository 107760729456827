import { useEffect, useRef, useState } from "react";
import Canvas from "./Canvas";

const CanvasData = ({ selectedChallenge, users, view, True }) => {
  const [participants, setPartiticipants] = useState([]);

  useEffect(() => {
    const newParticipants = users.map((use) => ({
      id: use?._id,
      gender: use?.gender === "I prefer not to say" ? "Other" : use?.gender,
      study: use?.study,
    }));

    if (JSON.stringify(newParticipants) !== JSON.stringify(participants)) {
      setPartiticipants(newParticipants);
    }
  }, [users]);

  const [chartData, setChartData] = useState({ labels: [], data: [] });

  useEffect(() => {
    const countOccurrences = (key) => {
      return participants.reduce((acc, participant) => {
        const keyValue = participant[key];
        if (acc[keyValue]) {
          acc[keyValue] += 1;
        } else {
          acc[keyValue] = 1;
        }
        return acc;
      }, {});
    };

    const processChartData = (dataObj) => {
      const sortedEntries = Object.entries(dataObj).sort((a, b) => b[1] - a[1]);

      const topEntries = sortedEntries.slice(0, True ? 3 : 7);
      const otherCount = sortedEntries
        .slice(7)
        .reduce((acc, [, count]) => acc + count, 0);

      const labels = topEntries.map(
        ([key, count]) =>
          `${key} ${count} ${((count * 100) / participants.length).toFixed(0)}%`
      );
      const data = topEntries.map(([, count]) => count);

      if (otherCount > 0) {
        labels.push(
          `Others ${otherCount} ${(
            (otherCount * 100) /
            participants.length
          ).toFixed(0)}%`
        );
        data.push(otherCount);
      }

      return { labels, data };
    };

    if (view === "gender") {
      const genderCount = countOccurrences("gender");
      setChartData(processChartData(genderCount));
    } else if (view === "fieldofstudy") {
      const studyCount = countOccurrences("study");
      setChartData(processChartData(studyCount));
    }
  }, [participants, view]);

  return (
    <div>
      <Canvas
        participants={participants}
        labels={chartData.labels}
        data={chartData.data}
        view={view}
        True={True}
      />
    </div>
  );
};

export default CanvasData;
