import React, { useEffect, useRef, useState } from "react";
import card1 from "../../assets/images/newcard.png";
import SchoolIcon from "@mui/icons-material/School";
import { useMediaQuery } from "@mui/material";
import { FixedSizeList as List } from "react-window";
import { useDispatch } from "react-redux";
import kpmg from "../../assets/images/logosCOmpany/KPMG.png";
import cover2 from "../../assets/images/card2cover.png";
import mastercard from "../../assets/images/logosCOmpany/mastercard.png";
import uniicon from "../../assets/images/uniicon.png";
import img from "../../assets/images/homecard2.png";
import { setUni } from "../../state";
import { useNavigate } from "react-router-dom";
import NewChallengeCard from "./NewChallengeCard";
import cardscover from "../../assets/images/cardscover.png";
const HomeFirstSection = ({
  selectedUni,
  setSelectedUni,
  country,
  setCountry,
  Countries,
  unilist,
  loading,
  checker,
  setChecker,
  truncateString,
  isNonMobile450,
  isNonMobile1024,
}) => {
  const isNonMobile = useMediaQuery("(min-width:768px)");

  const isNonMobile1440 = useMediaQuery("(min-width:1440px)");
  const isNonMobile1100 = useMediaQuery("(min-width:1100px)");
  const [showUni, setShowUni] = useState(false);
  const [search, setSearch] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const unilistinputdivRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        unilistinputdivRef.current &&
        !unilistinputdivRef.current.contains(event.target)
      ) {
        setShowUni(false); // Close showUni when clicked outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleUniClick = () => {
    setShowUni(!showUni);
  };
  const [searchList, setSearchList] = useState(unilist);
  useEffect(() => {
    setSearchList(unilist);
  }, [unilist]);

  const [countrySearchList, SetCountriesList] = useState(Countries);
  const filterCountries = () => {
    if (!search) {
      SetCountriesList(Countries); // Reset searchList if search input is empty
    } else {
      const filteredUniversities = Countries.filter((uni) =>
        uni.name.toLowerCase().includes(search.toLowerCase())
      );
      SetCountriesList(filteredUniversities);
    }
  };

  // Update searchList whenever search state changes
  useEffect(() => {
    filterCountries();
  }, [search]);

  const filterUniversities = () => {
    if (!search) {
      setSearchList(unilist); // Reset searchList if search input is empty
    } else {
      const filteredUniversities = unilist.filter((uni) =>
        uni.name.toLowerCase().includes(search.toLowerCase())
      );
      setSearchList(filteredUniversities);
    }
  };

  // Update searchList whenever search state changes
  useEffect(() => {
    filterUniversities();
  }, [search]);

  const isValidImage = async (base64String) => {
    try {
      await new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve();
        img.onerror = () => reject();
        img.src = `${base64String}`;
      });
      return true; // Image loaded successfully
    } catch (error) {
      return false; // Error occurred while loading image
    }
  };

  const ValidateLogo = async (base64String) => {
    return await isValidImage(base64String);
  };

  const renderUniversityItem = ({ index, style }) => {
    const uni = searchList[index];
    return (
      <div
        key={index}
        className="d-flex unilists "
        style={{
          ...style,
          gap: "0.5rem",
          width: "97%",

          alignItems: "center",
        }}
        onClick={() => {
          setSelectedUni(uni);
          setShowUni(false);
          dispatch(setUni({ Uni: uni }));
        }}
      >
        {uni.logo && ValidateLogo(uni.logo) ? (
          <img
            src={`${uni.logo}`}
            alt="University Logo"
            style={{ width: "40px", height: "40px", objectFit: "contain" }}
            className="Interest_img_icon shadowa rounded-5"
          />
        ) : (
          <>
            <img
              src={uniicon}
              alt="University Logo"
              style={{ width: "40px", height: "40px", objectFit: "contain" }}
              className="Interest_img_icon shadowa rounded-5"
            />
          </>
        )}
        <p className="uniselecttext d-flex" style={{ alignItems: "center" }}>
          {uni.name}
        </p>
      </div>
    );
  };

  const renderUniversityList = () => (
    <List
      height={250}
      itemCount={searchList.length}
      itemSize={isNonMobile ? 55 : 70}
      width={"100%"}
      className="overflowx whitescrollbar"
    >
      {renderUniversityItem}
    </List>
  );

  const [changeLocation, ShowChangeLocation] = useState(country ? false : true);

  return (
    <>
      <div className="d-flex" style={{ justifyContent: "center" }}>
        <div className="headerCompany margintopcompany">
          <div
            className={`${isNonMobile1100 && "d-flex"} `}
            style={{ gap: "2.5rem" }}
          >
            <div style={{ width: isNonMobile1100 ? "65%" : "100%" }}>
              <div>
                {/* <p className="smallheadingcompany">Fast-Track your</p> */}
                <p
                  className="mainheadingcompany"
                  style={{
                    display: "inline-block",
                    gap: "20px",
                    maxWidth: "442px",
                  }}
                >
                  Explore your future career.{" "}
                </p>
                <p
                  className="newblack18"
                  style={{
                    marginTop: "1rem",
                    maxWidth: "650px",
                    fontSize: isNonMobile450 ? "16px" : "",
                  }}
                >
                  Take-on{" "}
                  <span style={{ fontSize: isNonMobile450 ? "16px" : "" }}>
                    virtual challenges
                  </span>{" "}
                  designed by investment banks, big law, consulting firms and
                  industry leaders{" "}
                  <span style={{ fontSize: isNonMobile450 ? "16px" : "" }}>
                    to explore your future career.
                  </span>
                </p>
                <span
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    position: "relative",
                    // top: isNonMobile1024 ? "-5px" : "10px",
                    gap: "1rem",
                    marginTop: "0.5rem",

                    alignItems: "center",
                  }}
                  className=""
                >
                  <div
                    className="unilistinputdiv d-flex"
                    ref={unilistinputdivRef}
                    onClick={handleUniClick}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="uniselect">
                      {selectedUni &&
                      selectedUni.logo &&
                      ValidateLogo(selectedUni.logo) ? (
                        <img
                          src={`${selectedUni.logo}`}
                          className="rounded-3"
                          alt={uniicon}
                          style={{
                            objectFit: "contain",
                            maxWidth: isNonMobile1440 ? "40px" : "32px",
                            maxHeight: isNonMobile1440 ? "40px" : "32px",
                            // maxWidth: "100%",
                            // maxHeight: "100%",
                          }}
                        />
                      ) : (
                        <div
                          className={`rounded-5 d-flex ${
                            isNonMobile1440 ? "p-2" : "p-1"
                          } notselecteduni `}
                          style={{
                            height: isNonMobile1440 ? "40px" : "32px",
                            width: isNonMobile1440 ? "40px" : "32px",
                            backgroundColor: country ? "#FFF" : "",
                          }}
                        >
                          {" "}
                          {country && country.icon ? (
                            <img
                              src={country.icon}
                              alt={uniicon}
                              className="rounded-3"
                              style={{
                                objectFit: "contain",
                                maxWidth: "100%",
                                maxHeight: "100%",
                              }}
                            />
                          ) : (
                            <SchoolIcon
                              style={{
                                color: "white",
                              }}
                            />
                          )}
                        </div>
                      )}

                      <p className="uniselecttext">
                        {selectedUni
                          ? isNonMobile1024
                            ? truncateString(`${selectedUni.name}`, 100)
                            : truncateString(selectedUni.name, 53)
                          : "Select your university to register"}
                      </p>
                    </div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M5 7.5L10 12.5L15 7.5"
                        stroke="#1D2939"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>

                  {showUni && (
                    <div
                      ref={unilistinputdivRef}
                      style={{
                        position: "absolute",
                        overflow: "hidden",
                        top: "0",
                        zIndex: 10,
                      }}
                      className="showUnilistmodal "
                    >
                      <div className="paddingsinsearchlist">
                        <div className="searchinput">
                          <div style={{ minHeight: "20px", minWidth: "20px" }}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <path
                                d="M19 19L15.5001 15.5M18 9.5C18 14.1944 14.1944 18 9.5 18C4.80558 18 1 14.1944 1 9.5C1 4.80558 4.80558 1 9.5 1C14.1944 1 18 4.80558 18 9.5Z"
                                stroke="#475467"
                                strokeWidth="1.70603"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                          <input
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            type="text"
                            placeholder="Search"
                            className="searchinputuni"
                          />
                        </div>
                        <hr style={{ width: "97%" }} />
                        <div
                          className=""
                          style={{ overflow: "auto", maxHeight: "20.438rem" }}
                        >
                          {changeLocation && !checker ? (
                            countrySearchList.map((con, index) => (
                              <div
                                key={index} // Make sure to add a unique key when mapping
                                className="d-flex unilists "
                                style={{ gap: "0.5rem", marginTop: "1.25rem" }}
                                onClick={() => {
                                  setCountry(con);
                                  ShowChangeLocation(false);
                                  setSearch(""); // Assuming 'con' is the selected university in this context
                                }}
                              >
                                <img
                                  src={con.icon}
                                  alt="uni logo"
                                  height="40px"
                                  width="40px"
                                  className="Interest_img_icon rounded-5"
                                />
                                <p
                                  className="uniselecttext d-flex"
                                  style={{ alignItems: "center" }}
                                >
                                  {con.name}
                                </p>
                              </div>
                            ))
                          ) : (
                            <>
                              {loading ? (
                                <div
                                  className="d-flex"
                                  style={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <p
                                    style={{
                                      fontSize: "1rem",
                                      fontFamily: "Poppins",
                                      fontWeight: "500",
                                    }}
                                  >
                                    Fetching Universities
                                  </p>
                                  <div class="loader"></div>
                                </div>
                              ) : (
                                <>{renderUniversityList()}</>
                              )}
                            </>
                          )}
                          <div
                            className="d-flex unilists"
                            style={{ gap: "0.5rem", marginTop: "1.25rem" }}
                            onClick={() => {
                              setSelectedUni({ name: "other" });
                              navigate("/register?other=true");

                              setShowUni(false);
                            }}
                          >
                            <p
                              className="uniselecttext d-flex"
                              style={{ alignItems: "center" }}
                            >
                              other
                            </p>
                          </div>
                        </div>
                        <hr style={{ width: "97%" }} />
                      </div>
                      <div className="bottomsearchlist">
                        <div
                          className="d-flex"
                          style={{
                            justifyContent: "space-between",
                            // width: "85%",
                          }}
                        >
                          <div className="countrydisplay">
                            {country ? (
                              <>
                                <img
                                  src={country.icon}
                                  alt="country flag"
                                  width="16px"
                                  height="16px"
                                />
                                {country.name}
                              </>
                            ) : (
                              "select country"
                            )}
                          </div>
                          <button
                            className="changelocationbutton btn p-0"
                            onClick={() => {
                              ShowChangeLocation(true);
                              setChecker(false);
                            }}
                          >
                            {" "}
                            Change Location
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                  <div
                    className="d-flex"
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <button
                      className="continuebutton "
                      style={{
                        height: "50px",
                        position: "relative",
                        maxHeight: isNonMobile1440 ? "" : "48px",
                        border: "none",
                        width: isNonMobile450 ? "" : "90vw",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/register");
                      }}
                    >
                      Join now
                    </button>
                  </div>
                </span>

                {/* <div
              className={`belowuniselect  ${isNonMobile && "section d-flex"}`}
            >
              <div className="imgsunderuni ">
                <img
                  className="personimage move imgshadow rounded-5"
                  style={{ zIndex: 5 }}
                  src={person1}
                  alt="person"
                />
                <img
                  className="personimage move imgshadow rounded-5"
                  style={{ left: "-1.5rem", zIndex: 4 }}
                  src={person2}
                  alt="person"
                />
                <img
                  className="personimage move imgshadow rounded-5"
                  style={{ left: "-2.5rem", zIndex: 3 }}
                  src={person3}
                  alt="person"
                />
                <img
                  className="personimage move imgshadow rounded-5"
                  style={{ left: "-3.5rem", zIndex: 2 }}
                  src={person4}
                  alt="person"
                />
                <img
                  className="personimage move imgshadow rounded-5"
                  style={{ left: "-4.5rem", zIndex: 1 }}
                  src={person5}
                  alt="person"
                />
              </div>
              <p
                className="challengeengageCompany"
                style={{
                  marginTop: !isNonMobile ? "1.5rem" : "",
                  marginLeft: isNonMobile ? "-40px" : "0px",
                  maxWidth: "580px",
                }}
              >
                Solve <span> virtual challenges</span> designed by investment
                banks, law and consulting firms and industry leaders to{" "}
                <span>explore your career track.</span>
              </p>
            </div> */}
              </div>
            </div>
            {isNonMobile1100 && (
              <div
                style={{ width: "35%", justifyContent: "", gap: "1rem" }}
                className="d-flex"
              >
                <NewChallengeCard
                  title={"101 Introduction into Strategy Consulting"}
                  selectedFile={cardscover}
                  company={{
                    title: "KPMG - UK",
                    img: kpmg,
                  }}
                  description={
                    "Discover strategy consulting with KPMG. Learn key concepts, tackle real cases, and build essential business skills."
                  }
                  type={"Virtual Experience"}
                  duration={30}
                />
                {/* <img
                  src={card1}
                  alt="card"
                  className="shadowa"
                  style={{
                    maxWidth: "250px",
                    minWidth: "250px",
                    minHeight: "336px",
                    objectFit: "cover",
                    maxHeight: "336px",
                  }}
                /> */}
                <div>
                  {" "}
                  <NewChallengeCard
                    title={"New business Modeling"}
                    selectedFile={cover2}
                    company={{
                      title: "Mastercard",
                      img: mastercard,
                    }}
                    description={
                      "Work on a new business case, analyze the opportunity, and explore strategic decision-making across key areas."
                    }
                    type={"Fast-Track"}
                    duration={45}
                  />
                  {/* <img
                    src={img}
                    alt="card"
                    className="shadowa"
                    style={{
                      position: "absolute",
                      maxWidth: "250px",
                      minWidth: "250px",
                      minHeight: "336px",
                      maxHeight: "336px",
                      objectFit: "cover",
                    }}
                  /> */}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeFirstSection;
