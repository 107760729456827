import "./terms.css";
import Header from "../../components/CompanyHome/Header";
import { useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import HomeFooter from "../../components/Home/HomeFooter";
import Loader from "../../components/Loader/Loader";
const Terms = () => {
  const [Test, setTest] = useState(false);
  const isNonMobile = useMediaQuery("(min-width: 768px)");
  const isNonMobile450 = useMediaQuery("(min-width: 450px)");
  const navigate = useNavigate();

  const privacyPolicyHtml = `
  
  <html>
  <head>
      <title>Terms of Use</title>
  </head>
  <body>
  
  <h1>Terms of Use</h1>
  
  <h2 id="company-information">1. Company Information</h2>
  <p>Legal Entity Name: Varyance LTD.<br>
  Registered Address: 71-75 Shelton St, London, WC2H 9JQ, United Kingdom<br>
  Contact Information: Email: <a href="mailto:support@varyance.io">support@varyance.io</a><br>
  Company Registration Number: 14662428</p>
  
  <h2 id="dba-name">2. D/B/A (Doing Business As) Name</h2>
  <p>D/B/A Name: varyance or varyance.io</p>
  
  <h2 id="service-description">3. Service Description</h2>
  <p><strong>Mission Statement:</strong> At Varyance, we are dedicated to providing students with the opportunity to explore a wide range of industries and career fields. Our goal is to help students gain valuable insights into their chosen career paths or discover new opportunities in the job market.</p>
  <p><strong>How Varyance Works:</strong> Varyance partners with prominent organizations across various sectors, including but not limited to investment banks, law firms, consulting firms, and other industry leaders. These partners design virtual challenges that offer students first-hand experience with the daily tasks, required skills, and potential opportunities within their respective fields.</p>
  <p><strong>Core Features:</strong></p>
  <ul>
      <li><strong>Real-World Experience:</strong> Gain a deeper understanding of your future career through interactive, real-world scenarios beyond traditional educational settings.</li>
      <li><strong>Skill Enhancement:</strong> Develop and refine the skills necessary to succeed in your career applications and professional development.</li>
      <li><strong>Career Exploration:</strong> If you're undecided about your career path, Varyance provides the tools to explore and gain insights into various industries and professional trajectories.</li>
  </ul>
  <p><strong>Discover and Plan Your Future Career:</strong> Varyance is committed to inspiring students to explore and define their future careers. By using our platform, you agree to engage with the challenges provided by our partners and to use the insights gained responsibly as you plan your professional journey.</p>
  
  <h2 id="target-audience">4. Target Audience</h2>
  <p>The Service is designed for a global audience, specifically targeting employers, universities, other educational institutions, and students. Employers and educational institutions, including but not limited to companies, organizations, universities, and colleges, use the Service to create, manage, and participate in virtual challenges, assessments, and educational activities. Students, including individuals seeking academic advancement, career opportunities, or professional development, use the Service to explore various industries, gain insights into potential career paths, and develop essential skills.</p>
  <p>This Service is available to users worldwide, provided they comply with applicable local laws and regulations. By accessing and using the Service, you affirm that you meet the eligibility criteria and that your use of the Service is lawful in your respective jurisdiction.</p>
  
  <h2 id="geographic-scope">5. Geographic Scope</h2>
  <p>The Service is offered to users worldwide, with the exception of regions where such use would be prohibited by applicable law or regulation. Users are responsible for ensuring that their use of the Service is in compliance with all local laws and regulations within their respective jurisdictions.</p>
  <p>We make no representation that the Service is appropriate or available for use in all locations. Access to the Service from territories where its contents are illegal or restricted is prohibited. Users who choose to access the Service from such locations do so on their own initiative and are responsible for compliance with local laws.</p>
  
  <h2 id="user-eligibility">6. User Eligibility</h2>
  
  <h3 id="minimum-age-requirement">6.1. Minimum Age Requirement</h3>
  <p>The Service is not intended for use by individuals under the age of 13. By accessing or using the Service, you confirm that you are at least 13 years old. If you are under 18 years old, you may only use the Service under the supervision of a parent or legal guardian who agrees to be bound by these Terms of Use.</p>
  <p>We do not knowingly collect personal information from individuals under 13. If we become aware that we have inadvertently collected personal information from someone under the age of 13, we will take steps to delete such information as soon as possible.</p>
  
  <h3 id="account-requirements">6.2. Account Requirements</h3>
  <ul>
      <li><strong>Accurate Information:</strong> Users must provide accurate, current, and complete information during the account registration process. Users are responsible for keeping their account information up-to-date.</li>
      <li><strong>Account Security:</strong> Users are required to choose a strong, secure password and keep it confidential. Users are responsible for all activities that occur under their account, including any unauthorized use.</li>
      <li><strong>One Account Per User:</strong> Typically, users are allowed to create and maintain only one account unless expressly permitted by the service.</li>
      <li><strong>Restrictions on Account Sharing:</strong> Users are not permitted to share their account with others or allow others to access their account. Users may not transfer or assign their account to another person or entity without the company’s written consent.</li>
      <li><strong>Compliance with Terms:</strong> Users must comply with all terms and conditions of the service, as outlined in the Terms of Use. Violation of these terms may result in suspension or termination of the user’s account.</li>
      <li><strong>Age and Eligibility Verification:</strong> Users may be required to verify their age and eligibility to use the service, particularly if there are age restrictions or specific user qualifications.</li>
      <li><strong>Communication Preferences:</strong> Users may need to set and manage their communication preferences for receiving notifications, updates, and marketing materials.</li>
      <li><strong>Deactivation and Termination:</strong> Users must follow the appropriate procedures to deactivate or terminate their account if they no longer wish to use the service. The service provider reserves the right to terminate accounts that violate the Terms of Use or engage in prohibited activities.</li>
  </ul>
  
  <h2 id="user-obligations">7. User Obligations</h2>
  
  <h3 id="acceptable-use-policy">7.1. Acceptable Use Policy</h3>
  <p>By accessing and using the Service, you agree to comply with this Acceptable Use Policy. The following activities are strictly prohibited:</p>
  <ul>
      <li><strong>Illegal Activities:</strong> Engaging in any activity that is illegal under applicable law or regulation. Using the Service to commit fraud, theft, or any other unlawful acts.</li>
      <li><strong>Harmful Content and Conduct:</strong> Posting, transmitting, or distributing content that is harmful, abusive, harassing, defamatory, or otherwise objectionable. Engaging in behavior that could harm, intimidate, or endanger others.</li>
      <li><strong>Infringement of Intellectual Property:</strong> Uploading, sharing, or distributing content that infringes upon the intellectual property rights of others, including copyrights, trademarks, and patents.</li>
      <li><strong>Security Violations:</strong> Attempting to gain unauthorized access to any part of the Service, including accounts, computer systems, or networks connected to the Service. Introducing viruses, malware, or other harmful code into the Service.</li>
      <li><strong>Spamming and Unsolicited Communications:</strong> Sending unsolicited messages, spam, or promotional materials through the Service. Harvesting or collecting information about other users without their consent.</li>
      <li><strong>Misrepresentation and False Information:</strong> Misrepresenting your identity, affiliations, or the source of any content you provide. Providing false, misleading, or inaccurate information to us or other users.</li>
      <li><strong>Interference with the Service:</strong> Disrupting or interfering with the operation of the Service, including by overloading, flooding, or spamming. Attempting to circumvent any security features or restrictions placed on the Service.</li>
      <li><strong>Prohibited Content:</strong> Uploading, sharing, or distributing content that is obscene, offensive, or inappropriate. Promoting or endorsing violence, discrimination, or illegal activities.</li>
      <li><strong>Commercial Activities:</strong> Using the Service for unauthorized commercial purposes, such as selling or promoting products or services without our express permission.</li>
      <li><strong>Compliance with Laws:</strong> You must comply with all applicable local, national, and international laws and regulations in your use of the Service.</li>
  </ul>
  
  <h3 id="enforcement-and-consequences">7.2. Enforcement and Consequences</h3>
  <p>We reserve the right to monitor your use of the Service to ensure compliance with this Acceptable Use Policy. Violation of this Acceptable Use Policy may result in the suspension or termination of your account, legal action, or other appropriate remedies.</p>
  
  <h3 id="reporting-violations">7.3. Reporting Violations</h3>
  <p>If you become aware of any violation of this Acceptable Use Policy, please report it to us immediately at <a href="mailto:support@varyance.io">support@varyance.io</a>.</p>
  
  <h3 id="user-content-policy">7.4. User Content Policy</h3>
  <p>By using the Service, you agree to the following terms regarding any content ("User Content") you create, upload, share, or otherwise make available through the Service:</p>
  
  <h4 id="ownership-of-user-content">7.4.1. Ownership of User Content</h4>
  <p>You retain ownership of any User Content that you create, upload, or share on the Service. This includes, but is not limited to, text, images, videos, company logos, and trademarks. However, by posting User Content on the Service, you grant us a worldwide, non-exclusive, royalty-free, transferable, sublicensable license to use, reproduce, distribute, prepare derivative works of, display, and perform that User Content in connection with the operation of the Service, including promoting and redistributing part or all of the Service in any media formats and through any media channels.</p>
  
  <h4 id="responsibility-for-user-content">7.4.2. Responsibility for User Content</h4>
  <p>You are solely responsible for the User Content that you upload, share, or otherwise make available on the Service. This includes ensuring that your User Content does not infringe on the rights of others and complies with all applicable laws and regulations.</p>
  
  <h4 id="prohibited-user-content">7.4.3. Prohibited User Content</h4>
  <p>You agree not to upload, share, or otherwise make available any User Content that:</p>
  <ul>
      <li>Is unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, libelous, invasive of another's privacy, hateful, or racially, ethnically, or otherwise objectionable.</li>
      <li>Infringes upon or violates any intellectual property rights, privacy rights, or other rights of any third party.</li>
      <li>Contains viruses, malware, or any other harmful or malicious code.</li>
      <li>Promotes or endorses illegal activities or conduct that is abusive, threatening, obscene, defamatory, or libelous.</li>
      <li>Misrepresents your identity or affiliations, or is intended to deceive or defraud others.</li>
  </ul>
  
  <h4 id="monitoring-and-moderation">7.4.4. Monitoring and Moderation</h4>
  <p>We reserve the right, but are not obligated, to monitor, review, or remove any User Content at our sole discretion for any reason, including for violation of this User Content Policy or if it is otherwise objectionable. We may also remove or disable access to User Content that is alleged to infringe upon the rights of others, including copyright, trademark, or other intellectual property rights.</p>
  
  <h4 id="reporting-violations">7.4.5. Reporting Violations</h4>
  <p>If you believe that any User Content on the Service violates this User Content Policy or infringes your rights, please report it to us immediately at <a href="mailto:support@varyance.io">support@varyance.io</a>.</p>
  
  <h4 id="consequences-of-violations">7.4.6. Consequences of Violations</h4>
  <p>Violating this User Content Policy may result in the removal of your User Content, suspension or termination of your account, and any other legal actions or remedies available to us.</p>
  
  <h4 id="indemnification">7.4.7. Indemnification</h4>
  <p>You agree to indemnify and hold harmless Varyance LTD., its affiliates, officers, directors, employees, and agents from any claims, damages, liabilities, and expenses (including legal fees) arising out of or related to your User Content or any breach of this User Content Policy.</p>
  
  <h2 id="subscription-and-fees">8. Subscription and Fees</h2>
  
  <h3 id="fees-for-companies">8.1. Fees for Companies</h3>
  <p>Companies, organizations, and other entities that utilize the Service for commercial purposes are required to pay fees as outlined in our "Terms for Pages." These fees are applicable for access to specific features, tools, or services provided by our platform.</p>
  <p>The fees, billing cycles, and payment terms for companies are clearly described in our "Terms for Pages." By agreeing to these Terms, companies acknowledge their obligation to pay the specified fees in accordance with the payment terms outlined therein.</p>
  
  <h3 id="no-fees-for-students-and-educational-institutions">8.2. No Fees for Students and Educational Institutions</h3>
  <p>Students, universities, and other educational institutions may access the Service without any subscription fees, unless otherwise agreed upon in a separate contract. Access to the Service for these users is generally provided free of charge, subject to the terms outlined in these Terms of Use.</p>
  
  <h3 id="payment-terms">8.3. Payment Terms</h3>
  <p>Fees for companies must be paid in advance according to the billing cycle specified in the "Terms for Pages." Accepted payment methods and billing procedures are detailed in the "Terms for Pages" and must be adhered to by all companies.</p>
  <p>All fees are non-refundable, except as required by law or as otherwise provided in the "Terms for Pages."</p>
  <p>Companies are responsible for providing accurate billing information and for keeping this information up to date. Failure to pay fees when due may result in the suspension or termination of access to the Service for the company involved.</p>
  
  <h3 id="changes-to-fees">8.4. Changes to Fees</h3>
  <p>We reserve the right to modify the fees for our Service at any time. Any changes to the fees will be communicated in advance and will only take effect at the beginning of the next billing cycle as detailed in the "Terms for Pages."</p>
  <p>If a company does not agree to the new fees, they must notify us in writing before the start of the new billing cycle and may choose to terminate their use of the Service as provided in the "Terms for Pages."</p>
  
  <h3 id="taxes">8.5. Taxes</h3>
  <p>All fees described in the "Terms for Pages" are exclusive of applicable taxes. Companies are responsible for paying any taxes, duties, or other governmental levies associated with their use of the Service as specified in the "Terms for Pages."</p>
  
  <h2 id="intellectual-property">9. Intellectual Property</h2>
  
  <h3 id="ownership-of-intellectual-property">9.1. Ownership of Intellectual Property</h3>
  <p>All intellectual property rights in and to the Service, including but not limited to the software, content, features, design, graphics, trademarks, and all other materials available on or through the Service (collectively, "Service Content"), are owned by or licensed to Varyance LTD., its affiliates, or its licensors. All rights not expressly granted to you in these Terms are reserved by Varyance LTD. and its licensors.</p>
  
  <h3 id="license-to-use-the-service">9.2. License to Use the Service</h3>
  <p>Subject to your compliance with these Terms, Varyance LTD. grants you a limited, non-exclusive, non-transferable, non-sublicensable license to access and use the Service for its intended purposes, as described in these Terms of Use and the "Terms for Pages." This license is granted for personal or internal business use only and does not include any rights to:</p>
  <ul>
      <li>Modify, copy, or create derivative works based on the Service Content;</li>
      <li>Reverse engineer, decompile, or disassemble any part of the Service;</li>
      <li>Use any data mining, robots, or similar data gathering or extraction methods on the Service;</li>
      <li>Redistribute, sublicense, lease, sell, rent, or otherwise transfer rights to the Service Content;</li>
      <li>Remove any copyright, trademark, or other proprietary notices from the Service Content.</li>
  </ul>
  
  <h3 id="user-content">9.3. User Content</h3>
  <p>As stated in the "User Content Policy," you retain ownership of any content you upload, share, or otherwise make available on the Service ("User Content"). This includes, but is not limited to, text, images, videos, company logos, and trademarks. However, by submitting User Content, you grant Varyance LTD. a worldwide, non-exclusive, royalty-free, transferable, sublicensable license to use, reproduce, distribute, prepare derivative works of, display, and perform your User Content in connection with the operation of the Service, including for promoting and redistributing part or all of the Service in any media formats and through any media channels.</p>
  
  <h3 id="trademarks">9.4. Trademarks</h3>
  <p>The trademarks, logos, service marks, and trade names displayed on the Service (collectively, the "Trademarks") are registered and unregistered Trademarks of Varyance LTD. and its affiliates or licensors. You may not use any Trademark without the prior written permission of Varyance LTD. or the applicable trademark holder.</p>
  <p><strong>User-Uploaded Trademarks:</strong> If you upload or submit any trademarks, logos, or service marks ("User Trademarks") to the Service, you hereby grant Varyance LTD. a worldwide, non-exclusive, royalty-free, transferable, sublicensable license to use, reproduce, display, and distribute the User Trademarks in connection with the operation, marketing, and promotion of the Service. You represent and warrant that you own or have the rights to grant such a license for any User Trademarks you upload.</p>
  
  <h3 id="feedback">9.5. Feedback</h3>
  <p>If you provide Varyance LTD. with any suggestions, feedback, or comments regarding the Service ("Feedback"), you grant Varyance LTD. a perpetual, irrevocable, non-exclusive, royalty-free, fully paid, worldwide license to use, copy, modify, create derivative works from, distribute, and incorporate such Feedback into the Service or any other products or services, without any compensation to you.</p>
  
  <h3 id="infringement-claims">9.6. Infringement Claims</h3>
  <p>Varyance LTD. respects the intellectual property rights of others and expects users to do the same. If you believe that any content on the Service infringes your intellectual property rights, please contact us immediately at <a href="mailto:support@varyance.io">support@varyance.io</a> with detailed information about the alleged infringement. We will investigate and, if appropriate, remove or disable access to the infringing content in accordance with applicable laws.</p>
  
  <h2 id="privacy-and-data-protection">10. Privacy and Data Protection</h2>
  
  <h3 id="personal-data-collection">10.1. Personal Data Collection</h3>
  <p>Varyance LTD. is committed to protecting the privacy of our users. We collect personal data that is necessary for the operation of our Service. The types of personal data we collect include, but are not limited to:</p>
  <ul>
      <li><strong>Account Information:</strong> Name, email address, company name, job title, and other relevant contact details provided during registration.</li>
      <li><strong>Usage Data:</strong> Information about how you interact with the Service, including IP addresses, browser type, device identifiers, and pages visited.</li>
      <li><strong>Content Data:</strong> Any content you upload, such as text, images, company logos, or other media, including metadata associated with such content.</li>
      <li><strong>Communication Data:</strong> Information from communications with us, including support inquiries, feedback, and other correspondence.</li>
  </ul>
  <p>We use this data to provide, maintain, and improve our Service, to communicate with users, to process transactions, and to ensure compliance with our Terms of Use. We may also use aggregated and anonymized data for analytical purposes to better understand how our Service is used.</p>
  
  <h3 id="user-rights-regarding-data">10.2. User Rights Regarding Data</h3>
  <p>Under the UK GDPR, users have several rights regarding their personal data. These rights include:</p>
  <ul>
      <li><strong>Right to Access:</strong> You have the right to request access to the personal data we hold about you and to receive a copy of that data.</li>
      <li><strong>Right to Rectification:</strong> You have the right to request correction of any inaccurate or incomplete personal data we hold about you.</li>
      <li><strong>Right to Deletion:</strong> You have the right to request the deletion of your personal data where there is no legitimate reason for us to continue processing it.</li>
      <li><strong>Right to Restrict Processing:</strong> You have the right to request that we restrict the processing of your personal data in certain circumstances.</li>
      <li><strong>Right to Data Portability:</strong> You have the right to receive your personal data in a structured, commonly used, and machine-readable format and to transmit that data to another controller.</li>
      <li><strong>Right to Object:</strong> You have the right to object to the processing of your personal data for direct marketing purposes or on grounds relating to your particular situation.</li>
      <li><strong>Right to Withdraw Consent:</strong> Where processing is based on consent, you have the right to withdraw that consent at any time.</li>
  </ul>
  <p>To exercise any of these rights, please contact us at <a href="mailto:support@varyance.io">support@varyance.io</a>. We will respond to your request in accordance with applicable laws.</p>
  
  <h3 id="cookies-policy">10.3. Cookies Policy</h3>
  <p>Our Service uses cookies and similar technologies to enhance your experience and provide personalized services. Cookies are small text files placed on your device that allow us to recognize your device and gather information about your interactions with the Service.</p>
  <ul>
      <li><strong>Essential Cookies:</strong> Necessary for the operation of the Service, such as maintaining session integrity and security.</li>
      <li><strong>Analytical/Performance Cookies:</strong> Used to collect information about how users interact with the Service, helping us improve functionality and user experience.</li>
      <li><strong>Functional Cookies:</strong> Enable the Service to remember your preferences, such as language settings or login information.</li>
      <li><strong>Advertising Cookies:</strong> Used to deliver relevant advertisements and track ad performance.</li>
  </ul>
  <p><strong>Managing Cookies:</strong> You can manage your cookie preferences through your browser settings. Most browsers allow you to refuse or accept cookies and to delete any existing cookies. Please note that if you disable cookies, some parts of the Service may not function properly.</p>
  
  <h3 id="third-party-services">10.4. Third-Party Services</h3>
  <p>To provide our Service, we may share your personal data with third-party service providers or partners. These third parties are carefully selected and bound by data protection agreements that require them to safeguard your personal data and use it only for the purposes for which it was provided.</p>
  <ul>
      <li><strong>Hosting Providers:</strong> Companies that provide the infrastructure for our Service, including data storage and processing.</li>
      <li><strong>Analytics Providers:</strong> Services that help us analyze how the Service is used and improve its performance.</li>
      <li><strong>Payment Processors:</strong> Entities that handle payment transactions and billing information.</li>
      <li><strong>Marketing Partners:</strong> Companies that assist us in delivering targeted advertisements and marketing communications.</li>
  </ul>
  <p>We do not sell or share your personal data with third parties for their direct marketing purposes without your explicit consent.</p>
  
  <h3 id="data-security">10.5. Data Security</h3>
  <p>Varyance LTD. takes data security seriously and implements appropriate technical and organizational measures to protect your personal data from unauthorized access, disclosure, alteration, or destruction. However, no internet-based service can be completely secure, and we cannot guarantee the absolute security of your data.</p>
  <p>If you have any questions or concerns about our Privacy and Data Protection practices, please contact us at <a href="mailto:support@varyance.io">support@varyance.io</a>.</p>
  
  <h2 id="termination-conditions">11. Termination Conditions</h2>
  
  <h3 id="termination-by-varyance">11.1. Termination by Varyance LTD.</h3>
  <p>Varyance LTD. reserves the right to terminate or suspend your access to the Service at any time, with or without cause, and with or without notice, effective immediately. This may include, but is not limited to, the following circumstances:</p>
  <ul>
      <li><strong>Violation of Terms:</strong> If you breach any of the provisions of these Terms of Use, including but not limited to failure to comply with the Acceptable Use Policy, Intellectual Property clauses, or any other relevant terms.</li>
      <li><strong>Non-Payment:</strong> If you fail to pay any fees due under the "Terms for Pages" in a timely manner, Varyance LTD. may suspend or terminate your access to the Service until such payments are made in full.</li>
      <li><strong>Legal Compliance:</strong> If required to do so by law or in response to a valid legal process, Varyance LTD. may terminate your access to the Service.</li>
      <li><strong>Harm to Others:</strong> If your use of the Service poses a threat to the security, stability, or integrity of the Service, or could cause harm to Varyance LTD. or other users.</li>
  </ul>
  
  <h3 id="termination-by-you">11.2. Termination by You</h3>
  <p>You may terminate your account and cease using the Service at any time by contacting us at <a href="mailto:support@varyance.io">support@varyance.io</a>. Termination will be effective at the end of the current billing cycle, if applicable. Upon termination:</p>
  <ul>
      <li><strong>Outstanding Fees:</strong> You remain responsible for any fees due up to the effective date of termination.</li>
      <li><strong>Data Deletion:</strong> Varyance LTD. will delete your account and associated data in accordance with our data retention policy, unless otherwise required by law.</li>
      <li><strong>No Refunds:</strong> Unless otherwise specified in the "Terms for Pages," fees paid for the Service are non-refundable upon termination.</li>
  </ul>
  
  <h3 id="consequences-of-termination">11.3. Consequences of Termination</h3>
  <ul>
      <li><strong>Access to Service:</strong> Upon termination, your right to access and use the Service will immediately cease. Varyance LTD. is not liable for any loss of data or other content resulting from the termination of your access to the Service.</li>
      <li><strong>Surviving Provisions:</strong> Any provisions of these Terms that, by their nature, should survive termination (such as Intellectual Property rights, Limitation of Liability, and Dispute Resolution) will remain in effect after termination.</li>
  </ul>
  
  <h3 id="termination-for-inactivity">11.4. Termination for Inactivity</h3>
  <p>Varyance LTD. reserves the right to terminate or suspend accounts that have been inactive for an extended period. In such cases, users will be notified prior to termination and given the opportunity to reactivate their accounts.</p>
  
  <h2 id="limitation-of-liability">12. Limitation of Liability</h2>
  
  <h3 id="disclaimers">12.1. Disclaimers</h3>
  <ul>
      <li><strong>Service Reliability:</strong> The Service is provided on an "as-is" and "as available" basis. Varyance LTD. makes no representations or warranties of any kind, whether express, implied, or statutory, regarding the reliability, availability, timeliness, quality, suitability, truth, accuracy, or completeness of the Service. This includes, but is not limited to, any implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement.</li>
      <li><strong>No Guarantee of Results:</strong> Varyance LTD. does not guarantee that the Service will meet your requirements or expectations, or that the results obtained from the use of the Service will be accurate or reliable. Any reliance you place on the information provided through the Service is strictly at your own risk.</li>
      <li><strong>Third-Party Content:</strong> The Service may include content provided by third parties, including users, and Varyance LTD. is not responsible for the accuracy, completeness, or reliability of such content. Any interactions you have with third-party content or third-party services accessed through the Service are solely between you and the third party.</li>
      <li><strong>Service Interruptions:</strong> Varyance LTD. does not warrant that the Service will be uninterrupted, error-free, secure, or free from viruses or other harmful components. We are not responsible for any interruptions or delays that may occur while using the Service.</li>
  </ul>
  
  <h3 id="liability-caps">12.2. Liability Caps</h3>
  <ul>
      <li><strong>Maximum Liability:</strong> To the fullest extent permitted by applicable law, Varyance LTD.'s total liability to you for any claims arising out of or relating to these Terms of Use or your use of the Service, whether in contract, tort, strict liability, or any other legal theory, is limited to the amount you have paid to Varyance LTD. for access to the Service in the twelve (12) months preceding the event giving rise to the liability.</li>
      <li><strong>Exclusion of Certain Damages:</strong> Varyance LTD. shall not be liable for any indirect, incidental, consequential, special, punitive, or exemplary damages, including but not limited to loss of profits, revenue, data, goodwill, or business opportunities, even if Varyance LTD. has been advised of the possibility of such damages.</li>
      <li><strong>Jurisdictional Limitations:</strong> Some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion of liability for certain types of damages. As such, the above limitations and exclusions may not apply to you. In such jurisdictions, Varyance LTD.'s liability is limited to the maximum extent permitted by law.</li>
  </ul>
  
  <h2 id="dispute-resolution">13. Dispute Resolution</h2>
  
  <h3 id="governing-law">13.1. Governing Law</h3>
  <p>These Terms of Use and any disputes arising out of or in connection with them, including disputes regarding their existence, validity, or termination, shall be governed by and construed in accordance with the laws of England and Wales, without regard to its conflict of law principles.</p>
  
  <h3 id="dispute-process">13.2. Dispute Process</h3>
  <ul>
      <li><strong>Informal Negotiations:</strong> In the event of any dispute, claim, or controversy arising out of or relating to these Terms of Use or your use of the Service (collectively, "Disputes"), you and Varyance LTD. agree to first attempt to resolve the Dispute informally. You must contact us at <a href="mailto:support@varyance.io">support@varyance.io</a> with a written description of the Dispute, including relevant details and any proposed resolution. We will attempt to resolve the Dispute through good faith negotiations within 30 days of receipt of your notice.</li>
      <li><strong>Arbitration:</strong> If the Dispute cannot be resolved through informal negotiations within 30 days, either party may submit the Dispute to binding arbitration. The arbitration shall be conducted by the London Court of International Arbitration (LCIA) in accordance with its rules. The arbitration shall be held in London, England, and conducted in English. The arbitrator's decision shall be final and binding, and judgment on the award rendered by the arbitrator may be entered in any court having jurisdiction thereof.</li>
      <li><strong>Costs:</strong> Each party shall bear its own costs and expenses incurred in connection with the arbitration, except as may be otherwise provided in the LCIA rules or as determined by the arbitrator.</li>
  </ul>
  
  <h3 id="jurisdiction">13.3. Jurisdiction</h3>
  <p>Subject to the arbitration clause above, you and Varyance LTD. agree that the courts of England and Wales shall have exclusive jurisdiction to settle any Disputes that may arise out of or in connection with these Terms of Use, including any Dispute regarding the existence, validity, or termination of these Terms.</p>
  <p>By agreeing to these Terms of Use, you agree to submit to the personal jurisdiction of the courts of England and Wales for the purpose of litigating any such Disputes.</p>
  
  <h2 id="modifications-to-the-terms">14. Modifications to the Terms</h2>
  
  <h3 id="amendment-process">14.1. Amendment Process</h3>
  <p>Varyance LTD. reserves the right to amend or update these Terms of Use at any time at its sole discretion. Any amendments or updates will take effect immediately upon their posting on our website unless otherwise specified. It is your responsibility to review these Terms periodically to stay informed of any changes.</p>
  <ul>
      <li><strong>Substantive Changes:</strong> If we make any material or substantive changes to these Terms that may impact your rights or obligations, we will provide you with advance notice of such changes. This notice will be provided at least 30 days before the changes take effect, giving you the opportunity to review the revised Terms.</li>
      <li><strong>Non-Material Changes:</strong> Minor changes, such as those made for legal or administrative reasons, or to correct a typographical error, may be made without prior notice and will be effective immediately upon posting.</li>
  </ul>
  
  <h3 id="user-notification-method">14.2. User Notification Method</h3>
  <ul>
      <li><strong>Website Notice:</strong> Any amendments or updates to these Terms of Use will be posted on our website at <a href="https://www.varyance.io/terms">www.varyance.io/terms</a>. We recommend that you bookmark this page and review it regularly.</li>
      <li><strong>Email Notification:</strong> For material changes that may affect your use of the Service, we will also notify you by sending an email to the address associated with your account. It is your responsibility to ensure that your contact information is up to date.</li>
      <li><strong>Continued Use:</strong> Your continued use of the Service after the effective date of any amendments constitutes your acceptance of the revised Terms. If you do not agree with the amended Terms, you must discontinue your use of the Service before the changes take effect.</li>
  </ul>
  
  <h2 id="miscellaneous">15. Miscellaneous</h2>
  
  <h3 id="entire-agreement">15.1. Entire Agreement</h3>
  <p>These Terms of Use, along with our Privacy Policy and any other legal notices published by Varyance LTD. on the Service, constitute the entire agreement between you and Varyance LTD. regarding the use of the Service. These Terms supersede all prior or contemporaneous agreements, communications, and proposals, whether oral or written, between you and Varyance LTD. regarding the Service. Any additional or different terms or conditions in any written or oral communication from you are void.</p>
  
  <h3 id="severability">15.2. Severability</h3>
  <p>If any provision of these Terms of Use is found to be invalid, illegal, or unenforceable by a court of competent jurisdiction, that provision shall be enforced to the maximum extent permissible, and the remaining provisions of these Terms shall remain in full force and effect. The invalid, illegal, or unenforceable provision will be deemed modified so that it is valid and enforceable to the maximum extent permitted by law.</p>
  
  <h3 id="assignment-rights">15.3. Assignment Rights</h3>
  <p>You may not assign, transfer, or delegate any of your rights or obligations under these Terms of Use without the prior written consent of Varyance LTD. Any attempt to assign or transfer your rights or obligations without such consent will be null and void. Varyance LTD. may freely assign or transfer its rights and obligations under these Terms without restriction and without providing notice to you.</p>
  
  <h3 id="waiver">15.4. Waiver</h3>
  <p>No failure or delay by Varyance LTD. in exercising any right, power, or remedy under these Terms of Use shall operate as a waiver of that right, power, or remedy, nor shall any single or partial exercise of any right, power, or remedy preclude any other or further exercise thereof or the exercise of any other right, power, or remedy. Any waiver of any provision of these Terms will be effective only if in writing and signed by an authorized representative of Varyance LTD.</p>
  
  <h2 id="compliance-with-uk-law">16. Compliance with UK Law</h2>
  
  <h3 id="general-compliance">16.1. General Compliance</h3>
  <p>You agree to comply with all applicable laws, regulations, and rules when using the Service, including but not limited to those set forth by UK law. This includes, but is not limited to, compliance with laws regarding data protection, intellectual property, consumer protection, and electronic communications.</p>
  
  <h3 id="data-protection">16.2. Data Protection</h3>
  <p>Varyance LTD. complies with the UK General Data Protection Regulation (UK GDPR) and the Data Protection Act 2018. We are committed to protecting your personal data and processing it in accordance with these regulations. For more detailed information, please refer to our Privacy Policy.</p>
  
  <h3 id="consumer-rights">16.3. Consumer Rights</h3>
  <p>If you are using the Service as a consumer, you may have additional rights under UK consumer protection laws, such as the Consumer Rights Act 2015. These Terms of Use do not affect your statutory rights as a consumer, and any provision that seeks to limit those rights may not apply to you.</p>
  
  <h3 id="export-control-and-sanctions">16.4. Export Control and Sanctions</h3>
  <p>You agree that your use of the Service will comply with all applicable export control laws and regulations, including those of the UK and any other relevant jurisdictions. You represent and warrant that you are not located in, under the control of, or a national or resident of any country to which the UK has embargoed goods or services or which is subject to UK sanctions.</p>
  
  <h3 id="anti-bribery-and-anti-corruption">16.5. Anti-Bribery and Anti-Corruption</h3>
  <p>You agree to comply with the UK Bribery Act 2010 and all applicable anti-bribery and anti-corruption laws. You will not offer, promise, give, request, or accept any bribe or other improper payment, or otherwise engage in any corrupt practice in connection with your use of the Service.</p>
  
  
  
  </body>
  </html>
  `;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  return (
    <div className="">
      <Header setTest={setTest} Test={Test} />
      <div
        className="newrightcorner pb-5 center"
        style={{ alignItems: "center" }}
      >
        <div className="marginsleftrightCompany">
          <div className="d-flex" style={{ justifyContent: "center" }}>
            <div className="headerCompany margintopcompany">
              <p
                className="mainheadingcompany"
                style={{
                  display: "inline-block",
                  gap: "20px",
                }}
              >
                Terms of Use
              </p>
              <p
                className="learnmoreabout text-start"
                style={{ marginTop: "1rem", maxWidth: "600px" }}
              >
                We’re excited to have you here! To keep things running smoothly,
                we’ve put together some guidelines. These terms explain how you
                can use our platform and what you can expect from us.
                <br /> <br /> Take a moment to read through, and if you have any
                questions, we're always here to help!
              </p>

              <div className="d-flex" style={{ justifyContent: "end" }}>
                <div>
                  <p className="black16">Other useful Links:</p>
                  <p
                    className="pink16 hovercolorback"
                    style={{ marginTop: "0.75rem", cursor: "pointer" }}
                    onClick={() => navigate("/privacy")}
                  >
                    Privacy Policy
                  </p>
                  <p
                    className="pink16 hovercolorback"
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate("/cookie")}
                  >
                    Cookie Policy
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="marginsleftrightCompany">
        <div className="d-flex" style={{ justifyContent: "center" }}>
          <div className="headerCompany margintopcompany">
            {/* <Loader /> */}
            <div
              className="allpoppins spacingbetween"
              // style={{ lineHeight: "3rem" }}
              dangerouslySetInnerHTML={{ __html: privacyPolicyHtml }}
            />
            {/* <div
              className="lastSection headerCompany d-flex"
              style={{
                justifyContent: isNonMobile ? "space-between" : "center",
                flexDirection: isNonMobile ? "row" : "column",
              }}
            >
              <p className={`startjourney ${isNonMobile && "section"}`}>
                Hi campus recruiter!
                <br />
                Learn more about varyance.
              </p>
              <div
                className="d-flex "
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: isNonMobile ? "" : "1rem",
                }}
              >
                <div
                  className={`${isNonMobile450 ? "d-flex" : ""} col-12`}
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  <button
                    className={`backbutton ${isNonMobile450 ? "" : "col-12"}`}
                  >
                    Learn More
                  </button>
                  <button
                    className={`continuebutton  ${
                      isNonMobile450 ? "" : "col-12 mt-3"
                    }`}
                  >
                    Let's Talk
                  </button>
                </div>
              </div>
            </div> */}
            <hr className="horiline " />
            <HomeFooter />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Terms;
