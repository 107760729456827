// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.newTabOuter {
  display: flex;

  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #fff;
  background: rgba(255, 255, 255, 0.4);

  /* Trans Card: Icons */
  box-shadow: 0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02);
}
.newTabButton {
  display: inline-flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  width: 222px;
  gap: 8px;
  flex: 1 0 auto;
  border-radius: 6px;
  border: none;
  background: transparent;
  color: var(--Gray-800, #1d2939);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */

  /* Prevent text from wrapping */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.2s ease-in-out;
}
.newTabButton:hover {
  color: #e31b54;
}
.newTabButton.enabled {
  border-radius: 6px;
  background: var(--Base-White, #fff);
  color: var(--Primary-600, #e31b54);
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  .newTabButton {
    width: -moz-fit-content;
    width: fit-content;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/TabButtons/tab.css"],"names":[],"mappings":"AAAA;EACE,aAAa;;EAEb,YAAY;EACZ,uBAAuB;EACvB,mBAAmB;EACnB,QAAQ;EACR,kBAAkB;EAClB,sBAAsB;EACtB,oCAAoC;;EAEpC,sBAAsB;EACtB;;;kDAGgD;AAClD;AACA;EACE,oBAAoB;EACpB,iBAAiB;EACjB,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,QAAQ;EACR,cAAc;EACd,kBAAkB;EAClB,YAAY;EACZ,uBAAuB;EACvB,+BAA+B;EAC/B,oBAAoB;EACpB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,aAAa;;EAEhC,+BAA+B;EAC/B,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;EACvB,gCAAgC;AAClC;AACA;EACE,cAAc;AAChB;AACA;EACE,kBAAkB;EAClB,mCAAmC;EACnC,kCAAkC;EAClC,gBAAgB;AAClB;;AAEA;EACE;IACE,uBAAkB;IAAlB,kBAAkB;EACpB;AACF","sourcesContent":[".newTabOuter {\n  display: flex;\n\n  padding: 4px;\n  justify-content: center;\n  align-items: center;\n  gap: 8px;\n  border-radius: 8px;\n  border: 1px solid #fff;\n  background: rgba(255, 255, 255, 0.4);\n\n  /* Trans Card: Icons */\n  box-shadow: 0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),\n    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),\n    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),\n    0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02);\n}\n.newTabButton {\n  display: inline-flex;\n  padding: 8px 12px;\n  justify-content: center;\n  align-items: center;\n  width: 222px;\n  gap: 8px;\n  flex: 1 0 auto;\n  border-radius: 6px;\n  border: none;\n  background: transparent;\n  color: var(--Gray-800, #1d2939);\n  font-family: Poppins;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 20px; /* 142.857% */\n\n  /* Prevent text from wrapping */\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  transition: all 0.2s ease-in-out;\n}\n.newTabButton:hover {\n  color: #e31b54;\n}\n.newTabButton.enabled {\n  border-radius: 6px;\n  background: var(--Base-White, #fff);\n  color: var(--Primary-600, #e31b54);\n  font-weight: 600;\n}\n\n@media screen and (max-width: 768px) {\n  .newTabButton {\n    width: fit-content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
