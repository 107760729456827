// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.outerLoader {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  flex-shrink: 0;
  background: var(
    --Icon-Gradient,
    linear-gradient(136deg, #c01048 -4.38%, #a11043 67.26%, #89123e 102.63%)
  );
  filter: drop-shadow(0px 0.172px 0.138px rgba(0, 49, 122, 0.02))
    drop-shadow(0px 0.414px 0.331px rgba(0, 49, 122, 0.03))
    drop-shadow(0px 0.78px 0.624px rgba(0, 49, 122, 0.04))
    drop-shadow(0px 1.392px 1.113px rgba(0, 49, 122, 0.04))
    drop-shadow(0px 2.603px 2.082px rgba(0, 49, 122, 0.05))
    drop-shadow(0px 6.231px 4.985px rgba(0, 49, 122, 0.07));
}

.loadertext {
  color: #fff;
  font-size: 18.693px;
  font-style: normal;
  font-weight: 400;
}

.spinner {
  width: 30px;
  height: 30px;

  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #fff;
  border-radius: 50%;
  animation: spin 2s linear infinite, fadeIn 0.1s ease-in forwards;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Loader/loader.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,cAAc;EACd;;;GAGC;EACD;;;;;2DAKyD;AAC3D;;AAEA;EACE,WAAW;EACX,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;;EAEZ,0CAA0C;EAC1C,0BAA0B;EAC1B,kBAAkB;EAClB,gEAAgE;AAClE;;AAEA;EACE;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;AACF;;AAEA;EACE;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;AACF;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".outerLoader {\n  width: 40px;\n  height: 40px;\n  border-radius: 4px;\n  flex-shrink: 0;\n  background: var(\n    --Icon-Gradient,\n    linear-gradient(136deg, #c01048 -4.38%, #a11043 67.26%, #89123e 102.63%)\n  );\n  filter: drop-shadow(0px 0.172px 0.138px rgba(0, 49, 122, 0.02))\n    drop-shadow(0px 0.414px 0.331px rgba(0, 49, 122, 0.03))\n    drop-shadow(0px 0.78px 0.624px rgba(0, 49, 122, 0.04))\n    drop-shadow(0px 1.392px 1.113px rgba(0, 49, 122, 0.04))\n    drop-shadow(0px 2.603px 2.082px rgba(0, 49, 122, 0.05))\n    drop-shadow(0px 6.231px 4.985px rgba(0, 49, 122, 0.07));\n}\n\n.loadertext {\n  color: #fff;\n  font-size: 18.693px;\n  font-style: normal;\n  font-weight: 400;\n}\n\n.spinner {\n  width: 30px;\n  height: 30px;\n\n  border: 4px solid rgba(255, 255, 255, 0.3);\n  border-top: 4px solid #fff;\n  border-radius: 50%;\n  animation: spin 2s linear infinite, fadeIn 0.1s ease-in forwards;\n}\n\n@keyframes fadeOut {\n  from {\n    opacity: 1;\n  }\n  to {\n    opacity: 0;\n  }\n}\n\n@keyframes fadeIn {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}\n\n@keyframes spin {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
