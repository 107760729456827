import React, { useState } from "react";
import Automotive from "../../assets/images/icons/Automotive.png";
import Brand from "../../assets/images/icons/Brand.png";
import Consulting from "../../assets/images/icons/Consulting.png";
import Economics from "../../assets/images/icons/Economics.png";
import realstate from "../../assets/images/icons/realstate.png";
import Finance from "../../assets/images/icons/Finance.png";
import marketing from "../../assets/images/icons/marketing.png";
import Government from "../../assets/images/icons/Government.png";
import FMCG from "../../assets/images/icons/FMCG.png";
import Law from "../../assets/images/icons/Law.png";
import Pharma from "../../assets/images/icons/Pharma.png";
import Startup from "../../assets/images/icons/Startup.png";
import Supply from "../../assets/images/icons/Supply.png";
import Technology from "../../assets/images/icons/Technology.png";
import Engineering from "../../assets/images/icons/Engineering.png";
import Computer from "../../assets/images/icons/Computer.png";
import HR from "../../assets/images/icons/HR.png";
import Aviation from "../../assets/images/icons/Aviation.png";
import { useNavigate } from "react-router-dom";
const HomeNewSection = () => {
  const [MouseHover, setStartHover] = useState(false);
  const navigate = useNavigate();
  let Interestitems = [
    {
      id: "Brand Management",
      icon: Brand,
      text: "Brand Management",
      select: false,
    },
    { id: "Finance", icon: Finance, text: "Finance", select: false },
    { id: "Consulting", icon: Consulting, text: "Consulting", select: false },
    { id: "Economics", icon: Economics, text: "Economics", select: false },
    { id: "Corporate Law", icon: Law, text: "Corporate Law", select: false },
    {
      id: "HR",
      icon: HR,
      text: "HR",
      select: false,
    },
    {
      id: "Aviation",
      icon: Aviation,
      text: "Aviation",
      select: false,
    },
    { id: "Supply Chain", icon: Supply, text: "Supply Chain", select: false },
    { id: "Pharma", icon: Pharma, text: "Pharma", select: false },
    {
      id: "Technology",
      icon: Technology,
      text: "Technology & AI",
      select: false,
    },
    { id: "Government", icon: Government, text: "Government", select: false },
    { id: "marketing", icon: marketing, text: "Marketing & PR", select: false },
    {
      id: "Real Estate",
      icon: realstate,
      text: "Real Estate",
      select: false,
    },
    { id: "Start-Up", icon: Startup, text: "Start-Up", select: false },
    { id: "Automotive", icon: Automotive, text: "Automotive", select: false },
    {
      id: "FMCG",
      icon: FMCG,
      text: "FMCG",
      select: false,
    },

    {
      id: "Engineering",
      icon: Engineering,
      text: "Engineering",
      select: false,
    },
    {
      id: "Computer Science",
      icon: Computer,
      text: "Computer Science",
      select: false,
    },
  ];

  const [selectedItem, setSelectedItem] = useState([]);

  const handleChipClick = (item) => {
    setSelectedItem((prevState) => {
      if (prevState.some((selected) => selected.text === item.text)) {
        return prevState.filter((selected) => selected.text !== item.text);
      } else {
        return [...prevState, item];
      }
    });
  };
  const handleContinueClick = () => {
    navigate("/register", { state: { selectedItems: selectedItem } });
  };

  return (
    <>
      <div>
        <p className="sparkInterest text-center">
          Get <span> Inspired</span>
        </p>
        <div className="d-flex" style={{ justifyContent: "center" }}>
          <p className="itsearly text-center">
            <span className="greyspan"> Discover a variety of</span> industries
            <span className="greyspan"> and</span> job fields
            <span className="greyspan"> to find the </span>
            <span className="pinkspan"> perfect fit</span>.
          </p>
        </div>
        <div className="d-flex" style={{ justifyContent: "center" }}>
          <div
            className="d-flex flex-wrap wrapperofinterests"
            style={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {Interestitems.map((item, index) => (
              <div
                className={` ChipCover d-flex ${
                  selectedItem.some((items) => items.text === item.text)
                    ? "selected_interests_new"
                    : ""
                }`}
                onClick={() => handleChipClick(item)}
              >
                <img
                  src={item?.icon}
                  alt={item?.text}
                  height="20px"
                  width="20px"
                  className="interest_icon"
                />
                <p className="interesttext" style={{}}>
                  {item.text}
                </p>
              </div>
            ))}
          </div>
        </div>

        <div className="d-flex" style={{ justifyContent: "center" }}>
          <div
            className="learnmorewidth d-flex"
            style={{ alignItems: "center", flexDirection: "column" }}
          >
            <p className="learnmoreabout text-center">
              Learn more about your <span>chosen career </span>path or simply
              <span> explore</span> what else is there in the market for you.
              With challenges you now can discover interesting and fun areas
              which you might not have considered (yet).
            </p>
            {selectedItem.length > 0 && (
              <button
                className="btn startonboard p-0 d-flex"
                style={{ gap: "1rem" }}
                onMouseEnter={() => setStartHover(true)}
                onMouseLeave={() => setStartHover(false)}
                onClick={handleContinueClick}
              >
                Continue Onboarding
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M20.05 11.47L13.05 4.47C12.8571 4.28243 12.5787 4.21204 12.3198 4.28533C12.0609 4.35863 11.8608 4.56447 11.7948 4.82533C11.7289 5.08619 11.8071 5.36243 12 5.55L17.71 11.25H4.47998C4.06577 11.25 3.72998 11.5858 3.72998 12C3.72998 12.4142 4.06577 12.75 4.47998 12.75H17.7L12 18.45C11.857 18.5893 11.7764 18.7804 11.7764 18.98C11.7764 19.1796 11.857 19.3707 12 19.51C12.1378 19.6546 12.3302 19.7345 12.53 19.73C12.729 19.7309 12.9201 19.6516 13.06 19.51L20.06 12.51C20.3524 12.2172 20.3524 11.7428 20.06 11.45L20.05 11.47Z"
                    fill={MouseHover ? "#A11043" : "#E31B54"}
                  />
                </svg>
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeNewSection;
