import React from "react";
import companyPageLady from "../../assets/images/companyPageLady.png";
const card1 = () => {
  return (
    <div className="card1Company">
      <div
        className="d-flex"
        style={{
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "",
          height: "100%",
        }}
      >
        <div className="card1paddings">
          <div className="d-flex">
            <div className="firstcard1button">
              <p className="firstcard1buttontext">01 Challenge</p>
            </div>
          </div>
          <p className="text-start firstcard2ndtext">Virtual Challenges</p>
          <p className="text-start firstcard3rdtext">
            Accelerate your employer brand by designing{" "}
            <span>real-world challenges</span> in which students learn and
            practice the <span>skills sets</span> required to strive while
            getting to know your company.
          </p>
        </div>
        <img
          src={companyPageLady}
          alt="company PageLady"
          className="img-fluid rounded-3"
        />
      </div>
    </div>
  );
};

export default card1;
