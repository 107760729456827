import React, { useState, useRef, useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

import "./talent.css";

import ResultTable from "./ResultTable";
import Filter from "./Filter";

const Talent = ({
  results,
  livechallengesLength,
  selectedStudents,
  setSelectedStudent,
  DATA,
  setData,
  company,
  user,
  TRUE,
  bulkAction,
  setBulkAction,
  handleBulkAction,
  searchFilter,
  setSearchFilter,
  challenges,
}) => {
  const [resultstoshow, setResults] = useState(results);

  useEffect(() => {
    setResults(results);
  }, [results]);

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className="Next_arrow p-2 rounded-5 shadowa "
        style={{
          ...style,
          right: "0",
          zIndex: 1,
          position: "absolute",
          top: "90%",
          transform: "translateY(-50%)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          backgroundColor: "#FFF",
        }}
        onClick={onClick}
      >
        <MdKeyboardArrowRight size={24} />
      </div>
    );
  }

  const isNonMobile = useMediaQuery("(min-width:800px)");

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className="Prev_arrow p-2 rounded-5 shadowa"
        style={{
          ...style,
          left: "0",
          zIndex: 1,
          position: "absolute",
          top: "90%",
          transform: "translateY(-50%)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          backgroundColor: "#FFF",
        }}
        onClick={onClick}
      >
        <MdKeyboardArrowLeft size={24} />
      </div>
    );
  }

  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 2,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          dots: true,
          arrows: false,
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          dots: true,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleRemoveStudent = (res) => {
    setResults(resultstoshow.filter((rese) => rese._id !== res._id));
  };

  const sliderRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const scrollLeft = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollLeft -= sliderRef.current.offsetWidth;
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? resultstoshow.length - 1 : prevIndex - 1
      );
    }
  };

  const scrollRight = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollLeft += sliderRef.current.offsetWidth;
      setCurrentIndex((prevIndex) =>
        prevIndex === resultstoshow.length - 1 ? 0 : prevIndex + 1
      );
    }
  };

  const bulkOptions = [TRUE ? "Shortlist" : null, "Remove"].filter(Boolean);

  return (
    <div style={{ marginTop: "2.75rem" }} className="">
      <div style={{ marginTop: "1.5rem" }}>
        <ResultTable
          results={results}
          livechallengesLength={livechallengesLength}
          selectedStudents={selectedStudents}
          setSelectedStudent={setSelectedStudent}
          DATA={DATA}
          setData={setData}
          company={company}
          challenges={challenges}
          TRUE={TRUE}
          user={user}
          bulkAction={bulkAction}
          setBulkAction={setBulkAction}
          bulkOptions={bulkOptions}
          handleBulkAction={handleBulkAction}
          searchFilter={searchFilter}
          setSearchFilter={setSearchFilter}
        />
      </div>
    </div>
  );
};

export default Talent;
