import EditIcon from "@mui/icons-material/Edit";
const Publications = ({ user, owner }) => {
  return (
    <div className=" shadowa">
      <div
        className="innerglass tablescroll"
        style={{ maxHeight: "300px", overflow: "auto", padding: "1.25rem" }}
      >
        <div className="space flex-wrap" style={{ gap: "1rem" }}>
          <p className="filmo20">Publications</p>
          {owner && (
            <EditIcon
              className="hoverred"
              style={{ marginTop: "0.25rem", width: "24px", cursor: "pointer" }}
            />
          )}
        </div>
        {user?.publications?.map((pub, index) => (
          <div
            className="d-flex flex-wrap"
            key={index}
            style={{ marginTop: "1.5rem", gap: "1rem" }}
          >
            <div>
              <p className="black16 text-start">{pub?.title}</p>
              <p className="gray14" style={{ marginTop: "0.5rem" }}>
                {pub?.publishedDate}
              </p>
              <p className="gray14" style={{ marginTop: "0.75rem" }}>
                {pub?.description}
              </p>
            </div>
            <div className="center" style={{ alignItems: "center" }}>
              <button
                className="backbutton"
                onClick={() => {
                  window.open(`${pub?.url}`, "_blank");
                }}
              >
                Show Publication
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default Publications;
