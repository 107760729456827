const SecondSection = ({ company, isNonMob, color, isNonMobile1024 }) => {
  return (
    <>
      <div style={{ background: "#F2F3F7" }}>
        <div
          style={{
            width: "100vw",
            background: "#FFF",
            paddingTop: "0.5rem",
            paddingBottom: "0.5rem",
          }}
          className="shadowa"
        >
          <div className="marginsleftrightCompany">
            <div className="d-flex" style={{ justifyContent: "center" }}>
              <div className={`headerCompany space`}>
                <div
                  className=" rounded-3 shadowa center"
                  style={{
                    background: "#fff",
                    height: "36px",
                    alignItems: "center",
                    width: "36px",
                    padding: "2.5px",
                  }}
                >
                  {company && company?.img && (
                    <img
                      className="img-fluid rounded-1"
                      src={company?.img}
                      alt="company logo"
                    />
                  )}
                </div>
                {isNonMob && (
                  <div
                    className="d-flex border border-1 rounded-3"
                    style={{ padding: "0.25px", alignItems: "center" }}
                  >
                    <a
                      href="#overview"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <button
                        className="btn gray14 "
                        style={{
                          fontWeight: "500",
                          width: isNonMobile1024 ? "180px" : "",
                          color: color,
                          background: "#F2F4F7",
                        }}
                      >
                        Overview
                      </button>
                    </a>
                    <a
                      href="#challenges"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <button
                        className="btn gray14 "
                        style={{
                          fontWeight: "500",
                          width: isNonMobile1024 ? "180px" : "",
                        }}
                      >
                        Challenges
                      </button>
                    </a>
                    <a
                      href="#jobposts"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      {" "}
                      <button
                        className="btn gray14 "
                        style={{
                          fontWeight: "500",
                          width: isNonMobile1024 ? "180px" : "",
                        }}
                      >
                        Job Posts
                      </button>
                    </a>
                    <a
                      href="#posts"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      {" "}
                      <button
                        className="btn gray14 "
                        style={{
                          fontWeight: "500",
                          width: isNonMobile1024 ? "180px" : "",
                        }}
                      >
                        Posts
                      </button>
                    </a>
                  </div>
                )}
                <button
                  className="continuebutton"
                  style={{
                    maxHeight: "40px",
                    border: "none",
                    background: color,
                  }}
                  onClick={() => {}}
                >
                  Follow
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="marginsleftrightCompany w-full" id="overview">
          <div className="d-flex w-full" style={{ justifyContent: "center" }}>
            <div className={`headerCompany  w-full`}>
              <div
                style={{
                  marginTop: "2.5rem",
                  marginBottom: "2.5rem",
                  width: "100%",
                }}
                className="outerglass"
              >
                <div className="innerglass space flex-wrap w-full">
                  <div>
                    <p className="black16 text-start">Industry & Firm Size</p>
                    <div
                      className="d-flex flex-wrap"
                      style={{ marginTop: "1rem", gap: "1rem" }}
                    >
                      {company && company?.industry && (
                        <p className=" newskillsshow">{company?.industry}</p>
                      )}
                      {company && company?.size && (
                        <p className=" newskillsshow">
                          {company?.size} Employees
                        </p>
                      )}
                    </div>
                    {company && company?.details && (
                      <div style={{ marginTop: "2.5rem" }}>
                        <p className="black16 text-start">Details</p>
                        <p
                          className="black14 tablescroll"
                          style={{
                            marginTop: "1rem",
                            fontWeight: "400",
                            maxHeight: "260px",
                            overflow: "auto",
                            maxWidth: "636px",
                          }}
                        >
                          {company && company?.details}
                        </p>
                      </div>
                    )}
                  </div>
                  <div style={{ width: isNonMobile1024 ? "30%" : "" }}>
                    {company && company?.website && (
                      <>
                        <p className="black16 text-start">Website</p>

                        <a
                          className="pink16 hoverdarkred"
                          href={company && company?.website}
                          style={{
                            marginTop: "1rem",
                            fontWeight: "400",
                            fontSize: "14px",
                            cursor: "pointer",
                            textDecoration: "underline", // Optionally, you can underline the link text
                            display: "inline-block", // Ensure the link behaves like an inline block element
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            window.open(company && company?.website, "_blank");
                          }}
                        >
                          {company && company?.website}
                        </a>
                      </>
                    )}
                    {company && company?.interest && (
                      <div style={{ marginTop: "2.5rem" }}>
                        <p className="black16 text-start">Career Fields</p>
                        {company && company?.interest && (
                          <div
                            className="d-flex flex-wrap"
                            style={{ gap: "0.75rem", marginTop: "1.5rem" }}
                          >
                            {company?.interest?.map((int, index) => (
                              <p key={index} className=" newskillsshow">
                                <img
                                  src={int?.icon}
                                  alt={int?.id}
                                  height={"20px"}
                                  width={"20px"}
                                />{" "}
                                {int?.text}
                              </p>
                            ))}
                          </div>
                        )}
                      </div>
                    )}
                    <div style={{ marginTop: "2.5rem" }}>
                      <p className="black16 text-start">Locations</p>
                      {company && company?.country && (
                        <div style={{ marginTop: "1.5rem" }}>
                          {company?.country?.map((loc, index) => (
                            <div
                              key={index}
                              className="d-flex flex-wrap"
                              style={{ gap: "0.5rem", marginTop: "0.5rem" }}
                            >
                              <p
                                className="newskillsshow d-flex"
                                style={{ gap: "0.5rem" }}
                              >
                                <span> {loc?.Country?.flag}</span>
                                {loc?.Country?.name}
                              </p>{" "}
                              {loc?.City?.map((city, i) => (
                                <p key={i} className="newskillsshow">
                                  {city.name}
                                </p>
                              ))}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
          </div>
        </div>
      </div>
    </>
  );
};

export default SecondSection;
