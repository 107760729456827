import React from "react";
import StudentRegister from "../../components/StudentRegister";

function StudentRegistration() {
  return (
    <div>
      <StudentRegister />
    </div>
  );
}

export default StudentRegistration;
