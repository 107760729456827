import { useState } from "react";

import { toast } from "react-toastify";
import axios from "axios";
import emailjs from "@emailjs/browser";
import { setEmail } from "../../../state/index";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import RegisterCompany from "../../../assets/images/RegisterCompany.png";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import "./input.css";
const Inputs = ({ role, company, user, dsa }) => {
  const [email, setEmails] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [selected, setSelected] = useState("Company");
  const [emailErr, setEmailErr] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:768px)");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handleLinkedInSignUp = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/linkedin/authorize`
      );
      const { authorizationUrl } = response.data;
      window.location.href = authorizationUrl; // Redirect the user to the LinkedIn authorization URL
    } catch (error) {
      console.error("Error signing up with LinkedIn:", error);
    }
  };

  const sendEmail = (ad) => {
    const payload = {
      code: `${process.env.REACT_APP_FRONTEND_URL}/Find/${ad}`,
      to_email: email,
    };

    emailjs
      .send("service_08a640e", "template_ararw0s", payload, "FCKBh2iU92kGQhhAA")
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    dispatch(setEmail({ email }));
  };

  const sentEmail = (ad) => {
    const payload = {
      code: `${process.env.REACT_APP_FRONTEND_URL}/create/profile/${ad}`,
      to_email: email,
    };

    emailjs
      .send("service_08a640e", "template_ararw0s", payload, "FCKBh2iU92kGQhhAA")
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    dispatch(setEmail({ email }));
  };

  const handleSubmit = () => {
    if (!email) {
      toast.error("Please enter your Email", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    } else if (!password) {
      toast.error("Please enter your password", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    } else if (password.length < 6) {
      toast.error("Password must be at least 6 characters", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast.error("Please Enter a Valid Email Address", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setEmailErr(true);
      return;
    }

    const publicEmailDomainPattern =
      /@(gmail\.com|yahoo\.com|hotmail\.com|outlook\.com|aol\.com|protonmail\.com|mail\.com)$/;

    if (publicEmailDomainPattern.test(email)) {
      toast.error("Please use a valid company email address", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setEmailErr(true);
      return;
    }
    setEmailErr(false);
    if (!role) {
      axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/organisation/register`, {
          email,
          password,
          selected,
        })
        .then((res) => {
          if (res.data?.msg === "alreadyreg") {
            setError("Email Already Registered");
          } else {
            sendEmail(res.data.uniqueId);

            navigate("/company/register/verify");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/organisation/register/role`,
          {
            email,
            password,
            role,
            selected,
            company,
            user,
          }
        )
        .then((res) => {
          if (res.data?.msg === "alreadyreg") {
            setError("Email Already Registered");
          } else {
            sentEmail(`${res.data.uniqueId}/${dsa}/${company}/${user}`);

            toast.success("Verification link has been sent to your email");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <>
      <div
        className="RegisterOrganmarginTop d-flex"
        style={{ justifyContent: "space-between" }}
      >
        <div
          className=""
          style={{ width: isNonMobile ? "37.6%" : "100%", maxWidth: "440px" }}
        >
          <p
            className=" registeration"
            style={isNonMobile ? { fontSize: "40px" } : { fontSize: "30px" }}
          >
            Registration
          </p>
          <p className="signup">Sign-Up as an employer.</p>
          <div className="">
            <p className="Email" style={{ marginTop: "2rem" }}>
              Company Email
            </p>
            <div className="">
              <input
                value={email}
                type="email"
                onChange={(e) => setEmails(e.target.value)}
                className="rounded-3   col-md-12 col-12  newinputs"
                style={{ marginTop: "14px", maxWidth: "440px" }}
              />
            </div>
            <div
              className=" d-flex"
              style={{ justifyContent: "space-between" }}
            >
              <div className="col-md-12 col-12">
                <div className="" style={{ marginTop: "1.5rem" }}>
                  <p className="Email"> Password</p>
                </div>
                <div className="position-relative">
                  <input
                    value={password}
                    type={showPassword ? "text" : "password"}
                    onChange={(e) => setPassword(e.target.value)}
                    className="rounded-3 newinputs mt-3 col-md-12 col-12 "
                    style={{ marginTop: "14px", maxWidth: "440px" }}
                  />
                  <div
                    className="position-absolute end-10 top-50 translate-middle-y"
                    style={{
                      right: "10px",
                      cursor: "pointer",
                      maxWidth: "440px",
                    }}
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? (
                      <VisibilityOffOutlinedIcon />
                    ) : (
                      <RemoveRedEyeOutlinedIcon />
                    )}
                  </div>
                </div>
              </div>
            </div>
            {error && (
              <p
                className="headersmalltext"
                style={{ marginTop: "0.5rem", color: "red" }}
              >
                {error}
              </p>
            )}
            <div className=" ">
              <button
                className=" registerButton col-md-12 col-12"
                onClick={() => {
                  handleSubmit();
                }}
                style={{ maxWidth: "440px" }}
              >
                Register
              </button>
              {!role && (
                <>
                  {" "}
                  <div
                    className="d-flex"
                    style={{ marginTop: "10px", marginBottom: "10px" }}
                  >
                    <div className="col-md-5 col-5">
                      <hr />
                    </div>
                    <div className="col-md-2 col-2 d-flex">
                      <p
                        className="orbetween  d-flex"
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        or
                      </p>
                    </div>
                    <div className="col-md-5 col-5">
                      <hr />
                    </div>
                  </div>
                  <button
                    className=" linkedinButton col-md-12 col-12"
                    style={{ maxWidth: "440px" }}
                    onClick={() => handleLinkedInSignUp()}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_5582_23599)">
                        <path
                          d="M22.7283 0.168945H2.27167C1.80179 0.168945 1.35116 0.355603 1.01891 0.687855C0.686657 1.02011 0.5 1.47074 0.5 1.94061V22.3973C0.5 22.8672 0.686657 23.3178 1.01891 23.65C1.35116 23.9823 1.80179 24.1689 2.27167 24.1689H22.7283C23.1982 24.1689 23.6488 23.9823 23.9811 23.65C24.3133 23.3178 24.5 22.8672 24.5 22.3973V1.94061C24.5 1.47074 24.3133 1.02011 23.9811 0.687855C23.6488 0.355603 23.1982 0.168945 22.7283 0.168945ZM7.65333 20.6139H4.045V9.15228H7.65333V20.6139ZM5.84667 7.56395C5.43736 7.56164 5.03792 7.43814 4.69873 7.20903C4.35955 6.97993 4.09584 6.65548 3.94088 6.27663C3.78591 5.89779 3.74665 5.48154 3.82803 5.0804C3.90941 4.67926 4.1078 4.31122 4.39816 4.02273C4.68851 3.73423 5.05782 3.53821 5.45947 3.45941C5.86112 3.3806 6.27711 3.42254 6.65495 3.57993C7.03279 3.73732 7.35554 4.00311 7.58247 4.34376C7.80939 4.6844 7.93032 5.08464 7.93 5.49395C7.93386 5.76798 7.88251 6.03998 7.77901 6.29375C7.67551 6.54751 7.52198 6.77785 7.32757 6.97101C7.13316 7.16418 6.90185 7.31623 6.64742 7.4181C6.393 7.51996 6.12067 7.56957 5.84667 7.56395ZM20.9533 20.6239H17.3467V14.3623C17.3467 12.5156 16.5617 11.9456 15.5483 11.9456C14.4783 11.9456 13.4283 12.7523 13.4283 14.4089V20.6239H9.82V9.16061H13.29V10.7489H13.3367C13.685 10.0439 14.905 8.83895 16.7667 8.83895C18.78 8.83895 20.955 10.0339 20.955 13.5339L20.9533 20.6239Z"
                          fill="#0A66C2"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_5582_23599">
                          <rect
                            width="24"
                            height="24"
                            fill="white"
                            transform="translate(0.5 0.168945)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                    Continue with LinkedIn
                  </button>
                </>
              )}

              <p className="already text-center" style={{ marginTop: "2rem" }}>
                Already have an account?{" "}
                <span
                  className="login hoverdarkred"
                  onClick={() => navigate("/company/login")}
                >
                  Log in
                </span>
              </p>
            </div>
          </div>
        </div>
        {isNonMobile && (
          <div
            className=" d-flex"
            style={{
              width: "55%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={RegisterCompany}
              alt="register company"
              className="img-fluid"
            />
          </div>
        )}
      </div>
    </>
  );
};
export default Inputs;
