import "./analytics.css";
const Smart = ({ text, analytics, setAnalytics }) => {
  const buttons = ["12 months", "30 days", "7 days"];
  return (
    <div className="space flex-wrap" style={{ gap: "1rem" }}>
      <p style={{ fontSize: "20px" }}>{text}</p>
      <div className="d-flex">
        {buttons.map((but, index) => (
          <button
            key={index}
            onClick={() => setAnalytics(but)}
            className={` ${
              but === analytics
                ? "selectedbuttonanalysis"
                : "notselectedbutton hoverdarkblack"
            }`}
          >
            {but}
          </button>
        ))}
      </div>
    </div>
  );
};
export default Smart;
