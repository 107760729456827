import img from "../../assets/images/newimghome.png";
const OnePlatform = () => {
  return (
    <div
      className="marginsleftrightCompany "
      style={{ paddingTop: "8.125rem", paddingBottom: "8.125rem" }}
    >
      <div className="d-flex" style={{ justifyContent: "center" }}>
        <div className="headerCompany">
          <div className="d-flex flex-column align-items-center">
            <p className="sparkInterest text-center">
              One <span> Platform</span>
            </p>
            {/* <div className="d-flex" style={{ justifyContent: "center" }}> */}
            <p
              style={{ marginTop: "1rem", maxWidth: "562px", width: "100%" }}
              className="itsearly text-center "
            >
              <span className="greyspan">All the tools</span> you need{" "}
              <span className="greyspan"> along your </span>
              <span className="pinkspan">career journey.</span>
            </p>
            {/* </div> */}
            <p
              className="text-center learnmoreabout"
              style={{ marginTop: "1rem", maxWidth: "700px" }}
            >
              Start a <span>challenge</span>, get the{" "}
              <span>latest updates</span> from top-employers, attend events or{" "}
              <span>discuss</span> your experience with students around the
              world.
            </p>
            <div style={{ maxWidth: "810px" }}>
              <img
                src={img}
                alt="one platform img"
                className="img-fluid shadowa rounded-1"
                style={{ marginTop: "2.25rem" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OnePlatform;
