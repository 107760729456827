import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { toast } from "react-toastify";
const Step1 = ({
  title,
  setTitle,
  position,
  setPosition,
  workStudy,
  setWorkStudy,
  jobDescription,
  setJobDescription,
  ReactQuill,
  navigate,
  setSteps,
}) => {
  const positionType = [
    "Job",
    "Internship",
    "Thesis",
    "Scientific Assistant",
    "Other",
  ];

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
      [{ align: [] }],
      ["clean"],
    ],
  };
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "link",
    "image",
    "align",
  ];

  const handleSubmit = () => {
    if (!title) {
      toast.error("Please Enter Job Title");
      return;
    } else if (!position) {
      toast.error("Please Select Position Type");
      return;
    } else if (!jobDescription) {
      toast.error("Please Enter Job Description");
      return;
    } else {
      setSteps(2);
    }
  };

  return (
    <div className="">
      <p className="BasicInforMationHeading">Job Details</p>
      <p className="UnderBasicHeading">What is this job about?</p>
      <div className=" " style={{ marginTop: "2.5rem" }}>
        <p className="thumbnailcreate">Job title</p>
        <input
          className="col-md-12 col-12 forminput "
          style={{ marginTop: "0.75rem" }}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>
      <div className=" " style={{ marginTop: "2.5rem" }}>
        <p className="thumbnailcreate">Position type</p>
        <div
          className="d-flex"
          style={{ marginTop: "0.75rem", flexDirection: "column", gap: "1rem" }}
        >
          {positionType.map((item, index) => (
            <div key={index} className="d-flex">
              <div
                className="d-flex"
                style={{ gap: "0.75rem", cursor: "pointer" }}
                onClick={() => setPosition(item)}
              >
                <div className="d-flex">
                  {item === position ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M9.97591 1.6665C5.37591 1.6665 1.64258 5.39984 1.64258 9.99984C1.64258 14.5998 5.37591 18.3332 9.97591 18.3332C14.5759 18.3332 18.3092 14.5998 18.3092 9.99984C18.3092 5.39984 14.5842 1.6665 9.97591 1.6665ZM10.0009 13.5248C8.05091 13.5248 6.47591 11.9498 6.47591 9.99984C6.47591 8.04984 8.05091 6.47484 10.0009 6.47484C11.9509 6.47484 13.5259 8.04984 13.5259 9.99984C13.5259 11.9498 11.9509 13.5248 10.0009 13.5248Z"
                        fill="#E31B54"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z"
                        stroke="#292D32"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                </div>
                <p className="black14" style={{ fontWeight: "400" }}>
                  {item}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="d-flex " style={{ marginTop: "2.5rem", gap: "0.75rem" }}>
        <div className="d-flex" onClick={() => setWorkStudy(!workStudy)}>
          {workStudy ? (
            <>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z"
                  fill="#E31B54"
                />
              </svg>
            </>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.00521 1.66602H15.0052C16.8462 1.66602 18.3385 3.1584 18.3385 4.99935V14.9993C18.3385 16.8403 16.8462 18.3327 15.0052 18.3327H5.00521C3.16426 18.3327 1.67188 16.8403 1.67188 14.9993V4.99935C1.67188 3.1584 3.16426 1.66602 5.00521 1.66602ZM16.4783 16.4725C16.869 16.0818 17.0885 15.5519 17.0885 14.9993V4.99935C17.0885 4.44681 16.869 3.91691 16.4783 3.52621C16.0876 3.13551 15.5577 2.91602 15.0052 2.91602H5.00521C3.85461 2.91602 2.92188 3.84876 2.92188 4.99935V14.9993C2.92188 15.5519 3.14137 16.0818 3.53207 16.4725C3.92277 16.8632 4.45267 17.0827 5.00521 17.0827H15.0052C15.5577 17.0827 16.0876 16.8632 16.4783 16.4725Z"
                fill="#344054"
              />
            </svg>
          )}
        </div>
        <p className="black14" style={{ fontWeight: "400" }}>
          Work-Study program
        </p>
      </div>
      <div style={{ marginTop: "2.5rem" }} className="jobcreatereactquill">
        <p className="thumbnailcreate">Job description</p>
        <ReactQuill
          value={jobDescription}
          onChange={(value) => setJobDescription(value)}
          modules={modules}
          formats={formats}
          className="col-md-12  reactquillfontfix whitescrollbar "
          style={{
            backgroundColor: "#FFF",
            marginTop: "0.75rem",
          }}
        />

        <p className="formtext" style={{ marginTop: "1rem" }}>
          {jobDescription.replace(/(<([^>]+)>)/gi, "").length} Characters
        </p>
      </div>
      <hr style={{ marginTop: "2.5rem", marginBottom: "1.25rem" }} />
      <div
        className="d-flex flex-wrap mb-5"
        style={{ justifyContent: "space-between", gap: "1.25rem" }}
      >
        <button
          className="backbutton"
          onClick={() => navigate("/dashboard/Jobposts")}
        >
          Back
        </button>
        <button
          className="continuebutton"
          onClick={() => {
            handleSubmit();
          }}
        >
          Continue <ChevronRightIcon />
        </button>
      </div>
    </div>
  );
};

export default Step1;
