import { useState } from "react";
import Header from "../../../components/Company/field/Header";
import PrimaryFooter from "../../../layouts/components/Footer/PrimaryFooter";
import Inputs from "./Inputs";
import { toast } from "react-toastify";

import Center from "./Center";
import axios from "axios";

const UniversityRegister = () => {
  const [Done, setDone] = useState(false);
  const [email, setEmails] = useState(null);
  const [uniqueId, setUniqueId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");

  const sendVerifyEmail = async () => {
    setLoading(true);
    const data = {
      email,
      password,
    };
    await axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/career-center/register`, data)
      .then((res) => {
        if (res.data.msg === "success") {
          setDone(true);
          if (Done) {
            toast.success("Verification Email Sent");
          }
        } else if (res.data.msg === "User already exists") {
          toast.error("Email already Registered");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An Error Occured while registering, please try again");
      });
    setLoading(false);
  };

  return (
    <div
      className="backgroundimg d-flex"
      style={{ justifyContent: "space-between", flexDirection: "column" }}
    >
      <div className="marginsleftrightCompany">
        <div className="d-flex" style={{ justifyContent: "center" }}>
          <div className="headerCompany">
            <Header />
          </div>
        </div>

        <div className="d-flex" style={{ justifyContent: "center" }}>
          <div className="headerCompany">
            {Done ? (
              <Center
                email={email}
                setDone={setDone}
                sendVerifyEmail={sendVerifyEmail}
                uniqueId={uniqueId}
                loading={loading}
              />
            ) : (
              <Inputs
                Done={Done}
                setDone={setDone}
                email={email}
                setEmails={setEmails}
                sendVerifyEmail={sendVerifyEmail}
                setUniqueId={setUniqueId}
                loading={loading}
                setPassword={setPassword}
                password={password}
              />
            )}
          </div>
        </div>
      </div>
      <div className="marginsleftrightCompany mb-5">
        <div className="d-flex" style={{ justifyContent: "center" }}>
          <div className="headerCompany">
            <PrimaryFooter />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UniversityRegister;
