import { toast } from "react-toastify";
import upload from "../../assets/images/icons/upload.png";
import React, { useEffect, useState } from "react";
import UnselectedBox from "../../assets/images/icons/unselectedBox";
import SelectedBox from "../../assets/images/icons/selectedbox";
const Step5 = ({
  setDocuments,
  documents,
  allData,
  setAddedJobChal,
  addedJobChal,
  handleRemoveDocument,
  TRUE,
}) => {
  const fileInputRef = React.createRef();

  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const entriesPerPage = 5;
  const [filteredData, setFilteredData] = useState(allData);

  const [currentEntries, setCurrentEntries] = useState([]);

  useEffect(() => {
    if (search) {
      const filtered = allData.filter((entry) =>
        entry?.title?.toLowerCase().includes(search.toLowerCase())
      );
      setFilteredData(filtered);
      setCurrentPage(1);
    } else {
      setFilteredData(allData);
    }
  }, [search, allData]);

  useEffect(() => {
    const updateCurrentEntries = () => {
      const indexOfLastEntry = currentPage * entriesPerPage;
      const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
      setCurrentEntries(
        filteredData.slice(indexOfFirstEntry, indexOfLastEntry)
      );
    };
    updateCurrentEntries();
  }, [currentPage, filteredData]);

  let indexOfLastEntry = currentPage * entriesPerPage;
  let indexOfFirstEntry = indexOfLastEntry - entriesPerPage;

  //   let totalEntries = oldData.length;
  //   let totalPages = Math.ceil(totalEntries / entriesPerPage);

  //   useEffect(() => {
  //     setCurrentEntries(oldData.slice(indexOfFirstEntry, indexOfLastEntry));
  //   }, [oldData]);

  //   useEffect(() => {
  //     let filteredData = [...oldData];
  //     console.log(filteredData);
  //     if (search) {
  //       filteredData = filteredData.filter((entry) =>
  //         `${entry?.user?.firstName} ${entry?.user?.LastName}`
  //           .toLowerCase()
  //           .includes(search.toLowerCase())
  //       );
  //       setOldData(filteredData);
  //     } else {
  //       setOldData(oldData);
  //     }

  //     setCurrentPage(1);
  //   }, [search]);

  const openFileInput = () => {
    fileInputRef.current.click();
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  //   useEffect(() => {
  //     setCurrentEntries(oldData.slice(indexOfFirstEntry, indexOfLastEntry));
  //   }, [currentPage, oldData, indexOfFirstEntry, indexOfLastEntry]);

  const getFileIcon = (fileName) => {
    if (fileName && fileName.split(".")) {
      const fileExtension = fileName.split(".").pop().toLowerCase();
      const fileIcons = {
        pdf: "#155EEF",
        docx: "#155EEF",
        txt: "#344054",
        xlsl: "#099250",
        ppt: "#E62E05",
      };

      const color = fileIcons[fileExtension] || "#E62E05";

      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="33"
          height="40"
          viewBox="0 0 33 40"
          fill="none"
        >
          <path
            d="M0 4C0 1.79086 1.79086 0 4 0H20L32 12V36C32 38.2091 30.2091 40 28 40H4C1.79086 40 0 38.2091 0 36V4Z"
            fill={color}
          />
          <path
            opacity="0.3"
            d="M20 0L32 12H24C21.7909 12 20 10.2091 20 8V0Z"
            fill="white"
          />
          <text
            x="50%"
            y="70%"
            dominantBaseline="middle"
            textAnchor="middle"
            fill="#fff"
            fontSize="8"
          >
            {fileExtension.toUpperCase()}
          </text>
        </svg>
      );
    }
  };

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    const currentDocuments = documents.length;
    const newDocumentsCount = selectedFiles.length;

    if (currentDocuments + newDocumentsCount > 5) {
      const allowedFiles = Array.from(selectedFiles).slice(
        0,
        5 - currentDocuments
      );
      setDocuments((prevDocuments) => [...prevDocuments, ...allowedFiles]);

      toast.error(
        `You can only upload up to 5 documents. ${
          newDocumentsCount - allowedFiles.length
        } files were not added.`,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
    } else {
      if (selectedFiles && selectedFiles.length > 0) {
        setDocuments((prevDocuments) => [
          ...prevDocuments,
          ...Array.from(selectedFiles),
        ]);
      } else {
        toast.error("Please select a valid file.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  const handleAddJobChal = (id) => {
    if (addedJobChal.some((ad) => ad === id)) {
      setAddedJobChal(addedJobChal.filter((ad) => ad !== id));
    } else {
      setAddedJobChal((prev) => [...prev, id]);
    }
  };

  return (
    <div style={{ width: "100%", maxWidth: "100vw" }}>
      <p className="BasicInforMationHeading">
        {!TRUE ? "Event Documents" : "Select Job"}
      </p>
      <p className="UnderBasicHeading" style={{ marginTop: "0.25rem" }}>
        Add relevant information to publish the event{" "}
      </p>
      <div style={{ marginTop: "2.5rem" }}>
        {!TRUE && (
          <>
            {" "}
            <p className="thumbnailcreate">Upload Documents</p>
            <div
              className=" border rounded-3"
              style={{
                backgroundColor: "#FFF",
                justifyContent: "center",
                marginTop: "0.75rem",
                padding: "1rem",
              }}
            >
              <div className="d-flex" style={{ justifyContent: "center" }}>
                {documents ? (
                  <div>
                    <div className="center">
                      <img
                        src={upload}
                        alt="upload"
                        width="40px"
                        height="40px"
                      />
                    </div>
                    <p
                      style={{
                        color: "#E31B54",
                        fontFamily: "Poppins",
                        fontWeight: "600",
                        fontSize: "14px",
                        cursor: "pointer",
                        marginTop: "1rem",
                      }}
                    >
                      {documents.length}{" "}
                      {documents.length > 1
                        ? "Files Selected"
                        : "File Selected"}
                    </p>
                  </div>
                ) : (
                  <div
                    className="mt-2 p-3 d-flex"
                    style={{
                      backgroundColor: "#FFF5F6",
                      borderRadius: "60px",
                      height: "60px",
                      width: "60px",
                      justifyContent: "center",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="24"
                      viewBox="0 0 20 24"
                      fill="none"
                    >
                      <path
                        d="M0.399902 4C0.399902 1.79086 2.19076 0 4.3999 0H12.3999L19.5999 7.2V20C19.5999 22.2091 17.809 24 15.5999 24H4.3999C2.19076 24 0.399902 22.2091 0.399902 20V4Z"
                        fill="#E31B54"
                      />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="11"
                        viewBox="0 0 10 11"
                        fill="none"
                        x="5"
                        y="6"
                      >
                        <g clipPath="url(#clip0_4343_10944)">
                          <path
                            d="M5.4 3.3998L4.95378 2.50737C4.82536 2.25053 4.76115 2.12210 4.66535 2.02827C4.58063 1.94530 4.47853 1.88219 4.36643 1.84353C4.23967 1.7998 4.09608 1.7998 3.80892 1.7998H2.28C1.83196 1.7998 1.60794 1.7998 1.43681 1.887C1.28628 1.9637 1.16389 2.08608 1.08719 2.23661C1 2.40774 1 2.63176 1 3.0798V3.3998M1 3.3998H7.08C7.75206 3.3998 8.08809 3.3998 8.34479 3.5306C8.57058 3.64565 8.75416 3.82922 8.86921 4.05502C9 4.31171 9 4.64774 9 5.31981V7.07981C9 7.75187 9 8.0879 8.86921 8.34459C8.75416 8.57039 8.57058 8.75397 8.34479 8.86901C8.08809 8.99981 7.75206 8.99981 7.08 8.99981H2.92C2.24794 8.99981 1.91191 8.99981 1.65521 8.86901C1.42942 8.75397 1.24584 8.57039 1.13079 8.34459C1 8.0879 1 7.75187 1 7.07981V3.3998Z"
                            stroke="white"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_4343_10944">
                            <rect
                              width="9.6"
                              height="9.6"
                              fill="white"
                              transform="translate(0.200195 0.599609)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </svg>
                  </div>
                )}
              </div>
              {!documents && (
                <div
                  className="d-flex mt-2 "
                  style={{ justifyContent: "center" }}
                >
                  <p className="addthumbnail">Add Reletive Documents</p>
                </div>
              )}
              <div
                className="d-flex mt-2 mb-3"
                style={{ justifyContent: "center" }}
              >
                {" "}
                <p
                  className="d-flex  pink16"
                  style={{ gap: "10px", cursor: "pointer" }}
                  onClick={openFileInput}
                >
                  click to upload
                </p>
                <input
                  type="file"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  ref={fileInputRef}
                  multiple
                />
              </div>
            </div>
            <div
              className="d-flex flex-wrap"
              style={{ gap: "1.5rem 1rem", marginTop: "0.5rem" }}
            >
              {documents && documents.length > 0 && (
                <>
                  {Array.from(documents).map((file, index) => (
                    <div
                      key={index}
                      className="d-flex rounded-3 "
                      style={{ background: "#FFF", padding: "6px 12px" }}
                    >
                      <div className="">
                        {file?.originalname
                          ? getFileIcon(file.originalname)
                          : getFileIcon(file.name)}
                      </div>{" "}
                      <p
                        style={{
                          color: "#E31B54",
                          fontFamily: "Poppins",
                          fontWeight: "600",
                          fontSize: "14px",
                          cursor: "pointer",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        className="  m-2 d-flex"
                      >
                        {file?.originalname
                          ? file.originalname.length > 20
                            ? file.originalname.slice(0, 20) + "..."
                            : file.originalname
                          : file.name.length > 20
                          ? file.name.slice(0, 20) + "..."
                          : file.name}
                      </p>
                      <div
                        className=" d-flex"
                        style={{
                          cursor: "pointer",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          handleRemoveDocument(file);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17"
                          height="17"
                          viewBox="0 0 17 17"
                          fill="none"
                        >
                          <path
                            d="M9.30108 8.28369L14.3648 3.23713C14.5763 3.02359 14.5763 2.67765 14.3648 2.46411C14.157 2.24675 13.8138 2.24022 13.598 2.44952L8.53429 7.49607L3.5357 2.44952C3.43322 2.34024 3.29063 2.27832 3.14145 2.27832C2.99228 2.27832 2.84969 2.34024 2.74721 2.44952C2.55964 2.65584 2.55964 2.97248 2.74721 3.17879L7.7458 8.21805L2.6821 13.2573C2.47055 13.4709 2.47055 13.8168 2.6821 14.0303C2.78282 14.1343 2.92133 14.1923 3.0655 14.1908C3.21244 14.2028 3.35811 14.1556 3.47059 14.0595L8.53429 9.01296L13.598 14.1179C13.6987 14.2219 13.8372 14.2798 13.9814 14.2783C14.1254 14.279 14.2636 14.2211 14.3648 14.1179C14.5763 13.9043 14.5763 13.5584 14.3648 13.3448L9.30108 8.28369Z"
                            fill="black"
                          />
                        </svg>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </>
        )}
        <div style={{ marginTop: "2.5rem" }}>
          {!TRUE && (
            <p className="thumbnailcreate">Recomended Challenges and jobs</p>
          )}
          <div
            className="shadowa"
            style={{
              marginTop: "0.75rem",
              background: "#FFF",
              padding: "1.5rem",
            }}
          >
            <p className="black16 text-start">Add Jobs</p>
            <input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              type="search"
              placeholder="Search Jobs and Challenges"
              className="newinputs col-md-12 col-12"
              style={{ marginTop: "1.5rem" }}
            />
            <table
              className="tableofchallenges"
              style={{
                width: "100%",
                overflowX: "scroll",
                boxShadow: "none",
                marginTop: "0.75rem",
              }}
            >
              <thead className="thead">
                <tr className="theadtr">
                  <td className="theadth">Title</td>
                  <td className="theadth">Deadline</td>
                  <td className="theadth">Type</td>
                </tr>
              </thead>
              <tbody className="tbody">
                {currentEntries && currentEntries.length > 0 && (
                  <>
                    {currentEntries.map((challenge, index) => (
                      <tr key={index} className="tbodytr">
                        <td
                          className="tbodytrtd align-items-center d-flex"
                          style={{ gap: "0.5rem" }}
                        >
                          <div
                            onClick={() => {
                              handleAddJobChal(challenge._id);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            {" "}
                            {addedJobChal.some((e) => e === challenge._id) ? (
                              <SelectedBox />
                            ) : (
                              <UnselectedBox />
                            )}
                          </div>
                          {JSON.parse(challenge?.company)?.img && (
                            <img
                              src={JSON.parse(challenge?.company)?.img}
                              alt="company"
                              className="shadowa rounded-1 center align-items-center"
                              style={{
                                padding: "5px",
                                maxHeight: "30px",
                                maxWidth: "30px",
                                background: "#FFF",
                              }}
                            />
                          )}

                          {challenge?.title}
                        </td>
                        <td className="tbodytrtd">
                          {challenge?.estimatedEndDate
                            ? new Date(
                                challenge?.estimatedEndDate
                              ).toDateString()
                            : "N/A"}
                        </td>
                        <td className="tbodytrtd">
                          <div className="d-flex">
                            <div className="jobCardBlueChip black12">
                              {" "}
                              {challenge?.type
                                ? challenge?.type
                                : challenge?.timeRequirement}
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </table>
            <div className="d-flex justify-content-between align-items-center mt-3">
              <button
                className="backbutton"
                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <p className="mb-0 black14">Page {currentPage}</p>
              <button
                className="continuebutton"
                onClick={() => setCurrentPage((prev) => prev + 1)}
                disabled={currentEntries.length < entriesPerPage}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Step5;
