import { FiEdit } from "react-icons/fi";
import Cast from "../../assets/images/icons/Cast";
import Location from "../../assets/images/icons/Location";
import Calender from "../../assets/images/icons/Calender";
import on from "../../assets/images/on.svg";
import off from "../../assets/images/off.png";
import ChallengeCard from "../../components/Company/Dashboard/ChallengeCard";
import JobCard from "../JobPost/jobCard";
import photo from "../../assets/images/avatar/profile.png";

const Step6 = ({
  setSteps,
  company,
  title,
  description,
  type,
  datetime,
  duration,
  language,
  schoolYear,
  targetGroups,
  relevantCourses,
  settings,
  isNonMobile1000,
  users,
  addedUsers,
  addedSpeakers,
  documents,
  handleRemoveDocument,
  addedJobChal,
  allData,
}) => {
  const [date, time] = datetime.split("T");

  const getFileIcon = (fileName) => {
    if (fileName && fileName.split(".")) {
      const fileExtension = fileName.split(".").pop().toLowerCase();
      const fileIcons = {
        pdf: "#155EEF",
        docx: "#155EEF",
        txt: "#344054",
        xlsl: "#099250",
        ppt: "#E62E05",
      };

      const color = fileIcons[fileExtension] || "#E62E05";

      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="33"
          height="40"
          viewBox="0 0 33 40"
          fill="none"
        >
          <path
            d="M0 4C0 1.79086 1.79086 0 4 0H20L32 12V36C32 38.2091 30.2091 40 28 40H4C1.79086 40 0 38.2091 0 36V4Z"
            fill={color}
          />
          <path
            opacity="0.3"
            d="M20 0L32 12H24C21.7909 12 20 10.2091 20 8V0Z"
            fill="white"
          />
          <text
            x="50%"
            y="70%"
            dominantBaseline="middle"
            textAnchor="middle"
            fill="#fff"
            fontSize="8"
          >
            {fileExtension.toUpperCase()}
          </text>
        </svg>
      );
    }
  };

  return (
    <div style={{ width: "100%", maxWidth: "100vw" }}>
      <p className="BasicInforMationHeading">Event Preview</p>
      <p className="UnderBasicHeading" style={{ marginTop: "0.25rem" }}>
        Add relevant information to publish the event
      </p>
      <div style={{ marginTop: "2.5rem" }}>
        <div
          style={{ padding: "1.5rem", background: "#FFF" }}
          className="shadowa"
        >
          <div className="space">
            <p className="black16 text-start">Event Information</p>
            <div
              className="d-flex "
              style={{ gap: "0.75rem", cursor: "pointer" }}
              onClick={() => {
                setSteps(2);
              }}
            >
              <p className="pink14">Edit</p>
              <FiEdit color="#E31b54" />
            </div>
          </div>
          <div className="d-flex" style={{ marginTop: "1.5rem", gap: "1rem" }}>
            {company && company?.img && (
              <img
                src={company.img}
                alt="User Profile"
                className="rounded-2 shadowa px-2 py-2  center align-items-center"
                style={{
                  height: "48px",
                  width: "48px",
                }}
              />
            )}
            <p className="black20">{title}</p>
          </div>
          <div style={{ marginTop: "1.5rem" }}>
            <p className="thumbnailcreate">About the live event</p>
            <div
              className="black14all tablescroll"
              style={{
                marginTop: "0.75rem",
                maxHeight: "348px",
                overflow: "auto",
              }}
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            />
          </div>
          <div className="" style={{ marginTop: "1.5rem" }}>
            <p className="thumbnailcreate">Event Type</p>
            <div className="d-flex">
              <div style={{ marginTop: "0.75rem" }} className="whitechip">
                <div style={{ height: "16px", width: "16px" }}>
                  {" "}
                  {type === "varyance Livestream" && (
                    <Cast height={16} width={16} />
                  )}
                  {type === "Physical Event" && (
                    <Location height={16} width={16} />
                  )}
                  {type === "Job Schedule" && (
                    <Calender height={16} width={16} />
                  )}
                </div>
                {type}
              </div>
            </div>
          </div>
          <hr style={{ marginTop: "2.5rem", marginBottom: "1.25rem" }} />
          <div className="d-flex flex-wrap" style={{ gap: "1rem" }}>
            <div className="">
              <p className="thumbnailcreate">Event Date</p>
              <p
                className="underthumbnailcreate"
                style={{ marginTop: "0.75rem" }}
              >
                {date}
              </p>
            </div>
            <div className="">
              <p className="thumbnailcreate">Event Time</p>
              <p
                className="underthumbnailcreate"
                style={{ marginTop: "0.75rem" }}
              >
                {time}
              </p>
            </div>
          </div>
          <div style={{ marginTop: "1.5rem" }}>
            <p className="thumbnailcreate">Event Duration</p>
            <p
              className="underthumbnailcreate"
              style={{ marginTop: "0.75rem" }}
            >
              {duration}
            </p>
          </div>
          <div style={{ marginTop: "1.5rem" }}>
            <p className="thumbnailcreate">Language</p>
            <div className="d-flex">
              <p className="whitechip" style={{ marginTop: "0.75rem" }}>
                {language}
              </p>
            </div>
          </div>
        </div>
        <div
          style={{ padding: "1.5rem", marginTop: "2.5rem", background: "#FFF" }}
          className="shadowa"
        >
          <div className="space">
            <p className="black16 text-start">Requirements</p>
            <div
              className="d-flex "
              style={{ gap: "0.75rem", cursor: "pointer" }}
              onClick={() => {
                setSteps(3);
              }}
            >
              <p className="pink14">Edit</p>
              <FiEdit color="#E31b54" />
            </div>
          </div>
          <p className="thumbnailcreate" style={{ marginTop: "1.5rem" }}>
            Schools Year
          </p>
          <div
            className="d-flex flex-wrap"
            style={{ marginTop: "0.75rem", gap: "0.75rem" }}
          >
            {schoolYear.map((study, index) => (
              <div
                className="difficultybuttons"
                key={index}
                style={{
                  cursor: "pointer",
                  backgroundColor: schoolYear.some((s) => s === study)
                    ? "#FFF5F6"
                    : "",
                  color: schoolYear.some((s) => s === study) ? "#E31B54" : "",
                  border: schoolYear.some((s) => s === study)
                    ? "1px solid #FFE4E8"
                    : "",
                }}
              >
                {study}
              </div>
            ))}
            {schoolYear.length === 0 && <>--</>}
          </div>
          <p className="thumbnailcreate" style={{ marginTop: "1.5rem" }}>
            Relevant Courses
          </p>
          <div
            className="d-flex flex-wrap"
            style={{ marginTop: "0.75rem", gap: "0.75rem" }}
          >
            {relevantCourses.map((study, index) => (
              <div
                className="difficultybuttons"
                key={index}
                style={{
                  cursor: "pointer",
                  backgroundColor: relevantCourses.some((s) => s === study)
                    ? "#FFF5F6"
                    : "",
                  color: relevantCourses.some((s) => s === study)
                    ? "#E31B54"
                    : "",
                  border: relevantCourses.some((s) => s === study)
                    ? "1px solid #FFE4E8"
                    : "",
                }}
              >
                {study}
              </div>
            ))}{" "}
            {relevantCourses.length === 0 && <>--</>}
          </div>
          <p className="thumbnailcreate" style={{ marginTop: "1.5rem" }}>
            Targeted Schools
          </p>
          <div
            className="d-flex flex-wrap"
            style={{ marginTop: "0.75rem", gap: "0.75rem" }}
          >
            {targetGroups.map((study, index) => (
              <div
                className="difficultybuttons"
                key={index}
                style={{
                  cursor: "pointer",
                  backgroundColor: targetGroups.some((s) => s === study)
                    ? "#FFF5F6"
                    : "",
                  color: targetGroups.some((s) => s === study) ? "#E31B54" : "",
                  border: targetGroups.some((s) => s === study)
                    ? "1px solid #FFE4E8"
                    : "",
                }}
              >
                {study}
              </div>
            ))}
            {targetGroups.length === 0 && <>--</>}
          </div>
          <div style={{ marginTop: "1.5rem" }}>
            <div
              className="space"
              style={{
                flexDirection: isNonMobile1000 ? "row" : "column",
                gap: "1rem",
              }}
            >
              <div className="" style={{ maxWidth: "280px" }}>
                <p className="thumbnailcreate">Data collection</p>
                <p
                  className="underthumbnailcreate"
                  style={{ marginTop: "0.25rem" }}
                >
                  You can change these settings to set data collection during
                  the event
                </p>
              </div>
              <div className="d-flex flex-column " style={{ gap: "1rem" }}>
                <div className="d-flex" style={{ gap: "1rem" }}>
                  <img
                    src={settings.StudentProfile ? on : off}
                    alt="button"
                    width="36px"
                    height="20px"
                    style={{ cursor: "pointer" }}
                  />{" "}
                  <p className="gray14new">Student Profile</p>
                </div>
                <div className="d-flex " style={{ gap: "1rem" }}>
                  <img
                    src={settings.CV ? on : off}
                    alt="button"
                    width="36px"
                    height="20px"
                    style={{ cursor: "pointer" }}
                  />{" "}
                  <p className="gray14new">CV</p>
                </div>
                {settings.CV && (
                  <div className="d-flex " style={{ gap: "1rem" }}>
                    <img
                      src={settings.CVimp ? on : off}
                      alt="button"
                      width="36px"
                      height="20px"
                      style={{ cursor: "pointer" }}
                    />{" "}
                    <p className="gray14new" style={{ fontSize: "12px" }}>
                      CV(mandatory)
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            padding: "1.5rem",
            marginTop: "2.5rem",
            background: "#FFF",
          }}
          className="shadowa"
        >
          <div className="space">
            <p className="black16 text-start">Admin & Speakers</p>
            <div
              className="d-flex "
              style={{ gap: "0.75rem", cursor: "pointer" }}
              onClick={() => {
                setSteps(4);
              }}
            >
              <p className="pink14">Edit</p>
              <FiEdit color="#E31b54" />
            </div>
          </div>
          <div style={{ marginTop: "1.5rem" }}>
            <p className="thumbnailcreate">Admin</p>
            <div
              className="d-flex flex-column"
              style={{ marginTop: "0.75rem", gap: "1.5rem" }}
            >
              {addedUsers.map((user, index) => {
                const userInfo = users.find((s) => s.uniqueId === user);
                return (
                  <div
                    key={index}
                    style={{ gap: "0.75rem" }}
                    className="d-flex"
                  >
                    <img
                      src={userInfo.profilephoto}
                      alt="img"
                      height="40px"
                      width="40px"
                      className="rounded-5 p-1 shadowa"
                    />
                    <div>
                      <p className="black14">
                        {userInfo?.firstName} {userInfo?.lastName}
                      </p>
                      <p
                        className="thumbnailcreate"
                        style={{ marginTop: "0.25rem" }}
                      >
                        {userInfo?.department}
                      </p>
                    </div>
                  </div>
                );
              })}
              {addedUsers.length === 0 && <>--</>}
            </div>
          </div>
          <div style={{ marginTop: "1.5rem" }}>
            <p className="thumbnailcreate">Speakers</p>
            <div
              className="d-flex flex-column"
              style={{ marginTop: "0.75rem", gap: "1.5rem" }}
            >
              {addedSpeakers.map((user, index) => {
                const userInfo = users.find((s) => s.uniqueId === user);
                return (
                  <div
                    key={index}
                    style={{ gap: "0.75rem" }}
                    className="d-flex"
                  >
                    <img
                      src={userInfo ? userInfo.profilephoto : photo}
                      alt="img"
                      height="40px"
                      width="40px"
                      className="rounded-5 p-1 shadowa"
                    />
                    <div>
                      {userInfo ? (
                        <p className="black14">
                          {userInfo?.firstName} {userInfo?.lastName}
                        </p>
                      ) : (
                        <p className="black14">{user}</p>
                      )}
                      <p
                        className="thumbnailcreate"
                        style={{ marginTop: "0.25rem" }}
                      >
                        {userInfo ? userInfo?.department : "Guest Speaker"}
                      </p>
                    </div>
                  </div>
                );
              })}
              {addedSpeakers.length === 0 && <>--</>}
            </div>
          </div>
        </div>
        <div
          style={{
            padding: "1.5rem",
            marginTop: "2.5rem",
            background: "#FFF",
          }}
          className="shadowa"
        >
          {" "}
          <div className="space">
            <p className="black16 text-start">Event Documents</p>
            <div
              className="d-flex "
              style={{ gap: "0.75rem", cursor: "pointer" }}
              onClick={() => {
                setSteps(5);
              }}
            >
              <p className="pink14">Edit</p>
              <FiEdit color="#E31b54" />
            </div>
          </div>
          <div style={{ marginTop: "1.5rem" }}>
            <p className="thumbnailcreate">Documents</p>
            <div
              className="d-flex flex-wrap"
              style={{ gap: "1.5rem 1rem", marginTop: "0.75rem" }}
            >
              {documents && documents.length > 0 && (
                <>
                  {Array.from(documents).map((file, index) => (
                    <div
                      key={index}
                      className="d-flex rounded-3 "
                      style={{ background: "#FFF", padding: "6px 12px" }}
                    >
                      <div className="">
                        {file?.originalname
                          ? getFileIcon(file.originalname)
                          : getFileIcon(file.name)}
                      </div>{" "}
                      <p
                        style={{
                          color: "#E31B54",
                          fontFamily: "Poppins",
                          fontWeight: "600",
                          fontSize: "14px",
                          cursor: "pointer",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        className="  m-2 d-flex"
                      >
                        {file?.originalname
                          ? file.originalname?.length > 20
                            ? file.originalname.slice(0, 20) + "..."
                            : file.originalname
                          : file.name?.length > 20
                          ? file.name.slice(0, 20) + "..."
                          : file.name}
                      </p>
                      <div
                        className=" d-flex"
                        style={{
                          cursor: "pointer",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          handleRemoveDocument(file);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17"
                          height="17"
                          viewBox="0 0 17 17"
                          fill="none"
                        >
                          <path
                            d="M9.30108 8.28369L14.3648 3.23713C14.5763 3.02359 14.5763 2.67765 14.3648 2.46411C14.157 2.24675 13.8138 2.24022 13.598 2.44952L8.53429 7.49607L3.5357 2.44952C3.43322 2.34024 3.29063 2.27832 3.14145 2.27832C2.99228 2.27832 2.84969 2.34024 2.74721 2.44952C2.55964 2.65584 2.55964 2.97248 2.74721 3.17879L7.7458 8.21805L2.6821 13.2573C2.47055 13.4709 2.47055 13.8168 2.6821 14.0303C2.78282 14.1343 2.92133 14.1923 3.0655 14.1908C3.21244 14.2028 3.35811 14.1556 3.47059 14.0595L8.53429 9.01296L13.598 14.1179C13.6987 14.2219 13.8372 14.2798 13.9814 14.2783C14.1254 14.279 14.2636 14.2211 14.3648 14.1179C14.5763 13.9043 14.5763 13.5584 14.3648 13.3448L9.30108 8.28369Z"
                            fill="black"
                          />
                        </svg>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
          <div style={{ marginTop: "0.75rem" }}>
            <p className="thumbnailcreate">Recomended Challenge</p>
            <div className="d-flex flex-wrap" style={{ gap: "1rem" }}>
              {addedJobChal.map((user, index) => {
                const userInfo = allData.find((s) => s._id === user);
                return (
                  <div key={index}>
                    {userInfo?.alltask ? (
                      <ChallengeCard challe={userInfo} nonavigate={true} />
                    ) : (
                      <JobCard challe={userInfo} index={index} />
                    )}
                  </div>
                );
              })}
              {addedJobChal?.length === 0 && <>--</>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Step6;
