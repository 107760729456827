import MoreVertIcon from "@mui/icons-material/MoreVert";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
const Profile = ({
  viewedUser,
  isNonMobile450,
  viewedCompany,
  user,
  navigate,
  isNonMobile1024,
  setViewedUser,
}) => {
  const iconRef = useRef();

  const [selectedFile, setSelectedFile] = useState(null);

  const openFileInput = () => {
    fileInputRef.current.click();
  };
  const fileInputRef = React.createRef();

  const [showDropDown, setShowDropDown] = useState(false);
  useEffect(() => {
    // Scroll to the top
    window.scrollTo({ top: 0, behavior: "instant" });

    function handleClickOutside(event) {
      // Check if the click is outside iconRef
      if (iconRef.current && !iconRef.current.contains(event.target)) {
        setShowDropDown(false);
      }

      // Check if the click is outside iconRef2
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [iconRef]);
  const handleCopy = () => {
    const link = `${process.env.REACT_APP_FRONTEND_URL}/dashboard/u/${viewedUser?.url}`;

    navigator.clipboard
      .writeText(link)
      .then(() => {
        toast.success("Link copied to clipboard!");
        setShowDropDown(false);
      })
      .catch((err) => {
        toast.error("Failed to copy the link.");
      });
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    if (file && file.type.startsWith("image/")) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("userid", user?.uniqueId);

      try {
        await axios
          .post(
            `${process.env.REACT_APP_BACKEND_URL}/upload/recruiter/cover`,
            formData
          )
          .then((res) => {
            if (res.data.message === "File uploaded successfully!") {
              setViewedUser(res.data.user);
            } else {
              toast.error("Error uploading file");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        // Handle network error
        toast.error("An error occurred while uploading the image.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } else {
      toast.error("Please select a valid image file.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  return (
    <div
      style={{
        padding: "20px",
        paddingTop: "0px",
        background: "#FFF",
        borderRadius: "0px 0px 4px 4px",
      }}
    >
      <div className="space">
        <img
          src={viewedUser?.profilephoto}
          alt="profile"
          className="center"
          height={isNonMobile450 ? "80px" : "64px"}
          width={isNonMobile450 ? "80px" : "64px"}
          style={{
            borderRadius: "125px",
            alignItems: "center",
            position: "relative",
            background: "#FFF",

            padding: "2px",
            marginTop: "-40px",
          }}
        />
        {user?._id === viewedUser?._id && (
          <div
            className="center black14 hoverred gap-2 backbutton p-2 rounded-5"
            style={{
              alignItems: "center",
              cursor: "pointer",
              marginTop: "-50px",
              maxHeight: "40px",
            }}
            onClick={() => openFileInput()}
          >
            {" "}
            <EditIcon style={{ width: "20px" }} />
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              style={{ display: "none" }}
              ref={fileInputRef}
            />
          </div>
        )}
      </div>
      <div
        className="d-flex flex-wrap space align-items-center"
        style={{ gap: "1rem", marginTop: "1rem" }}
      >
        <div
          className="d-flex flex-wrap align-items-center"
          style={{ gap: "0.5rem" }}
        >
          <p className="black90020400">
            {viewedUser?.firstName} {viewedUser?.lastName}
          </p>
          <div className="Role-outer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <mask
                id="mask0_11986_93383"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="16"
                height="16"
              >
                <rect width="16" height="16" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_11986_93383)">
                <path
                  d="M7.30423 10.3673L11.0709 6.60065L10.1209 5.65065L7.30423 8.46732L5.90423 7.06732L4.95423 8.01732L7.30423 10.3673ZM8.00423 14.6673C6.45979 14.2784 5.18479 13.3923 4.17923 12.009C3.17368 10.6257 2.6709 9.08954 2.6709 7.40065V3.33398L8.00423 1.33398L13.3376 3.33398V7.40065C13.3376 9.08954 12.8348 10.6257 11.8292 12.009C10.8237 13.3923 9.54867 14.2784 8.00423 14.6673ZM8.00423 13.2673C9.15979 12.9007 10.1153 12.1673 10.8709 11.0673C11.6265 9.96732 12.0042 8.7451 12.0042 7.40065V4.25065L8.00423 2.75065L4.00423 4.25065V7.40065C4.00423 8.7451 4.38201 9.96732 5.13756 11.0673C5.89312 12.1673 6.84868 12.9007 8.00423 13.2673Z"
                  fill="#444CE7"
                />
              </g>
            </svg>
            {viewedUser?.invite ? "Company Representative" : viewedUser?.Role}
          </div>
        </div>
        <div style={{ position: "relative" }}>
          {" "}
          <MoreVertIcon
            className="hoverred shadowa"
            onClick={() => {
              setShowDropDown(!showDropDown);
            }}
            style={{
              cursor: "pointer",
              background: "#FFF",
              borderRadius: "5px",
              height: "40px",
              width: "40px",
              padding: "10px",
            }}
          />
          {showDropDown && (
            <div
              className="shadowa"
              ref={iconRef}
              style={{
                position: "absolute",
                width: "230px",
                right: "1px",
              }}
            >
              {user?._id === viewedUser?._id && (
                <button
                  className="dropdowntopbutton"
                  onClick={() => {
                    navigate("/dashboard/settings");
                  }}
                >
                  Edit Profile
                </button>
              )}
              <button
                className="dropdownbottombutton"
                onClick={() => handleCopy()}
              >
                Copy Link
              </button>
            </div>
          )}
        </div>
      </div>

      {viewedUser?.tagline && (
        <p
          className="gray95014400"
          style={{
            maxWidth: isNonMobile1024 ? "50%" : "100%",
            marginTop: "0.75rem",
          }}
        >
          {viewedUser?.tagline}
        </p>
      )}
      <div
        className="d-flex flex-wrap align-items-center"
        style={{ marginTop: "2rem", gap: "1rem" }}
      >
        <div className="p-2 rounded-2 shadowa" style={{ background: "#FFF" }}>
          <img
            src={viewedCompany?.img}
            alt="img"
            style={{ maxWidth: "42px", maxHeight: "42px" }}
          />
        </div>

        <div>
          <p className="black14500">{viewedCompany?.title}</p>
          <p className="gray14400" style={{ marginTop: "0.25rem" }}>
            {viewedUser?.jobTitle}
          </p>
        </div>
      </div>
    </div>
  );
};
export default Profile;
