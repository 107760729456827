// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progressbackground {
  padding: 30px;

  border-radius: 12px;
  background: #fff;
}

.f16gray700 {
  color: var(--Gray-700, #344054);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}
.f14gray700 {
  color: var(--Gray-700, #344054);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
`, "",{"version":3,"sources":["webpack://./src/pages/Progress/main.css"],"names":[],"mappings":"AAAA;EACE,aAAa;;EAEb,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,+BAA+B;EAC/B,oBAAoB;EACpB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,SAAS;AAC9B;AACA;EACE,+BAA+B;EAC/B,oBAAoB;EACpB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,aAAa;AAClC","sourcesContent":[".progressbackground {\n  padding: 30px;\n\n  border-radius: 12px;\n  background: #fff;\n}\n\n.f16gray700 {\n  color: var(--Gray-700, #344054);\n  font-family: Poppins;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 24px; /* 150% */\n}\n.f14gray700 {\n  color: var(--Gray-700, #344054);\n  font-family: Poppins;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 20px; /* 142.857% */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
