import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EmptySpace from "../../assets/images/EmptyState.svg";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import CheckBoxOutlineBlankRoundedIcon from "@mui/icons-material/CheckBoxOutlineBlankRounded";
import SelectedBox from "../../assets/images/icons/selectedbox";
import UnselectedBox from "../../assets/images/icons/unselectedBox";
const Table = ({
  challenges,
  jobposts,
  TRUE,
  selectedStudents,
  setSelectedStudents,
  applyFilter,
  setApplyFilter,
  engageselect,
  locationfilter,
  languagefilter,
  studyLevel,
  graduationFilter,
  appliedUnis,
  academicFilter,
  handleShortList,
  handleRemove,
  blocked,
  searchFilter,
  checker,
  setTotal,
  Reset,
}) => {
  const [usersWithDetails, setUsersWithDetails] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const entriesPerPage = 6;
  const [showIcon, setShowIcon] = useState(false);
  let indexOfLastEntry = currentPage * entriesPerPage;
  let indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  let [currentEntries, setCurrentEntries] = useState([]);
  const [Index, setIndex] = useState(-1);
  const [totalPages, setTotalPages] = useState(0);
  const [oldData, setOldData] = useState([]);
  const navigate = useNavigate();
  const [userstoshow, setUserstoShow] = useState([]);

  useEffect(() => {
    let filteredData = [...oldData];

    if (searchFilter) {
      filteredData = filteredData.filter((entry) =>
        `${entry?.userDetails?.user?.firstName} ${entry?.userDetails?.user?.LastName}`
          .toLowerCase()
          .includes(searchFilter.toLowerCase())
      );
      setUserstoShow(filteredData);
    } else {
      setUserstoShow(oldData);
    }

    setCurrentPage(1);
  }, [searchFilter]);

  console.log({ userstoshow });

  useEffect(() => {
    if (checker?.length > 0 && !searchFilter) {
      let arr = [];
      usersWithDetails?.map((user) => {
        if (user?.userDetails?.user?._id) {
          if (checker.some((check) => check === user?.userDetails?.user?._id)) {
            arr.push(user);
          }
        }
      });
      setUserstoShow([...arr]);
      setOldData([...arr]);
    } else if (Reset) {
      setUserstoShow([]);
      setOldData([]);
    } else if (TRUE && !searchFilter) {
      setUserstoShow([...usersWithDetails]);
      setOldData([...usersWithDetails]);
    }
  }, [usersWithDetails, checker, jobposts, challenges]);

  useEffect(() => {
    if (appliedUnis?.length > 0) {
      indexOfLastEntry = currentPage * entriesPerPage;
      indexOfFirstEntry = indexOfLastEntry - entriesPerPage;

      const filtered = userstoshow.filter((chk) => {
        return appliedUnis?.some(
          (uni) => uni.name === chk?.userDetails?.user?.uni?.name
        );

        return false;
      });

      const filteredArray = filtered.filter((chk) => {
        return !checker.some((check) => {
          return check !== chk?.userDetails?.user?._id;
        });
      });

      setCurrentEntries(
        filteredArray.slice(indexOfFirstEntry, indexOfLastEntry)
      );
      setTotal(filteredArray.length);

      setTotalPages(Math.ceil(filteredArray.length / entriesPerPage));
    } else {
      setCurrentEntries(userstoshow.slice(indexOfFirstEntry, indexOfLastEntry));
      setTotal(userstoshow.length);

      setTotalPages(Math.ceil(userstoshow.length / entriesPerPage));
    }
  }, [appliedUnis, jobposts, challenges, userstoshow]);

  useEffect(() => {
    if (!appliedUnis) {
      setCurrentEntries(userstoshow.slice(indexOfFirstEntry, indexOfLastEntry));

      setTotal(userstoshow.length);

      setTotalPages(Math.ceil(userstoshow.length / entriesPerPage));
    }
  }, [
    currentPage,
    usersWithDetails,
    indexOfFirstEntry,
    indexOfLastEntry,
    userstoshow,
    jobposts,
    challenges,
  ]);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (applyFilter) {
      let filteredData = [...oldData];

      if (engageselect) {
        if (engageselect === "Low") {
          filteredData = filteredData.filter(
            (entry) => entry.levelofEngagement < 25
          );
        } else if (engageselect === "Medium") {
          filteredData = filteredData.filter(
            (entry) =>
              entry.levelofEngagement >= 25 && entry.levelofEngagement < 50
          );
        } else if (engageselect === "High") {
          filteredData = filteredData.filter(
            (entry) =>
              entry.levelofEngagement >= 50 && entry.levelofEngagement < 75
          );
        } else if (engageselect === "Your Fan") {
          filteredData = filteredData.filter(
            (entry) => entry.levelofEngagement >= 75
          );
        }
      }
      if (searchFilter) {
        filteredData = filteredData.filter((entry) =>
          `${entry.userDetails.user.firstName} ${entry.userDetails.user.LastName}`
            .toLowerCase()
            .includes(searchFilter.toLowerCase())
        );
        setUsersWithDetails(filteredData);
      }

      if (locationfilter) {
        filteredData = filteredData.filter(
          (entry) =>
            entry.userDetails.user.uni.area.toLowerCase() ===
            locationfilter.toLowerCase()
        );
      }

      if (studyLevel) {
        filteredData = filteredData.filter(
          (entry) =>
            entry.userDetails.user.levelofstudy.toLowerCase() ===
            studyLevel.toLowerCase()
        );
      }

      if (graduationFilter) {
        filteredData = filteredData.filter(
          (entry) =>
            entry.userDetails.user.graduation.Year.toLowerCase() ===
            graduationFilter.toLowerCase()
        );
      }

      setUsersWithDetails(filteredData);
      setApplyFilter(false); // Reset applyFilter to false after applying filters
    }
  }, [
    applyFilter,
    engageselect,
    locationfilter,
    studyLevel,
    graduationFilter,
    jobposts,
    challenges,
  ]);

  const renderPageButtons = () => {
    const maxButtons = 5; // Maximum number of buttons to display
    const buttons = [];

    // Calculate the range of buttons to display around the current page
    let startPage = Math.max(1, currentPage - Math.floor(maxButtons / 2));
    let endPage = Math.min(totalPages, startPage + maxButtons - 1);

    // Adjust startPage and endPage if there are not enough buttons
    if (endPage - startPage + 1 < maxButtons) {
      if (currentPage <= Math.floor(maxButtons / 2) + 1) {
        endPage = Math.min(totalPages, maxButtons);
      } else {
        startPage = Math.max(1, totalPages - maxButtons + 1);
      }
    }

    // Render the buttons in the calculated range
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          key={i}
          style={
            i === currentPage
              ? { backgroundColor: "#E31B54", color: "#FFF" }
              : { color: "#000", backgroundColor: "transparent" } // Adjust colors as needed
          }
          onClick={() => handlePageChange(i)}
          className={`btn normalbuttons ${i === currentPage && " rounded-3"}`}
        >
          {i}
        </button>
      );
    }

    return buttons;
  };

  useEffect(() => {
    const fetchData = async () => {
      const users = [];

      const check = [...challenges, ...jobposts] || [];
      if (check.length > 0) {
        const clickDates = check.reduce((allDates, challenge) => {
          return [...allDates, ...challenge.clickDates];
        }, []);

        clickDates.forEach((date) => {
          if (!users.includes(date.userId)) {
            users.push(date.userId);
          }
        });

        const results = await Promise.all(
          users.map(async (user) => {
            let count = 0;
            let complete = 0;
            let apply = 0;

            check.forEach((chec) => {
              chec.clickDates.forEach((click) => {
                if (click.userId === user) {
                  count++;
                }
              });
            });
            challenges.forEach((chec) => {
              chec.countDates.forEach((click) => {
                if (click.userId === user) {
                  complete++;
                }
              });
            });
            jobposts.forEach((chec) => {
              chec.countDates.forEach((click) => {
                if (click.userId === user) {
                  apply++;
                }
              });
            });
            const countPercentage = Math.min(
              (count / challenges.length) * 20,
              20
            );
            const completePercentage = Math.min(
              (complete / challenges.length) * 60,
              60
            );

            const totalPercentage = countPercentage + completePercentage;

            // Normalize to ensure the total does not exceed 100%
            const levelofEngagement = Math.min(totalPercentage, 100);

            if (apply > 0) {
              levelofEngagement += 10;
            }
            try {
              const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/get/user/details`,
                {
                  userId: user,
                  msg: "fjksabnfjkbnasjfbajsbfjasjhfbjahsbjkfbk",
                }
              );

              return {
                userId: user,
                levelofEngagement: levelofEngagement,
                userDetails: response.data,
              };
            } catch (error) {
              console.error("Error fetching user details:", error);
              return null;
            }
          })
        );
        results.sort((a, b) => b.levelofEngagement - a.levelofEngagement);

        if (blocked) {
          const filteredResults = results?.filter((user) => {
            return !blocked?.some((block) =>
              block?.list?.some(
                (bl) => bl?.user === user?.userDetails?.user?._id
              )
            );
          });

          setUsersWithDetails(filteredResults);
          // setOldData(filteredResults);
        } else {
          setUsersWithDetails(results);
          // setOldData(results);
        }
      }
    };

    fetchData();
  }, [challenges, jobposts, checker]);

  const ShowUserRecord = async (user, index) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/get/user/details`,
        {
          userId: user.userId,
          msg: "fjksabnfjkbnasjfbajsbfjasjhfbjahsbjkfbk",
        }
      );

      return (
        <tr key={index} className="tbodytr">
          <td className="tbodytrtd">a</td>
        </tr>
      );
    } catch (error) {
      console.error("Error fetching user details:", error);
      return null; // or handle the error accordingly
    }
  };

  const iconRef = useRef(null);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });

    function handleClickOutside(event) {
      if (iconRef.current && !iconRef.current.contains(event.target)) {
        setShowIcon(false);
        setIndex(-1);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleIconClick = (id) => {
    setSelectedStudents((prevSelectedStudents) => {
      if (prevSelectedStudents.includes(id)) {
        return prevSelectedStudents.filter((studentId) => studentId !== id);
      } else {
        return [...prevSelectedStudents, id];
      }
    });
  };

  return (
    <>
      <div
        className="tablescroll maindashboardtopcards shadowa "
        style={{ marginTop: "0.6rem", overflowX: "auto" }}
      >
        <table
          className="tableofchallenges innermaindashboardcarddiv"
          style={{ width: "100%", overflowX: "scroll", padding: "1rem" }}
        >
          <thead className="thead">
            <tr className="theadtr">
              <th className="theadth">Name</th>
              <th className="theadth">University Name</th>
              <th className="theadth">Course</th>
              <th className="theadth">Location</th>
              <th className="theadth">Level of Engagement</th>

              <th className="theadth">Action</th>
            </tr>
          </thead>

          {currentEntries && currentEntries.length > 0 && (
            <tbody className="tbody" style={{ marginTop: "1.25rem" }}>
              {currentEntries.map((user, index) => (
                <>
                  {user?.userDetails?.user?._id &&
                    (TRUE ||
                      checker.some(
                        (check) => check === user?.userDetails?.user?._id
                      )) && (
                      <tr key={index} className="tbodytr">
                        <td className="tbodytrtd">
                          <div className="d-flex" style={{ gap: "0.75rem" }}>
                            <div
                              className="center"
                              style={{ alignItems: "center" }}
                            >
                              <div
                                className="d-flex "
                                onClick={() => handleIconClick(user.userId)}
                                style={{ cursor: "pointer" }}
                              >
                                {selectedStudents.includes(user.userId) ? (
                                  <SelectedBox />
                                ) : (
                                  <UnselectedBox />
                                )}
                              </div>{" "}
                            </div>

                            <div className="d-flex">
                              {user?.userDetails?.user?.profilePhoto && (
                                <img
                                  src={user.userDetails.user.profilePhoto}
                                  alt="user profile"
                                  height="45px"
                                  className="shadowa"
                                  width="45px"
                                  style={{ borderRadius: "100px" }}
                                />
                              )}
                              <p
                                className="mt-0 mb-0 m-2 black14 d-flex hoverred"
                                style={{
                                  justifyContent: "center",
                                  alignItems: "center",
                                  fontWeight: "500",
                                  maxWidth: "300px",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  navigate(
                                    `/dashboard/user/${user?.userDetails?.user?.uniqueId}`
                                  )
                                }
                              >
                                {user?.userDetails?.user?.firstName}{" "}
                                {user?.userDetails?.user?.LastName}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td>
                          <p
                            className="mt-0 mb-0 m-2 black14 d-flex"
                            style={{
                              // justifyContent: "center",
                              // alignItems: "center",
                              fontWeight: "500",
                              maxWidth: "300px",
                              padding: "12px 12px",
                            }}
                          >
                            {user?.userDetails?.user?.uni?.name}
                          </p>
                        </td>
                        <td>
                          <p
                            className="mt-0 mb-0 m-2 black14 d-flex"
                            style={{
                              // justifyContent: "center",
                              // alignItems: "center",
                              fontWeight: "500",
                              padding: "12px 12px",
                            }}
                          >
                            {user?.userDetails?.user?.levelofstudy} of{" "}
                            {user?.userDetails?.user?.study}
                          </p>
                        </td>
                        <td>
                          <p
                            className="mt-0 mb-0 m-2 black14 d-flex"
                            style={{
                              // justifyContent: "center",
                              // alignItems: "center",
                              padding: "12px 12px",
                              fontWeight: "500",
                            }}
                          >
                            {user?.userDetails?.user?.uni?.area}
                          </p>
                        </td>
                        <td>
                          <div
                            className="d-flex"
                            style={{
                              justifyContent: "center",
                              alignItems: "center",
                              padding: "12px 12px",
                            }}
                          >
                            <p
                              className="mt-0 mb-0 m-2 black14 d-flex"
                              style={{
                                justifyContent: "center",
                                alignItems: "center",
                                fontWeight: "500",
                              }}
                            >
                              {user?.levelofEngagement.toFixed(2)}%
                            </p>
                            <div
                              className=""
                              style={{
                                width: `90%`,
                                height: "4px",
                                borderRadius: "100px",
                                background: `linear-gradient(to right, #8098F9 0%, #C01048 ${user?.levelofEngagement}%, #DBDFF1 ${user?.levelofEngagement}%)`,
                              }}
                            />
                          </div>
                        </td>
                        <td className=" tbodytrtd">
                          <div
                            className="center"
                            // style={{ padding: "12px 0px" }}
                          >
                            <div style={{ position: "absolute" }}>
                              {showIcon && Index === index && (
                                <div
                                  style={{
                                    position: "absolute",
                                    background: "transparent",
                                    zIndex: 10,

                                    marginLeft: "-140px",
                                  }}
                                  ref={iconRef}
                                >
                                  <div
                                    className="d-flex shadowa"
                                    style={{
                                      flexDirection: "column",
                                      background: "transparent",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    <button
                                      className="d-flex popuptext"
                                      style={{
                                        padding: "11px 1rem",
                                        maxHeight: "2rem",
                                        borderRadius: "5px 5px 0px 0px",
                                        gap: "10px",
                                        background: "#FFF",
                                        border: "none",
                                        width: "160px",
                                        minHeight: "42px",
                                      }}
                                      onClick={() => {
                                        setShowIcon(false);
                                        navigate(
                                          `/dashboard/chat?id=${user?.userDetails?.user?.uniqueId}`
                                        );

                                        setIndex(-1);
                                      }}
                                    >
                                      Message
                                    </button>
                                    {TRUE && (
                                      <button
                                        className="d-flex popuptext"
                                        style={{
                                          padding: "11px 1rem",
                                          maxHeight: "2rem",
                                          // borderRadius: "0px 0px 5px 5px",
                                          gap: "10px",
                                          background: "#FFF",
                                          border: "none",
                                          width: "160px",
                                          minHeight: "42px",
                                          borderTop: "1px solid #d9d9d9",
                                        }}
                                        onClick={() => {
                                          // setNewTasks((prevTasks) => {
                                          //   return prevTasks.filter(
                                          //     (task, i) => i !== index
                                          //   );
                                          // });

                                          setShowIcon(false);
                                          setIndex(-1);
                                          handleShortList(
                                            user.userDetails.user._id
                                          );
                                        }}
                                      >
                                        Short List
                                      </button>
                                    )}
                                    <button
                                      className="d-flex popuptext"
                                      style={{
                                        padding: "11px 1rem",
                                        maxHeight: "2rem",
                                        borderRadius: "0px 0px 5px 5px",
                                        gap: "10px",
                                        background: "#FFF",
                                        border: "none",
                                        width: "160px",
                                        minHeight: "42px",
                                        borderTop: "1px solid #d9d9d9",
                                      }}
                                      onClick={() => {
                                        setShowIcon(false);
                                        setIndex(-1);
                                        handleRemove(user.userDetails.user._id);
                                      }}
                                    >
                                      Remove
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>
                            <MoreVertIcon
                              className="hoverred gray700"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setIndex(index);
                                setShowIcon(true);
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    )}
                </>
              ))}
              {currentEntries.length > 0 && currentEntries.length < 5 && (
                <>
                  {Array.from({ length: 5 - currentEntries.length }).map(
                    (_, index) => (
                      <tr
                        key={index}
                        className="tbodytr"
                        style={{ border: "none" }}
                      >
                        <td className="tbodytrtd"></td>
                        <td className="tbodytrtd"></td>
                        <td className="tbodytrtd"></td>
                        <td className="tbodytrtd"></td>
                        <td className="tbodytrtd"></td>
                        <td className="tbodytrtd"></td>
                      </tr>
                    )
                  )}
                </>
              )}
            </tbody>
          )}
        </table>
        {!currentEntries ||
          (currentEntries.length === 0 && (
            <div
              className="center col-md-12 col-12"
              style={{
                alignItems: "center",
                flexDirection: "column",
                width: "100%",
                height: "400px",
              }}
            >
              <img
                src={EmptySpace}
                alt="Emptyspace"
                height="225px"
                width="180px"
                className="img-fluid"
              />
              <p className="pink16">No Data yet</p>
              <p className="black14" style={{ fontWeight: "500" }}>
                <span className="black14">Please come back later.</span> The
                first students will engage with your company in no time!
              </p>
            </div>
          ))}
      </div>
      <div
        className="d-flex mb-5"
        style={{ marginTop: "2.5rem", justifyContent: "end" }}
      >
        <div
          className="d-flex flex-wrap"
          style={{
            justifyContent: "center",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          {renderPageButtons()}
        </div>
      </div>
    </>
  );
};

export default Table;
