// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact {
  max-height: 45px;
  font-size: 1rem;
}

/* Add media queries for different screen sizes */
@media screen and (max-width: 768px) {
  .contact {
    font-size: 0.9rem;
  }
}
@media screen and (max-width: 1052px) {
  .contact {
    max-height: 60px;
  }
}
@media screen and (max-width: 576px) {
  .contact {
    font-size: 0.8rem;
  }
}
.blueshadow {
  box-shadow: 0px 2.242px 1.794px 0px rgba(0, 49, 122, 0.02), 0px 5.389px 4.311px 0px rgba(0, 49, 122, 0.03), 0px 10.147px 8.118px 0px rgba(0, 49, 122, 0.04), 0px 18.101px 14.48px 0px rgba(0, 49, 122, 0.04), 0px 33.855px 27.084px 0px rgba(0, 49, 122, 0.05), 0px 81.036px 64.829px 0px rgba(0, 49, 122, 0.07);
}

.supportbutton {
  display: flex;
  height: 40px;
  padding: 10.236px 16.378px;
  justify-content: center;
  align-items: center;
  gap: 8.189px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 2.242px 1.794px 0px rgba(0, 49, 122, 0.02), 0px 5.389px 4.311px 0px rgba(0, 49, 122, 0.03), 0px 10.147px 8.118px 0px rgba(0, 49, 122, 0.04), 0px 18.101px 14.48px 0px rgba(0, 49, 122, 0.04), 0px 33.855px 27.084px 0px rgba(0, 49, 122, 0.05), 0px 81.036px 64.829px 0px rgba(0, 49, 122, 0.07);
  color: var(--Gray-900, #101828);
  font-family: "Poppins";
  font-size: 16.378px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 26.205px */
}`, "",{"version":3,"sources":["webpack://./src/layouts/components/Navbar/navbar.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,eAAA;AACF;;AAEA,iDAAA;AACA;EACE;IACE,iBAAA;EACF;AACF;AACA;EACE;IACE,gBAAA;EACF;AACF;AAEA;EACE;IACE,iBAAA;EAAF;AACF;AAEA;EACE,gTAAA;AAAF;;AAOA;EACE,aAAA;EAEA,YAAA;EACA,0BAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,kBAAA;EACA,oCAAA;EACA,gTAAA;EAMA,+BAAA;EACA,sBAAA;EACA,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,aAAA;AAVF","sourcesContent":[".contact {\n  max-height: 45px;\n  font-size: 1rem;\n}\n\n/* Add media queries for different screen sizes */\n@media screen and (max-width: 768px) {\n  .contact {\n    font-size: 0.9rem;\n  }\n}\n@media screen and (max-width: 1052px) {\n  .contact {\n    max-height: 60px;\n  }\n}\n\n@media screen and (max-width: 576px) {\n  .contact {\n    font-size: 0.8rem;\n  }\n}\n.blueshadow {\n  box-shadow: 0px 2.242px 1.794px 0px rgba(0, 49, 122, 0.02),\n    0px 5.389px 4.311px 0px rgba(0, 49, 122, 0.03),\n    0px 10.147px 8.118px 0px rgba(0, 49, 122, 0.04),\n    0px 18.101px 14.48px 0px rgba(0, 49, 122, 0.04),\n    0px 33.855px 27.084px 0px rgba(0, 49, 122, 0.05),\n    0px 81.036px 64.829px 0px rgba(0, 49, 122, 0.07);\n}\n.supportbutton {\n  display: flex;\n\n  height: 40px;\n  padding: 10.236px 16.378px;\n  justify-content: center;\n  align-items: center;\n  gap: 8.189px;\n  border-radius: 5px;\n  background: rgba(255, 255, 255, 0.9);\n  box-shadow: 0px 2.242px 1.794px 0px rgba(0, 49, 122, 0.02),\n    0px 5.389px 4.311px 0px rgba(0, 49, 122, 0.03),\n    0px 10.147px 8.118px 0px rgba(0, 49, 122, 0.04),\n    0px 18.101px 14.48px 0px rgba(0, 49, 122, 0.04),\n    0px 33.855px 27.084px 0px rgba(0, 49, 122, 0.05),\n    0px 81.036px 64.829px 0px rgba(0, 49, 122, 0.07);\n  color: var(--Gray-900, #101828);\n  font-family: \"Poppins\";\n  font-size: 16.378px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: 160%; /* 26.205px */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
