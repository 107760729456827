import React, { useEffect, useRef, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import on from "../../assets/images/on.svg";
import off from "../../assets/images/off.svg";
import trash from "../../assets/images/trash.svg";
import AddIcon from "@mui/icons-material/Add";
import { useMediaQuery } from "@mui/material";
import upload from "../../assets/images/icons/upload.png";
import axios from "axios";
const TaskStep4 = ({
  taskCount,
  newTasks,
  setNewTasks,
  setTaskSteps,
  toast,
}) => {
  const [expandedQuestions, setExpandedQuestions] = useState(
    Array.from({ length: newTasks[taskCount].questions.length }, () => true)
  );

  const fileInputRef = React.createRef();

  const fileInputRefAdditional = React.createRef();

  const handleFileChange = (event, index) => {
    const newFiles = Array.from(event.target.files);
    const updatedTasks = [...newTasks];
    const existingFiles = updatedTasks[taskCount].questions[index].files || [];

    // Merge existing files with new files and enforce a limit of 5
    const mergedFiles = [...existingFiles, ...newFiles].slice(0, 1);
    if ([...existingFiles, ...newFiles].length > 1) {
      toast.error("Only one file upload is allowed");
    }
    updatedTasks[taskCount].questions[index].files = mergedFiles;
    // Update state with new tasks array
    setNewTasks(updatedTasks);
  };

  const openFileInput = () => {
    fileInputRef.current.click();
  };

  const types = ["Multiple Choice Question", "Text field", "File Upload"];

  const isNonMobile768 = useMediaQuery("(min-width:768px)");

  const handleTitleChange = (index, title) => {
    const updatedTasks = [...newTasks];
    updatedTasks[taskCount].questions[index].question = title;
    setNewTasks(updatedTasks);
  };

  const handleExplainationChange = (index, explaination) => {
    const updatedTasks = [...newTasks];
    updatedTasks[taskCount].questions[index].explaination = explaination;
    setNewTasks(updatedTasks);
  };
  console.log(newTasks);

  const getFileIcon = (fileName) => {
    if (fileName && fileName.split(".")) {
      const fileExtension = fileName.split(".").pop().toLowerCase();
      const fileIcons = {
        pdf: "#155EEF",
        docx: "#155EEF",
        txt: "#344054",
        xlsl: "#099250",
        ppt: "#E62E05",
        csv: "#099250",
      };

      const color = fileIcons[fileExtension] || "#E62E05";

      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="33"
          height="40"
          viewBox="0 0 33 40"
          fill="none"
        >
          <path
            d="M0 4C0 1.79086 1.79086 0 4 0H20L32 12V36C32 38.2091 30.2091 40 28 40H4C1.79086 40 0 38.2091 0 36V4Z"
            fill={color}
          />
          <path
            opacity="0.3"
            d="M20 0L32 12H24C21.7909 12 20 10.2091 20 8V0Z"
            fill="white"
          />
          <text
            x="50%"
            y="70%"
            dominantBaseline="middle"
            textAnchor="middle"
            fill="#fff"
            fontSize="8"
          >
            {fileExtension.toUpperCase()}
          </text>
        </svg>
      );
    }
  };

  const handleDescriptionChange = (index, description) => {
    const updatedTasks = [...newTasks];
    updatedTasks[taskCount].questions[index].description = description;
    setNewTasks(updatedTasks);
  };

  const handleisMultiSelectChange = (index) => {
    const updatedTasks = [...newTasks];
    updatedTasks[taskCount].questions[index].isMultiSelect =
      !updatedTasks[taskCount].questions[index].isMultiSelect;
    setNewTasks(updatedTasks);
  };

  const handleOptionChange = (questionIndex, optionIndex, value) => {
    const updatedTasks = [...newTasks];
    updatedTasks[taskCount].questions[questionIndex].options[
      optionIndex
    ].value = value;
    setNewTasks(updatedTasks);
  };

  const handleOptionCorrectChange = (questionIndex, optionIndex, value) => {
    const updatedTasks = [...newTasks];
    updatedTasks[taskCount].questions[questionIndex].options[
      optionIndex
    ].isCorrect = value;
    setNewTasks(updatedTasks);
  };

  const handleAnswerChange = (questionIndex, event) => {
    const updatedTasks = [...newTasks];
    updatedTasks[taskCount].questions[questionIndex].answer =
      event.target.value;
    setNewTasks(updatedTasks);
  };

  // Handle removing a document
  const handleRemoveDocument = (questionIndex, fileToRemove) => {
    const updatedTasks = [...newTasks];
    const questionFiles =
      updatedTasks[taskCount].questions[questionIndex].files;
    updatedTasks[taskCount].questions[questionIndex].files = Array.from(
      questionFiles
    ).filter((file) => file !== fileToRemove);
    // Update state with new tasks array
    setNewTasks(updatedTasks);
  };

  const handleAddOptions = (index) => {
    setNewTasks((prevTasks) => {
      const updatedTasks = [...prevTasks];
      const options = [...updatedTasks[taskCount].questions[index].options];
      options.push({
        value: "",
        isCorrect: false,
      });
      updatedTasks[taskCount].questions[index].options = options;
      return updatedTasks;
    });
  };

  const handleAddNextQuestion = () => {
    setNewTasks((prevTasks) => {
      const updatedTasks = [...prevTasks];
      updatedTasks[taskCount].questions.push({
        question: "",
        answer: "",
        type: "Multiple Choice Question",
        explaination: "",
        files: [],
        description: "",
        options: [
          { value: "", isCorrect: false },
          { value: "", isCorrect: false },
        ],
        isMultiSelect: false,
      });
      return updatedTasks;
    });
    setExpandedQuestions((prevExpanded) => [...prevExpanded, true]);
  };

  const handleRemoveQuestion = (indexToRemove) => {
    setNewTasks((prevTasks) => {
      const updatedTasks = [...prevTasks];
      updatedTasks[taskCount].questions.splice(indexToRemove, 1);
      return updatedTasks;
    });
    setExpandedQuestions((prevExpanded) =>
      prevExpanded.filter((_, index) => index !== indexToRemove)
    );
  };

  const toggleQuestionExpansion = (index) => {
    setExpandedQuestions((prevExpanded) => {
      const updatedExpanded = [...prevExpanded];
      updatedExpanded[index] = !updatedExpanded[index];
      return updatedExpanded;
    });
  };

  const handleRemoveOption = (index, optionIndex) => {
    setNewTasks((prevTasks) => {
      const updatedTasks = [...prevTasks];
      updatedTasks[taskCount].questions[index].options.splice(optionIndex, 1);
      return updatedTasks;
    });
  };

  const Check = () => {
    if (newTasks[taskCount].questions.length < 2) {
      toast.error("Please add at least 2 questions");
      return;
    } else if (
      newTasks[taskCount].questions.some((question) => !question.question)
    ) {
      toast.error("Please provide all questions title");
      return;
    } else if (
      newTasks[taskCount].questions.some(
        (question, index) =>
          question.options.length < 2 && !question.isMultiSelect
      )
    ) {
      const questionIndex = newTasks[taskCount].questions.findIndex(
        (question) => question.options.length < 2 && !question.isMultiSelect
      );
      toast.error(
        `Please add at least 2 options in question ${questionIndex + 1}`
      );
      return;
    } else if (
      newTasks[taskCount].questions.some(
        (question, index) =>
          question.options.length < 3 && question.isMultiSelect
      )
    ) {
      const questionIndex = newTasks[taskCount].questions.findIndex(
        (question) => question.options.length < 3 && question.isMultiSelect
      );
      toast.error(
        `Please add at least 3 options in question ${questionIndex + 1}`
      );
      return;
    } else if (
      newTasks[taskCount].questions.some(
        (question, qIndex) =>
          question.type === "Multiple Choice Question" &&
          question.options.some((option, oIndex) => !option.value)
      )
    ) {
      // Find the index of the first question with an empty option
      const questionIndex = newTasks[taskCount].questions.findIndex(
        (question) => {
          if (question.type === "Multiple Choice Question") {
            return question.options.some((option) => !option.value);
          }
          return false;
        }
      );

      // Find the index of the first option within that question that has no value
      const optionIndex = newTasks[taskCount].questions[
        questionIndex
      ].options.findIndex((option, oIndex) => !option.value);

      toast.error(
        `Please fill in the value for option ${optionIndex + 1} of question ${
          questionIndex + 1
        }`
      );
      return;
    } else if (
      newTasks[taskCount].questions.some((question) => {
        if (question.type === "Multiple Choice Question") {
          if (question.isMultiSelect) {
            // For multi-select questions, at least 2 options should be correct
            return (
              question.options.filter((option) => option.isCorrect).length < 2
            );
          } else {
            // For single-select questions, at least 1 option should be correct
            return !question.options.some((option) => option.isCorrect);
          }
        }
      })
    ) {
      toast.error(
        "Each question must have 1 correct option. MultiSelect Questions must have at least 2 correct options"
      );
      return;
    } else if (
      newTasks[taskCount].questions.some((question) => {
        if (
          !question.isMultiSelect &&
          question.type === "Multiple Choice Question"
        ) {
          // Check if there are more than one correct option for non-multiselect questions
          const correctOptionsCount = question.options.filter(
            (option) => option.isCorrect
          ).length;

          return correctOptionsCount !== 1;
        }
      })
    ) {
      toast.error("Non-multiselect questions can only have 1 correct option");
      return;
    } else if (
      newTasks[taskCount].questions.some(
        (question, index) =>
          question.type === "Text field" && !question.explaination
      )
    ) {
      newTasks[taskCount].questions.some((question, index) => {
        if (question.type === "Text field" && !question.explaination) {
          toast.error(
            `Please Provide Explaination for the answer of question ${
              index + 1
            }`
          );
        }
      });
    } else if (
      newTasks[taskCount].questions.some(
        (question, index) =>
          question.type === "File Upload" && question.files.length < 1
      )
    ) {
      newTasks[taskCount].questions.some((question, index) => {
        if (question.type === "File Upload" && question.files.length < 1) {
          toast.error(
            `Please Upload files which student will work on for question ${
              index + 1
            }`
          );
        }
      });
    } else {
      setTaskSteps(4);
    }
  };

  const handleTypeChange = (index, tp) => {
    setNewTasks((prevTasks) => {
      const updatedTasks = [...prevTasks];

      updatedTasks[taskCount].questions[index].type = tp;
      return updatedTasks;
    });
  };

  const [disable, setDisabled] = useState(-1);
  const [dropdown, setDropDown] = useState(-1);

  const handlegenerateQuestion = async (index, type, dif) => {
    setDisabled(index);
    await axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/api/generate-questions`, {
        challenge: newTasks[taskCount],
        difficulty: dif,
      })
      .then((res) => {
        console.log(res.data);
        if (res?.data?.msg === "success") {
          setNewTasks((prevTasks) => {
            const updatedTasks = [...prevTasks];

            if (updatedTasks[taskCount] && updatedTasks[taskCount].questions) {
              const updatedQuestions = [...updatedTasks[taskCount].questions];

              if (updatedQuestions[index]) {
                const currentQuestion = updatedQuestions[index];
                updatedQuestions[index] = {
                  ...JSON.parse(res.data.questions),
                  type: currentQuestion.type,
                };
              }

              updatedTasks[taskCount].questions = updatedQuestions;
            }

            return updatedTasks;
          });
        } else {
          toast.error("Error generating Question ,Please try again");
        }

        setDisabled(-1);
      })
      .catch((err) => {
        console.log(err.message);
        toast.error("Error generating Question ,Please try again");

        setDisabled(-1);
      });
  };

  const iconRef = useRef(null);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });

    function handleClickOutside(event) {
      if (iconRef.current && !iconRef.current.contains(event.target)) {
        setDropDown(-1);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const difficulties = ["Easy", "Intermediate", "Hard"];

  return (
    <div className="mb-5 pb-5" style={{ width: "100%" }}>
      <div className="">
        <p className="BasicInforMationHeading ">Questions</p>
        <p className="UnderBasicHeading" style={{ marginTop: "0.25rem" }}>
          Add multiple-choice questions. Minimum 2 questions.
        </p>
        {newTasks[taskCount].questions.map((question, index) => (
          <>
            <hr style={{ marginTop: "2.5rem", marginBottom: "1.25rem" }} />
            <div className="space">
              <div
                className="d-flex flex-wrap align-items-center"
                style={{ gap: "0.75rem" }}
              >
                <p className="black16">Question {index + 1} </p>
                <span className="vslastmonthtext">
                  ({index + 1}/{newTasks[taskCount].questions.length})
                </span>
              </div>

              <div
                onClick={() => toggleQuestionExpansion(index)}
                className="d-flex"
                style={{ cursor: "pointer" }}
              >
                {expandedQuestions[index] ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </div>
            </div>
            <div
              className="space flex-wrap"
              style={{ marginTop: "1.5rem", gap: "1.5rem" }}
            >
              <div style={{ maxWidth: "375px" }}>
                <p className="thumbnailcreate">Add Examination Type</p>
                <p
                  className="underthumbnailcreate"
                  style={{ marginTop: "0.25rem" }}
                >
                  Should students respond by multiple choice or work on a text
                  and fill in their answers in text form? Or should they work on
                  a file (e.g. Excel Spread Sheet) and upload their version?
                </p>
              </div>

              <div className="d-flex flex-column" style={{ gap: "1rem" }}>
                {types.map((tp, i) => (
                  <div
                    className="d-flex"
                    style={{ gap: "1rem", cursor: "pointer" }}
                    key={i}
                    onClick={() => {
                      handleTypeChange(index, tp);
                    }}
                  >
                    <img
                      src={question.type === tp ? on : off}
                      alt="switch"
                      height={"20px"}
                      width={"36px"}
                    />
                    <p className="underthumbnailcreate">{tp}</p>
                  </div>
                ))}
              </div>
            </div>

            <div
              key={index}
              style={{
                background: "#FFF",
                padding: "1.5rem",
                marginTop: "2.5rem",
                borderRadius: "5px",
              }}
            >
              <div style={{ marginTop: "1.25rem" }}>
                <div className="d-flex justify-content-end ">
                  <div>
                    <button
                      className=" generateAiButton"
                      disabled={index === disable}
                      onClick={() => {
                        setDropDown(index);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="18"
                        viewBox="0 0 19 18"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M4.40066 8.52124L5.86754 4.12058H7.76491L9.2318 8.52124L13.6325 9.98812V11.8855L9.2318 13.3524L7.76491 17.753H5.86754L4.40066 13.3524L0 11.8855V9.98812L4.40066 8.52124ZM6.81623 7.59908L6.13991 9.62803L5.50746 10.2605L3.47851 10.9368L5.50746 11.6131L6.13991 12.2456L6.81623 14.2745L7.49255 12.2456L8.125 11.6131L10.154 10.9368L8.125 10.2605L7.49255 9.62803L6.81623 7.59908Z"
                          fill="url(#paint0_linear_11217_29024)"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M13.4669 2.58747L14.247 0.24707H15.3854L16.1656 2.58747L18.506 3.3676V4.50602L16.1656 5.28615L15.3854 7.62654H14.247L13.4669 5.28615L11.1265 4.50602V3.3676L13.4669 2.58747Z"
                          fill="url(#paint1_linear_11217_29024)"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_11217_29024"
                            x1="4"
                            y1="9"
                            x2="9.25298"
                            y2="17.753"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop offset="0.0001" stopColor="#444CE7" />
                            <stop offset="1" stopColor="#C01048" />
                          </linearGradient>
                          <linearGradient
                            id="paint1_linear_11217_29024"
                            x1="4"
                            y1="9"
                            x2="9.25298"
                            y2="17.753"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop offset="0.0001" stopColor="#444CE7" />
                            <stop offset="1" stopColor="#C01048" />
                          </linearGradient>
                        </defs>
                      </svg>
                      Generate Question <ExpandMoreIcon />
                    </button>
                    {dropdown === index && (
                      <div style={{ position: "relative" }}>
                        <div
                          className="shadowa"
                          ref={iconRef}
                          style={{
                            position: "absolute",
                            background: "#FFF",
                            width: "100%",
                            borderRadius: "5px",
                          }}
                        >
                          {difficulties.map((dif, i) => (
                            <button
                              key={i}
                              className="d-flex popuptext"
                              style={{
                                padding: "11px 1rem",
                                maxHeight: "2rem",

                                gap: "10px",
                                background: "#FFF",
                                border: "none",
                                width: "160px",
                                minHeight: "42px",
                              }}
                              onClick={() => {
                                handlegenerateQuestion(
                                  index,
                                  question.type,
                                  dif
                                );
                                setDropDown(-1);
                              }}
                            >
                              {dif}
                            </button>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <p className="thumbnailcreate">Title</p>
                <input
                  type="text"
                  value={question.question}
                  className="forminput"
                  onChange={(e) => handleTitleChange(index, e.target.value)}
                  placeholder="Enter question title"
                  style={{ marginTop: "0.75rem", width: "100%" }}
                />
                {expandedQuestions[index] && (
                  <>
                    <p
                      className="thumbnailcreate"
                      style={{ marginTop: "1rem" }}
                    >
                      Description or Context or Data (optional field)
                    </p>
                    <textarea
                      value={question.description}
                      onChange={(e) =>
                        handleDescriptionChange(index, e.target.value)
                      }
                      className="forminput "
                      style={{ marginTop: "0.75rem", width: "100%" }}
                      rows="4"
                    />
                    {question.type === "File Upload" && (
                      <>
                        <p
                          className="thumbnailcreate"
                          style={{ marginTop: "1rem" }}
                        >
                          Upload file which student will edit
                        </p>
                        <div
                          className=" border rounded-3"
                          style={{
                            backgroundColor: "#FFF",
                            justifyContent: "center",
                            marginTop: "0.75rem",
                            padding: "1rem",
                          }}
                        >
                          <div
                            className="d-flex"
                            style={{ justifyContent: "center" }}
                          >
                            {question.files ? (
                              <div>
                                <div className="center">
                                  <img
                                    src={upload}
                                    alt="upload"
                                    width="40px"
                                    height="40px"
                                  />
                                </div>
                                <p
                                  style={{
                                    color: "#E31B54",
                                    fontFamily: "Poppins",
                                    fontWeight: "600",
                                    fontSize: "14px",
                                    cursor: "pointer",
                                  }}
                                >
                                  {question.files.length}{" "}
                                  {question.files.length > 1
                                    ? "Files Selected"
                                    : "File Selected"}
                                </p>
                              </div>
                            ) : (
                              <div
                                className="mt-2 p-3 d-flex"
                                style={{
                                  backgroundColor: "#FFF5F6",
                                  borderRadius: "60px",
                                  height: "60px",
                                  width: "60px",
                                  justifyContent: "center",
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="24"
                                  viewBox="0 0 20 24"
                                  fill="none"
                                >
                                  <path
                                    d="M0.399902 4C0.399902 1.79086 2.19076 0 4.3999 0H12.3999L19.5999 7.2V20C19.5999 22.2091 17.809 24 15.5999 24H4.3999C2.19076 24 0.399902 22.2091 0.399902 20V4Z"
                                    fill="#E31B54"
                                  />
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="10"
                                    height="11"
                                    viewBox="0 0 10 11"
                                    fill="none"
                                    x="5"
                                    y="6"
                                  >
                                    <g clipPath="url(#clip0_4343_10944)">
                                      <path
                                        d="M5.4 3.3998L4.95378 2.50737C4.82536 2.25053 4.76115 2.12210 4.66535 2.02827C4.58063 1.94530 4.47853 1.88219 4.36643 1.84353C4.23967 1.7998 4.09608 1.7998 3.80892 1.7998H2.28C1.83196 1.7998 1.60794 1.7998 1.43681 1.887C1.28628 1.9637 1.16389 2.08608 1.08719 2.23661C1 2.40774 1 2.63176 1 3.0798V3.3998M1 3.3998H7.08C7.75206 3.3998 8.08809 3.3998 8.34479 3.5306C8.57058 3.64565 8.75416 3.82922 8.86921 4.05502C9 4.31171 9 4.64774 9 5.31981V7.07981C9 7.75187 9 8.0879 8.86921 8.34459C8.75416 8.57039 8.57058 8.75397 8.34479 8.86901C8.08809 8.99981 7.75206 8.99981 7.08 8.99981H2.92C2.24794 8.99981 1.91191 8.99981 1.65521 8.86901C1.42942 8.75397 1.24584 8.57039 1.13079 8.34459C1 8.0879 1 7.75187 1 7.07981V3.3998Z"
                                        stroke="white"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_4343_10944">
                                        <rect
                                          width="9.6"
                                          height="9.6"
                                          fill="white"
                                          transform="translate(0.200195 0.599609)"
                                        />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </svg>
                              </div>
                            )}
                          </div>
                          {!question.files && (
                            <div
                              className="d-flex mt-2 "
                              style={{ justifyContent: "center" }}
                            >
                              <p className="addthumbnail">
                                Add Reletive Documents
                              </p>
                            </div>
                          )}
                          <div
                            className="d-flex mt-3 mb-3"
                            style={{ justifyContent: "center" }}
                          >
                            {" "}
                            <p
                              className="d-flex  pink16"
                              style={{ gap: "10px", cursor: "pointer" }}
                              onClick={openFileInput}
                            >
                              click to upload
                            </p>
                            <input
                              type="file"
                              onChange={(e) => handleFileChange(e, index)}
                              style={{ display: "none" }}
                              ref={fileInputRef}
                              multiple
                            />
                          </div>
                        </div>
                        <div
                          className="d-flex flex-wrap"
                          style={{ gap: "1.5rem 1rem", marginTop: "0.5rem" }}
                        >
                          {question.files && question.files.length > 0 && (
                            <>
                              {Array.from(question.files).map((file, index) => (
                                <div
                                  key={index}
                                  className="d-flex rounded-3 "
                                  style={{
                                    background: "#FFF",
                                    padding: "6px 12px",
                                  }}
                                >
                                  <div className="">
                                    {file?.originalname
                                      ? getFileIcon(file.originalname)
                                      : getFileIcon(file.name)}
                                  </div>{" "}
                                  <p
                                    style={{
                                      color: "#E31B54",
                                      fontFamily: "Poppins",
                                      fontWeight: "600",
                                      fontSize: "14px",
                                      cursor: "pointer",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                    className="  m-2 d-flex"
                                  >
                                    {file?.originalname
                                      ? file.originalname.length > 20
                                        ? file.originalname.slice(0, 20) + "..."
                                        : file.originalname
                                      : file.name.length > 20
                                      ? file.name.slice(0, 20) + "..."
                                      : file.name}
                                  </p>
                                  <div
                                    className=" d-flex"
                                    style={{
                                      cursor: "pointer",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                    onClick={() => {
                                      handleRemoveDocument(index, file);
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="17"
                                      height="17"
                                      viewBox="0 0 17 17"
                                      fill="none"
                                    >
                                      <path
                                        d="M9.30108 8.28369L14.3648 3.23713C14.5763 3.02359 14.5763 2.67765 14.3648 2.46411C14.157 2.24675 13.8138 2.24022 13.598 2.44952L8.53429 7.49607L3.5357 2.44952C3.43322 2.34024 3.29063 2.27832 3.14145 2.27832C2.99228 2.27832 2.84969 2.34024 2.74721 2.44952C2.55964 2.65584 2.55964 2.97248 2.74721 3.17879L7.7458 8.21805L2.6821 13.2573C2.47055 13.4709 2.47055 13.8168 2.6821 14.0303C2.78282 14.1343 2.92133 14.1923 3.0655 14.1908C3.21244 14.2028 3.35811 14.1556 3.47059 14.0595L8.53429 9.01296L13.598 14.1179C13.6987 14.2219 13.8372 14.2798 13.9814 14.2783C14.1254 14.279 14.2636 14.2211 14.3648 14.1179C14.5763 13.9043 14.5763 13.5584 14.3648 13.3448L9.30108 8.28369Z"
                                        fill="black"
                                      />
                                    </svg>
                                  </div>
                                </div>
                              ))}
                            </>
                          )}
                        </div>
                      </>
                    )}

                    {question.type === "Text field" && (
                      <>
                        <p
                          className="thumbnailcreate"
                          style={{ marginTop: "1rem" }}
                        >
                          Please give us a brief explanation for the right
                          answer.
                        </p>
                        <textarea
                          value={question.explaination}
                          onChange={(e) =>
                            handleExplainationChange(index, e.target.value)
                          }
                          className="forminput "
                          style={{ marginTop: "0.75rem", width: "100%" }}
                          rows="4"
                        />
                      </>
                    )}
                    {question.type === "Multiple Choice Question" && (
                      <>
                        <div className="space" style={{ marginTop: "2.5rem" }}>
                          <p className="thumbnailcreate">Answers</p>
                          <div
                            className="d-flex flex-wrap"
                            style={{
                              gap: "0.5rem",
                              cursor: "pointer",
                              alignItems: "center",
                            }}
                            onClick={() => handleisMultiSelectChange(index)}
                          >
                            <p className="namecountry">
                              Multiple right answers?
                            </p>
                            {question.isMultiSelect ? (
                              <img
                                src={on}
                                alt="on"
                                width="30px"
                                height="16px"
                              />
                            ) : (
                              <img
                                src={off}
                                alt="on"
                                width="30px"
                                height="16px"
                              />
                            )}
                          </div>
                        </div>
                        <div
                          className="d-flex"
                          style={{
                            marginTop: "1.5rem",
                            flexDirection: "column",
                            gap: "1rem",
                          }}
                        >
                          {question.options.map((option, optionIndex) => (
                            <div
                              key={optionIndex}
                              style={{
                                display: "flex",
                                flexDirection: isNonMobile768
                                  ? "row"
                                  : "column",

                                gap: "0.5rem",
                              }}
                            >
                              <textarea
                                type="text"
                                value={option.value}
                                onChange={(e) =>
                                  handleOptionChange(
                                    index,
                                    optionIndex,
                                    e.target.value
                                  )
                                }
                                className="forminput"
                                style={{ width: "100%" }}
                                rows="1"
                              />
                              <div
                                className="space "
                                style={{
                                  gap: "0.5rem",
                                  height: "2.5rem",
                                  maxHeight: "2.5rem",
                                  alignItems: "center",
                                }}
                              >
                                <select
                                  value={option.isCorrect ? "true" : "false"}
                                  className="forminput"
                                  onChange={(e) =>
                                    handleOptionCorrectChange(
                                      index,
                                      optionIndex,
                                      e.target.value === "true" ? true : false
                                    )
                                  }
                                  style={{
                                    maxHeight: "2.5rem",
                                    background: option.isCorrect
                                      ? ""
                                      : "#FFF5F6",
                                    padding: "8px 12px",
                                  }}
                                >
                                  <option value="true">Correct</option>
                                  <option value="false">Incorrect</option>
                                </select>
                                <div
                                  style={{
                                    marginLeft: "1rem",
                                    marginRight: "1rem",
                                    height: "2.5rem",
                                    maxHeight: "2.5rem",
                                    width: "1px",
                                    background: "#D0D5DD",
                                  }}
                                ></div>

                                <img
                                  src={trash}
                                  alt="trash"
                                  width="20px"
                                  height="20px"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    handleRemoveOption(index, optionIndex)
                                  }
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                        <button
                          className="backbutton"
                          style={{
                            marginTop: "2.5rem",
                            gap: "0.5rem",
                            padding: "10px 14px",
                          }}
                          onClick={() => {
                            handleAddOptions(index);
                          }}
                        >
                          <AddIcon />
                          Add answer
                        </button>
                      </>
                    )}
                    <hr
                      style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}
                    />
                    <button
                      className="backbutton"
                      onClick={() => handleRemoveQuestion(index)}
                    >
                      Remove Question
                    </button>
                  </>
                )}
              </div>
            </div>
          </>
        ))}
        <div style={{ marginTop: "1rem" }}>
          <button className="continuebutton" onClick={handleAddNextQuestion}>
            <AddIcon color="#475467" />
            Add Question
          </button>
        </div>
        <div>
          <hr style={{ marginTop: "2.5rem", marginBottom: "1.25rem" }} />
          <div
            className="d-flex flex-wrap"
            style={{ justifyContent: "end", gap: "1.25rem" }}
          >
            <button className="backbutton" onClick={() => setTaskSteps(2)}>
              Back
            </button>
            <button className="continuebutton" onClick={() => Check()}>
              Continue
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M6.66669 17.2917C6.83257 17.2925 6.99178 17.2264 7.10835 17.1083L13.775 10.4417C14.0187 10.1977 14.0187 9.80235 13.775 9.55833L7.10835 2.89167C6.8621 2.6622 6.47835 2.66897 6.24034 2.90698C6.00233 3.14499 5.99555 3.52874 6.22502 3.775L12.45 10L6.22502 16.225C5.98131 16.469 5.98131 16.8643 6.22502 17.1083C6.34159 17.2264 6.5008 17.2925 6.66669 17.2917Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskStep4;
