import Footer from "../../components/Company/Footer/footer";
import Body from "../../components/Company/create/Body";
import Header from "../../components/Role/component/Header";

import "./createcompany.css";
const CreateCompany = () => {
  return (
    <div>
      <div className="createcompany " style={{ height: "100%" }}>
        <div className="marginsleftrightCompany">
          <div className="d-flex" style={{ justifyContent: "center" }}>
            <Header />
          </div>
          <div className="d-flex" style={{ justifyContent: "center" }}>
            <div className="headerCompany mt-5 pt-5">
              <Body />
            </div>
          </div>
        </div>
        <div className="marginsleftrightCompany ">
          <div className="d-flex" style={{ justifyContent: "center" }}>
            <div className="headerCompany mt-5 pb-5">{/* <Footer /> */}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CreateCompany;
