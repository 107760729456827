import "./student.css";
import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useState, useRef, useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import eye from "../../assets/images/Eye.svg";
import ChallengeCard from "../../components/Company/Dashboard/ChallengeCard";
import uservip from "../../assets/images/Uservip.svg";
import square from "../../assets/images/square.svg";
import Experience from "./Experience";
import Education from "./Education";
import Certificates from "./Certificates";
import Awards from "./Awards";
import Volunteering from "./Volunteering";
import Publications from "./Publications";
import Test from "./Test";
import { toast } from "react-toastify";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setLogin } from "../../state/index";
import CloseIcon from "@mui/icons-material/Close";
import AddExperience from "./addExperience";
import EducationPopup from "./EducationPopup";
import DeleteIcon from "@mui/icons-material/Delete";
import CertificatesPopup from "./CertificatePopup";
import AwardsPopup from "./AwardsPopup";
import CoverPhoto from "./backgroundImg";

const SecondSection = ({
  user,
  isNonMobile450,
  isNonMobile1024,
  owner,
  challenges,
  navigate,
  AllChallenges,
  jobposts,
  similarUsers,
  watcher,
  recipient,
  test,
  isNonMobile,
  TRUE,
}) => {
  const [levelofEngagement, setLevel] = useState(null);
  const [completed, setCompleted] = useState(0);
  const isNonMobile1200 = useMediaQuery("(min-width:1200px)");
  const [bio, setBio] = useState(watcher?.bio ?? "");
  const [bioPopup, setBioPopup] = useState(false);
  const [coverPopup, setCoverPopup] = useState(false);
  const [counter, setCounter] = useState(0);
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState(watcher?.coverPhoto ?? null);
  const [about, setAbout] = useState(watcher?.about ?? "");
  const [aboutPopup, setAboutPopup] = useState(false);
  const [experiencePopup, setExperiencePopup] = useState(false);
  const [experience, setExperience] = useState(watcher?.experience ?? []);
  const [index, setIndex] = useState(-1);
  const [education, setEducation] = useState(watcher?.education ?? []);
  const [certificates, setCertificates] = useState(watcher?.certificates ?? []);
  const [educationPopup, setEducationPopup] = useState(false);
  const [certificatesPopup, setCertificatesPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [awards, setAwards] = useState(watcher?.awards ?? []);
  const [awardsPopup, setAwardsPopup] = useState(false);
  const [selected, setSelected] = useState("Details");
  const [newAward, setNewAward] = useState({
    logo: null,
    title: "",
    companyName: "",
    package: "",
    issue: "",
    description: "",
  });
  const [newExperience, setNewExperience] = useState({
    logo: null,
    position: "",
    from: "",
    to: "",
    location: "",
    company: "",
    description: "",
  });
  const [addExperience, setAddExperience] = useState(false);
  const [newEducation, setNewEducation] = useState({
    logo: null,
    degree: "",
    from: "",
    to: "",
    location: "",
    schoolName: "",
    description: "",
  });
  const [newCertificate, setNewCertificate] = useState({
    logo: null,
    name: "",
    issued: "",
    credentialId: "",
    link: "",
  });
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1440 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 1440, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  useEffect(() => {
    const Test = async () => {
      if (AllChallenges) {
        if (AllChallenges.length > 0) {
          let count = 0;
          let complete = 0;

          AllChallenges.forEach((check) => {
            check.clickDates.forEach((click) => {
              if (click?.userId === user?._id) {
                count++;
              }
            });
          });
          AllChallenges.forEach((chec) => {
            chec.countDates.forEach((click) => {
              if (click.userId === user?._id) {
                complete++;
              }
            });
          });
          const countPercentage = Math.min(
            (count / AllChallenges.length) * 20,
            20
          );
          const completePercentage = Math.min(
            (complete / AllChallenges.length) * 60,
            60
          );

          const totalPercentage = countPercentage + completePercentage;

          // Normalize to ensure the total does not exceed 100%
          const levelofEngagement = Math.min(totalPercentage, 100);
          // let levelofEngagement =
          //   (count / AllChallenges.length) * 20 +
          //   (complete / AllChallenges.length) * 60;

          setLevel(levelofEngagement);
        }
        let completed = 0;

        AllChallenges.forEach((chec) => {
          let userIdSet = new Set();
          chec.countDates.forEach((count) => {
            if (count.userId === user?._id && !userIdSet.has(count.userId)) {
              completed++;
              userIdSet.add(count.userId);
            }
          });
        });

        setCompleted(completed);
      }
    };

    Test();
  }, [AllChallenges]);

  const [showMenu, setShowMenu] = useState(false);

  const iconRef = useRef(null);
  const popupref = useRef(null);
  useEffect(() => {
    const AppliedCheck = () => {
      if (jobposts?.length > 0) {
        let counter = 0;
        jobposts.forEach((job) => {
          job.countDates.forEach((count) => {
            if (count.userId === user?._id) {
              counter++;
            }
          });
        });
        setCounter(counter);
      }
    };
    AppliedCheck();
  }, [jobposts]);
  const CompletedDiv = (completed) => {
    // Assuming this is the completion percentage, change it accordingly
    let divider = 10;
    while (completed > divider) {
      divider = divider * 10;
    }

    const completionPercentage = Math.min((completed / divider) * 100, 100);

    return (
      <div
        className=""
        style={{
          width: `100%`,
          height: "4px",
          borderRadius: "100px",
          background: `linear-gradient(to right, #8098F9 0%, #C01048 ${completionPercentage}%, #DBDFF1 ${completionPercentage}%)`,
        }}
      />
    );
  };
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });

    function handleClickOutside(event) {
      if (iconRef.current && !iconRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });

    function handleClickOutside(event) {
      if (popupref.current && !popupref.current.contains(event.target)) {
        setAboutPopup(false);
        setBioPopup(false);
        setCoverPopup(false);

        setExperiencePopup(false);
        setAddExperience(false);
        setNewExperience({
          logo: null,
          position: "",
          from: "",
          to: "",
          location: "",
          company: "",
          description: "",
        });
        setIndex(-1);
        setCertificatesPopup(false);
        setEducationPopup(false);
        setNewEducation({
          logo: null,
          degree: "",
          from: "",
          to: "",
          location: "",
          schoolName: "",
          description: "",
        });
        setNewCertificate({
          logo: null,
          name: "",
          issued: "",
          credentialId: "",
          link: "",
        });
        setAwardsPopup(false);
        setNewAward({
          logo: null,
          title: "",
          companyName: "",
          package: "",
          issue: "",
          description: "",
        });
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const openFileInput = () => {
    fileInputRef.current.click();
  };

  const fileInputRef = React.createRef();

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file && file.type.startsWith("image/")) {
      const img = new Image();
      img.onload = function () {
        setSelectedFile(file);
      };
      img.src = URL.createObjectURL(file);
    } else {
      toast.error("Please select a valid image file.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handleConfirmAward = () => {
    if (!newAward.companyName) {
      toast.error("Please enter Institute name");
    } else if (!newAward.description) {
      toast.error("Please enter description");
    } else if (!newAward.package) {
      toast.error("Please provide information about this award/scholarship");
    } else if (!newAward.issue) {
      toast.error("Please enter issue date");
    } else if (!newAward.title) {
      toast.error("Please enter title");
    } else {
      if (index === -1) {
        awards.push(newAward);
        setAddExperience(false);
        setNewAward({
          logo: null,
          title: "",
          companyName: "",
          package: "",
          issue: "",
          description: "",
        });
      } else {
        setAwards((prevExperience) => {
          const updatedExperience = [...prevExperience]; // Create a shallow copy of the array
          updatedExperience[index] = newAward; // Update the element at the specified index
          return updatedExperience; // Return the updated array
        });
        setNewAward({
          logo: null,
          title: "",
          companyName: "",
          package: "",
          issue: "",
          description: "",
        });

        setAddExperience(false);
        setIndex(-1);
      }
    }
  };

  const handleConfirmCertificate = () => {
    if (!newCertificate.credentialId) {
      toast.error("Please enter credential id");
    } else if (!newCertificate.link) {
      toast.error("Please enter link");
    } else if (!newCertificate.name) {
      toast.error("Please enter title");
    } else if (!newCertificate.issued) {
      toast.error("Please select issued date");
    } else {
      if (index === -1) {
        certificates.push(newCertificate);
        setAddExperience(false);
        setNewCertificate({
          logo: null,
          name: "",
          issued: "",
          credentialId: "",
          link: "",
        });
      } else {
        setCertificates((prevExperience) => {
          const updatedExperience = [...prevExperience]; // Create a shallow copy of the array
          updatedExperience[index] = newCertificate; // Update the element at the specified index
          return updatedExperience; // Return the updated array
        });
        setNewCertificate({
          logo: null,
          name: "",
          issued: "",
          credentialId: "",
          link: "",
        });

        setAddExperience(false);
        setIndex(-1);
      }
    }
  };

  const handleConfirmEducation = () => {
    if (!newEducation?.schoolName) {
      toast.error("Please enter company name");
    } else if (!newEducation?.from) {
      toast.error("Please enter from date");
    } else if (!newEducation?.to) {
      toast.error("Please enter to date");
    } else if (!newEducation?.degree) {
      toast.error("Please enter position");
    } else if (!newEducation?.description) {
      toast.error("Please Provide your experience at the institution");
    } else if (!newEducation?.location) {
      toast.error("Please select location");
    } else {
      if (index === -1) {
        education.push(newEducation);
        setAddExperience(false);
        setNewEducation({
          logo: null,
          degree: "",
          from: "",
          to: "",
          location: "",
          schoolName: "",
          description: "",
        });
      } else {
        setEducation((prevExperience) => {
          const updatedExperience = [...prevExperience]; // Create a shallow copy of the array
          updatedExperience[index] = newEducation; // Update the element at the specified index
          return updatedExperience; // Return the updated array
        });
        setNewEducation({
          logo: null,
          degree: "",
          from: "",
          to: "",
          location: "",
          schoolName: "",
          description: "",
        });

        setAddExperience(false);
        setIndex(-1);
      }
    }
  };

  const handleConfirm = () => {
    if (!newExperience?.company) {
      toast.error("Please enter company name");
    } else if (!newExperience?.from) {
      toast.error("Please enter from date");
    } else if (!newExperience?.to) {
      toast.error("Please enter to date");
    } else if (!newExperience?.position) {
      toast.error("Please enter position");
    } else if (!newExperience?.description) {
      toast.error("Please Provide your experience at the company");
    } else if (!newExperience?.location) {
      toast.error("Please select location");
    } else {
      if (index === -1) {
        experience.push(newExperience);
        setAddExperience(false);
        setNewExperience({
          logo: null,
          position: "",
          from: "",
          to: "",
          location: "",
          company: "",
          description: "",
        });
      } else {
        setExperience((prevExperience) => {
          const updatedExperience = [...prevExperience]; // Create a shallow copy of the array
          updatedExperience[index] = newExperience; // Update the element at the specified index
          return updatedExperience; // Return the updated array
        });
        setNewExperience({
          logo: null,
          position: "",
          from: "",
          to: "",
          location: "",
          company: "",
          description: "",
        });

        setAddExperience(false);
        setIndex(-1);
      }
    }
  };

  const handleUpdateEducation = async () => {
    if (education.length > 0 && owner) {
      setLoading(true);
      await axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/api/user/update/Education`,
          {
            education,
            userId: user.uniqueId,
          }
        )
        .then((res) => {
          if (res.data.msg === "success") {
            // dispatch(setLogin({ user: res.data.updated })).then(() => {
            //   console.log("");
            // });
            setEducationPopup(false);
            setAddExperience(false);
            setNewEducation({
              logo: null,
              degree: "",
              from: "",
              to: "",
              location: "",
              schoolName: "",
              description: "",
            });
            setIndex(-1);

            window.location.reload();
          } else {
            toast.error("Error updating Education");
            setEducationPopup(false);
            setAddExperience(false);
            setNewEducation({
              logo: null,
              degree: "",
              from: "",
              to: "",
              location: "",
              schoolName: "",
              description: "",
            });
            setIndex(-1);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err.message);
          setEducationPopup(false);
          setAddExperience(false);
          setNewEducation({
            logo: null,
            degree: "",
            from: "",
            to: "",
            location: "",
            schoolName: "",
            description: "",
          });
          setIndex(-1);
          setLoading(false);
        });
    } else {
      toast.error("Kindly add at least one education record");
    }
  };

  const handleUpdateAchievements = async () => {
    if (awards.length > 0 && owner) {
      setLoading(true);
      await axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/api/user/update/Awards`, {
          awards,
          userId: user.uniqueId,
        })
        .then((res) => {
          if (res.data.msg === "success") {
            // dispatch(setLogin({ user: res.data.updated }));
            setAwardsPopup(false);
            setAddExperience(false);
            setNewAward({
              logo: null,
              title: "",
              companyName: "",
              package: "",
              issue: "",
              description: "",
            });
            setIndex(-1);

            window.location.reload();
          } else {
            toast.error("Error updating Certificates");
            setAwardsPopup(false);
            setAddExperience(false);
            setNewExperience({
              logo: null,
              position: "",
              from: "",
              to: "",
              location: "",
              company: "",
              description: "",
            });
            setIndex(-1);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err.message);
          setAwardsPopup(false);
          setAddExperience(false);
          setNewAward({
            logo: null,
            title: "",
            companyName: "",
            package: "",
            issue: "",
            description: "",
          });
          setIndex(-1);
          setLoading(false);
        });
    } else {
      toast.error("Kindly add at least one Certificate record");
    }
  };

  const handleUpdateCertificate = async () => {
    if (certificates.length > 0 && owner) {
      setLoading(true);
      await axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/api/user/update/Certificate`,
          {
            certificates,
            userId: user.uniqueId,
          }
        )
        .then((res) => {
          if (res.data.msg === "success") {
            // dispatch(setLogin({ user: res.data.updated }));
            setCertificatesPopup(false);
            setAddExperience(false);
            setNewCertificate({
              logo: null,
              name: "",
              issued: "",
              credentialId: "",
              link: "",
            });
            setIndex(-1);

            window.location.reload();
          } else {
            toast.error("Error updating Certificates");
            setExperiencePopup(false);
            setAddExperience(false);
            setNewExperience({
              logo: null,
              position: "",
              from: "",
              to: "",
              location: "",
              company: "",
              description: "",
            });
            setIndex(-1);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err.message);
          setExperiencePopup(false);
          setAddExperience(false);
          setNewExperience({
            logo: null,
            position: "",
            from: "",
            to: "",
            location: "",
            company: "",
            description: "",
          });
          setIndex(-1);
          setLoading(false);
        });
    } else {
      toast.error("Kindly add at least one Certificate record");
    }
  };

  const handleUpdateExperience = async () => {
    if (experience.length > 0 && owner) {
      setLoading(true);
      await axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/api/user/update/Experience`,
          {
            experience,
            userId: user.uniqueId,
          }
        )
        .then((res) => {
          if (res.data.msg === "success") {
            // dispatch(setLogin({ user: res.data.updated }));
            setExperiencePopup(false);
            setAddExperience(false);
            setNewExperience({
              logo: null,
              position: "",
              from: "",
              to: "",
              location: "",
              company: "",
              description: "",
            });
            setIndex(-1);

            window.location.reload();
          } else {
            toast.error("Error updating Experience");
            setExperiencePopup(false);
            setAddExperience(false);
            setNewExperience({
              logo: null,
              position: "",
              from: "",
              to: "",
              location: "",
              company: "",
              description: "",
            });
            setIndex(-1);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err.message);
          setExperiencePopup(false);
          setAddExperience(false);
          setNewExperience({
            logo: null,
            position: "",
            from: "",
            to: "",
            location: "",
            company: "",
            description: "",
          });
          setIndex(-1);
          setLoading(false);
        });
    } else {
      toast.error("Kindly add at least one experience");
    }
  };

  const updateBio = async () => {
    if (owner) {
      if (bio) {
        setLoading(true);
        await axios
          .post(`${process.env.REACT_APP_BACKEND_URL}/api/user/update/bio`, {
            bio,
            userId: user.uniqueId,
          })
          .then((res) => {
            if (res.data.msg === "success") {
              // dispatch(setLogin({ user: res.data.updated }));
              setBioPopup(false);
              window.location.reload();
            } else {
              toast.error("Error updating bio");
              setBioPopup(false);
            }
            setLoading(false);
          })
          .catch((err) => {
            console.log(err.message);
            setBioPopup(false);
            setLoading(false);
          });
      } else {
        toast.error("Kindly write something");
      }
    }
  };

  const updateAbout = async () => {
    if (owner) {
      if (about) {
        setLoading(true);
        await axios
          .post(`${process.env.REACT_APP_BACKEND_URL}/api/user/update/about`, {
            about,
            userId: user.uniqueId,
          })
          .then((res) => {
            if (res.data.msg === "success") {
              // dispatch(setLogin({ user: res.data.updated }));
              setAboutPopup(false);
              window.location.reload();
            } else {
              toast.error("Error updating About");
              setAboutPopup(false);
            }
            setLoading(false);
          })
          .catch((err) => {
            console.log(err.message);
            setAboutPopup(false);
            setLoading(false);
          });
      } else {
        toast.error("Kindly write something");
      }
    }
  };

  const updateCoverPhoto = async () => {
    if (owner) {
      if (selectedFile) {
        const reader = new FileReader();

        reader.onloadend = async () => {
          const base64Url = reader.result;

          try {
            setLoading(true);
            const res = await axios.post(
              `${process.env.REACT_APP_BACKEND_URL}/api/user/update/coverPhoto`,
              { userId: user.uniqueId, img: base64Url }
            );

            if (res.data.msg === "success") {
              // dispatch(setLogin({ user: res.data.updated }));
              setCoverPopup(false);
              window.location.reload();
            } else {
              toast.error("Error updating Cover photo");
              setCoverPopup(false);
            }
            setLoading(false);
          } catch (err) {
            console.error(err.message);
            toast.error("Error updating Cover photo");
            setCoverPopup(false);
          }
        };

        reader.readAsDataURL(selectedFile);
      } else {
        toast.error("Kindly upload cover photo");
      }
    }
  };

  return (
    <>
      {(bioPopup ||
        coverPopup ||
        aboutPopup ||
        experiencePopup ||
        educationPopup ||
        certificatesPopup ||
        awardsPopup) && (
        <div
          className="center"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            height: "100vh",
            background: "rgba(0, 0, 0, 0.5)",
            width: "100vw",

            zIndex: 1,
          }}
        >
          <div className="">
            <div className=" " style={{ marginTop: "8rem", padding: "1rem" }}>
              <div
                className="tablescroll innerglass"
                style={{
                  background: "#FFF",
                  padding: "2rem",
                  alignItems: "center",
                  borderRadius: "10px",

                  maxHeight: "70vh",
                  minWidth: "30vw",
                  overflow: "auto",
                  overflowX: "hidden",
                }}
                ref={popupref}
              >
                {bioPopup && (
                  <div>
                    <div className="space">
                      <p className="black16 text-start">Add profile bio</p>{" "}
                      <CloseIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => setBioPopup(false)}
                      />
                    </div>
                    <textarea
                      className="col-md-12 col-12 mt-3 forminput"
                      value={bio}
                      placeholder="Add profile bio"
                      style={{
                        minWidth: isNonMobile450 ? "400px" : "70vw",
                        minHeight: "30vh",
                        width: "100%",
                      }}
                      onChange={(e) => {
                        setBio(e.target.value);
                      }}
                    />
                    <button
                      className="continuebutton mt-3"
                      onClick={() => updateBio()}
                      disabled={loading}
                    >
                      Update
                    </button>
                  </div>
                )}
                {coverPopup && (
                  <div>
                    <p className="black16 text-start">Add Cover Photo</p>{" "}
                    <div
                      className="d-flex mt-3 mb-3 p-3 border border-2 rounded-2"
                      style={{ justifyContent: "center" }}
                    >
                      <div>
                        {selectedFile && (
                          <img
                            src={
                              selectedFile instanceof File
                                ? URL.createObjectURL(selectedFile)
                                : selectedFile
                            }
                            alt="selectefFile"
                            className="rounded-2"
                            style={{ maxHeight: "300px", maxWidth: "60vw" }}
                          />
                        )}
                        <div style={{ gap: "5px", justifyContent: "center" }}>
                          <p
                            className="text-center clicktoupload"
                            onClick={openFileInput}
                            style={{ cursor: "pointer" }}
                          >
                            {selectedFile
                              ? "Click to change Image"
                              : "Click to upload Image"}
                          </p>
                          <p className="svgpngtext">
                            SVG, PNG, JPG or GIF (max. 12MB)
                          </p>
                        </div>

                        <input
                          type="file"
                          accept="image/*"
                          onChange={handleFileChange}
                          style={{ display: "none" }}
                          ref={fileInputRef}
                        />
                      </div>
                    </div>
                    <button
                      className="continuebutton mt-3"
                      onClick={() => updateCoverPhoto()}
                      disabled={loading}
                    >
                      Update
                    </button>
                  </div>
                )}

                {aboutPopup && (
                  <div>
                    <div className="space">
                      <p className="black16 text-start">Add profile about</p>{" "}
                      <CloseIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => setAboutPopup(false)}
                      />
                    </div>{" "}
                    <textarea
                      className="forminput mt-3"
                      value={about}
                      style={{
                        minWidth: isNonMobile450 ? "400px" : "70vw",
                        minHeight: "30vh",
                      }}
                      placeholder="Add profile about"
                      onChange={(e) => {
                        setAbout(e.target.value);
                      }}
                    />
                    <button
                      className="continuebutton mt-3"
                      onClick={() => updateAbout()}
                      disabled={loading}
                    >
                      Update
                    </button>
                  </div>
                )}
                {experiencePopup && (
                  <div style={{ minWidth: "50vw", minHeight: "30vh" }}>
                    <p className="black16 text-start">Add Experiences</p>
                    <div>
                      {user?.experience?.length > 0 &&
                        !addExperience &&
                        user?.experience.map((exp, index) => (
                          <>
                            {exp?.company && (
                              <div key={index} style={{ marginTop: "1.5rem" }}>
                                <hr />
                                <div
                                  className="d-flex justify-content-end"
                                  style={{ gap: "1rem" }}
                                >
                                  <EditIcon
                                    className="hoverred"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      setIndex(index);
                                      setNewExperience(exp);
                                      setAddExperience(true);
                                    }}
                                  />
                                  <DeleteIcon
                                    className="hoverred"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      setExperience(
                                        experience.filter((_, i) => i !== index)
                                      );
                                    }}
                                  />
                                </div>
                                <div
                                  className="d-flex flex-wrap"
                                  style={{ gap: "1rem" }}
                                >
                                  {exp?.logo && (
                                    <img
                                      src={
                                        exp?.logo instanceof File
                                          ? URL.createObjectURL(exp?.logo)
                                          : exp?.logo
                                      } // Access logo property from each experience object
                                      alt="university logo"
                                      className="shadowa rounded-2"
                                      style={{
                                        maxWidth: "32px",
                                        maxHeight: "32px",
                                        height: "32px",
                                        padding: "2px",
                                        background: "#FFF",
                                      }}
                                    />
                                  )}
                                  <div style={{ width: "100%" }}>
                                    <div
                                      className="space flex-wrap"
                                      style={{ gap: "1rem" }}
                                    >
                                      <p className="black16 text-start">
                                        {exp?.position}
                                      </p>
                                      <p className="gray14400">
                                        {exp?.from}-{exp?.to}
                                      </p>
                                    </div>
                                    <p
                                      className="gray14400 d-flex flex-wrap"
                                      style={{
                                        alignItems: "center",
                                        gap: "0.5rem",
                                        marginTop: "0.5rem",
                                      }}
                                    >
                                      {exp?.location?.flag}{" "}
                                      {exp?.location?.name}
                                      <div
                                        className=""
                                        style={{
                                          background: "#667085",
                                          height: "4px",
                                          width: "4px",
                                          borderRadius: "125px",
                                        }}
                                      />
                                      {exp?.company}
                                    </p>
                                    {exp?.description && (
                                      <p
                                        className="gray14400"
                                        style={{ marginTop: "0.75rem" }}
                                      >
                                        {exp?.description}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        ))}
                      {addExperience && (
                        <AddExperience
                          handleConfirm={handleConfirm}
                          newExperience={newExperience}
                          setNewExperience={setNewExperience}
                          addExperience={addExperience}
                          setAddExperience={setAddExperience}
                        />
                      )}
                    </div>
                    {!addExperience && (
                      <div className="mt-4">
                        <div className="d-flex">
                          <p
                            className=" text-start backbutton hoverred mt-3"
                            onClick={() => setAddExperience(true)}
                            style={{ cursor: "pointer" }}
                          >
                            Add Experience
                          </p>
                        </div>
                        <hr className="mt-5" />
                        <button
                          className="continuebutton mt-2"
                          onClick={() => handleUpdateExperience()}
                          disabled={loading}
                        >
                          Update experience
                        </button>
                      </div>
                    )}
                  </div>
                )}
                {educationPopup && (
                  <div style={{ minWidth: "50vw", minHeight: "30vh" }}>
                    <EducationPopup
                      education={education}
                      setEducation={setEducation}
                      setNewEducation={setNewEducation}
                      newEducation={newEducation}
                      addExperience={addExperience}
                      setAddExperience={setAddExperience}
                      handleConfirmEducation={handleConfirmEducation}
                      setIndex={setIndex}
                      loading={loading}
                      user={user}
                      handleUpdateEducation={handleUpdateEducation}
                    />
                  </div>
                )}
                {certificatesPopup && (
                  <div style={{ minWidth: "50vw", minHeight: "30vh" }}>
                    <CertificatesPopup
                      addExperience={addExperience}
                      setAddExperience={setAddExperience}
                      setIndex={setIndex}
                      user={user}
                      certificates={certificates}
                      setCertificates={setCertificates}
                      newCertificate={newCertificate}
                      setNewCertificate={setNewCertificate}
                      handleConfirmCertificate={handleConfirmCertificate}
                      handleUpdateCertificate={handleUpdateCertificate}
                      loading={loading}
                    />
                  </div>
                )}
                {awardsPopup && (
                  <div style={{ minWidth: "50vw", minHeight: "30vh" }}>
                    <AwardsPopup
                      awards={awards}
                      user={user}
                      setAwards={setAwards}
                      newAward={newAward}
                      setNewAward={setNewAward}
                      setIndex={setIndex}
                      setAddExperience={setAddExperience}
                      addExperience={addExperience}
                      loading={loading}
                      handleConfirmAward={handleConfirmAward}
                      handleUpdateAchievements={handleUpdateAchievements}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="space flex-wrap " style={{ gap: "1rem" }}>
        {owner && (
          <div
            className="center black14 hoverred gap-2 backbutton"
            style={{
              alignItems: "center",
              cursor: "pointer",
              marginTop: "-50px",
              maxHeight: "40px",
            }}
            onClick={() => setCoverPopup(true)}
          >
            {" "}
            Edit {isNonMobile450 && "Cover Photo"}
            <EditIcon style={{ width: "20px" }} />
          </div>
        )}
      </div>
      <div
        className="d-flex"
        style={{ gap: isNonMobile450 ? "1.875rem" : "1.5rem" }}
      >
        <div
          style={{
            // marginTop: "1rem",
            width: "100%",
            maxWidth: TRUE
              ? "100%"
              : owner
              ? "100%"
              : isNonMobile1200
              ? "68.947%"
              : isNonMobile1024
              ? "68.947%"
              : "100%",
          }}
        >
          <CoverPhoto user={user} />
          <div
            className="pt-0 shadowa"
            style={{
              background: "#FFF",
              padding: "1.25rem",
              borderRadius: "0px 0px 4px 4px",
            }}
          >
            <img
              src={user?.profilePhoto}
              alt="profile"
              className="center"
              height={isNonMobile450 ? "80px" : "64px"}
              width={isNonMobile450 ? "80px" : "64px"}
              style={{
                borderRadius: "125px",
                alignItems: "center",
                position: "relative",
                background: "#FFF",
                // marginLeft: "1rem",
                padding: "2px",
                marginTop: "-40px",
              }}
            />
            <div className="space" style={{ alignItems: "center" }}>
              <p className="filmo20">
                {user?.firstName} {user?.LastName}
              </p>
              <p className="black14">{user?.uni?.area}</p>
            </div>
            <div className="" style={{ marginTop: "0.75rem" }}>
              <p style={{ fontWeight: "400" }} className="black14">
                {user?.bio}
              </p>
              {owner && (
                <div className="d-flex">
                  <div
                    className="d-flex flex-wrap gap-1 center hoverred black14"
                    style={{ alignItems: "center", cursor: "pointer" }}
                    onClick={() => {
                      setBioPopup(true);
                    }}
                  >
                    {user?.bio ? "Edit Bio" : "Add Bio"}
                    <EditIcon style={{ marginTop: "0.25rem", width: "24px" }} />
                  </div>
                </div>
              )}
            </div>
            <div
              className="space flex-wrap"
              style={{ marginTop: "1rem", gap: "1rem" }}
            >
              <div
                className="d-flex flex-wrap"
                style={{ gap: "1rem", alignItems: "center" }}
              >
                <img
                  src={user?.uni?.logo}
                  alt="university logo"
                  className="shadowa"
                  style={{
                    maxWidth: "32px",
                    maxHeight: "32px",
                    padding: "2px",
                    background: "#FFF",
                  }}
                />
                <p className="black14" style={{ fontWeight: "500" }}>
                  {user?.uni?.name}
                </p>
              </div>

              {!owner && !recipient && (
                <div className="d-flex flex-wrap" style={{ gap: "1rem" }}>
                  {/* {!test && (
                    <button className="continuebutton">Short list</button>
                  )} */}
                  {showMenu && (
                    <div
                      style={{
                        position: "absolute",
                        background: "transparent",
                        marginTop: "1rem",
                        marginLeft: "-1rem",
                      }}
                      ref={iconRef}
                    >
                      <div
                        className="d-flex shadowa"
                        style={{
                          flexDirection: "column",
                          background: "transparent",
                        }}
                      >
                        <button
                          className="d-flex popuptext"
                          style={{
                            padding: "11px 1rem",
                            maxHeight: "2rem",
                            borderRadius: "5px 5px 0px 0px",
                            gap: "10px",
                            background: "#FFF",
                            border: "none",
                            width: "160px",
                            minHeight: "42px",
                          }}
                          onClick={() => {
                            setShowMenu(false);
                            navigate(`/dashboard/chat?id=${user?.uniqueId}`);
                          }}
                        >
                          Message
                        </button>
                        <button
                          className="d-flex popuptext"
                          style={{
                            padding: "11px 1rem",
                            maxHeight: "2rem",
                            borderRadius: "0px 0px 5px 5px",
                            gap: "10px",
                            background: "#FFF",
                            border: "none",
                            width: "160px",
                            minHeight: "42px",
                            borderTop: "1px solid #d9d9d9",
                          }}
                          onClick={() => {
                            setShowMenu(false);
                            const sharedlink = `${process.env.REACT_APP_FRONTEND_URL}/dashboard/user/${user?.uniqueId}`;
                            navigator.clipboard.writeText(sharedlink).then(
                              function () {
                                toast.success("Link copied to clipboard!");
                              },
                              function (err) {
                                console.error("Could not copy text: ", err);
                                toast.error(
                                  "Failed to copy link to clipboard."
                                );
                              }
                            );
                          }}
                        >
                          Share profile
                        </button>
                        {/* <button
                        className="d-flex popuptext"
                        style={{
                          padding: "11px 1rem",
                          maxHeight: "2rem",
                          borderRadius: "0px 0px 5px 5px",
                          gap: "10px",
                          background: "#FFF",
                          border: "none",
                          width: "160px",
                          minHeight: "42px",
                          borderTop: "1px solid #d9d9d9",
                        }}
                        onClick={() => {
                          setShowMenu(false);
                        }}
                      >
                        Invite to event
                      </button> */}
                        {/* <button
                        className="d-flex popuptext"
                        style={{
                          padding: "11px 1rem",
                          maxHeight: "2rem",
                          borderRadius: "0px 0px 5px 5px",
                          gap: "10px",
                          background: "#FFF",
                          border: "none",
                          width: "160px",
                          minHeight: "42px",
                          borderTop: "1px solid #d9d9d9",
                        }}
                        onClick={() => {
                          setShowMenu(false);
                        }}
                      >
                        Report
                      </button> */}
                      </div>
                    </div>
                  )}

                  <div
                    className="shadowa rounded-2 hoverred center"
                    style={{
                      padding: "10px",
                      background: "#FFF",
                      width: "40px",
                      cursor: "pointer",
                      alignItems: "center",
                    }}
                    onClick={() => setShowMenu(!showMenu)}
                  >
                    <MoreVertIcon style={{ height: "20px" }} />
                  </div>
                </div>
              )}
            </div>
          </div>
          {recipient && (
            <div style={{ marginTop: "1rem", marginBottom: "1.5rem" }}>
              <div className="outercoverofbuttons shadowa">
                <div
                  className={`innercoverofbuttons ${
                    selected === "Details" ? "change" : null
                  }`}
                  onClick={() => setSelected("Details")}
                >
                  Details
                </div>
                <div
                  className={`innercoverofbuttons ${
                    selected === "Engagement" ? "change" : null
                  }`}
                  onClick={() => setSelected("Engagement")}
                >
                  Engagement Level
                </div>
              </div>
            </div>
          )}
          {!recipient && (
            <>
              <div className=" shadowa" style={{ marginTop: "1.5rem" }}>
                <div className="innerglass" style={{ padding: "1.25rem" }}>
                  {(user?.about || owner) && (
                    <>
                      {" "}
                      <div className="space flex-wrap">
                        <p className="filmo20">About</p>
                        {owner && (
                          <div
                            className=" center hoverred "
                            style={{ alignItems: "center", cursor: "pointer" }}
                            onClick={() => setAboutPopup(true)}
                          >
                            <EditIcon
                              style={{ marginTop: "0.25rem", width: "24px" }}
                            />
                          </div>
                        )}
                      </div>
                      <p
                        className="gray14 tablescroll"
                        style={{
                          overflow: "auto",
                          maxHeight: "120px",
                          marginTop: "1.5rem",
                          color: "#667085",
                        }}
                      >
                        {user?.about ? user?.about : "Add about  to stand out"}
                      </p>
                      <hr
                        style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}
                      />
                    </>
                  )}
                  <div className="space flex-wrap" style={{ gap: "1.5rem" }}>
                    {user?.selectedItem?.length > 0 && (
                      <div style={{ width: "48%", minWidth: "233px" }}>
                        <p className="black14" style={{ color: "#475467" }}>
                          Interests
                        </p>
                        <div
                          className="d-flex flex-wrap"
                          style={{ gap: "0.75rem", marginTop: "0.75rem" }}
                        >
                          {user?.selectedItem
                            ?.slice(0, 4)
                            .map((item, index) => (
                              <div key={index} className="interests">
                                <div className="newdashboardsidechips">
                                  <img
                                    src={item.icon}
                                    alt=""
                                    height="16px"
                                    width="16px"
                                    className="Interest_img_icon"
                                  />
                                  <p className="namecountrya">{item.id}</p>
                                </div>
                              </div>
                            ))}
                          {user?.selectedItem?.length > 4 && (
                            <div className="interests">
                              <div className="newdashboardsidechips">
                                <p className="namecountrya">
                                  + {user.selectedItem.length - 4}{" "}
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    {user?.Skills?.length > 0 && (
                      <div style={{ width: "48%", minWidth: "233px" }}>
                        <p className="black14" style={{ color: "#475467" }}>
                          Skills
                        </p>
                        <div
                          className="d-flex flex-wrap"
                          style={{ gap: "0.75rem", marginTop: "0.75rem" }}
                        >
                          {user?.Skills?.slice(0, 4).map((item, index) => (
                            <div key={index} className="interests">
                              <div className="newdashboardsidechips">
                                <p className="namecountrya">
                                  {item.skill}{" "}
                                  <span
                                    className="namecountrya"
                                    style={{
                                      color: "#3538CD",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {item.points} Points
                                  </span>
                                </p>
                              </div>
                            </div>
                          ))}
                          {user?.Skills?.length > 4 && (
                            <div className="interests">
                              <div className="newdashboardsidechips">
                                <p className="namecountrya">
                                  + {user.Skills.length - 4}{" "}
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>{" "}
                  <hr style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }} />
                  <div className="space flex-wrap" style={{ gap: "1.5rem" }}>
                    {user?.levelofstudy && (
                      <div style={{ width: "48%", minWidth: "233px" }}>
                        <p className="black14" style={{ color: "#475467" }}>
                          Level of Study
                        </p>
                        <div
                          className="d-flex flex-wrap"
                          style={{ gap: "0.75rem", marginTop: "0.75rem" }}
                        >
                          <div className="interests">
                            <div className="newdashboardsidechips">
                              <p className="namecountrya">
                                {user?.levelofstudy} of{" "}
                                {user?.study && user?.study}
                              </p>
                            </div>
                          </div>
                          {console.log(user)}
                          {user?.intake?.Year && (
                            <div className="interests">
                              <div className="newdashboardsidechips">
                                <p className="namecountrya">
                                  Intake {user?.intake?.Year}
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    {user?.languages?.length > 0 && (
                      <div style={{ width: "48%", minWidth: "233px" }}>
                        <p className="black14" style={{ color: "#475467" }}>
                          Languages
                        </p>
                        <div
                          className="d-flex flex-wrap"
                          style={{ gap: "0.75rem", marginTop: "0.75rem" }}
                        >
                          {user?.languages?.slice(0, 4).map((item, index) => (
                            <div key={index} className="interests">
                              <div className="newdashboardsidechips">
                                <p className="namecountrya">{item.text} </p>
                              </div>
                            </div>
                          ))}
                          {user?.languages?.length > 4 && (
                            <div className="interests">
                              <div className="newdashboardsidechips">
                                <p className="namecountrya">
                                  + {user.languages.length - 4}{" "}
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {challenges.length > 0 && !owner && !recipient && (
                <div className=" " style={{ marginTop: "1.875rem" }}>
                  <div
                    className="innerchallengeglass "
                    style={{ background: "transparent" }}
                    // style={{ overflow: "auto", gap: "1.125rem" }}
                  >
                    <p className="filmo20">
                      Completed {JSON.parse(challenges[0]?.company).title}{" "}
                      Challenges
                    </p>
                    <div style={{ marginTop: "1.5rem" }}>
                      <Carousel responsive={responsive}>
                        {challenges.map((challe, index) => (
                          <div key={index}>
                            <ChallengeCard
                              TRUE={true}
                              challe={challe}
                              navigate={navigate}
                            />
                          </div>
                        ))}
                      </Carousel>
                    </div>
                  </div>
                </div>
              )}{" "}
              {(user?.experience?.length > 0 || owner) && (
                <div style={{ marginTop: "1.875rem" }}>
                  <Experience
                    owner={owner}
                    user={user}
                    isNonMobile450={isNonMobile450}
                    setExperiencePopup={setExperiencePopup}
                  />
                </div>
              )}
              {(user?.education?.length > 0 || owner) && (
                <div style={{ marginTop: "1.875rem" }}>
                  <Education
                    owner={owner}
                    user={user}
                    isNonMobile450={isNonMobile450}
                    setEducationPopup={setEducationPopup}
                  />
                </div>
              )}
              {(user?.certificates?.length > 0 || owner) && (
                <div style={{ marginTop: "1.875rem" }}>
                  <Certificates
                    owner={owner}
                    user={user}
                    setCertificatesPopup={setCertificatesPopup}
                  />
                </div>
              )}
              {(user?.awards?.length > 0 || owner) && (
                <div style={{ marginTop: "1.875rem" }}>
                  <Awards
                    owner={owner}
                    user={user}
                    setAwardsPopup={setAwardsPopup}
                  />
                </div>
              )}
              {(user?.volunteer?.length > 0 || owner) && (
                <div style={{ marginTop: "1.875rem" }}>
                  <Volunteering owner={owner} user={user} />
                </div>
              )}
              {(user?.publications?.length > 0 || owner) && (
                <div style={{ marginTop: "1.875rem" }}>
                  <Publications owner={owner} user={user} />
                </div>
              )}
              {(user?.testScores?.length > 0 || owner) && (
                <div style={{ marginTop: "1.875rem" }}>
                  <Test owner={owner} user={user} />
                </div>
              )}
            </>
          )}
          {recipient && selected === "Details" && (
            <>
              <div
                className={` ${TRUE ? "" : " shadowa"} `}
                style={{ marginTop: "1.5rem" }}
              >
                <div className={` ${TRUE ? "" : "innerglass "} `}>
                  {(user?.about || owner) && (
                    <>
                      {" "}
                      <div className="space flex-wrap">
                        <p className="filmo20">About</p>
                        {owner && (
                          <div
                            className=" center hoverred "
                            style={{ alignItems: "center", cursor: "pointer" }}
                            onClick={() => setAboutPopup(true)}
                          >
                            <EditIcon
                              style={{ marginTop: "0.25rem", width: "24px" }}
                            />
                          </div>
                        )}
                      </div>
                      <p
                        className="gray14400 tablescroll"
                        style={{
                          overflow: "auto",
                          maxHeight: "120px",
                          marginTop: "1.5rem",
                          color: "#667085",
                        }}
                      >
                        {user?.about ? user?.about : "Add about  to stand out"}
                      </p>
                      <hr
                        style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}
                      />
                    </>
                  )}
                  <div className="space flex-wrap" style={{ gap: "1.5rem" }}>
                    {user?.selectedItem?.length > 0 && (
                      <div style={{ width: "48%", minWidth: "233px" }}>
                        <p className="gray14950" style={{ color: "#475467" }}>
                          Interests
                        </p>
                        <div
                          className="d-flex flex-wrap"
                          style={{ gap: "0.75rem", marginTop: "0.75rem" }}
                        >
                          {user?.selectedItem
                            ?.slice(0, 4)
                            .map((item, index) => (
                              <div key={index} className="interests">
                                <div className="newdashboardsidechips">
                                  <img
                                    src={item.icon}
                                    alt=""
                                    height="16px"
                                    width="16px"
                                    className="Interest_img_icon"
                                  />
                                  <p className="namecountrya">{item.id}</p>
                                </div>
                              </div>
                            ))}
                          {user?.selectedItem?.length > 4 && (
                            <div className="interests">
                              <div className="newdashboardsidechips">
                                <p className="namecountrya">
                                  + {user.selectedItem.length - 4}{" "}
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    {user?.Skills?.length > 0 && (
                      <div style={{ width: "48%", minWidth: "233px" }}>
                        <p className="gray14950" style={{ color: "#475467" }}>
                          Skills
                        </p>
                        <div
                          className="d-flex flex-wrap"
                          style={{ gap: "0.75rem", marginTop: "0.75rem" }}
                        >
                          {user?.Skills?.slice(0, 4).map((item, index) => (
                            <div key={index} className="interests">
                              <div className="newdashboardsidechips">
                                <p className="namecountrya">
                                  {item.skill}{" "}
                                  <span
                                    className="namecountrya"
                                    style={{
                                      color: "#3538CD",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {item.points} Points
                                  </span>
                                </p>
                              </div>
                            </div>
                          ))}
                          {user?.Skills?.length > 4 && (
                            <div className="interests">
                              <div className="newdashboardsidechips">
                                <p className="namecountrya">
                                  + {user.Skills.length - 4}{" "}
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>{" "}
                  <hr style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }} />
                  <div className="space flex-wrap" style={{ gap: "1.5rem" }}>
                    {user?.levelofstudy && (
                      <div style={{ width: "48%", minWidth: "233px" }}>
                        <p className="gray14950" style={{ color: "#475467" }}>
                          Level of Study
                        </p>
                        <div
                          className="d-flex flex-wrap"
                          style={{ gap: "0.75rem", marginTop: "0.75rem" }}
                        >
                          <div className="interests">
                            <div className="newdashboardsidechips">
                              <p className="namecountrya">
                                {user?.levelofstudy} of{" "}
                                {user?.study && user?.study}
                              </p>
                            </div>
                          </div>
                          {user?.intake?.Year && (
                            <div className="interests">
                              <div className="newdashboardsidechips">
                                <p className="namecountrya">
                                  Intake {user?.intake?.Year}
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    {user?.languages?.length > 0 && (
                      <div style={{ width: "48%", minWidth: "233px" }}>
                        <p className="black14950" style={{ color: "#475467" }}>
                          Languages
                        </p>
                        <div
                          className="d-flex flex-wrap"
                          style={{ gap: "0.75rem", marginTop: "0.75rem" }}
                        >
                          {user?.languages?.slice(0, 4).map((item, index) => (
                            <div key={index} className="interests">
                              <div className="newdashboardsidechips">
                                <p className="namecountrya">{item.text} </p>
                              </div>
                            </div>
                          ))}
                          {user?.languages?.length > 4 && (
                            <div className="interests">
                              <div className="newdashboardsidechips">
                                <p className="namecountrya">
                                  + {user.languages.length - 4}{" "}
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {challenges.length > 0 && !owner && !recipient && (
                <div className=" shadowa" style={{ marginTop: "1.875rem" }}>
                  <div
                    className="innerchallengeglass "
                    // style={{ overflow: "auto", gap: "1.125rem" }}
                  >
                    <p className="filmo20">
                      Completed {JSON.parse(challenges[0]?.company).title}{" "}
                      Challenges
                    </p>
                    <div style={{ marginTop: "1.5rem" }}>
                      <Carousel responsive={responsive}>
                        {challenges.map((challe, index) => (
                          <div key={index}>
                            <ChallengeCard
                              challe={challe}
                              navigate={navigate}
                            />
                          </div>
                        ))}
                      </Carousel>
                    </div>
                  </div>
                </div>
              )}{" "}
              {(user?.experience?.length > 0 || owner) && (
                <div style={{ marginTop: "1.875rem" }}>
                  <Experience
                    owner={owner}
                    user={user}
                    isNonMobile450={isNonMobile450}
                    setExperiencePopup={setExperiencePopup}
                  />
                </div>
              )}
              {(user?.education?.length > 0 || owner) && (
                <div style={{ marginTop: "1.875rem" }}>
                  <Education
                    owner={owner}
                    user={user}
                    isNonMobile450={isNonMobile450}
                    setEducationPopup={setEducationPopup}
                  />
                </div>
              )}
              {(user?.certificates?.length > 0 || owner) && (
                <div style={{ marginTop: "1.875rem" }}>
                  <Certificates
                    owner={owner}
                    user={user}
                    setCertificatesPopup={setCertificatesPopup}
                  />
                </div>
              )}
              {(user?.awards?.length > 0 || owner) && (
                <div style={{ marginTop: "1.875rem" }}>
                  <Awards
                    owner={owner}
                    user={user}
                    setAwardsPopup={setAwardsPopup}
                  />
                </div>
              )}
              {(user?.volunteer?.length > 0 || owner) && (
                <div style={{ marginTop: "1.875rem" }}>
                  <Volunteering owner={owner} user={user} />
                </div>
              )}
              {(user?.publications?.length > 0 || owner) && (
                <div style={{ marginTop: "1.875rem" }}>
                  <Publications owner={owner} user={user} />
                </div>
              )}
              {(user?.testScores?.length > 0 || owner) && (
                <div style={{ marginTop: "1.875rem" }}>
                  <Test owner={owner} user={user} />
                </div>
              )}
            </>
          )}
          {recipient && selected === "Engagement" && (
            <>
              <div
                style={{
                  width: TRUE ? "100%" : isNonMobile1024 ? "28.421%" : "100%",
                  maxWidth: TRUE
                    ? "100%"
                    : isNonMobile1024
                    ? "28.421%"
                    : "100%",
                }}
              >
                {challenges.length > 0 && !owner && (
                  <div
                    className="rightsideStudentProfile"
                    style={{ width: "100%" }}
                  >
                    <p className="black16 text-start">Engagment Analytics</p>
                    <p
                      className="black14"
                      style={{ marginTop: "1.5rem", fontWeight: "400" }}
                    >
                      Determining the right business strategy is anything
                      Determining the right business strategy is anything
                      determining.
                    </p>
                    <div style={{ marginTop: "3.25rem" }}>
                      <div
                        className="d-flex flex-wrap"
                        style={{ gap: "0.75rem" }}
                      >
                        <div
                          className="shadowa iconcontainer center"
                          style={{ alignItems: "center" }}
                        >
                          <img src={eye} alt="eye icon" />
                        </div>
                        <div>
                          <p
                            className="black14"
                            style={{ color: "#475467", fontWeight: "500" }}
                          >
                            Level of Engagement
                          </p>

                          <p className="black22">
                            {levelofEngagement?.toFixed(2)}%
                          </p>
                          <div
                            className=""
                            style={{
                              width: `100%`,
                              height: "4px",
                              borderRadius: "100px",
                              background: `linear-gradient(to right, #8098F9 0%, #C01048 ${levelofEngagement}%, #DBDFF1 ${levelofEngagement}%)`,
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="d-flex flex-wrap"
                        style={{ gap: "0.75rem", marginTop: "1.5rem" }}
                      >
                        <div
                          className="shadowa iconcontainer center"
                          style={{ alignItems: "center" }}
                        >
                          <img src={uservip} alt="eye icon" />
                        </div>
                        <div>
                          <p
                            className="black14"
                            style={{ color: "#475467", fontWeight: "500" }}
                          >
                            Completed Challenges
                          </p>

                          <p className="black22">{completed}</p>
                          {CompletedDiv(completed)}
                        </div>
                      </div>
                      <div
                        className="d-flex flex-wrap"
                        style={{ gap: "0.75rem", marginTop: "1.5rem" }}
                      >
                        <div
                          className="shadowa iconcontainer center"
                          style={{ alignItems: "center" }}
                        >
                          <img src={square} alt="eye icon" />
                        </div>
                        <div>
                          <p
                            className="black14"
                            style={{ color: "#475467", fontWeight: "500" }}
                          >
                            Application Status
                          </p>
                          <div style={{ marginTop: "0.5rem" }}>
                            {counter > 0 ? (
                              <div className="Applied">Applied</div>
                            ) : (
                              <div className="notApplied">
                                <div
                                  className=""
                                  style={{
                                    background: "#6172F3",
                                    height: "8px",
                                    width: "8px",
                                    borderRadius: "125px",
                                  }}
                                />
                                Not Applied
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {similarUsers?.length > 0 && !owner && (
                  <div
                    className="rightsideStudentProfile"
                    style={{
                      width: "100%",
                      marginTop: "1.25rem",
                    }}
                  >
                    <p className="black16 text-start">Similar Profiles</p>
                    {similarUsers.map((sim, index) => (
                      <div key={index} style={{ marginTop: "1.5rem" }}>
                        <div className="d-flex " style={{ gap: "0.75rem" }}>
                          <img
                            src={sim?.profilePhoto}
                            alt="profile"
                            className="center"
                            height="48px"
                            width="48px"
                            style={{
                              borderRadius: "125px",
                              alignItems: "center",

                              background: "#FFF",
                              padding: "2px",
                            }}
                          />
                          <div>
                            <p className="black14">
                              {sim?.firstName} {sim?.LastName}
                            </p>
                            <p
                              className="gray14400"
                              style={{ marginTop: "0.25rem", fontSize: "12px" }}
                            >
                              {sim?.uni?.name}
                            </p>
                            <div className="d-flex">
                              <p
                                className="pink12hover "
                                style={{ marginTop: "6px" }}
                                onClick={() => {
                                  if (!test) {
                                    navigate(`/dashboard/user/${sim.uniqueId}`);
                                  } else {
                                    navigate(
                                      `/student/dashboard/user/${sim.uniqueId}`
                                    );
                                  }
                                }}
                              >
                                View Profile
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
        {isNonMobile1024 && !recipient && (
          <div style={{ width: isNonMobile1024 ? "28.421%" : "100%" }}>
            {challenges.length > 0 && !owner && (
              <div
                className="rightsideStudentProfile"
                style={{ width: "100%" }}
              >
                <p className="black16 text-start">Engagment Analytics</p>
                <p
                  className="black14"
                  style={{ marginTop: "1.5rem", fontWeight: "400" }}
                >
                  Determining the right business strategy is anything
                </p>
                <div style={{ marginTop: "3.25rem" }}>
                  <div className="d-flex flex-wrap" style={{ gap: "0.75rem" }}>
                    <div
                      className="shadowa iconcontainer center"
                      style={{ alignItems: "center" }}
                    >
                      <img src={eye} alt="eye icon" />
                    </div>
                    <div>
                      <p
                        className="black14"
                        style={{ color: "#475467", fontWeight: "500" }}
                      >
                        Level of Engagement
                      </p>

                      <p className="black22">
                        {levelofEngagement?.toFixed(2)}%
                      </p>
                      <div
                        className=""
                        style={{
                          width: `100%`,
                          height: "4px",
                          borderRadius: "100px",
                          background: `linear-gradient(to right, #8098F9 0%, #C01048 ${levelofEngagement}%, #DBDFF1 ${levelofEngagement}%)`,
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="d-flex flex-wrap"
                    style={{ gap: "0.75rem", marginTop: "1.5rem" }}
                  >
                    <div
                      className="shadowa iconcontainer center"
                      style={{ alignItems: "center" }}
                    >
                      <img src={uservip} alt="eye icon" />
                    </div>
                    <div>
                      <p
                        className="black14"
                        style={{ color: "#475467", fontWeight: "500" }}
                      >
                        Completed Challenges
                      </p>

                      <p className="black22">{completed}</p>
                      {CompletedDiv(completed)}
                    </div>
                  </div>
                  <div
                    className="d-flex flex-wrap"
                    style={{ gap: "0.75rem", marginTop: "1.5rem" }}
                  >
                    <div
                      className="shadowa iconcontainer center"
                      style={{ alignItems: "center" }}
                    >
                      <img src={square} alt="eye icon" />
                    </div>
                    <div>
                      <p
                        className="black14"
                        style={{ color: "#475467", fontWeight: "500" }}
                      >
                        Application Status
                      </p>
                      <div style={{ marginTop: "0.5rem" }}>
                        {counter > 0 ? (
                          <div className="Applied">Applied</div>
                        ) : (
                          <div className="notApplied">
                            <div
                              className=""
                              style={{
                                background: "#6172F3",
                                height: "8px",
                                width: "8px",
                                borderRadius: "125px",
                              }}
                            />
                            Not Applied
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {similarUsers?.length > 0 && !owner && (
              <div
                className="rightsideStudentProfile"
                style={{
                  width: "100%",
                  marginTop: "1.25rem",
                }}
              >
                <p className="black16 text-start">Similar Profiles</p>
                {similarUsers.map((sim, index) => (
                  <div key={index} style={{ marginTop: "1.5rem" }}>
                    <div className="d-flex " style={{ gap: "0.75rem" }}>
                      <img
                        src={sim?.profilePhoto}
                        alt="profile"
                        className="center"
                        height="48px"
                        width="48px"
                        style={{
                          borderRadius: "125px",
                          alignItems: "center",

                          background: "#FFF",
                          padding: "2px",
                        }}
                      />
                      <div>
                        <p className="black14">
                          {sim?.firstName} {sim?.LastName}
                        </p>
                        <p
                          className="gray14400"
                          style={{ marginTop: "0.25rem", fontSize: "12px" }}
                        >
                          {sim?.uni?.name}
                        </p>
                        <div className="d-flex">
                          <p
                            className="pink12hover "
                            style={{ marginTop: "6px" }}
                            onClick={() => {
                              if (!test) {
                                navigate(`/dashboard/user/${sim.uniqueId}`);
                              } else {
                                navigate(
                                  `/student/dashboard/user/${sim.uniqueId}`
                                );
                              }
                            }}
                          >
                            View Profile
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};
export default SecondSection;
