// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FilterTables {
  width: 324px;
  padding: 0px 8px 8px 8px;

  border-radius: 5px;
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
  background: rgba(255, 255, 255, 0.5);
}

@media (max-width: 370px) {
  .FilterTables {
    width: 90vw;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/TalentPool/filter.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,wBAAwB;;EAExB,kBAAkB;EAClB,0BAA0B;EAC1B,2BAA2B;EAC3B,oCAAoC;AACtC;;AAEA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".FilterTables {\n  width: 324px;\n  padding: 0px 8px 8px 8px;\n\n  border-radius: 5px;\n  border-top: 2px solid #fff;\n  border-left: 2px solid #fff;\n  background: rgba(255, 255, 255, 0.5);\n}\n\n@media (max-width: 370px) {\n  .FilterTables {\n    width: 90vw;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
