import { useEffect, useState } from "react";
import upload from "../../assets/images/icons/upload.png";
import { setup, isSupported } from "@loomhq/record-sdk";
import { oembed } from "@loomhq/loom-embed";
import axios from "axios";
import "./step4.css";
import { setStep as set } from "../../state";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import loom from "../../assets/images/loom.svg";
import ReactQuill from "react-quill";

// Correct import
const Step4 = ({
  duration,
  setDuration,
  difficulty,
  setDifficulty,
  tools,
  setTools,
  setStep,
  selectedFile,
  setSelectedFile,
  information,
  setInformation,
  studentInfo,
  setStudentInfo,
  setTaskSteps,
}) => {
  const tool = [
    "PDF Reader",
    "Text Editor",
    "MS Excel",
    "MS Powerpoint",
    "MS Word",
    "Calculator",
    "Webcam",
  ];

  const [selectedTool, setSelectedTool] = useState("");
  const dispatch = useDispatch();
  const [recordedvideo, setRecordedVideo] = useState();
  const handleFileUpload = (e) => {
    const file = e.target.files[0];

    if (file && file.type === "video/mp4") {
      setSelectedFile(file);
    } else {
      toast.error("Please upload a valid MP4 video file.");
    }
  };

  const [isRecording, setIsRecording] = useState(false);

  const [recorder, setRecorder] = useState(false);
  const [isRecordingDone, setIsRecordingDone] = useState(false);
  useEffect(() => {
    dispatch(set({ step: 4 }));
  }, []);

  const handleDrop = (e) => {
    e.preventDefault();

    const file = e.dataTransfer.files[0];

    // Check if the file is an mp4
    if (file && file.type === "video/mp4") {
      setSelectedFile(file);
    } else {
      toast.error("Please drop only MP4 files.");
    }
  };

  const handleAddtools = (tol) => {
    if (!tools.includes(tol)) {
      setTools([...tools, tol]);
    }
  };

  const handleAddtool = (e) => {
    e.preventDefault();
    if (!tools.includes(selectedTool)) {
      setTools([...tools, selectedTool]);
      setSelectedTool("");
    }
  };

  const handleSubmit = () => {
    if (difficulty && selectedFile && information?.length > 0) {
      setStep(6);
      setTaskSteps(0);
      dispatch(set({ step: 5 }));
    } else {
      toast.error("Enter All Details");
    }
  };

  const handleRemove = (ski) => {
    setTools(tools.filter((skill) => skill !== ski));
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
      [{ align: [] }],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "link",
    "image",
    "align",
  ];

  //LOOM
  const [videoHTML, setVideoHTML] = useState("");
  const BUTTON_ID = "dasdafafdsffsadfdadsgdak";
  useEffect(() => {
    async function setupLoom() {
      try {
        const jwsResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/get/loom/key`
        );

        const jws = jwsResponse.data.jws;
        const app_id = jwsResponse.data.App_id;
        const { supported, error } = await isSupported();
        if (!supported) {
          console.warn(`Error setting up Loom: ${error}`);
          return;
        }

        const button = document.getElementById(BUTTON_ID);
        if (!button) {
          console.warn(`Button with id ${BUTTON_ID} not found.`);
          return;
        }

        const { configureButton } = await setup({
          publicAppId: app_id,
          jws,
        });
        const sdkButton = configureButton({
          element: button,
          hooks: {
            onInsertClicked: (shareLink) => {
              console.log("clicked insert");
              console.log(shareLink);
              setSelectedFile(shareLink.embedUrl);
            },
            onStart: () => console.log("start"),
            onCancel: () => console.log("cancelled"),
            onComplete: () => console.log("Completed"),
          },
        });

        sdkButton.on("insert-click", async (video) => {
          const { html } = await oembed(video.sharedUrl, { width: 400 });
          setVideoHTML(html);
        });
      } catch (error) {
        console.error("Error setting up Loom:", error);
      }
    }
    setupLoom();
  }, []);

  //   <>
  //   <button id={BUTTON_ID}>Record</button>
  {
    /* <div dangerouslySetInnerHTML={{ __html: videoHTML }}></div> */
  }
  // </>

  return (
    <div className="" style={{ width: "100%" }}>
      <div className="">
        <p className="BasicInforMationHeading ">Challenge Introduction page</p>
        <p className="UnderBasicHeading" style={{ marginTop: "0.25rem" }}>
          What is this challenge about?
        </p>
        <p className="thumbnailcreate " style={{ marginTop: "2.5rem" }}>
          Upload Introduction Video
        </p>
        <div
          className="col-md-12 col-12  d-flex  p-3 border rounded-3"
          style={{
            minHeight: "300px",
            justifyContent: "center",
            backgroundColor: "#FFF",

            marginTop: "0.75rem",
          }}
        >
          <div
            className="d-flex col-md-12"
            style={{
              flexDirection: "column",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <div className=" col-md-6">
              {!recorder && (
                <div
                  onClick={() => document.getElementById("videoUpload").click()}
                  onDrop={handleDrop}
                  onDragOver={(e) => e.preventDefault()}
                >
                  <div className="d-flex" style={{ justifyContent: "center" }}>
                    {selectedFile ? (
                      <>
                        {selectedFile instanceof File ? (
                          <video
                            controls
                            onClick={(e) => e.stopPropagation()}
                            style={{
                              maxWidth: "100%",
                              height: "auto",
                              cursor: "pointer",
                            }}
                          >
                            <source
                              src={URL.createObjectURL(selectedFile)}
                              type={selectedFile.type}
                            />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <>
                            {selectedFile.includes("null") ? (
                              <img
                                src={upload}
                                alt="upload"
                                height="40px"
                                width="40px"
                              />
                            ) : (
                              <iframe
                                title="Embedded Video"
                                className="img-fluid"
                                src={
                                  selectedFile.includes("http")
                                    ? selectedFile
                                    : `${process.env.REACT_APP_BACKEND_URL}${selectedFile}`
                                }
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                              ></iframe>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <img
                        src={upload}
                        alt="upload"
                        height="40px"
                        width="40px"
                      />
                    )}
                  </div>
                  {console.log(selectedFile)}
                  <div
                    className="d-flex"
                    style={{ gap: "5px", justifyContent: "center" }}
                  >
                    <p
                      className="clicktoupload"
                      style={{
                        marginTop: "15px",
                        cursor: "pointer",
                      }}
                    >
                      {selectedFile
                        ? "Click to change Video"
                        : "Click to upload Video"}
                    </p>

                    <input
                      type="file"
                      id="videoUpload"
                      accept="video/mp4"
                      style={{ display: "none" }}
                      onChange={handleFileUpload}
                    />
                  </div>
                  <p
                    className="belowClicktoupload text-center"
                    style={{ marginTop: "0.25rem" }}
                  >
                    Please use MP4 Format
                  </p>
                </div>
              )}
            </div>

            <div
              className="col-md-6 d-flex flex-wrap"
              style={{
                justifyContent: "center",
                gap: "0.2rem",
                alignItems: "center",
              }}
            >
              <button
                id={BUTTON_ID}
                className="clicktoupload"
                style={{ background: "transparent", border: "none" }}
              >
                or Click here
              </button>
              <p className="black14" style={{ cursor: "auto" }}>
                to Record with
              </p>
              <img src={loom} alt="loom logo" width="53px" height="15px" />
            </div>
          </div>
        </div>

        {/* <p className="timecommitment mt-5">Time Commitment</p>
      <div className="row mt-3">
        <div className="col-md-6 ">
          <p className="expectedduration ">Expected Duration</p>
        </div>
        <div className="col-md-6 d-flex" style={{ gap: "20px" }}>
          <button
            className="btn border rounded-3 secondbuttontext"
            style={
              duration === "45"
                ? { backgroundColor: "#FEA3B4", color: "#FFF" }
                : { backgroundColor: "#FFF" }
            }
            onClick={() => setDuration("45")}
          >
            45 min
          </button>
          <button
            className="btn border rounded-3 secondbuttontext"
            style={
              duration === "60"
                ? { backgroundColor: "#FEA3B4", color: "#FFF" }
                : { backgroundColor: "#FFF" }
            }
            onClick={() => setDuration("60")}
          >
            60 min
          </button>
          <button
            className="btn border rounded-3 secondbuttontext"
            style={
              duration === "120"
                ? { backgroundColor: "#FEA3B4", color: "#FFF" }
                : { backgroundColor: "#FFF" }
            }
            onClick={() => setDuration("120")}
          >
            120 min
          </button>
        </div>
      </div> */}
        <div>
          <p className="thumbnailcreate " style={{ marginTop: "2.5rem" }}>
            Challenge Information
          </p>

          <ReactQuill
            value={information}
            onChange={(value) => setInformation(value)}
            modules={modules}
            formats={formats}
            className="col-md-12  reactquillfontfix whitescrollbar"
            style={{
              backgroundColor: "#FFF",
              marginTop: "0.75rem",
            }}
          />
          <p className="formtext" style={{ marginTop: "1rem" }}>
            {information && information?.replace(/(<([^>]+)>)/gi, "")?.length}{" "}
            Characters
          </p>
        </div>
        <hr style={{ marginTop: "2.5rem", marginBottom: "2.5rem" }} />
        {/* <p className="thumbnailcreate " style={{ fontWeight: "600" }}>
          Student Requirements
        </p>
        <p className="selectaminimum">
          Select a minimum of 5 and up to 12 skills students will learn, apply
          or expand on. <span>Learn more about challenge skills.</span>
        </p> */}
        <p
          className="thumbnailcreate d-flex"
          style={{
            marginTop: "2.5rem",
            alignItems: "center",
            fontWeight: "600",
          }}
        >
          Difficulty Level
        </p>
        <p className="TYPEOFCHALLENGEbelow" style={{ marginTop: "0.5rem" }}>
          {" "}
          Advanced Challenges are only available once you have a create at a
          minimum of <span>1 Basic Challenge</span> and{" "}
          <span>1 Intermediate Challenge</span>
        </p>
        <div className=" " style={{ marginTop: "0.75rem" }}>
          <div className="col-md-6 d-flex flex-wrap" style={{ gap: "20px" }}>
            <button
              className="difficultybuttons"
              style={
                difficulty === "Basic"
                  ? {
                      backgroundColor: "#FFF5F6",
                      color: "#E31B54",
                      border: "1px solid #FFE4E8",
                    }
                  : { backgroundColor: "#FFF" }
              }
              onClick={() => setDifficulty("Basic")}
            >
              Basic
            </button>
            <button
              className="difficultybuttons"
              style={
                difficulty === "Intermediate"
                  ? {
                      backgroundColor: "#FFF5F6",
                      color: "#E31B54",
                      border: "1px solid #FFE4E8",
                    }
                  : { backgroundColor: "#FFF" }
              }
              onClick={() => setDifficulty("Intermediate")}
            >
              Intermediate
            </button>
            <button
              className="difficultybuttons"
              disabled
              style={
                difficulty === "Advanced"
                  ? {
                      backgroundColor: "#FFF5F6",
                      color: "#E31B54",
                      border: "1px solid #FFE4E8",
                    }
                  : { backgroundColor: "#FFF" }
              }
              onClick={() => setDifficulty("Advanced")}
            >
              Advanced
            </button>
          </div>
        </div>
        <p
          className="thumbnailcreate d-flex"
          style={{
            marginTop: "2.5rem",
            alignItems: "center",
            fontWeight: "600",
          }}
        >
          Required Tools
        </p>
        <div className="" style={{ marginTop: "0.75rem" }}>
          <div className="col-md-12 d-flex flex-wrap" style={{ gap: "10px" }}>
            {tool &&
              tool.map((tol, index) => (
                <div
                  className="difficultybuttons"
                  key={index}
                  style={{
                    cursor: "pointer",
                    backgroundColor: tools.find((tl) => tl === tol)
                      ? "#FFF5F6"
                      : "",
                    color: tools.find((tl) => tl === tol) ? "#E31B54" : "",
                    border: tools.find((tl) => tl === tol)
                      ? "1px solid #FFE4E8"
                      : "",
                  }}
                  onClick={() => handleAddtools(tol)}
                >
                  {tol}
                </div>
              ))}
            <form
              onSubmit={(e) => handleAddtool(e)}
              className="col-md-12 col-12"
            >
              <input
                value={selectedTool}
                onChange={(e) => setSelectedTool(e.target.value)}
                className="forminput col-md-12 col-12 mt-2"
                placeholder="Input Required Tools"
              />
            </form>
            {tools &&
              tools.map((ski, i) => (
                <div
                  key={i}
                  className="mt-2  skillsshow d-flex"
                  style={{ cursor: "pointer" }}
                >
                  <p className="skillsshowtext"> {ski}</p>
                  <div onClick={() => handleRemove(ski)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <path
                        d="M9.87484 9.00604L15.5715 3.32867C15.8095 3.08843 15.8095 2.69925 15.5715 2.45901C15.3377 2.21448 14.9516 2.20714 14.7089 2.4426L9.01221 8.11997L3.38879 2.4426C3.2735 2.31966 3.11309 2.25 2.94527 2.25C2.77745 2.25 2.61703 2.31966 2.50174 2.4426C2.29073 2.67471 2.29073 3.03093 2.50174 3.26303L8.12516 8.9322L2.4285 14.6014C2.1905 14.8416 2.1905 15.2308 2.4285 15.471C2.54181 15.588 2.69763 15.6532 2.85982 15.6515C3.02513 15.665 3.18901 15.6119 3.31555 15.5038L9.01221 9.82647L14.7089 15.5695C14.8222 15.6865 14.978 15.7517 15.1402 15.75C15.3022 15.7507 15.4577 15.6857 15.5715 15.5695C15.8095 15.3292 15.8095 14.9401 15.5715 14.6998L9.87484 9.00604Z"
                        fill="#0C111D"
                      />
                    </svg>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="" style={{ marginTop: "2.5rem" }}>
          <p
            className="thumbnailcreate d-flex"
            style={{
              marginTop: "2.5rem",
              alignItems: "center",
              fontWeight: "600",
            }}
          >
            Student Information
          </p>
          <p className="TYPEOFCHALLENGEbelow" style={{ marginTop: "0.5rem" }}>
            CV mandatory is only available for <span>Advanced Challenges</span>
            (difficulty level advanced).
          </p>
          <div
            className="d-flex flex-wrap"
            style={{ marginTop: "0.75rem", gap: "0.75rem" }}
          >
            <button
              className="difficultybuttons"
              style={{
                backgroundColor: "#FFF5F6",
                color: "#E31B54",
                border: "1px solid #FFE4E8",
              }}
            >
              varyance Student Profile
            </button>
            <button
              className="difficultybuttons"
              onClick={() =>
                studentInfo === "CV Optional"
                  ? setStudentInfo("")
                  : setStudentInfo("CV Optional")
              }
              style={{
                backgroundColor:
                  studentInfo === "CV Optional" ? "#FFF5F6" : "#FFF",
                color: studentInfo === "CV Optional" ? "#E31B54" : "",
                border:
                  studentInfo === "CV Optional" ? "1px solid #FFE4E8" : "",
              }}
            >
              CV Optional
            </button>
            <button
              className="difficultybuttons"
              disabled
              style={{
                backgroundColor: "#FFF",
              }}
            >
              CV Mandatory
            </button>
          </div>
        </div>
        <hr
          style={{
            marginTop: "2.5rem",
            marginBottom: "1.25rem",
          }}
        />
        <div
          className="d-flex mb-5 pb-5"
          style={{ justifyContent: "space-between", gap: "10px" }}
        >
          <button className="backbutton" onClick={() => setStep(4)}>
            Back
          </button>
          <button
            className="continuebutton"
            onClick={() => {
              handleSubmit();
            }}
          >
            Start adding tasks
          </button>
        </div>
      </div>
    </div>
  );
};
export default Step4;
