import JobCard from "./JobCard";
import JobPage from "./JobPage";
import Loader from "./Loader";
import { useMediaQuery } from "@mui/material";
const Joblist = ({
  jobs,
  loading,
  selectedJob,
  setSelectedJob,
  handleNumChange,
  setJobNum,
}) => {
  const isNonMobile = useMediaQuery("(min-width:1024px)");
  return (
    <div>
      {loading ? (
        <div className="d-flex" style={{ gap: "1.5rem" }}>
          <Loader />
        </div>
      ) : (
        <>
          {jobs.length > 0 ? (
            <div className="d-flex" style={{ gap: "1.5rem" }}>
              <div
                style={{ width: isNonMobile ? "35.62%" : "100%", gap: "1rem" }}
                className="d-flex flex-column "
              >
                {jobs.map((job, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      setSelectedJob(job);
                      setJobNum(index);
                    }}
                  >
                    <JobCard job={job} />
                  </div>
                ))}
              </div>
              {isNonMobile && (
                <div style={{ width: "64.38%" }}>
                  <JobPage
                    job={selectedJob}
                    handleNumChange={handleNumChange}
                  />
                </div>
              )}
            </div>
          ) : (
            <p className="black16 text-start">No Jobs Found</p>
          )}
        </>
      )}
    </div>
  );
};
export default Joblist;
