import { useNavigate, Outlet, useLocation } from "react-router-dom";
import NewStudentHeader from "./NewHeader";

import "./main.css";
import { useEffect, useState } from "react";

const LatestStudentDashboard = ({ student, setStudent }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname.toLowerCase();
  const [selected, setSelected] = useState("Feed");

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/login");
    }

    if (path === "/student/dashboard") {
      setSelected("Feed");
    } else if (path.includes("/student/dashboard/challenges")) {
      setSelected("Challenges");
    } else if (path.includes("/student/dashboard/jobs")) {
      setSelected("Jobs");
    } else if (path.includes("/student/dashboard/events")) {
      setSelected("Events");
    } else if (path.includes("/student/dashboard/groups")) {
      setSelected("Groups");
    }
  }, [path]);

  return (
    <div
      style={{
        width: "100%",
        minHeight: "100vh",
        background: "#F2F3F7",
        scrollBehavior: "smooth",
      }}
    >
      <NewStudentHeader
        student={student}
        setStudent={setStudent}
        setSelected={setSelected}
        selected={selected}
        navigate={navigate}
      />
      <div style={{ paddingTop: "60px", paddingBottom: "60px" }}>
        <Outlet />
      </div>
    </div>
  );
};

export default LatestStudentDashboard;
