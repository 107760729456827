import companydemo from "../../assets/images/companydemo.png";

const First = ({
  company,
  followers,
  isNonMob768,
  organizer,
  navigate,
  handleFollow,
}) => {
  return (
    <div>
      <div className="CompanyPage-First-First">
        {company && company.img && (
          <div
            className="shadowa center align-items-center"
            style={{
              padding: "2px",
              background: "#FFF",
              borderRadius: "5px",
              height: "48px",
              width: "48px",
            }}
          >
            <img
              src={company.img}
              alt="card-bg"
              style={{
                maxHeight: "44px",
                maxWidth: "44px",
                height: "44px",
                borderRadius: "3px",
                objectFit: "cover",
                width: "100%",
              }}
            />
          </div>
        )}
        <div
          style={{
            marginTop: "2.375rem",
            gap: "2.5rem",
            flexDirection: isNonMob768 ? "row" : "column",
          }}
          className="space"
        >
          <div>
            <p className="gray95020400" style={{ fontSize: "1.875rem" }}>
              {company?.title}
            </p>
            <p
              className="gray80014400"
              style={{ marginTop: "0.75rem", fontWeight: 500 }}
            >
              {company?.tagline}
            </p>
            <div
              className="d-flex flex-wrap"
              style={{ marginTop: "1.5rem", gap: "1rem" }}
            >
              <div className="newdashboardsidechips">{company?.industry}</div>
              <div className="newdashboardsidechips">
                {company?.size} Employees
              </div>
            </div>
          </div>
          <div
            className={` ${
              isNonMob768 && "d-flex justify-content-end flex-column"
            }`}
            style={{ width: isNonMob768 ? "40%" : "100%" }}
          >
            <div>
              {followers.length > 0 && (
                <div
                  className={`${
                    isNonMob768 && "d-flex flex-column align-items-end"
                  }`}
                >
                  <div
                    className="d-flex align-items-center"
                    style={{ width: `${36 * followers.length}px` }}
                  >
                    {followers.map((speaker, index) => (
                      <img
                        className="upcoming-speaker-img"
                        src={speaker.img}
                        key={index}
                        alt="speaker"
                        height={"36px"}
                        width={"36px"}
                        style={{
                          position: "relative",
                          left: `-${index * 10}px`, // Adjust 20px according to half of the image width (40px / 2)
                        }}
                      />
                    ))}
                  </div>
                </div>
              )}
              <p
                style={{ marginTop: "0.5rem", fontWeight: 500 }}
                className={`gray80014400  ${
                  isNonMob768 ? "text-end" : "text-start"
                }`}
              >
                {company?.followers?.length} Follower
                {company?.followers?.length > 1 && "s"}
              </p>
              <div
                className="d-flex   "
                style={{
                  gap: "1rem",
                  marginTop: "1rem",
                  height: "40px",
                  justifyContent: isNonMob768 ? "end" : "start",
                }}
              >
                {organizer?.company === company?.uniqueId ? (
                  <>
                    <button
                      className="backbutton"
                      style={{
                        color: company?.color ? company?.color : "#E31b54",
                        borderColor: company?.color
                          ? company?.color
                          : "#E31b54",
                      }}
                      onClick={() => {
                        navigate("/dashboard/Team");
                      }}
                    >
                      Invite Team
                    </button>
                    {organizer?.email === company?.createdBy && (
                      <button
                        className="continuebutton"
                        style={{
                          background: company?.color
                            ? company?.color
                            : "#E31b54",
                        }}
                        onClick={() => {
                          navigate(`/company/edit/${company?.uniqueId}`);
                        }}
                      >
                        Edit
                      </button>
                    )}
                  </>
                ) : (
                  <>
                    <a
                      className="backbutton"
                      style={{
                        color: company?.color ? company?.color : "#E31b54",
                        borderColor: company?.color
                          ? company?.color
                          : "#E31b54",
                        textDecoration: "none",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        document
                          .getElementById("Details")
                          .scrollIntoView({ behavior: "smooth" });
                      }}
                    >
                      Learn More
                    </a>
                    <button
                      className="backbutton"
                      style={{
                        color: company?.color ? company?.color : "#E31b54",
                        borderColor: company?.color
                          ? company?.color
                          : "#E31b54",
                      }}
                      onClick={() => {
                        handleFollow(organizer?.uniqueId, company?.uniqueId);
                      }}
                    >
                      {company?.followers?.some(
                        (fol) => fol.userid === organizer?.uniqueId
                      )
                        ? "Unfollow"
                        : "Follow"}
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <img
        src={company?.banner ? company?.banner : companydemo}
        alt="banner"
        style={{
          width: "100%",
          borderRadius: "0px 0px 8px 8px",
          objectFit: "cover",
          height: "350px",
          maxHeight: "350px",
          minHeight: "350px",
        }}
      />
    </div>
  );
};

export default First;
