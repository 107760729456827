import { useMediaQuery } from "@mui/material";
import "./Document.css"; // Import CSS file for styling
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
const Document = ({ doc1, doc2, doc3, axios }) => {
  const isLargeScreen = useMediaQuery("(min-width: 1440px)");
  const isMediumScreen = useMediaQuery("(min-width: 1024px)");
  const isSmallScreen = useMediaQuery("(min-width: 768px)");
  const isMobile = useMediaQuery("(max-width: 450px)");
  let columns = 2; // Default to 1 column for mobile screens
  if (isLargeScreen) {
    columns = 4;
  } else if (isMediumScreen) {
    columns = 2;
  } else if (isSmallScreen) {
    columns = 3;
  } else if (isMobile) {
    columns = 1;
  }

  const truncateString = (str, maxLength) => {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + "...";
    } else {
      return str;
    }
  };

  const handleDownload = (filename, originalname) => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/download/${filename}`, {
        responseType: "blob",
      })
      .then((response) => {
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = originalname;
        link.click();
      })
      .catch((error) => {
        console.error("Error downloading document:", error);
      });
  };

  const FileType = (filename) => {
    const splitFileName = filename.split(".");
    const fileExtension = splitFileName[splitFileName.length - 1];
    return fileExtension.toUpperCase();
  };

  const FileColour = (filename) => {
    const splitFileName = filename.split(".");
    const fileExtension = splitFileName[splitFileName.length - 1];
    if (fileExtension.toUpperCase() === "TXT") {
      return "#344054";
    } else if (fileExtension.toUpperCase() === "PPT") {
      return "#E62E05";
    } else if (fileExtension.toUpperCase() === "DOCX") {
      return "#155EEF";
    } else if (fileExtension.toUpperCase() === "XLSX") {
      return "#099250";
    } else {
      return "#344054";
    }
  };

  const FileBackground = (filename) => {
    const splitFileName = filename.split(".");
    const fileExtension = splitFileName[splitFileName.length - 1];
    if (fileExtension.toUpperCase() === "TXT") {
      return "rgba(52, 64, 84, 0.05)";
    } else if (fileExtension.toUpperCase() === "PPT") {
      return "rgba(230, 46, 5, 0.05)";
    } else if (fileExtension.toUpperCase() === "DOCX") {
      return "rgba(21, 94, 239, 0.05)";
    } else if (fileExtension.toUpperCase() === "XLSX") {
      return "rgba(9, 146, 80, 0.05)";
    } else {
      return "rgba(52, 64, 84, 0.05)";
    }
  };
  return (
    <div className={`grid-container columns-${columns}`}>
      {doc1 &&
        doc1.length > 0 &&
        doc1.map((item, index) => (
          <div
            key={index}
            className="grid-item shadowa"
            style={{
              background: FileBackground(
                item?.name ? item?.name : item?.originalname
              ),
            }}
          >
            <div className="d-flex" style={{ flexDirection: "column" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="40"
                viewBox="0 0 32 40"
                fill="none"
              >
                <path
                  d="M0 4C0 1.79086 1.79086 0 4 0H20L32 12V36C32 38.2091 30.2091 40 28 40H4C1.79086 40 0 38.2091 0 36V4Z"
                  fill={FileColour(
                    item?.name ? item?.name : item?.originalname
                  )}
                />
                <path
                  opacity="0.3"
                  d="M20 0L32 12H24C21.7909 12 20 10.2091 20 8V0Z"
                  fill="white"
                />
                <text
                  x="15%"
                  y="70%"
                  dominantBaseline="middle"
                  fill="white"
                  className="black12"
                  style={{ fontSize: "9px", fontWeight: "700" }}
                >
                  {FileType(item?.name ? item?.name : item?.originalname)}
                </text>
              </svg>

              <p className="black14" style={{ marginTop: "0.75rem" }}>
                {" "}
                {truncateString(
                  item?.name ? item?.name : item?.originalname,
                  70
                )}
              </p>
              <button
                className="pink16 d-flex hovercolorback p-0"
                style={{
                  gap: "0.75rem",
                  fontSize: "14px",
                  marginTop: "0.75rem",
                  background: "transparent",
                  border: "none",
                }}
                onClick={() =>
                  handleDownload(
                    item?.name ? item?.name : item?.filename,
                    item?.name ? item?.name : item.originalname
                  )
                }
              >
                Download File <NavigateNextIcon />
              </button>
            </div>
          </div>
        ))}

      {doc2 &&
        doc2.length > 0 &&
        doc2.map((item, index) => (
          <div
            key={index}
            className="grid-item shadowa"
            style={{
              background: FileBackground(
                item?.name ? item?.name : item?.originalname
              ),
            }}
          >
            <div className="d-flex" style={{ flexDirection: "column" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="40"
                viewBox="0 0 32 40"
                fill="none"
              >
                <path
                  d="M0 4C0 1.79086 1.79086 0 4 0H20L32 12V36C32 38.2091 30.2091 40 28 40H4C1.79086 40 0 38.2091 0 36V4Z"
                  fill={FileColour(
                    item?.name ? item?.name : item?.originalname
                  )}
                />
                <path
                  opacity="0.3"
                  d="M20 0L32 12H24C21.7909 12 20 10.2091 20 8V0Z"
                  fill="white"
                />
                <text
                  x="15%"
                  y="70%"
                  dominantBaseline="middle"
                  fill="white"
                  className="black12"
                  style={{ fontSize: "9px", fontWeight: "700" }}
                >
                  {FileType(item?.name ? item?.name : item?.originalname)}
                </text>
              </svg>

              <p className="black14" style={{ marginTop: "0.75rem" }}>
                {truncateString(
                  item?.name ? item?.name : item?.originalname,
                  70
                )}
              </p>
              <button
                className="pink16 d-flex hovercolorback p-0"
                style={{
                  gap: "0.75rem",
                  fontSize: "14px",
                  marginTop: "0.75rem",
                  background: "transparent",
                  border: "none",
                }}
                onClick={() =>
                  handleDownload(
                    item.name ? item?.name : item?.filename,
                    item.name ? item?.name : item.originalname
                  )
                }
              >
                Download File <NavigateNextIcon />
              </button>
            </div>
          </div>
        ))}
    </div>
  );
};

export default Document;
