// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/newregisterbackground.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.backgrounddashboard {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-size: cover;

  margin: 0;
  min-height: 100vh;
}
`, "",{"version":3,"sources":["webpack://./src/pages/StudentDashboard/dashboard.css"],"names":[],"mappings":"AAAA;EACE,mDAA8D;EAC9D,4BAA4B;EAC5B,sBAAsB;;EAEtB,SAAS;EACT,iBAAiB;AACnB","sourcesContent":[".backgrounddashboard {\n  background: url(../../assets/images/newregisterbackground.png);\n  background-repeat: no-repeat;\n  background-size: cover;\n\n  margin: 0;\n  min-height: 100vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
