import React, { useState } from "react";
import { useMediaQuery } from "@mui/material";
import finance from "../../assets/images/icons/Finance.png";
import fourthCard from "../../assets/images/fourthCard.png";
import fourthCard2 from "../../assets/images/fourthcard2.png";
import Slider from "react-slick";
import fourthCard11 from "../../assets/images/fourthCard11.png";
import fourthCard22 from "../../assets/images/fourthCard22.png";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
const FourthSection = () => {
  const isNonMobile = useMediaQuery("(min-width: 768px)");
  const isNonMobile450 = useMediaQuery("(min-width: 450px)");
  const [MouseHover, setStartHover] = useState(false);
  const [selectedImage, setSelectedImage] = useState([fourthCard, fourthCard2]);

  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 100,
    slidesToShow: 1,

    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "1%",
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          dots: false,
          arrows: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className="Prev_arrow p-2 rounded-5 shadowa"
        style={{
          ...style,
          left: "0",
          zIndex: 1,
          position: "absolute",
          top: "105%",
          left: "60%",
          transform: "translateY(-50%)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          backgroundColor: "#FFF",
        }}
        onClick={onClick}
      >
        <MdKeyboardArrowLeft size={24} />
      </div>
    );
  }
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className="Next_arrow p-2 rounded-5 shadowa "
        style={{
          ...style,
          right: "0",
          zIndex: 1,
          position: "absolute",
          top: "105%",
          right: "10%",
          transform: "translateY(-50%)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          backgroundColor: "#FFF",
        }}
        onClick={onClick}
      >
        <MdKeyboardArrowRight size={24} />
      </div>
    );
  }

  const skills = [
    "Company Analysis",
    "Data Interpretation",
    "DFC Analysis",
    "M&A Screening",
    "M&A Process Understanding",
  ];
  const HandleImageNext = () => {
    if (selectedImage[0] === fourthCard) {
      setSelectedImage([fourthCard11, fourthCard2]);
    } else if (selectedImage[0] === fourthCard11) {
      setSelectedImage([fourthCard22, fourthCard2]);
    }
  };
  const HandleImagePrev = () => {
    if (selectedImage[0] === fourthCard22) {
      setSelectedImage([fourthCard11, fourthCard2]);
    } else if (selectedImage[0] === fourthCard11) {
      setSelectedImage([fourthCard, fourthCard2]);
    }
  };
  return (
    <div
      className="d-flex marginTOpFourthCompany"
      style={{ justifyContent: "center", position: "relative" }}
    >
      <div className="headerCompany">
        <div
          className="d-flex"
          style={{
            flexDirection: isNonMobile ? "row" : "column",
            justifyContent: "space-between",
            gap: "3.806rem",
          }}
        >
          <div
            style={{
              width: isNonMobile ? "35%" : "100%",
              maxWidth: isNonMobile ? "" : "402px",
              minWidth: "330px",
            }}
          >
            <Slider
              {...settings}
              className="  pt-0 mt-0 shadowa"
              style={{ borderRadius: "8px" }}
            >
              <img
                src={fourthCard}
                className="img-fluid imageborderremove"
                style={{ height: "100%" }}
                alt="company home"
              />{" "}
              <img
                src={fourthCard11}
                className="img-fluid imageborderremove"
                style={{ height: "100%" }}
                alt="company home"
              />{" "}
              <img
                src={fourthCard22}
                className="img-fluid imageborderremove"
                style={{ height: "100%" }}
                alt="company home"
              />
            </Slider>
          </div>
          <div style={{ width: isNonMobile ? "60%" : "100%" }}>
            <p className="sparkInterest">
              Get in <span> Touch</span>
            </p>
            <p className="underSparkInterest">
              Build your <span className="bluespans"> talent pool</span> of
              curious and motivated <span className="blackspans">students</span>{" "}
              and accompany them along
              <span className="pinkspans"> their journey.</span>
            </p>

            <p className="engageEarly">
              Get points for different skills learned, showcase your skills to
              future employers and receive badges for outstanding achievements
              in your chosen career field.
            </p>
            <div
              className="d-flex flex-wrap "
              style={{
                gap: "0.625rem 0.5rem",

                marginTop: "1rem",
              }}
            >
              <div className="skillswrapper">
                <img src={finance} alt="finance" height="17px" width="17px" />
                Finance <span>Top 5%</span>
              </div>
              {skills.map((ski, index) => (
                <div className="skillswrapper " style={{ background: "#FFF" }}>
                  {ski}
                </div>
              ))}
            </div>
            <button
              className="btn startonboard p-0 d-flex"
              style={{ gap: "1rem" }}
              onMouseEnter={() => setStartHover(true)}
              onMouseLeave={() => setStartHover(false)}
            >
              Start Onboarding
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M20.05 11.47L13.05 4.47C12.8571 4.28243 12.5787 4.21204 12.3198 4.28533C12.0609 4.35863 11.8608 4.56447 11.7948 4.82533C11.7289 5.08619 11.8071 5.36243 12 5.55L17.71 11.25H4.47998C4.06577 11.25 3.72998 11.5858 3.72998 12C3.72998 12.4142 4.06577 12.75 4.47998 12.75H17.7L12 18.45C11.857 18.5893 11.7764 18.7804 11.7764 18.98C11.7764 19.1796 11.857 19.3707 12 19.51C12.1378 19.6546 12.3302 19.7345 12.53 19.73C12.729 19.7309 12.9201 19.6516 13.06 19.51L20.06 12.51C20.3524 12.2172 20.3524 11.7428 20.06 11.45L20.05 11.47Z"
                  fill={MouseHover ? "#A11043" : "#E31B54"}
                />
              </svg>
            </button>
          </div>
        </div>
        <div
          className="lastSection d-flex"
          style={{
            justifyContent: isNonMobile ? "space-between" : "center",
            flexDirection: isNonMobile ? "row" : "column",
          }}
        >
          <p className={`startjourney ${isNonMobile && "section"}`}>
            Start your journey today and explore your future career.
          </p>
          <div
            className="d-flex "
            style={{
              justifyContent: "center",
              alignItems: "center",
              marginTop: isNonMobile ? "" : "1rem",
            }}
          >
            <div
              className={`${isNonMobile450 ? "d-flex" : ""} col-12`}
              style={{
                justifyContent: "center",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <button
                className={`backbutton ${isNonMobile450 ? "" : "col-12"}`}
              >
                Book a demo
              </button>
              <button
                className={`continuebutton  ${
                  isNonMobile450 ? "" : "col-12 mt-3"
                }`}
              >
                Create Company Page
              </button>
            </div>
          </div>
        </div>
        <hr className="horiline " />
      </div>
    </div>
  );
};

export default FourthSection;
