// import React, { useState } from "react";
// import Modal from "react-modal";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

// const PostMedia = ({ media }) => {
//   const [modalIsOpen, setModalIsOpen] = useState(false);
//   const [currentSlide, setCurrentSlide] = useState(0);

//   const openModal = (index) => {
//     setCurrentSlide(index);
//     setModalIsOpen(true);
//     setCurrentSlide(index);
//   };

//   const closeModal = () => {
//     setModalIsOpen(false);
//   };

//   const isVideo = (url) => {
//     return url.match(/\.(mp4|webm|ogg)$/) !== null;
//   };

//   const settings = {
//     initialSlide: currentSlide,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     nextArrow: <div className="slick-next">→</div>,
//     prevArrow: <div className="slick-prev">←</div>,
//   };

//   return (
//     <div className="post-media-container">
//       <div className="media-thumbnails">
//         {media.map((item, index) => (
//           <div
//             key={index}
//             className="media-thumbnail"
//             onClick={() => openModal(index)}
//           >
//             {isVideo(item) ? (
//               <video className="media-video" src={item} />
//             ) : (
//               <img src={item} alt={`media-${index}`} className="media-img" />
//             )}
//           </div>
//         ))}
//       </div>
//       <Modal
//         isOpen={modalIsOpen}
//         onRequestClose={closeModal}
//         contentLabel="Media Modal"
//         className="media-modal"
//         overlayClassName="media-overlay"
//       >
//         <Slider {...settings}>
//           {media.map((item, index) => (
//             <div key={index} className="slide-item">
//               {isVideo(item) ? (
//                 <video className=" img-fluid" src={item} controls autoPlay />
//               ) : (
//                 <img
//                   src={item}
//                   alt={`media-${index}`}
//                   className="media-modal-img"
//                 />
//               )}
//             </div>
//           ))}
//         </Slider>
//       </Modal>
//     </div>
//   );
// };

// export default PostMedia;
