import { useEffect, useState } from "react";
import "./main.css";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import axios from "axios";
import PanoramaFishEyeRoundedIcon from "@mui/icons-material/PanoramaFishEyeRounded";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setProgress } from "../../state";
import { useMediaQuery } from "@mui/material";
import challenge from "../../assets/images/progressChallenge.png";
import job from "../../assets/images/progressJobs.png";
import team from "../../assets/images/progressTeam.png";
import event from "../../assets/images/progressEvents.png";
const Progress = () => {
  const laststep = 4;
  const dispatch = useDispatch();
  const isNonMobile1024 = useMediaQuery("(min-width:1020px)");

  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const id = useSelector((state) => state.uniqueId);
  const navigate = useNavigate();
  const progressa = useSelector((state) => state.progress);
  const [counts, setCounts] = useState(progressa ?? null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!counts) {
          setLoading(true);
        }
        const userInfoResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/getUserInfo/uniqueId/progress`,
          { email: id }
        );

        if (userInfoResponse.data.message === "Found") {
          setUser(userInfoResponse.data.user);
          setCounts(userInfoResponse.data.progress);
          console.log(userInfoResponse.data.progress);
          dispatch(setProgress({ progress: userInfoResponse.data.progress }));
        } else {
          navigate("/company/register");
          return;
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const [stepscontent, setStepsContent] = useState([
    {
      title: "Create your first challenge",
      text: "Engage top talent with immersive experiences by creating your first challenge. These virtual case studies allow students to dive deep into your employer brand, solving real-world problems and showcasing their skills. Stand out by providing a hands-on glimpse into your company's work culture and values.",
      button: "Create Challenge",
      function: () => {
        navigate("/dashboard/challenges");
      },
      done: false,
    },

    {
      title: "Post your first job post",
      text: "Attract future stars by posting your first job opportunity. Whether it's a full-time position or an internship, our platform makes it easy to connect with driven students looking to launch their careers. Provide detailed job descriptions, desired qualifications, and key responsibilities to find the perfect fit for your team.",
      button: "Post Job",
      function: () => {
        navigate("/dashboard/Jobposts");
      },
      done: false,
    },
    {
      title: "Invite team members",
      text: "Collaborate efficiently by inviting your team to join the platform. Assign roles, set permissions, and work together to enhance your recruiting efforts. By bringing your team into the fold, you can streamline the process, ensuring everyone has the access they need to contribute effectively.",
      button: "Invite teammates",
      function: () => {
        navigate("/dashboard/team");
      },
      done: false,
    },
    {
      title: "Plan your first event",
      text: "Connect with students through engaging events. Whether it's a Q&A session, an online workshop, or a physical recruiting event, our platform helps you plan and promote your first event seamlessly. Share your company’s story, answer questions, and build meaningful connections with prospective candidates.",
      button: "Plan Event",
      function: () => {
        navigate("/dashboard/events");
      },
      done: false,
    },
  ]);

  const [step, setStep] = useState(1);

  useEffect(() => {
    if (counts) {
      const updatedStepsContent = stepscontent.map((step, index) => {
        if (
          (index === 0 && counts.countChallenges > 0) ||
          (index === 1 && counts.countJobs > 0) ||
          (index === 2 && counts.countInvite > 0) ||
          (index === 3 && counts.countEvent > 0)
        ) {
          return { ...step, done: true };
        }
        return step;
      });
      setStepsContent(updatedStepsContent);
    }
  }, [counts]);

  useEffect(() => {
    let check = 0;
    stepscontent.map((a) => {
      if (a.done === true) {
        check += 1;
      }
    });
    setStep(check);
    setShow(stepscontent.findIndex((step) => !step.done));
  }, [stepscontent]);

  const [show, setShow] = useState(-1);

  const radius = 20;
  const stroke = 4;
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const progress = (step / laststep) * circumference;

  return (
    <div className="paddingmaincontent mb-5 pb-3">
      <div
        className="d-flex align-items-center col-md-12 col-12"
        style={{ gap: "0.5rem" }}
      >
        <svg height={radius * 2} width={radius * 2} className="progress-circle">
          <circle
            stroke="#e6e6e6"
            fill="transparent"
            strokeWidth={stroke}
            r={normalizedRadius}
            cx={radius}
            cy={radius}
          />
          <circle
            stroke="#4caf50"
            fill="transparent"
            strokeWidth={stroke}
            strokeDasharray={circumference + " " + circumference}
            style={{ strokeDashoffset: circumference - progress }}
            r={normalizedRadius}
            cx={radius}
            cy={radius}
          />
        </svg>
        <p className="f16gray700">Get set up</p>
        <FiberManualRecordIcon
          style={{ height: "4px", width: "4px", color: "#667085" }}
        />
        <p className="f16gray700">
          {step}/{laststep} steps
        </p>
      </div>
      {loading ? (
        <div className="center align-items-center w-100 h-100">
          <div className="loader"></div>
        </div>
      ) : (
        <>
          <div
            style={{ marginTop: "30px", height: "fixed" }}
            className={`progressbackground d-flex gap-5 ${
              isNonMobile1024 ? "flex-row" : "flex-column"
            }`}
          >
            <div style={{ width: isNonMobile1024 ? "50%" : "100%" }}>
              <div style={{ marginTop: "2.5rem" }} className="col-md-12 col-12">
                <div
                  className="d-flex flex-column col-md-12 col-12"
                  style={{ gap: "1rem" }}
                >
                  {stepscontent.map((s, index) => (
                    <div
                      className="d-flex transition col-md-12 col-12"
                      key={index}
                      style={{
                        gap: "1rem",
                        cursor: !s.done ? "pointer" : "auto",
                      }}
                      onClick={() => {
                        if (!s.done) {
                          setShow(index);
                        }
                      }}
                    >
                      {s.done ? (
                        <CheckCircleRoundedIcon
                          style={{
                            color: s.done ? "#D0D5DD" : "#344054",
                            height: "20px",
                            width: "20px",
                          }}
                        />
                      ) : (
                        <PanoramaFishEyeRoundedIcon
                          style={{
                            height: "20px",
                            width: "20px",
                            color: s.done ? "#D0D5DD" : "#344054",
                          }}
                        />
                      )}
                      <div className="w-100">
                        <div className="space w-100">
                          <p
                            className="f14gray700"
                            style={{
                              color: s.done ? "#98A2B3" : "",
                              textDecoration: s.done ? "line-through" : "",
                            }}
                          >
                            {s.title}
                          </p>
                          {index !== show && !s.done && (
                            <>
                              <KeyboardArrowRightRoundedIcon
                                style={{ color: "#1D2939" }}
                              />
                            </>
                          )}
                        </div>
                        <div
                          style={{
                            marginTop: "0.75rem",

                            maxHeight:
                              show !== index
                                ? "0px"
                                : isNonMobile1024
                                ? "200px"
                                : "800px",
                            opacity: show !== index ? "0" : "1",
                            overflow: "hidden",
                          }}
                        >
                          <p
                            className="f14gray700"
                            style={{
                              fontWeight: 400,
                            }}
                          >
                            {s.text}
                          </p>
                          <button
                            onClick={() => {
                              s.function();
                            }}
                            className="continuebutton"
                            style={{ marginTop: "0.75rem", maxHeight: "36px" }}
                          >
                            {s.button}
                          </button>
                        </div>
                        <hr
                          style={{ marginTop: "1rem", marginBottom: "0rem" }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {isNonMobile1024 && (
              <div
                style={{ width: "48%" }}
                className="center align-items-center"
              >
                <div>
                  <img
                    src={
                      show === 0
                        ? challenge
                        : show === 1
                        ? job
                        : show === 2
                        ? team
                        : show === 3
                        ? event
                        : challenge
                    }
                    alt="img"
                    className="img-fluid shadowa"
                  />
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};
export default Progress;
