// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#Step8Dk39 {
  width: 100%;
}
#Step8Dk39 .stepWrapper {
  text-align: left;
}
#Step8Dk39 .section_title {
  width: 70%;
}
#Step8Dk39 .section_title h3 {
  color: #101828;
}
#Step8Dk39 .section_title p {
  font-family: sans-serif;
  font-size: 20px;
}
@media only screen and (max-width: 992px) {
  #Step8Dk39 .section_title {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/StudentRegister/RegisterationSteps/step8/style.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AACE;EACE,gBAAA;AACJ;AAEE;EAIE,UAAA;AAHJ;AAAI;EACE,cAAA;AAEN;AACI;EACE,uBAAA;EACA,eAAA;AACN;AA4CE;EACE;IACE,WAAA;EA1CJ;AACF","sourcesContent":["#Step8Dk39 {\n  width: 100%;\n\n  .stepWrapper {\n    text-align: left;\n  }\n\n  .section_title {\n    h3 {\n      color: #101828; //mostlyused\n    }\n    width: 70%;\n    p {\n      font-family: sans-serif;\n      font-size: 20px;\n    }\n  }\n  // .otp_wrapper {\n  //   margin-top: 20px;\n  //   .Seperator {\n  //     width: 40px;\n  //     @media only screen and (max-width: 768px) {\n  //       width: 10px;\n  //     }\n  //   }\n  //   .OTPInput {\n  //     height: 150px;\n  //     width: 110px !important;\n  //     border: none;\n  //     outline: none;\n  //     box-shadow: -2px 10px 29px -3px rgba(157, 157, 157, 0.75);\n  //     font-size: 33px;\n  //     @media only screen and (max-width: 768px) {\n  //       height: 80px;\n  //       width: 60px !important;\n  //     }\n  //   }\n  //   .TermsCHck {\n  //     margin-top: 15px;\n  //     display: flex;\n  //     gap: 10px;\n  //     p {\n  //       font-size: 12px;\n  //       font-family: sans-serif;\n  //       span {\n  //         font-weight: 550;\n  //         font-size: 12px;\n  //         font-family: sans-serif;\n  //       }\n  //     }\n  //     .CheckBoxInput {\n  //       border: none;\n  //       outline: none;\n  //       background-color: #fff;\n  //       border-radius: 5px;\n  //     }\n  //   }\n  // }\n\n  @media only screen and (max-width: 992px) {\n    .section_title {\n      width: 100%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
