import { useState, useEffect } from "react";
import CreateEventHeader from "./Header";
import "./main.css";
import { motion, useAnimate } from "framer-motion";
import { Country, City } from "country-state-city";
import { useSelector } from "react-redux";
import Step1 from "./Step1";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Step2 from "./Step2";
import axios from "axios";
import ReactQuill from "react-quill";
import { toast } from "react-toastify";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";

const CreateEvent = () => {
  const [step, setSteps] = useState(1);
  const com = useSelector((state) => state.company);
  const id = useSelector((state) => state.uniqueId);
  const [challenges, setChallenges] = useState([]);
  const [jobposts, setJobposts] = useState([]);
  const [loading, setLoading] = useState(false);

  const [type, setType] = useState("varyance Livestream");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [location, setLocation] = useState({
    Country: "",
    City: "",
  });
  const [user, setUser] = useState(null);
  const [company, setCompany] = useState(null);
  const [feature, setFeature] = useState(false);

  const [search, setSearch] = useState("");
  const [search2, setSearch2] = useState("");

  const [selectedItem, setSelectedItem] = useState([]);

  const [duration, setDuration] = useState("");
  const [datetime, setDateTime] = useState("");
  const [topic, setTopic] = useState("");
  const [topic2, setTopic2] = useState("");
  const [language, setLanguage] = useState("");
  const [schoolYear, setSchoolYear] = useState([]);
  const [relevantCourses, setRelevantCourses] = useState([]);
  const [targetGroups, setTargetGroups] = useState([]);
  const [settings, setSettings] = useState({
    StudentProfile: false,
    CV: false,
    CVimp: false,
  });
  const [documents, setDocuments] = useState([]);

  const navigate = useNavigate();
  const [languages, setLanguages] = useState([]);
  const allCountries = Country.getAllCountries();
  const [users, setUsers] = useState([]);
  const [list, setList] = useState([...users]);
  const [list2, setList2] = useState([...users]);
  const [addedUsers, setAddedUsers] = useState([]);
  const [addedSpeakers, setAddedSpeakers] = useState([]);
  const [addedJobChal, setAddedJobChal] = useState([]);

  useEffect(() => {
    if (company?.country[0]?.Country?.name) {
      setLocation({
        Country: company.country[0].Country.name,
        City: "",
      });
    }
  }, [company]);

  const handleDraft = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("feature", feature);
    formData.append("owner", user.uniqueId);
    formData.append("company", JSON.stringify(company));
    formData.append("type", type);
    formData.append("title", title);
    formData.append("description", description);
    formData.append("location", JSON.stringify(location));
    formData.append("datetime", datetime);
    formData.append("duration", duration);
    formData.append("topic", topic);
    formData.append("language", language);
    formData.append("selectedItem", JSON.stringify(selectedItem));
    formData.append("schoolYear", JSON.stringify(schoolYear));
    formData.append("relevantCourses", JSON.stringify(relevantCourses));
    formData.append("targetGroups", JSON.stringify(targetGroups));
    formData.append("settings", JSON.stringify(settings));

    // Append documents if any
    documents.forEach((doc, index) => {
      formData.append("documents", doc); // Ensure 'documents' matches the backend field name
    });

    formData.append("addedUsers", JSON.stringify(addedUsers));
    formData.append("addedSpeakers", JSON.stringify(addedSpeakers));
    formData.append("addedJobChal", JSON.stringify(addedJobChal));
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/createEvent/draft`,
        formData
      );
      if (response.data.msg === "success") {
        toast.success("Event created successfully!");
        navigate("/dashboard/events");
      } else {
        toast.error("Error creating event.");
      }
    } catch (error) {
      console.error("There was an error creating the event!", error);
      toast.error("There was an error creating the event.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setList(users);
    setList2(users);
  }, [users]);

  useEffect(() => {
    if (company && company?.uniqueId) {
      axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/api/get/company/users`, {
          uniqueId: company?.uniqueId,
          userId: user?.uniqueId,
        })
        .then((res) => {
          if (res.data.msg === "success") {
            setUsers(res.data.filtered);
          }
        });
    }
  }, [company]);

  useEffect(() => {
    setList(
      users.filter((user) => {
        return `${user.firstName.toLowerCase()} ${user.lastName.toLowerCase()}`.includes(
          search.toLowerCase()
        );
      })
    );
  }, [search, users]);

  useEffect(() => {
    setList2(
      users.filter((user) => {
        return `${user.firstName.toLowerCase()} ${user.lastName.toLowerCase()}`.includes(
          search2.toLowerCase()
        );
      })
    );
  }, [search2, users]);

  useEffect(() => {
    const getChallenges = async () => {
      try {
        const companyInfo = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/getCompanyInfo`,
          { id: com }
        );
        setCompany(companyInfo.data);
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/get/company/challenges`,
          {
            uniqueId: companyInfo.data.uniqueId,
          }
        );
        if (response.data.msg === "success") {
          setChallenges(response.data.challenges);
        }
        const userInfoResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/getUserInfo/uniqueId`,
          { email: id }
        );

        const responsea = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/get/company/jobs`,
          {
            uniqueId: companyInfo.data.uniqueId,
          }
        );
        if (responsea.data.msg === "success") {
          setJobposts(responsea.data.challenges);
        } else {
          setJobposts([]);
        }

        if (userInfoResponse.data.message === "Found") {
          setUser(userInfoResponse.data.user);
        } else {
          navigate("/company/register");
          return;
        }
      } catch (error) {
        console.error(error);
      }
    };
    if (com) {
      getChallenges();
    }
  }, [com]);

  const levelofstudylist = [
    "All students",
    "First Year",
    "2nd Year",
    "3rd Year",
    "Bachelor",
    "Masters Program",
    "PHD",
    "State Examination",
    "MBA",
    "Doctorate",
    "Postdoctoral Studies",
  ];

  const options = [
    "All Courses",
    "Humanity",
    "Business",
    "Law",
    "Engineering",
    "MINT",
    "Social Sciences",
    "Psychology",
    "Political Sciences",
  ];

  const isNonMobile = useMediaQuery("(min-width:768px)");
  const isNonMobile1000 = useMediaQuery("(min-width:1000px)");
  const isNonMobile1024 = useMediaQuery("(min-width:1024px)");
  const [lastStep, setLastStep] = useState(6);

  let width = Math.floor(step * (100 / lastStep));

  const [scope, animate] = useAnimate();

  useEffect(() => {
    // Fetch data from the REST Countries API
    axios
      .get("https://restcountries.com/v3.1/all")
      .then((response) => {
        const countries = response.data;
        const languageSet = new Set();

        countries.forEach((country) => {
          if (country.languages) {
            Object.values(country.languages).forEach((language) => {
              languageSet.add(language);
            });
          }
        });

        setLanguages(Array.from(languageSet));
      })
      .catch((error) => {
        console.error("There was an error fetching the languages!", error);
      });
  }, []);

  useEffect(() => {
    setLastStep(type === "varyance Livestream" ? 6 : 3);
  }, [type]);

  useEffect(() => {
    if (scope?.current) {
      animate(scope?.current, { width: `${width}%` }, { duration: 0.3 });
    }
  }, [step]);

  const handleBack = () => {
    if (step === 1) {
      navigate(-1);
    } else {
      setSteps(step - 1);
    }
  };

  const handleContinue = async () => {
    if (step === 1) {
      setSteps(2);
    } else if (step === 2) {
      if (!title) {
        toast.error("Please Enter Title");
        return;
      } else if (description.replace(/(<([^>]+)>)/gi, "").length === 0) {
        toast.error("Please Enter Description");
        return;
      } else if (
        (location.City === "" || location.Country === "") &&
        type !== "varyance Livestream"
      ) {
        toast.error("Please Enter Location");
        return;
      } else if (!datetime) {
        toast.error("Please Enter Event Date and time");
        return;
      } else if (!duration) {
        toast.error("Please Enter Event Duration");
        return;
      } else if (!topic) {
        toast.error("Please Enter Event Topic");
        return;
      } else if (!language) {
        toast.error("Please Select Event Language");
        return;
      } else if (selectedItem.length === 0) {
        toast.error("Please Add At least 1 category");
        return;
      } else {
        setSteps(step + 1);
      }
    } else if (
      (step === 3 && type === "varyance Livestream") ||
      (step === 4 && type === "varyance Livestream") ||
      (step === 5 && type === "varyance Livestream")
    ) {
      setSteps(step + 1);
    } else if (step === lastStep) {
      setLoading(true);
      const formData = new FormData();
      formData.append("feature", feature);
      formData.append("owner", user.uniqueId);
      formData.append("company", JSON.stringify(company));
      formData.append("type", type);
      formData.append("title", title);
      formData.append("description", description);
      formData.append("location", JSON.stringify(location));
      formData.append("datetime", datetime);
      formData.append("duration", duration);
      formData.append("topic", topic);
      formData.append("language", language);
      formData.append("selectedItem", JSON.stringify(selectedItem));
      formData.append("schoolYear", JSON.stringify(schoolYear));
      formData.append("relevantCourses", JSON.stringify(relevantCourses));
      formData.append("targetGroups", JSON.stringify(targetGroups));
      formData.append("settings", JSON.stringify(settings));

      // Append documents if any
      documents.forEach((doc, index) => {
        formData.append("documents", doc); // Ensure 'documents' matches the backend field name
      });

      formData.append("addedUsers", JSON.stringify(addedUsers));
      formData.append("addedSpeakers", JSON.stringify(addedSpeakers));
      formData.append("addedJobChal", JSON.stringify(addedJobChal));
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/createEvent`,
          formData
        );
        console.log(response.data.msg);
        if (response.data.msg === "success") {
          toast.success("Event created successfully!");
          navigate("/dashboard/events");
        } else if ((response.data.msg = "atleast3")) {
          toast.error(
            "You must have 3 live Challenges to publish a event however you can save the event as draft"
          );
        } else {
          toast.error("Error creating event.");
        }
      } catch (error) {
        console.error("There was an error creating the event!", error);
        toast.error("There was an error creating the event.");
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, [step]);

  const groups = ["Everyone", "Women in Business", "DE&I", "LGBTQ+", "BIPoc"];

  const handleSubmit = (study) => {
    if (study === "All students") {
      setSchoolYear(["All students"]);
    } else {
      setSchoolYear((prevSchoolYear) => {
        let check = [];
        if (prevSchoolYear.includes(study)) {
          check = prevSchoolYear.filter((s) => s !== study);
        } else {
          check = [
            ...prevSchoolYear.filter((s) => s !== "All students"),
            study,
          ];
        }
        return check;
      });
    }
  };

  const handleSubmitRelevant = (study) => {
    if (study === "All Courses") {
      setRelevantCourses(["All Courses"]);
    } else {
      setRelevantCourses((prevSchoolYear) => {
        let check = [];
        if (prevSchoolYear.includes(study)) {
          check = prevSchoolYear.filter((s) => s !== study);
        } else {
          check = [...prevSchoolYear.filter((s) => s !== "All Courses"), study];
        }
        return check;
      });
    }
  };

  const handleSubmitTarget = (study) => {
    if (study === "Everyone") {
      setTargetGroups(["Everyone"]);
    } else {
      setTargetGroups((prevSchoolYear) => {
        let check = [];
        if (prevSchoolYear.includes(study)) {
          check = prevSchoolYear.filter((s) => s !== study);
        } else {
          check = [...prevSchoolYear.filter((s) => s !== "Everyone"), study];
        }
        return check;
      });
    }
  };

  const [allData, setAllData] = useState([]);
  console.log(allData);

  useEffect(() => {
    if (jobposts.length > 0 || challenges.length > 0)
      setAllData([...challenges, ...jobposts]);
  }, [jobposts, challenges]);

  const handleRemoveDocument = (removedFile) => {
    setDocuments((prevDocuments) =>
      prevDocuments.filter((file) => file !== removedFile)
    );
  };

  return (
    <div className="fullscreenheightwidth">
      <CreateEventHeader handleDraft={handleDraft} True={true} />
      <motion.div
        ref={scope}
        className="ProgressBarForm"
        style={{
          width: `${width}%`,
          position: "relative",
          zIndex: 10,
          top: "",
        }}
      ></motion.div>

      <div className="d-flex">
        <div
          className="basicInforMationPADDINGS center"
          style={{
            width: "100%",
          }}
        >
          {" "}
          <div
            style={{
              maxWidth: step <= 5 ? "700px" : "700px",
              minWidth: step <= 5 && isNonMobile ? "700px" : "700px",
            }}
          >
            {step === 1 && (
              <Step1
                type={type}
                setType={setType}
                isNonMobile1000={isNonMobile1000}
              />
            )}
            {step === 2 && (
              <Step2
                setTitle={setTitle}
                title={title}
                setDescription={setDescription}
                description={description}
                ReactQuill={ReactQuill}
                isNonMobile1000={isNonMobile1000}
                setLocation={setLocation}
                location={location}
                allCountries={allCountries}
                setDuration={setDuration}
                duration={duration}
                setDateTime={setDateTime}
                datetime={datetime}
                setTopic={setTopic}
                topic={topic}
                language={language}
                setLanguage={setLanguage}
                languages={languages}
                setSelectedItem={setSelectedItem}
                selectedItem={selectedItem}
                type={type}
              />
            )}
            {step === 3 && type !== "Job Schedule" && (
              <Step3
                levelofstudylist={levelofstudylist}
                schoolYear={schoolYear}
                handleSubmit={handleSubmit}
                relevantCourses={relevantCourses}
                options={options}
                handleSubmitRelevant={handleSubmitRelevant}
                groups={groups}
                targetGroups={targetGroups}
                handleSubmitTarget={handleSubmitTarget}
                settings={settings}
                setSettings={setSettings}
                isNonMobile1000={isNonMobile1000}
              />
            )}
            {step === 3 && type === "Job Schedule" && (
              <Step5
                setDocuments={setDocuments}
                documents={documents}
                allData={jobposts}
                setAddedJobChal={setAddedJobChal}
                addedJobChal={addedJobChal}
                handleRemoveDocument={handleRemoveDocument}
                TRUE={true}
              />
            )}
            {step === 4 && type === "varyance Livestream" && (
              <Step4
                setSearch={setSearch}
                search={search}
                list={list}
                isNonMobile1024={isNonMobile1024}
                company={company}
                setAddedUsers={setAddedUsers}
                addedUsers={addedUsers}
                setFeature={setFeature}
                feature={feature}
                setSearch2={setSearch2}
                search2={search2}
                list2={list2}
                setAddedSpeakers={setAddedSpeakers}
                addedSpeakers={addedSpeakers}
              />
            )}
            {step === 5 && (
              <Step5
                setDocuments={setDocuments}
                documents={documents}
                allData={allData}
                setAddedJobChal={setAddedJobChal}
                addedJobChal={addedJobChal}
                handleRemoveDocument={handleRemoveDocument}
              />
            )}
            {step === 6 && (
              <Step6
                setSteps={setSteps}
                company={company}
                title={title}
                description={description}
                type={type}
                datetime={datetime}
                duration={duration}
                language={language}
                schoolYear={schoolYear}
                relevantCourses={relevantCourses}
                targetGroups={targetGroups}
                settings={settings}
                isNonMobile1000={isNonMobile1000}
                users={users}
                addedUsers={addedUsers}
                addedSpeakers={addedSpeakers}
                documents={documents}
                handleRemoveDocument={handleRemoveDocument}
                addedJobChal={addedJobChal}
                allData={allData}
              />
            )}

            <div className="space mb-5 pb-5" style={{ marginTop: "3rem" }}>
              <button className="backbutton" onClick={() => handleBack()}>
                Back
              </button>
              <button
                className="continuebutton"
                disabled={loading}
                onClick={() => handleContinue()}
              >
                {loading ? (
                  <div
                    className="loader"
                    style={{
                      height: "24px",
                      width: "24px",
                      border: "3px solid #f3f3f3",
                      borderTop: "3px solid #E31B54",
                    }}
                  ></div>
                ) : (
                  <>
                    {step === lastStep ? "Submit" : "Continue"}{" "}
                    <ArrowForwardIosIcon />
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CreateEvent;
