import { useEffect, useRef, useState } from "react";
import EmptyState from "../../assets/images/EmptyState.svg";
import logo from "../../assets/images/logo.png";
import SelectedBox from "../../assets/images/icons/selectedbox";
import UnSelectedBox from "../../assets/images/icons/unselectedBox";
import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { toast } from "react-toastify";

const Step4 = ({
  setSearch,
  search,
  list,
  isNonMobile1024,
  company,
  setAddedUsers,
  addedUsers,
  setFeature,
  feature,
  setSearch2,
  search2,
  list2,
  setAddedSpeakers,

  addedSpeakers,
}) => {
  const handleAddUsers = (newId) => {
    setAddedUsers((prev) => {
      const check = [...prev];
      if (check.some((id) => id === newId)) {
        return check.filter((id) => id !== newId);
      } else {
        return [...check, newId];
      }
    });
  };

  const [userEmail, setUserEmail] = useState("");

  const popupref = useRef(null);

  const handleAddUsers2 = (newId) => {
    setAddedSpeakers((prev) => {
      const check = [...prev];
      if (check.some((id) => id === newId)) {
        return check.filter((id) => id !== newId);
      } else {
        return [...check, newId];
      }
    });
  };

  const [show, setShow] = useState(false);

  useEffect(() => {
    function handleClickOutside(event) {
      if (popupref.current && !popupref.current.contains(event.target)) {
        setShow(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div style={{ width: "100%", maxWidth: "100vw" }}>
      <p className="BasicInforMationHeading">Add Admin & Speakers</p>
      <p className="UnderBasicHeading" style={{ marginTop: "0.25rem" }}>
        Add relevant information to publish the event
      </p>
      <div style={{ marginTop: "2.5rem" }}>
        <div
          className="shadowa"
          style={{
            marginTop: "2.5rem",
            borderRadius: "5px",
            background: "#FFF",
            padding: "1.5rem",
          }}
        >
          <p className="black16 text-start">Add Event Admin</p>
          <div style={{ marginTop: "1.5rem" }}>
            <p className="thumbnailcreate">Search Admin</p>

            <input
              type="search"
              placeholder="Choose team member"
              className="forminput"
              style={{
                marginTop: "0.75rem",
                width: "100%",
                display: "block",
                paddingLeft: "2rem", // Adjust padding to accommodate the icon
              }}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          {list.length === 0 ? (
            <>
              <div
                className="center"
                style={{
                  marginTop: "0.75rem",
                  marginBottom: "0.75rem",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <img
                  src={EmptyState}
                  alt="EmptyState"
                  height="180px"
                  width="225px"
                />
                <p
                  className="pink16"
                  style={{ fontSize: "20px", fontWeight: "500" }}
                >
                  No Results yet
                </p>
                <p className="black14" style={{ fontWeight: "400" }}>
                  <span className="black14" style={{ fontWeight: "500" }}>
                    Invite your colleagues.
                  </span>{" "}
                  It’s for sure even more fun with you team here.
                </p>
              </div>
            </>
          ) : (
            <>
              <div style={{ padding: "1.25rem" }}>
                {list.map((doc, index) => (
                  <>
                    <div
                      className="space flex-wrap"
                      key={index}
                      style={{ gap: "1rem" }}
                    >
                      <div
                        className="d-flex flex-wrap"
                        style={{
                          width: isNonMobile1024 ? "40%" : "",
                          gap: "1.25rem",
                        }}
                      >
                        <img
                          src={doc.profilephoto}
                          alt="profilephoto"
                          className="shadowa"
                          height="42px"
                          width="42px"
                          style={{
                            borderRadius: "42px",
                            padding: "2px",
                            background: "#FFF",
                          }}
                        />

                        <div>
                          <p className="black14" style={{ fontWeight: "600" }}>
                            {doc.firstName} {doc.lastName}
                          </p>
                          <p
                            className="black12"
                            style={{ marginTop: "0.25rem" }}
                          >
                            {doc.email}
                          </p>
                        </div>
                      </div>
                      <div style={{ width: isNonMobile1024 ? "40%" : "" }}>
                        <p className="black14" style={{ fontWeight: "600" }}>
                          {doc.jobTitle}
                        </p>
                        <p className="black12" style={{ marginTop: "0.25rem" }}>
                          {company.title}
                        </p>
                      </div>
                      <div
                        className="d-flex"
                        style={{
                          width: isNonMobile1024 ? "5%" : "",
                          alignItems: "center",
                        }}
                      >
                        <div
                          className="d-flex"
                          onClick={() => {
                            handleAddUsers(doc.uniqueId);
                          }}
                        >
                          {addedUsers.some((user) => user === doc.uniqueId) ? (
                            <>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z"
                                  fill="#E31B54"
                                />
                              </svg>
                            </>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M5.00521 1.66602H15.0052C16.8462 1.66602 18.3385 3.1584 18.3385 4.99935V14.9993C18.3385 16.8403 16.8462 18.3327 15.0052 18.3327H5.00521C3.16426 18.3327 1.67188 16.8403 1.67188 14.9993V4.99935C1.67188 3.1584 3.16426 1.66602 5.00521 1.66602ZM16.4783 16.4725C16.869 16.0818 17.0885 15.5519 17.0885 14.9993V4.99935C17.0885 4.44681 16.869 3.91691 16.4783 3.52621C16.0876 3.13551 15.5577 2.91602 15.0052 2.91602H5.00521C3.85461 2.91602 2.92188 3.84876 2.92188 4.99935V14.9993C2.92188 15.5519 3.14137 16.0818 3.53207 16.4725C3.92277 16.8632 4.45267 17.0827 5.00521 17.0827H15.0052C15.5577 17.0827 16.0876 16.8632 16.4783 16.4725Z"
                                fill="#344054"
                              />
                            </svg>
                          )}
                        </div>
                      </div>
                    </div>
                    {index !== list.length - 1 && (
                      <>
                        <hr
                          style={{
                            marginTop: "1.25rem",
                            marginBottom: "1.25rem",
                          }}
                        />
                      </>
                    )}
                  </>
                ))}
              </div>
            </>
          )}
        </div>
        <div style={{ marginTop: "2.5rem" }}>
          <p className="thumbnailcreate">Messaging availability</p>
          <p className="underthumbnailcreate" style={{ marginTop: "0.25rem" }}>
            Give candidates the option to message you through the messenger.
            Messages from candidates will not count agains your message limit.
          </p>
          <div
            className="d-flex align-items-center"
            style={{ gap: "1rem", marginTop: "0.75rem", cursor: "pointer" }}
            onClick={() => {
              setFeature(!feature);
            }}
          >
            <div className="center align-items-center">
              {" "}
              {feature ? <SelectedBox /> : <UnSelectedBox />}
            </div>
            <p className="underthumbnailcreate">
              Feature me as avaliable for candidate messages
            </p>
          </div>
        </div>
        <hr
          style={{
            marginTop: "2.5rem",
            marginBottom: "2.5rem",
          }}
        />
        <div
          className="shadowa"
          style={{
            marginTop: "2.5rem",
            borderRadius: "5px",
            background: "#FFF",
            padding: "1.5rem",
          }}
        >
          <p className="black16 text-start">Add Event Speaker</p>
          <div style={{ marginTop: "1.5rem" }}>
            <p className="thumbnailcreate">Search Speaker</p>
            {show && (
              <div
                className="center zindex1000"
                style={{
                  width: "100%",
                  height: "100%",

                  position: "fixed",
                  alignItems: "center",
                  top: 0,
                  left: 0,
                  right: 0,
                  zIndex: 11,
                  backgroundColor: "rgba(45, 50, 130, 0.30)",
                  backdropFilter: "blur(3px)",
                }}
              >
                <div
                  className="shadowa newPopUpContainer"
                  ref={popupref}
                  style={{
                    maxWidth: "90vw",
                    maxHeight: "80vh",
                    padding: "1.5rem",
                    maxWidth: "700px",
                    backgroundColor: "",
                  }}
                >
                  <div className="space ">
                    <div
                      className="d-flex"
                      style={{ gap: "1rem", alignItems: "center" }}
                    >
                      <img
                        src={logo}
                        alt=" logo img"
                        height="36px"
                        width="36px"
                      />
                      <p className="black24">Invite as a Speaker</p>
                    </div>
                    <CloseIcon
                      className="hoverdarkblacksvg"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setShow(false);
                      }}
                    />
                  </div>

                  <div
                    className=""
                    style={{
                      marginTop: "1rem",
                      borderRadius: "20px",
                      padding: "1.5rem",
                    }}
                  >
                    <div className="d-flex " style={{ gap: "0.5rem" }}>
                      {" "}
                      <InfoOutlinedIcon />
                      <p className="black14">
                        Kindly Provide the user's company email address to send
                        the invite to for the Event
                      </p>
                    </div>
                    <p className="black14" style={{ marginTop: "1.75rem" }}>
                      User Email *
                    </p>
                    <input
                      className="forminput col-md-12 col-12"
                      value={userEmail}
                      onChange={(e) => setUserEmail(e.target.value)}
                      style={{ marginTop: "0.7rem" }}
                    />
                    <div
                      className="d-flex "
                      style={{ marginTop: "1.75rem", justifyContent: "end" }}
                    >
                      <div className="d-flex flex-wrap" style={{ gap: "1rem" }}>
                        <button
                          className="backbutton"
                          onClick={() => {
                            setShow(false);
                            setUserEmail("");
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          className="continuebutton"
                          onClick={() => {
                            handleAddUsers2(userEmail);
                            setShow(false);
                            setUserEmail("");
                            toast.success("Added to invite list");
                          }}
                        >
                          Send Invitation
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="d-flex justify-content-end">
              <p
                className="pink14"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setShow(!show);
                }}
              >
                Invite Speakers
              </p>
            </div>
            <input
              type="search"
              placeholder="Choose team member"
              className="forminput"
              style={{
                marginTop: "0.75rem",
                width: "100%",
                display: "block",
                paddingLeft: "2rem", // Adjust padding to accommodate the icon
              }}
              value={search2}
              onChange={(e) => setSearch2(e.target.value)}
            />
          </div>
          {list2.length === 0 ? (
            <>
              <div
                className="center"
                style={{
                  marginTop: "0.75rem",
                  marginBottom: "0.75rem",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <img
                  src={EmptyState}
                  alt="EmptyState"
                  height="180px"
                  width="225px"
                />
                <p
                  className="pink16"
                  style={{ fontSize: "20px", fontWeight: "500" }}
                >
                  No Results yet
                </p>
                <p className="black14" style={{ fontWeight: "400" }}>
                  <span className="black14" style={{ fontWeight: "500" }}>
                    Invite your colleagues.
                  </span>{" "}
                  It’s for sure even more fun with you team here.
                </p>
              </div>
            </>
          ) : (
            <>
              <div style={{ padding: "1.25rem" }}>
                {list2.map((doc, index) => (
                  <>
                    <div
                      className="space flex-wrap"
                      key={index}
                      style={{ gap: "1rem" }}
                    >
                      <div
                        className="d-flex flex-wrap"
                        style={{
                          width: isNonMobile1024 ? "40%" : "",
                          gap: "1.25rem",
                        }}
                      >
                        <img
                          src={doc.profilephoto}
                          alt="profilephoto"
                          className="shadowa"
                          height="42px"
                          width="42px"
                          style={{
                            borderRadius: "42px",
                            padding: "2px",
                            background: "#FFF",
                          }}
                        />

                        <div>
                          <p className="black14" style={{ fontWeight: "600" }}>
                            {doc.firstName} {doc.lastName}
                          </p>
                          <p
                            className="black12"
                            style={{ marginTop: "0.25rem" }}
                          >
                            {doc.email}
                          </p>
                        </div>
                      </div>
                      <div style={{ width: isNonMobile1024 ? "40%" : "" }}>
                        <p className="black14" style={{ fontWeight: "600" }}>
                          {doc.jobTitle}
                        </p>
                        <p className="black12" style={{ marginTop: "0.25rem" }}>
                          {company.title}
                        </p>
                      </div>
                      <div
                        className="d-flex"
                        style={{
                          width: isNonMobile1024 ? "5%" : "",
                          alignItems: "center",
                        }}
                      >
                        <div
                          className="d-flex"
                          onClick={() => {
                            handleAddUsers2(doc.uniqueId);
                          }}
                        >
                          {addedSpeakers.some(
                            (user) => user === doc.uniqueId
                          ) ? (
                            <>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z"
                                  fill="#E31B54"
                                />
                              </svg>
                            </>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M5.00521 1.66602H15.0052C16.8462 1.66602 18.3385 3.1584 18.3385 4.99935V14.9993C18.3385 16.8403 16.8462 18.3327 15.0052 18.3327H5.00521C3.16426 18.3327 1.67188 16.8403 1.67188 14.9993V4.99935C1.67188 3.1584 3.16426 1.66602 5.00521 1.66602ZM16.4783 16.4725C16.869 16.0818 17.0885 15.5519 17.0885 14.9993V4.99935C17.0885 4.44681 16.869 3.91691 16.4783 3.52621C16.0876 3.13551 15.5577 2.91602 15.0052 2.91602H5.00521C3.85461 2.91602 2.92188 3.84876 2.92188 4.99935V14.9993C2.92188 15.5519 3.14137 16.0818 3.53207 16.4725C3.92277 16.8632 4.45267 17.0827 5.00521 17.0827H15.0052C15.5577 17.0827 16.0876 16.8632 16.4783 16.4725Z"
                                fill="#344054"
                              />
                            </svg>
                          )}
                        </div>
                      </div>
                    </div>
                    {index !== list.length - 1 && (
                      <>
                        <hr
                          style={{
                            marginTop: "1.25rem",
                            marginBottom: "1.25rem",
                          }}
                        />
                      </>
                    )}
                  </>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default Step4;
