import information from "../../../assets/images/icons/information.png";
import logo from "../../../assets/images/logo.png";
import "./nav.css";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
const First = () => {
  const isNonMobile = useMediaQuery("(min-width:767px)");
  const isNonMobile1024 = useMediaQuery("(min-width:1024px)");
  const navigate = useNavigate();
  return (
    <>
      <div>
        <div
          className={` d-flex ${!isNonMobile1024 && "mt-5"}`}
          style={{ justifyContent: "space-between" }}
        >
          <div className="col-md-3">
            <p className="registeration">Challenges</p>
          </div>
        </div>
        <div className="">
          <p className="headersmalltext" style={{ marginTop: "0.5rem" }}>
            Design challenges and inspire early talent for their future career.
          </p>
        </div>
      </div>
    </>
  );
};
export default First;
