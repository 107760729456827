import "./upcoming.css";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

import { HiMiniUsers } from "react-icons/hi2";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import OwnerComponent from "./OwnerComponent";
import SpeakersComponent from "./SpeakersComponent";
import { useMediaQuery } from "@mui/material";
import { useDispatch } from "react-redux";
import { setEvent } from "../../../state";
import { useNavigate } from "react-router-dom";
import deleteicon from "../../../assets/images/delete.png";

import EmptyState from "../../../assets/images/EmptyState.png";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const Upcoming = ({
  events,
  setSelectedEvent,
  selectedEvent,
  getDayOfWeek,
  getDayOfMonth,
  isWithinNextThreeDays,
  getTimeRange,
  future,
  setData,
}) => {
  const isNonMobile1024 = useMediaQuery("(min-width:1024px)");
  const isNonMob = useMediaQuery("(min-width:767px)");
  const [Index, setIndex] = useState(-1);
  const [showIcon, setShowIcon] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const now = new Date();
  now.setHours(0, 0, 0, 0); // Remove time component from `now`

  const iconRef = useRef(null);
  const popupref = useRef(null);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });

    function handleClickOutside(event) {
      if (iconRef.current && !iconRef.current.contains(event.target)) {
        setShowIcon(false);
        setIndex(-1);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (popupref.current && !popupref.current.contains(event.target)) {
        setPopup(false);
        setSelected("");
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Logging each event for debugging
  const monthOrder = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
  ];
  const [selected, setSelected] = useState(false);
  const [popup, setPopup] = useState(false);
  const groupedEvents = events
    .filter((event) => {
      const eventDate = new Date(event?.datetime);
      eventDate.setHours(0, 0, 0, 0); // Remove time component from `eventDate`
      const isFutureEvent = future ? eventDate >= now : eventDate < now;

      return isFutureEvent;
    })
    .reduce((acc, event) => {
      const eventDate = new Date(event?.datetime);
      const month = eventDate
        .toLocaleString("default", { month: "long" })
        .toLowerCase();

      if (!acc[month]) {
        acc[month] = [];
      }

      acc[month].push(event);
      return acc;
    }, {});

  const sortedGroupedEvents = monthOrder.reduce((acc, month) => {
    if (groupedEvents[month]) {
      acc[month] = groupedEvents[month];
    }
    return acc;
  }, {});

  const handleDeleteEvent = async (id, draft) => {
    const check = draft ? true : false;
    await axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/api/delete/event`, {
        id: id,
        check: check,
      })
      .then((res) => {
        if (res.data.msg === "success") {
          toast.success("Event Deleted Successfully");
          setData((prev) => !prev);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <div>
      {popup && (
        <div
          className="center zindex1000"
          style={{
            width: "100%",
            height: "100%",

            position: "fixed",
            alignItems: "center",
            top: 0,
            left: 0,
            right: 0,
            zIndex: 11,
            backgroundColor: "rgba(45, 50, 130, 0.30)",
            backdropFilter: "blur(3px)",
            padding: "1rem",
          }}
        >
          <div
            className="shadowa newPopUpContainer"
            ref={popupref}
            style={{
              maxWidth: "90vw",
              maxHeight: "80vh",

              maxWidth: "700px",
              backgroundColor: "",
              padding: "1.5rem",
            }}
          >
            <div className="d-flex " style={{ gap: "1rem" }}>
              <div className="circle-background">
                <img src={deleteicon} alt="delete" height="48px" width="48px" />
              </div>
              <div>
                <p className="gray90018500">Delete Event</p>
                <p className="gray70014400">
                  Are you sure you want to delete this Event?
                  <br /> This action cannot be undone.
                </p>
              </div>
            </div>
            <div
              className="d-flex justify-content-end"
              style={{ marginTop: "2rem", gap: "0.75rem" }}
            >
              <button
                className="backbutton"
                onClick={() => {
                  setPopup(false);
                  setSelected("");
                }}
              >
                Cancel
              </button>
              <button
                className="continuebutton"
                onClick={() => {
                  let id = selected;
                  handleDeleteEvent(selected._id, selected.draft);
                  setPopup(false);
                  setSelected("");
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="d-flex flex-wrap flex-column" style={{ gap: "2.5rem" }}>
        {Object.keys(sortedGroupedEvents).map((month) => (
          <div key={month} className="upcoming-outer">
            <p className="welcome text-start">
              {month.charAt(0).toUpperCase() + month.slice(1)}
            </p>
            {sortedGroupedEvents[month].map((event, index) => (
              <div
                key={index}
                className="upcoming-inner"
                onClick={() => {
                  dispatch(setEvent({ selectedEvent: event }));
                  navigate(`/dashboard/events/details`);
                }}
              >
                <div className="space">
                  <div className="d-flex" style={{ gap: "1.5rem" }}>
                    <div>
                      <p
                        className="upcomingDay"
                        style={{
                          color: isWithinNextThreeDays(event?.datetime)
                            ? "#F97066"
                            : "inherit",
                        }}
                      >
                        {getDayOfWeek(event?.datetime)}
                      </p>
                      <p
                        className="upcomingDate"
                        style={{
                          color: isWithinNextThreeDays(event?.datetime)
                            ? "#F97066"
                            : "inherit",
                        }}
                      >
                        {getDayOfMonth(event?.datetime)}
                      </p>
                    </div>
                    <div
                      style={{
                        height: "62px",
                        width: "1px",
                        background: "#D0D5DD",
                      }}
                    />
                    <div>
                      <div
                        className="d-flex align-items-center"
                        style={{ gap: "0.75rem" }}
                      >
                        <AccessTimeIcon
                          style={{ height: "20px", width: "20px" }}
                        />
                        <p className="headersmalltext">
                          {getTimeRange(event?.datetime, event?.duration)}
                        </p>
                      </div>
                      <div
                        className="d-flex align-items-center"
                        style={{ gap: "0.75rem", marginTop: "0.75rem" }}
                      >
                        <HiMiniUsers height={20} width={20} />
                        <p className="headersmalltext">
                          {event?.participants?.length}
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="  space" style={{ width: "100%" }}>
                        <div>
                          {" "}
                          {isNonMob && (
                            <p
                              style={{
                                fontWeight: 400,
                                width: "100%",
                                fontSize: "16px",
                                lineHeight: "24px",
                                color: "#0C111D",
                                // overflow: "hidden",
                                // textOverflow: "ellipsis",
                                // whiteSpace: "nowrap",
                              }}
                            >
                              {event?.title}
                            </p>
                          )}
                          {isNonMobile1024 && (
                            <>
                              {" "}
                              <div
                                className="d-flex align-items-center"
                                style={{ gap: "2.5rem", marginTop: "0.75rem" }}
                              >
                                <div
                                  className="upcoming-type-outer"
                                  style={{
                                    color:
                                      event?.type?.toLowerCase() ===
                                      "varyance livestream"
                                        ? "#079455"
                                        : event?.type?.toLowerCase() ===
                                          "physical event"
                                        ? "#7835CD"
                                        : "inherit",
                                    background:
                                      event?.type?.toLowerCase() ===
                                      "varyance livestream"
                                        ? "rgba(7, 148, 85, 0.10)"
                                        : event?.type?.toLowerCase() ===
                                          "physical event"
                                        ? "rgba(120, 53, 205, 0.10)"
                                        : "inherit",
                                  }}
                                >
                                  {event?.type?.toLowerCase() ===
                                    "varyance livestream" && (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                    >
                                      <path
                                        d="M2.63047 11.9527C2.52157 11.8443 2.38189 11.7721 2.23047 11.746C2.1818 11.7394 2.13247 11.7394 2.0838 11.746C1.89164 11.7458 1.70626 11.8171 1.5638 11.946C1.26201 12.248 1.26201 12.7374 1.5638 13.0393C1.70791 13.1857 1.9051 13.2674 2.11047 13.266C2.31598 13.2682 2.51346 13.1863 2.65713 13.0393C2.81905 12.8579 2.88796 12.6118 2.8438 12.3727C2.81568 12.2147 2.74145 12.0686 2.63047 11.9527Z"
                                        fill="#079455"
                                      />
                                      <path
                                        d="M1.93713 9.99268C1.756 9.95431 1.56849 10.0192 1.44979 10.1613C1.33108 10.3034 1.30061 10.4994 1.3706 10.6708C1.44058 10.8422 1.59956 10.9609 1.7838 10.9793C2.72673 11.1182 3.46522 11.8621 3.59713 12.806C3.63069 13.0522 3.84205 13.235 4.09046 13.2327H4.1638C4.43588 13.1915 4.62358 12.9383 4.5838 12.666C4.39563 11.2786 3.30451 10.1875 1.91713 9.99935L1.93713 9.99268Z"
                                        fill="#079455"
                                      />
                                      <path
                                        d="M2.2638 6.97935C2.07889 6.93648 1.88566 7.00202 1.76497 7.14852C1.64428 7.29502 1.61696 7.49723 1.69443 7.67051C1.7719 7.84379 1.94081 7.95826 2.13047 7.96602C4.47927 8.25563 6.32783 10.1097 6.61047 12.4593C6.64407 12.7092 6.85839 12.8949 7.11047 12.8927H7.17047C7.30227 12.8761 7.42204 12.8077 7.50334 12.7026C7.58464 12.5975 7.62078 12.4644 7.6038 12.3327C7.2539 9.54491 5.05824 7.34925 2.27047 6.99935L2.2638 6.97935Z"
                                        fill="#079455"
                                      />
                                      <path
                                        d="M2.09047 8.49268C1.90556 8.44982 1.71232 8.51535 1.59164 8.66185C1.47095 8.80836 1.44362 9.01056 1.5211 9.18384C1.59857 9.35712 1.76748 9.4716 1.95713 9.47935C2.75737 9.58222 3.50075 9.94805 4.07047 10.5193C4.64128 11.0867 5.00716 11.8278 5.11047 12.626C5.14094 12.8748 5.35315 13.0612 5.6038 13.0593H5.6638C5.79571 13.0412 5.91492 12.9711 5.99502 12.8648C6.07511 12.7584 6.10947 12.6245 6.09047 12.4927C5.94627 11.4829 5.47841 10.5472 4.75713 9.82601C4.03795 9.10194 3.10123 8.63359 2.09047 8.49268Z"
                                        fill="#079455"
                                      />
                                      <path
                                        d="M11.6638 2.66602H4.39713C2.80547 2.66969 1.51713 3.96102 1.51713 5.55268C1.51713 5.82882 1.74099 6.05268 2.01713 6.05268C2.29327 6.05268 2.51713 5.82882 2.51713 5.55268C2.51536 5.05292 2.71265 4.57302 3.06541 4.21901C3.41817 3.865 3.89737 3.66601 4.39713 3.66602H11.6638C12.6927 3.6842 13.5173 4.52359 13.5171 5.55268V10.0327C13.5101 11.0566 12.6876 11.8879 11.6638 11.906H9.2638C8.98765 11.906 8.7638 12.1299 8.7638 12.406C8.7638 12.6822 8.98765 12.906 9.2638 12.906H11.6638C13.2555 12.906 14.5468 11.6177 14.5505 10.026V5.55268C14.5505 3.95842 13.2581 2.66602 11.6638 2.66602Z"
                                        fill="#079455"
                                      />
                                    </svg>
                                  )}
                                  {event?.type?.toLowerCase() ===
                                    "physical event" && (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                    >
                                      <path
                                        d="M11.2135 10.324C10.9703 10.2017 10.6739 10.2966 10.5468 10.5373C10.4294 10.7826 10.5265 11.0768 10.7668 11.204C11.3535 11.504 11.6868 11.8706 11.6868 12.224C11.6868 12.9773 10.1135 13.8173 8.00683 13.8173C5.90016 13.8173 4.32016 12.984 4.32016 12.2506C4.32016 11.904 4.6535 11.5306 5.24016 11.2306C5.4161 11.1601 5.53701 10.9961 5.55243 10.8072C5.56784 10.6183 5.4751 10.4369 5.31292 10.3388C5.15075 10.2406 4.94703 10.2427 4.78683 10.344C3.97743 10.649 3.41315 11.3893 3.3335 12.2506C3.3335 13.7306 5.3335 14.844 8.00016 14.844C10.6668 14.844 12.6668 13.7306 12.6668 12.2506C12.5945 11.3819 12.029 10.6321 11.2135 10.324Z"
                                        fill="#7835CD"
                                      />
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M7.50016 12.544V9.66397C5.30476 9.39947 3.69518 7.47084 3.82767 5.26353C3.96016 3.05623 5.78888 1.33398 8.00016 1.33398C10.2114 1.33398 12.0402 3.05623 12.1727 5.26353C12.3051 7.47084 10.6956 9.39947 8.50016 9.66397V12.544C8.50016 12.8201 8.27631 13.044 8.00016 13.044C7.72402 13.044 7.50016 12.8201 7.50016 12.544ZM10.9496 4.29474C10.4547 3.10336 9.29026 2.32794 8.00016 2.33064C6.24542 2.33431 4.82383 3.75589 4.82016 5.51064C4.81747 6.80073 5.59288 7.96517 6.78426 8.46012C7.97564 8.95506 9.3479 8.68285 10.2601 7.77061C11.1724 6.85838 11.4446 5.48612 10.9496 4.29474Z"
                                        fill="#7835CD"
                                      />
                                      <path
                                        d="M6.3935 6.25064C6.66964 6.25064 6.8935 6.02678 6.8935 5.75064C6.8935 4.9848 7.51433 4.36397 8.28016 4.36397C8.55631 4.36397 8.78016 4.14011 8.78016 3.86397C8.78016 3.58783 8.55631 3.36397 8.28016 3.36397C7.64541 3.36574 7.03736 3.61959 6.58977 4.06967C6.14218 4.51976 5.89173 5.12922 5.8935 5.76397C5.89525 5.89482 5.94892 6.0196 6.04269 6.11087C6.13646 6.20214 6.26265 6.25242 6.3935 6.25064Z"
                                        fill="#7835CD"
                                      />
                                    </svg>
                                  )}
                                  {event?.type?.toLowerCase() ===
                                  "varyance livestream"
                                    ? "Livestream"
                                    : event?.type?.toLowerCase() ===
                                      "physical event"
                                    ? "In-Person Event"
                                    : event?.type}
                                </div>
                                <OwnerComponent ownerId={event?.owner} />
                                {event?.addedSpeakers?.length > 0 && (
                                  <SpeakersComponent
                                    addedSpeakers={event?.addedSpeakers}
                                  />
                                )}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <MoreVertIcon
                      className="hoverred"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setIndex(index);
                        setShowIcon(!showIcon);
                      }}
                    />
                    <div style={{ position: "absolute" }}>
                      {showIcon && Index === index && (
                        <div style={{ position: "relative" }}>
                          <div
                            style={{
                              position: "absolute",
                              background: "transparent",
                              zIndex: 10,

                              marginLeft: "-150px",
                            }}
                            ref={iconRef}
                          >
                            <div
                              className="d-flex shadowa"
                              style={{
                                flexDirection: "column",
                                background: "transparent",
                                borderRadius: "5px",
                              }}
                            >
                              <button
                                className="d-flex popuptext"
                                style={{
                                  padding: "11px 1rem",
                                  maxHeight: "2rem",
                                  borderRadius: "5px 5px 0px 0px",
                                  gap: "10px",
                                  background: "#FFF",
                                  border: "none",
                                  width: "160px",
                                  minHeight: "42px",
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setShowIcon(false);

                                  setIndex(-1);
                                  navigate("/edit/event", {
                                    state: {
                                      challenge: event,
                                    },
                                  });
                                }}
                              >
                                Edit
                              </button>

                              <button
                                className="d-flex popuptext"
                                style={{
                                  padding: "11px 1rem",
                                  maxHeight: "2rem",
                                  borderRadius: "0px 0px 5px 5px",
                                  gap: "10px",
                                  background: "#FFF",
                                  border: "none",
                                  width: "160px",
                                  minHeight: "42px",
                                  borderTop: "1px solid #d9d9d9",
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setShowIcon(false);
                                  setIndex(-1);
                                  setSelected(event);
                                  setPopup(true);
                                  // handleDeleteEvent(event._id, event.draft);
                                }}
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {!isNonMob && (
                  <p
                    style={{
                      fontWeight: 400,
                      width: "100%",
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "#0C111D",
                      marginTop: "1rem",
                      // overflow: "hidden",
                      // textOverflow: "ellipsis",
                      // whiteSpace: "nowrap",
                    }}
                  >
                    {event?.title}
                  </p>
                )}
                {!isNonMobile1024 && (
                  <>
                    {" "}
                    <div
                      className="space align-items-center flex-wrap"
                      style={{
                        gap: isNonMob ? "2rem" : "1rem",
                        marginTop: "0.75rem",
                      }}
                    >
                      <div
                        className="upcoming-type-outer"
                        style={{
                          color:
                            event?.type?.toLowerCase() === "varyance livestream"
                              ? "#079455"
                              : event?.type?.toLowerCase() === "physical event"
                              ? "#7835CD"
                              : "inherit",
                          background:
                            event?.type?.toLowerCase() === "varyance livestream"
                              ? "rgba(7, 148, 85, 0.10)"
                              : event?.type?.toLowerCase() === "physical event"
                              ? "rgba(120, 53, 205, 0.10)"
                              : "inherit",
                        }}
                      >
                        {event?.type?.toLowerCase() ===
                          "varyance livestream" && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                          >
                            <path
                              d="M2.63047 11.9527C2.52157 11.8443 2.38189 11.7721 2.23047 11.746C2.1818 11.7394 2.13247 11.7394 2.0838 11.746C1.89164 11.7458 1.70626 11.8171 1.5638 11.946C1.26201 12.248 1.26201 12.7374 1.5638 13.0393C1.70791 13.1857 1.9051 13.2674 2.11047 13.266C2.31598 13.2682 2.51346 13.1863 2.65713 13.0393C2.81905 12.8579 2.88796 12.6118 2.8438 12.3727C2.81568 12.2147 2.74145 12.0686 2.63047 11.9527Z"
                              fill="#079455"
                            />
                            <path
                              d="M1.93713 9.99268C1.756 9.95431 1.56849 10.0192 1.44979 10.1613C1.33108 10.3034 1.30061 10.4994 1.3706 10.6708C1.44058 10.8422 1.59956 10.9609 1.7838 10.9793C2.72673 11.1182 3.46522 11.8621 3.59713 12.806C3.63069 13.0522 3.84205 13.235 4.09046 13.2327H4.1638C4.43588 13.1915 4.62358 12.9383 4.5838 12.666C4.39563 11.2786 3.30451 10.1875 1.91713 9.99935L1.93713 9.99268Z"
                              fill="#079455"
                            />
                            <path
                              d="M2.2638 6.97935C2.07889 6.93648 1.88566 7.00202 1.76497 7.14852C1.64428 7.29502 1.61696 7.49723 1.69443 7.67051C1.7719 7.84379 1.94081 7.95826 2.13047 7.96602C4.47927 8.25563 6.32783 10.1097 6.61047 12.4593C6.64407 12.7092 6.85839 12.8949 7.11047 12.8927H7.17047C7.30227 12.8761 7.42204 12.8077 7.50334 12.7026C7.58464 12.5975 7.62078 12.4644 7.6038 12.3327C7.2539 9.54491 5.05824 7.34925 2.27047 6.99935L2.2638 6.97935Z"
                              fill="#079455"
                            />
                            <path
                              d="M2.09047 8.49268C1.90556 8.44982 1.71232 8.51535 1.59164 8.66185C1.47095 8.80836 1.44362 9.01056 1.5211 9.18384C1.59857 9.35712 1.76748 9.4716 1.95713 9.47935C2.75737 9.58222 3.50075 9.94805 4.07047 10.5193C4.64128 11.0867 5.00716 11.8278 5.11047 12.626C5.14094 12.8748 5.35315 13.0612 5.6038 13.0593H5.6638C5.79571 13.0412 5.91492 12.9711 5.99502 12.8648C6.07511 12.7584 6.10947 12.6245 6.09047 12.4927C5.94627 11.4829 5.47841 10.5472 4.75713 9.82601C4.03795 9.10194 3.10123 8.63359 2.09047 8.49268Z"
                              fill="#079455"
                            />
                            <path
                              d="M11.6638 2.66602H4.39713C2.80547 2.66969 1.51713 3.96102 1.51713 5.55268C1.51713 5.82882 1.74099 6.05268 2.01713 6.05268C2.29327 6.05268 2.51713 5.82882 2.51713 5.55268C2.51536 5.05292 2.71265 4.57302 3.06541 4.21901C3.41817 3.865 3.89737 3.66601 4.39713 3.66602H11.6638C12.6927 3.6842 13.5173 4.52359 13.5171 5.55268V10.0327C13.5101 11.0566 12.6876 11.8879 11.6638 11.906H9.2638C8.98765 11.906 8.7638 12.1299 8.7638 12.406C8.7638 12.6822 8.98765 12.906 9.2638 12.906H11.6638C13.2555 12.906 14.5468 11.6177 14.5505 10.026V5.55268C14.5505 3.95842 13.2581 2.66602 11.6638 2.66602Z"
                              fill="#079455"
                            />
                          </svg>
                        )}
                        {event?.type?.toLowerCase() === "physical event" && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                          >
                            <path
                              d="M11.2135 10.324C10.9703 10.2017 10.6739 10.2966 10.5468 10.5373C10.4294 10.7826 10.5265 11.0768 10.7668 11.204C11.3535 11.504 11.6868 11.8706 11.6868 12.224C11.6868 12.9773 10.1135 13.8173 8.00683 13.8173C5.90016 13.8173 4.32016 12.984 4.32016 12.2506C4.32016 11.904 4.6535 11.5306 5.24016 11.2306C5.4161 11.1601 5.53701 10.9961 5.55243 10.8072C5.56784 10.6183 5.4751 10.4369 5.31292 10.3388C5.15075 10.2406 4.94703 10.2427 4.78683 10.344C3.97743 10.649 3.41315 11.3893 3.3335 12.2506C3.3335 13.7306 5.3335 14.844 8.00016 14.844C10.6668 14.844 12.6668 13.7306 12.6668 12.2506C12.5945 11.3819 12.029 10.6321 11.2135 10.324Z"
                              fill="#7835CD"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M7.50016 12.544V9.66397C5.30476 9.39947 3.69518 7.47084 3.82767 5.26353C3.96016 3.05623 5.78888 1.33398 8.00016 1.33398C10.2114 1.33398 12.0402 3.05623 12.1727 5.26353C12.3051 7.47084 10.6956 9.39947 8.50016 9.66397V12.544C8.50016 12.8201 8.27631 13.044 8.00016 13.044C7.72402 13.044 7.50016 12.8201 7.50016 12.544ZM10.9496 4.29474C10.4547 3.10336 9.29026 2.32794 8.00016 2.33064C6.24542 2.33431 4.82383 3.75589 4.82016 5.51064C4.81747 6.80073 5.59288 7.96517 6.78426 8.46012C7.97564 8.95506 9.3479 8.68285 10.2601 7.77061C11.1724 6.85838 11.4446 5.48612 10.9496 4.29474Z"
                              fill="#7835CD"
                            />
                            <path
                              d="M6.3935 6.25064C6.66964 6.25064 6.8935 6.02678 6.8935 5.75064C6.8935 4.9848 7.51433 4.36397 8.28016 4.36397C8.55631 4.36397 8.78016 4.14011 8.78016 3.86397C8.78016 3.58783 8.55631 3.36397 8.28016 3.36397C7.64541 3.36574 7.03736 3.61959 6.58977 4.06967C6.14218 4.51976 5.89173 5.12922 5.8935 5.76397C5.89525 5.89482 5.94892 6.0196 6.04269 6.11087C6.13646 6.20214 6.26265 6.25242 6.3935 6.25064Z"
                              fill="#7835CD"
                            />
                          </svg>
                        )}
                        {event?.type?.toLowerCase() === "varyance livestream"
                          ? "Livestream"
                          : event?.type?.toLowerCase() === "physical event"
                          ? "In-Person Event"
                          : event?.type}
                      </div>
                      <OwnerComponent ownerId={event?.owner} />
                      {event?.addedSpeakers?.length > 0 && (
                        <SpeakersComponent
                          addedSpeakers={event?.addedSpeakers}
                        />
                      )}
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
        ))}

        {Object.keys(sortedGroupedEvents).length === 0 && (
          <div
            className="center align-items-center shadowa "
            style={{
              background: "#FFF",
              padding: "1rem",
              borderRadius: "5px",
              minHeight: "350px",
            }}
          >
            <div>
              <div className="center  ">
                <img
                  src={EmptyState}
                  className="img-fluid"
                  style={{ maxWidth: "300px" }}
                />
              </div>
              <p className="text-center pink14">No Events yet</p>
              <p
                className="underthumbnailcreate text-center"
                style={{ marginTop: "0.5rem" }}
              >
                Create events to view them here
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Upcoming;
