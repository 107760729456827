// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fullscreenheightwidth {
  min-height: 100vh;
  min-width: 100vw;
  background: #f2f3f7;
}

.jobcreatereactquill .ql-editor {
  max-height: 255px;
  min-height: 255px;
  border-radius: 0px 0px 5px 5px;
}
.jobcreatereactquill .ql-toolbar.ql-snow {
  border-radius: 5px 5px 0px 0px;
  border-top: 1px solid #d9d9d9;
  border-left: 1px solid #d9d9d9;
  border-radius: 1px solid #d9d9d9;
}
.jobcreatereactquill .ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-radius: 0px 0px 5px 5px;
  border-bottom: 1px solid #d9d9d9;
  border-left: 1px solid #d9d9d9;
  border-radius: 1px solid #d9d9d9;
}

@media (max-width: 768px) {
  .fullscreenheightwidth {
    min-height: 100svh;
  }
}

@media (min-width: 1200px) {
  .grid-auto-columns {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 1199px) and (min-width: 992px) {
  .grid-auto-columns {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .grid-auto-columns {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 767px) {
  .grid-auto-columns {
    grid-template-columns: repeat(1, 1fr);
  }
}

.allblack14 * {
  color: #475467;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
`, "",{"version":3,"sources":["webpack://./src/pages/JobPost/CreateJob/createjob.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,8BAA8B;AAChC;AACA;EACE,8BAA8B;EAC9B,6BAA6B;EAC7B,8BAA8B;EAC9B,gCAAgC;AAClC;AACA;EACE,8BAA8B;EAC9B,gCAAgC;EAChC,8BAA8B;EAC9B,gCAAgC;AAClC;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF;;AAEA;EACE;IACE,qCAAqC;EACvC;AACF;;AAEA;EACE;IACE,qCAAqC;EACvC;AACF;;AAEA;EACE;IACE,qCAAqC;EACvC;AACF;;AAEA;EACE;IACE,qCAAqC;EACvC;AACF;;AAEA;EACE,cAAc;EACd,oBAAoB;EACpB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,aAAa;AAClC","sourcesContent":[".fullscreenheightwidth {\n  min-height: 100vh;\n  min-width: 100vw;\n  background: #f2f3f7;\n}\n\n.jobcreatereactquill .ql-editor {\n  max-height: 255px;\n  min-height: 255px;\n  border-radius: 0px 0px 5px 5px;\n}\n.jobcreatereactquill .ql-toolbar.ql-snow {\n  border-radius: 5px 5px 0px 0px;\n  border-top: 1px solid #d9d9d9;\n  border-left: 1px solid #d9d9d9;\n  border-radius: 1px solid #d9d9d9;\n}\n.jobcreatereactquill .ql-toolbar.ql-snow + .ql-container.ql-snow {\n  border-radius: 0px 0px 5px 5px;\n  border-bottom: 1px solid #d9d9d9;\n  border-left: 1px solid #d9d9d9;\n  border-radius: 1px solid #d9d9d9;\n}\n\n@media (max-width: 768px) {\n  .fullscreenheightwidth {\n    min-height: 100svh;\n  }\n}\n\n@media (min-width: 1200px) {\n  .grid-auto-columns {\n    grid-template-columns: repeat(4, 1fr);\n  }\n}\n\n@media (max-width: 1199px) and (min-width: 992px) {\n  .grid-auto-columns {\n    grid-template-columns: repeat(3, 1fr);\n  }\n}\n\n@media (max-width: 991px) and (min-width: 768px) {\n  .grid-auto-columns {\n    grid-template-columns: repeat(2, 1fr);\n  }\n}\n\n@media (max-width: 767px) {\n  .grid-auto-columns {\n    grid-template-columns: repeat(1, 1fr);\n  }\n}\n\n.allblack14 * {\n  color: #475467;\n  font-family: Poppins;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 20px; /* 142.857% */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
