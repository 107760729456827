import React, { useEffect, useRef, useState } from "react";
// import "./header.css";
import logo from "../../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import deleteicon from "../../../assets/images/unsaved.png";

const CreateJobHeader = ({ handleDraftSubmit, edit }) => {
  const navigate = useNavigate();
  const isNonMob1130 = useMediaQuery("(min-width:1130px)");

  const popupref = useRef(null);
  const [popup, setPopup] = useState(false);

  useEffect(() => {
    function handleClickOutside(event) {
      if (popupref.current && !popupref.current.contains(event.target)) {
        setPopup(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      {popup && (
        <div
          className="center zindex1000"
          style={{
            width: "100%",
            height: "100%",

            position: "fixed",
            alignItems: "center",
            top: 0,
            left: 0,
            right: 0,
            zIndex: 11,
            backgroundColor: "rgba(45, 50, 130, 0.30)",
            backdropFilter: "blur(3px)",
            padding: "1rem",
          }}
        >
          <div
            className="shadowa newPopUpContainer"
            ref={popupref}
            style={{
              maxWidth: "90vw",
              maxHeight: "80vh",

              maxWidth: "700px",
              backgroundColor: "",
              padding: "1.5rem",
            }}
          >
            <div className="d-flex " style={{ gap: "1rem" }}>
              <div className="circle-background">
                <img src={deleteicon} alt="delete" height="48px" width="48px" />
              </div>
              <div>
                <p className="gray90018500">Unsaved changes</p>
                <p className="gray70014400">
                  Any Changes made will be lost, are you sure?
                </p>
              </div>
            </div>
            <div
              className="d-flex justify-content-end"
              style={{ marginTop: "2rem", gap: "0.75rem" }}
            >
              <button
                className="backbutton"
                onClick={() => {
                  setPopup(false);
                }}
              >
                Back
              </button>
              <button
                className="continuebutton"
                onClick={() => {
                  navigate(-1);
                  setPopup(false);
                }}
              >
                Exit
              </button>
            </div>
          </div>
        </div>
      )}
      <div
        className=" d-flex justify-content-between headercreate "
        style={{ position: "relative" }}
      >
        <div
          className="d-flex align-items-center"
          style={{ justifyContent: "center", gap: "1.24rem" }}
        >
          <img
            src={logo}
            alt="logo img"
            width="36px"
            height="36px"
            className="shadowa rounded-2"
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/dashboard/Jobposts");
            }}
          />
          <p className="HeaderText">Create new job</p>
        </div>
        <div className="d-flex" style={{ gap: "2.5rem" }}>
          {edit === false && (
            <button
              className="supportbtnHeader "
              onClick={() => handleDraftSubmit()}
            >
              Save As Draft
            </button>
          )}

          <div
            className="d-flex  "
            style={{ justifyContent: "center", alignItems: "center" }}
            onClick={() => setPopup(true)}
          >
            <CloseIcon
              className="hoverdarkblacksvg"
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateJobHeader;
