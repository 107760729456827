// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/companyloginimg.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.companyrightimg {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 40px 0px 0px 40px;
}
.red14 {
  color: var(--Primary-600, #e31b54);
  text-align: right;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}
`, "",{"version":3,"sources":["webpack://./src/pages/CompanyLogin/login.css"],"names":[],"mappings":"AAAA;EACE,mDAAwD;EACxD,4BAA4B;EAC5B,sBAAsB;EACtB,gCAAgC;AAClC;AACA;EACE,kCAAkC;EAClC,iBAAiB;EACjB,oBAAoB;EACpB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,aAAa;AAClC","sourcesContent":[".companyrightimg {\n  background: url(../../assets/images/companyloginimg.svg);\n  background-repeat: no-repeat;\n  background-size: cover;\n  border-radius: 40px 0px 0px 40px;\n}\n.red14 {\n  color: var(--Primary-600, #e31b54);\n  text-align: right;\n  font-family: Poppins;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: 20px; /* 142.857% */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
