import EditIcon from "@mui/icons-material/Edit";
const Experience = ({ owner, user, setExperiencePopup, isNonMobile450 }) => {
  return (
    <div className=" shadowa">
      <div
        className="innerglass tablescroll pb-5"
        style={{ maxHeight: "30rem", overflow: "auto", padding: "1.25rem" }}
      >
        <div className="space flex-wrap" style={{ gap: "1rem" }}>
          <p className="filmo20">Experience</p>
          {owner && (
            <EditIcon
              className="hoverred"
              style={{ marginTop: "0.25rem", width: "24px", cursor: "pointer" }}
              onClick={() => setExperiencePopup(true)}
            />
          )}
        </div>
        {user?.experience?.length > 0 && (
          <>
            {user?.experience?.map((exp, index) => (
              <div key={index} style={{ marginTop: "1.5rem" }}>
                <div
                  className={`${isNonMobile450 ? "d-flex" : ""} `}
                  style={{ gap: "1rem" }}
                >
                  {exp?.logo && (
                    <img
                      src={exp?.logo} // Access logo property from each experience object
                      alt="university logo"
                      className="shadowa rounded-3"
                      style={{
                        maxWidth: "32px",
                        maxHeight: "32px",
                        padding: "2px",
                        background: "#FFF",
                      }}
                    />
                  )}
                  <div style={{ width: "100%" }}>
                    <div className="space flex-wrap" style={{ gap: "1rem" }}>
                      <p className="black16 text-start">{exp?.position}</p>
                      <p className="gray14">
                        {exp?.from}-{exp?.to}
                      </p>
                    </div>
                    <div
                      className="gray14400 d-flex flex-wrap"
                      style={{
                        alignItems: "center",
                        gap: "0.5rem",
                        marginTop: "0.5rem",
                      }}
                    >
                      {exp?.location?.flag} {exp?.location?.name}
                      <div
                        className=""
                        style={{
                          background: "#667085",
                          height: "4px",
                          width: "4px",
                          borderRadius: "125px",
                        }}
                      />
                      {exp?.company}
                    </div>
                    {exp?.description && (
                      <p className="gray14400" style={{ marginTop: "0.75rem" }}>
                        {exp?.description}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};
export default Experience;
