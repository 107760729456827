import { useEffect, useRef, useState } from "react";
import CountdownTimer from "./CountDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import logo from "../../../assets/images/logo.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import First from "./FirstSectionNew";
import Second from "./Second";
import Third from "./Third";
import Fourth from "./Fourth";
import Fifth from "./Fifth";
import Sixth from "./Sixth";
const FirstSection = ({
  newTasks,
  taskCount,
  frombefore,
  type,
  color,
  setTaskCount,
  UpdateRecord,
  SubmitDone,
  actual,
  isNonMobile1024,
  isNonMobile768,
  setPreview,
  taskCounter,
  Test,
  setTest,
  referenceDiv2,
}) => {
  const [progress, setProgress] = useState(0);
  const [timeleft, setTimeLeft] = useState(0);
  const [question, setQuestion] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [show, setShow] = useState([]);
  const [files, setFiles] = useState([]);

  const [totalTime, setTotalTime] = useState(() => {
    let totaltime = 0;
    newTasks.map((task) => {
      totaltime = totaltime + Number(task.time);
    });

    return totaltime;
  });

  useEffect(() => {
    setProgress((taskCount / newTasks.length) * 100);
  }, [taskCount]);

  useEffect(() => {
    if (frombefore) {
      setTaskCount(Number(frombefore.taskCount));
    }
  }, [frombefore]);

  useEffect(() => {
    if (newTasks.length > 0) {
      let totaltime = 0;
      newTasks.map((task) => {
        totaltime = totaltime + Number(task.time);
      });

      setTotalTime(totaltime);
    }
  }, [newTasks]);

  const referenceDiv = useRef(null); // Reference to the div you want to measure
  const [divWidth, setDivWidth] = useState(0);

  const updateWidth = () => {
    if (isNonMobile1024) {
      if (referenceDiv.current) {
        const width = referenceDiv.current.offsetWidth;
        setDivWidth(width);
      }
    } else {
      setDivWidth("100%");
    }
  };

  useEffect(() => {
    // Initial width setting
    updateWidth();

    // Add event listener for window resize
    window.addEventListener("resize", updateWidth);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, [taskCount]);

  const sectionsRef = useRef([]);

  const scrollToSection = (index) => {
    if (sectionsRef.current[index]) {
      sectionsRef.current[index].scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleScrollUp = () => {
    // Find the current section index
    const currentIndex = sectionsRef.current.findIndex(
      (el) => el && el.getBoundingClientRect().top >= 0
    );

    // Scroll to the previous section if it exists
    if (currentIndex > 0) {
      scrollToSection(currentIndex - 1);
    }
  };

  const iconRef = useRef(null);

  const handleScrollDown = () => {
    // Find the current section index
    const currentIndex = sectionsRef.current.findIndex(
      (el) => el && el.getBoundingClientRect().top >= 0
    );

    // Scroll to the next section if it exists
    if (currentIndex < sectionsRef.current.length - 1) {
      scrollToSection(currentIndex + 1);
    }
  };

  const scrollToTop = () => {
    scrollToSection(0);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });

    function handleClickOutside(event) {
      if (iconRef.current && !iconRef.current.contains(event.target)) {
        if (
          referenceDiv2.current &&
          referenceDiv2.current.contains(event.target)
        ) {
        } else {
          setTest(false);
        }
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      {!isNonMobile1024 && (
        <div
          className="preview-whole-slider-outer overflowshown"
          ref={iconRef}
          style={{
            position: "fixed",
            right: Test ? 0 : "-100%",

            zIndex: 10,
            maxHeight: "calc(100vh-60px)",

            transition: "right 0.3s ease-in-out", // Adjust duration and timing function as needed
          }}
        >
          <div
            className=" space flex-column pb-4"
            style={{ height: "100%", padding: "20px" }}
          >
            <div
              style={{
                position: "relative",

                width: "218px",
                minWidth: "218px",
              }}
            >
              <div
                className="TaskBarWholePreviewOuter  flex-column"
                style={{ position: "relative", height: "370px" }}
              >
                <div
                  className="TaskBarWholePreview shadowa"
                  style={{ position: "relative" }}
                >
                  {newTasks.length > 0 &&
                    newTasks.map((task, index) => (
                      <div
                        className="TaskBar_element"
                        key={index}
                        style={{
                          borderLeft: `2px solid ${
                            taskCount === index ? color : "#D0D5DD"
                          }`,
                        }}
                      >
                        <p
                          className="black12"
                          style={{
                            fontWeight: 500,
                            color: taskCount === index ? color : "",
                          }}
                        >
                          Task {index + 1}
                        </p>
                        <p className="black14" style={{ fontWeight: 500 }}>
                          {task.taskTitle}
                        </p>
                        <div className="d-flex" style={{ gap: "0.5rem" }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                          >
                            <path
                              d="M14.6668 8.00004C14.6668 11.68 11.6802 14.6667 8.00016 14.6667C4.32016 14.6667 1.3335 11.68 1.3335 8.00004C1.3335 4.32004 4.32016 1.33337 8.00016 1.33337C11.6802 1.33337 14.6668 4.32004 14.6668 8.00004Z"
                              stroke="#667085"
                              strokeWidth="1.2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M10.4734 10.12L8.40675 8.88671C8.04675 8.67338 7.75342 8.16005 7.75342 7.74005V5.00671"
                              stroke="#667085"
                              strokeWidth="1.2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          <p className="progressTime_whole">{task.time} mins</p>
                        </div>
                      </div>
                    ))}
                </div>
                <div className="ProgressBarWhole shadowa">
                  <div className="space w-100">
                    <p className="black14500">Your Progress</p>
                    <p className="blue14600" style={{ color }}>
                      {((taskCount * 100) / newTasks.length).toFixed(0)}%
                    </p>
                  </div>
                  <div
                    className="progress-container-whole"
                    style={{ marginTop: "0.5rem" }}
                  >
                    <div
                      className="progress-bar-whole"
                      style={{
                        width: `${progress}%`,
                        backgroundColor: color,
                      }}
                    ></div>
                  </div>
                </div>
                <div className="ProgressBarWhole shadowa">
                  <CountdownTimer
                    totalTime={totalTime}
                    color={color}
                    setTimeLeft={setTimeLeft}
                    frombefore={frombefore}
                    SubmitDone={SubmitDone}
                    answers={answers}
                    show={show}
                    files={files}
                  />
                </div>
              </div>
            </div>
            <div style={{ position: "relative" }}>
              <button
                className="continuebutton w-100 mt-4"
                onClick={() => {
                  if (actual) {
                    window.location.href = `/student/dashboard/Challenges`;
                  } else {
                    setPreview(false);
                  }
                }}
                style={{ position: "relative", zIndex: 12 }}
              >
                Exit{type !== "Fast-Track" && "(continue later)"}
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="scroll-container">
        <div
          className="firstSectionWholeOuter center align-items-center scroll-item"
          ref={(el) => (sectionsRef.current[0] = el)}
        >
          <div
            className="space w-100"
            style={{ gap: "1.25rem", height: "100%" }}
          >
            {isNonMobile1024 && (
              <div
                style={{
                  position: "relative",
                  minHeight: "287px",
                  width: "218px",
                  minWidth: "218px",
                }}
              >
                <div
                  className="TaskBarWholePreviewOuter  flex-column"
                  style={{ position: "fixed", height: "80%" }}
                >
                  <div className="TaskBarWholePreview shadowa">
                    {newTasks.length > 0 &&
                      newTasks.map((task, index) => (
                        <div
                          className="TaskBar_element"
                          key={index}
                          style={{
                            borderLeft: `2px solid ${
                              taskCount === index ? color : "#D0D5DD"
                            }`,
                          }}
                        >
                          <p
                            className="black12"
                            style={{
                              fontWeight: 500,
                              color: taskCount === index ? color : "",
                            }}
                          >
                            Task {index + 1}
                          </p>
                          <p className="black14" style={{ fontWeight: 500 }}>
                            {task.taskTitle}
                          </p>
                          <div className="d-flex" style={{ gap: "0.5rem" }}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <path
                                d="M14.6668 8.00004C14.6668 11.68 11.6802 14.6667 8.00016 14.6667C4.32016 14.6667 1.3335 11.68 1.3335 8.00004C1.3335 4.32004 4.32016 1.33337 8.00016 1.33337C11.6802 1.33337 14.6668 4.32004 14.6668 8.00004Z"
                                stroke="#667085"
                                strokeWidth="1.2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M10.4734 10.12L8.40675 8.88671C8.04675 8.67338 7.75342 8.16005 7.75342 7.74005V5.00671"
                                stroke="#667085"
                                strokeWidth="1.2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            <p className="progressTime_whole">
                              {task.time} mins
                            </p>
                          </div>
                        </div>
                      ))}
                  </div>
                  <div className="ProgressBarWhole shadowa">
                    <div className="space w-100">
                      <p className="black14500">Your Progress</p>
                      <p className="blue14600" style={{ color }}>
                        {((taskCount * 100) / newTasks.length).toFixed(0)}%
                      </p>
                    </div>
                    <div
                      className="progress-container-whole"
                      style={{ marginTop: "0.5rem" }}
                    >
                      <div
                        className="progress-bar-whole"
                        style={{
                          width: `${progress}%`,
                          backgroundColor: color,
                        }}
                      ></div>
                    </div>
                  </div>

                  <div className="ProgressBarWhole shadowa">
                    <CountdownTimer
                      totalTime={totalTime}
                      color={color}
                      setTimeLeft={setTimeLeft}
                      frombefore={frombefore}
                      SubmitDone={SubmitDone}
                      answers={answers}
                      show={show}
                      files={files}
                    />
                  </div>
                </div>
              </div>
            )}

            <div
              className=""
              ref={referenceDiv}
              style={{ width: "100%", maxHeight: "100%", overflow: "hidden" }}
            >
              <First newTask={newTasks[taskCount]} />
            </div>
            {isNonMobile1024 && (
              <div style={{ position: "relative", width: "48px" }}>
                <div
                  className="space flex-column align-items-center"
                  style={{
                    position: "fixed",
                    top: "0%",
                    height: "100%",
                    padding: "3rem 0rem",
                  }}
                >
                  <div />
                  <div
                    style={{
                      width: "32px",
                      height: "72px",
                      background: "#FFf",
                      borderRadius: "6px",
                    }}
                    className="shadowa space flex-column align-items-center "
                  >
                    <KeyboardArrowUpIcon
                      style={{ cursor: "pointer" }}
                      onClick={handleScrollUp}
                    />
                    <div
                      style={{
                        width: "70%",
                        backgroundColor: "#F2F3F7",
                        height: "1px",
                      }}
                    />
                    <KeyboardArrowDownIcon
                      style={{ cursor: "pointer" }}
                      onClick={handleScrollDown}
                    />
                  </div>
                  <div className="logoContainer" style={{}}>
                    <img src={logo} width={"36px"} height={"36px"} alt="logo" />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {newTasks[taskCount]?.terms && (
          <div
            className="scroll-item seconddiv_whole"
            ref={(el) => (sectionsRef.current[1] = el)}
          >
            <Second divWidth={divWidth} newTask={newTasks[taskCount]} />
          </div>
        )}
        {newTasks[taskCount]?.taskBackground && (
          <div
            className="scroll-item seconddiv_whole"
            ref={(el) => (sectionsRef.current[2] = el)}
          >
            <Third divWidth={divWidth} newTask={newTasks[taskCount]} />
          </div>
        )}
        {newTasks[taskCount]?.caseDocuments?.length > 0 && (
          <div
            className="scroll-item seconddiv_whole"
            ref={(el) => (sectionsRef.current[3] = el)}
          >
            <Fourth
              divWidth={divWidth}
              newTask={newTasks[taskCount]}
              color={color}
            />
          </div>
        )}
        {newTasks[taskCount]?.task && (
          <div
            className="scroll-item seconddiv_whole"
            ref={(el) => (sectionsRef.current[4] = el)}
          >
            <Fifth
              divWidth={divWidth}
              newTask={newTasks[taskCount]}
              color={color}
            />
          </div>
        )}
        <div
          className="scroll-item seconddiv_whole "
          ref={(el) => (sectionsRef.current[5] = el)}
        >
          <Sixth
            divWidth={divWidth}
            newTask={newTasks[taskCount]}
            color={color}
            question={question}
            setQuestion={setQuestion}
            answers={answers}
            setAnswers={setAnswers}
            show={show}
            setShow={setShow}
            files={files}
            setFiles={setFiles}
            actual={actual}
            UpdateRecord={UpdateRecord}
            frombefore={frombefore}
            taskCount={taskCount}
            setTaskCount={setTaskCount}
            total={newTasks.length}
            scrollToTop={scrollToTop}
            isNonMobile1024={isNonMobile1024}
            isNonMobile768={isNonMobile768}
            setPreview={setPreview}
            taskCounter={taskCounter}
            timeleft={timeleft}
            SubmitDone={SubmitDone}
          />
        </div>
      </div>
    </>
  );
};
export default FirstSection;
