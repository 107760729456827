import React from "react";
import guideline from "../../assets/images/guideline.png";
const card1 = () => {
  return (
    <div className="card1Company shadowa">
      <div
        className="d-flex"
        style={{
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "",
          height: "100%",
        }}
      >
        <div className="card1paddings">
          {/* <div className="d-flex">
            <div className="firstcard1button">
              <p className="firstcard1buttontext">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M11.9227 4.7148L6.36692 10.4914C6.24126 10.6185 6.17038 10.7929 6.17038 10.975C6.17038 11.1572 6.24126 11.3316 6.36692 11.4587C6.49045 11.5871 6.65803 11.6593 6.83284 11.6594C7.00689 11.6555 7.17318 11.5839 7.29875 11.4587L12.8546 5.68213C12.9802 5.55502 13.0511 5.3806 13.0511 5.19846C13.0511 5.01633 12.9802 4.84191 12.8546 4.7148C12.5972 4.44792 12.1802 4.44792 11.9227 4.7148Z"
                    fill="#E31B54"
                  />
                  <path
                    d="M7.28996 8.29207C7.46439 8.28997 7.63129 8.21805 7.75588 8.0913L9.61075 6.16579C9.85281 5.89611 9.84567 5.47588 9.59459 5.21524C9.34352 4.9546 8.9387 4.94718 8.67892 5.19846L6.82405 7.12398C6.56696 7.3912 6.56696 7.82409 6.82405 8.0913C6.94962 8.21649 7.11591 8.28814 7.28996 8.29207Z"
                    fill="#E31B54"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.4016 10.61L16.916 3.15431C16.6365 1.74182 15.4392 0.727706 14.0501 0.726883H5.21532C3.80977 0.705836 2.58823 1.72488 2.30555 3.15431L0.819895 10.61C0.564731 11.43 0.844644 12.3275 1.51437 12.8367L8.48552 18.1296C9.15845 18.6293 10.0631 18.6293 10.736 18.1296L17.7071 12.8732C18.3922 12.3589 18.6739 11.4408 18.4016 10.61ZM16.8368 11.5864L9.86568 16.852C9.71227 16.962 9.50923 16.962 9.35581 16.852L2.38466 11.5864C2.22977 11.4747 2.16517 11.2697 2.22643 11.0845C2.23036 11.0298 2.23036 10.9749 2.22643 10.9203L3.75604 3.46459C3.90024 2.75237 4.51451 2.24915 5.21532 2.26912H14.0941C14.7785 2.2711 15.3684 2.76939 15.5094 3.46459L16.9951 10.9203C16.9911 10.9749 16.9911 11.0298 16.9951 11.0845C17.0563 11.2697 16.9917 11.4747 16.8368 11.5864Z"
                    fill="#E31B54"
                  />
                </svg>{" "}
                Challenge
              </p>
            </div>
          </div> */}
          <p className="text-start firstcard2ndtext">Stand Out</p>
          <p className="text-start firstcard3rdtext">
            Immerse in <span>real-world challenges</span> designed by{" "}
            <span>top employers</span> while practicing the skills sets required
            to stand out.
          </p>
        </div>
        <img
          src={guideline}
          alt="company PageLady"
          className="img-fluid rounded-3"
        />
      </div>
    </div>
  );
};

export default card1;
