import React from "react";

const EventIcon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.22461 2.5H6.14128C7.17681 2.5 8.01628 3.33947 8.01628 4.375V7.29167C8.01628 8.3272 7.17681 9.16667 6.14128 9.16667H3.22461C2.18908 9.16667 1.34961 8.3272 1.34961 7.29167V4.375C1.34961 3.33947 2.18908 2.5 3.22461 2.5ZM6.14128 7.91667C6.48645 7.91667 6.76628 7.63684 6.76628 7.29167V4.375C6.76628 4.02982 6.48645 3.75 6.14128 3.75H3.22461C2.88131 3.75449 2.6041 4.0317 2.59961 4.375V7.29167C2.6041 7.63497 2.88131 7.91218 3.22461 7.91667H6.14128Z"
      fill={color || "#344054"}
    />
    <path
      d="M11.3496 5.625H15.5163C15.8615 5.625 16.1413 5.34518 16.1413 5C16.1413 4.65482 15.8615 4.375 15.5163 4.375H11.3496C11.0044 4.375 10.7246 4.65482 10.7246 5C10.7246 5.34518 11.0044 5.625 11.3496 5.625Z"
      fill={color || "#344054"}
    />
    <path
      d="M11.3496 8.125H18.0163C18.3615 8.125 18.6413 7.84518 18.6413 7.5C18.6413 7.15482 18.3615 6.875 18.0163 6.875H11.3496C11.0044 6.875 10.7246 7.15482 10.7246 7.5C10.7246 7.84518 11.0044 8.125 11.3496 8.125Z"
      fill={color || "#344054"}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.22461 10.8333H6.14128C7.17681 10.8333 8.01628 11.6728 8.01628 12.7083V15.625C8.01628 16.1223 7.81873 16.5992 7.4671 16.9508C7.11547 17.3025 6.63856 17.5 6.14128 17.5H3.22461C2.18908 17.5 1.34961 16.6605 1.34961 15.625V12.7083C1.34961 11.6728 2.18908 10.8333 3.22461 10.8333ZM6.14128 16.25C6.48645 16.25 6.76628 15.9702 6.76628 15.625V12.7083C6.76628 12.3632 6.48645 12.0833 6.14128 12.0833H3.22461C2.88131 12.0878 2.6041 12.365 2.59961 12.7083V15.625C2.6041 15.9683 2.88131 16.2455 3.22461 16.25H6.14128Z"
      fill={color || "#344054"}
    />
    <path
      d="M11.3496 13.9583H15.5163C15.8615 13.9583 16.1413 13.6785 16.1413 13.3333C16.1413 12.9882 15.8615 12.7083 15.5163 12.7083H11.3496C11.0044 12.7083 10.7246 12.9882 10.7246 13.3333C10.7246 13.6785 11.0044 13.9583 11.3496 13.9583Z"
      fill={color || "#344054"}
    />
    <path
      d="M18.0163 15.2083H11.3496C11.0044 15.2083 10.7246 15.4882 10.7246 15.8333C10.7246 16.1785 11.0044 16.4583 11.3496 16.4583H18.0163C18.3615 16.4583 18.6413 16.1785 18.6413 15.8333C18.6413 15.4882 18.3615 15.2083 18.0163 15.2083Z"
      fill={color || "#344054"}
    />
  </svg>
);

export default EventIcon;
