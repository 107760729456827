// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  position: relative;
  /* Adjust the value as needed */
}
.buttons-footer {
  color: black;
  font-family: Poppins;
  font-size: 12px;
  opacity: 0.6;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  transition: color 0.3s;
}

.buttons-footer:hover {
  color: #e31b54;
  opacity: 1; /* Color to be applied on hover */
}
`, "",{"version":3,"sources":["webpack://./src/layouts/components/Footer/styles.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,+BAA+B;AACjC;AACA;EACE,YAAY;EACZ,oBAAoB;EACpB,eAAe;EACf,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,SAAS;EAC5B,sBAAsB;AACxB;;AAEA;EACE,cAAc;EACd,UAAU,EAAE,iCAAiC;AAC/C","sourcesContent":[".footer {\n  position: relative;\n  /* Adjust the value as needed */\n}\n.buttons-footer {\n  color: black;\n  font-family: Poppins;\n  font-size: 12px;\n  opacity: 0.6;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 24px; /* 150% */\n  transition: color 0.3s;\n}\n\n.buttons-footer:hover {\n  color: #e31b54;\n  opacity: 1; /* Color to be applied on hover */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
