import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
const CertificatesPopup = ({
  addExperience,
  setAddExperience,
  setIndex,
  certificates,
  setCertificates,
  newCertificate,
  setNewCertificate,
  handleConfirmCertificate,
  handleUpdateCertificate,
  loading,
  user,
}) => {
  const fileInputRef = React.createRef();

  const openFileInput = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();

      reader.onload = function (event) {
        const base64String = event.target.result;

        setNewCertificate((prev) => {
          return {
            ...prev,
            logo: base64String,
          };
        });
      };

      reader.readAsDataURL(file);
    } else {
      toast.error("Please select a valid image file.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  return (
    <div>
      <p className="black16 text-start">Certificates</p>

      {!addExperience ? (
        <>
          {user?.certificates?.map((cert, index) => (
            <div key={index} style={{ marginTop: "1.5rem" }}>
              <hr />
              <div
                className="d-flex justify-content-end"
                style={{ gap: "1rem" }}
              >
                <EditIcon
                  className="hoverred"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setIndex(index);
                    setNewCertificate(cert);
                    setAddExperience(true);
                  }}
                />
                <DeleteIcon
                  className="hoverred"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setCertificates(certificates.filter((_, i) => i !== index));
                  }}
                />
              </div>
              <div className="space flex-wrap col-md-12 col-12">
                <div className="d-flex flex-wrap" style={{ gap: "1rem" }}>
                  {cert?.logo && (
                    <img
                      src={cert?.logo} // Access logo property from each experience object
                      alt="university logo"
                      className="shadowa rounded-3"
                      style={{
                        maxWidth: "32px",
                        maxHeight: "32px",
                        padding: "2px",
                        background: "#FFF",
                      }}
                    />
                  )}
                  <div>
                    {cert?.name && (
                      <p className="black16 text-start">{cert?.name}</p>
                    )}
                    <p className="gray14" style={{ marginTop: "0.75rem" }}>
                      Issued Date: {cert?.issued}
                      <br />
                      Credential ID: {cert?.credentialId}
                    </p>
                  </div>
                </div>
                <div className="center" style={{ alignItems: "center" }}>
                  <button
                    className="backbutton"
                    onClick={() =>
                      window.open(
                        `${cert?.link.startsWith("http") ? "" : "https://"}${
                          cert?.link
                        }`,
                        "_blank"
                      )
                    }
                  >
                    Show Credential
                  </button>
                </div>
              </div>
            </div>
          ))}
          <div className="mt-4">
            <div className="d-flex">
              <p
                className=" text-start backbutton hoverred mt-3"
                onClick={() => setAddExperience(true)}
                style={{ cursor: "pointer" }}
              >
                Add Certificate
              </p>
            </div>
            <hr className="mt-5" />
            <button
              className="continuebutton mt-2"
              onClick={() => handleUpdateCertificate()}
              disabled={loading}
            >
              Update Certificates
            </button>
          </div>
        </>
      ) : (
        <>
          <p className="black14 mt-4">Title</p>
          <input
            className="col-md-12 col-12 forminput mt-3"
            value={newCertificate?.name}
            placeholder="Enter Certificates title"
            onChange={(e) => {
              setNewCertificate((prev) => {
                return {
                  ...prev,
                  name: e.target.value,
                };
              });
            }}
          />
          <p className="black14 mt-4">Institute Logo(optional):</p>
          <div className="mt-3 border p-4 rounded-3">
            {newCertificate?.logo && (
              <div className="center">
                <img
                  src={
                    newCertificate?.logo instanceof File
                      ? URL.createObjectURL(newCertificate?.logo)
                      : newCertificate?.logo
                  }
                  alt="selectefFile"
                  className="rounded-2"
                  style={{ maxHeight: "200px", maxWidth: "200px" }}
                />
              </div>
            )}

            <div style={{ gap: "5px", justifyContent: "center" }}>
              <p
                className="text-center clicktoupload"
                onClick={openFileInput}
                style={{ cursor: "pointer" }}
              >
                {newCertificate?.logo
                  ? "Click to change Image"
                  : "Click to upload Image"}
              </p>
              <p className="svgpngtext">SVG, PNG, JPG or GIF (max. 12MB)</p>
            </div>

            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              style={{ display: "none" }}
              ref={fileInputRef}
            />
          </div>
          <p className="black14 mt-4">Issued Date:</p>
          <input
            className="col-md-12 col-12 forminput mt-3"
            value={newCertificate?.issued}
            type="date"
            placeholder="Enter Issued Date"
            onChange={(e) => {
              setNewCertificate((prev) => {
                return {
                  ...prev,
                  issued: e.target.value,
                };
              });
            }}
          />
          <p className="black14 mt-4">Credential ID:</p>
          <input
            className="col-md-12 col-12 forminput mt-3"
            value={newCertificate?.credentialId}
            type="text"
            placeholder="Enter credential id"
            onChange={(e) => {
              setNewCertificate((prev) => {
                return {
                  ...prev,
                  credentialId: e.target.value,
                };
              });
            }}
          />

          <p className="black14 mt-4">Link url:</p>
          <input
            className="col-md-12 col-12 forminput mt-3"
            value={newCertificate?.link}
            type="text"
            placeholder="Provide Link"
            onChange={(e) => {
              setNewCertificate((prev) => {
                return {
                  ...prev,
                  link: e.target.value,
                };
              });
            }}
          />

          <button
            className="continuebutton mt-4 mb-5"
            onClick={() => handleConfirmCertificate()}
          >
            Confirm
          </button>
        </>
      )}
    </div>
  );
};

export default CertificatesPopup;
