import React, { useEffect, useState } from "react";
import "./body.css";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import upload from "../../../assets/images/icons/upload.png";
import Automotive from "../../../assets/images/icons/Automotive.png";
import Brand from "../../../assets/images/icons/Brand.png";
import Consulting from "../../../assets/images/icons/Consulting.png";
import Economics from "../../../assets/images/icons/Economics.png";
import HR from "../../../assets/images/icons/HR.png";
import Aviation from "../../../assets/images/icons/Aviation.png";
import marketing from "../../../assets/images/icons/marketing.png";
import realstate from "../../../assets/images/icons/realstate.png";
import FMCG from "../../../assets/images/icons/FMCG.png";
import Engineering from "../../../assets/images/icons/Engineering.png";
import Computer from "../../../assets/images/icons/Computer.png";
import Finance from "../../../assets/images/icons/Finance.png";
import Government from "../../../assets/images/icons/Government.png";
import { Country, City } from "country-state-city";
import Law from "../../../assets/images/icons/Law.png";
import Pharma from "../../../assets/images/icons/Pharma.png";
import Startup from "../../../assets/images/icons/Startup.png";
import Supply from "../../../assets/images/icons/Supply.png";
import Technology from "../../../assets/images/icons/Technology.png";
import sample from "../../../assets/images/logosCOmpany/Defaultcompany.png";
import { useMediaQuery } from "@mui/material";
import TextArea from "../textarea";
import { toast } from "react-toastify";
import Location from "./location/location";
import axios from "axios";
import { setCompany as set } from "../../../state";
import ImageCropper from "./ImageCropper";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import SelectedBox from "../../../assets/images/icons/selectedbox";
import UnselectedBox from "../../../assets/images/icons/unselectedBox";
const Body = () => {
  const email = useSelector((state) => state.email);
  const navigate = useNavigate();
  const Location = useLocation();
  const { com } = Location.state || {};

  const isNonMobile = useMediaQuery("(min-width:768px)");
  const [name, setName] = useState(com?.title ?? "");
  const [companyLink, setCompanyLink] = useState("");
  const [website, setWebsite] = useState("");
  const [industry, setIndustry] = useState("");
  const [size, setSize] = useState("");
  const [type, setType] = useState("");
  const [selectedFile, setSelectedFile] = useState(com?.img ?? null);
  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useState([]);
  const [citySearch, setCitySearch] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [filteredCities, setFilteredCities] = useState([]);

  const [croppedimage, setcroppedImage] = useState(null);
  const [tagline, setTagline] = useState("");
  const [agree, setAgree] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);

  const [location, setLocation] = useState("");
  const [country, setCountry] = useState("");
  const [isChangeImage, setChangeImage] = useState(false);
  const dispatch = useDispatch();
  const goBack = () => {
    navigate(-1);
  };

  const allCountries = Country.getAllCountries();

  const handleSearchCountry = (e, index) => {
    setSearch((prevSearch) => {
      const newSearch = [...prevSearch];
      newSearch[index] = e.target.value;
      return newSearch;
    });

    const filteredCountries = allCountries.filter((country) =>
      country.name.toLowerCase().includes(e.target.value.toLowerCase())
    );

    setFilteredLocations((prevLocations) => {
      const newLocations = [...prevLocations];
      newLocations[index] = filteredCountries;
      return newLocations;
    });
  };

  const handleRemoveCity = (i, index, name) => {
    setCountry((prev) => {
      const newCountry = [...prev];
      newCountry[index].City = newCountry[index].City.filter(
        (city) => city.name !== name
      );
      return newCountry;
    });
  };

  const handleSearchCity = (e, index) => {
    setCitySearch((prevSearch) => {
      const newSearch = [...prevSearch];
      newSearch[index] = e.target.value;

      return newSearch;
    });
    const isoCode = country[index].Country.isoCode;

    if (isoCode) {
      const filtered = City.getCitiesOfCountry(isoCode).filter((city) =>
        city.name.toLowerCase().includes(e.target.value.toLowerCase())
      );

      setFilteredCities((prevLocations) => {
        const newLocations = [...prevLocations];
        newLocations[index] = filtered;

        return newLocations;
      });
    }
  };

  const handleDeleteCountry = (index) => {
    setCountry((prevCountry) => {
      // Filter out the country at the specified index
      const updatedCountry = prevCountry.filter((_, idx) => idx !== index);
      return updatedCountry;
    });
  };

  console.log({ selectedFile });

  const handleSubmit = (e) => {
    e.preventDefault();

    if (selectedItem.length === 0) {
      return toast.error("Please select at least one tag");
    }

    const createPayload = (fileUrl) => ({
      email,
      name,
      companyLink,
      website,
      industry,
      size,
      type,
      selectedFile: fileUrl,
      tagline,
      selectedItem,
      pre: com ? com?.uniqueId : false,
      country,
    });

    const handleResponse = (res) => {
      if (res.data.message === "Created") {
        dispatch(set({ company: res.data.savedUser.uniqueId }));
        navigate("/create/company/role/Recruiter");
      } else if (res.data.message === "Already") {
        toast.error("Company Name Already Taken", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (res.data.message === "company already created") {
        toast.error("Company Already Created");
        navigate("/create/company/role/Recruiter");
      }
      setLoading(false);
    };

    const handleError = (err) => {
      console.log(err.message);
      setLoading(false);
    };

    const submitForm = (payload) => {
      setLoading(true);
      axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/create/company`, payload)
        .then(handleResponse)
        .catch(handleError);
    };

    if (selectedFile) {
      if (typeof selectedFile === "string" && selectedFile.includes("http")) {
        submitForm(createPayload(selectedFile));
      } else {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64Url = reader.result;
          const fileUrl = croppedimage ?? base64Url;
          submitForm(createPayload(fileUrl));
          reader.abort();
        };
        reader.readAsDataURL(selectedFile);
      }
    }
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   if (selectedItem.length === 0) {
  //     return toast.error("Please select atleast one tag");
  //   }
  //   if (selectedFile) {
  //     const reader = new FileReader();

  //     reader.onloadend = () => {
  //       const base64Url = reader.result;

  //       // Build the payload
  //       const payload = {
  //         email,
  //         name,
  //         companyLink,
  //         website,
  //         industry,
  //         size,
  //         type,

  //         selectedFile:
  //           typeof selectedFile === "string" && selectedFile?.includes("http")
  //             ? selectedFile
  //             : croppedimage ?? base64Url, // Use the base64 URL// Use the base64 URL
  //         tagline,
  //         selectedItem,
  //         pre: com ? com?.uniqueId : false,
  //         country,
  //       };
  //       setLoading(true);
  //       console.log("TEST1");
  //       axios
  //         .post(`${process.env.REACT_APP_BACKEND_URL}/create/company`, payload)
  //         .then((res) => {
  //           if (res.data.message === "Created") {
  //             dispatch(set({ company: res.data.savedUser.uniqueId }));
  //             // navigate("/create/company/role/Recruiter");
  //           } else if (res.data.message === "Already") {
  //             toast.error("Company Name Already Taken", {
  //               position: "top-right",
  //               autoClose: 5000,
  //               hideProgressBar: false,
  //               closeOnClick: true,
  //               pauseOnHover: true,
  //               draggable: true,
  //               progress: undefined,
  //               theme: "colored",
  //             });
  //           }
  //           setLoading(false);
  //         })
  //         .catch((err) => {
  //           console.log(err.message);
  //           setLoading(false);
  //         });

  //       reader.abort();
  //     };

  //     if (!typeof selectedFile === "string") {
  //       reader.readAsDataURL(selectedFile);
  //     } else {
  //       const payload = {
  //         email,
  //         name,
  //         companyLink,
  //         website,
  //         industry,
  //         size,
  //         type,
  //         selectedFile:
  //           typeof selectedFile === "string" && selectedFile?.includes("http")
  //             ? selectedFile
  //             : croppedimage, // Use the base64 URL// Use the base64 URL
  //         tagline,
  //         selectedItem,
  //         pre: com ? com?.uniqueId : false,
  //         country,
  //       };
  //       setLoading(true);

  //       console.log("TEST2");

  //       axios
  //         .post(`${process.env.REACT_APP_BACKEND_URL}/create/company`, payload)
  //         .then((res) => {
  //           if (res.data.message === "Created") {
  //             dispatch(set({ company: res.data.savedUser.uniqueId }));
  //             // navigate("/create/company/role/Recruiter");
  //           } else if (res.data.message === "Already") {
  //             toast.error("Company Name Already Taken", {
  //               position: "top-right",
  //               autoClose: 5000,
  //               hideProgressBar: false,
  //               closeOnClick: true,
  //               pauseOnHover: true,
  //               draggable: true,
  //               progress: undefined,
  //               theme: "colored",
  //             });
  //           }
  //           setLoading(false);
  //         })
  //         .catch((err) => {
  //           console.log(err.message);
  //           setLoading(false);
  //         });
  //     }
  //   }
  // };

  const items = [
    {
      id: "Brand Management",
      icon: Brand,
      text: "Brand Management",
      select: false,
    },
    { id: "Finance", icon: Finance, text: "Finance", select: false },
    { id: "Consulting", icon: Consulting, text: "Consulting", select: false },
    { id: "Economics", icon: Economics, text: "Economics", select: false },
    { id: "Corporate Law", icon: Law, text: "Corporate Law", select: false },
    {
      id: "HR",
      icon: HR,
      text: "HR",
      select: false,
    },
    {
      id: "Aviation",
      icon: Aviation,
      text: "Aviation",
      select: false,
    },
    { id: "Supply Chain", icon: Supply, text: "Supply Chain", select: false },
    { id: "Pharma", icon: Pharma, text: "Pharma", select: false },
    {
      id: "Technology",
      icon: Technology,
      text: "Technology & AI",
      select: false,
    },
    {
      id: "FMCG",
      icon: FMCG,
      text: "FMCG",
      select: false,
    },
    { id: "Government", icon: Government, text: "Government", select: false },
    { id: "marketing", icon: marketing, text: "Marketing & PR", select: false },
    {
      id: "Real Estate",
      icon: realstate,
      text: "Real Estate",
      select: false,
    },
    { id: "Start-Up", icon: Startup, text: "Start-Up", select: false },
    { id: "Automotive", icon: Automotive, text: "Automotive", select: false },

    {
      id: "Engineering",
      icon: Engineering,
      text: "Engineering",
      select: false,
    },
    {
      id: "Computer Science",
      icon: Computer,
      text: "Computer Science",
      select: false,
    },
  ];

  const [InterestItemsList, setInterestItem] = useState([...items]);

  const handleFileChange = (event) => {
    const file = event.target.files?.[0];

    if (file && file.type.startsWith("image/")) {
      const img = new Image();

      img.onload = function () {
        setSelectedFile(file);
        setChangeImage(false);
      };
      img.src = URL.createObjectURL(file);
    } else {
      toast.error("Please select a valid image file.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  useEffect(() => {
    if (name) {
      // Generate the company link by normalizing the title
      const normalizedTitle = name
        .toLowerCase()
        .trim()
        .replace(/[^a-z0-9]+/g, "-") // Replace non-alphanumeric characters with hyphens
        .replace(/^-+|-+$/g, ""); // Remove leading or trailing hyphens

      // Call a function to check for uniqueness or ensure it's unique
      setCompanyLink(normalizedTitle);
    }
  }, [name]);

  const handleDrop = (event) => {
    event.preventDefault();
    handleFileChange({ target: { files: event.dataTransfer.files } });
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const openFileInput = () => {
    fileInputRef.current.click();
  };

  const fileInputRef = React.createRef();
  useEffect(() => {
    if (!email) {
      navigate("/company/register");
    }
  }, []);

  function selectItem(item, i) {
    if (selectedItem.length <= 3 || item.select === true) {
      let itemId = item.id;
      let updatedItem = [...InterestItemsList];

      if (updatedItem[i]?.select) {
        // If the item is already selected, unselect it
        updatedItem[i].select = false;
        let newSelectedItems = selectedItem.filter(
          (selected) => selected.id !== itemId
        );
        setSelectedItem(newSelectedItems);
      } else {
        // If the item is not selected, select it
        updatedItem[i].select = true;
        setSelectedItem((prev) => [...prev, item]);
      }

      setInterestItem(updatedItem);
    }
  }

  return (
    <>
      <p className="registeration">Create Company</p>
      <p
        style={{
          fontSize: "14px",
          fontFamily: "Poppins",
          color: "#1D2939",
          marginTop: "0.5rem",
        }}
      >
        Let’s get started with a few details about your company.
      </p>
      <div className="d-flex" style={{ width: "100%" }}>
        <div className="col-md-6 col-12" style={{ maxWidth: "567px" }}>
          <div className="row">
            <div>
              <label htmlFor="companyname" className="formtext mt-5">
                Company Name *
              </label>

              <input
                id="companyname"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="forminput col-md-12 col-12 mt-2"
              />
            </div>
            <div>
              <label
                htmlFor="companylink"
                className="formtext "
                style={{ marginTop: "1rem" }}
              >
                varyance.com/company/*
              </label>

              <input
                id="companylink"
                value={companyLink}
                placeholder="/bostonconsultinggroup"
                // onChange={(e) => setCompanyLink(e.target.value)}
                disabled
                className="forminput col-md-12 col-12 "
                style={{ marginTop: "12px" }}
              />
            </div>
            {/* <p
              style={{
                color: "#E31B54",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "20px",
                marginTop: "8px",
              }}
            >
              Learn more about the Page Public URL
            </p> */}
            <div>
              <p className="formtext " style={{ marginTop: "1rem" }}>
                Website
              </p>

              <input
                value={website}
                placeholder="Begin with https://’ http:// or www."
                onChange={(e) => setWebsite(e.target.value)}
                className="forminput col-md-12 col-12 "
                style={{ marginTop: "12px" }}
              />
              <hr style={{ marginTop: "2.5rem", marginBottom: "2.5rem" }} />
            </div>

            <div>
              <p className="formtext">Industry *</p>

              <input
                value={industry}
                placeholder="e.g. Professional Services / Banking / Healthcare"
                onChange={(e) => setIndustry(e.target.value)}
                className="forminput col-md-12 col-12 "
                style={{ marginTop: "12px" }}
              />
            </div>
            <div
              className="tag-container"
              style={{
                gap: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <p className="formtext" style={{ marginTop: "1rem" }}>
                Select tags * (maximum 4)
              </p>
              <p
                className="underthumbnailcreate"
                style={{ marginTop: "0.25rem" }}
              >
                Tags are used to categorize your company among student’s
                interests in industries and fields for their future career.
              </p>{" "}
              <div
                className="tags-container"
                style={{ display: "flex", flexWrap: "wrap", gap: "12px 6px" }}
              >
                {InterestItemsList.map((item, index) => (
                  <div
                    className={`ChipCover d-flex ${
                      item.select ? "selected_interests_new" : ""
                    }`}
                    key={index}
                    onClick={() => selectItem(item, index)}
                    style={{
                      padding: "10px 10px",
                      gap: "0.5rem",
                      cursor: "pointer",
                    }}
                  >
                    {item.icon && (
                      <img
                        src={item.icon}
                        height="20px"
                        width="20px"
                        alt="icon"
                        className="interest_icon"
                      />
                    )}
                    <p
                      className="interesttext"
                      style={{
                        color: item.select ? "white" : "",
                        fontWeight: "400",
                        lineHeight: "1.125rem",
                      }}
                    >
                      {item.text}
                    </p>
                  </div>
                ))}
              </div>
              <hr style={{ marginTop: "2rem", marginBottom: "2.5rem" }} />
            </div>
            <div>
              <p className="formtext ">Organization size *</p>
              <select
                value={size}
                onChange={(e) => setSize(e.target.value)}
                className="forminput col-md-12 col-12"
                style={{ marginTop: "12px" }}
              >
                <option value="" disabled className="formtext ">
                  Select Size
                </option>
                <option value="1-10" className="formtext">
                  1-10 employees
                </option>
                <option value="11-50" className="formtext">
                  11-50 employees
                </option>
                <option value="51-500" className="formtext">
                  51-500 employees
                </option>
                <option value="501-5000" className="formtext">
                  501 - 5000 employees
                </option>
                <option value="5000+" className="formtext">
                  5000+ employees
                </option>
              </select>
            </div>
            <div>
              <p className="formtext" style={{ marginTop: "1rem" }}>
                Organization type *
              </p>

              <select
                value={type}
                onChange={(e) => setType(e.target.value)}
                className="forminput col-md-12 col-12 "
                style={{ marginTop: "12px" }}
              >
                <option value="" disabled className="formtext">
                  Select type
                </option>
                <option value="Public Company" className="formtext">
                  Public Company
                </option>
                <option value="Privately Held" className="formtext">
                  Privately Held
                </option>
                <option value="Partnership" className="formtext">
                  Partnership
                </option>
                <option value="Government Agency" className="formtext">
                  Government Agency
                </option>
                <option value="NGO / Think Tank" className="formtext">
                  NGO / Think Tank
                </option>
                <option value="Non Profit" className="formtext">
                  Non Profit
                </option>
                <option value="Association" className="formtext">
                  Association
                </option>
              </select>
            </div>
            <div>
              <p className="formtext " style={{ marginTop: "1rem" }}>
                Location *
              </p>
              {/* <Location
                setCountry={setCountry}
                setLocation={setLocation}
                location={location}
                country={country}
              /> */}
              <>
                <p
                  className="underthumbnailcreate"
                  style={{ marginTop: "0.25rem" }}
                >
                  Here you can add office Country.
                </p>
                {country &&
                  country.length > 0 &&
                  country.map((count, index) => (
                    <div key={index}>
                      {country.length > 1 && (
                        <div className="space" style={{ marginTop: "0.75rem" }}>
                          <p className="thumbnailcreate">Country {index + 1}</p>
                          {index > 0 && (
                            <p
                              className="pink16"
                              style={{ fontSize: "14px", cursor: "pointer" }}
                              onClick={() => handleDeleteCountry(index)}
                            >
                              Delete <ClearIcon />
                            </p>
                          )}
                        </div>
                      )}
                      <div style={{ position: "relative" }} key={index}>
                        <input
                          type="search"
                          placeholder="Search country"
                          className="forminput"
                          style={{
                            marginTop: "0.75rem",
                            width: "100%",
                            display: "block",
                            paddingLeft: "2rem", // Adjust padding to accommodate the icon
                          }}
                          value={search[index] || ""}
                          onChange={(e) => handleSearchCountry(e, index)}
                        />

                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          style={{
                            position: "absolute",
                            left: "0.5rem", // Adjust icon position
                            top: "50%",
                            transform: "translateY(-50%)",
                          }}
                        >
                          <path
                            d="M17.4999 17.4999L14.5833 14.5832M16.6665 9.58321C16.6665 13.4952 13.4952 16.6665 9.58321 16.6665C5.67119 16.6665 2.49988 13.4952 2.49988 9.58321C2.49988 5.67119 5.67119 2.49988 9.58321 2.49988C13.4952 2.49988 16.6665 5.67119 16.6665 9.58321Z"
                            stroke="#667085"
                            strokeWidth="1.36482"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>

                      {filteredLocations[index] &&
                        filteredLocations[index].length > 0 &&
                        search[index] && (
                          <div
                            className="shadowa tablescroll d-flex flex-column rounded-3"
                            style={{
                              marginTop: "0.75rem",
                              position: "absolute",
                              zIndex: 3,
                              minWidth: "300px",
                              maxHeight: "15rem",
                              background: "#FFF",
                              padding: "1.5rem",
                              overflow: "auto",
                              gap: "0.5rem",
                            }}
                          >
                            {filteredLocations[index].map((contri, i) => (
                              <div
                                className="d-flex"
                                key={i}
                                style={{ gap: "1rem", cursor: "pointer" }}
                                onClick={() => {
                                  setCountry((prevCountry) => {
                                    const updated = [...prevCountry];
                                    let text = -1;
                                    updated.map((con) => {
                                      if (con.Country.name === contri.name) {
                                        text = 1;
                                      }
                                      return null; // Added to avoid warning about unused return value
                                    });
                                    if (text === -1) {
                                      updated[index].Country = contri;
                                      updated[index].City = [];
                                    } else {
                                      toast.error("Country already Selected");
                                    }
                                    return updated;
                                  });
                                  setSearch((prevSearch) => {
                                    const updated = [...prevSearch];
                                    updated[index] = "";
                                    return updated;
                                  });
                                }}
                              >
                                {contri.flag}
                                <p className="black14">{contri.name}</p>
                              </div>
                            ))}
                          </div>
                        )}

                      {count.Country?.name && (
                        <>
                          {" "}
                          <div className="d-flex">
                            <div
                              className="d-flex  shadowa rounded-5"
                              style={{
                                padding: "0.5rem",
                                gap: "0.5rem",
                                background: "#FFF",

                                marginTop: "0.75rem",
                              }}
                            >
                              {count.Country.flag}
                              <p className="black14">{count.Country.name}</p>
                            </div>{" "}
                          </div>
                          <div style={{ position: "relative" }}>
                            <input
                              type="search"
                              placeholder="Search City"
                              className="forminput"
                              style={{
                                marginTop: "0.75rem",
                                width: "100%",
                                display: "block",
                                paddingLeft: "2rem", // Adjust padding to accommodate the icon
                              }}
                              value={citySearch[index] || ""}
                              onChange={(e) => handleSearchCity(e, index)}
                            />

                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              style={{
                                position: "absolute",
                                left: "0.5rem", // Adjust icon position
                                top: "50%",
                                transform: "translateY(-50%)",
                              }}
                            >
                              <path
                                d="M17.4999 17.4999L14.5833 14.5832M16.6665 9.58321C16.6665 13.4952 13.4952 16.6665 9.58321 16.6665C5.67119 16.6665 2.49988 13.4952 2.49988 9.58321C2.49988 5.67119 5.67119 2.49988 9.58321 2.49988C13.4952 2.49988 16.6665 5.67119 16.6665 9.58321Z"
                                stroke="#667085"
                                strokeWidth="1.36482"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                          {filteredCities[index] &&
                            filteredCities[index].length > 0 &&
                            citySearch[index] && (
                              <div
                                className="shadowa tablescroll d-flex flex-column rounded-3"
                                style={{
                                  marginTop: "0.75rem",
                                  position: "absolute",
                                  zIndex: 3,
                                  minWidth: "300px",
                                  maxHeight: "15rem",
                                  background: "#FFF",
                                  padding: "1.5rem",
                                  overflow: "auto",
                                  gap: "0.5rem",
                                }}
                              >
                                {filteredCities[index].map((contri, i) => (
                                  <div
                                    className="d-flex"
                                    key={i}
                                    style={{ gap: "1rem", cursor: "pointer" }}
                                    onClick={() => {
                                      setCountry((prevCountry) => {
                                        const updated = [...prevCountry];
                                        const cityExists = updated[
                                          index
                                        ].City.some(
                                          (cit) => cit.name === contri.name
                                        );
                                        if (!cityExists) {
                                          updated[index].City.push(contri);
                                        }
                                        return updated;
                                      });

                                      setCitySearch((prevSearch) => {
                                        const updated = [...prevSearch];
                                        updated[index] = "";
                                        return updated;
                                      });
                                    }}
                                  >
                                    {contri.flag}
                                    <p className="black14">{contri.name}</p>
                                  </div>
                                ))}
                              </div>
                            )}
                          {count.City.length > 0 && (
                            <div
                              className="d-flex flex-wrap"
                              style={{ gap: "0.5rem 0.5rem" }}
                            >
                              {count.City.map((city, i) => (
                                <div
                                  className="d-flex  shadowa rounded-5"
                                  key={i}
                                  style={{
                                    padding: "0.5rem",
                                    gap: "0.5rem",
                                    background: "#FFF",
                                    marginTop: "0.75rem",
                                  }}
                                >
                                  <p className="black14">{city.name}</p>
                                  <ClearIcon
                                    style={{
                                      cursor: "pointer",
                                      height: "20px",
                                      width: "20px",
                                    }}
                                    onClick={() =>
                                      handleRemoveCity(i, index, city.name)
                                    }
                                  />
                                </div>
                              ))}
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  ))}

                <div className="d-flex">
                  <p
                    className="pink16 d-flex hoverdarkred"
                    style={{
                      marginTop: "2.5rem",
                      gap: "1rem",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setCountry((prev) => {
                        const updated = [...prev, { Country: "", City: [] }];
                        return updated;
                      });
                    }}
                  >
                    <AddIcon />
                    Add country
                  </p>
                </div>
              </>
              <hr style={{ marginTop: "2.5rem", marginBottom: "2.5rem" }} />
            </div>
            <div>
              <p className="formtext ">Upload logo</p>
              <div
                className="drop-area"
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onClick={isChangeImage ? null : openFileInput}
                style={{ marginTop: "12px" }}
              >
                {selectedFile && isChangeImage && (
                  <>
                    <ImageCropper
                      selectedFile={selectedFile}
                      setcroppedImage={setcroppedImage}
                      setChangeImage={setChangeImage}
                    />
                  </>
                )}{" "}
                {selectedFile && !isChangeImage && !croppedimage && (
                  <img
                    src={
                      selectedFile
                        ? typeof selectedFile === "string" &&
                          selectedFile?.includes("http")
                          ? selectedFile
                          : URL.createObjectURL(selectedFile)
                        : sample
                    }
                    alt="Company logo"
                    style={{ maxHeight: "90px", maxWidth: "90px" }}
                  />
                )}
                {croppedimage && !isChangeImage && (
                  <img
                    src={croppedimage}
                    alt="croped image"
                    style={{ maxWidth: "150px", maxHeight: "150px" }}
                  />
                )}
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  ref={fileInputRef}
                />
                {!selectedFile && (
                  <>
                    <img
                      src={upload}
                      alt="upload image"
                      style={{ maxHeight: "40px", maxWidth: "40px" }}
                    />
                    <div className="d-flex" style={{ gap: "5px" }}>
                      <p
                        className="hoverdarkred red14"
                        style={{
                          fontWeight: "600",
                        }}
                      >
                        Click to upload
                      </p>

                      <p
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        or drag and drop
                      </p>
                    </div>{" "}
                  </>
                )}
                {selectedFile && !isChangeImage && (
                  <>
                    <p
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "#E31B54",
                      }}
                    >
                      Click to change
                    </p>
                  </>
                )}
                <p
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#475467",
                  }}
                >
                  SVG, PNG, JPG, or GIF
                </p>
                {selectedFile && !isChangeImage && (
                  <p
                    className="hoverdarkred red14"
                    style={{
                      fontWeight: "600",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setChangeImage(true);
                    }}
                  >
                    Crop image
                  </p>
                )}
              </div>
            </div>
            <div>
              <p className="formtext " style={{ marginTop: "1rem" }}>
                Tagline
              </p>

              {/* <textarea
                value={tagline}
                onChange={(e) => setTagline(e.target.value)}
                className="forminput col-md-12 col-12 mt-2"
                rows="3" // Set the number of visible rows as needed
                maxLength={120}
              /> */}
              <TextArea tagline={tagline} setTagline={setTagline} />
            </div>
            <div
              className="d-flex "
              style={{ justifyContent: "space-between" }}
            >
              <p
                className="col-md-10 "
                style={{
                  color: "#667085",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "400",
                  marginTop: "0.5rem",
                }}
              >
                Use your tagline to briefly describe what your organization
                does. This can be changed later
              </p>

              <p
                className="col-md-2 text-end"
                style={{
                  color: "#667085",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                {tagline.length}/120
              </p>
            </div>
            {/* <div className=" d-flex" style={{ marginTop: "4.188rem" }}>
              <div className="" style={{ marginRight: "10px" }}>
                <div
                  onClick={() => setAgree(!agree)}
                  style={{ cursor: "pointer" }}
                >
                  {" "}
                  {agree ? <SelectedBox /> : <UnselectedBox />}
                </div>
              </div>
              <div className="">
                <p
                  style={{
                    color: "#667085",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  I verify that I am an authorized representative of this
                  organization and have the right to act on its behalf in the
                  creation and management of this page.The organization and I
                  agree to the additional
                  <a
                    className="red14 hoverdarkred"
                    href={`/terms`}
                    target="_blank"
                    style={{
                      fontWeight: "500",
                      marginLeft: "3px",
                      cursor: "pointer",
                      textDecoration: "none",
                    }}
                  >
                    Terms for Pages
                  </a>
                </p>
              </div>
            </div> */}
          </div>
        </div>
        {isNonMobile && (
          <div
            className="d-flex mt-5"
            style={{ justifyContent: "end", width: "50vw" }}
          >
            <div
              className="companyCardtoDisplay shadowa"
              style={{ overflow: "hidden", display: "block" }}
            >
              <div className=" m-5 mt-4 mb-0 ">
                <div class="custom-image ">
                  <img
                    src={
                      selectedFile
                        ? typeof selectedFile === "string" &&
                          selectedFile.includes("http")
                          ? selectedFile
                          : URL.createObjectURL(selectedFile)
                        : sample
                    }
                    alt="Company logo Image"
                    style={{
                      maxHeight: "72px",
                      maxWidth: "72px",
                      borderRadius: "5px",
                    }}
                  />
                </div>
              </div>
              <p className="companyname m-5 mt-3 mb-0">
                {name ? name : "Company Name"}
              </p>
              <p className="m-5 mt-0 mb-0 tagline" style={{ display: "block" }}>
                {tagline ? tagline : "Tagline"}
              </p>
              <div
                className="d-flex m-5 mt-3 mb-3"
                style={{ flexWrap: "wrap", gap: "0.2rem" }}
              >
                {country &&
                  country.length > 0 &&
                  country[0]?.Country?.flag &&
                  country.map((con, index) => (
                    <div className={`tagscard  `} key={index}>
                      {" "}
                      {con?.Country?.flag && con?.Country?.flag}
                      <p className="itemstexts">{con?.Country?.name}</p>
                    </div>
                  ))}
                {selectedItem.map((item, index) => (
                  <div
                    className={`interestcont `}
                    key={index}
                    style={{
                      padding: "10px 10px",
                      gap: "0.5rem",
                      border: "1px solid #d0d5dd",
                    }}
                  >
                    {item.icon && (
                      <img
                        src={item.icon}
                        alt="icon"
                        width="20px"
                        height="20px"
                      />
                    )}
                    <p
                      className="interesttext"
                      style={{
                        fontWeight: "400",
                        lineHeight: "1.125rem",
                      }}
                    >
                      {item.text}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="1"
        viewBox="0 0 1360 1"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1360 1H0V0H1360V1Z"
          fill="#EAECF0"
        />
      </svg>
      <div className="d-flex mt-3" style={{ justifyContent: "end" }}>
        <button className="m-3 mt-0 mb-0 backbutton" onClick={goBack}>
          Back
        </button>
        <button
          className="continuebutton"
          type="submit"
          onClick={(e) => {
            handleSubmit(e);
          }}
          disabled={
            !name ||
            !companyLink ||
            !website ||
            !industry ||
            selectedItem.length === 0 ||
            !size ||
            !type ||
            !country ||
            !selectedFile ||
            !tagline ||
            loading
          }
        >
          Continue
        </button>
      </div>
    </>
  );
};
export default Body;
