import "./secondsection.css";
import trophy from "../../assets/images/trophy.png";
import book from "../../assets/images/book.png";
import user from "../../assets/images/user.png";
import search from "../../assets/images/searcha.png";
import briefcase from "../../assets/images/briefcase.png";
import mobile from "../../assets/images/icons/Computer.png";
import laptop from "../../assets/images/laptop.png";
import Medal from "../../assets/images/icons/Medal.png";
import horn from "../../assets/images/icons/horn.png";
import Economic from "../../assets/images/icons/Economics.png";
import Files from "../../assets/images/icons/files.png";
import video from "../../assets/images/icons/videoicon.png";
import chart from "../../assets/images/icons/Chart.png";
import rocket from "../../assets/images/icons/rocket.png";
import test from "../../assets/images/companyHomeSlider1.png";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import Slider from "react-slick";
import Section2Component from "./section2component";

const NewSecondSection = ({
  isNonMobile1000,
  isNonMobile1180,
  isNonMobile500,
}) => {
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className="p-2 "
        style={{
          ...style,
          left: "0",
          zIndex: 1,
          position: "absolute",
          top: "110%",
          left: "5%",
          transform: "translateY(-50%)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          backgroundColor: "#FFF",
          borderRadius: "100px",
          border: "1.5px solid var(--Gray-800, #1D2939)",
          backgroundColor: "#FFF",
        }}
        onClick={onClick}
      >
        <MdKeyboardArrowLeft size={24} />
      </div>
    );
  }
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className=" p-2 r "
        style={{
          ...style,
          right: "0",
          zIndex: 1,
          position: "absolute",
          direction: "rtl",
          top: "110%",
          right: "80%",
          transform: "translateY(-50%)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          backgroundColor: "#FFF",
          borderRadius: "100px",
          border: "1.5px solid var(--Gray-800, #1D2939)",
        }}
        onClick={onClick}
      >
        <MdKeyboardArrowRight size={24} />
      </div>
    );
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,

    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const chips = [
    {
      text: "Employer branding",
      icon: trophy,
    },
    {
      text: "Pre-Skilling",
      icon: book,
    },
    {
      text: "Talent Network",
      icon: user,
    },
    {
      text: "Get Insights",
      icon: search,
    },
    {
      text: "Candidate journey",
      icon: briefcase,
    },
  ];
  const chips2 = [
    {
      text: "Messenger",
      icon: mobile,
    },
    {
      text: "Virtual Events",
      icon: laptop,
    },
    {
      text: "Challenge Campaigns",
      icon: Medal,
    },
    {
      text: "Social Feed",
      icon: horn,
    },
    {
      text: "Engagement Level Analytics",
      icon: Economic,
    },
  ];
  const chips3 = [
    {
      text: "Smart Apply",
      icon: Files,
    },
    {
      text: "Shortlist",
      icon: search,
    },
    {
      text: "Virtual Interview",
      icon: video,
    },
    {
      text: "Analytics",
      icon: chart,
    },
    {
      text: "Fast-Track Applicants",
      icon: rocket,
    },
  ];

  const slides = [
    {
      class: "homeSliderCard1",
      title: "Attract early talent with virtual challenges.",
      desc: "Design virtual challenges in which students practice the skills sets required to strive while getting to know your company.",
      tomap: chips,
      width: "25.5rem",
    },
    {
      class: "homeSliderCard2",
      title: "Engage with students at scale.",
      desc: "Scale your organization's recruitment efforts with messaging, events, challenge campaigns & more. ",
      tomap: chips2,
      width: "18.188rem",
    },
    {
      class: "homeSliderCard3",
      title: "Add matching candidates to your talent pool.",
      desc: "Invite ideal candidates to on-platform interviews or design job specific Fast-Track Challenges.",
      tomap: chips3,
      width: "26.5rem",
    },
  ];

  return (
    <div className="overflowshown">
      <div className="marginsleftrightCompany">
        <div className="d-flex " style={{ justifyContent: "center" }}>
          <div className="headerCompany">
            {isNonMobile1000 ? (
              <Slider {...settings} className="dsadasasd">
                {slides.map((slide, index) => (
                  <div key={index} className="d-flex">
                    <div
                      className={`${slide.class} img-fluid `}
                      style={{ position: "relative" }}
                    >
                      <div
                        className="insideSlider1"
                        style={{ position: "absolute", zIndex: 2 }}
                      >
                        <p
                          className="filmo30400"
                          style={{ maxWidth: slide.width }}
                        >
                          {slide.title}
                        </p>
                        <p
                          className="black16500 text-start"
                          style={{
                            marginTop: "1rem",
                            maxWidth: isNonMobile1180 ? "" : "26rem",
                          }}
                        >
                          {slide.desc}
                        </p>
                        <div
                          style={{
                            marginTop: "2rem",
                            gap: "1rem 0.75rem",
                            maxWidth: "30rem",
                          }}
                          className="d-flex flex-wrap"
                        >
                          {slide.tomap.map((chip, index) => (
                            <div key={index} className="newCompanyChips">
                              <img
                                src={chip.icon}
                                alt="icon"
                                height={"16px"}
                                width={"16px"}
                              />
                              {chip.text}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div style={{ width: "1rem" }}></div>
                  </div>
                ))}
              </Slider>
            ) : (
              <Section2Component isNonMobile500={isNonMobile500} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewSecondSection;
