import React, { useEffect, useRef } from "react";
import "./style.scss";
import { IoIosArrowBack } from "react-icons/io";
import logo from "../../../../assets/images/logo.png";
import flag from "../../../../assets/images/flags/flagGermany.png";
import interest1 from "../../../../assets/images/icons/money.png";
import interest2 from "../../../../assets/images/icons/airplane.png";
import { useState } from "react";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
function Step3({ firstName, LastName, setLastName, selectedItem }) {
  const uni = useSelector((state) => state.Uni);
  const inputRef = useRef(null);
  useEffect(() => {
    localStorage.setItem("lastName", JSON.stringify(LastName));
  }, [LastName]);

  useEffect(() => {
    // Focus on the input field when the component loads
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    const adjustFontSize = () => {
      const input = inputRef.current;

      if (input) {
        const currentFontSize = parseInt(
          window.getComputedStyle(input).fontSize
        );
        const maxWidth = input.clientWidth;

        input.style.fontSize = "58px"; // Set a default font size

        while (input.scrollWidth > maxWidth) {
          const newSize = parseInt(window.getComputedStyle(input).fontSize) - 1;
          input.style.fontSize = `${newSize}px`;
        }
      }
    };

    adjustFontSize();
  }, [LastName]);

  return (
    <motion.div
      id="Step3KN343"
      initial={{ opacity: 0.2, scale: 0.2, x: "50%" }}
      animate={{ opacity: 1, scale: 1, x: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="stepWrapper">
        {/* <div className="backButton" onClick={() => BackSetp()}>
          <h6>
            <IoIosArrowBack /> {"    "}
            Back
          </h6>
        </div> */}
        <div className="section_title">
          <p className="sectionheadingclamped">Your last name</p>
        </div>
        <div className="Form_first_wrapper">
          <div className="">
            <div className="">
              <div className="Input_wrapper">
                <input
                  ref={inputRef}
                  value={LastName}
                  type="text"
                  name="First_Name"
                  className="Name_field"
                  placeholder="type here"
                  onChange={(e) => setLastName(e.target.value)}
                  autoComplete="off"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default Step3;
