import React, { useState, useEffect } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const CountdownTimer = ({
  totalTime,
  color,
  setTimeLeft,
  frombefore,
  SubmitDone,
  answers,
  show,
  files,
}) => {
  // Convert total time from minutes to seconds
  const totalTimeInSeconds = totalTime * 60;

  // Initialize the remaining time with the total time in seconds
  const [remainingTime, setRemainingTime] = useState(totalTimeInSeconds);
  const [percentage, setPercentage] = useState(100);

  useEffect(() => {
    if (frombefore) {
      setRemainingTime(Number(frombefore.timeleft));
    }
  }, [frombefore]);

  useEffect(() => {
    if (remainingTime === 0) {
      SubmitDone(answers, show, files, remainingTime);
    }
  }, [remainingTime]);

  useEffect(() => {
    // Start an interval to decrease the remaining time
    const interval = setInterval(() => {
      setRemainingTime((prevTime) => {
        if (prevTime <= 1) {
          // Change to 1 to avoid immediate zero
          clearInterval(interval); // Stop the interval when time is up
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setTimeLeft(remainingTime);
    setPercentage((remainingTime / totalTimeInSeconds) * 100);
  }, [remainingTime, totalTimeInSeconds]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? `0${secs}` : secs}`;
  };

  return (
    <div style={{ display: "flex" }}>
      <div style={{ width: "50px", height: "50px", marginRight: "0.75rem" }}>
        <CircularProgressbar
          value={percentage}
          strokeWidth={13}
          styles={buildStyles({
            textColor: "transparent", // Hide the text
            pathColor: color,
            trailColor: "#EAECF0",
          })}
        />
      </div>
      <div className="space flex-column">
        <p className="pink20" style={{ color }}>
          {formatTime(remainingTime)}
        </p>
        <p
          className="gray12"
          style={{ fontWeight: 500, marginTop: "0.25rem" }}
        >{`${totalTime} mins total time`}</p>
      </div>
    </div>
  );
};

export default CountdownTimer;
