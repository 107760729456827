import { motion, useTransform, useScroll } from "framer-motion";
import React, { useRef, useState, useCallback, useEffect } from "react";
import emailjs from "@emailjs/browser";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { toast } from "react-toastify";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
// Wrap the component with React.memo
const HorizontalScrollCarousel = React.memo(
  ({ isNonMobile1000, navigate, text }) => {
    const targetRef = useRef();

    const { scrollYProgress } = useScroll({
      target: targetRef,
      offset: ["start end", "end start"], // Adjust the offsets to trigger the animation at the right time
    });
    const width = useTransform(scrollYProgress, [0, 0.4], ["82%", "100vw"]);
    const height = useTransform(
      scrollYProgress,
      [0, 0.3],
      [isNonMobile1000 ? "300px" : "600px", isNonMobile1000 ? "458px" : "600px"]
    );
    const borderRadius = useTransform(
      scrollYProgress,
      [0, 0.3],
      [isNonMobile1000 ? "10px" : "20px", "0px"]
    );
    const [loading, setLoading] = useState(false);
    const iconRef = useRef();
    useEffect(() => {
      window.scrollTo({ top: 0, behavior: "instant" });

      function handleClickOutside(event) {
        if (iconRef.current && !iconRef.current.contains(event.target)) {
          setShowDropDown(false);
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on cleanup
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);

    const [name, setName] = useState("");
    const [workMail, setWorkMail] = useState("");
    const [companySize, setCompanySize] = useState("");
    const [check, setCheck] = useState(false);
    const [showDropDown, setShowDropDown] = useState(false);
    const sendEmail = useCallback(() => {
      setLoading(true);
      const payload = {
        from_name: name,
        name: name,
        from_email: workMail,
        company_size: companySize,
      };

      emailjs
        .send(
          "service_08a640e",
          "template_65hjrwp",
          payload,
          "FCKBh2iU92kGQhhAA"
        )
        .then(
          (result) => {
            console.log(result.text);
            setWorkMail("");
            setCheck(false);
            setCompanySize("");
            setName("");
            toast.success("Email sent successfully");
            setLoading(false);
          },
          (error) => {
            console.log(error.text);
            toast.error("Error Sending Email");
            setLoading(false);
          }
        );
    }, [name, workMail, companySize]);

    const options = ["1-10", "11-50", "51-500", "501-5000", "5000+"];

    return (
      <>
        <section
          ref={targetRef}
          style={{ height: "fit-content", position: "relative" }}
          className="center align-items-center"
        >
          <div style={{ position: "relative" }}>
            <motion.div
              className="d-flex contactusBackground"
              style={{
                width,
                height,
                borderRadius,
                paddingTop: isNonMobile1000 ? "3rem" : "",
                paddingBottom: isNonMobile1000 ? "3rem" : "",
              }}
            >
              <div className="marginsleftrightCompany">
                <div className="center">
                  <div className="d-flex headerCompany">
                    <div
                      className={`d-flex  space gap-5 w-100 ${
                        isNonMobile1000 ? "pt-5" : ""
                      }`}
                      style={{
                        padding: "1.25px",
                        flexDirection: "column",
                      }}
                    >
                      <div>
                        <h2 className="filmo48400 text-center">
                          {text ? text : "Let’s get started"}
                        </h2>
                        <p
                          className="white20500 text-center"
                          style={{ marginTop: "1.5rem" }}
                        >
                          We are happy to have you onboard!
                        </p>
                        <div
                          className="center"
                          style={{
                            width: "100%",
                            position: "relative",
                            marginTop: "3.75rem",
                          }}
                        >
                          <div
                            className="d-flex flex-wrap"
                            style={{ gap: "1.25rem" }}
                          >
                            <button
                              className="continuebutton border-0"
                              onClick={() => {
                                navigate("/career-center/contact");
                              }}
                            >
                              Get a demo
                            </button>
                            <button
                              className="backbutton"
                              onClick={() => {
                                navigate("/career-center/register");
                              }}
                            >
                              Start Onboarding (Free)
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        </section>
      </>
    );
  }
);

const Fifth = ({ text }) => {
  const isNonMobile1000 = useMediaQuery("(min-width: 1001px)");
  const navigate = useNavigate();
  return (
    <div
      className="d-flex"
      style={{
        justifyContent: "center",
      }}
    >
      <div className="headerCompany">
        <HorizontalScrollCarousel
          isNonMobile1000={isNonMobile1000}
          navigate={navigate}
          text={text}
        />
      </div>
    </div>
  );
};
export default Fifth;
